import { useState, useEffect } from "react";
import * as React from "react";
import { Autocomplete, TextField, Box, Chip, Typography, FormHelperText, Grid, IconButton } from '@mui/material';
import CheckBoxOutlineBlankIcon from "@mui/icons-material/CheckBoxOutlineBlank";
import CheckBoxIcon from "@mui/icons-material/CheckBox";
import { useFormContext, Controller } from 'react-hook-form';
import FormErrors from 'src/Components/FormItem/formErrors';
import { IsNullOrUndefined, ResultAPI } from 'src/CommonFunction/TS_function';
import SearchIcon from '@mui/icons-material/Search';
import axios from "axios"
import qs from "qs";
import { InputForm } from 'src/Components/FormItem/Input'
const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
const checkedIcon = <CheckBoxIcon fontSize="small" />;
/**
 * Auto Complete 
 * สามารถ เลื่อกได้มากกว่า 1 รายการ
 */
export const AutoCompleteForm = (props: AutoCompleteUI) => {
  const {
    name,
    multiple,
    required,
    label,
    placeholder,
    fullWidth,
    disabled,
    externalErrorMessage,
    urlAPI,
    paramUrl,
    isClearable = true,
    limitTag = 3,
    onChange,
    textFilterCont = 3,
    helperText,
    size,
    shrink = undefined,
    fromHome = false,
    sMethodAxios = "GET"
  } = props;

  const {
    register,
    setValue,
    watch,
    control,
    formState: { errors, isSubmitted, touchedFields },
  } = useFormContext();

  const errorMessage = FormErrors.errorMessage(
    name,
    errors,
    touchedFields,
    isSubmitted,
    externalErrorMessage,
  );

  const [inputValue, setInputValue] = React.useState('');
  const [optionsValueAuto, setOptionsValueAuto] = React.useState([] as any);
  const [txtOption, settxtOption] = useState("กรอกเพื่อค้นหา")

  useEffect(() => {
    setInputValue(watch(name) ? watch(name).label : "")
    if (watch(name) === null) {
      setOptionsValueAuto([])
    }
  }, [watch(name)])

  useEffect(() => {
    if (inputValue === '' || inputValue === null || inputValue === undefined) {
      setOptionsValueAuto([])
    }
    else {
      if (inputValue.length >= textFilterCont) {
        const source = axios.CancelToken.source();
        const paramSearch = { sSearch: inputValue };
        let paramObj = { ...paramUrl, ...paramSearch };
        let sPathApi = process.env.REACT_APP_API + "api/" + urlAPI;
        let auth_token = localStorage.getItem(`${process.env.REACT_APP_JWT_KEY}`) || null;
        let ConfigJwt = {
          Authorization: IsNullOrUndefined(auth_token) ? "" : `Bearer ${auth_token}`,
          Accept: "application/json",
          "Content-Type": "application/json",
        };

        settxtOption("กำลังค้นหา");

        if (sMethodAxios === "POST") {
          axios.post(sPathApi, paramObj,{
            headers: ConfigJwt,
            cancelToken: source.token,
            paramsSerializer: (params) => {
                return qs.stringify(params);
            },
            }).then((response) => {
                if (response.data != null && response.data.length > 0) {
                    setOptionsValueAuto(response.data || [])
                }
                else {
                    setOptionsValueAuto([])
                    settxtOption("ไม่พบข้อมูล")
                }
            }).catch((error) => {
                if (axios.isCancel(error)) return;
            });
        }
        else {
          axios.get(sPathApi, {
            headers: ConfigJwt,
            params: paramObj,
            cancelToken: source.token,
            paramsSerializer: (params) => {
              return qs.stringify(params);
            },
          }).then((response) => {
            if (response.data != null && response.data.length > 0) {
              setOptionsValueAuto(response.data || [])
            }
            else {
              setOptionsValueAuto([])
              settxtOption("ไม่พบข้อมูล")
            }
          }).catch((error) => {
            if (axios.isCancel(error)) return;
          });
        }
        
        return () => source.cancel();
      }
    }
  }, [inputValue])

  return (
    <Controller
      control={control}
      name={name}
      rules={{ required: required }}
      render={({ field }) => (
        <Autocomplete
          {...field}
          getOptionLabel={(itemOption: any) => {
            return `${itemOption.label}`;
          }}
          filterOptions={(x) => x}
          options={optionsValueAuto}
          autoComplete
          size={size || "small"}
          noOptionsText={txtOption}
          blurOnSelect
          includeInputInList
          filterSelectedOptions
          disabled={disabled}
          // disableClearable={!isClearable}
          multiple={multiple}
          limitTags={limitTag}
          onChange={(event: any, newValue) => {
            field.onChange(event, newValue);
            if (newValue) {
              if (multiple) {
                if (newValue) {
                  setValue(name, newValue ? newValue : undefined, {
                    shouldValidate: true,
                  });
                  setOptionsValueAuto([]);
                }
              } else {
                if (newValue.value) {
                  setValue(name, newValue != null ? newValue : undefined, {
                    shouldValidate: true,
                  });
                  setOptionsValueAuto([]);
                }
              }
            } else {
              setValue(name, null);
            }
            onChange && onChange(newValue);
          }}
          onInputChange={async (event, newInputValue) => {
            settxtOption(
              "กรอกตัวอักษรมากกว่า " + textFilterCont + " ตัวอักษรเพื่อค้นหา"
            );
            if (newInputValue === "") {
              setOptionsValueAuto([]);
              return undefined;
            } else if (newInputValue.length >= textFilterCont) {
            } else {
              setOptionsValueAuto([]);
            }
            setInputValue(newInputValue);
          }}
          sx={{
            ".MuiOutlinedInput-root": {
              paddingRight: "10px !important",
              borderRadius: fromHome ? "16px" : "",
              width: fromHome ? "300px" : "100%",
            },
            ".MuiTextField-root": {
              borderRadius: fromHome ? "16px" : "",
              width: fromHome ? "300px" : "100%",
            },
          }}
          renderInput={(params) => (
            <TextField
              {...params}
              label={label}
              sx={[{ m: 1 }]}
              fullWidth={fullWidth}
              required={required}
              style={{ width: fromHome ? "":  "100%" }}
              placeholder={placeholder}
              InputProps={{
                ...params.InputProps,
                endAdornment: (
                    <React.Fragment>
                        <IconButton style={{ padding: '0px' }} disabled={disabled}>
                            <SearchIcon />
                        </IconButton>
                    </React.Fragment>
                )
            }}
              // InputProps={{
              //   ...params.InputProps,
              //   endAdornment: (
              //     <React.Fragment>
              //       <IconButton style={{ padding: '0px' }}>
              //         <SearchIcon />
              //       </IconButton>
              //     </React.Fragment>
              //   )
              // }}
              InputLabelProps={{
                disabled: disabled || false,
                shrink: shrink,
              }}
              error={!!Boolean(errorMessage)}
              helperText={errorMessage || helperText}
            />
          )}
          renderOption={(props, option: any) => {
            return (
              <li {...props} key={option.value}>
                {option.label}
              </li>
            );
          }}
          renderTags={(tagValue, getTagProps) => {
            return (
              <React.Fragment>
                {tagValue.slice(0, limitTag | 1).map((option: any, index) => (
                  <Chip
                    {...getTagProps({ index })}
                    label={option.label}
                    disabled={disabled || false}
                  />
                ))}
                {/* {
                                tagValue.length > (limitTag | 1) ? <Chip label={"+" + (tagValue.length - (limitTag | 1))} disabled={disabled || false} /> : <React.Fragment></React.Fragment>
                            } */}
              </React.Fragment>
            );
          }}
        />
      )}
    />
  );
}

interface AutoCompleteUI extends InputForm {
  /**
   * ถ้าเป็นจริงจะเลือกได้มากว่า 1
   */
  multiple?: boolean;
  /**
   * API ที่ต้องการไปดึงข้อมูล
   */
  urlAPI?: string;
  /**
   * parameter ที่ต้องการส่งไปเพิ่ม
   */
  paramUrl?: any;
  /**
   * ถ้าเป็นจริงจะแสดง ปุ่ม Clear
   */
  isClearable?: boolean;
  /**
   * ถ้าเป็น multiple
   * limitTags คือจำนวนรายการที่สามารถเลือกได้
   * default 3
   */
  limitTag?: any;
  /**
   * จำนวนตัวอักษรที่จะไปต้องการค้นหา
   * default 3
   */
  textFilterCont?: number;
  /**
   * มาจาก PageHome
   * default false
   */
  fromHome?: boolean;
  sMethodAxios?: any;
}

export default AutoCompleteForm;