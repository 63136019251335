import React, { useState, useEffect } from "react";
import ListSubheader from "@mui/material/ListSubheader";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import Collapse from "@mui/material/Collapse";

import ExpandLess from "@mui/icons-material/ExpandLess";
import ExpandMore from "@mui/icons-material/ExpandMore";
import Divider from "@mui/material/Divider";
import { Link } from "react-router-dom";

export default function Layoutmenu(props): any {
  const [lstMenuAtID, setlstMenuAtID] = useState([0]);

  useEffect(() => {
    let arrMenu: any[] = [];
    props.lstMenu.forEach(f => {
      arrMenu.push({ path: f.path, lstHead: f.lstHead });
      if (f.items.length > 0) {
        arrMenu = setarrMenu(arrMenu, f.items)
      }
    });

    let d = arrMenu.filter(f => (process.env.REACT_APP_API_URL + f.path) == window.location.pathname + window.location.search);
    if (d.length > 0) {
      setlstMenuAtID(d[0].lstHead)
    } else {
      if (props.menuID !== undefined) {
        if (props.menuID.length > 0) {
          setlstMenuAtID(props.menuID)
        } else {
          setlstMenuAtID([])
        }
      } else {
        setlstMenuAtID([])
      }
    }
  }, [window.location.href, props.lstMenu]);

  const setarrMenu = (arrMenu, item) => {
    item.forEach(f => {
      arrMenu.push({ path: f.path, lstHead: f.lstHead });
      if (f.items.length > 0) {
        setarrMenu(arrMenu, f.items)
      }
    });
    return arrMenu;
  }

  return props.lstMenu.map((item, key) => (
    <MenuItem
      key={key}
      item={item}
      toggleDrawer={props.toggleDrawer}
      menuID={props.menuID}
      lstMenuAtID={lstMenuAtID} />
  ));
}
const hasChildren = (item) => {
  const { items: children } = item;

  if (children === undefined || children === null) {
    return false;
  }

  if (children.constructor !== Array) {
    return false;
  }

  if (children.length === 0) {
    return false;
  }

  return true;
};


const MenuItem = ({ item, toggleDrawer, menuID, lstMenuAtID }) => {
  const MenuComponent = hasChildren(item) ? MultiLevel : SingleLevel;
  return <MenuComponent item={item} toggleDrawer={toggleDrawer} menuID={menuID} lstMenuAtID={lstMenuAtID} />;
};

const SingleLevel = ({ item, toggleDrawer, menuID, lstMenuAtID }) => {
  let aMenu = lstMenuAtID.filter(f => f == item.menuID).length > 0
  const [toggle, setToggle] = React.useState(toggleDrawer);
  return (
    <Link
      key={item.menuID}
      to={process.env.REACT_APP_API_URL + item.path || ""}
      style={{
        fontWeight: "normal", textDecoration: "none",
        color: aMenu
          ? "rgb(255,255,255)"
          : "black",
      }}
    >
      <ListItem
        key={item.menuID}
        sx={{
          textTransform: "none", fontWeight: "normal",
          bgcolor: aMenu
            ? "#3997df"
            : "rgb(255,255,255)",
          ":hover": {
            bgcolor: "#3997df",
            color: "rgb(255,255,255)",
          },
        }}
        button
        onClick={() => {
          setToggle("left");
        }}
      >
        <ListItemText primary={item.menuTH} />
      </ListItem>
    </Link>
  );
};

const MultiLevel = ({ item, menuID, lstMenuAtID }) => {
  const { items: children } = item;
  const [open, setOpen] = useState(false);
  const isactive = lstMenuAtID.filter(f => f == item.menuID).length > 0;

  const handleClick = () => {
    setOpen((prev) => !prev);
  };

  return (
    <>
      <>
        <ListItem button onClick={handleClick} sx={{
          bgcolor: isactive ? "#3997df" : "white",
          color: isactive ? "white" : "black",
          ":hover": {
            bgcolor: "#3997df",
            color: "rgb(255,255,255)",
          }
        }}>
          <ListItemText primary={item.menuTH} />
          {open ? <ExpandLess /> : <ExpandMore />}
        </ListItem>
        <Collapse in={open} timeout="auto" unmountOnExit>
          <List component="div" >
            {children.map((child) => (
              <MenuItem
                key={child.menuID}
                item={child}
                toggleDrawer={undefined}
                menuID={menuID}
                lstMenuAtID={lstMenuAtID}
              />
            ))}
          </List>
        </Collapse>
      </>
    </>
  );
};