import { Button, Box, CardContent, Grid, Stack, Card } from "@mui/material";
import Typography from "@mui/material/Typography";
import { useEffect, useState } from "react";
import { Axios } from "src/CommonFunction/TS_Axios";
import { FnBlock_UI } from "../BlockUI";
import NameBox from "./RegisterNameBox";
import { Banner } from "../Banner";
import imgCover from "src/Layout/img/vision-cover.jpg";
import htmlparse from "html-react-parser";
import { useNavigate } from "react-router";
import { Encrypt } from "src/CommonFunction/TS_function";
import { BreadcrumbsUI } from "../BreadcrumbsUI";
const Regiter = () => {
  const { BlockUI, UnBlockUI } = FnBlock_UI();
  const [arrCard, setarrCard] = useState<any[]>([]);
  const [banner, setBanner] = useState([] || undefined);
  const navigate = useNavigate();

  useEffect(() => {
    setData();
  }, []);

  const setData = async () => {
    let resBanner = await loadBanner();
    let resarrCard = await OnGetData();
    setBanner(resBanner || []);
    setarrCard(resarrCard || []);
  };

  const OnGetData = async () => {
    let result = {} as any;
    await Axios(
      `RegisterPage/GetData_Register`,
      {},
      "GET",
      async (res) => {
        result = res.lstRegisterPage;
      },
      null,
      BlockUI,
      UnBlockUI,
      () => { }
    );
    return result;
  };

  const loadBanner = async () => {
    let result = [] as any;
    result.push({
      sPath: imgCover,
    });
    return result;
  };

  const getEncrypt = async (param) => {
    let result = null as any;
    await Axios(
      `Master/Encrypt`,
      "sValue=" + param,
      "Get",
      async (res) => {
        result = res;
      },
      null,
      BlockUI,
      UnBlockUI,
      () => { }
    );
    return result;
  };

  const onClickRegister = async (nUserTypeID, nUserType) => {
    let nUTID = await getEncrypt(nUserTypeID);
    let nUID = await getEncrypt(nUserType);
    navigate(process.env.REACT_APP_API_URL + `Registration?nUserTypeID=${nUTID}` + `&nUserType=${nUID}`);
  }

  const onClickCheckTracking = (nUserType) => {
    navigate(nUserType == 3 ? process.env.REACT_APP_API_URL + "RegisterTrackingDealer" : process.env.REACT_APP_API_URL + "RegisterTrackingMember");
  }

  const StepRegister = [
    {
      id: 0,
      sName: "กรอกข้อมูลคำขอ",
      sDesc: "ระบุข้อมูลของท่านหรือธุรกิจของท่าน",
      classname: "request",
    },
    {
      id: 1,
      sName: "ส่งคำขอ",
      sDesc: "ส่งคำขอสมัครเข้าใช้งาน เพื่อให้ OR Academy ตรวจสอบข้อมูลและอนุมัติคำขอ",
      classname: "send",
    },
    {
      id: 2,
      sName: "รออนุมัติคำขอ",
      sDesc: "เป็นขั้นตอน OR Academy ตรวจสอบข้อมูลและอนุมัติคำขอ",
      classname: "approve",
    },
    {
      id: 3,
      sName: "เสร็จสิ้นการสมัคร",
      sDesc: "ท่านจะได้รับอีเมลผลคำขอการสมัครเข้าใช้งานพร้อม Username",
      classname: "complete",
    },
  ];

  return (
    <Grid container justifyContent="center" alignItems="center">
      <Grid item xs={12}>
        <Banner
          dataSource={banner || []}
          height={50}
          textTH="สมัครสมาชิก"
          textENG="Sign Up"
        />
      </Grid>
      {/* <Grid item xs={12} sx={{ marginTop: 2, mx: 3 }}>
        <Typography variant="h5"> สมัครสมาชิก/Sign Up</Typography>
      </Grid> */}
      <Grid item xs={12}>
        <BreadcrumbsUI
          isShowHome={false}
          items={[
            {
              Key: "1",
              menuName: "สมัครสมาชิก/Sign Up",
              IsOnClick: false,
            },
          ]}
        />

      </Grid>
      <Grid item xs={12} sx={{ paddingTop: "30px" }}>
        <Typography align="center" color="#03a9f4" variant="h6">
          {" "}
          ขั้นตอนการสมัครเข้าใช้งานระบบ OR Academy
        </Typography>
        <Grid
          container
          justifyContent="center"
          alignItems="center"
          sx={{
            marginTop: "2%",
          }}
        >
          {StepRegister.map((item, index) => (
            <Grid item key={index}>
              <NameBox sNameBox={item} />
            </Grid>
          ))}
        </Grid>
      </Grid>
      <Grid
        item
        xs={12}
        justifyContent="center"
        alignItems="center"
        sx={{
          marginTop: "2%",
        }}
      >
        <Typography align="center" fontSize={23}>
          {" "}
          เลือกประเภทผู้ใช้งานของท่าน
        </Typography>
      </Grid>
      <Grid item xs={12} justifyContent="center" alignItems="center">
        <Typography align="center" fontSize={23}>
          Select a user type.{" "}
        </Typography>
      </Grid>

      <Grid container>
        <Grid item xs={12} sx={{ display: "flex", flexWrap: "wrap", mb: "2em", mx: { md: "5em" } }}>
          {(arrCard.length > 0 ? arrCard : []).map((item, i) => {
            if (arrCard.length > 0) {
              return (
                <Grid item xs={12} sm={6} lg={3} sx={{ px: { xs: "3em", md: "2em" }, py: "1em" }}>
                  < Card sx={{
                    borderRadius: 5,
                    boxShadow: "13px 9px 20px 2px #b5b5b5;",
                  }} >
                    <CardContent sx={{ height: 350 }} >

                      <Grid item xs={12}>
                        <Typography fontSize={20} color="#2c387e" align="center">
                          {item.sUserTypeNameTH}
                        </Typography>
                      </Grid>
                      <Grid item xs={12}>
                        <Typography fontSize={20} color="#2c387e" align="center" >
                          {item.sUserTypeNameEN}
                        </Typography>
                      </Grid>

                      <Grid item xs={12} sx={{ height: "105px", m: "1em 1.1em", overflowY: "auto" }}>
                        <Typography
                          fontSize={14}
                          align="left"
                          // flexWrap="wrap"
                          sx={{
                            whiteSpace: "normal",
                          }}
                        >
                          {htmlparse(item.sUserTypeDescTH)}
                        </Typography>
                      </Grid>


                      <Grid item xs={12} sx={{ mt: "1.5em" }} >
                        <Button variant="contained" fullWidth
                          onClick={() => onClickRegister(item.nUserTypeID, item.nUserType)}
                          sx={{
                            backgroundColor: "#00BFFF", justifyContent: "center", fontSize: 15, color: "White", ":hover": { bgcolor: "##009ACD" }
                          }}>สมัครสมาชิก/Sign Up</Button>
                      </Grid>
                      <Grid item xs={12} sx={{ mt: "0.5em" }}>
                        <Button variant="contained" fullWidth
                          onClick={() => { onClickCheckTracking(item.nUserType) }}
                          sx={{
                            backgroundColor: "#009ACD", justifyContent: "center", fontSize: 15, color: "White", ":hover": { bgcolor: "##009ACD" }
                          }}>
                          <p style={{ whiteSpace: "nowrap", margin: "0px" }}>
                            <label>ตรวจสอบสถานะการสมัคร </label>
                            <br />
                            <label>Tracking Status</label>
                          </p>
                        </Button>
                      </Grid>
                    </CardContent>
                  </Card >
                </Grid>
              );
            }
          })}
        </Grid>
      </Grid>
    </Grid>
  );
};
export default Regiter;
