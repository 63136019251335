import { useEffect, useRef, useState } from "react";
import { Dialog, DialogTitle, DialogContent, DialogActions, Grid, Box, SxProps, Stack, Divider } from "@mui/material";
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import { styled } from '@mui/material/styles';
import { FnBlock_UI } from 'src/Components/BlockUI';
import { Axios, AxiosGetAsync } from 'src/CommonFunction/TS_Axios';
import { BsFlagFill } from 'react-icons/bs';
import { MdLocationPin } from 'react-icons/md';
import { BiCalendar } from 'react-icons/bi';
import { useForm } from "react-hook-form";
import DialogPreview from "src/Components/FormItem/Dialog/DialogPreview";
import { IsNullOrUndefined, ParseHtml } from "src/CommonFunction/TS_function";
import "./ClassroomDetail.css";
import { BtnDetail, BtnCancelForm } from 'src/Components/Button';
import { ClassroomPrice } from 'src/Components/System/ClassroomModal/ClassroomPrice';
import "src/Components/System/ClassColor.css"
export function ClassroomDetail(props: pClassroom) {
    const [open, setOpen] = useState(props && props.isopen);
    const [lstData, setData] = useState([] as any);
    const { BlockUI, UnBlockUI } = FnBlock_UI();

    // const GetClassroomDetail = async () => {
    //     BlockUI();
    //     let result = {} as any;
    //     result = await AxiosGetAsync("Classroom/GetClassroomDetail", { nCourseID: 34, nScheduleID: 1 });
    //     UnBlockUI();
    //     return result;
    // };
    // const GetData = async () => {
    //     let res = await GetClassroomDetail();
    //     setData(res);
    // };
    const BootstrapDialog = styled(Dialog)(({ theme }) => ({
        '& .MuiDialogContent-root': {
            padding: theme.spacing(2),
        },
        '& .MuiDialogActions-root': {
            padding: theme.spacing(1),
        },
    }));

    useEffect(() => {
        if (props.isopen) {
            console.log("useEffect", props)
            setOpen(props.isopen)
        }
    }, [props]);

    // useEffect(() => {

    //     setTimeout(() => {
    //         GetData();
    //         console.log(lstData)
    //     }, 50);
    // }, [])

    const handleClickOpen = () => {
        setOpen(true);
    };
    const handleClose = () => {
        setOpen(false);
    };

    if (props.onClose) {
        props.onClose(open)
    }


    const BootstrapDialogTitle = (props: DialogTitleProps) => {
        const { children, onClose, ...other } = props;

        return (
            <DialogTitle sx={{ m: 0, p: 2, paddingLeft: "1.5em !important" }} className="card-titie-dialog-color" {...other}>
                {children}
                {onClose ? (
                    <IconButton
                        aria-label="close"
                        onClick={onClose}
                        sx={{
                            position: 'absolute',
                            right: 8,
                            top: 8,
                            //color: (theme) => theme.palette.grey[500],
                            color: "#fff"
                        }}
                    >
                        <CloseIcon />
                    </IconButton>
                ) : null}
            </DialogTitle>
        );
    };

    return (
        <>
            <BtnDetail
                color={"#FF0000"}
                hover={"#f53232"}
                txt="รายละเอียดเพิ่มเติม/Detail"
                fullWidth
                onClick={handleClickOpen}
            />


            <div >
                <BootstrapDialog
                    onClose={handleClose}
                    aria-labelledby="customized-dialog-title"
                    open={open}
                    maxWidth={"md"}
                    fullWidth={true}

                >
                    <BootstrapDialogTitle id="customized-dialog-title" onClose={handleClose}>
                        <div style={{ paddingTop: "0.7em" }}>
                            {props.data.sTitle}
                        </div>


                    </BootstrapDialogTitle>
                    <DialogContent dividers sx={{ paddingLeft: "1.5em !important" }}>
                        <Typography gutterBottom >
                            <p className="m-title-detail"
                                style={{
                                    WebkitLineClamp: 1,
                                    overflow: "hidden",
                                    whiteSpace: "nowrap",
                                    textOverflow: "ellipsis",
                                    flexWrap: "nowrap",
                                    margin: "0px",
                                    WebkitBoxOrient: "vertical",
                                }}
                            >
                                <BsFlagFill
                                    color="rgb(251 107 42)"
                                    size="20"
                                    style={{
                                        marginRight: "5px",
                                    }}
                                />
                                {ParseHtml("&nbsp;&nbsp;")}{props.data.sScheduleName}

                            </p>
                            <p className="m-title-detail"
                                style={{
                                    display: "flex",
                                    alignItems: "center",
                                    margin: "0px",
                                }}
                            >
                                <BiCalendar
                                    color="rgb(0 0 255)"
                                    size="20"
                                    style={{
                                        marginRight: "5px",
                                    }}
                                />
                                {ParseHtml("&nbsp;&nbsp;")}{props.data.sClassDate}

                            </p>
                            <p className="m-title-detail"
                                style={{
                                    display: "flex",
                                    alignItems: "center",
                                    margin: "0px",
                                }}
                            >
                                <MdLocationPin
                                    color="rgb(255 0 0)"
                                    size="20"
                                    style={{ marginRight: "5px" }}
                                />

                                {ParseHtml("&nbsp;&nbsp;")}{props.data.sPlace}<>{!IsNullOrUndefined(props.data.sPlace) && !IsNullOrUndefined(props.data.sRoom) ? <>,</> : <></>}</>{props.data.sRoom}
                            </p>

                        </Typography>
                        <Typography gutterBottom>

                            {ParseHtml("&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;")} {ParseHtml(props.data.sDesc)}
                        </Typography>
                        <Typography gutterBottom>
                            <p className="m-title-content">เนื้อหาหลักสูตร/Course Content</p>
                            {ParseHtml(props.data.sCourseContent)}

                        </Typography>
                        <Typography gutterBottom>

                            <p className="m-title-content">ผลลัพธ์จากการอบรม/Learning Outcomes</p>
                            {ParseHtml(props.data.sLearningOutcome)}

                        </Typography>
                        <Typography gutterBottom>
                            <p className="m-title-content">คุณสมบัติ,ระดับของผู้อบรม</p>
                            {ParseHtml(props.data.sQualification)}
                        </Typography>

                        {

                            // (!props.data.isExpire) ?
                            ((props.data.lstPrice || []).length > 0 ? props.data.lstPrice : []).map(
                                (m) => {

                                    let price: { sID: string, nCourseID: number, nPriceID: number, nScheduleID: number, sPriceSchedule: string, sPrice: string, sNameRoom: string, isRegisterable: boolean, sMsgRegisterable: string, isWaitingList: boolean }[] = [
                                        { "sID": m.nCourseID, "nCourseID": m.nCourseID, "nPriceID": m.nPriceID, "nScheduleID": m.nScheduleID, "sPriceSchedule": m.sPriceSchedule, "sPrice": m.sPrice, "sNameRoom": m.sNameRoom, "isRegisterable": m.oRegis.isRegisterable, "sMsgRegisterable": m.oRegis.sMsgRegisterable, "isWaitingList": m.oRegis.isWaitingList },
                                    ];

                                    return (<>
                                        <Box>

                                            <ClassroomPrice dataPrice={price} sField={m.sNameRoom} dataColumn={m.dataColumn} isExpire={props.data.isExpire}></ClassroomPrice>

                                        </Box></>)


                                }
                            )
                            // :
                            // (props.data.lstPrice.length > 0 && !IsNullOrUndefined(props.data.lstPrice[0].oRegis.sMsgRegisterable)) ?

                            //     <Typography gutterBottom style={{ textAlign: "center" }}>


                            //         <Divider style={{ paddingBottom: "0.2em" }} />
                            //         <p style={{ color: "#db0000", fontWeight: "550", padding: "0.1em", fontSize: "1.3em" }}>{props.data.lstPrice[0].oRegis.sMsgRegisterable}</p>
                            //         <Divider style={{ paddingTop: "0.2em" }} />
                            //     </Typography>
                            //     :
                            //     <></>

                        }

                        {!IsNullOrUndefined(props.data.sContactPerson) &&
                            <Typography gutterBottom>
                                <p className="m-title-content">ติดต่อสอบถามผู้ดูแลหลักสูตร/Contact Person</p>
                                {ParseHtml(props.data.sContactPerson)}
                            </Typography>
                        }
                    </DialogContent>
                    <DialogActions>
                        <BtnCancelForm txt={"ย้อนกลับ/Back"} onClick={handleClose} />
                        {/* <Button autoFocus onClick={handleClose}>
                            กลับ/ฺBack
                        </Button> */}
                    </DialogActions>
                </BootstrapDialog>
            </div>

        </>
    );

}
interface pClassroom {
    nID: number;
    nScheduleID: number;
    data: any;
    isExpire: Boolean;
    isopen?: any;
    onClose?: (e) => any;
}
interface DialogTitleProps {
    id: string;
    children?: React.ReactNode;
    onClose: () => void;
}