import LayoutFooter from "./LayoutFooter";
import LayoutHead from "./LayoutHead";
import Box from "@mui/material/Box";
import { ScrollToTop } from "src/Layout/ScrollToTop";
import { ScrollToBottom } from "src/Layout/ScrollToBottom";
import {
  HiOutlineArrowNarrowDown,
  HiOutlineArrowNarrowUp,
} from "react-icons/hi";
import "./Layout.css";
import { useEffect, useState } from "react";
import { Axios } from "src/CommonFunction/TS_Axios";
import { FnBlock_UI } from "src/Components/BlockUI";
import { profile } from "console";
import Fab from "@mui/material/Fab";

const Layout = (props: any) => {
  const { BlockUI, UnBlockUI } = FnBlock_UI();
  const [lstMenu, setlstMenu] = useState([]);
  const [IsExpired, setIsExpired] = useState(true);
  const [profile, setProfile] = useState([]);
  const [comName, setComName] = useState("" as any);
  const [comNameFire, setComNameFire] =  useState("" as any);

  useEffect(() => {
    setMenu();
  }, []);

  const setMenu = async () => {
    let res = await onLoadMenu();
    let resName = await onPageLoad();
    let resSetting = await onGetSetting();
    setProfile(resName || {});
    setlstMenu(res.lstMenu || []);
    setIsExpired(res.IsExpired);
    setComName(resSetting.lstAddressOBA);
    setComNameFire(resSetting.lstAddressFire);
  };

  const onGetSetting = async () => {
    let result = {} as any;
    await Axios(
      `Master/GetSettingContact`,
      {},
      "Get",
      async (res) => {
        result = res;
      },
      null,
      BlockUI,
      UnBlockUI,
      () => {}
    );
    return result;
  };

  const onLoadMenu = async () => {
    let result = {} as any;
    await Axios(
      `Master/GetMenuMaster`,
      {},
      "Get",
      async (res) => {
        result = res;
      },
      null,
      BlockUI,
      UnBlockUI,
      () => {}
    );
    return result;
  };

  const onPageLoad = async () => {
    let result = {} as any;
    await Axios(
      `Master/onPageLoad`,
      {},
      "Get",
      async (res) => {
        result = res;
      },
      null,
      BlockUI,
      UnBlockUI,
      () => {}
    );
    return result;
  };

  return (
    <>
      {/* <Box sx={{overflowX:"hidden"}}> */}
      <LayoutHead
        lstMenu={lstMenu}
        menuID={props.menuID}
        hMenuID={props.hMenuID}
        showMenu={props.propsLayout.showMenu}
        onDisClickLogo={props.propsLayout.onDisClickLogo}
        IsExpired={IsExpired}
        profile={profile}
        comName={comName}
        showComName={props.propsLayout.showComName}       
      />
      {props.propsLayout.showPageDown && (
        <ScrollToBottom>
          <Fab
            color="error"
            size="large"
            // aria-label="scroll back to top"
            sx={{
              flexDirection: "column",
              borderRadius: "16px",
              width: "35px",
              height: "70px",
              textAlign: "center",
              lineHeight: "1",
              fontSize: "10px",
            }}
          >
            <HiOutlineArrowNarrowDown size="25" />
            DOWN
          </Fab>
          {/* <div
            className="containerDown"
            style={{
              display: props.propsLayout.showPageDown ? "flex" : "none",
            }}
          >
            <div className="chevronDown"></div>
            <div className="chevronDown"></div>
            <div className="chevronDown"></div>
            <span className="textDown">DOWN</span>
          </div> */}
        </ScrollToBottom>
      )}
      <Box
        sx={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          // paddingTop:10,
          // height: "92vh",
          // position:"static"
          // minHeight:"70vh",
        }}
      >
        {props.children}
      </Box>
      {/* <Box bgcolor="rgb(214,219,223)" height="90vh"></Box>
      <Box bgcolor="rgb(213,245,227)" height="90vh"></Box> */}
      {props.propsLayout.showfooter && <LayoutFooter lstMenu={lstMenu} comName={comName} comNameFire={comNameFire}  />}

      {props.propsLayout.showPageUp && (
        <ScrollToTop>
          <Fab
            color="primary"
            size="large"
            // aria-label="scroll back to top"
            sx={{
              flexDirection: "column",
              borderRadius: "16px",
              width: "35px",
              height: "70px",
              textAlign: "center",
              lineHeight: "1",
              fontSize: "13px",
            }}
          >
            <HiOutlineArrowNarrowUp size="25" />
            UP
          </Fab>
          {/* <div
            className="containerUp"
            style={{
              display: props.propsLayout.showPageDown ? "flex" : "none",
            }}
          >
            <div className="chevronUp"></div>
            <div className="chevronUp"></div>
            <div className="chevronUp"></div>
            <span className="textUp">UP</span>
          </div> */}
        </ScrollToTop>
      )}
      {/* </Box> */}
    </>
  );
};

export default Layout;
