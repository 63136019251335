import React, { useEffect, useState } from "react";
import { Axios } from "src/CommonFunction/TS_Axios";
import BlockUI1, { FnBlock_UI } from "src/Components/BlockUI";
import { Grid, Box, Typography, Card, Button } from "@mui/material";
import { FormProvider, useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import yupFormSchemas from "src/Components/FormItem/yup/yupFormSchemas";
import { GridColumns } from "@mui/x-data-grid-pro";
import { DataGridMui as Table, initRows, PaginationInterface } from "src/Components/Table/DataGridMui";
import { InputForm } from "src/Components/FormItem/Input";
import { BtnBack, BtnCustomText, BtnDetail, BtnCustomSearch, BtnDetailOnForm, BtnEditOnForm, BtnAddFileOnTable } from "src/Components/Button";
import AutoCompleteSelect from "src/Components/FormItem/AutoCompleteSelect";
import { SelectForm } from "src/Components/FormItem/Select";
import FileUploadIcon from "@mui/icons-material/FileUpload";
import FileDialog from "./DialogTable/fileDialog";
import SearchIcon from "@mui/icons-material/Search";
import EditIcon from "@mui/icons-material/Edit";
import { useNavigate } from "react-router-dom";
import { ParseHtml } from "src/CommonFunction/TS_function";

export default function HistoryRegisterCourse() {
  const navigate = useNavigate();
  const { BlockUI, UnBlockUI } = FnBlock_UI();
  const [open, setOpen] = useState(false);
  const [regID, setRegID] = useState("");
  const [arrYears, setsarrYears] = useState("");
  const [isFirst, setFirstLoad] = useState(true);

  const objSchema = {};
  const schema = yup.object().shape(objSchema);
  const form = useForm({
    mode: "all",
    resolver: yupResolver(schema),
    shouldFocusError: true,
  });

  const [dataRow, setDataRow] = useState<PaginationInterface>({
    ...initRows,
  });

  // const arrYears = [
  //   { value: "1", label: "2565" },
  //   { value: "2", label: "2564" },
  //   { value: "3", label: "2563" },
  // ];

  const arrStatus = [
    { value: "0", label: "รอชำระเงิน" },
    { value: "1", label: "รอยืนยันเข้าอบรม" },
    { value: "2", label: "รอเข้าอบรม" },
    { value: "M", label: "เลื่อนการอบรม" },
    { value: "3", label: "สิ้นสุดการอบรม" },
    { value: "C", label: "ยกเลิกลงทะเบียน" },
    { value: "W", label: "ลงทะเบียนรอคิวเข้าอบรม" },
  ];

  const routeChange = (path, sRegID) => {
    navigate(process.env.REACT_APP_API_URL + path + `?str=${sRegID}`);
  };

  const dataColumn: GridColumns = [
    {
      field: "nRow",
      headerName: "ที่",
      headerAlign: "center",
      editable: false,
      width: 100,
      resizable: true,
      filterable: false,
      align: "center",
      sortable: false,
    },
    {
      field: "sCourseName",
      headerName: "หลักสูตร",
      headerAlign: "center",
      align: "left",
      flex: 1,
      resizable: true,
      minWidth: 300,
    },
    {
      field: "nEmp",
      headerName: "ผู้เข้าอบรม(คน)",
      headerAlign: "center",
      align: "center",
      resizable: true,
      width: 150,
    },
    {
      field: "sTrainDateBegin",
      headerName: "วันที่อบรม",
      headerAlign: "center",
      align: "center",
      resizable: true,
      minWidth: 200,
      sortable: false,
    },
    {
      field: "sStatus",
      headerName: "สถานะ",
      headerAlign: "center",
      align: "center",
      resizable: true,
      minWidth: 250,
    },
    {
      field: "sRemark",
      headerName: "หมายเหตุ",
      headerAlign: "center",
      align: "center",
      resizable: true,
      minWidth: 250,     
      renderCell: (item) => {
        var txt = item.value ? <>{ParseHtml(item.value)}</> : ""
        return (
            <div style={{textAlign:"center"}}>{txt}</div>
        );
      },
      
    },
    {
      renderHeader: (item) => "",
      field: "Button (Add/Edit)",
      type: "actions",
      width: 120,
      resizable: false,
      sortable: false,
      getActions: (item) => {
        
        return (item.row.cStatus === "0")
          ? [
            <BtnAddFileOnTable
              onClick={() => showFileDialog(item.row)}
              sx={{
                backgroundColor: item.row.isHasFile ? "#0a8f08" : "#d01716",
                color: "white",
                ":hover": { bgcolor: item.row.isHasFile ? "#0a7e07" : "#b0120a" },
              }}
            />,
            <BtnEditOnForm txt="แก้ไข" onClick={() => routeChange("ClassroomRegister", item.row.sRegID_Encrypt)} />,
          ]
          : (item.row.cStatus === "1")
            ? [
              <BtnAddFileOnTable
                onClick={() => showFileDialog(item.row)}
                sx={{
                  backgroundColor: item.row.isHasFile ? "#0a8f08" : "#d01716",
                  color: "white",
                  ":hover": { bgcolor: item.row.isHasFile ? "#0a7e07" : "#b0120a" },
                }}
              />,
              <BtnDetailOnForm onClick={() => routeChange("ClassroomRegister", item.row.sRegID_Encrypt)} />,
            ]
            : item.row.sStatus == "ลงทะเบียนรอคิวเข้าอบรม"
              ? [
                <BtnEditOnForm txt="แก้ไข" onClick={() => routeChange("Classroom_RegisterWaiting", item.row.sRegID_Encrypt)} />
              ]
              :
              [<BtnDetailOnForm onClick={() => routeChange("ClassroomRegister", item.row.sRegID_Encrypt)} />];
      },
    },
  ];

  const showFileDialog = (item) => {
    setOpen(true);
    setRegID(item.sRegID_Encrypt);
  };

  useEffect(() => {
    loadData(dataRow);
  }, []);

  const loadData = async (p: PaginationInterface) => {
    var Years = {
      value: "",
      label: "",
    };
    var Status = {
      value: "",
      label: "",
    };
    var year = "";
    if (form.getValues("sYears") != undefined) {
      Years = form.getValues("sYears");
      year = (parseInt(Years.label) - 543).toString();
    }
    if (form.getValues("sStatus") != undefined) {
      Status = form.getValues("sStatus");
      console.log("Status", Status.value);
    }
    var param = {
      nPageSize: p.nPageSize || 10,
      nPageIndex: p.nPageIndex || 1,
      sSortExpression: p.sSortExpression || "",
      sSortDirection: p.sSortDirection.toUpperCase() || "",
      sNameSearch: form.getValues("sCourseName") || "",
      sYearSearch: year || "",
      sStatus: Status.value || "",
    };
    let result = {} as any;
    await Axios(
      `HistoryPage/GetData_HistoryRegisCourse2`,
      param || {},
      "Post",
      async (res) => {
        result = res;
        setDataRow({
          ...p,
          arrRows: res.lstdata,
          nDataLength: res.nDataLength === 0 ? res.nDataLength : (res.nDataLength > 10 ? res.nDataLength : 10),
          nPageIndex: res.nPageIndex,
        });
        if(isFirst){
        setsarrYears(res.lstYears);
        }
        setFirstLoad(false)
      },
      null,
      BlockUI,
      UnBlockUI,
      null
    );
    return result;
  };
  return (
    <FormProvider {...form}>
      <FileDialog open={open} handleClose={() => {setOpen(false);loadData(dataRow);} } sRegID={regID} />
      <Grid container flexWrap={"wrap"} sx={{ px:"1em"}}>
        <Grid item xs={12} sx={{ mb: "1em" ,display:"flex",justifyContent:"flex-end",flexDirection:{xs:"column",lg:"row"}}}>
          <Grid item xs={12} lg={2.5} sx={{ px: { lg: "0.5em" } }}>
            <InputForm name="sCourseName" label="ค้นหาจากชื่อหลักสูตร" fullWidth mx={0} />
          </Grid>
          <Grid item xs={12} lg={2.5} sx={{ px: { lg: "0.5em" } }}>
            <AutoCompleteSelect name={"sYears"} width={{ width: "100%" }} mx={0} options={arrYears || []} label={"ปีที่ลงทะเบียน"} />
          </Grid>
          <Grid item xs={12} lg={2.5} sx={{ px: { lg: "0.5em" } }}>
            <AutoCompleteSelect name={"sStatus"} width={{ width: "100%" }} mx={0} options={arrStatus || []} label={"สถานะ"} />
          </Grid>
          {/* <Grid item container md={1} xs={12} justifyContent={"center"}>
          <BtnCustomSearch txt="ค้นหา" isCircleWithOutText onClick={() => (loadData(dataRow))}/>
        </Grid> */}
          <Grid item xs={12} lg={1} sx={{  justifyContent: "center" }}>
            <BtnCustomText txt="ค้นหา/Search" onClick={() => loadData(dataRow)} />
          </Grid>
        </Grid>
        <Grid item xs={12}>
          <Table
            HiddenToolHead
            Rows={dataRow}
            Columns={dataColumn}
            OnLoadData={(p) => loadData(p)}
            backgroundHeaderCustom={"#0a286c"}
            sxCustomHeader={{ color: "#FFF" }}
            
          />
        </Grid>
      </Grid>
    </FormProvider>
  );
}
