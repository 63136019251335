import {Grid} from "@mui/material";
import { useEffect, useState } from "react";
import { Axios } from "src/CommonFunction/TS_Axios";
import 'src/Components/Register/Register.css';
import PicMission from "../../Layout/img/Mission.png";
import PicVision from "../../Layout/img/Vision.png";
import sCover from "src/Layout/img/vision-cover.jpg";
import { ParseHtml } from "src/CommonFunction/TS_function";
import ReactPlayer from "react-player";
import { BreadcrumbsUI } from "../BreadcrumbsUI";
import { Banner } from "../Banner";
import { FnBlock_UI } from "../BlockUI";
const VisionPage = () => {
    const [banner, setBanner] = useState([] as any);
    const [Data, setData] = useState({} as any);
    const [DataMission, setDataMission] = useState({} as any);
    const { BlockUI, UnBlockUI } = FnBlock_UI();

    useEffect(() => {
        OnGetData();
    }, []);

    const OnGetData = async () => {
        let result = [] as any;
        result.push({
          sPath: sCover,
        });
        setBanner(result);
        await Axios(
          `VisionPage/GetData_Vision`,
          {},
          "GET",
          async (res) => {
            setData(res.lstVisionPage || []);
            setDataMission(res.lstMissionPage || []);
          },
          null,
          BlockUI,
          UnBlockUI,
          () => {}
        );
    };

    return (
      <Grid container>
        <Grid item xs={12}>
          {/* <Banner sImg={sCover} sTitleTH="วิสัยทัศน์และพันธกิจ" /> */}
          <Banner
            dataSource={banner || []}
            height={50}
            textTH="วิสัยทัศย์และพันธกิจ"
            textENG="Vision & Mission"
          />
        </Grid>
        <BreadcrumbsUI
          isShowHome={false}
          items={[
            {
              Key: "1",
              menuName: "เกี่ยวกับเรา",
              IsOnClick: false,
            },
            {
              Key: "2",
              menuName: "วิสัยทัศน์และพันธกิจ",
              IsOnClick: false,
            },
          ]}
        />

        <Grid container sx={{ backgroundColor: "#F5F5F5" }}>
          <Grid item xs={12} md={6}>
            <Grid container sx={{ marginLeft: 1 }}>
              {(DataMission
                ? DataMission.length > 0
                  ? DataMission
                  : []
                : []
              ).map((m) => {
                if (m.nContentTypeID == 1) {
                  return (
                    <Grid item xs={12} sx={{ marginBottom: "3%" }}>
                      <p
                        className="sContentHTML"
                        style={{
                          whiteSpace: "normal",
                          textOverflow: "ellipsis",
                        }}
                      >
                        {ParseHtml(m.sContentHTML)}
                      </p>
                    </Grid>
                  );
                } else if (m.nContentTypeID == 2) {
                  return (
                    <Grid item xs={12} sx={{ marginBottom: "3%" }}>
                      <img
                        src={m.sURLImage}
                        style={{
                          height: "auto",
                          width: "95%",
                        }}
                      />
                      <p
                        className="sDesc"
                        style={{
                          whiteSpace: "normal",
                          textOverflow: "ellipsis",
                          color: "#A0A0A0",
                        }}
                      >
                        {m.sDesc}
                      </p>
                    </Grid>
                  );
                } else if (m.nContentTypeID == 3) {
                  return (
                    <Grid item xs={12}>
                      <ReactPlayer
                        url={m.sURL}
                        width="100%"
                        height="400px"
                        muted
                        controls={true}
                      />

                      <p
                        className="sDesc"
                        style={{
                          whiteSpace: "normal",
                          textOverflow: "ellipsis",
                          color: "#A0A0A0",
                        }}
                      >
                        {m.sDesc}
                      </p>
                    </Grid>
                  );
                } else if (m.nContentTypeID == 4) {
                  console.log("sURL", m.sURL);
                  return (
                    <Grid item xs={12}>
                      <ReactPlayer
                        url={m.sURL}
                        width="100%"
                        height="400px"
                        muted
                        controls={true}
                      />
                      <p
                        className="sDesc"
                        style={{
                          whiteSpace: "normal",
                          textOverflow: "ellipsis",
                          color: "#A0A0A0",
                        }}
                      >
                        {m.sDesc}
                      </p>
                    </Grid>
                  );
                } else {
                  return null;
                }
              })}
            </Grid>
          </Grid>
          <Grid item xs={12} md={6}>
            <img src={PicMission} style={{ width: "100%", height: "100" }} />
          </Grid>
          <Grid item xs={12} md={6}>
            <img src={PicVision} style={{ width: "100%", height: "100" }} />
          </Grid>
          <Grid item xs={12} md={6}>
            <Grid container sx={{ marginLeft: 1 }}>
              {(Data ? (Data.length > 0 ? Data : []) : []).map((item) => {
                if (item.nContentTypeID == 1) {
                  return (
                    <Grid item xs={12} sx={{ marginBottom: "3%" }}>
                      <p
                        className="sContentHTML"
                        style={{
                          whiteSpace: "normal",
                          textOverflow: "ellipsis",
                        }}
                      >
                        {ParseHtml(item.sContentHTML)}
                      </p>
                    </Grid>
                  );
                } else if (item.nContentTypeID == 2) {
                  return (
                    <Grid item xs={12} sx={{ marginBottom: "3%" }}>
                      <img
                        src={item.sURLImage}
                        style={{
                          height: "100",
                          width: "89%",
                        }}
                      />
                      <p
                        className="sDesc"
                        style={{
                          whiteSpace: "normal",
                          textOverflow: "ellipsis",
                          color: "#A0A0A0",
                        }}
                      >
                        {item.sDesc}
                      </p>
                    </Grid>
                  );
                } else if (item.nContentTypeID == 3) {
                  return (
                    <Grid item xs={12}>
                      <ReactPlayer
                        url={item.sURL}
                        width="100%"
                        height="400px"
                        muted
                        controls={true}
                      />
                      <p
                        className="sDesc"
                        style={{
                          whiteSpace: "normal",
                          textOverflow: "ellipsis",
                          color: "#A0A0A0",
                        }}
                      >
                        {item.sDesc}
                      </p>
                    </Grid>
                  );
                } else if (item.nContentTypeID == 4) {
                 
                  return (
                    <Grid item xs={12}>
                      <ReactPlayer
                        url={item.sURL}
                        width="100%"
                        height="400px"
                        muted
                        controls={true}
                      />
                      <p
                        className="sDesc"
                        style={{
                          whiteSpace: "normal",
                          textOverflow: "ellipsis",
                          color: "#A0A0A0",
                        }}
                      >
                        {item.sDesc}
                      </p>
                    </Grid>
                  );
                } else {
                  return null;
                }
              })}
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    );
}
export default VisionPage


