import React, { useEffect, useState } from "react";
import NumberFormat from "react-number-format";
import { Controller, useFormContext, useWatch } from "react-hook-form";
import TextField from "@mui/material/TextField";
import { InputForm } from 'src/Components/FormItem/Input'
import InputAdornment from "@mui/material/InputAdornment";
import FormErrors from "../formErrors";
import { SxProps } from "@mui/material";
const fnParseFloat = (value: string) => {
  const IsNumberic = (sVal) => {
    sVal = (sVal + "").replace(/,/g, "");
    return !isNaN(sVal) && sVal !== "";
  };

  value = (value + "").replace(/ /g, "").replace(/,/g, "");
  if (IsNumberic(value)) {
    return parseFloat(value);
  } else {
    return null;
  }
};

function NumberFormatCustom(props) {
  const { inputRef, onChange, name, customInput, error, helperText, label, ...other } = props;
  var xvalue = props.value;

  if (other.type === "number") {
    if (xvalue != undefined && xvalue != "") {
      var t = xvalue + "";
      var splitted = t.replace(/,/g, "").split(".");
      var c = t.search("-");
      var d = "";

      if (splitted.length > 0) {
        d = splitted[0].substring(0, other.integerDigits + (c + 1));

        if (splitted.length > 1) {
          d = d + "." + splitted[1].substring(0, other.digits);
          d = (+d).toFixed(other.digits);
        } else {
          if (other.digits > 0) {
            d = (+d).toFixed(other.digits);
          }
        }
      }
      xvalue = d;
    }
  }
  return (
    <NumberFormat
      label={label}
      customInput={TextField}

      ref={props.ref}
      name={props.name}
      onBlur={props.onBlur}
      sx={other.sx}
      type={"text"}
      value={xvalue}
      getInputRef={inputRef}
      onValueChange={(values) => {
        onChange({
          target: {
            name: props.name,
            value:
              other.valuetype === "number"
                ? fnParseFloat(values.value)
                : values.value,
            formattedValue: values.formattedValue || "",
          },
        });
      }}
      thousandSeparator={other.thousandSeparator}
      isNumericString
      fixedDecimalScale
      allowNegative={other.allowMinus}
      placeholder={props.placeholder || ""}
      disabled={other.disabled}
      allowLeadingZeros={props.allowLeadingZeros}
      error={error}
      helperText={helperText}
      fullWidth={other.fullWidth || false}
      required={other.required || false}
      InputLabelProps={{
        shrink: other.shrink,
      }}
      size={props.size || "small"}
      variant={other.variant || "outlined"}
      InputProps={other.InputProps}
      autoComplete={"disabled"}
    />
  );
}
/**
 * 
 * @param props 
 * @returns 
 */
export const InputNumberForm = (props: InputNumberProp) => {
  const {
    name,
    label,
    integerDigits = 14,
    digits = 2,
    allowMinus = true,
    externalErrorMessage,
    required,
    disabled,
    textAlign = "end",
    defaultValues = "",
    type = "number",
    format = "",
    mask,
    valuetype = "text",
    thousandSeparator = true,
    variant,
    maxLength,
    allowLeadingZeros = false,
    placeholder,
    suffix,
    helperText,
    ...other
  } = props;

  const {
    register,
    setValue,
    control,
    watch,
    formState: { touchedFields, errors, isSubmitted },
  } = useFormContext();

  const errorMessage = FormErrors.errorMessage(
    name,
    errors,
    touchedFields,
    isSubmitted,
    externalErrorMessage
  );

  const handleChange = (event) => {
    setValueText(event.target.value)
    setValue(
      name,
      valuetype === "number"
        ? fnParseFloat(event.target.value)
        : event.target.formattedValue,
      { shouldValidate: true }
    );
    props.onChange && props.onChange(event);
  };
  const [sValueText, setValueText] = useState<string>(watch(name));
  const maxLengthText: SxProps = {
    "::after": {
      content: maxLength ? `"${sValueText ? sValueText.length : 0}/${maxLength}"` : '""',
      position: "absolute",
      bottom: "0px",
      right: "10px",
      color: "#989898",
      fontSize: "10px",
    },
  };
  const FieldName = useWatch({
    control,
    name: name,
  });
  const [Focus, SetFocus] = useState(false);

  useEffect(() => { }, [watch(name)]);

  return (
    <Controller
      control={control}
      name={name}
      rules={{ required: required }}
      render={({ field }) => (
        <NumberFormatCustom
          {...field}
          label={label}
          textAlign={textAlign}
          digits={digits}
          integerDigits={integerDigits}
          allowMinus={allowMinus}
          type={type}
          format={format}
          mask={mask}
          thousandSeparator={thousandSeparator}
          valuetype={valuetype}
          placeholder={placeholder}
          value={
            watch(name) != null && watch(name) != undefined
              ? watch(name)
              : defaultValues
          }
          allowLeadingZeros={allowLeadingZeros}
          onChange={handleChange}
          onBlur={(e) => {
            field.onBlur();
            SetFocus(false);
            props.onBlur && props.onBlur(e);
          }}
          onFocus={() => {
            SetFocus(true);
          }}
          sx={[
            { ...maxLengthText },
            { "& input": { textAlign: textAlign } }, { m: 1 },
            {
              "label.MuiInputLabel-shrink": {
                // backgroundColor: "rgba(255,255,255,0.95)", //ปิดไว้ก่อน Background ตรงหัวชื่อ Field มีพื้นหลังสีขาวขึ้นมา
                top: "0px",
              },
              ".MuiInputLabel-outlined": {
                top: "0px",
              },
              ".MuiInputLabel-asterisk": {
                color: "red"
              }
            }]}
          InputProps={{
            startAdornment: other.prefix ? (
              <InputAdornment position="start">{props.prefix}</InputAdornment>
            ) : undefined,
            endAdornment: <InputAdornment position="end">{props.suffix}</InputAdornment>,
          }}
          inputProps={{
            name,
            maxLength: maxLength ? maxLength : null,
            style: { textAlign: textAlign },
            config: {
              digits: digits,
              integerDigits: integerDigits,
              allowMinus: allowMinus,
              type: type,
              format: format,
              mask: mask,
              thousandSeparator: thousandSeparator,
              valuetype: valuetype,
              maxLength: maxLength,
              disabled: { disabled }
            }
          }}
          disabled={disabled || false}
          fullWidth={other.fullWidth || false}
          required={required || false}
          InputLabelProps={{
           // shrink: other.shrink,
            shrink: FieldName || Focus ? true : other.shrink
          }}
          size={props.size || "small"}
          variant={variant || "outlined"}
          error={!!Boolean(errorMessage)}
          helperText={errorMessage || helperText}
        />
      )}
    />
  );
};

interface InputNumberProp extends InputForm {
  /***
   * จํานวนทศนิยม
   * default 2
   */
  digits?: number;
  /**
   * จํานวนเต็ม
   * default 14
   */
  integerDigits?: number;
  /***
   * อนุญาตตัวเลขติดลบ
   * default true
   */
  allowMinus?: boolean;
  /**
   * ตำแหน่ง
   * default right
   */
  textAlign?: "inherit" | "left" | "center" | "right" | "justify";
  /**
   * default Values
   */
  defaultValues?: string;
  /**
   * type
   */
  type?: "number";
  /**
   * รูปแบบ
   */
  format?: "#### ##" | "## ##" | "(###) ###-####" | any;
  /**
   * หากกำหนดรูปแบบไว้ ส่วนประกอบจะแสดงว่าไม่ได้ป้อนด้วยค่าที่ปิดบัง
   */
  mask?: "_" | any;
  /**
   * หากระบุจะส่งค่า่กับตามที่เลือก
   */
  valuetype?: "number" | "text";
  /**
   * เพิ่มตัวคั่นหลักพันบนตัวเลข
   * default true
   */
  thousandSeparator?: boolean;
  /**
   * จำนวนที่กรอกได้กี่หลัก
   */
  maxLength?: number;
  /**
   * อนุญาตเลขศูนย์นำหน้าตอนต้นของตัวเลข
   * default false
   */
  allowLeadingZeros?: boolean;
}

export default InputNumberForm;
