import { yupResolver } from "@hookform/resolvers/yup";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router";
import { FnBlock_UI } from "../BlockUI";
import * as yup from "yup";
import { Axios } from "src/CommonFunction/TS_Axios";
import { FormProvider, useForm } from "react-hook-form";
import { Box, Grid, TextareaAutosize } from "@mui/material";
import { BreadcrumbsUI } from "../BreadcrumbsUI";
import { DataGridMui, initRows, PaginationInterface } from "../Table/DataGridMui";
import { GridColumns } from '@mui/x-data-grid';
import { BtnAddOnTable, BtnEditOnTable, BtnSaveForm, BtnSearch, BtnSendForm } from "../Button";
import { Process_System, ResultAPI } from "src/CommonFunction/TS_function";
import { AlertMsg, AlertTitle, DialogConfirmDelete, SwAlert } from "src/Components/Alert/Sweetalert";
import { InputForm } from "../FormItem/Input";
import RadioFormItem from "../FormItem/RadioFormItem/RadioIFormtem";
import AutoCompleteSelect from "../FormItem/AutoCompleteSelect";
import ListAltRoundedIcon from '@mui/icons-material/ListAltRounded';
import Cards from "../CardHeader/Cards";
import { margin } from "@mui/system";
import "./questionnaire.css";
import yupFormSchemas from "src/Components/FormItem/yup/yupFormSchemas";



const Questionnaire = () => {

    const [arrQuestionnair, setQuestionnair] = useState({});
    const [objSchema, setSchema] = useState({});
    const schema = yup.object().shape(objSchema);


    const form = useForm({
        resolver: yupResolver(schema),
        shouldUnregister: false,
        shouldFocusError: true,
        mode: "all"
    });

    const arrScore = [
        { "value": "4", "label": "ดีมาก" },
        { "value": "3", "label": "ดี" },
        { "value": "2", "label": "พอใช้" },
        { "value": "1", "label": "ควรปรับปรุง" }
    ]

    useEffect(() => {
        onCreateschema(loadData())
    }, [])

    useEffect(() => {
        setQuestionnair(loadData())
    }, [objSchema])

    const loadData = () => {
        return {
            "detail": {
                "sCourseName": "0023 - ทดสอบหลักสูตร",
                "sClass": "2565/1",
                "sDate": "30/07/2565 - 31/07/2565 (2 วัน)",
                "sPlace": "สำนักงานพระโขนง ห้องประชุม 11"
            },
            "questionnaire": {
                "sNameForm": "ชัยวัฒน์ ผลลูกอินทร์",
                "arrQuestion": [
                    {
                        "topic": "1. ด้านเนื้อหา",
                        "score": [],
                        "data": [
                            {
                                "topic": "1.1) 1.1",
                                "score": [
                                    { "value": "4", "label": "ดีมาก", "selected": false },
                                    { "value": "3", "label": "ดี", "selected": false },
                                    { "value": "2", "label": "พอใช้", "selected": false },
                                    { "value": "1", "label": "ควรปรับปรุง", "selected": false }
                                ]
                            },
                            {
                                "topic": "1.2) 1.2",
                                "score": [
                                    { "value": "4", "label": "ดีมาก", "selected": false },
                                    { "value": "3", "label": "ดี", "selected": false },
                                    { "value": "2", "label": "พอใช้", "selected": false },
                                    { "value": "1", "label": "ควรปรับปรุง", "selected": false }
                                ]
                            },
                            {
                                "topic": "1.3) 1.3",
                                "score": [
                                    { "value": "4", "label": "ดีมาก", "selected": false },
                                    { "value": "3", "label": "ดี", "selected": false },
                                    { "value": "2", "label": "พอใช้", "selected": false },
                                    { "value": "1", "label": "ควรปรับปรุง", "selected": false }
                                ]
                            }
                        ]
                    },
                    {
                        "topic": "2. ด้านวิทยากร",
                        "score": [],
                        "data": [
                            {
                                "topic": "2.1) 2.1",
                                "score": [
                                    { "value": "4", "label": "ดีมาก", "selected": false },
                                    { "value": "3", "label": "ดี", "selected": false },
                                    { "value": "2", "label": "พอใช้", "selected": false },
                                    { "value": "1", "label": "ควรปรับปรุง", "selected": false }
                                ]
                            },
                            {
                                "topic": "2.2) 2.2",
                                "score": [
                                    { "value": "4", "label": "ดีมาก", "selected": false },
                                    { "value": "3", "label": "ดี", "selected": false },
                                    { "value": "2", "label": "พอใช้", "selected": false },
                                    { "value": "1", "label": "ควรปรับปรุง", "selected": false }
                                ]
                            }
                        ]
                    },
                    {
                        "topic": "3. ด้านสถานที่",
                        "score": [
                            { "value": "4", "label": "ดีมาก", "selected": false },
                            { "value": "3", "label": "ดี", "selected": false },
                            { "value": "2", "label": "พอใช้", "selected": false },
                            { "value": "1", "label": "ควรปรับปรุง", "selected": false }
                        ],
                        "data": []
                    }
                ]
            }
        }
    }


    const onSubmit = (event) => {

        var result = {
            ...arrQuestionnair,
            "comment": event.comment
        }

        console.log(result)
    }

    const handleChange = (event, index, indexField) => {
        var result = arrQuestionnair

        if (indexField == null) {
            var compare = result['questionnaire'].arrQuestion[index]['score'].findIndex(f => f.value == event)
            var toFalse = result['questionnaire'].arrQuestion[index]['score'].findIndex(f => f.selected == true)

            if (toFalse > -1 && compare != toFalse) {
                result['questionnaire'].arrQuestion[index]['score'][toFalse].selected = false
            }

            result['questionnaire'].arrQuestion[index]['score'][compare].selected = true
        } else {
            var compare = result['questionnaire'].arrQuestion[index]['data'][indexField]['score'].findIndex(f => f.value == event)
            var toFalse = result['questionnaire'].arrQuestion[index]['data'][indexField]['score'].findIndex(f => f.selected == true)

            if (toFalse > -1 && compare != toFalse) {
                result['questionnaire'].arrQuestion[index]['data'][indexField]['score'][toFalse].selected = false
            }

            result['questionnaire'].arrQuestion[index]['data'][indexField]['score'][compare].selected = true
        }
        setQuestionnair(result)
    }

    const onCreateschema = (data) => {
        var question = data.questionnaire.arrQuestion
        var schema = {
            ...objSchema
        }
        question.forEach((item, index) => {
            if (item.score.length > 0) {
                var name = `score-${index}`
                schema[name] = yupFormSchemas.string("คำตอบ", { required: true })
            }

            item.data.forEach((element, i) => {
                if (element.score.length > 0) {
                    var name = `score-${index}-${i}`
                    schema[name] = yupFormSchemas.string("คำตอบ", { required: true })
                }
            });
        });
        setSchema({ ...schema })
        form.clearErrors()
    }

    return (
        <FormProvider {...form}>
            <Grid container>
                <Grid item xs={12}>
                    <BreadcrumbsUI
                        isShowHome={false}
                        items={[
                            {
                                icon: <ListAltRoundedIcon />,
                                menuName: "แบบประเมินหลักสูตร",
                                path: process.env.REACT_APP_API_URL + "Questionnaire",
                                IsOnClick: false
                            },

                        ]}
                    />
                </Grid>
                {Object.keys(arrQuestionnair).length > 0 &&
                    <Grid item container spacing={2} xs={12} flex={1} marginX={2} sx={{ m: "4rem", mt: 4 }}>
                        <Grid item xs={12} container sx={{ borderBottom: "1px solid #ccc" }}>
                            <Grid item xs={2}>
                                หลักสูตร
                            </Grid>
                            <Grid item xs={10}>
                                {arrQuestionnair['detail'].sCourseName}
                            </Grid>
                        </Grid>
                        <Grid item xs={12} container sx={{ borderBottom: "1px solid #ccc" }}>
                            <Grid item xs={2}>
                                รุ่นที่
                            </Grid>
                            <Grid item xs={10}>
                                {arrQuestionnair['detail'].sClass}
                            </Grid>
                        </Grid>
                        <Grid item xs={12} container sx={{ borderBottom: "1px solid #ccc" }}>
                            <Grid item xs={2}>
                                วันที่อบรม
                            </Grid>
                            <Grid item xs={10}>
                                {arrQuestionnair['detail'].sDate}
                            </Grid>
                        </Grid>
                        <Grid item xs={12} container sx={{ borderBottom: "1px solid #ccc" }}>
                            <Grid item xs={2}>
                                สถานที่อบรม
                            </Grid>
                            <Grid item xs={10}>
                                {arrQuestionnair['detail'].sPlace}
                            </Grid>
                        </Grid>
                        {/* questionnaire */}

                        {Object.keys(arrQuestionnair['questionnaire']).length > 0 &&
                            <Grid item xs={12} container sx={{ p: "0px !important" }}>
                                <Grid item xs={12} className="questionnaire-title">
                                    <p>ชื่อแบบประเมิน <span style={{ color: "#000" }}>({arrQuestionnair['questionnaire'].sNameForm})</span></p>
                                </Grid>
                                {arrQuestionnair['questionnaire'].arrQuestion.map((item, index) => (
                                    <Grid key={`qn-${index}`} container direction="row" className="questionnaire-detail" justifyContent="center" alignItems="start">
                                        <Grid item container direction="row" className="questionnaire-topic border-bottom" justifyContent={"space-between"} alignItems={"center"} xs={12} sx={{ backgroundColor: item.score.length > 0 ? "#fff" : "#ccc", minHeight: "54px", borderBottom: "1px solid #ccc" }}>
                                            <Grid item>
                                                {item.topic}
                                            </Grid>
                                            {item.score &&
                                                <Grid item>
                                                    <RadioFormItem
                                                        name={`score-${index}`}
                                                        options={item.score}
                                                        value={form.getValues(`score-${index}`)}
                                                        onChange={(e) => handleChange(e, index, null)}
                                                    />
                                                </Grid>
                                            }

                                        </Grid>
                                        {item.data.map((data, i) => (
                                            <Grid key={`lesson-score-${i}`} item container direction="row" className="questionnaire-lesson" xs={12}>
                                                <Grid item container direction="row" className="border-bottom" justifyContent={"space-between"} alignItems={"center"} xs={12}>
                                                    <Grid item className="pd-3">
                                                        {data.topic}
                                                    </Grid>
                                                    <Grid item>
                                                        <RadioFormItem
                                                            name={`score-${index}-${i}`}
                                                            options={data.score}
                                                            value={form.getValues(`score-${index}-${i}`)}
                                                            onChange={(e) => handleChange(e, index, i)}
                                                        />
                                                    </Grid>
                                                </Grid>
                                            </Grid>
                                        ))}
                                    </Grid>
                                ))}
                            </Grid>
                        }
                        {/* comment */}
                        <Grid item xs={12} sx={{ pr: "0px !important", pl: "0px !important" }}>
                            <Grid item xs={12}>
                                <label>ความคิดเห็น / หลักสูตรที่สนใจเพิ่มเติม</label>
                            </Grid>
                            <Grid item xs={12} >
                                <InputForm
                                    name="comment"
                                    rows={7}
                                    fullWidth
                                    multiline
                                    maxLength={1000}
                                    styles={{ margin: 0 }}
                                />
                            </Grid>
                        </Grid>
                        {/* send */}
                        <Grid item xs={12} container justifyContent="right">
                            <Grid item>
                                <BtnSendForm onClick={form.handleSubmit((e) => onSubmit(e))} />
                            </Grid>
                        </Grid>
                    </Grid>
                }
            </Grid >
        </FormProvider >
    );
}
export default Questionnaire