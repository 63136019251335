import { yupResolver } from "@hookform/resolvers/yup";
import { Typography } from "@mui/material";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import { useState, useEffect } from "react";
import { FormProvider, useForm } from "react-hook-form";
import { Axios } from "src/CommonFunction/TS_Axios";
import { FnBlock_UI } from 'src/Components/BlockUI';
import { AccordionUI } from "src/Components/AccordionUI";
import { Banner } from "src/Components/Banner";
import { BreadcrumbsUI } from "src/Components/BreadcrumbsUI";
import { BtnCustomText } from "src/Components/Button";
import { CheckBoxUI } from "src/Components/FormItem/CheckBox";
import { InputForm } from "src/Components/FormItem/Input";
import { NoDataUI } from "src/Components/NoData";
import { ProfileUI } from "src/Components/Profile";
import imgCover from "src/Layout/img/instructor-cover.jpg";
import { GetQueryString, DecryptApi, IsNullOrUndefined } from "src/CommonFunction/TS_function";
import * as yup from "yup";

const instructor = () => {
  const { BlockUI, UnBlockUI } = FnBlock_UI();
  const [banner, setBanner] = useState([] || undefined);
  const [dataInstructor, setDataInstructor] = useState<any[]>([]);
  const [showNodata, setShowNodata] = useState(false);
  const [disTypeIN, setDisTypeIN] = useState(true);
  const [disTypeOUT, setDisTypeOUT] = useState(true);
  const [nCount, setCount] = useState(0);
  const [search, setSearch] = useState("");
  const [Page, setPage] = useState(1); //page
  const sSearch = GetQueryString("s");
  const objSchema = {};
  const schema = yup.object().shape(objSchema);
  const form = useForm({
    mode: "all",
    resolver: yupResolver(schema),
    shouldFocusError: true,
  });
  const GetSearch = async () => {
    if (!IsNullOrUndefined(sSearch)) {
      let sName = await DecryptApi(sSearch);
      setSearch(sName);
      form.setValue("sName", sName);
      setData();
    } else {
      setData();
    }

  }
  useEffect(() => {
    GetSearch();


  }, []);

  const setData = async () => {
    let resBanner = await loadBanner();
    ListData(1);
    setBanner(resBanner);
  };
  const ListData = async (nPage) => {
    let lstType: any = [];
    let sTypeIn: any = form.getValues("typeIN");
    let sTypeOut: any = form.getValues("typeOUT");
    if (sTypeIn.length > 0) lstType.push(sTypeIn[0]);
    if (sTypeOut.length > 0) lstType.push(sTypeOut[0]);
    let param = {
      nPage: nPage,
      sName: form.getValues("sName") || "",
      sCourse: form.getValues("sCourse") || "",
      sOrganization: form.getValues("sOrganization") || "",
      sUnitabbr: form.getValues("sUnitabbr") || "",
      sType: lstType || [],
      sKeyword: form.getValues("sKeyword") || "",
    };
    
    let resInstructor = await loadInstructor(param);
    setDataInstructor(resInstructor);
  }
  useEffect(() => {
    
    ListData(Page);
  }, [Page]);
  const loadBanner = async () => {
    let result = [] as any;
    result.push({
      sPath: imgCover,
    });
    return result;
  };

  const loadInstructor = async (param) => {
    // console.log("param", param)
    let result = null as any;
    await Axios(
      `InstructorPage/GetData_Instructor`,
      param || {},
      "post",
      async (res) => {
        result = res.lstInstructorPage;
        setCount(res.nCount);
        if (res.lstInstructorPage === null || res.lstInstructorPage.length === 0) {
          setShowNodata(true);
        } else {
          setShowNodata(false);
        }
      },
      null,
      BlockUI,
      UnBlockUI,
      () => { }
    );
    return result;
  };

  const onChange = (e) => { };

  const onChangeCheckIN = (e) => {
    setDisTypeIN(!disTypeIN);
    form.setValue("sOrganization", "");
  };

  const onChangeCheckOUT = (e) => {
    setDisTypeOUT(!disTypeOUT);
    form.setValue("sUnitabbr", "");
  };

  const onClcikSearch = async () => {
    let lstType: any = [];
    let sTypeIn: any = form.getValues("typeIN");
    let sTypeOut: any = form.getValues("typeOUT");
    if (sTypeIn.length > 0) lstType.push(sTypeIn[0]);
    if (sTypeOut.length > 0) lstType.push(sTypeOut[0]);
    setPage(1);
    let param = {
      nPage: 1,
      sName: form.getValues("sName") || "",
      sCourse: form.getValues("sCourse") || "",
      sOrganization: form.getValues("sOrganization") || "",
      sUnitabbr: form.getValues("sUnitabbr") || "",
      sType: lstType || [],
      sKeyword: form.getValues("sKeyword") || "",
    };
    let resIns = await loadInstructor(param);
    setDataInstructor(resIns);
  };

  return (
    <FormProvider {...form}>
      {/* <form> */}
      <Grid container>
        <Grid item xs={12}>
          <Banner
            dataSource={banner || []}
            height={50}
            textTH="ทำเนียบวิทยากร"
            textENG="Instructor"
          />
        </Grid>
        <Grid item xs={12}>
          <BreadcrumbsUI
            isShowHome={false}
            items={[
              {
                Key: "1",
                menuName: "เกี่ยวกับเรา /About Us",
                IsOnClick: false,
              },
              {
                menuName: "ทำเนียบวิทยากร/Instructor",
                Key: "3",
                IsonClick: false,
              },
            ]}
          />
        </Grid>
        <Grid sx={{ my: "1rem", mx: { xs: "2em", md: "4rem" } }} xs={12} item>
          <AccordionUI headerName="ตัวกรอง/Filter" defaultExpand>
            <Grid container columnSpacing={10}>
              <Grid xs={12} md={6} item>
                <InputForm
                  name="sName"
                  label="ค้นหาโดยข้อมูลวิทยากร"
                  placeholder="ชื่อ,นามสกุล,คำค้นหา"
                  onChange={onChange}
                  fullWidth
                />
              </Grid>
              <Grid xs={12} md={6} item>
                <InputForm
                  name="sCourse"
                  label="ค้นหาโดยข้อมูลหลักสูตร"
                  placeholder="รหัสหลักสูตร,ชื่อหลักสูตร"
                  onChange={onChange}
                  fullWidth
                />
              </Grid>
              <Grid xs={12} item>
                <InputForm
                  name="sKeyword"
                  label="ค้นหาโดยคำสำคัญ/Keyword"
                  placeholder="คำสำคัญ/Keyword"
                  onChange={onChange}
                  fullWidth
                />
              </Grid>
              <Grid xs={12} item sx={{ display: "flex", flexDirection: { xs: "column", sm: "row" } }}>
                <Grid xs={12} sm={2} lg={2} xl={1.5} item>
                  <CheckBoxUI
                    name="typeIN"
                    type="group"
                    label="ประเภทวิทยากร"
                    // AllowCheckAll
                    options={[
                      { label: "ภายใน", value: "I" },
                    ]}
                    onChange={onChangeCheckIN}
                  />
                </Grid>
                <Grid xs={12} sm={10} lg={10} xl={10.5} item sx={{ mt: { xs: "0px", sm: "23px" } }}>
                  <InputForm
                    name="sOrganization"
                    label="ชื่อหน่วยงาน"
                    placeholder="ชื่อหน่วยงาน"
                    disabled={disTypeIN}
                    onChange={onChange}
                    fullWidth
                  />
                </Grid>
              </Grid>
              <Grid xs={12} item sx={{ display: "flex", flexDirection: { xs: "column", sm: "row" } }}>
                <Grid xs={12} sm={2} lg={2} xl={1.5} item>
                  <CheckBoxUI
                    name="typeOUT"
                    type="group"
                    options={[
                      { label: "ภายนอก", value: "O" },
                    ]}
                    onChange={onChangeCheckOUT}
                  />
                </Grid>
                <Grid xs={12} sm={10} lg={10} xl={10.5} item >
                  <InputForm
                    name="sUnitabbr"
                    label="ชื่อสังกัด"
                    placeholder="ชื่อสังกัด"
                    disabled={disTypeOUT}
                    onChange={onChange}
                    fullWidth
                  />
                </Grid>
              </Grid>
            </Grid>
            <Grid xs={12} item sx={{ display: "flex", flexDirection: { xs: "column", sm: "row" }, justifyContent: "center" }}>
              <Grid item sx={{ textAlign: "center", paddingLeft: "1em" }}>
                <BtnCustomText
                  txt="ค้นหา / Search"
                  onClick={onClcikSearch}
                />
              </Grid>
            </Grid>
          </AccordionUI>
        </Grid>
        <Grid xs={12} item sx={{ mx: { xs: 0, lg: "4rem" } }}>
          <NoDataUI isShow={showNodata} />
          <ProfileUI dataSource={dataInstructor || [{}]} ListData={ListData} nCount={nCount} setPage={setPage} page={Page} />
        </Grid>
      </Grid>
      {/* </form> */}
    </FormProvider>
  );
};

export default instructor;
