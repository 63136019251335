import { Button, Card, Grid, Typography, useMediaQuery } from '@mui/material';
import React from 'react';
import { useEffect, useState } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { Axios } from 'src/CommonFunction/TS_Axios';
import { FnBlock_UI } from 'src/Components/BlockUI';
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { InputForm } from 'src/Components/FormItem/Input';
import { DataGridMui as Table, initRows, PaginationInterface } from "src/Components/Table/DataGridMui";
import { DateRangePickerUI } from 'src/Components/FormItem/DateRangePicker';
import SelectForm from 'src/Components/FormItem/Select';
import { BtnCustomIcon, BtnCustomSearch, BtnCustomText, BtnSearch } from 'src/Components/Button';
import Search from '@mui/icons-material/Search';
import { GridColumns } from '@mui/x-data-grid-pro';
import AutoCompleteSelect from 'src/Components/FormItem/AutoCompleteSelect';
import { ResultAPI } from 'src/CommonFunction/TS_function';
import yupFormSchemas from 'src/Components/FormItem/yup/yupFormSchemas';
import { DatePickerUI } from 'src/Components/FormItem/DatePicker';
import moment from 'moment';

const HistoryTrainUserLogin = () => {

    const { BlockUI, UnBlockUI } = FnBlock_UI();

    //UseState
    const Sort = [
        { value: "1", label: "ผ่าน" },
        { value: "2", label: "ไม่ผ่าน" },
        { value: "3", label: "รอบันทึกผล" },
    ];
    const matches = useMediaQuery('(min-width:900px)');
    const [StartDate, setStartDate] = useState(undefined);
    const [EndDate, setEndDate] = useState(undefined);
    const [loadingTable, setLoadingTable] = useState(false);
    const [DataRow, setDataRow] = useState<PaginationInterface>({
        ...initRows,
    });

    //Yup
    const objSchema = {
        sCourseSearch: yupFormSchemas.string("ชื่อหลักสูตร", { required: false }),
        sStart: yupFormSchemas.string("วันที่เริ่มต้น", { required: false }),
        sEnd: yupFormSchemas.string("วันที่สิ้นสุด", { required: false }),
        dateRange: yupFormSchemas.stringArray("วันที่เริ่มต้น-สิ้นสุด", { required: false, }),
        cResult: yupFormSchemas.object("ผลการอบรม", { required: false }),
    };
    const schema = yup.object().shape(objSchema);
    const form = useForm({
        mode: "all",
        resolver: yupResolver(schema),
        shouldFocusError: true,
    })

    useEffect(() => {
        let dDateNow = new Date();
        // form.setValue("sStart",dDateNow)  //เซตข้อมูลแต่ไม่เซตฟอร์ม
        // form.setValue("sEnd","dDateNow")  //เซตข้อมูลแต่ไม่เซตฟอร์ม
        loadDataCourse(DataRow);
    }, [])


    const loadDataCourse = async (p: PaginationInterface) => {
        setLoadingTable(true)
        BlockUI();
        let param = {
            ...p,
            sCourseSearch: form.getValues("sCourseSearch") || "",
            sEmpSearch: "",
            sYearBegin: form.getValues("sStart") ? moment(form.getValues("sStart")).format("YYYY") : "",
            sYearEnd: form.getValues("sEnd") ? moment(form.getValues("sEnd")).format("YYYY") : "",
            cResult: form.getValues("cResult") ? form.getValues("cResult").value : "",
        };
        // console.log("param",param)
        await Axios(`HistoryPage/GetData_HistoryTrainingStaff`, param || {}, "Post", async (res) => {
            UnBlockUI();
            ResultAPI(true, res, null, () => {
                setLoadingTable(false)
                setDataRow({
                    ...p,
                    arrRows: res.lstByCourse,
                    nDataLength: res.nDataLength,
                    nPageIndex: res.nPageIndex,
                });
            });
        }, null, BlockUI, UnBlockUI,null);
    };


    const dataColumn: GridColumns = [
        {
            field: "nRow",
            headerName: "ที่",
            headerAlign: "center",
            editable: false,
            width: 100,
            resizable: false,
            filterable: false,
            align: "center",
            disableReorder: true,
            sortable: false,
        },
        {
            field: "sDate",
            headerName: "หลักสูตร",
            headerAlign: "center",
            align: "left",
            flex: 2,
            resizable: false,
            sortable: false,
            disableReorder: true,
            minWidth: 200,
        },
        {
            field: "sTrainDate",
            headerName: "วันที่อบรม",
            headerAlign: "center",
            align: "center",
            flex: 2,
            resizable: false,
            sortable: true,
            disableReorder: true,
            minWidth: 200,
        },
        {
            field: "sGen",
            headerName: "รุ่น",
            headerAlign: "center",
            align: "center",
            flex: 2,
            resizable: false,
            sortable: true,
            disableReorder: true,
            minWidth: 200,
        },
        {
            field: "sResult",
            headerName: "ผลการอบรม",
            headerAlign: "center",
            align: "center",
            flex: 2,
            resizable: false,
            sortable: true,
            disableReorder: true,
            minWidth: 200,
        },
    ];

    return (
        <>
            <FormProvider {...form}>
                <form>
                    <Grid container spacing={2} direction="row" justifyContent="right" alignItems="center">
                        <Grid item xs={12}>
                            <Grid container spacing={2} direction="row" justifyContent="right" alignItems="center">
                                <Grid item xs={12} md={3}>
                                    <InputForm
                                        name="sCourseSearch"
                                        placeholder="ค้นหาจากชื่อหลักสูตร"
                                        showClear
                                        fullWidth
                                    />
                                </Grid>
                                <Grid item xs={12} md={'auto'} style={{ textAlign: matches ? 'right' : 'center' }}>
                                    <span >ตั้งแต่ปี</span>
                                </Grid>
                                <Grid item xs={12} md={2}>
                                    <DatePickerUI
                                        name="sStart"
                                        small={true}
                                        label={'เริ่มต้น'}
                                        format={'yyyy'}
                                        // openTo={'year'}
                                        views={['year']}
                                        onChange={(e) => setStartDate(e)}
                                        maxDate={EndDate ? EndDate : undefined}
                                        onClearFields={(e)=> setStartDate(undefined)}
                                    />
                                </Grid>
                                <Grid item xs={12} md={2}>
                                    <DatePickerUI
                                        name="sEnd"
                                        small={true}
                                        label={'สิ้นสุด'}
                                        format={'yyyy'}
                                        // openTo={'year'}
                                        views={['year']}
                                        onChange={(e) => setEndDate(e)}
                                        minDate={StartDate ? StartDate : undefined}
                                        onClearFields={(e)=> setEndDate(undefined)}
                                    />
                                </Grid>
                                <Grid item xs={12} md={2}>
                                    <AutoCompleteSelect
                                        name={"cResult"}
                                        width={{ width: "100%" }}
                                        options={Sort || []}
                                        label={"ผลการอบรม"}
                                    />
                                </Grid>
                                <Grid item>
                                <BtnCustomSearch txt='ค้นหา/Search' onClick={() => { loadDataCourse(DataRow); }} />
                                </Grid>

                            </Grid>
                        </Grid>
                        <Grid item xs={12}>
                            <Table
                                Rows={DataRow}
                                isLoading={loadingTable}
                                Columns={dataColumn}
                                OnLoadData={(p) => loadDataCourse(p)}
                                HiddenToolHead={true}
                                isShowCheckBox={false}
                                backgroundHeaderCustom={"#0a286c"}
                                sxCustomHeader={{color:"#FFF"}}
                                sxCustomTable={{
                                    ".MuiDataGrid-cell": {
                                        minHeight: "35px !important",
                                        maxHeight: "35px !important"
                                    },
                                    ".MuiDataGrid-row": {
                                        minHeight: "35px !important",
                                        maxHeight: "35px !important"
                                    },
                                }}
                            />
                        </Grid>
                    </Grid>
                </form>
            </FormProvider>
        </>
    )
}

export default HistoryTrainUserLogin