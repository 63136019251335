//React
import React, { useEffect, useState } from "react";
//other - Mui
import { FnBlock_UI } from "../../BlockUI";
import { useNavigate } from "react-router";
import { Box, Checkbox, DialogActions, DialogContent, Divider, Grid, Typography } from "@mui/material";
import { DialogCustomUI } from "src/Components/Dialog/DialogCustomUI";
//component Input
//Button
import { BtnOK, BtnCancelForm } from "../../Button";

const PopupConfirmPrint = (props) => {
    const { IsOpenModal, setIsOpenModal, Print } = props
    const elementAction = () => {
        return (
            <Grid container spacing={1} direction="row" justifyContent="right" alignItems="center">
                <Grid item><BtnOK onClick={() => { Print() }} /></Grid>
                <Grid item><BtnCancelForm onClick={() => { setIsOpenModal(false) }} /></Grid>
            </Grid>
        )

    }

    return (
        <DialogCustomUI
            open={IsOpenModal}
            textHead="ยืนยันการดำเนินการ/Confirmation"
            fullWidth
            questionIcon
            handleClose={() => { setIsOpenModal(false) }}
            isSetHeadColor={true}
            titleBgColor={"#43a7f3"}
            titleColor={"#fff"}
            isShowPopupConfirm={true}
            elementAction={elementAction}
        >
            <DialogContent sx={{
                display: "flex"
                , flexDirection: "column"
                , alignItems: "center", m: "1em", paddingBottom: "0px"
            }}>
                <Grid container spacing={2} direction="row" justifyContent="center" alignItems="center">
                    <Grid item xs={12} md={12} style={{ padding: '0px' }}>
                        <Divider />
                    </Grid>
                    <Grid item xs={12} md={12}>
                        <Grid container spacing={2} direction="row" justifyContent="start" alignItems="center">
                            <Grid item xs={12} md={12}>
                                <Typography>
                                    โปรดตรวจสอบข้อมูลในใบสั่งจ่ายและใบหักภาษี ณ ที่จ่ายให้เรียบร้อยก่อนนำไปชำระเงิน หากมีข้อผิดพลาด สามารถติดต่อผู้ดูแลระบบได้ที่ oracademy@pttor.com หรือ โทร. 02-196-6363
                                </Typography>

                            </Grid>
                        </Grid>
                    </Grid>

                </Grid>
            </DialogContent>
            <DialogActions sx={{ borderTop: "1px solid rgba(0,0,0,0.3)" }}>
                <Grid container spacing={1} direction="row" justifyContent="right" alignItems="center">
                    <Grid item><BtnOK txt="ยืนยัน/Ok" onClick={() => { Print(); setIsOpenModal(false);}} /></Grid>
                    <Grid item><BtnCancelForm onClick={() => { setIsOpenModal(false) }} /></Grid>
                </Grid>
            </DialogActions>
        </DialogCustomUI>
    );
}

export default PopupConfirmPrint;