import React, { Fragment, useState } from "react";
import Grid from "@mui/material/Grid";
import ItemRow from "./Item/ItemRow";
// import { TablePagination } from "@mui/material";
// import { defaultPagination, PaginationData, PerPageOptions } from "./Table";
// import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
import { makeStyles, useTheme } from "@mui/styles";

const useStyles = makeStyles((theme) => ({
  lstFile: {
    listStyle: "none",
    paddingLeft: 0,
  },
}));

const ItemListRow = (props: any) => {
  const classes = useStyles();

  const arrFile = props.arrFile ? props.arrFile : [];

  const handleOnDragEnd = (result) => {
    if (!result.destination) return;

    const items = Array.from(arrFile);
    const [reorderedItem] = items.splice(result.source.index, 1);
    items.splice(result.destination.index, 0, reorderedItem);

    props.SetarrFile(items);
  };

  return (
    <Fragment>
      <Grid item xs={12}>
        {arrFile.map((f, i) => {
          return (
            <ItemRow
              key={i}
              sFileType={f.sFileType}
              sFileName={f.sFileName}
              sSize={f.sSizeName}
              IsComplete={f.IsComplete}
              IsProgress={f.IsProgress}
              sProgress={f.sProgress}
              sFolderName={f.sFolderName}
              onDelete={props.onDelete}
              nFile_ID={f.nFile_ID}
              onLoad={props.onLoad}
              sUrl={f.sUrl}
              sFileLink={f.sFileLink}
              disabled={props.disabled}
              IsOneLine={props.IsOneLine}
              IsCanRename={props.IsCanRename}
              arrFile={props.arrFile}
              SetarrFile={props.SetarrFile}
              ArrFileRename={props.ArrFileRename}
              SetarrFileRename={props.SetarrFileRename}
              onOpenFile={props.onOpenFile}
              IsHiddenUploadBox={props.IsHiddenUploadBox}
              setStartVideoOn={props.setStartVideoOn}
              nStartVideoOn={props.nStartVideoOn}
              CannotSkipForward={props.CannotSkipForward}
              onVideoEnd={props.onVideoEnd}
            />
          );
        })}
      </Grid>
    </Fragment>
  );
};
export default ItemListRow;
