import React, { createElement } from "react";
import { Button, Fab, Theme, Tooltip, Icon, TooltipProps, Box, Typography } from "@mui/material";
import {
  Login,
  Search,
  Send,
  Delete,
  ArrowBackIos,
  Logout,
  Settings,
  FileDownload,
  Save,
  Visibility,
  DeleteForever,
  Add,
  RemoveRedEye,
  Image,
  Edit,
  Link,
  ArrowForwardIos,
  CheckCircleRounded,
} from "@mui/icons-material";
import ClearIcon from '@mui/icons-material/Clear';
import ModeEditIcon from '@mui/icons-material/ModeEdit';
import { HiOutlineArrowRight, HiOutlineArrowLeft } from "react-icons/hi";
import PrintIcon from '@mui/icons-material/Print';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faLine, faMicrosoft } from "@fortawesome/free-brands-svg-icons";
import * as Icons from "@mui/icons-material";
import { GridActionsCellItem } from "@mui/x-data-grid-pro";
import { BiChevronDown, BiChevronUp } from "react-icons/bi";
import { VscSignIn, VscSignOut } from "react-icons/vsc";
import { FiEdit } from "react-icons/fi";

import CheckIcon from '@mui/icons-material/Check';
import CloseIcon from '@mui/icons-material/Close';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import Cancel from "@mui/icons-material/Cancel";
import SearchIcon from '@mui/icons-material/Search';
import FileUploadIcon from '@mui/icons-material/FileUpload';
import DownloadIcon from '@mui/icons-material/Download';
import UploadIcon from '@mui/icons-material/Upload';

// import { LoadingButton } from "@mui/lab";
const sizeDefault = { width: 40, height: 40 };
export const styleX = {
  btnRadius: {
    my: 2,
    border: "1px solid #f2f2f2",
    borderRadius: "20px",
    height: "40px",
    ".MuiFilledInput-input": { paddingTop: "10px" },
  },
  btnSubmit: {
    color: "white",
    backgroundColor: "#212529",
    ":hover": {
      backgroundColor: "#188e32",
    },
    textTransform: "none",
    fontWeight: "normal",
  },
  btnCancel: {
    color: "white",
    backgroundColor: "#ed3847",
    ":hover": {
      backgroundColor: "rgb(220, 53, 69)",
    },
    textTransform: "none",
    fontWeight: "normal",
  },
  btnDownload: {
    color: "#fff",
    backgroundColor: "#3997df",
    borderColor: "#3997df",
    borderRadius: "16px",
    "&:hover": {
      backgroundColor: "#4095d6",
    },
    margin: "0",
    textTransform: "none",
    fontWeight: "normal",
  },
  btnDownloadFile: {
    color: "#fff",
    backgroundColor: "#333333",
    borderColor: "#3997df",
    borderRadius: "16px",
    "&:hover": {
      backgroundColor: "#2C2C2C",
    },
    marginTop: "15px",
    textTransform: "none",
    fontWeight: "normal",
  },
  btnSetting: {
    color: "#fff",
    backgroundColor: "#1976d2",
    "&:hover": {
      backgroundColor: "#0946a2",
    },
  },
  btnSave: {
    backgroundColor: "rgb(51, 166, 76)",
    ":hover": {
      backgroundColor: "rgb(24, 142, 50)",
    },
    textTransform: "none",
    fontWeight: "normal",
  },
  btnBack: {
    backgroundColor: "#a7a7a7",
    ":hover": {
      backgroundColor: "#878787",
    },
    textTransform: "none",
    fontWeight: "normal",
  },
  btnPreview: {
    backgroundColor: "#e39a2d",
    ":hover": {
      backgroundColor: "#b9812d",
    },
    textTransform: "none",
    fontWeight: "normal",
  },
  violetColor: {
    color: "#fff",
    backgroundColor: "#9e3eda",
    borderColor: "#9e3eda",
    ":hover": { bgcolor: "#671d96" },
  },
  btnLogin: {
    color: "#fff",
    backgroundColor: "#005f8b",
    borderColor: "#005f8b",
    ":hover": { bgcolor: "#015c85" },
  },
  btnRed: {
    backgroundColor: "#e30000",
    ":hover": {
      backgroundColor: "#c80101",
    },
    textTransform: "none",
  },

};
export const BtnLogin = ({
  IsDisabled = false,
  IsHisabled = false,
  onClick = () => { },
  txt = "Login",
  isCircleWithOutText = false,
  tooltipPlacement = "bottom",
  sName =""
}) => {
  return <Tooltip placement={tooltipPlacement as any} title={txt}>
    {!isCircleWithOutText ?
      <Button 
        name = {sName}
        variant="contained"
        size="small"
        startIcon={<Login />}
        disabled={IsDisabled === true ? true : false}
        hidden={IsHisabled === true ? true : false}
        onClick={onClick}
        sx={{
          backgroundColor: "#122768", ":hover": {
            backgroundColor: "#122768"
          },
          height: "40px",
          width: "-webkit-fill-available",
          textTransform: "none",
        }}
      >
        {txt}
      </Button>
      :
      <Fab
        sx={{ width: 40, height: 40 }}
        color="default"
        onClick={onClick}
        aria-label="login"

      >
        <Login />
      </Fab>}
  </Tooltip>
};

export const BtnLineLogin = ({
  IsDisabled = false,
  IsHisabled = false,
  onClick = () => { },
  txt = "Line Login",
  isCircleWithOutText = false,
  tooltipPlacement = "bottom",
}) => {
  return (
    <Tooltip placement={tooltipPlacement as any} title={txt}>
      {!isCircleWithOutText ? (
        <Button
          variant="contained"
          size="small"
          sx={{
            backgroundColor: "#20af4c",
            ":hover": {
              backgroundColor: "#198639",
            },
          }}
          startIcon={<FontAwesomeIcon icon={faLine} />}
          disabled={IsDisabled === true ? true : false}
          hidden={IsHisabled === true ? true : false}
          onClick={onClick}
        >
          {txt}
        </Button>
      ) : (
        <Fab
          sx={{ width: 40, height: 40, backgroundColor: "#20af4c" }}
          onClick={onClick}
          aria-label="Line Login"
        >
          <Login />
        </Fab>
      )}
    </Tooltip>
  );
};

export const BtnAzureLogin = ({
  IsDisabled = false,
  IsHisabled = false,
  onClick = () => { },
  txt = "Azure Login",
  isCircleWithOutText = false,
  tooltipPlacement = "bottom",
}) => {
  return (
    <Tooltip placement={tooltipPlacement as any} title={txt}>
      {!isCircleWithOutText ? (
        <Button
          variant="contained"
          size="small"
          sx={{
            backgroundColor: "#f34f1c",
            ":hover": {
              backgroundColor: "#c2350a",
            },
          }}
          startIcon={<FontAwesomeIcon icon={faMicrosoft} />}
          disabled={IsDisabled === true ? true : false}
          hidden={IsHisabled === true ? true : false}
          onClick={onClick}
        >
          {txt}
        </Button>
      ) : (
        <Fab
          sx={{ width: 40, height: 40 }}
          color="default"
          onClick={onClick}
          aria-label="Azure Login"
        >
          <Login />
        </Fab>
      )}
    </Tooltip>
  );
};

export const BtnSetting = ({
  IsDisabled = false,
  IsHisabled = false,
  onClick = () => { },
  txt = "Setting",
  isCircleWithOutText = false,
  tooltipPlacement = "bottom",
}) => {
  return (
    <Tooltip placement={tooltipPlacement as any} title={txt}>
      {!isCircleWithOutText ? (
        <Button
          variant="contained"
          size="small"
          startIcon={<Settings />}
          disabled={IsDisabled === true ? true : false}
          hidden={IsHisabled === true ? true : false}
          onClick={onClick}
          sx={styleX.btnSetting}
        >
          {txt}
        </Button>
      ) : (
        <Fab
          sx={{
            width: 40,
            height: 40,
            bgcolor: "#1976d2",
            ...styleX.btnSetting,
          }}
          onClick={onClick}
          aria-label="setting"
        >
          <Settings />
        </Fab>
      )}
    </Tooltip>
  );
};

export const BtnAddFileOnTable = ({
  IsDisabled = false,
  IsHisabled = false,
  txt = "แนบเอกสาร",
  onClick = () => { },
  sx = {
    backgroundColor: "#0a8f08",
    color: "white",
    ":hover": { bgcolor: "#0a7e07" },
  }
}) => (
  <GridActionsCellItem
    disabled={IsDisabled}
    hidden={IsHisabled}
    icon={
      <Tooltip title={txt}>
        <FileUploadIcon />
      </Tooltip>
    }
    label={txt}
    sx={sx}
    onClick={onClick}
    aria-label={txt}
  />
);

export const BtnLogout = ({
  IsDisabled = false,
  IsHisabled = false,
  onClick = () => { },
  txt = "Logout",
  isCircleWithOutText = false,
  sx = {},
  tooltipPlacement = "bottom",
}) => {
  return (
    <Tooltip placement={tooltipPlacement as any} title={txt}>
      {!isCircleWithOutText ? (
        <Button
          variant="contained"
          size="small"
          startIcon={<Logout />}
          disabled={IsDisabled === true ? true : false}
          hidden={IsHisabled === true ? true : false}
          onClick={onClick}
          sx={{ ...styleX.btnCancel, ...sx }}
        >
          {txt}
        </Button>
      ) : (
        <Fab
          sx={{
            width: 40,
            height: 40,
            bgcolor: "#ed3847",
            ...styleX.btnCancel,
            ...sx,
          }}
          onClick={onClick}
          aria-label="logout"
        >
          <Logout />
        </Fab>
      )}
    </Tooltip>
  );
};

export const BtnLoginWithLoading = ({
  IsDisabled = false,
  IsHisabled = false,
  onClick = () => { },
  txt = "Login",
  isLoading,
}) => {
  // return (
  //   <Tooltip title={txt}>
  //     <LoadingButton
  //       loading={isLoading}
  //       variant="contained"
  //       size="small"
  //       startIcon={<Login />}
  //       disabled={IsDisabled === true ? true : false}
  //       hidden={IsHisabled === true ? true : false}
  //       onClick={onClick}
  //       sx={styleX.btnSubmit}
  //     >
  //       {txt}
  //     </LoadingButton>
  //   </Tooltip>
  // );
};

export const BtnSubmitWithLoading = ({
  IsDisabled = false,
  IsHisabled = false,
  onClick = () => { },
  txt = "Submit",
  isLoading,
}) => {
  // return (
  //   <Tooltip title={txt}>
  //     <LoadingButton
  //       loading={isLoading}
  //       variant="contained"
  //       size="small"
  //       startIcon={<Send />}
  //       disabled={IsDisabled === true ? true : false}
  //       hidden={IsHisabled === true ? true : false}
  //       onClick={onClick}
  //       sx={styleX.btnSubmit}
  //     >
  //       {txt}
  //     </LoadingButton>
  //   </Tooltip>
  // );
};

export const BtnSubmit = ({
  IsDisabled = false,
  IsHisabled = false,
  onClick = () => { },
  txt = "Submit",
  isCircleWithOutText = false,
  tooltipPlacement = "bottom",
}) => {
  return (
    <Tooltip placement={tooltipPlacement as any} title={txt}>
      {!isCircleWithOutText ? (
        <Button
          variant="contained"
          size="small"
          startIcon={<Send />}
          disabled={IsDisabled === true ? true : false}
          hidden={IsHisabled === true ? true : false}
          onClick={onClick}
          sx={styleX.btnSubmit}
        >
          {txt}
        </Button>
      ) : (
        <Fab
          sx={[styleX.btnSubmit, { ...sizeDefault }]}
          onClick={onClick}
          aria-label="submit"
        >
          <Send />
        </Fab>
      )}
    </Tooltip>
  );
};

export const BtnCancel = ({
  IsDisabled = false,
  IsHisabled = false,
  onClick = () => { },
  txt = "Remove",
  isCircleWithOutText = false,
  tooltipPlacement = "bottom",
}) => {
  return (
    <Tooltip placement={tooltipPlacement as any} title={txt}>
      {!isCircleWithOutText ? (
        <Button
          variant="contained"
          size="small"
          startIcon={<DeleteForever />}
          disabled={IsDisabled === true ? true : false}
          hidden={IsHisabled === true ? true : false}
          onClick={onClick}
          sx={styleX.btnCancel}
        >
          {txt}
        </Button>
      ) : (
        <Fab
          sx={[styleX.btnCancel, { ...sizeDefault }]}
          onClick={onClick}
          aria-label="cancel"
          disabled={IsDisabled === true ? true : false}
        >
          <DeleteForever />
        </Fab>
      )}
    </Tooltip>
  );
};

export const BtnSearch = ({
  IsDisabled = false,
  IsHisabled = false,
  onClick = () => { },
  txt = "Search",
  isCircleWithOutText = false,
  tooltipPlacement = "bottom",
  small = true
}) => {
  return (
    <Tooltip placement={tooltipPlacement as any} title={txt}>
      {!isCircleWithOutText ? (
        <Button
          variant="contained"
          size={small ? "small" : "medium"}
          startIcon={<Search />}
          disabled={IsDisabled === true ? true : false}
          hidden={IsHisabled === true ? true : false}
          onClick={onClick}
          sx={styleX.btnSetting}
        >
          {txt}
        </Button>
      ) : (
        <Fab
          sx={[styleX.btnSetting, { ...sizeDefault }]}
          onClick={onClick}
          aria-label="search"
        >
          <Search />
        </Fab>
      )}
    </Tooltip>
  );
};

export const BtnCustomIcon = ({
  IsDisabled = false,
  IsHisabled = false,
  onClick = () => { },
  txt = "",
  isCircleWithOutText = false,
  sIconComponent = null,
  eIconComponent = null,
  sxCustom = {},
  tooltipPlacement = "bottom",
}) => {
  return (
    <Tooltip placement={tooltipPlacement as any} title={txt}>
      {!isCircleWithOutText ? (
        <Button
          variant="contained"
          size="small"
          startIcon={sIconComponent ? sIconComponent : null}
          endIcon={eIconComponent ? eIconComponent : null}
          disabled={IsDisabled === true ? true : false}
          hidden={IsHisabled === true ? true : false}
          onClick={onClick}
          sx={[styleX.btnBack, { ...sxCustom }]}
        >
          {txt}
        </Button>
      ) : (
        <Fab
          sx={[styleX.btnBack, { ...sizeDefault, ...sxCustom }]}
          onClick={onClick}
          disabled={IsDisabled === true ? true : false}
        >
          {sIconComponent ? sIconComponent : null}
        </Fab>
      )}
    </Tooltip>
  );
};

export const BtnBack = ({
  IsDisabled = false,
  IsHisabled = false,
  onClick = () => { },
  txt = "Back",
  isCircleWithOutText = false,
  tooltipPlacement = "bottom",
}) => {
  return (
    <Tooltip placement={tooltipPlacement as any} title={txt}>
      {!isCircleWithOutText ? (
        <Button
          variant="contained"
          size="small"
          startIcon={<ArrowBackIos />}
          disabled={IsDisabled === true ? true : false}
          hidden={IsHisabled === true ? true : false}
          onClick={onClick}
          sx={styleX.btnBack}
        >
          {txt}
        </Button>
      ) : (
        <Fab
          onClick={onClick}
          sx={{ width: 40, height: 40, color: "#a6a6a6" }}
          aria-label="back"
        >
          <ArrowBackIos />
        </Fab>
      )}
    </Tooltip>
  );
};

export const BtnDownload = ({
  IsDisabled = false,
  IsHisabled = false,
  onClick,
  txt = "Download",
  isCircleWithOutText = false,
  tooltipPlacement = "bottom",
}) => {
  return (
    <Tooltip placement={tooltipPlacement as any} title={txt}>
      {!isCircleWithOutText ? (
        <Button
          variant="contained"
          size="small"
          startIcon={<FileDownload />}
          disabled={IsDisabled === true ? true : false}
          hidden={IsHisabled === true ? true : false}
          onClick={onClick}
          sx={styleX.btnDownloadFile}
        >
          {txt}
        </Button>
      ) : (
        <Fab sx={{ width: 40, height: 40, color: "#a6a6a6" }} aria-label="back">
          <FileDownload />
        </Fab>
      )}
    </Tooltip>
  );
};

export const BtnSave = ({
  IsDisabled = false,
  IsHisabled = false,
  onClick = () => { },
  txt = "Save",
  isCircleWithOutText = false,
  tooltipPlacement = "bottom",
}) => {
  return (
    <Tooltip placement={tooltipPlacement as any} title={txt}>
      {!isCircleWithOutText ? (
        <Button
          variant="contained"
          size="small"
          startIcon={<Save />}
          disabled={IsDisabled === true ? true : false}
          hidden={IsHisabled === true ? true : false}
          onClick={onClick}
          sx={styleX.btnSave}
        >
          {txt}
        </Button>
      ) : (
        <Fab sx={{ width: 40, height: 40, color: "#a6a6a6" }} aria-label="back">
          <Save />
        </Fab>
      )}
    </Tooltip>
  );
};

export const BtnPreview = ({
  IsDisabled = false,
  IsHisabled = false,
  onClick = () => { },
  txt = "Preview",
  isCircleWithOutText = false,
  tooltipPlacement = "bottom",
}) => {
  return (
    <Tooltip placement={tooltipPlacement as any} title={txt}>
      {!isCircleWithOutText ? (
        <Button
          variant="contained"
          size="small"
          startIcon={<Visibility />}
          disabled={IsDisabled === true ? true : false}
          hidden={IsHisabled === true ? true : false}
          onClick={onClick}
          sx={styleX.btnPreview}
        >
          {txt}
        </Button>
      ) : (
        <Fab sx={{ width: 40, height: 40, color: "#a6a6a6" }} aria-label="back">
          <Visibility />
        </Fab>
      )}
    </Tooltip>
  );
};

export const BtnAddOnTable = ({
  IsDisabled = false,
  IsHisabled = false,
  txt = "Add",
  onClick = () => { },
}) => (
  <GridActionsCellItem
    disabled={IsDisabled}
    hidden={IsHisabled}
    icon={
      <Tooltip title={txt}>
        <Add />
      </Tooltip>
    }
    label={txt}
    // sx={{
    //   bgcolor: "#1976d2",
    //   color: "white",
    //   ":hover": {
    //     bgcolor: "#0946a2",
    //   },
    // }}
    sx={{
      backgroundColor: "#169bd5",
      color: "#ffffff",
      ":hover": { bgcolor: "#2fb8f4" },
    }}
    onClick={onClick}
    aria-label={txt}
  />
);

export const BtnRemoveOnTable = ({
  IsDisabled = false,
  IsHisabled = false,
  txt = "Remove",
  onClick = () => { },
}) => (
  <GridActionsCellItem
    disabled={IsDisabled}
    hidden={IsHisabled}
    icon={
      <Tooltip title={txt}>
        <Cancel />
      </Tooltip>
    }
    label={txt}
    sx={{
      backgroundColor: "#E12424",
      color: "white",
      ":hover": { bgcolor: "#CD1414" },
    }}
    onClick={onClick}
    aria-label={txt}
  />
);


export const BtnPreviewOnTable = ({
  IsDisabled = false,
  IsHisabled = false,
  txt = "Preview",
  onClick = () => { },
}) => (
  <GridActionsCellItem
    disabled={IsDisabled}
    hidden={IsHisabled}
    icon={
      <Tooltip title={txt}>
        <RemoveRedEye />
      </Tooltip>
    }
    label={txt}
    sx={(theme: Theme) => ({
      color: "#fff",
      backgroundColor: theme.palette.info.main,
      borderColor: theme.palette.info.light,
      ":hover": { bgcolor: theme.palette.info.dark },
    })}
    onClick={onClick}
    aria-label={txt}
  />
);

export const BtnLinkOnTable = ({
  IsDisabled = false,
  IsHisabled = false,
  txt = "Link",
  onClick = () => { },
}) => (
  <GridActionsCellItem
    disabled={IsDisabled}
    hidden={IsHisabled}
    icon={
      <Tooltip title={txt}>
        <Link />
      </Tooltip>
    }
    label={txt}
    sx={(theme: Theme) => ({
      color: "#fff",
      backgroundColor: "#9e3eda",
      borderColor: "#9e3eda",
      ":hover": { bgcolor: "#671d96" },
    })}
    onClick={onClick}
    aria-label={txt}
  />
);

export const BtnViewImageOnTable = ({
  IsDisabled = false,
  IsHisabled = false,
  txt = "View Image",
  onClick = () => { },
}) => (
  <GridActionsCellItem
    disabled={IsDisabled}
    hidden={IsHisabled}
    icon={
      <Tooltip title={txt}>
        <Image />
      </Tooltip>
    }
    label={txt}
    sx={{
      backgroundColor: "tranparent",
      color: "#4f5bef",
      ":hover": { bgcolor: "#d7d7d7" },
    }}
    onClick={onClick}
    aria-label={txt}
  />
);

export const BtnEditOnTable = ({
  IsDisabled = false,
  IsHisabled = false,
  txt = "Edit",
  onClick = () => { },
}) => (
  <GridActionsCellItem
    disabled={IsDisabled}
    hidden={IsHisabled}
    icon={
      <Tooltip title={txt}>
        <Edit />
      </Tooltip>
    }
    label={txt}
    sx={{
      backgroundColor: "#ffc107",
      color: "black",
      ":hover": { bgcolor: "#cc9900" },
    }}
    onClick={onClick}
    aria-label={txt}
  />
);

export const BtnEditOnForm = ({
  IsDisabled = false,
  IsHisabled = false,
  txt = "Edit",
  onClick = () => { },
}) => (
  <GridActionsCellItem
    disabled={IsDisabled}
    hidden={IsHisabled}
    icon={
      <Tooltip title={txt}>
        <ModeEditIcon />
      </Tooltip>
    }
    label={txt}
    sx={{
      backgroundColor: "#169bd5",
      color: "#ffffff",
      ":hover": { bgcolor: "#2fb8f4" },
    }}
    onClick={onClick}
    aria-label={txt}
  />
);

export const BtnDeleteOnForm = ({
  IsDisabled = false,
  IsHisabled = false,
  txt = "Delete",
  onClick = () => { },
}) => (
  <GridActionsCellItem
    disabled={IsDisabled}
    hidden={IsHisabled}
    icon={
      <Tooltip title={txt}>
        <ClearIcon />
      </Tooltip>
    }
    label={txt}
    sx={{
      backgroundColor: "#d9001b",
      color: "#ffffff",
      ":hover": { bgcolor: "#fb2842" },
    }}
    onClick={onClick}
    aria-label={txt}
  />
);

export const BtnViewOnTable = ({
  IsDisabled = false,
  IsHisabled = false,
  txt = "View",
  onClick = () => { },
}) => (
  <GridActionsCellItem
    disabled={IsDisabled}
    hidden={IsHisabled}
    icon={
      <Tooltip title={txt}>
        <Visibility />
      </Tooltip>
    }
    label={txt}
    sx={{
      backgroundColor: "#6FDBFF",
      color: "white",
      ":hover": { bgcolor: "#5DB6D4" },
    }}
    onClick={onClick}
    aria-label={txt}
  />
);

export const BtnRadius = ({
  IsDisabled = false,
  IsHisabled = false,
  txt = "Add",
  onClick = () => { },
  sBackgroundColor = "rgb(102, 187, 106)",
  sFontColor = "rgb(255, 255, 255)",
}) => (
  <Button
    disabled={IsDisabled === true ? true : false}
    hidden={IsHisabled === true ? true : false}
    onClick={onClick}
    variant="contained"
    size="small"
    fullWidth
    sx={styleX.btnRadius}
    style={{ backgroundColor: sBackgroundColor, color: sFontColor }}
    endIcon={<Add />}
  >
    {txt}
  </Button>
);

export const BtnEnterSite = ({
  IsDisabled = false,
  IsHisabled = false,
  onClick = () => { },
  txt = "เข้าสู่เว็บไซต์ | Enter Website",
  isCircleWithOutText = false,
  tooltipPlacement = "bottom",
}) => {
  return (
    <Tooltip placement={tooltipPlacement as any} title={txt}>
      {!isCircleWithOutText ? (
        <Button
          variant="contained"
          size="large"
          // startIcon={<Login />}
          disabled={IsDisabled === true ? true : false}
          hidden={IsHisabled === true ? true : false}
          onClick={onClick}
          //style={{textTransform: "none",  fontWeight: "normal",}}
          sx={{
            backgroundColor: "rgb(8, 40, 107)",
            color: "#ffffff",
            ":hover": { bgcolor: "rgb(8, 40, 107)" },
            textTransform: "none", fontWeight: "normal",
          }}
        >
          {txt}
        </Button>
      ) : (
        <Fab
          sx={{ width: 40, height: 40 }}
          color="default"
          onClick={onClick}
          aria-label="login"
        >
          {/* <Login /> */}
        </Fab>
      )}
    </Tooltip>
  );
};

export const BtnRegister = ({
  IsDisabled = false,
  IsHisabled = false,
  onClick = () => { },
  txtth = "สมัครสมาชิก",
  txteng = "Sign Up",
  menuID = 0,
}) => {
  return (
    <>
      <Button
        variant="contained"
        id="btnMainRegister"
        sx={{
          width: { xs: "90px", sm: "130px", lg: "180px" },
          height: "90px",
          borderRadius: 0,
          background: menuID === 98 ? "#1b2648" : "linear-gradient(to bottom, #b6deea ,#d9efe9)",
          color: menuID === 98 ? "rgb(255,255,255)" : "#1b2648",
          fontWeight: "normal",
          boxShadow: "none !important",
          fontSize: "1em",
          ":hover": {
            background: "#1b2648",
            color: "rgb(255,255,255)",
          },
          textTransform: "none",
          padding: { xs: "0px", lg: "16px" },

        }}
        disabled={IsDisabled === true ? true : false}
        hidden={IsHisabled === true ? true : false}
        onClick={onClick}
      >
        <Box sx={{ display: { xs: "none", lg: "block" } }}>
          <FiEdit size={35} />
        </Box>
        <Box sx={{ ml: { lg: "1em" } }}>
          {/* <p style={{ whiteSpace: "nowrap", marginLeft: "1em" }}> */}
          {/* <label style={{ cursor: "pointer"}}>{txtth} </label> */}
          <Typography sx={{ fontSize: { xs: "0.8em", md: "0.9em", lg: "1em" } }}>{txtth}</Typography>
          <Typography sx={{ fontSize: { xs: "0.8em", md: "0.9em", lg: "1em" } }}>{txteng}</Typography>
          {/* <label style={{ cursor: "pointer" }}>{txteng} </label> */}
          {/* </p> */}
        </Box>
      </Button>
    </>
  );
};

export const BtnLoginWithText = ({
  IsDisabled = false,
  IsHisabled = false,
  onClick = () => { },
  txtth = "เข้าสู่ระบบ",
  txteng = "Login",
  menuID = 0,
}) => {
  return (
    <>
      <Button
        variant="contained"
        id="btnMainLogin"
        sx={{
          width: { xs: "90px", sm: "130px", lg: "180px" },
          height: "90px",
          borderRadius: 0,
          background: menuID === 99 ? "#1b2648" : "linear-gradient(to bottom, #d9efe9 ,#b6deea)",
          color: menuID === 99 ? "rgb(255,255,255)" : "#1b2648",
          fontSize: "1em",
          boxShadow: "none !important",
          borderBottom: "0px",
          // background: "linear-gradient( #000000 0%, #ffffff 145%)",
          // WebkitBackgroundClip: "text",
          // WebkitTextFillColor: "transparent",
          ":hover": {
            background: "#1b2648 !important",
            color: "rgb(255,255,255) !important",
          },
          textTransform: "none",
          fontWeight: "normal",
          // background: "linear-gradient( #000000 0%, #ffffff 145%)",
          // WebkitBackgroundClip: "text",
          // WebkitTextFillColor: "transparent",
          // ":hover": {
          //   color:"#3997df",
          //   // bgcolor: "#3997df",
          //   // background:
          //   //   "linear-gradient( rgba(255,255,255,1) 0%, rgba(255,255,255,1) 100%)",
          //   // WebkitBackgroundClip: "text",
          //   // WebkitTextFillColor: "transparent",
          // },
          padding: { xs: "0px", lg: "16px" },
        }}
        disabled={IsDisabled === true ? true : false}
        hidden={IsHisabled === true ? true : false}
        onClick={onClick}
      >
        {/* <VscSignIn size={35} />
        <p style={{ whiteSpace: "nowrap", marginLeft: "1em" }}>
          <label style={{ cursor: "pointer" }}>{txtth} </label>
          <br />
          <label style={{ cursor: "pointer" }}>{txteng} </label>
        </p> */}

        <Box sx={{ display: { xs: "none", lg: "block" } }}>
          <VscSignIn size={35} />
        </Box>
        <Box sx={{ ml: { lg: "1em" } }}>
          {/* <p style={{ whiteSpace: "nowrap", marginLeft: "1em" }}> */}
          {/* <label style={{ cursor: "pointer"}}>{txtth} </label> */}
          <Typography sx={{ fontSize: { xs: "0.8em", md: "0.9em", lg: "1em" } }}>{txtth}</Typography>
          <Typography sx={{ fontSize: { xs: "0.8em", md: "0.9em", lg: "1em" } }}>{txteng}</Typography>
          {/* <label style={{ cursor: "pointer" }}>{txteng} </label> */}
          {/* </p> */}
        </Box>
      </Button>
    </>
  );
};


export const BtnHome = ({
  IsDisabled = false,
  IsHisabled = false,
  onClick = () => { },
  txtth = "หน้าแรก",
  txteng = "Home",
  menuID = 0,
}) => {
  return (
    <>
      <Box sx={{
        bgcolor: menuID === 97 ? "#3997df" : "white",
        ":hover": {
          bgcolor: "#3997df",
        },
      }}>
        <Button
          variant="contained"
          sx={{
            width: "120px",
            height: "90px",
            borderRadius: 0,

            background: menuID === 97 ?
              "linear-gradient( rgba(255,255,255,1) 0%, rgba(255,255,255,1) 100%)"
              : "linear-gradient( #000000 0%, #ffffff 145%)",
            WebkitBackgroundClip: "text",
            WebkitTextFillColor: "transparent",
            fontWeight: "normal",
            boxShadow: "none !important",
            fontSize: "1em",
            ":hover": {
              background: "linear-gradient( rgba(255,255,255,1) 0%, rgba(255,255,255,1) 100%)",
              WebkitBackgroundClip: "text",
              WebkitTextFillColor: "transparent",
            },
            textTransform: "none",
          }}
          disabled={IsDisabled === true ? true : false}
          hidden={IsHisabled === true ? true : false}
          onClick={(e) => {
            onClick();
          }}
        >
          <p style={{ whiteSpace: "nowrap" }}>
            <label style={{ cursor: "pointer" }}>{txtth} </label>
            <br />
            <label style={{ cursor: "pointer" }}>{txteng} </label>
          </p>
        </Button>
      </Box>
    </>
  );
};

export const BtnDetailOnForm = ({
  IsDisabled = false,
  IsHisabled = false,
  txt = "ดูรายละเอียด",
  onClick = () => { },
}) => (
  <GridActionsCellItem
    disabled={IsDisabled}
    hidden={IsHisabled}
    icon={
      <Tooltip title={txt}>
        <SearchIcon />
      </Tooltip>
    }
    label={txt}
    sx={{
      backgroundColor: "#0a286c",
      color: "#ffffff",
      ":hover": { bgcolor: "#1a237e" },
    }}
    onClick={onClick}
    aria-label={txt}
  />
);

export const BtnSeeDetail = ({
  IsDisabled = false,
  IsHisabled = false,
  fullWidth = false,
  style = {},
  txt = "" || "รายละเอียดเพิ่มเติม/Detail",
  color = '"primary" || "default" || "inherit" || "warning" || "error" || "success" || "info" || "secondary"' as any,
  hover = "",
  onClick = () => { },
}) => {
  return (
    <Button
      variant="contained"
      size="medium"
      disabled={IsDisabled === true ? true : false}
      hidden={IsHisabled === true ? true : false}
      // sx={styleX.btnDownload}
      sx={{ borderRadius: "16px", textTransform: "none", background: color, ":hover": { background: hover }, ...style }}


      fullWidth={fullWidth}
      onClick={(e) => {
        onClick();
      }}
    >
      {txt}
    </Button>
  );
};

export const BtnReadMore = ({
  IsDisabled = false,
  IsHisabled = false,
  txt = "อ่านต่อ/Read More",
  tooltipPlacement = "bottom",
}) => {
  return (
    <Button
      variant="contained"
      size="medium"
      disabled={IsDisabled === true ? true : false}
      hidden={IsHisabled === true ? true : false}
      sx={styleX.btnDownload}
    >
      {txt}
    </Button>
  );
};

export const BtnLeftWithOutText = ({
  IsDisabled = false,
  IsHisabled = false,
  onClick = () => { },
  color = '"primary" || "default" || "inherit" || "warning" || "error" || "success" || "info" || "secondary"' as any,
}) => {
  return (
    <>
      <Fab sx={{ width: 40, height: 40 ,zIndex:"2 !important"}} color={color} onClick={onClick}>
        <HiOutlineArrowLeft size={25} />
      </Fab>
    </>
  );
};

export const BtnRightWithOutText = ({
  IsDisabled = false,
  IsHisabled = false,
  onClick = () => { },
  color = '"primary" || "default" || "inherit" || "warning" || "error" || "success" || "info" || "secondary"' as any,
}) => {
  return (
    <>
      <Fab sx={{ width: 40, height: 40,zIndex:"2 !important" }} color={color} onClick={onClick}>
        <HiOutlineArrowRight size={25} />
      </Fab>
    </>
  );
};

export const BtnSearchWithOutText = ({
  IsDisabled = false,
  IsHisabled = false,
  onClick = () => { },
  color = '"primary" || "default" || "inherit" || "warning" || "error" || "success" || "info" || "secondary"' as any,
}) => {
  return (
    <>
      <Fab
        sx={{
          width: 40,
          height: 40,
          borderRadius: "8px",
          mt: "7px",
          mr: "10px",
        }}
        color={color}
        onClick={onClick}
      >
        <Search />
      </Fab>
    </>
  );
};

export const BtnSingUp = ({
  IsDisabled = false,
  IsHisabled = false,
  onClick = () => { },
  txt = "Sing Up",
  isCircleWithOutText = false,
  tooltipPlacement = "bottom",
}) => {
  return (
    <Tooltip placement={tooltipPlacement as any} title={txt}>
      {!isCircleWithOutText ? (
        <Button
          variant="contained"
          size="small"
          disabled={IsDisabled === true ? true : false}
          hidden={IsHisabled === true ? true : false}
          onClick={onClick}
          sx={{
            backgroundColor: "#1956f6",
            ":hover": { bgcolor: "#1052fc" },
            height: "40px",
          }}
        >
          {txt}
        </Button>
      ) : (
        <Fab
          sx={{ width: 40, height: 40 }}
          color="default"
          onClick={onClick}
          aria-label="sing up"
        ></Fab>
      )}
    </Tooltip>
  );
};

export const BtnCustomText = ({
  IsDisabled = false,
  IsHisabled = false,
  txt = "",
  onClick = () => { },
  tooltipPlacement = "bottom",
  isShowExpand = false,
  isExpand = false,
  fullWidth = false,
  width = "",
  height = "auto",
  backgroundColor = "#3997df",
  color = "#fff",
  maxWidth = "100%",
  mx = "8px",
}) => {
  return (
    <Button
      variant="contained"
      size="medium"
      disabled={IsDisabled === true ? true : false}
      hidden={IsHisabled === true ? true : false}
      fullWidth={fullWidth}
      sx={{
        textTransform: "none",
        width: width || "100%",
        height: height,
        maxWidth:{maxWidth},
        // justifyItems:"center",
        color: color,
        backgroundColor: { backgroundColor },
        borderColor: { backgroundColor },
        "&:hover": {
          backgroundColor: { backgroundColor },
        },
        margin: mx,
        borderRadius: "16px",
        justifyContent: "center",
      }}
      onClick={onClick}
    >
      {txt}
      {isShowExpand ? (
        isExpand ? (
          <BiChevronUp size="15" style={{ margin: "0px 5px 0px 10px" }} />
        ) : (
          <BiChevronDown size="15" style={{ margin: "0px 5px 0px 10px" }} />
        )
      ) : (
        <></>
      )}
    </Button>
  );
};

export const BtnHistory = ({
  IsDisabled = false,
  IsHisabled = false,
  txt = "",
  onClick = () => { },
  tooltipPlacement = "bottom",
  isShowExpand = false,
  isExpand = false,
  fullWidth = false,
  // width = "auto",
  height = "auto",
}) => {
  return (
    <Button
      variant="contained"
      size="medium"
      disabled={IsDisabled === true ? true : false}
      hidden={IsHisabled === true ? true : false}
      fullWidth={fullWidth}
      sx={{
        // width: width,
        height: height,
        // justifyItems:"center",
        color: "#fff",
        backgroundColor: "#3997df",
        borderColor: "#3997df",
        "&:hover": {
          backgroundColor: "#4095d6",
        },
        // margin: "8px",
        borderRadius: "16px",
        justifyContent: "space-between",
      }}
      onClick={onClick}
    >
      {txt}
      {isShowExpand ? (
        isExpand ? (
          <BiChevronUp size="15" style={{ margin: "0px 5px 0px 10px" }} />
        ) : (
          <BiChevronDown size="15" style={{ margin: "0px 5px 0px 10px" }} />
        )
      ) : (
        <></>
      )}
    </Button>
  );
};

export const BtnSend = ({
  IsDisabled = false,
  IsHisabled = false,
  onClick = () => { },
  txt = "ส่ง/Send",
  isCircleWithOutText = false,
  tooltipPlacement = "bottom",
}) => {
  return (
    <Tooltip placement={tooltipPlacement as any} title={txt}>
      {!isCircleWithOutText ? (
        <Button
          variant="contained"
          size="medium"
          disabled={IsDisabled === true ? true : false}
          hidden={IsHisabled === true ? true : false}
          onClick={onClick}
          sx={{
            backgroundColor: "#000",
            color: "#F3F3F3",
            "&:hover": {
              backgroundColor: "#474748",
            },
            margin: "1",
            borderRadius: "16px",
            justifyContent: "space-between",
          }}
        >
          {txt}

        </Button>
      ) : (
        <Fab
          sx={{
            backgroundColor: "green",
            color: "#F3F3F3",
            "&:hover": {
              backgroundColor: "#528E52",
            },
            margin: "1",
            borderRadius: "16px",
            justifyContent: "space-between",
          }}
          color="default"
          onClick={onClick}
          aria-label="ส่ง/Send"
        ></Fab>
      )}
    </Tooltip>
  );
};
export const BtnSendCancel = ({
  IsDisabled = false,
  IsHisabled = false,
  onClick = () => { },
  txt = "ยกเลิก/Cancel",
  isCircleWithOutText = false,
  tooltipPlacement = "bottom",
}) => {
  return (
    <Tooltip placement={tooltipPlacement as any} title={txt}>
      {!isCircleWithOutText ? (
        <Button
          variant="contained"
          size="medium"
          disabled={IsDisabled === true ? true : false}
          hidden={IsHisabled === true ? true : false}
          onClick={onClick}
          sx={{
            backgroundColor: "white",
            color: "#000000",
            "&:hover": {
              backgroundColor: "#eaeaea",
            },
            margin: "1",
            borderRadius: "16px",
            justifyContent: "space-between",
          }}
        >
          {txt}
        </Button>
      ) : (
        <Fab
          sx={{
            backgroundColor: "white",
            color: "#000000",
            "&:hover": {
              backgroundColor: "#FFFFFF",
            },
            margin: "1",
            borderRadius: "16px",
            justifyContent: "space-between",
          }}
          color="default"
          onClick={onClick}
          aria-label="ยกเลิก/Cancel"
        ></Fab>
      )}
    </Tooltip>
  );
};
export const BtnDetail = ({
  IsDisabled = false,
  IsHisabled = false,
  fullWidth = false,
  txt = "" || "รายละเอียดเพิ่มเติม/Detail",
  // color = '"primary" || "default" || "inherit" || "warning" || "error" || "success" || "info" || "secondary"' as any,
  color = "",
  hover = "",
  onClick = () => { },
}) => {
  return (
    <Button
      variant="contained"
      size="medium"
      disabled={IsDisabled === true ? true : false}
      hidden={IsHisabled === true ? true : false}
      // color={color}
      sx={{ borderRadius: "16px", textTransform: "none", fontWeight: "normal", background: color, ":hover": { background: hover } }}
      fullWidth={fullWidth}
      onClick={
        onClick
      }
    >
      {txt}
    </Button>
  );
};

export const BtnSaveForm = ({
  IsDisabled = false,
  IsHisabled = false,
  onClick = () => { },
  txt = "บันทึก/Save",
  isCircleWithOutText = false,
  tooltipPlacement = "bottom",
}) => {
  return (
    <Tooltip placement={tooltipPlacement as any} title={txt}>
      {!isCircleWithOutText ? (
        <Button
          variant="contained"
          size="medium"
          disabled={IsDisabled === true ? true : false}
          hidden={IsHisabled === true ? true : false}
          onClick={onClick}
          sx={[styleX.btnSubmit, { color: "#fff", backgroundColor: "#366d21" }]}
        >
          {txt}
        </Button>
      ) : (
        <Fab
          sx={[styleX.btnSubmit, { ...sizeDefault }]}
          onClick={onClick}
          aria-label="บันทึก/Save"
        >
          <Send />
        </Fab>
      )}
    </Tooltip>
  );
};

export const BtnCancelForm = ({
  IsDisabled = false,
  IsHisabled = false,
  onClick = () => { },
  txt = "ยกเลิก/Cancel",
  isCircleWithOutText = false,
  tooltipPlacement = "bottom",
}) => {
  return (
    <Tooltip placement={tooltipPlacement as any} title={txt}>
      {!isCircleWithOutText ? (
        <Button
          variant="outlined"
          size="medium"
          disabled={IsDisabled === true ? true : false}
          hidden={IsHisabled === true ? true : false}
          onClick={onClick}
          sx={{ border: "1px solid #797979", color: "#000", textTransform: "none" }}
        >
          {txt}
        </Button>
      ) : (
        <Fab
          sx={[styleX.btnSubmit, { ...sizeDefault }]}
          onClick={onClick}
          aria-label="ยกเลิก/Cancel"
        >
          <Send />
        </Fab>
      )}
    </Tooltip>
  );
};

export const BtnOK = ({
  IsDisabled = false,
  IsHisabled = false,
  onClick = () => { },
  txt = "ตกลง/OK",
  isCircleWithOutText = false,
  tooltipPlacement = "bottom",
}) => {
  return (
    <Tooltip placement={tooltipPlacement as any} title={txt}>
      {!isCircleWithOutText ? (
        <Button
          variant="contained"
          size="medium"
          disabled={IsDisabled === true ? true : false}
          hidden={IsHisabled === true ? true : false}
          onClick={onClick}
          sx={[styleX.btnSubmit, { color: "#fff", backgroundColor: "#366d21" }]}
        >
          {txt}
        </Button>
      ) : (
        <Fab
          sx={[styleX.btnSubmit, { ...sizeDefault }]}
          onClick={onClick}
          aria-label="บันทึก/Save"
        >
          <Send />
        </Fab>
      )}
    </Tooltip>
  );
};

export const BtnConfirmRegister = ({
  IsDisabled = false,
  IsHisabled = false,
  onClick = () => { },
  txt = "ยืนยันการลงทะเบียน / Confirm",
  isCircleWithOutText = false,
  tooltipPlacement = "bottom",
}) => {
  return (
    <Tooltip placement={tooltipPlacement as any} title={txt}>

      <Button
        variant="contained"
        size="medium"
        disabled={IsDisabled === true ? true : false}
        hidden={IsHisabled === true ? true : false}
        onClick={onClick}
        sx={[styleX.btnSave, { border: "1px solid #797979", color: "#fff", backgroundColor: "#366d21" }]}
        startIcon={<CheckIcon />}
      >
        {txt}
      </Button>
    </Tooltip>
  );
};
export const BtnCancelRegister = ({
  IsDisabled = false,
  IsHisabled = false,
  onClick = () => { },
  txt = "ยกเลิกการลงทะเบียน / Cancel Register",
  isCircleWithOutText = false,
  tooltipPlacement = "bottom",
}) => {
  return (
    <Tooltip placement={tooltipPlacement as any} title={txt}>

      <Button
        variant="contained"
        size="medium"
        disabled={IsDisabled === true ? true : false}
        hidden={IsHisabled === true ? true : false}
        onClick={onClick}
        sx={[styleX.btnRed, { border: "1px solid #c80101", color: "#fff", backgroundColor: "#c80101" }]}
        startIcon={<CloseIcon />}
      >
        {txt}
      </Button>
    </Tooltip>
  );
};

export const BtnBackRegister = ({
  IsDisabled = false,
  IsHisabled = false,
  onClick = () => { },
  txt = "กลับ/Back",
  isCircleWithOutText = false,
  tooltipPlacement = "bottom",
}) => {
  return (
    <Tooltip placement={tooltipPlacement as any} title={txt}>
      <Button
        variant="contained"
        size="medium"
        disabled={IsDisabled === true ? true : false}
        hidden={IsHisabled === true ? true : false}
        onClick={onClick}
        sx={[styleX.btnBack, { border: "1px solid #797979", color: "#000", backgroundColor: "#ffffff" }]}
        startIcon={<ArrowBackIcon />}
      >
        {txt}
      </Button>
    </Tooltip>
  );
};

export const BtnPrintRegister = ({
  IsDisabled = false,
  IsHisabled = false,
  onClick = () => { },
  txt = "พิมพ์ใบสั่งจ่ายและใบหักภาษี ณ ที่จ่าย/Print invoice and Withholding Tax",
  isCircleWithOutText = false,
  tooltipPlacement = "bottom",
}) => {
  return (
    <Tooltip placement={tooltipPlacement as any} title={txt}>
      <Button
        variant="contained"
        size="medium"
        disabled={IsDisabled === true ? true : false}
        hidden={IsHisabled === true ? true : false}
        onClick={onClick}
        sx={{
          border: "1px solid #169bd5",
          backgroundColor: "#169bd5",
          color: "#ffffff",
          ":hover": { bgcolor: "#2fb8f4" },
        }}
        startIcon={<PrintIcon />}
      >
        {txt}
      </Button>
    </Tooltip>
  );
};

export const BtnSendForm = ({
  IsDisabled = false,
  IsHisabled = false,
  onClick = () => { },
  txt = "ส่งผลการประเมิน/Send",
  isCircleWithOutText = false,
  tooltipPlacement = "bottom",
}) => {
  return (
    <Tooltip placement={tooltipPlacement as any} title={txt}>
      {!isCircleWithOutText ? (
        <Button
          variant="contained"
          size="medium"
          startIcon={<CheckCircleRounded />}
          disabled={IsDisabled === true ? true : false}
          hidden={IsHisabled === true ? true : false}
          onClick={onClick}
          sx={[styleX.btnSubmit, { color: "#fff", backgroundColor: "#77b651" }]}
        >
          {txt}
        </Button>
      ) : (
        <Fab
          sx={[styleX.btnSubmit, { ...sizeDefault }]}
          onClick={onClick}
          aria-label="ส่งผลการประเมิน"
        >
          <Send />
        </Fab>
      )}
    </Tooltip>
  );
};
export const BtnCustomSearch = ({
  IsDisabled = false,
  IsHisabled = false,
  onClick = () => { },
  txt = "Search",
  isCircleWithOutText = false,
  tooltipPlacement = "bottom",
}) => {
  return (
    <Tooltip placement={tooltipPlacement as any} title={txt}>
      {!isCircleWithOutText ? (
        <Button
          variant="contained"
          size="medium"
          startIcon={<Search />}
          disabled={IsDisabled === true ? true : false}
          hidden={IsHisabled === true ? true : false}
          onClick={onClick}

          //sx={styleX.btnSetting}
          sx={{
            backgroundColor: "#0275d8",
            dcolor: "#F3F3F3",
            "&:hover": {
              backgroundColor: "#474748",
            },
            margin: "8",
            borderRadius: "16px",
            justifyContent: "space-between",
          }}
        >
          {txt}
        </Button>
      ) : (
        <Fab
          //sx={[styleX.btnSetting, { ...sizeDefault }]}
          sx={{
            backgroundColor: "#0275d8",
            color: "#F3F3F3",
            "&:hover": {
              backgroundColor: "#528E52",
            },
            margin: "1",
            borderRadius: "16px",
            justifyContent: "space-between",
          }}
          color="default"
          onClick={onClick}
          aria-label="search"
        >
          <Search />
        </Fab>
      )}
    </Tooltip>
  );
};
export const BtnEditFilmRed = ({
  IsDisabled = false,
  IsHisabled = false,
  txt = "Edit",
  onClick = () => { },
}) => (
  <GridActionsCellItem
    disabled={IsDisabled}
    hidden={IsHisabled}
    icon={
      <Tooltip title={txt}>
        <ModeEditIcon />
      </Tooltip>
    }
    label={txt}
    sx={{
      backgroundColor: "red",
      color: "#ffffff",
      ":hover": { bgcolor: "#2fb8f4" },
    }}
    onClick={onClick}
    aria-label={txt}
  />
);

export const BtnSendCutom = ({
  IsDisabled = false,
  onClick = () => { },
  txt = "ส่ง/Send",
  tooltipPlacement = "bottom",
  sxStyle = {},
}) => {
  return (
    <Tooltip placement={tooltipPlacement as any} title={txt}>
      <Button
        variant="contained"
        size="medium"
        disabled={IsDisabled === true ? true : false}
        onClick={onClick}
        sx={{
          backgroundColor: "#1A6F0C",
          color: "#F3F3F3",
          "&:hover": {
            backgroundColor: "#169a00",
          },
          margin: "1",
          borderRadius: "16px",
          justifyContent: "space-between",
          ...sxStyle
        }}
      >
        {txt}

      </Button>
    </Tooltip>
  );
};

export const BtnDownloadTemplate = ({
  IsDisabled = false,
  onClick = () => { },
  txt = "Download Template",
  tooltipPlacement = "bottom",
}) => {
  return (
    <Tooltip placement={tooltipPlacement as any} title={txt}>
      <Button
        variant="contained"
        size="medium"
        disabled={IsDisabled === true ? true : false}
        onClick={onClick}
        sx={{
          border: "1px solid #005f8b",
          color: "#fff",
          backgroundColor: "#005f8b",
          "&:hover": {
            backgroundColor: "#005f8b",
          },
        }}
        startIcon={<DownloadIcon />}
      >
        {txt}
      </Button>
    </Tooltip>
  );
};

export const BtnImport = ({
  IsDisabled = false,
  onClick = () => { },
  txt = "Import รายชื่อผู้เข้าอบรม",
  tooltipPlacement = "bottom",
}) => {
  return (
    <Tooltip placement={tooltipPlacement as any} title={txt}>
      <Button
        variant="contained"
        size="medium"
        disabled={IsDisabled === true ? true : false}
        onClick={onClick}
        sx={{
          border: "1px solid #02a7f0",
          color: "#fff",
          backgroundColor: "#02a7f0",
          "&:hover": {
            backgroundColor: "#02a7f0",
          },
        }}
        startIcon={<UploadIcon />}
      >
        {txt}
      </Button>
    </Tooltip>
  );
};