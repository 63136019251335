const en = {
  common: {
    or: 'หรือ',
    cancel: 'ยกเลิก',
    reset: 'รีเซ็ต',
    save: 'Save',
    search: 'Search',
    edit: 'แก้ไข',
    new: 'ใหม่',
    export: 'ดาวน์โหลด Excel',
    noDataToExport: 'No data to export',
    import: 'Import',
    discard: 'Discard',
    yes: 'Yes',
    no: 'No',
    pause: 'หยุด',
    areYouSure: 'Are you sure?',
    view: 'View',
    destroy: 'ทำลาย',
    mustSelectARow: 'Must select a row',
    start: 'เริ่ม',
    end: 'หยุด',
    select: 'เลือก',
    continue: 'Continue',
    filters: 'Filters',
    add: 'Add',
    clear: 'Clear',
    back: 'Back',
    ok: "OK",
    close: "ปิด",
    btnConfirm: "ยืนยัน",

    confirm: "ยืนยัน",
    Success: "สำเร็จ",
    Warning: "คำเตือน",
    Error: "ข้อผิดพลาด",
    Info: "ข้อมูล",
    Hint: "Hint",
    Duplicate: "Duplicate",
    SaveComplete: "บันทึกข้อมูลเรียบร้อยแล้ว",
    SaveDraftComplete: "บันทึกข้อมูลร่างแล้ว",
    SaveSubmitComplete: "ส่งข้อมูลเรียบร้อยแล้ว",
    SaveRequestEditComplete:'ได้ขอแก้ไขข้อมูลแล้ว',
    SaverejectComplete: 'ปฏิเสธข้อมูลแล้ว',
    SaveCancelComplete: 'ยกเลิกรายการสำเร็จ',
    SaveApproveComplete: 'อนุมัติเรียบร้อย',
    ImportComplete: 'Already import data.',
    DeleteComplete: "ลบข้อมูลเรียบร้อยแล้ว.",
    ErrorSomething: "Something went wrong, please contact your system administrator.",
    ConfirmSave: "คุณต้องการบันทึกข้อมูลหรือไม่?",
    ConfirmDelete: "คุณต้องการลบข้อมูลหรือไม่?",
    ConfirmCancel: "คุณต้องการยกเลิกหรือไม่",
    NoPermission: "คุณไม่ได้รับอนุญาตให้เข้าถึงหน้านี้",
    SessionTimeOut: "กรุณาเข้าสู่ระบบ",
    DeleteFail: 'Please select data to delete !',
    UploadImage: "Please upload image.",
    confirmSubmit: "ต้องการส่งข้อมูล ?",
    confirmDraft: "คุณต้องการบันทึกข้อมูลร่างหรือไม่?",
    confirmRequestEdit: "คุณต้องการขอแก้ไขหรือไม่?",
    confirmApprove: "คุณต้องการอนุมัติหรือไม่?",
    confirmExportPDF: "Do you want to export PDF?",
    confirmAppointment: "Do you want to appointment?",
    confirmReview: "Do you want to review?",
    confirmMsg: "Do you want to confirm?",
    confirmTopicMsg: "Do you want to assign committee to scoring?",
    confirmAssign: "Do you want to assign?",
    confirmLogout: "คุณต้องการที่จะออกจากระบบ ?",
    confirmReject: "คุณต้องการที่จะปฏิเสธ?",
    confirmImport:'Do you want to import data?',
    deleteMessage: "Do you want to delete ?",
  },

  app: {
    title: "Scaffolding",
  },
  // auth.login.fields
  entities: {
    authen: {
      fields: {
        sUsername: "Username",
        sPassword: "Password",       
      },
    },
    
    search: {
      fields: {
        sSearch: "Search",     
      },
    },
    contactUs: {
      fields: {
        lstTopic: "หัวข้อ / Topic",     
        sRequirement: "ความต้องการ / Your Requirement",     
        sName: "ชื่อ-สกุล / Your Name ",     
        sEmail: "อีเมล / E-mail",     
        sTel: "เบอร์โทรศัพท์ / Tel.",     
        lstContact: "สะดวกให้ติดต่อกลับโดย / How would you like us to contact you? ",     
        Address: "ที่อยู่ / Address (ต้องลงท้ายด้วยรหัสไปรษณีย์)",     

      },
    },
    RegisReject: {
      fields: {
        sReason:"เหตุผล / Reason"
      },
    },
    ChangePassword: {
      fields: {
        sPassword:"รหัสผ่าน/Password",
        sConfirmNewPassword:"ยืนยันรหัสผ่านใหม่/New Password Confirmation",
        sNewPassword:"รหัสผ่านใหม่/New Password",
      },
    },
    
  },

  errors: {
    backToHome: "Back to home",
    403: `Sorry, you don't have access to this page`,
    404: "Sorry, the page you visited does not exist",
    500: "Sorry, the server is reporting an error",
    429: "Too many requests. Please try again later.",
    forbidden: {
      message: "Forbidden",
    },
    validation: {
      message: "An error occurred",
    },
    defaultErrorMessage: "Ops, an error occurred",
  },
  // See https://github.com/jquense/yup#using-a-custom-locale-dictionary
  /* eslint-disable */
  validation: {
    mixed: {
      default: "${path} ไม่ถูกต้อง",
      required: "โปรดระบุ ${path}",
      oneOf: "${path} must be one of the following values: ${values}",
      notOneOf: "${path} must not be one of the following values: ${values}",
      notType: ({ path, type, value, originalValue }) => {
        //return `${path} must be a ${type}`;
        return `${path} ต้องเป็น ${type}`;
      },
    },
    string: {
      length: "${path} must be exactly ${length} characters",
      min: "${path} ต้องมีความยาวรวมไม่น้อยกว่า ${min} ตัวอักษร",
      max: "${path} must be at most ${max} characters",
      matches: '${path} must match the following: "${regex}"',
      email: "${path} อีเมลต้องเป็นอีเมลที่ถูกต้อง",
      url: "${path} must be a valid URL",
      trim: "${path} must be a trimmed string",
      lowercase: "${path} must be a lowercase string",
      uppercase: "${path} must be a upper case string",
      selected: "${path} must be selected",
    },
    number: {
      min: "${path} must be greater than or equal to ${min}",
      max: "${path} must be less than or equal to ${max}",
      lessThan: "${path} must be less than ${less}",
      moreThan: "${path} must be greater than ${more}",
      notEqual: "${path} must be not equal to ${notEqual}",
      positive: "${path} must be a positive number",
      negative: "${path} must be a negative number",
      integer: "${path} must be an integer",
    },
    date: {
      min: "${path} field must be later than ${min}",
      max: "${path} field must be at earlier than ${max}",
    },
    boolean: {},
    object: {
      noUnknown:
        "${path} field cannot have keys not specified in the object shape",
    },
    array: {
      min: ({ min, path }) =>
        min === 1
          ? `โปรดเลือก ${path}`
          : `${path} field must have at least ${min} items`,
      max: "${path} field must have less than or equal to ${max} items",
    },
  },

  autocomplete: {
    loading: "Loading...",
    noOptions: "No data found",
  },

  table: {
    noData: "No records found",
    loading: "Loading...",
  },

  pagination: {
    labelDisplayedRows: "{0}-{1} of {2}",
    labelRowsPerPage: "Per page:",
    of: "of",
  },
};

export default en;
