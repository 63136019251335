import about from "../pages/main/about";
import Layout from "../Layout";
import home from "../pages/home/home";
import information from "../pages/main/information";
import allComponent from "../pages/main/allComponent";
import intro from "src/pages/intro/intro";
// import Regiter from "src/Components/Register/Regiter";
import ForgetUsername from "src/Components/UserName/ForgetUsername";
import login from "../pages/login/login";
import Register from "src/Components/Register/Register";
import ResetPassWord from "src/Components/Password/ResetPassWord";
import ForgetPassword from "src/Components/Password/ForgetPassword";
import ForgetPasswordfail from "src/Components/Password/ForgetPasswordfail";
import PasswordSuccess from "src/Components/Password/PasswordSuccess";
import EventPhoto from "src/Components/EventPhoto/EventPhoto";
import EventDetail from "src/Components/EventPhoto/EventDetail";
import Vision from "src/Components/Vision/Vision";
import General from "src/Components/GeneralKnowledge/General";
import instructor from "src/pages/main/instructor";
import ContactUs from "src/pages/ContactUs/ContactUs";
import { NewsDetails } from "src/Components/News/NewsDetail";
import SelectProfeil from "src/Components/Password/SelectProfile";
import { NewsHome } from "src/Components/NewsHome";
import WhatsNewDetails from "src/Components/WhatsNewsHome/WhatsNewDetails";
import ChangePassword from "src/Components/Password/ChangePassword";
import ResultSearch from "src/pages/ResultSearch/ResultSearch";
import SelectProfile from "src/Components/Password/SelectProfile";
import { NewsList } from "src/Components/News/NewsList";
import KnowledgeSharingList from "src/pages/knowledgeSharing/KnowledgeSharing";
import Registration from "src/pages/Register/Registration";
import RegisterTrackingDealer from "src/pages/Register/RegisterTrackingDealer";
import RegisterTrackingMember from "src/pages/Register/RegisterTrackingMember";
import Classroom from "src/Components/AssessmentForm/Classroom";
import CMSContent from "src/Components/CMS";
import OnlineDetail from "src/pages/Course/OnlineDetail";
import CourseList from "src/pages/Course/CourseList";
import TrainingHistory from "src/pages/TrainingHistory/TrainingHistory";
import HistoryTrainEmp from "src/pages/TrainingHistory/HistoryTrainEmp";
import ManageProfileTable from "src/Components/Profile/ManageProfileTable";
import ManageProfile_Edit from "src/Components/Profile/ManageProfile_Edit";
import test from "src/pages/testModal/test";
import ManageStaffForm from "src/Components/Staff/ManageStaffForm";
import ManageStaffTable from "src/Components/Staff/ManageStaffTable";
import Questionnaire from "src/Components/Questionnaire/Questionnaire";
import ClassroomRegister from "src/Components/Courseware/ClassroomDetail/ClassroomRegister";
import Classroom_RegisterWaiting from "src/Components/Courseware/ClassroomDetail/ClassroomRegisterWaiting";
import TradingHistory from "src/pages/TrainingHistory/TrainingHistory";
import AccountEdit from "src/Components/Account/AccountEdit";
import LoginBypass from "src/pages/LoginBypass/LoginBypass";
import historyFAQ_list from "src/pages/TrainingHistory/HistoryFAQ/historyFAQ_list";
import AnswerFAQ from "src/pages/TrainingHistory/HistoryFAQ/AnswerFAQ";
import RenewStaff from "src/Components/Staff/RenewStaff";
import QuestionnaireNew from "src/Components/Questionnaire/QuestionnaireNew";


const route = [
  {
    path: "",
    component: intro,
    layout: Layout,
    exact: true,
    propsLayout: {
      onDisClickLogo: true,
      showComName: true,
      showMenu: false,
      showHeader: true,
      showfooter: false,
      showPageDown: false,
      showPageUp: false,
    },
  },
  {
    path: "home",
    component: home,
    layout: Layout,
    exact: true,
    propsLayout: {
      showMenu: true,
      showHeader: true,
      showfooter: true,
      showPageDown: true,
      showPageUp: true,
    },
  },
  {
    path: "about",
    component: about,
    layout: Layout,
    exact: true,
    menuID: 44,
    propsLayout: {
      showMenu: true,
      showHeader: true,
      showfooter: true,
      showPageDown: true,
      showPageUp: true,
    },
  },
  {
    path: "new-activity",
    component: information,
    layout: Layout,
    exact: true,
    propsLayout: {
      showMenu: true,
      showHeader: true,
      showfooter: true,
      showPageDown: true,
      showPageUp: true,
    },
  },
  {
    path: "allComponent",
    component: allComponent,
    layout: Layout,
    exact: true,
    menuID: 50,
    propsLayout: {
      showMenu: true,
      showHeader: true,
      showfooter: true,
      showPageDown: true,
      showPageUp: true,
    },
  }
  ,
  {
    path: "instructor",
    component: instructor,
    layout: Layout,
    exact: true,
    menuID: 10,
    hMenuID: 1,
    propsLayout: {
      showMenu: true,
      showHeader: true,
      showfooter: true,
      showPageDown: true,
      showPageUp: true,
    },
  },
  {
    path: "Register",
    component: Register,
    layout: Layout,
    exact: true,
    menuID: 98,
    propsLayout: {
      showMenu: true,
      showHeader: true,
      showfooter: true,
      showPageDown: true,
      showPageUp: true,
    },
  },
  {
    path: "ResetPassWord",
    component: ResetPassWord,
    layout: Layout,
    exact: true,
    propsLayout: {
      showMenu: true,
      showHeader: true,
      showfooter: true,
      showPageDown: true,
      showPageUp: true,
    },
  },
  {
    path: "ForgetPassword",
    component: ForgetPassword,
    layout: Layout,
    exact: true,
    propsLayout: {
      showMenu: true,
      showHeader: true,
      showfooter: true,
      showPageDown: true,
      showPageUp: true,
    },
  },
  {
    path: "ForgetPasswordfail",
    component: ForgetPasswordfail,
    layout: Layout,
    exact: true,
    propsLayout: {
      showMenu: true,
      showHeader: true,
      showfooter: true,
      showPageDown: true,
      showPageUp: true,
    },
  },
  {
    path: "PasswordSuccess",
    component: PasswordSuccess,
    layout: Layout,
    exact: true,
    propsLayout: {
      showMenu: true,
      showHeader: true,
      showfooter: true,
      showPageDown: true,
      showPageUp: true,
    },
  },
  {
    path: "EventPhoto",
    component: EventPhoto,
    layout: Layout,
    exact: true,
    menuID: 21,
    hMenuID: 4,
    propsLayout: {
      showMenu: true,
      showHeader: true,
      showfooter: true,
      showPageDown: true,
      showPageUp: true,
    },
  },
  {
    path: "EventDetail",
    component: EventDetail,
    layout: Layout,
    exact: true,
    menuID: 21,
    hMenuID: 4,
    propsLayout: {
      showMenu: true,
      showHeader: true,
      showfooter: true,
      showPageDown: true,
      showPageUp: true,
    },
  },
  {
    path: "ForgetUserName",
    component: ForgetUsername,
    layout: Layout,
    exact: true,
    propsLayout: {
      showMenu: true,
      showHeader: true,
      showfooter: true,
      showPageDown: true,
      showPageUp: true,
    },
  },
  {
    path: "Vision",
    component: Vision,
    layout: Layout,
    exact: true,
    menuID: 6,
    hMenuID: 1,
    propsLayout: {
      showMenu: true,
      showHeader: true,
      showfooter: true,
      showPageDown: true,
      showPageUp: true,
    },
  },
  {
    path: "General",
    component: General,
    layout: Layout,
    exact: true,
    menuID: [3],
    propsLayout: {
      showMenu: true,
      showHeader: true,
      showfooter: true,
      showPageDown: true,
      showPageUp: true,
    },
  },
  {
    path: "cms-content",
    component: CMSContent,
    layout: Layout,
    exact: true,
    propsLayout: {
      showMenu: true,
      showHeader: true,
      showfooter: true,
      showPageDown: true,
      showPageUp: true,
    },
  },
  {
    path: "NewsHome",
    component: NewsHome,
    layout: Layout,
    exact: true,
    propsLayout: {
      showMenu: true,
      showHeader: true,
      showfooter: true,
      showPageDown: true,
      showPageUp: true,
    },
  },
  {
    path: "NewsList",
    component: NewsList,
    layout: Layout,
    exact: true,
    menuID: 20,
    hMenuID: 4,
    propsLayout: {
      showMenu: true,
      showHeader: true,
      showfooter: true,
      showPageDown: true,
      showPageUp: true,
    },
  },
  {
    path: "NewsDetail",
    component: NewsDetails,
    layout: Layout,
    exact: true,
    menuID: [31, 4],
    hMenuID: 20,
    propsLayout: {
      showMenu: true,
      showHeader: true,
      showfooter: true,
      showPageDown: true,
      showPageUp: true,
    },
  },
  {
    path: "login",
    component: login,
    layout: Layout,
    exact: true,
    menuID: 99,
    propsLayout: {
      showMenu: true,
      showHeader: true,
      // showfooter: true,
      showPageDown: true,
      showPageUp: true,
    },
  },
  {
    path: "ContactUs",
    component: ContactUs,
    layout: Layout,
    exact: true,
    menuID: 5,
    propsLayout: {
      showMenu: true,
      showHeader: true,
      showfooter: true,
      showPageDown: true,
      showPageUp: true,
    },
  },
  {
    path: "WhatsNewDetails",
    component: WhatsNewDetails,
    layout: Layout,
    exact: true,
    propsLayout: {
      showMenu: true,
      showHeader: true,
      showfooter: true,
      showPageDown: true,
      showPageUp: true,
    },
  },
  {
    path: "ChangePassword",
    component: ChangePassword,
    layout: Layout,
    exact: true,
    menuID: 102,
    propsLayout: {
      showMenu: true,
      showHeader: true,
      showfooter: true,
      showPageDown: true,
      showPageUp: true,
    },
  },
  {
    path: "ResultSearch",
    component: ResultSearch,
    layout: Layout,
    exact: true,
    propsLayout: {
      showMenu: true,
      showHeader: true,
      showfooter: true,
      showPageDown: true,
      showPageUp: true,
    },
  },
  {
    path: "SelectProfile",
    component: SelectProfile,
    layout: Layout,
    exact: true,
    propsLayout: {
      onDisClickLogo: true,
      showMenu: false,
      showHeader: false,
      showfooter: false,
      showPageDown: false,
      showPageUp: false,
    },
  },
  {
    path: "Questionnaire",
    component: Questionnaire,
    layout: Layout,
    exact: true,
    propsLayout: {
      onDisClickLogo: true,
      showMenu: false,
      showHeader: true,
      showfooter: false,
      showPageDown: false,
      showPageUp: false,
    },
  },
  {
    path: "QuestionnaireNew",
    component: QuestionnaireNew,
    layout: Layout,
    exact: true,
    propsLayout: {
      onDisClickLogo: true,
      showMenu: false,
      showHeader: true,
      showfooter: false,
      showPageDown: false,
      showPageUp: false,
    },
  },
  {
    path: "KnowledgeSharingList",
    component: KnowledgeSharingList,
    layout: Layout,
    exact: true,
    menuID: 3,
    propsLayout: {
      showMenu: true,
      showHeader: true,
      showfooter: true,
      showPageDown: true,
      showPageUp: true,
    },
  },
  {
    path: "Registration",
    component: Registration,
    layout: Layout,
    exact: true,
    propsLayout: {
      showMenu: true,
      showHeader: true,
      showfooter: true,
      showPageDown: true,
      showPageUp: true,
    },
  },
  {
    path: "RegisterTrackingDealer",
    component: RegisterTrackingDealer,
    layout: Layout,
    exact: true,
    propsLayout: {
      showMenu: true,
      showHeader: true,
      showfooter: true,
      showPageDown: true,
      showPageUp: true,
    },
  },
  {
    path: "RegisterTrackingMember",
    component: RegisterTrackingMember,
    layout: Layout,
    exact: true,
    propsLayout: {
      showMenu: true,
      showHeader: true,
      showfooter: true,
      showPageDown: true,
      showPageUp: true,
    },
  },
  {
    path: "Classroom",
    component: Classroom,
    layout: Layout,
    exact: true,
    propsLayout: {
      showMenu: true,
      showHeader: true,
      showfooter: true,
      showPageDown: true,
      showPageUp: true,
    }
  },
  {
    path: "OnlineDetail",
    component: OnlineDetail,
    layout: Layout,
    exact: true,
    menuID: [28, 2],
    propsLayout: {
      showMenu: true,
      showHeader: true,
      showfooter: true,
      showPageDown: true,
      showPageUp: true,
    }
  },
  {
    path: "CourseList",
    component: CourseList,
    layout: Layout,
    exact: true,
    propsLayout: {
      showMenu: true,
      showHeader: true,
      showfooter: true,
      showPageDown: true,
      showPageUp: true,
    }
  },
  {
    path: "ManageProfileTable",
    component: ManageProfileTable,
    layout: Layout,
    exact: true,
    menuID: 103,
    propsLayout: {
      showMenu: true,
      showHeader: true,
      showfooter: true,
      showPageDown: true,
      showPageUp: true,
    }
  },
  {
    path: "ManageStaffTable",
    component: ManageStaffTable,
    layout: Layout,
    exact: true,
    menuID: 104,
    propsLayout: {
      showMenu: true,
      showHeader: true,
      showfooter: true,
      showPageDown: true,
      showPageUp: true,
    }
  },
  {
    path: "ManageStaffForm",
    component: ManageStaffForm,
    layout: Layout,
    exact: true,
    propsLayout: {
      showMenu: true,
      showHeader: true,
      showfooter: true,
      showPageDown: true,
      showPageUp: true,
    }
  },
  {
    path: "HistoryTrainEmp",
    component: HistoryTrainEmp,
    layout: Layout,
    exact: true,
    propsLayout: {
      showMenu: true,
      showHeader: true,
      showfooter: true,
      showPageDown: true,
      showPageUp: true,
    }
  },
  {
    path: "test",
    component: test,
    layout: Layout,
    exact: true,
    propsLayout: {
      showMenu: true,
      showHeader: true,
      showfooter: true,
      showPageDown: true,
      showPageUp: true,
    }
  },
  {
    path: "ManageProfile_Edit",
    component: ManageProfile_Edit,
    layout: Layout,
    exact: true,
    propsLayout: {
      showMenu: true,
      showHeader: true,
      showfooter: true,
      showPageDown: true,
      showPageUp: true,
    }
  },
  {
    path: "TrainingHistory",
    component: TrainingHistory,
    layout: Layout,
    menuID: 101,
    exact: true,
    propsLayout: {
      showMenu: true,
      showHeader: true,
      showfooter: true,
      showPageDown: true,
      showPageUp: true,
    }
  },
  {
    path: "ClassroomRegister",
    component: ClassroomRegister,
    layout: Layout,
    exact: true,
    propsLayout: {
      showMenu: true,
      showHeader: true,
      showfooter: true,
      showPageDown: true,
      showPageUp: true,
    }
  },
  {
    path: "AccountEdit",
    component: AccountEdit,
    layout: Layout,
    menuID: 100,
    exact: true,
    propsLayout: {
      showMenu: true,
      showHeader: true,
      showfooter: true,
      showPageDown: true,
      showPageUp: true,
    }
  },
  {
    path: "LoginBypass",
    component: LoginBypass,
    layout: Layout,
    exact: true,
    propsLayout: {
      onDisClickLogo: true,
      showComName: true,
      showMenu: false,
      showHeader: true,
      showfooter: false,
      showPageDown: false,
      showPageUp: false,
    },
  },
  {
    path: "historyFAQ_list",
    component: historyFAQ_list,
    layout: Layout,
    exact: true,
    menuID: 105,
    propsLayout: {
      showMenu: true,
      showHeader: true,
      showfooter: true,
      showPageDown: true,
      showPageUp: true,
    }
  },
  {
    path: "AnswerFAQ",
    component: AnswerFAQ,
    layout: Layout,
    exact: true,
    propsLayout: {
      showComName: true,
      showMenu: false,
      showHeader: true,
      showfooter: false,
      showPageDown: true,
      showPageUp: false,
    },
  },
  {
    path: "RenewStaff",
    component: RenewStaff,
    layout: Layout,
    exact: true,
    propsLayout: {
      showMenu: true,
      showHeader: true,
      showfooter: true,
      showPageDown: true,
      showPageUp: true,
    }
  },
  {
    path: "Classroom_RegisterWaiting",
    component: Classroom_RegisterWaiting,
    layout: Layout,
    exact: true,
    propsLayout: {
      showMenu: true,
      showHeader: true,
      showfooter: true,
      showPageDown: true,
      showPageUp: true,
    }
  },

];
export default route;