import { Card, Grid, Typography } from '@mui/material';
import CardContent from '@mui/material/CardContent';
import './Register.css'

const NameBox = (item) => {
    return (
        <>
        <div className={"box arrow-right " + item.sNameBox.classname}  > 
                <CardContent  sx={{ width: 360, height: 100}}  >
                    <Grid container>
                        <Grid item xs={12}>
                            <Grid container  >
                                <Grid item xs={12}>
                                    <Typography fontSize={20} color="#FFFFFF" >
                                        {item.sNameBox.sName}
                                    </Typography>
                                </Grid>
                            </Grid>
                            <Grid item xs={12}>
                                <Typography fontSize={16} color="#FFFFFF">
                                    {item.sNameBox.sDesc}
                                </Typography>
                            </Grid>
                        </Grid>
                    </Grid>
                </CardContent>
             </div> 
        </>

    )
}
export default NameBox
