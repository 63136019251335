import axios from "axios";
import { i18n } from "src/i18n";
import { SwAlert, AlertTitle } from "src/Components/Alert/Sweetalert";
import { Encrypt, lnkToLogin, lnkToHomePage, IsNullOrUndefined } from "src/CommonFunction/TS_function";
import React from "react";
import moment from "moment";
import { ResultAPI } from 'src/CommonFunction/TS_function';
import qs from "qs";

export const Axios = async (
  sWebMetodName,
  objJSON,
  method,
  // dataField,
  fnSuccess?,
  fnError?,
  BlockUI?,
  UnBlockUI?,
  fnIsLogin?
) => {
  let auth_token =
    localStorage.getItem(process.env.REACT_APP_JWT_KEY as string) != null
      ? localStorage.getItem(process.env.REACT_APP_JWT_KEY as string)
      : undefined;

  if (BlockUI) BlockUI();
  await axios({
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      "Company-Token": `${process.env.REACT_APP_JWT_KEY}`,
      Authorization:
        auth_token && auth_token !== "" ? `Bearer ${auth_token}` : "",
    },
    method: method,
    url:
      process.env.REACT_APP_API +
      "api/" +
      sWebMetodName +
      (method == "Get" ? (Object.keys(objJSON).length !== 0 ? "?" + objJSON : "") : ""),
    data: objJSON || {},
  })
    .then((res) => {
      if (fnSuccess) fnSuccess(res.data);
    })
    .catch(function (errors) {
      console.log(
        process.env.REACT_APP_API + "api/" + sWebMetodName + " ERR Code ",
        errors
      );
      if (errors.response) {
        if (errors.response.status === 401) {         
          if (fnIsLogin) {
            fnIsLogin();
          } else {
            SwAlert.Warning(
              undefined,
              i18n("common.SessionTimeOut"),
              lnkToHomePage
            );
          }
        } else if (errors.response.status === 500) {
          SwAlert.Warning(undefined, i18n("common.ErrorSomething"));
        } else if (errors.response.status === 400) {
        }
      } else if (fnError) {
        fnError();
      }
    })
    .then((res) => {
      if (UnBlockUI) UnBlockUI();
    });
};
export const AxiosGetAsync = async (
  sWebMetodName,
  objJSON,
  fnSuccess?,
  fnError?,
  fnComplete?
) => {
  let localJwtKey = process.env.REACT_APP_JWT_KEY as string;
  let auth_token =
    localStorage.getItem(localJwtKey) != null
      ? (localStorage.getItem(localJwtKey) as string)
      : undefined;
  let ConfigJwt = {
    Authorization: auth_token && auth_token !== "" ? `Bearer ${auth_token}` : "",
  };

  let sPathApi = process.env.REACT_APP_API + "api/" + sWebMetodName;
  const response = await axios.get(sPathApi, {
    headers: ConfigJwt,
    params: objJSON,
  })
  return response.data;
}

export const AxiosPostFilePDF = async (
  sWebMetodName,
  objJSON,
  sfileName,
  fnSuccess?,
  fnError?,
  BlockUI?,
  UnBlockUI?,

) => {
  let auth_token =
    localStorage.getItem(process.env.REACT_APP_JWT_KEY as string) != null
      ? localStorage.getItem(process.env.REACT_APP_JWT_KEY as string)
      : undefined;

  if (BlockUI) BlockUI();
  await axios({
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      "Company-Token": `${process.env.REACT_APP_JWT_KEY}`,
      Authorization: auth_token !== "" ? `Bearer ${auth_token}` : "",
    },
    method: "post",
    url: process.env.REACT_APP_API + "api/" + sWebMetodName,
    data: objJSON || {},
    responseType: "blob",
  })
    .then((res) => {
      var blob = new Blob([res.data], {
        type: "application/pdf",
      });
      // let fileName = res.headers["content-disposition"]
      //   .split("filename=")[1]
      //   .split(";")[0];
  
      let fileName = sfileName || "Invoice.pdf";
      const link = document.createElement("a");
      link.target = "_blank";
      var objectUrl = URL.createObjectURL(blob);
      link.href = objectUrl;
      link.setAttribute("download", fileName);
      link.setAttribute("visibility", "hidden");
      link.click();
      link.remove();
      if (fnSuccess) fnSuccess(res);
    })
    .catch((errors) => {
      console.log("errors", errors);
      if (errors.response) {
        if (errors.response.status === 401) {
          SwAlert.Warning(undefined, i18n("common.SessionTimeOut"), lnkToHomePage);
        } else if (errors.response.status === 500) {
          SwAlert.Warning(undefined, i18n("common.ErrorSomething"));
        }
      } else if (fnError) {
        fnError();
      }
    })
    .then((res) => {
      if (UnBlockUI) UnBlockUI();
    });
};

export const AxiosPostPrintFile = async (
  sWebMetodName,
  objJSON,
  fnSuccess?,
  fnError?,
  BlockUI?,
  UnBlockUI?
) => {
  let auth_token =
    localStorage.getItem(process.env.REACT_APP_JWT_KEY as string) != null
      ? localStorage.getItem(process.env.REACT_APP_JWT_KEY as string)
      : undefined;

  if (BlockUI) BlockUI();
  await axios({
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      "Company-Token": `${process.env.REACT_APP_JWT_KEY}`,
      Authorization: auth_token !== "" ? `Bearer ${auth_token}` : "",
    },
    method: "post",
    url: process.env.REACT_APP_API + "api/" + sWebMetodName,
    data: objJSON || {},
    // responseType: "blob",
  })
    .then((res) => {
      res.data.lstData.forEach((f) => {
        let urlEncode = Encrypt(f);
        window.open(`PrintPDF?str=${urlEncode}`);
      });
      if (fnSuccess) fnSuccess(res);
    })
    .catch((errors) => {
      console.log("errors", errors);
      if (errors.response) {
        if (errors.response.status === 401) {
          SwAlert.Warning(undefined, i18n("common.SessionTimeOut"), lnkToHomePage);
        } else if (errors.response.status === 500) {
          SwAlert.Warning(undefined, i18n("common.ErrorSomething"));
        }
      } else if (fnError) {
        fnError();
      }
    })
    .then((res) => {
      if (UnBlockUI) UnBlockUI();
    });
};

export const AxiosPostFileText = async (
  sWebMetodName,
  objJSON,
  fnSuccess?,
  fnError?,
  BlockUI?,
  UnBlockUI?
) => {
  let auth_token =
    localStorage.getItem(process.env.REACT_APP_JWT_KEY as string) != null
      ? localStorage.getItem(process.env.REACT_APP_JWT_KEY as string)
      : undefined;

  if (BlockUI) BlockUI();
  await axios({
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      "Company-Token": `${process.env.REACT_APP_JWT_KEY}`,
      Authorization: auth_token !== "" ? `Bearer ${auth_token}` : "",
    },
    method: "post",
    url: process.env.REACT_APP_API + "api/" + sWebMetodName,
    data: objJSON || {},
    responseType: "blob",
  })
    .then((res) => {
      var blob = new Blob([res.data], { type: "text/plain" });
      const link = document.createElement("a");
      link.target = "_blank";
      var objectUrl = URL.createObjectURL(blob);
      let fileName = res.headers["content-disposition"]
        .split("filename=")[1]
        .split(";")[0];
      link.href = objectUrl;
      link.setAttribute("download", fileName);
      link.setAttribute("visibility", "hidden");
      link.click();
      link.remove();
      if (fnSuccess) fnSuccess(res);
    })
    .catch((errors) => {
      console.log("errors", errors);
      if (errors.response) {
        if (errors.response.status === 401) {
          SwAlert.Warning(undefined, i18n("common.SessionTimeOut"), lnkToHomePage);
        } else if (errors.response.status === 500) {
          SwAlert.Warning(undefined, i18n("common.ErrorSomething"));
        }
      } else if (fnError) {
        fnError();
      }
    })
    .then((res) => {
      if (UnBlockUI) UnBlockUI();
    });
};
export const AxiosPostImage = async (
  sWebMetodName,
  objJSON,
  fnSuccess?,
  fnError?,
  BlockUI?,
  UnBlockUI?
) => {
  let auth_token =
    localStorage.getItem(process.env.REACT_APP_JWT_KEY as string) != null
      ? localStorage.getItem(process.env.REACT_APP_JWT_KEY as string)
      : undefined;

  if (BlockUI) BlockUI();
  await axios({
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      "Company-Token": `${process.env.REACT_APP_JWT_KEY}`,
      Authorization: auth_token !== "" ? `Bearer ${auth_token}` : "",
    },
    method: "post",
    url: process.env.REACT_APP_API + "api/" + sWebMetodName,
    data: objJSON || {},
    responseType: "blob",
  })
    .then((res) => {
      var blob = new Blob([res.data], { type: "image/jpeg" });
      
      const link = document.createElement("img");
      
      var objectUrl = URL.createObjectURL(blob);
      
      if (fnSuccess) fnSuccess(objectUrl);
    })
    .catch((errors) => {
      console.log("errors", errors);
      if (errors.response) {
        if (errors.response.status === 401) {
          SwAlert.Warning(undefined, i18n("common.SessionTimeOut"), lnkToHomePage);
        } else if (errors.response.status === 500) {
          SwAlert.Warning(undefined, i18n("common.ErrorSomething"));
        }
      } else if (fnError) {
        fnError();
      }
    })
    .then((res) => {
      if (UnBlockUI) UnBlockUI();
    });
};
export const AxiosPostFileExcel = async (
  sWebMetodName,
  objJSON,
  fnSuccess?,
  fnError?,
  BlockUI?,
  UnBlockUI?,
  FileName?
) => {
  let auth_token =
    localStorage.getItem(process.env.REACT_APP_JWT_KEY as string) != null
      ? localStorage.getItem(process.env.REACT_APP_JWT_KEY as string)
      : undefined;

  if (BlockUI) BlockUI();
  await axios({
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      "Company-Token": `${process.env.REACT_APP_JWT_KEY}`,
      Authorization: auth_token !== "" ? `Bearer ${auth_token}` : "",
    },
    method: "post",
    url: process.env.REACT_APP_API + "api/" + sWebMetodName,
    data: objJSON || {},
    responseType: "blob",
  })
    .then((res) => {
      console.log("AxiosPostFileExcel", res);
      
      var blob = new Blob([res.data], {
        type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
      });
      let fileName =
        (FileName || "") +
        "_" +
        moment(new Date()).format("DDMMYYYYHHMM") +
        ".xlsx"; //res.headers["content-disposition"].split('filename=')[1].split(';')[0];     
      const link = document.createElement("a");
      link.target = "_blank";
      var objectUrl = URL.createObjectURL(blob);
      link.href = objectUrl;
      link.setAttribute("download", fileName);
      link.setAttribute("visibility", "hidden");
      link.click();
      link.remove();
      if (fnSuccess) fnSuccess(res);
    })
    .catch((errors) => {
      console.log("errors", errors);
      if (errors.response) {
        if (errors.response.status === 401) {
          SwAlert.Warning(undefined, i18n("common.SessionTimeOut"), lnkToHomePage);
        } else if (errors.response.status === 500) {
          SwAlert.Warning(undefined, i18n("common.ErrorSomething"));
        }
      } else if (fnError) {
        fnError();
      }
    })
    .then((res) => {
      if (UnBlockUI) UnBlockUI();
    });
};

export const AxiosGetMap = async (
  sWebMetodName,
  objJSON,
  fnSuccess?,
  fnError?,
  BlockUI?,
  UnBlockUI?,
  fnIsLogin?
) => {
  if (BlockUI) BlockUI();
  await axios({
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
    },
    method: "post",
    url: sWebMetodName,
    data: objJSON || {},
  })
    .then((res) => {
      if (fnSuccess) fnSuccess(res.data);
    })
    .catch(function (errors) {
      console.log(sWebMetodName);
      if (errors.response) {
        if (errors.response.status === 401) {
          if (fnIsLogin) {
            fnIsLogin();
          } else {
          }
        } else if (errors.response.status === 500) {
          SwAlert.Warning(undefined, i18n("common.ErrorSomething"));
        } else if (errors.response.status === 400) {
        }
      } else if (fnError) {
        fnError();
      }
    })
    .then((res) => {
      if (UnBlockUI) UnBlockUI();
    });
};

export const AxiosPost = async (
  sWebMetodName,
  objJSON,
  fnSuccess?,
  fnError?,
  BlockUI?,
  UnBlockUI?,
  fnIsLogin?
) => {

  let auth_token =
    localStorage.getItem(process.env.REACT_APP_JWT_KEY as string) != null
      ? localStorage.getItem(process.env.REACT_APP_JWT_KEY as string)
      : undefined;

  if (BlockUI) BlockUI();

  await axios({
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      "Company-Token": `${process.env.REACT_APP_JWT_KEY}`,
      Authorization: (auth_token && auth_token !== "") ? `Bearer ${auth_token}` : "",

    },

    method: 'post',
    url: process.env.REACT_APP_API + "api/" + sWebMetodName,
    data: objJSON || {}
  }).then((res) => {
    if (fnSuccess) fnSuccess(res.data);
  }).catch(function (errors) {
    console.log(process.env.REACT_APP_API + "api/" + sWebMetodName + " ERR Code ", errors);
    if (errors.response) {
      if (errors.response.status === 401) {

        if (fnIsLogin) {
          fnIsLogin();
        } else {
          SwAlert.Warning(undefined, i18n("common.SessionTimeOut"), lnkToHomePage);
        }
      } else if (errors.response.status === 500) {
        SwAlert.Warning(undefined, i18n("common.ErrorSomething"));

      } else if (errors.response.status === 400) {

      }
    } else if (fnError) {

      fnError();
    }
  }).then((res) => {
    if (UnBlockUI) UnBlockUI();
  });
};
export const AxiosGet = async (
  sWebMetodName,
  objJSON,
  fnSuccess?,
  fnError?,
  BlockUI?,
  UnBlockUI?,
  fnComplete?
) => {

  let auth_token =
    localStorage.getItem(process.env.REACT_APP_JWT_KEY as string) != null
      ? localStorage.getItem(process.env.REACT_APP_JWT_KEY as string)
      : undefined;

  if (BlockUI) BlockUI();

  let sPathApi = process.env.REACT_APP_API_URL + "api/" + sWebMetodName;
  if (CheckResourceInjection(sPathApi)) {
    await axios
      .get(sPathApi, {
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          "Company-Token": `${process.env.REACT_APP_JWT_KEY}`,
          Authorization: (auth_token && auth_token !== "") ? `Bearer ${auth_token}` : "",

        },
        params: objJSON,
        paramsSerializer: (params) => {
          return qs.stringify(params);
        },
      })
      .then((res) => {
        // if (fnSuccess) fnSuccess(res.data);
        switch (res.status) {
          case 200:
            if (fnSuccess) fnSuccess(res.data);
            break;
          case 401:
            Unauthorize();
            break;
          case 404:
          case 500:
            break;
          default:
            if (fnError) fnError(res.data);
            break;
        }
      })
      .catch((errors) => {
        console.log("errors", errors);

        if (errors && errors.response && errors.response.status === 401) {
          Unauthorize();
        }
      })
      .finally(() => {
        if (fnComplete) fnComplete();
      });
  }
};
export const CheckResourceInjection = (sUrl) => {
  let Result = true;
  var r = new RegExp(/^(ftp|http|https):\/\/[^ "]+$/);
  Result = r.test(sUrl);
  return Result;
}
export const Unauthorize = () => {
  SwAlert.Error(AlertTitle.Warning, "Unauthorize!", () => {

  });
}
export const AxiosPostFileVideo = async (
  sWebMetodName,
  objJSON,
  fnSuccess?,
  fnError?,
  BlockUI?,
  UnBlockUI?,
  FileName?
) => {
  let auth_token =
    localStorage.getItem(process.env.REACT_APP_JWT_KEY as string) != null
      ? localStorage.getItem(process.env.REACT_APP_JWT_KEY as string)
      : undefined;

  if (BlockUI) BlockUI();
  await axios({
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      "Company-Token": `${process.env.REACT_APP_JWT_KEY}`,
      Authorization: auth_token !== "" ? `Bearer ${auth_token}` : "",
    },
    method: "post",
    url: process.env.REACT_APP_API + "api/" + sWebMetodName,
    data: objJSON || {},
    responseType: "blob",
  })
    .then((res) => {
      let blob = new Blob([res.data], {
        type: "video/mp4",
      });
     
      let objectUrl = URL.createObjectURL(blob);
     
      if (fnSuccess) fnSuccess(objectUrl);
    })
    .catch((errors) => {
      console.log("errors", errors);
      if (errors.response) {
        if (errors.response.status === 401) {
          SwAlert.Warning(undefined, i18n("common.SessionTimeOut"), lnkToHomePage);
        } else if (errors.response.status === 500) {
          SwAlert.Warning(undefined, i18n("common.ErrorSomething"));
        }
      } else if (fnError) {
        fnError();
      }
    })
    .then((res) => {
      if (UnBlockUI) UnBlockUI();
    });
};