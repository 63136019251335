import { Grid, Typography } from '@mui/material';
import React from 'react';
import { useEffect, useState } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { Axios } from 'src/CommonFunction/TS_Axios';
import { FnBlock_UI } from 'src/Components/BlockUI';
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { InputForm } from 'src/Components/FormItem/Input';
import { SelectForm } from 'src/Components/FormItem/Select';
import { BtnCustomSearch, BtnCustomText, BtnSearch } from 'src/Components/Button';
import Pagination from "@mui/material/Pagination";
import { Margin } from '@mui/icons-material';
import { GridColumns } from "@mui/x-data-grid-pro";
import Box from "@mui/material/Box";
import { DataGridMui as Table, initRows, PaginationInterface } from "src/Components/Table/DataGridMui";
import DetailScore from './DetailScore';
import { any } from 'prop-types';
import AutoCompleteSelect from "src/Components/FormItem/AutoCompleteSelect";
import { array } from 'yup/lib/locale';

const HistoryListExamResult = () => {
  const [lstData, setData] = useState<any[]>([]);
  const { BlockUI, UnBlockUI } = FnBlock_UI();
  const [open, setOpen] = useState(false);
  const [lstDataScore, setlstDataScore] = useState([]);
  const [lstCategory, setlstCategory] = useState([]);
  const [switchOpen, setSwitchOpen] = useState(false);

  const objSchema = {};
  const schema = yup.object().shape(objSchema);
  const form = useForm({
    mode: "all",
    resolver: yupResolver(schema),
    shouldFocusError: true,
  });

  const OnSearch = async () => { }

  const [dataRow, setDataRow] = useState<PaginationInterface>({
    ...initRows,
  });

  const dataColumn: GridColumns = [

    {
      field: "nRow",
      headerName: "ที่",
      headerAlign: "center",
      align: "center",
      sortable: false,
      minWidth: 5,
    },
    {
      field: "sTopicName",
      headerName: "หลักสูตร",
      headerAlign: "center",
      align: "left",
      sortable: true,
      flex: 1,
      minWidth: 300,
    },
    {
      field: "firstScore",
      headerName: "คะแนนสอบก่อนเรียน",
      headerAlign: "center",
      align: "center",
      sortable: true,
      minWidth: 180,
      renderCell: (item) => {
        return (
          <>
            <Typography component={"label"} onClick={() => (GotoPopup(item.row), setSwitchOpen(false))} style={{ color: "#228dbd", textDecoration: "underline", cursor: "pointer" }}>
              {item.row.firstScore}
            </Typography>
          </>
        );
      },
    },
    {
      field: "afterScore",
      headerName: "คะแนนสอบหลังเรียน",
      headerAlign: "center",
      align: "center",
      sortable: true,
      minWidth: 180,
      renderCell: (item) => {
        return (
          <>
            <Typography component={"label"} onClick={() => (GotoPopup(item.row), setSwitchOpen(true))} style={{ color: "#228dbd", textDecoration: "underline", cursor: "pointer" }}>
              {item.row.afterScore}

            </Typography>
          </>
        );
      },
    },
  ];

  const GotoPopup = (item) => {
    console.log("item", item);
    setOpen(true)
    setlstDataScore(item.lstExamLog);
    // setsCourse(item.sCourse)
    // setsDate(item.sTraining)
    // setsTime(item.sTime)
    // setsCompany(item.sCompany)
    // setdataRowPopup(item.lstNoDocument)
  }

  const handleClose = () => {
    setOpen(false);
  };

  useEffect(() => {
    GetHistoryListExamResult(dataRow)
  }, []);

  const GetHistoryListExamResult = async (p: PaginationInterface) => {
    console.log('form.getValues("lstCategory")', form.getValues("lstCategory"))
    var param = {
      nPageSize: p.nPageSize || 10,
      nPageIndex: p.nPageIndex || 1,
      sSortExpression: p.sSortExpression || "",
      sSortDirection: p.sSortDirection.toUpperCase() || "",
      sCode: "",
      sCoursename: form.getValues("sSearch") || "",
      lstCategory: form.getValues("lstCategory") != undefined ? [form.getValues("lstCategory").value] : [],
    };
    console.log('param.....', param);
    let result = {} as any;
    await Axios(
      `HistoryPage/GetData_HistoryListExamResult`,
      param || {},
      "Post",

      async (res) => {
        console.log('res', res)
        res.lstData.forEach((e, index) => {
          e.nRow = index + 1;
          let nPreScoreGOT = e.nPreScore_GOT;
          let nPreScoreMAX = e.nPreScore_MAX;
          let nPostScoreGOT = e.nPostScore_GOT;
          let nPostScoreMAX = e.nPostScore_MAX;
          console.log("nPreScoreGOT", nPreScoreGOT)
          console.log("nPreScoreMAX", nPreScoreMAX)
          console.log("nPostScoreGOT", nPostScoreGOT)
          console.log("nPostScoreMAX", nPostScoreMAX)
          // e.firstScore = e.nPreScore_GOT == null ? "":e.nPreScore_GOT + '/' + e.nPreScore_MAX ;
          // e.afterScore = e.nPostScore_GOT == null ? "":e.nPostScore_GOT+"" + '/' + e.nPostScore_MAX;
          e.firstScore = nPreScoreGOT == null ? "" : nPreScoreGOT + "/" + nPreScoreMAX;
          e.afterScore = nPostScoreGOT == null ? "" : nPostScoreGOT + "/" + nPostScoreMAX;
        })

        result = res;
        setDataRow({
          ...p,
          arrRows: res.lstData,
          nDataLength: res.nDataLength === 0 ? res.nDataLength : (res.nDataLength > 10 ? res.nDataLength : 10),
          nPageIndex: res.nPageIndex,
        });
        setlstCategory(res.lstCategory);
      },
      null,
      BlockUI,
      UnBlockUI,
      null
    );
    return result;
  };



  return (
    <>
      <FormProvider {...form}>
        <form>
          <DetailScore
            open={open}
            handleClose={handleClose}
            lstDataScore={lstDataScore}
            Score={switchOpen ? "คะแนนสอบหลังเรียน" : "คะแนนสอบก่อนเรียน"}
          />
          <Grid container justifyContent={'flex-end'}>
            <Grid item xs={12} sx={{ mb: "1em", display: "flex", justifyContent: "flex-end", flexDirection: { xs: "column", lg: "row" } }} >
              <Grid item xs={12} lg={3} sx={{ px: { lg: "0.5em" } }}>
                <InputForm
                  name="sSearch"
                  placeholder="ค้นหาจากรหัส,ชื่อหลักสูตร"
                  showClear
                  fullWidth
                  mx={0}
                />
              </Grid>
              <Grid item xs={12} lg={3} sx={{ px: { lg: "0.5em" } }} >
                <AutoCompleteSelect
                  required
                  name="lstCategory"
                  label={" - All Category "}
                  width={{ width: "100%" }}
                  options={lstCategory}
                  IsShowIcon={true}
                  onChange={(v) => {
                    form.setValue('lstCategory', v ? v : null)
                  }}
                  mx={0}
                />
              </Grid>
              <Grid item xs={12} lg={1} sx={{ justifyContent: "center" }}>
                <BtnCustomText txt="ค้นหา/Search" onClick={() => { GetHistoryListExamResult(dataRow) }} />
              </Grid>
            </Grid>
            <Grid item xs={12}>
              <Table
                HiddenToolHead
                Rows={dataRow}
                Columns={dataColumn}
                OnLoadData={(p) => GetHistoryListExamResult(p)}
                backgroundHeaderCustom={"#0a286c"}
                sxCustomHeader={{ color: "#FFF" }}
                sxCustomTable={{
                  ".MuiDataGrid-cell": {
                    minHeight: "35px !important",
                    maxHeight: "35px !important"
                  },
                  ".MuiDataGrid-row": {
                    minHeight: "35px !important",
                    maxHeight: "35px !important"
                  },
                }}
              />
            </Grid>
          </Grid>
        </form>
      </FormProvider>

    </>
  )
}

export default HistoryListExamResult