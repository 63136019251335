import Box from "@mui/material/Box";
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import Paper from "@mui/material/Paper";
import footerCover from "./img/footer-cover.jpg";
import footerWaveBlue from "./img/wave-blue.png";
import footerWaveWhite from "./img/wave-white.png";
import footerWaveWhite2 from "./img/wave-blue-internal-footer.png";
import logo from "./img/oba-logo.png";
import logo2 from "./img/or-logo-sm.png";
import { Link, useNavigate } from "react-router-dom";
import { AccordionUI } from "src/Components/AccordionUI";
import { mkdirSync } from "fs";
import { FiMail } from "react-icons/fi";
import { BsFillTelephoneFill, BsFacebook } from "react-icons/bs";
import { ImFacebook2 } from "react-icons/im";
import "./Layout.css";

const LayoutFooter = (props: any) => {
  const navigate = useNavigate();

  const styles = {
    paperContainer1: {
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      height: "15em",
      color: "#ffffff",
      textShadow: "0px 0px 1em rgb(0 0 0 / 75%)",
      backgroundImage: `url(${footerCover})`,
      borderRadius: "0px !important",
    },
    paperContainer2: {
      height: "5em",
      backgroundImage: `url(${footerWaveBlue})`,
      backgroundColor: "rgba(255,255,255,1)",
      backgroundRepeat: "no-repeat",
      borderRadius: "0px !important",
    },
    paperContainer3: {
      height: "5em",
      width: "100%",
      backgroundImage: `url(${footerWaveWhite})`,
      backgroundColor: "#1b2648",
      backgroundRepeat: "no-repeat",
      backgroundPosition: "right",
      borderRadius: "0px !important",
    },
    paperContainer4: {
      height: "3em",
      backgroundImage: `url(${footerWaveWhite2})`,
      backgroundColor: "rgba(255,255,255,1)",
      backgroundRepeat: "no-repeat",
      boxShadow:
        "0 0px 8px 0 rgba(0, 0, 0, 0.2), 0 0px 20px 0 rgba(0, 0, 0, 0.19)",
    },
    typography1Style: {
      fontSize: 16,
      pt: 2,
    },
    typography2Style: {
      fontSize: 16,
    },
  };

  return (
    <Box
      sx={{
        backgroundColor: "rgba(255,255,255,1)",
        color: "rgba(0,0,0,1)",
        zIndex: 3,
        // position: "static",
        width: "100%",
        // paddingTop: "2rem",
      }}
    >
      {/* <img src="footer-cover.jpg"></img> */}
      {/* <Paper ></Paper> */}
      <Paper
        style={styles.paperContainer4}
        sx={{ borderRadius: "0px !important" }}
      />
      <Paper
        style={styles.paperContainer1}
        sx={{ flexDirection: "column", borderRadius: "0px !important" }}
      >
        {/* <Typography
            sx={{
              display: "flex",
              fontSize: "2.3em",
              justifyContent: "center",
            }}
          >
            แหล่งรวมความรู้สำหรับทุกคน
          </Typography> */}
        <Typography
          sx={{
            display: "flex",
            fontSize: {
              xs: "calc(1.5em - 10px)",
              sm: "calc(2em - 10px)",
              md: "calc(3em - 10px)",
            },
            justifyContent: "center",
          }}
        >
          {props.comName.sMotto}
        </Typography>
      </Paper>
      <Paper
        style={styles.paperContainer2}
        sx={{ borderRadius: "0px !important" }}
      />
      <Box p={{ xs: "2em", sm: "5em" }}>
        <Grid container columnSpacing={1} rowSpacing={2}>
          <Grid item xs={12} md={2.5} lg={4} sx={{ display: "flex", flexDirection: "column", ml: { xs: "20px", sm: "0px" } }}>
            <img className="logoFooter" src={logo} />
            <Typography sx={{ fontSize: "1.4em", maxWidth: "100%" }}>
              {props.comName.sNameTH}
            </Typography>
            <Typography
              sx={{ fontSize: "1.4em", maxWidth: "100%", color: "#3997df" }}
            >
              {props.comName.sNameEN}
            </Typography>
            {/* <Typography sx={{ fontSize: "1.4em" }}>สถาบันพัฒนา</Typography>
              <Typography sx={{ fontSize: "1.4em" }}>ศักยภาพธุรกิจ</Typography>
              <Typography sx={{ fontSize: "1.4em" }}>
                น้ำมันและการค้าปลีก
              </Typography>
              <Typography color="#3997df" sx={{ fontSize: "1.3em" }}>
                Oil & Retail
              </Typography>
              <Typography color="#3997df" sx={{ fontSize: "1.3em" }}>
                Business
              </Typography>
              <Typography color="#3997df" sx={{ fontSize: "1.3em" }}>
                Academy
              </Typography> */}
          </Grid>

          <Grid item xs={12} md={6} lg={5} sx={{ display: "flex", ml: { xs: "20px", sm: "0px" } }}>
            <Box>
              <Typography
                sx={{ fontSize: 16, paddingTop: 2, color: "#3997df" }}
              >
                เวลาทำการ จันทร์ - ศุกร์ เวลา 08:00 - 17:00 น.
              </Typography>
              <Typography sx={{ fontSize: 16, color: "#3997df", flexWrap: "wrap" }}>
                กรณีติดต่อนอกเวลาทำการ เจ้าหน้าที่จะติดต่อกลับในวันทำการถัดไป
              </Typography>

              <Typography sx={styles.typography1Style}>ติดต่อสอบถาม</Typography>
              <Typography sx={{ fontSize: 16, display: "flex", alignItems: "center", color: "#0b286c" }}><BsFillTelephoneFill className="IconFooter" />&nbsp;{props.comName.sTel}</Typography>
              <Typography sx={{ fontSize: 16, display: "flex", alignItems: "center", color: "#0b286c" }}><BsFillTelephoneFill className="IconFooter" />&nbsp;{props.comName.sTelExt}</Typography>
              <a
                href="mailto:oracademy@pttor.com"
                target="_top"
                style={{ textDecoration: "none" }}
              >
                <Typography sx={{ fontSize: 16, color: "#0b286c", display: "flex", alignItems: "center" }}><FiMail className="IconFooter" />&nbsp;{props.comName.sEmail} </Typography>
              </a>
              <Typography sx={{ fontSize: 16, pt: 2, display: "flex" }}>ติดตามเราได้ที่ </Typography>
              <Grid item xs={12} lg={12}>
                <a href={props.comName.sFacebook} style={{ textDecoration: "none", color: "#3997df", display: "flex", alignItems: "center" }} target="_blank">
                  &nbsp;<ImFacebook2 className="IconFooter" color="#0b286c" />&nbsp;{props.comName.sFacebookText}
                </a>
                <a href={props.comNameFire.sFacebook} style={{ textDecoration: "none", color: "#3997df", display: "flex", alignItems: "center" }} target="_blank">
                  &nbsp;<ImFacebook2 className="IconFooter" color="#0b286c" /> &nbsp;{props.comNameFire.sFacebookText}
                </a>
              </Grid>
            </Box>
          </Grid>

          <Grid item xs={12} md={3.5} lg={3} sx={{ display: "flex", flexDirection: "column", ml: { xs: "20px", sm: "0px" } }}>
            <a href={props.comName.sOR_Url} target="_blank">
              <img className="logoFooter2" src={logo2} />
            </a>
            <a
              href={props.comName.sPDPA_Url}
              target="_blank"
              style={{ color: "#3997df", textDecoration: "none" }}
            >
              นโยบายความเป็นส่วนตัว/Privacy Policy
            </a>
            <a
              href={props.comName.sCookieUrl}
              target="_blank"
              style={{ color: "#3997df", textDecoration: "none" }}
            >
              นโยบายการใช้คุกกี้/Cookie Policy
            </a>
          </Grid>

        </Grid>
      </Box>
      <AccordionUI
        isExpaned={false}
        hPosition
        marginLeft={"50px"}
        marginRight={"50px"}
        paddingLeft={"100px"}
        headerName="แผนผังเว็บไซต์">
        <Grid container>
          <Grid item xs={12} sx={{ display: "flex", flexWrap: { xs: "nowrap", md: "wrap" }, flexDirection: "column", maxHeight: { md: "550px" } }}>
            {(props.lstMenu || []).map((m, i) => {
              return (
                <>
                  {/* <Grid item xs={3}> */}
                  <Grid item xs={12} sx={{ maxHeight: { md: "550px" } }}>
                    {m.path !== "" ?
                      <Link
                        key={m.menuID}
                        to={process.env.REACT_APP_API_URL + m.path || ""}
                        style={{ textDecoration: "none" }}>
                        <Typography sx={{
                          fontSize: "1.2em",
                          color: "rgba(27,20,100,1)",
                          ":hover": { color: "rgb(76 185 255)" }
                        }}> {m.menuTH}</Typography>
                      </Link>
                      : <Typography sx={{ fontSize: "1.2em", cursor: "default" }}> {m.menuTH}</Typography>
                    }
                    {/* </Grid> */}
                    {m.items.length > 0 && m.items.map((ms) => (
                      <>
                        {/* <Grid item xs={12}> */}
                        {ms.path !== "" ?
                          <Link
                            key={ms.menuID}
                            to={process.env.REACT_APP_API_URL + ms.path || ""}
                            style={{ textDecoration: "none" }}>
                            <Typography sx={{
                              color: "rgba(0,0,0,0.5)",
                              ":hover": { color: "rgb(76 185 255)" },
                              ml: "10px"
                            }}> - {ms.menuTH}</Typography>
                          </Link>
                          : <Typography sx={{ ml: "10px", cursor: "default" }}> {ms.menuTH}</Typography>
                        }
                        {/* </Grid> */}
                        {ms.items.length > 0 && ms.items.map((mss) => (
                          <>
                            {/* <Grid item xs={12}> */}
                            {mss.path !== "" ?
                              <Link
                                key={mss.menuID}
                                to={process.env.REACT_APP_API_URL + mss.path || ""}
                                style={{ textDecoration: "none" }}>
                                <Typography sx={{
                                  color: "rgba(0,0,0,0.5)",
                                  ":hover": { color: "rgb(76 185 255)" },
                                  ml: "20px"
                                }}> - {mss.menuTH}</Typography>
                              </Link>
                              : <Typography sx={{ ml: "20px", cursor: "default" }}> {mss.menuTH}</Typography>
                            }
                            {/* </Grid> */}
                            {mss.items.length > 0 && mss.items.map((msss) => (
                              <>
                                {/* <Grid item xs={12}> */}
                                {msss.path !== "" ?
                                  <Link
                                    key={msss.menuID}
                                    to={process.env.REACT_APP_API_URL + msss.path || ""}
                                    style={{ textDecoration: "none" }}>
                                    <Typography sx={{
                                      color: "rgba(0,0,0,0.5)",
                                      ":hover": { color: "rgb(76 185 255)" },
                                      ml: "30px"
                                    }}> - {msss.menuTH}</Typography>
                                  </Link>
                                  : <Typography sx={{ ml: "30px", cursor: "default" }}> {msss.menuTH}</Typography>
                                }
                                {/* </Grid> */}
                                {msss.items.length > 0 && msss.items.map((mssss) => (
                                  // <Grid item xs={12}>
                                  <>
                                    {mssss.path !== "" ?
                                      <Link
                                        key={mssss.menuID}
                                        to={process.env.REACT_APP_API_URL + mssss.path || ""}
                                        style={{ textDecoration: "none" }}>
                                        <Typography sx={{
                                          color: "rgba(0,0,0,0.5)",
                                          ":hover": { color: "rgb(76 185 255)" },
                                          ml: "40px"
                                        }}> - {mssss.menuTH}</Typography>
                                      </Link>
                                      : <Typography sx={{ ml: "40px", cursor: "default" }}> {mssss.menuTH}</Typography>
                                    }
                                  </>
                                ))}
                              </>
                            ))}
                          </>
                        ))}
                      </>
                    ))}
                  </Grid>
                </>
              )
            })}
          </Grid>
        </Grid>
      </AccordionUI>
      <Paper style={styles.paperContainer3}></Paper>
    </Box>
  );
};

export default LayoutFooter;
