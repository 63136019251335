import React, { useCallback, useEffect, useState } from "react";
import ReactPlayer from "react-player";
import video from "./Video/SampleVideo.mp4";
import "./Banner.css";
import Box from "@mui/material/Box";
import img from "../../Layout/img/banner-1.jpg";
import { InputForm } from "../FormItem/Input";
import yupFormSchemas from "../FormItem/yup/yupFormSchemas";
import { FormProvider, useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import AutoCompleteForm from "../FormItem/AutoComplete";
import defaultImage from "../../Layout/img/default-image.png";
import InputNumber from "../FormItem/InputNumber";
import { Axios } from "src/CommonFunction/TS_Axios";
import { useNavigate } from "react-router-dom";
import { Encrypt } from "src/CommonFunction/TS_function";
import "swiper/css";
import "swiper/css/pagination";
import "swiper/css/navigation";
import { Swiper, SwiperSlide } from "swiper/react";
import { Autoplay, Pagination, Navigation } from "swiper";

export function Banner(props: Banner) {
  const { dataSource, height = 100, textTH, textENG, page } = props;
  const [wid, setWid] = useState(screen.availWidth);
  const [duration, setDuration] = useState(0);
  const objSchema = {};
  const schema = yup.object().shape(objSchema);
  const navigate = useNavigate();
  const form = useForm({
    mode: "all",
    resolver: yupResolver(schema),
    shouldFocusError: true,
  });

  useEffect(() => {
  }, [duration]);

  const onKeyPress = (e) => {
    if (e.key === "Enter") {
      let param = form.getValues("Search");
      navigate(
        process.env.REACT_APP_API_URL +
        "ResultSearch" +
        "?sSearch=" +
        Encrypt(param)
      );
    }
  };

  const onSearchClick = (e) => {
    let param = form.getValues("Search");
    navigate(
      process.env.REACT_APP_API_URL +
      "ResultSearch" +
      "?sSearch=" +
      Encrypt(param)
    );
  };

  return (
    <>
      <FormProvider {...form}>
        <form>
          <>
            <Box sx={{ minWidth: "100vw"}}>
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "flex-end",
                  position: "absolute",
                  width: "100vw",
                  my: "20px",
                  pr: "20px",
                  zIndex: 10,
                }}
              >
                <InputForm
                  name="Search"
                  label="Search"
                  placeholder="Search"
                  showClear
                  isCurve
                  isSearch
                  width={300}
                  onKeyPress={(e) => onKeyPress(e)}
                  onSearchClick={onSearchClick}
                />
              </Box>
              <Swiper
                // spaceBetween={30}
                centeredSlides={true}
                autoplay={{
                  delay: 5000,
                  disableOnInteraction: false,
                  pauseOnMouseEnter: true,
                }}
                pagination={{
                  clickable: true,
                }}
                modules={[Autoplay, Pagination]}
                // className="mySwiper"
                effect={"fade"}
                style={{
                  minWidth: "100%",
                }}
                className="SwiperSetDevice"
              >
                {page === "Home" ? (
                  <>
                    
                    {(dataSource.length > 0 ? dataSource : []).map((m) => {
                      return (
                        <>
                          {m.nType === 1 ? (
                            <SwiperSlide>
                              <img
                                src={m.sPath || defaultImage}
                                onError={({ currentTarget }) => {
                                  currentTarget.onerror = null;
                                  currentTarget.src = defaultImage;
                                }}
                              />
                              <div className="text1">{textTH}</div>
                              <div className="text2">{textENG}</div>
                            </SwiperSlide>
                          ) : (
                            <SwiperSlide>
                              <video
                                id="myVideo"
                                src={m.sPath}
                                controls
                                autoPlay
                                muted
                                loop
                                preload="none"
                                height="calc(100vw - 90px)"
                                width="100%"
                              />
                            </SwiperSlide>
                          )}
                        </>
                      );
                    })}
                  </>
                ) : (
                  <>
                    {(dataSource.length > 0 ? dataSource : []).map((m) => {
                      return (
                        <>
                          <SwiperSlide>
                            <img
                              src={m.sPath || defaultImage}
                              onError={({ currentTarget }) => {
                                currentTarget.onerror = null;
                                currentTarget.src = defaultImage;
                              }}
                            />
                            <div className="text1">{textTH}</div>
                            <div className="text2">{textENG}</div>
                          </SwiperSlide>
                        </>
                      );
                    })}
                  </>
                )}
              </Swiper>
            </Box>
          </>
        </form>
      </FormProvider>
    </>
  );
}

interface Banner {
  textTH?: string;
  textENG?: string;
  dataSource?: any;
  height?: any;
  page?: string;
  onClick?: (value: any) => void;
}
