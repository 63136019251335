import { Grid } from "@mui/material";
import { Axios } from "src/CommonFunction/TS_Axios";
import { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router";
import { FnBlock_UI, ParseHtml } from "src/CommonFunction/TS_function";
import YouTube, { YouTubeProps } from 'react-youtube';
import ReactPlayer from "react-player";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { FormProvider, useForm } from "react-hook-form";
import { BreadcrumbsUI } from "../BreadcrumbsUI";
var getYouTubeID = require('get-youtube-id');

const WhatsNewDetails = () => {
    const { BlockUI, UnBlockUI } = FnBlock_UI();
    const [WhatNewData, setWhatNewData] = useState({} as any);
    const [WhatNewDetails, setWhatNewDetails] = useState<any[]>([]);
    const history = useNavigate();
    const location = useLocation();
    const searchParams = location.search;
    const nID = new URLSearchParams(searchParams).get("nIDC");
    const [left, setLeft] = useState(0);
    const [banner, setBanner] = useState([] || undefined);

    const objSchema = {
        // sStart: yupFormSchemas.datetime("sStart", { required: false }),
        // sEnd: yupFormSchemas.datetime("sEnd", { required: false })

    }
    const schema = yup.object().shape(objSchema);
    const form = useForm({
        resolver: yupResolver(schema),
        shouldUnregister: false,
        shouldFocusError: true,
        mode: "all",
    });

    useEffect(() => {
        WhatsNewDetail();
    }, [left]);

    const WhatsNewDetail = async () => {
        await Axios("WhatsNew/GetData_WhatsNewDetail", { nIDC: nID }, "POST", async (result) => {
            let resBanner = await loadBanner();

            setWhatNewData(result.objDataDetail || {});
            setWhatNewDetails(result.objDataDetail.lstDetail);
            setBanner(resBanner)

            console.log("WhatNewData: ", WhatNewData.sUpdateDate)
        },
            null,
            BlockUI,
            UnBlockUI,
            () => { }
        );
    }

    const loadBanner = async () => {
        let result = {} as any;
        await Axios(
            `Master/GetBannerCMS`,
            "nContentID=5",
            "Get",
            async (res) => {
                result = res;
            },
            null,
            BlockUI,
            UnBlockUI,
            () => { }
        );
        return result;
    };

    const opts: YouTubeProps['opts'] = {
        height: '390',
        width: '640',
        playerVars: {
            autoplay: 0
        },
    };

    const onPlayerReady: YouTubeProps['onReady'] = (event) => {
        event.target.pauseVideo();
    }

    return (
        <FormProvider {...form}>
            <Grid container>
                <Grid item xs={12} sx={{ backgroundColor: "white" }}>
                    <BreadcrumbsUI
                        items={[
                            {
                                menuName: "มีอะไรใหม่/What's New",
                                IsOnClick: false
                            }
                        ]}
                    />
                </Grid>

                {/* What's new topic */}
                <Grid item xs={12} sx={{ mx: "3%", my: "3%", pl: 0, textAlign: "left" }} >
                    <h1
                        className="sNames"
                        style={{
                            whiteSpace: "normal",
                            textOverflow: "ellipsis",
                            marginLeft: "auto",
                            marginRight: "auto"
                        }}
                    >
                        {WhatNewData.sNames}
                    </h1>

                    <p
                        className="sUpdateDate"
                        style={{
                            marginBottom: "3%",
                            whiteSpace: "normal",
                            textOverflow: "ellipsis",
                            color: "#A0A0A0"
                        }}
                    >
                        {(WhatNewData.sUpdateDate)}
                    </p>
                </Grid>
                {/* End what's new topic */}

                {/* What's new detail */}
                <Grid container sx={{ mx: "4%", pl: 0 }} >

                    {(WhatNewDetails.length > 0 ? WhatNewDetails : []).map(
                        (m) => {
                            if (m.nContentTypeID == 1) {
                                return (
                                    <Grid item xs={12} justifyContent="center" sx={{ marginBottom: "3%" }}>
                                        <p
                                            className="sContentHTML"
                                            style={{
                                                whiteSpace: "normal",
                                                textOverflow: "ellipsis"
                                            }}
                                        >
                                            {ParseHtml(m.sContentHTML)}
                                        </p>
                                    </Grid>
                                )
                            } else if (m.nContentTypeID == 2) {
                                return (
                                    <Grid item xs={12} justifyContent="center" sx={{ marginBottom: "3%", textAlign: "center" }}>
                                        <img
                                            src={(m.sURLImagesub)}
                                            style={{
                                                justifyContent:"center" ,
                                                // height: "100%",
                                                // width: "100%"
                                                maxWidth:"80%",
                                                
                                            }}
                                        />
                                        <p
                                            className="sDescSub"
                                            style={{
                                                whiteSpace: "normal",
                                                textOverflow: "ellipsis",
                                                color: "#A0A0A0"
                                            }}
                                        >
                                            {(m.sDescSub)}
                                        </p>
                                    </Grid>
                                )
                            } else if (m.nContentTypeID == 3) {
                                return (
                                    <Grid item xs={12} sx={{ marginBottom: "3%", textAlign: "center" }}>
                                        <ReactPlayer url={(m.sURLImagesub)} width="40%" height="auto" controls={true}
                                            style={{
                                                marginLeft: "auto",
                                                marginRight: "auto"
                                            }} />

                                        <p
                                            className="sDescSub"
                                            style={{
                                                whiteSpace: "normal",
                                                textOverflow: "ellipsis",
                                                color: "#A0A0A0"
                                            }}
                                        >
                                            {(m.sDescSub)}
                                        </p>
                                    </Grid>
                                )
                            } else if (m.nContentTypeID == 4) {
                                var id = getYouTubeID(m.sURL);
                                return (
                                    <Grid item xs={12} sx={{ marginBottom: "3%", textAlign: "center" }}>
                                        <YouTube videoId={id} opts={opts} onReady={onPlayerReady} />

                                        <p
                                            className="sDescSub"
                                            style={{
                                                whiteSpace: "normal",
                                                textOverflow: "ellipsis",
                                                color: "#A0A0A0"
                                            }}
                                        >
                                            {(m.sDescSub)}
                                        </p>
                                    </Grid>
                                )
                            }
                        }
                    )}
                </Grid >
                {/* End what's new detail */}
            </Grid >
        </FormProvider>
    );
}
export default WhatsNewDetails