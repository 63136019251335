import React, { Component, useRef, useState } from "react";
import AppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import Box from "@mui/material/Box";
import IconButton from "@mui/material/IconButton";
import MenuIcon from "@mui/icons-material/Menu";
import Drawer from "@mui/material/Drawer";
import Typography from "@mui/material/Typography";
import Logo from "./img/oba-logo.png";
import Logo_L from "./img/oba-logo-l.png";
import { useNavigate } from "react-router-dom";
import Avatar from "@mui/material/Avatar";
import Divider from "@mui/material/Divider";
import Grid from "@mui/material/Grid";
import AppMenu from "./Layoutmenu";
import MenuDropDown from "./MenuDropDown";
import {
  BtnBack,
  BtnHome,
  BtnLoginWithText,
  BtnRegister,
} from "src/Components/Button";
import { AlertTitle, SwAlert } from "src/Components/Alert/Sweetalert";
import { Fade, Menu, MenuItem } from "@mui/material";
import Popover from "@mui/material/Popover";
import { borderBottom } from "@mui/system";
import { lnkToLogin, lnkToHomePage } from "src/CommonFunction/TS_function";
import { makeStyles } from "@mui/styles";
import { Helmet } from "react-helmet";
import secureLocalStorage from "react-secure-storage";


const useStyles = makeStyles({
  popover: {
    pointerEvents: "none",
    borderRadius: "unset !important",
    minWidth: '150px',
    right: 0,
  },
  popoverContent: {
    pointerEvents: "auto",
    borderRadius: "unset !important",
    minWidth: '150px',
    right: 0,
  },
});

export default function LayoutHead(props) {
  const [drawerstate, setDrawerstate] = useState(false);
  const [openedPopover, setOpenedPopover] = useState(false);
  const popoverAnchor = useRef(null);
  const navigate = useNavigate();
  const classes = useStyles();
  const isAuth = Boolean(secureLocalStorage.getItem("sName"));

  const toggleDrawer = (state, isopen) => (event) => {
    if (
      event.type === "keydown" &&
      (event.key === "Tab" || event.key === "Shift")
    ) {
      return;
    }
    setDrawerstate({ ...state, left: isopen });
  };

  const popoverEnter = ({ currentTarget }) => {
    setOpenedPopover(true);
  };

  const popoverLeave = ({ currentTarget }) => {
    setOpenedPopover(false);
  };

  return (
    <>
      <AppBar
        sx={{
          backgroundColor: "rgba(255,255,255,1)",
          position: "sticky",
          pr: "0px !important",
          zIndex: 900,
          "&.MuiAppBar-root:hover": {
            pr: "0px !important",
          },
        }}
      >
        <Helmet defaultTitle="PTT GBU">
          <meta charSet="utf-8" />
          <title>{"OR Academy "}</title>
          <meta
            name="DC.title"
            content={"OR Academy สถาบันพัฒนาศักยภาพผู้นำและธุรกิจ"}
          />
          <meta
            name="description"
            content={"OR Academy สถาบันพัฒนาศักยภาพผู้นำและธุรกิจ"}
          />
          <meta
            name="keywords"
            content={"or academy"}
          />
          <meta
            name="keywords"
            content={"PTT OBA"}
          />
          <meta
            name="keywords"
            content={"สถาบันพัฒนาศักยภาพผู้นำและธุรกิจ"}
          />
          <meta
            name="keywords"
            content={"oracademy"}
          />
          <meta name="robots" content="index,follow" />
        </Helmet>
        <Toolbar
          sx={{
            pr: "0px !important",
            // overflow: "hidden",
            "&.MuiToolbar-root:hover": { pr: "0px !important" },
          }}
        >
          {props.showMenu && (
            <IconButton
              aria-label="open drawer"
              edge={false}
              onClick={toggleDrawer("left", true)}
              sx={{ mr: 2, display: { xs: "block", lg: "none" } }}
            >
              <MenuIcon />
            </IconButton>
          )}
          <Typography
            sx={{ display: { xs: "block", lg: "block" }, height: "90px" }}
          >
            <img
              onClick={() => {
                props.onDisClickLogo
                  ? navigate("")
                  : navigate(process.env.REACT_APP_API_URL + "home"),
                  document.documentElement.scrollTo(0, 0);
                window.location.reload();
              }}
              src={props.onDisClickLogo ? Logo_L : Logo}
              style={{
                // width: props.IsExpired ? "80px" : "70px",
                // width: "100%",
                height: "85%",
                cursor: props.onDisClickLogo ? "" : "pointer",
                marginTop: "0.5vw",
                marginRight: "20px",
              }}
            />
          </Typography>
          {props.showComName && (
            <Box
              sx={{
                flexGrow: 1,
                display: { xs: "none", lg: "flex" },
                flexDirection: "column",
              }}
            >
              <Typography
                sx={{
                  color: "rgb(8, 40, 107) !important",
                  fontSize: "2em",
                  // fontWeight: "bold",
                }}
              >
                {props.comName.sNameTH}
              </Typography>
              {/* <Typography
                sx={{
                  color: "rgb(8, 40, 107) !important",
                  fontSize: "1.2em",
                  // fontWeight: "bold",
                }}
              >
                {props.comName.sNameEN}
              </Typography> */}
            </Box>
          )}
          {props.showMenu && (
            <>
              <Box sx={{ flexGrow: 1, display: { xs: "none", lg: "flex" } }}>
                <Grid
                  container
                  direction="row"
                  justifyContent="flex-start"
                  alignItems="flex-start"
                  height="auto"
                  width="auto"
                >
                  {/* <BtnHome
                  menuID={props.menuID}
                    onClick={() => navigate(process.env.REACT_APP_API_URL + "home")}
                  /> */}
                  <MenuDropDown
                    lstMenu={props.lstMenu}
                    menuID={props.menuID}
                    hMenuID={props.hMenuID}
                  />
                </Grid>
              </Box>
              <Box
                sx={{ flexGrow: 1, display: { xs: "block", lg: "none" } }}
              ></Box>
              {props.IsExpired || props.IsExpired === undefined ? (
                <>
                  <Box
                    sx={{
                      display: {
                        xs: "block",
                      },
                    }}
                  >
                    <BtnRegister
                      menuID={props.menuID}
                      onClick={() =>
                        navigate(process.env.REACT_APP_API_URL + "Register")
                      }
                    />
                  </Box>
                  <Box
                    sx={{
                      display: {
                        xs: "block",
                      },
                    }}
                  >
                    <BtnLoginWithText
                      menuID={props.menuID}
                      onClick={() =>
                        // navigate(process.env.REACT_APP_API_URL + "login")
                        SwAlert.Login("เข้าสู่ระบบ/Login")
                      }
                    />
                  </Box>
                </>
              ) : (
                <>
                  <Box sx={{ display: "flex", minWidth: "80px", }}
                    ref={popoverAnchor}
                    onMouseEnter={popoverEnter}
                    onMouseLeave={popoverLeave}
                  >
                    <Box
                      sx={{
                        display: {
                          xs: "flex",
                          alignItems: "center",
                          marginRight: "5px",
                        },
                        height: "90px",
                        borderLeft: { xs: "0", sm: "1px solid rgba(0,0,0,0.3)" },
                      }}
                    >
                      <Avatar
                        alt={props.profile.sFullName}
                        src="/static/images/avatar/2.jpg"
                        sx={{ ml: "5px" }}
                      />
                    </Box>

                    {props.profile.IsOR ? (
                      <Box
                        sx={{
                          display: {
                            xs: "none",
                            sm: "flex",
                            flexDirection: "column",
                            alignItems: "flex-start",
                            justifyContent: "center",
                          },
                          height: "90px",
                          mx: "10px",
                        }}
                      >
                        <Typography
                          sx={{
                            color: "rgb(5,89,137)",
                            fontSize: "1em",
                            maxWidth: "300px",
                          }}
                          noWrap
                        >
                          {props.profile.sFullName}
                        </Typography>
                        <Typography sx={{ color: "#909090", fontSize: "1em", maxWidth: "300px", }} noWrap>
                          {props.profile.sUserCode}
                        </Typography>
                      </Box>
                    ) : (
                      <Box
                        sx={{
                          display: {
                            xs: "none",
                            sm: "flex",
                            flexDirection: "column",
                            alignItems: "flex-start",
                            justifyContent: "center",
                          },
                          height: "90px",
                          mx: "10px",
                        }}

                      >
                        <Typography sx={{ color: "#3997df", fontSize: "1em", maxWidth: "300px" }} noWrap>
                          {props.profile.sCompany}
                        </Typography>
                        <Typography
                          sx={{
                            color: "rgb(5,89,137)",
                            fontSize: "1em",
                            maxWidth: "300px",
                          }}
                          noWrap
                        >
                          {props.profile.sFullName}
                        </Typography>
                        <Typography sx={{ color: "#909090", fontSize: "1em", maxWidth: "300px" }} noWrap>
                          {props.profile.sPosition}
                        </Typography>
                      </Box>
                    )}
                    <Popover
                      id="mouse-over-popover"
                      className={classes.popover}
                      classes={{
                        paper: classes.popoverContent,
                      }}
                      open={openedPopover}
                      anchorEl={popoverAnchor.current}
                      PaperProps={{
                        onMouseEnter: popoverEnter,
                        onMouseLeave: popoverLeave,
                      }}
                      anchorOrigin={{
                        vertical: "bottom",
                        horizontal: "left",
                      }}
                      transformOrigin={{
                        vertical: "top",
                        horizontal: "left",
                      }}
                      anchorPosition={{ top: 250, left: 1400 }}
                    >
                      {(props.profile.lstMenuLogin || []).map((m, i) =>
                        <>
                          <MenuItem key={m.nKey}
                            sx={{
                              minHeight: "70px !important",
                              borderBottom: "1px solid rgba(0,0,0,0.3)",
                              ":hover": {
                                bgcolor: "#3997df",
                                color: "rgb(255,255,255)",
                              },
                              backgroundColor: (i + 100) === props.menuID ? "#3997df" : "white",
                              color: (i + 100) === props.menuID ? "white" : "black",
                            }}
                            onClick={() => {
                              m.IsOnClick && navigate(process.env.REACT_APP_API_URL + m.path)
                            }}>
                            <p style={{ margin: "0px" }}>
                              <Typography sx={{ fontSize: { xs: "0.8em", lg: "1em" } }}>{m.menuName}</Typography>
                              <Typography sx={{ fontSize: { xs: "0.8em", lg: "1em" } }}>{m.menuNameENG}</Typography>
                            </p>
                          </MenuItem>
                        </>
                      )}
                      <MenuItem
                        sx={{
                          minHeight: "70px !important",
                          bgcolor: "rgba(255,0,0,0.95)",
                          color: "rgb(255,255,255)",
                          ":hover": {
                            bgcolor: "rgba(255,0,0,0.8)",
                            color: "rgb(255,255,255)"
                          }
                        }}
                        onClick={() => {
                          SwAlert.Confirm(
                            AlertTitle.Confirm,
                            "คุณต้องการออกจากระบบใช่หรือไม่",
                            () => {
                              window.localStorage.removeItem(
                                process.env.REACT_APP_JWT_KEY as string
                              ),
                                lnkToHomePage();
                            },
                            () => { }
                          );
                        }}>
                        <p style={{ margin: "0px" }}>
                          <Typography sx={{ fontSize: { xs: "0.8em", lg: "1em" } }}>ออกจากระบบ</Typography>
                          <Typography sx={{ fontSize: { xs: "0.8em", lg: "1em" } }}>Log Out</Typography>
                        </p>


                      </MenuItem>
                    </Popover>
                  </Box>
                </>
              )}
            </>
          )}
        </Toolbar>
        <Drawer
          open={drawerstate["left"]}
          onClose={toggleDrawer("left", false)}
          ModalProps={{
            keepMounted: true, // Better open performance on mobile.
          }}
          sx={{
            display: { xs: "block", lg: "none" },
            "& .MuiDrawer-paper": {
              boxSizing: "border-box",
              width: { xs: 240, sm: 350 },
            },
          }}
        >
          <Box>
            <Typography variant="h6" sx={{ my: 1, textAlign: "center" }}>
              <img
                onClick={() => navigate(process.env.REACT_APP_API_URL + "")}
                src={Logo}
                style={{ width: "60%", height: "auto" }}
              />
            </Typography>
            <Divider />
            <AppMenu
              toggleDrawer={toggleDrawer}
              lstMenu={props.lstMenu}
              menuID={props.menuID}
            />
          </Box>
        </Drawer>
      </AppBar>
    </>
  );
}

