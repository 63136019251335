//React
import React, { useEffect, useRef, useState } from "react";
import logo from "src/Layout/img/oba-logo.png";
//other - Mui
import { useNavigate } from "react-router";
import { Axios } from "src/CommonFunction/TS_Axios";
import { AlertMsg, AlertTitle, DialogConfirm, DialogConfirmDelete, SwAlert } from "src/Components/Alert/Sweetalert";
import { lnkToHomePage, Process_System, ResultAPI } from "src/CommonFunction/TS_function";
import { FnBlock_UI } from 'src/Components/BlockUI';
import { Box, Button, Grid, Tooltip, Typography } from "@mui/material";
import { GridColumns } from '@mui/x-data-grid';
import { DataGridMui, initRows, PaginationInterface } from "src/Components/Table/DataGridMui";
import AddIcon from '@mui/icons-material/Add';
import { BreadcrumbsUI } from "src/Components/BreadcrumbsUI";
//component Input
import AutoCompleteSelect from "src/Components/FormItem/AutoCompleteSelect";
import { InputForm } from "src/Components/FormItem/Input";
import UploadFile from "src/Components/ElementInForm/UploadFile/UploadFile";
import { Extension } from "src/CommonFunction/TS_function";
//Button
import { BtnCustomSearch, BtnRemoveOnTable, BtnSendCancel, BtnSendCutom,BtnCustomText } from "src/Components/Button";

//Yup
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { FormProvider, useForm } from "react-hook-form";
import yupFormSchemas from "src/Components/FormItem/yup/yupFormSchemas";
import { DatePickerUI } from "src/Components/FormItem/DatePicker";
import moment from "moment";
import AutoCompleteForm from "src/Components/FormItem/AutoComplete";

import DetailModal from "./DetailModal";
import { i18n } from "src/i18n";
import { fromUnixTime } from "date-fns";


const historyFAQ_list = () => {

    const { BlockUI, UnBlockUI } = FnBlock_UI();
    const oFormRef = useRef() as any;
    const oTableRef = useRef() as any;
    const navigate = useNavigate();
    const [arrCourse, setCourse] = useState([] as any)
    const [nFAQID, setnFAQID] = useState(0)
    const [arrFile, setArrFile] = useState<any[]>([]);
    const [IsOpenDetail, setIsOpenDetail] = useState(false)
    const [DataDetail, setDataDetail] = useState(null)

    const [IsOwner, setOwner] = useState(false)

    const [arrOptionCourse, setarrOptionCourse] = useState([] as any)
    const [arrOptionStatus, setarrOptionStatus] = useState([] as any)

    const [StartDate, setStartDate] = useState(undefined);
    const [EndDate, setEndDate] = useState(undefined);

    const [IsShowForm, setIsShowForm] = useState(false)
    const [loadingTable, setLoadingTable] = useState(false);
    const [dataRow, setDataRow] = useState<PaginationInterface>({
        ...initRows,
        sSortExpression: "sID",
        sSortDirection: "asc"
    });

    const schemaSearch = yup.object().shape({
        sCourseSearch: yupFormSchemas.string("", { required: false }),
        sQuestionBy: yupFormSchemas.string("", { required: false }),
        sStart: yupFormSchemas.string("", { required: false }),
        sEnd: yupFormSchemas.string("", { required: false }),
        oStatus: yupFormSchemas.object("", { required: false }),
    });

    const formSearch = useForm({
        resolver: yupResolver(schemaSearch),
        mode: "all",
    });

    const schemaForm = yup.object().shape({
        oCourse: yupFormSchemas.object("หลักสูตร/Course", { required: true }),
        sQuestion: yupFormSchemas.string("คำถาม/Question", { required: true }),
    });

    const form = useForm({
        resolver: yupResolver(schemaForm),
        mode: "all",
    });

    useEffect(() => {
        //  window.localStorage.setItem("sSubRouteName", item.sSection);
        getUserData()
    }, [])

    useEffect(() => {
        oSearchHistoryFAQ(dataRow);
    }, [])

    const getUserData = async () => {

        await Axios("Master/onPageLoad", {}, "GET", async (result) => {
            setOwner(result.IsOwner);
            if (!result.nUserTypeID) {
                SwAlert.Warning(undefined, i18n("common.NoPermission"), lnkToHomePage);

            }
            GetCourse();
        });

    }
    const GetCourse = async () => {

        await Axios(`FAQ/GetData_Course`, {}, "POST", async (res) => {
            ResultAPI(true, res, null, () => {

                setCourse(res);

            })
        },
            null,
            BlockUI,
            UnBlockUI,
            null
        );

    }

    const oSearchHistoryFAQ = async (p: PaginationInterface) => {
        setLoadingTable(true)
        //BlockUI();
        let cResult = formSearch.getValues("oStatus") ? formSearch.getValues("oStatus").value : "";
        let param = {
            ...p,
            sSearchCourse: formSearch.getValues("sCourseSearch") || "",
            sSearch: formSearch.getValues("sQuestionBy") || "",
            sStatus: cResult,
            sStart: formSearch.getValues("sStart") ? moment(formSearch.getValues("sStart")).format('DD/MM/YYYY') : "",
            sEnd: formSearch.getValues("sEnd") ? moment(formSearch.getValues("sEnd")).format('DD/MM/YYYY') : "",
            nSort: 0
        };
        await Axios(`FAQ/GetData_FAQ`, param || {}, "Post", async (res) => {
            setLoadingTable(false)
            // UnBlockUI();
            ResultAPI(true, res, null, () => {

                setarrOptionStatus(res.lstStatus || [])
                setarrOptionCourse(res.lstCourse || [])
                setDataRow({
                    ...p,
                    arrRows: res.lstFAQ.lstFAQ || [],
                    nDataLength: res.lstFAQ.nDataLength === 0 ? res.lstFAQ.nDataLength : (res.lstFAQ.nDataLength > 10 ? res.lstFAQ.nDataLength : 10),
                });
            });
        }, null, BlockUI, UnBlockUI, null);
    }

    const onAddData = () => {
        setIsShowForm(true)
        if (oFormRef.current != null) {
            window.scrollTo({
                top: oFormRef.current.offsetTop - 190,
                left: 0,
                behavior: "smooth",
            });
        }
    }

    const onSendFAQ = () => {
        let oForm = form.getValues();
        let xxx = oForm.oCourse;
        let lstFile = [] as any;
        let n = 0;
        // console.log("xxx", xxx)
        (arrFile || []).map((f) => {
            n++;
            lstFile.push({
                nRow: n,
                nID: n,
                sID: n.toString(),
                nFile_ID: f.nFile_ID.toString(),
                sFileName: f.sFileName || "",
                sSysFileName: f.sSysFileName || "",
                sFilePath: f.sPath || "",
                sFileLink: f.sFileLink || "",
                sFileType: f.sFileType || "",
                IsComplete: f.IsComplete || "",
                IsNew: f.IsNew || false,
                IsChanged: false,


            });
        });
        let Param = {
            nFAQID: nFAQID,
            nsaveCourseID: oForm.oCourse.nCourseID,
            nSaveScheduleID: oForm.oCourse.nScheduleID,
            sSaveQuestion: oForm.sQuestion,
            //lstSaveFile: arrFile,
            lstSaveFile: lstFile,
        }
        //console.log("Param", Param)
        DialogConfirm(() => {
            Axios(`FAQ/Create_FAQ`, Param, "POST", async (res) => {
                ResultAPI(true, res, res.Message, () => {
                    if (res.Status == Process_System.process_Success) {
                        SwAlert.Success(AlertTitle.Success, AlertMsg.SaveComplete, () => {
                            setIsShowForm(false)
                            oSearchHistoryFAQ(dataRow);
                            if (oTableRef.current != null) {
                                setTimeout(() => {
                                    window.scrollTo({
                                        top: 0,
                                        left: 0,
                                        behavior: "smooth",
                                    });
                                }, 500);
                                form.setValue('oCourse', null)// ยังไม่เครีย
                                form.setValue("sQuestion", "");
                                setArrFile([]);
                            }
                        });
                    }
                    else { SwAlert.Warning(AlertTitle.Warning, res.Message, () => { }); }
                })
            }, null, BlockUI, UnBlockUI, null
            );
        })
    }

    const onCancelFAQ = () => {
        setIsShowForm(false)
        form.clearErrors();
        form.setValue('oCourse', null) // ยังไม่เครีย
        form.setValue("sQuestion", "");
        setArrFile([])
        if (oTableRef.current != null) {
            window.scrollTo({
                top: 0,
                left: 0,
                behavior: "smooth",
            });
        }
    }

    const onDeleteInRowTable = (item) => {
        var oData = { nFAQID: parseInt(item.value), }
        DialogConfirmDelete(() => {
            Axios("FAQ/Delete_FAQ", oData, "POST", async (res) => {
                ResultAPI(true, res, res.Message, () => {
                    if (res.Status === Process_System.process_Success) {
                        SwAlert.Success(AlertTitle.Success, AlertMsg.DeleteComplete, () => { });
                        oSearchHistoryFAQ(dataRow);
                    }
                    else { SwAlert.Warning(AlertTitle.Warning, res.Message, () => { }); }
                })
            }, null, BlockUI, UnBlockUI,null)
        })
    }

    const onOpenDetailQuestion = (Row) => {
        setIsOpenDetail(true)
        setDataDetail(Row)
    }

    const dataColumn: GridColumns = [
        {
            field: "sID",
            headerName: "ที่",
            headerAlign: "center",
            editable: false,
            width: 50,
            resizable: true,
            filterable: false,
            disableReorder: true,
            align: "center",
            sortable: false,
            
        },
        {
            field: "sCourseNameFull",
            headerName: "หลักสูตร",
            headerAlign: "center",
            editable: false,
            width: 300,
            minWidth: 300,
            resizable: true,
            filterable: false,
            disableReorder: true,
            align: "left",
            sortable: false,
            flex:1,
            
        },
        {
            field: "sQuestionMore",
            headerName: "คำถาม",
            headerAlign: "center",
            editable: false,
            width: 160,
            resizable: true,
            filterable: false,
            disableReorder: true,
            align: "left",
            sortable: false,
            renderCell: (item) => {
                
                return (
                    <div onClick={() => { onOpenDetailQuestion(item.row) }}>
                        <Typography style={{ color: '#0099cc', cursor: 'pointer' }}>
                            {item.value ?? ""}
                        </Typography>
                    </div>

                )
            },
        },
        {
            field: "sAnswerMore",
            headerName: "คำตอบ",
            headerAlign: "center",
            editable: false,
            width: 160,
            resizable: true,
            filterable: false,
            disableReorder: true,
            align: "left",
            sortable: false,
        },
        {
            field: "sQuestionByName",
            headerName: "ผู้ถาม",
            headerAlign: "center",
            editable: false,
            width: 210,
            resizable: true,
            filterable: false,
            disableReorder: true,
            align: "left",
            sortable: false,
        },
        {
            field: "sQuestionDate",
            headerName: "วันที่ส่งคำถาม",
            headerAlign: "center",
            editable: false,
            width: 120,
            resizable: true,
            filterable: false,
            disableReorder: true,
            align: "center",
            sortable: false,
        },
        {
            field: "sAnswerByName",
            headerName: "ผู้ตอบคำถาม",
            headerAlign: "center",
            editable: false,
            width: 150,
            resizable: true,
            filterable: false,
            disableReorder: true,
            align: "left",
            sortable: false,
        },
        {
            field: "sAnswerDate",
            headerName: "วันที่ตอบคำถาม",
            headerAlign: "center",
            editable: false,
            width: 120,
            resizable: true,
            filterable: false,
            disableReorder: true,
            align: "center",
            sortable: false,
        },
        {
            field: "sStatusName",
            headerName: "สถานะ",
            headerAlign: "center",
            editable: false,
            width: 130,
            resizable: true,
            filterable: false,
            disableReorder: true,
            align: "center",
            sortable: false,
        },
        {
            field: "nFAQID",
            headerName: "",
            headerAlign: "center",
            editable: false,
            width: 3,
            resizable: true,
            filterable: false,
            disableReorder: true,
            align: "left",
            sortable: false,
            renderCell: (item) => {
                return (
                    <>
                        {(item.row.sStatusID != 3) &&
                            <BtnRemoveOnTable txt="ลบ" onClick={() => { onDeleteInRowTable(item) }}
                            />
                        }
                    </>
                );
            },
        },
    ];

    return (
        <>
            <Grid container direction={'row'} justifyContent={'flex-start'}>
                <Grid item xs={12}>
                    <BreadcrumbsUI
                        items={[
                            {
                                Key: "1",
                                menuName: "Frequently Asked Questions (FAQ)",
                                IsOnClick: false,
                            },
                        ]}
                        isShowHome={false}
                    />
                </Grid>

                <Grid item xs={12} sx={{ marginTop: "2%", padding: '0px 32px', display: !IsShowForm ? 'block' : 'none' }}>
                    <FormProvider {...formSearch}>
                        <Grid container spacing={2} justifyContent={'flex-end'} alignItems={'center'}>
                            <Grid item xs={12} md={2} style={{ marginLeft: '-11px' }}>
                                <InputForm
                                    fullWidth
                                    required={false}
                                    name={"sCourseSearch"}
                                    label="หลักสูตร/Course"
                                    placeholder="ค้นหาจากชื่อหลักสูตร"
                                    showClear={false}
                                />
                            </Grid>
                            {IsOwner &&
                                <Grid item xs={12} md={2} style={{ marginLeft: '-11px' }}>
                                    <InputForm
                                        fullWidth
                                        required={false}
                                        name={"sQuestionBy"}
                                        label="ชื่อผู้ถาม"
                                        placeholder="ค้นหาจากชื่อผู้ถาม"
                                        showClear={false}
                                    />
                                </Grid>
                            }

                            <Grid item xs={12} md={1} >
                                <Typography align="center" variant="subtitle1">
                                    วันที่ถาม
                                </Typography>
                            </Grid>
                            <Grid item xs={6} md={1.5} >
                                <DatePickerUI
                                    name="sStart"
                                    small={true}
                                    label={'เริ่มต้น'}
                                    onChange={(e) => setStartDate(e)}
                                    maxDate={EndDate ? EndDate : undefined}
                                    onClearFields={(e) => setStartDate(undefined)}
                                />
                            </Grid>
                            <Grid item xs={6} md={1.5} style={{ marginLeft: '-11px' }}>
                                <DatePickerUI
                                    name="sEnd"
                                    small={true}
                                    label={'สิ้นสุด'}
                                    onChange={(e) => setEndDate(e)}
                                    minDate={StartDate ? StartDate : undefined}
                                    onClearFields={(e) => setEndDate(undefined)}
                                />
                            </Grid>
                            <Grid item xs={12} md={2} style={{ marginLeft: '-20px' }}>
                                <AutoCompleteSelect
                                    name="oStatus"
                                    label="สถานะ/Status"
                                    required={false}
                                    fullWidth={true}
                                    width={{ width: "100%" }}
                                    options={arrOptionStatus}
                                />
                            </Grid>
                            <Grid item xs={12} md={1.5} sx={{ display: "flex", justifyContent: "center",paddingLeft:"8px"}}>
                                {/* <BtnCustomSearch txt='ค้นหา/Search' onClick={() => { oSearchHistoryFAQ(dataRow) }} /> */}
                                <BtnCustomText txt="ค้นหา/Search" onClick={() => { oSearchHistoryFAQ(dataRow) }} />
                            </Grid>
                        </Grid>
                    </FormProvider >
                </Grid>
                <Grid item xs={12} sx={{ marginTop: "10px", padding: '0px 32px 32px' }}>
                    <Grid container spacing={2} justifyContent={'flex-start'}>
                        <Grid item xs={12}>
                            <Tooltip title={"เพิ่ม/Add"}>
                                <Button
                                    variant="contained"
                                    size="medium"
                                    startIcon={<AddIcon />}
                                    sx={{
                                        // borderRadius: "16px",
                                        textTransform: "none",
                                        backgroundColor: "#169bd5",
                                        color: "#ffffff",
                                        ":hover": { bgcolor: "#2fb8f4" },
                                    }}
                                    onClick={() => { onAddData() }}
                                >เพิ่ม/Add
                                </Button>
                            </Tooltip>
                        </Grid>
                        <Grid item xs={12}>
                            <div ref={oTableRef} >
                                <Box sx={{ width: "100%" }}>
                                    <DataGridMui
                                        HiddenToolHead={true}
                                        isShowCheckBox={false}
                                        Rows={dataRow}
                                        //isLoading={loadingTable}
                                        isLoading={false}
                                        Columns={dataColumn}
                                        OnLoadData={(p) => { oSearchHistoryFAQ(p) }}
                                        sxCustomHeader={{
                                            color: "#fff !important"

                                        }}
                                        backgroundHeaderCustom={"#122768"}
                                    />
                                </Box>
                            </div>
                        </Grid>
                    </Grid>
                </Grid>
                <Grid item xs={12} sx={{ marginTop: "10px", padding: '0px 32px 32px' }}>
                    <div ref={oFormRef} style={{ border: IsShowForm ? '1px solid #bdbdbd' : '0px', borderRadius: '16px', padding: '16px' }}>
                        <FormProvider {...form}>
                            <Grid container spacing={2} justifyContent={'flex-start'} style={{ display: IsShowForm ? 'block' : 'none' }}>
                                <Grid item xs={12} md={12} style={{ marginRight: '11px' }}>         {/*marginRight Because component form have margin */}
                                    {/* <AutoCompleteForm
                                        label="หลักสูตร/Course"
                                        name="oCourse"
                                        required={true}
                                        sMethodAxios={"POST"}
                                        urlAPI={"FAQ/GetData_Course"}
                                        textFilterCont={1}
                                    /> */}
                                    <AutoCompleteSelect
                                        label="หลักสูตร/Course"
                                        name="oCourse"
                                        required={true}
                                        fullWidth={false}
                                        width={{ width: "100%" }}
                                        options={arrCourse}
                                        disableClearable={false}
                                        IsShowIcon={true}
                                    />
                                </Grid>
                                <Grid item xs={12} md={12} style={{ marginRight: '11px' }}>         {/*marginRight Because component form have margin */}
                                    <InputForm
                                        fullWidth
                                        required={true}
                                        name={"sQuestion"}
                                        label="คำถาม/Question"
                                        showClear={false}
                                        multiline={true}
                                        rows={6}
                                    />
                                </Grid>
                                <Grid item xs={12} md={12} style={{ margin: '0px 10px' }}>
                                    <UploadFile
                                        keyID={"sDocFile"}
                                        sTitle={"เอกสารเพิ่มเติม/Document Files"}
                                        sSubTitle={""}
                                        nLimitFile={50}
                                        IsRequired={false}
                                        arrFile={arrFile || []}
                                        setarrFile={setArrFile}
                                        IsFolder={false}
                                        IsFile={true}
                                        IsMultiple={true}
                                        isFileChange={true}
                                        Extension={Extension.OBAFile}
                                        IsDrag={false}
                                    />
                                </Grid>
                                <Grid item xs={12} md={12}>
                                    <Grid container spacing={2} direction="row" justifyContent="center" alignItems="center">
                                        <Grid item><BtnSendCutom onClick={form.handleSubmit(() => { onSendFAQ() })} /></Grid>
                                        <Grid item><BtnSendCancel onClick={() => { onCancelFAQ() }} /></Grid>
                                    </Grid>
                                </Grid>
                            </Grid>
                        </FormProvider >
                    </div>
                </Grid>
                <DetailModal
                    IsOpenModal={IsOpenDetail}
                    setIsOpenModal={setIsOpenDetail}
                    DataDetail={DataDetail}
                />
            </Grid>

        </>


    )
}

export default historyFAQ_list