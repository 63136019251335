import * as React from "react";
import IconButton from "@mui/material/IconButton";
import InputAdornment from "@mui/material/InputAdornment";
import TextField from "@mui/material/TextField";
import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";
import { useState, useEffect } from "react";
import { useFormContext, Controller, useWatch } from "react-hook-form";
import FormErrors from "src/Components/FormItem/formErrors";
import { styled, alpha } from "@mui/material/styles";
import { SxProps } from "@mui/material";
import ClearIcon from "@mui/icons-material/Clear";
import { FiSearch } from "react-icons/fi";

/***
 * Input From ต้องมี่ Form คลุมมาเสมอ
 * props ตามที่มีให้
 */
export const InputForm = (props: InputForm) => {
  const {
    label,
    name,
    placeholder,
    prefix,
    suffix,
    disabled,
    fullWidth,
    helperText,
    multiline,
    required,
    size,
    variant,
    type,
    isPassword,
    styles,
    maxLength,
    defaultValues,
    shrink = undefined,
    externalErrorMessage,
    showCoutTest = true,
    showClear = true,
    isSearch = false,
    isCurve = false,
    rows,
    width,
    mx = 1,
    my = 1,
  } = props;
  // const [value, SetValue] = useState(props.defaultValues);
  const [showPassword, setshowPassword] = useState(false);

  //#region form
  const {
    control,
    register,
    getValues,
    setValue,
    watch,
    formState: { touchedFields, errors, isSubmitted },
  } = useFormContext();

  const errorMessage = FormErrors.errorMessage(
    name,
    errors,
    touchedFields,
    isSubmitted,
    externalErrorMessage
  );

  const FieldName = useWatch({
    control,
    name: name,
  });

  const [Focus, SetFocus] = useState(false);

  //#endregion
  const onChange = (value) => {
    ///  SetValue(value);
    props.onChange && props.onChange(value);
  };

  const onKeyPress = (value) => {
    ///  SetValue(value);
    props.onKeyPress && props.onKeyPress(value);
  };

  const onKeyDown = (value) => {
    ///  SetValue(value);
    props.onKeyDown && props.onKeyDown(value);
  };

  const onSearchClick = (value) => {
    ///  SetValue(value);
    props.onSearchClick && props.onSearchClick(value);
  };

  const handleClickShowPassword = () => {
    setshowPassword(!showPassword);
  };

  const handleMouseDownPassword = (
    event: React.MouseEvent<HTMLButtonElement>
  ) => {
    event.preventDefault();
  };

  const handleClearClick = () => {
    setValue(name, "");
    onChange("");
  };

  //#region inputpropsEnd แสดง icon
  const inputpropsEnd = isPassword
    ? {
      startAdornment: prefix ? (
        <InputAdornment position="start">{prefix}</InputAdornment>
      ) : undefined,
      endAdornment: (
        <InputAdornment position="end">
          <IconButton
            aria-label="toggle password visibility"
            onClick={handleClickShowPassword}
            onMouseDown={handleMouseDownPassword}
            edge="end"
          >
            {showPassword ? <Visibility /> : <VisibilityOff />}
          </IconButton>
          {showClear && (
            <IconButton
              onClick={handleClearClick}
              sx={[{ display: watch(name) ? "" : "none" }]}
            >
              <ClearIcon />
            </IconButton>
          )}
        </InputAdornment>
      ),
    }
    : isSearch
      ? {
        startAdornment: prefix ? (
          <InputAdornment position="start">{prefix}</InputAdornment>
        ) : undefined,
        endAdornment: (
          <>
            <InputAdornment position="end">
              <FiSearch color="#3997df" onClick={onSearchClick} style={{ cursor: "pointer" }} />
            </InputAdornment>
            {showClear && (
              <IconButton
                onClick={handleClearClick}
                sx={[{ display: watch(name) ? "" : "none" }]}
              >
                <ClearIcon />
              </IconButton>
            )}
          </>
        ),
      }
      : {
        startAdornment: prefix ? (
          <InputAdornment position="start">{prefix}</InputAdornment>
        ) : undefined,
        endAdornment: (
          <>
            {suffix && <InputAdornment position="end">{suffix}</InputAdornment>}
            {showClear && (
              <IconButton
                onClick={handleClearClick}
                sx={[{ display: watch(name) ? "" : "none" }]}
              >
                <ClearIcon />
              </IconButton>
            )}
          </>
        ),
      };
  //#endregion

  //#region StyledInput เอาไวจัด styled
  const StyledInput = styled(TextField)({});

  //#endregion

  const maxLengthText: SxProps = {
    // "& input":{
    "::after": {
      content:
        showCoutTest && maxLength
          ? `"${getValues(name) ? getValues(name).length : 0}/${maxLength}"`
          : '""',
      position: "absolute",
      bottom: "0px",
      right: "10px",
      color: "#989898",
      fontSize: "10px",
    },
    // }
  };
  return (
    <Controller
      {...register(name)}
      control={control}
      name={name}
      rules={{ required: required }}
      render={({ field }) => (
        <TextField
          {...field}
          label={label}
          onKeyPress={(e) => {
            if (e.key === "Enter") {
              onKeyPress(e);
              e.preventDefault();
            }
          }}
          onKeyDown={(e) => {
            onKeyDown(e);
          }}

          sx={[
            { ...maxLengthText },
            { my: my, mx: mx },
            { borderRadius: isCurve ? "16px" : "" },
            { backgroundColor: "transparent !important" },
            // {margin: "0 !important"},
            {
              ".MuiOutlinedInput-root": {
                // paddingRight: isCurve ? "0px" : "",
                borderRadius: isCurve ? "16px" : "",
                width: width || "100%",
              },
              ".MuiTextField-root": {
                borderRadius: isCurve ? "16px" : "",
                width: width || "100%",
              },
              ".MuiFilledInput-root": {
                backgroundColor: "white !important"
              },
              ".MuiFilledInput-underline": {
                backgroundColor: "white !important"

              },
              ".MuiInputBase-root": {

                backgroundColor: "white !important"
              },
              ".MuiInputBase-root-MuiFilledInput-root:hover": {

                backgroundColor: "white !important"
              },
              "label.MuiInputLabel-formControl > span": {
                color: "#d32f2f"
              },
              ".MuiFormHelperText-root": {
                color: "#d32f2f"
              },
            },
          ]}
          placeholder={placeholder}
          InputProps={inputpropsEnd}
          disabled={disabled || false}
          fullWidth={fullWidth || false}
          multiline={multiline || false}
          required={required || false}
          size={size || "small"}
          variant={variant || "outlined"}
          margin={"normal"}
          type={
            type
              ? type
              : isPassword
                ? showPassword
                  ? "text"
                  : "password"
                : "text"
          }
          inputProps={{
            maxLength: maxLength ? maxLength : null,
            name,
            autoComplete: 'new-password',
          }}
          InputLabelProps={{
            // shrink: shrink,
            shrink: FieldName || Focus ? true : false,
          }}
          autoComplete={"disabled"}
          style={styles || undefined}
          defaultValue={defaultValues}
          onChange={(e) => {
            field.onChange(e);
            onChange(e.target.value);
          }}
          onBlur={(e) => {
            field.onBlur();
            SetFocus(false);
            props.onBlur && props.onBlur(e);
          }}
          onFocus={() => {
            SetFocus(true);
          }}
          error={!!Boolean(errorMessage)}
          helperText={errorMessage || helperText}
          rows={rows || undefined}
        />
      )}
    />
  );
};
export interface InputForm {
  /**
   * ชื่อ
   */
  name: string;
  /**
   * ข้อความข้างบน Textbox
   */
  label?: string;
  /**
   * ข้อความตัวอย่างใน Textbox
   */
  placeholder?: string;
  /**
   * ข้อความข้างหน้าใน Textbox
   */
  prefix?: string;
  /**
   * ข้อความข้างหลังใน Textbox
   */
  suffix?: string;
  /**
   * ปิดการใช้งาน
   */
  disabled?: boolean;
  /**
   * ความกว้างเต็ม
   */
  fullWidth?: boolean;
  /**
   * ข้อความใต้ Textbox
   */
  helperText?: string;
  /**
   * หลายบันทัด
   */
  multiline?: boolean;
  required?: boolean;
  /**
   * ขนาด
   */
  size?: "medium" | "small";
  /**
   * style
   */
  variant?: "filled" | "outlined" | "standard";
  onBlur?: (value: any) => void;
  onChange?: (value: any) => void;
  onKeyPress?: (value: any) => void;
  onKeyDown?: (value: any) => void;
  onSearchClick?: (value: any) => void;
  defaultValues?: string;
  /**
   * ประเภท
   */
  type?: string;
  /**
   * เป็น Textbox password ไหม
   */
  isPassword?: boolean;
  /**
   * จำนวนตัวอักษร
   */
  maxLength?: number;
  /**
   *  styles
   */
  styles?: any;
  /**
   * true = Lable จะขึ้นบนตลอด
   * false = Lable จะอยู่ในตลอด
   */
  shrink?: boolean | undefined;
  /***
   * ErrorMessage
   */
  externalErrorMessage?: any;
  /**
   * แสดงจำนวนที่กรอก
   * default true
   */
  showCoutTest?: boolean;
  /**
   * แสดงให้กด Clear
   * default true
   */
  showClear?: boolean;
  /**
   * แสดงIcon Search
   * default false
   */
  isSearch?: boolean;

  endAdornment?: any;
  isCurve?: boolean;
  rows?: number;
  width?: number;
  mx?: number;
  my?: number;
}
