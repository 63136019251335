//React
import React, { useEffect, useState } from "react";
import logo from "src/Layout/img/oba-logo.png";
//other - Mui
import { useNavigate } from "react-router";
import { Axios } from "src/CommonFunction/TS_Axios";
import { AlertMsg, AlertTitle, DialogConfirm, DialogConfirmDelete, SwAlert } from "src/Components/Alert/Sweetalert";
import { Extension, Process_System, ResultAPI,GetQueryString } from "src/CommonFunction/TS_function";
import { Avatar, Box, Button, Divider, Grid, IconButton, List, ListItem, ListItemAvatar, ListItemIcon, ListItemText, Typography, useMediaQuery } from "@mui/material";
import { GridColumns } from '@mui/x-data-grid';

//component Input
import InputNumberForm from "src/Components/FormItem/InputNumber";

//Button
import SearchIcon from '@mui/icons-material/Search';
//Yup
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { FormProvider, useForm } from "react-hook-form";
import yupFormSchemas from "src/Components/FormItem/yup/yupFormSchemas";
import { BreadcrumbsUI } from "src/Components/BreadcrumbsUI";
import { BtnAddOnTable, BtnSendCancel, BtnSendCutom } from "src/Components/Button";
import HaftHeaderCards from "src/Components/CardHeader/HaftHeaderCards";
import { InputForm } from "src/Components/FormItem/Input";
import UploadFile from "src/Components/ElementInForm/UploadFile/UploadFile";
import { resolve } from "path/win32";
import { Link } from "react-router-dom";
import { FnBlock_UI } from 'src/Components/BlockUI';

const AnswerFAQ = () => {

    const { BlockUI, UnBlockUI } = FnBlock_UI();
    const navigate = useNavigate();

    const matches = useMediaQuery('(min-width:900px)');
    const [oTrainingCourse, setoTrainingCourse] = useState({} as any)
    const [oCustomer, setoCustomer] = useState({} as any)
    const [arrFile, setArrFile] = useState<any[]>([]);
    const [nFAQID, setnFAQID] = useState(0)
    const [nInstructorID, setnInstructorID] = useState(0)

    const [oDataDetail, setoDataDetail] = useState(null)

    const [oDataCustomer, setoDataCustomer] = useState(null)
    const [sQuestion, setsQuestion] = useState("")
    const [arrDocFile, setarrDocFile] = useState([] as any)
    const IdFAQ = GetQueryString('f');          //nlFYpfLeB7RCvxNeYoMxxQ%3d%3d
    const IdInstructor = GetQueryString('i');           //xmSObHZ0xMu3qbOunaV2Ug%3d%3d



    const schema = yup.object().shape({
        sAnswer: yupFormSchemas.string("คำตอบ/Answer", { required: true }),
    });

    const form = useForm({
        resolver: yupResolver(schema),
        mode: "all",
    });

    useEffect(() => {
        GetParamDecrypt();
    }, [])

    const GetParamDecrypt = async () => {
        const search = window.location.search;
        const params = new URLSearchParams(search);
        // const IdFAQ = params.get("f");          //nlFYpfLeB7RCvxNeYoMxxQ%3d%3d
        // const IdInstructor = params.get("i");           //xmSObHZ0xMu3qbOunaV2Ug%3d%3d

        // const IdFAQ = "nlFYpfLeB7RCvxNeYoMxxQ%3d%3d";
        // const IdInstructor = "xmSObHZ0xMu3qbOunaV2Ug%3d%3d";


        let DecryptIdFAQ = 0;
        let DecryptIdInstructor = 0;

        await Axios(`Master/Decrypt`, "sValue=" + IdFAQ, "Get", async (res) => {
            DecryptIdFAQ = Number(res);
            setnFAQID(Number(res) || 0)
        }, null, BlockUI, UnBlockUI, null);

        await Axios(`Master/Decrypt`, "sValue=" + IdInstructor, "Get", async (res) => {
            DecryptIdInstructor = Number(res);
            setnInstructorID(Number(res) || 0)
        }, null, BlockUI, UnBlockUI, null);

        await onCheckStatusFAQ(DecryptIdFAQ, DecryptIdInstructor)
    }

    const onCheckStatusFAQ = async (IdFAQ, IdInstructor) => {
        let Param = {
            "nFAQID": IdFAQ
        }
        Axios(`FAQAnswer/CheckStatus_FAQ`, Param, "POST", async (res) => {
            ResultAPI(true, res, res.Message, () => {
                if (res.Status == Process_System.process_Success) {
                    onGetDataQuestion(IdFAQ, IdInstructor);
                }
                else {
                    SwAlert.Warning(AlertTitle.Warning, res.Message, () => {
                        navigate(process.env.REACT_APP_API_URL+"");
                    });
                }
            })
        }, null, BlockUI, UnBlockUI, null);
    }

    const onGetDataQuestion = async (IdFAQ, IdInstructor) => {
        let Param = {
            "nFAQID": IdFAQ
        }
        Axios(`FAQAnswer/GetDetail_FAQ`, Param, "POST", async (res) => {
            ResultAPI(true, res, res.Message, () => {
                console.log("res", res)
                if (res.Status == Process_System.process_Failed) {
                    SwAlert.Warning(AlertTitle.Warning, res.Message, () => { });
                }
                else {
                    if (res.sStatusID == "3")    //ตอบแล้ว
                    {
                        SwAlert.Warning(AlertTitle.Warning, "มีผู้ตอบคำถามนี้แล้ว", () => {
                            navigate(process.env.REACT_APP_API_URL + "");
                        });
                    }
                    else {
                        setoDataDetail(res)
                        setarrDocFile(res.lstFileQuestion || [])
                    }
                }

            })
        }, null, BlockUI, UnBlockUI, null);
    }

    const onSendAnswerFAQ = () => {       
        let oForm = form.getValues();
        let Param = {
            "nFAQID": nFAQID,
            "nAnswerByID": nInstructorID,
            "sAnswer": oForm.sAnswer ?? "",
            "lstFile": arrFile,
            "Message": "",
            "Status": "",
            "lstFileAnswer": [],
            "lstFileQuestion": [],
        }
        DialogConfirm(() => {
            Axios(`FAQAnswer/Upadte_FAQ`, Param, "POST", async (res) => {       //ชื่อ method  Update / Upadte
                ResultAPI(true, res, res.Message, () => {
                    if (res.Status == Process_System.process_Success) {
                        SwAlert.Success(AlertTitle.Success, AlertMsg.SaveComplete, () => {
                            navigate(process.env.REACT_APP_API_URL + "");
                        });
                    }
                    else {
                        SwAlert.Warning(AlertTitle.Warning, res.Message, () => { });
                    }
                })
            }, null, BlockUI, UnBlockUI, null);
        })
    }

    const onCancelAnswerFAQ = () => {
        form.clearErrors();
        setArrFile([])
        navigate(process.env.REACT_APP_API_URL + "");

    }

    return (
        <Grid container>
            <Grid item xs={12}>
                <BreadcrumbsUI
                    items={[
                        {
                            Key: "1",
                            menuName: "Frequently Asked Questions (FAQ)",
                            IsOnClick: false,
                        },
                    ]}
                    isShowHome={false}
                />
            </Grid>
            <Grid item xs={12}>
                <FormProvider {...form}>

                    <Grid container spacing={2} direction="row" justifyContent="center" alignItems="start" style={{ padding: matches ? '2em 5em' : '2em 1em' }}>
                        <Grid item xs={12}>
                            {/* Card */}
                            <Grid container spacing={4} direction="row" justifyContent="center" alignItems="start">
                                <Grid item xs={12} md={6}>
                                    <HaftHeaderCards
                                        skey={"hHCards01"}
                                        sTitle={"หลักสูตร / Training Course"}
                                        sColor={"#122768"}
                                    >
                                        <Grid container spacing={2} direction="row" justifyContent="start" alignItems="start">
                                            <Grid item xs={12} md={12}>
                                                <Typography><b>ชื่อหลักสูตร / Course Name</b></Typography>
                                                <Typography>{oDataDetail != null ? oDataDetail["sCourseNameFull"] ?? "-" : "-"}</Typography>
                                            </Grid>
                                            <Grid item xs={12} md={6}>
                                                <Typography><b>รุ่น / Session</b></Typography>
                                                <Typography>{oDataDetail != null ? oDataDetail["sCourseClass"] ?? "-" : "-"}</Typography>
                                            </Grid>
                                            <Grid item xs={12} md={6}>
                                                <Typography><b>วันที่อบรม / Date</b></Typography>
                                                <Typography>{oDataDetail != null ? oDataDetail["sCourseDate"] ?? "-" : "-"}</Typography>
                                            </Grid>
                                            <Grid item xs={12} md={12}>
                                                <Typography><b>สถานที่อบรม / Location</b></Typography>
                                                <Typography>{oDataDetail != null ? oDataDetail["sLocation"] ?? "-" : "-"}</Typography>
                                            </Grid>
                                        </Grid>
                                    </HaftHeaderCards>
                                </Grid>
                                <Grid item xs={12} md={6}>
                                    <HaftHeaderCards
                                        skey={"hHCards01"}
                                        sTitle={"ผู้ลงทะเบียน / Customer"}
                                        sColor={"#122768"}
                                    >
                                        <Grid container spacing={2} direction="row" justifyContent="start" alignItems="start">
                                            <Grid item xs={12} md={12}>
                                                <Typography><b>รหัสลูกค้า / Customer ID</b></Typography>
                                                <Typography>{oDataDetail != null ? oDataDetail["sQuestionBy"] ?? "-" : "-"}</Typography>
                                            </Grid>
                                            <Grid item xs={12} md={6}>
                                                <Typography><b>ชื่อผู้ลงทะเบียน / Name</b></Typography>
                                                <Typography>{oDataDetail != null ? oDataDetail["sQuestionByName"] ?? "-" : "-"}</Typography>
                                            </Grid>
                                            <Grid item xs={12} md={6}>
                                                <Typography><b>วันที่ถาม / Date</b></Typography>
                                                <Typography>{oDataDetail != null ? oDataDetail["sQuestionDate"] ?? "-" : "-"}</Typography>
                                            </Grid>
                                            <Grid item xs={12} md={12}>
                                                <Typography><b>อีเมล / E-mail</b></Typography>
                                                <Typography>{oDataDetail != null ? oDataDetail["sEmail"] ?? "-" : "-"}</Typography>     {/** ไม่มีฟิลด์ email id Customer */}
                                            </Grid>
                                        </Grid>
                                    </HaftHeaderCards>
                                </Grid>
                            </Grid>
                        </Grid>
                        {/* Question */}
                        <Grid item xs={12} style={{ marginLeft: '18px' }}>
                            <Typography><b>คำถาม / Question</b></Typography>
                            <Typography>&nbsp;&nbsp;&nbsp;{oDataDetail != null ? oDataDetail["sQuestion"] ?? "-" : "-"}</Typography>
                        </Grid>
                        {/* Document File */}
                        <Grid item xs={12} style={{ marginLeft: '18px' }}>
                            <Typography><b>เอกสารเพิ่มเติม / Document Files</b></Typography>
                            <List sx={{ width: '100%' }}>
                                {(arrDocFile || []).map((item, index) => {
                                    return (
                                        <>
                                            <ListItem key={index} alignItems="flex-start">
                                                <ListItemIcon style={{ margin: '0px' }}>
                                                    <a target='_blank' href={item.sFilePath + item.sSysFileName || "/"}>
                                                        <IconButton
                                                            sx={{
                                                                backgroundColor: "#05a6e8",
                                                                color: "#ffffff",
                                                                "&:hover": {
                                                                    backgroundColor: "#0b8abd",
                                                                },
                                                            }}
                                                        >
                                                            <SearchIcon />
                                                        </IconButton>
                                                    </a>
                                                </ListItemIcon>
                                                <ListItemText
                                                    primary={item.sFileName || "-"}
                                                    style={{ marginTop: '8px' }}
                                                />
                                            </ListItem>
                                        </>
                                    )
                                })}
                            </List>
                        </Grid>
                        {/* Answer */}
                        <Grid item xs={12}>
                            <Grid item xs={12} md={12} style={{ marginRight: '11px' }}>         {/*marginRight Because component form have margin */}
                                <InputForm
                                    fullWidth
                                    required={true}
                                    name={"sAnswer"}
                                    label="คำตอบ/Answer"
                                    showClear={false}
                                    multiline={true}
                                    rows={6}
                                    maxLength={5000}
                                />
                            </Grid>
                        </Grid>
                        {/* Upload Document File */}
                        <Grid item xs={12} md={12} style={{ margin: '0px 10px' }}>
                            <UploadFile
                                keyID={"sDocFile"}
                                sTitle={"เอกสารเพิ่มเติม/Document Files"}
                                sSubTitle={""}
                                nLimitFile={50}
                                IsRequired={false}
                                arrFile={arrFile || []}
                                setarrFile={setArrFile}
                                IsFolder={false}
                                IsFile={true}
                                IsMultiple={true}
                                isFileChange={true}
                                Extension={Extension.OBAFile}
                                IsDrag={false}
                            />
                        </Grid>
                        <Grid item>
                            <Grid container spacing={2} direction="row" justifyContent="center" alignItems="center">
                                <Grid item><BtnSendCutom onClick={form.handleSubmit(() => { onSendAnswerFAQ() })} /></Grid>
                                <Grid item><BtnSendCancel onClick={() => { onCancelAnswerFAQ() }} /></Grid>
                            </Grid>
                        </Grid>
                    </Grid>
                </FormProvider>
            </Grid>
        </Grid>
    )
}

export default AnswerFAQ