import { useEffect, useRef, useState } from "react";
import { i18n } from "src/i18n";
import * as yup from "yup";
import { yupResolver } from '@hookform/resolvers/yup';
import yupFormSchemas from 'src/Components/FormItem/yup/yupFormSchemas';
import { FormProvider, useForm } from "react-hook-form";
import { Grid, Box } from '@mui/material';
import "./ContactUsForm.css";
import { InputForm } from "src/Components/FormItem/Input";
import InputNumber from "src/Components/FormItem/InputNumber";
import SelectForm from "src/Components/FormItem/Select";
import AutoCompleteSelect from "src/Components/FormItem/AutoCompleteSelect";
import { Axios, AxiosPost } from "src/CommonFunction/TS_Axios";
import BlockUI1, { FnBlock_UI } from "src/Components/BlockUI";
import { AlertMsg, AlertTitle, SwAlert } from "src/Components/Alert/Sweetalert";
import { ResultAPI } from 'src/CommonFunction/TS_function';
import { Process_System } from "src/CommonFunction/TS_function";
import {
    BtnSend,
    BtnSendCancel

} from "src/Components/Button";
import {
    EmailOutlined,
    PhoneInTalkOutlined,
    FaxOutlined,
    MapOutlined,
    FacebookOutlined
} from "@mui/icons-material";
const i18nField = 'entities.contactUs.fields';
export function ContactUsForm(props: pContact) {
    const { register, setError, formState: { errors } } = useForm();
    const { BlockUI, UnBlockUI } = FnBlock_UI();
    const [lstDataContact, setDataContact] = useState<any[]>([]);
    const [lstDataTopic, setDataTopic] = useState<any[]>([]);
    const sMailTo = "mailto:" + props.data.sEmail;
    const objSchema = {
        lstTopic: yupFormSchemas.object(i18n(`${i18nField}.lstTopic`), { required: true }),
        sRequirement: yupFormSchemas.string(i18n(`${i18nField}.sRequirement`), { required: true }),
        sName: yupFormSchemas.string(i18n(`${i18nField}.sName`), { required: true }),
        sEmail: yupFormSchemas.string(i18n(`${i18nField}.sEmail`), { required: true }),
        sTel: yupFormSchemas.string(i18n(`${i18nField}.sTel`), { required: true }),
        lstContact: yupFormSchemas.object(i18n(`${i18nField}.lstContact`), { required: true }),
    };

    const schema = yup.object().shape(objSchema);

    const form = useForm({
        resolver: yupResolver(schema),
        mode: "all",
    });
    const GetContact = async () => {

        await Axios(
            `Content/GetData_SelectOptionsContact`, {}, "GET", async (res) => {
                setDataContact(res.lstContact);

            },
            null,
            BlockUI,
            UnBlockUI,
            () => { }
        );
    };
    const GetTopic = async () => {

        await Axios(
            `Content/GetData_SelectOptionsTopic`, {}, "GET", async (res) => {
                setDataTopic(res.lstContact);

            },
            null,
            BlockUI,
            UnBlockUI,
            () => { }
        );
    };
    const emailValidation = async () => {
        const regex = /^(([^<>()[\]\.,;:\s@\"]+(\.[^<>()[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i;
        const email = form.getValues('sEmail');
        if (!email || regex.test(email) === false) {
            form.setError('sEmail', { message: 'Email is not a valid' });


            return false;
        }
        return true;
    }
    const OnClear = async () => {
        form.reset();
        form.setValue("sRequirement", "");
        form.setValue("sName", "");
        form.setValue("sEmail", "");
        form.setValue("sTel", "");
        form.setValue("lstTopic", "");
        form.setValue("lstContact", "");


    }
    const onSubmit = () => {
        var oData = {
            sDetail: form.getValues("sRequirement"),
            sSenderName: form.getValues('sName'),
            sEmail: form.getValues('sEmail'),
            sTel: form.getValues('sTel'),
            //sTopic: form.getValues('lstTopic'),
            //sReply: form.getValues('lstContact'),
            sTopic: form.getValues("lstTopic").value,
            sReply: form.getValues('lstContact').value,


        };
        SwAlert.Confirm(AlertTitle.Confirm, "ต้องการส่งข้อมูลหรือไม่?", () => onSave(oData), () => { })

    };
    const onSave = async (data) => {
        await AxiosPost(`Content/SaveData_ContactForm`, data, (res) => {
            //  ResultAPI(true, res, null, () => {
            if (res.Status === Process_System.process_Success) {
                SwAlert.Success(AlertTitle.Success, "ส่งข้อมูลเรียบร้อย", () => OnClear());
            }
            else {
                SwAlert.Warning(AlertTitle.Warning, res.Message, () => { });
            }
            // })
        }, null, BlockUI, UnBlockUI, null)
    };

    useEffect(() => {
        GetContact();
        GetTopic();

    }, []);

    return (
        <>
            <FormProvider {...form}>
                <form onSubmit={form.handleSubmit(onSubmit)}>
                    <Grid xs={24} md={12} lg={12} >
                        <Grid container>
                            {/* style={{ border: "0.1px solid #00000036" #305871}} */}
                            <Grid item xs={12} md={6} style={{ backgroundColor: "#e5ebef", backgroundImage: "linear-gradient(to bottom right, #e5ebef,#053f64 )" }}>
                                <Grid item xs={24} md={12} lg={12} style={{ paddingTop: "30px" }}>
                                    <Box style={{ textAlign: "center", width: "80% !important" }}>
                                        {/* <SelectForm
                                            name="lstTopic"
                                            placeholder="- ระบุหัวข้อ -"
                                            label={i18n(`${i18nField}.lstTopic`)}
                                            required={true}
                                            fullWidth={false}
                                            width={{ width: "90%" }}
                                            options={lstDataTopic}
                                            defaultValue={""}
                                            isClearable
                                            variant={"filled"}

                                        /> */}
                                        <AutoCompleteSelect
                                            required
                                            name="lstTopic"
                                            label={i18n(`${i18nField}.lstTopic`)}
                                            width={{ width: "90%" }}
                                            options={lstDataTopic}
                                            onChange={(v) => {
                                                form.setValue('lstTopic', v ? v : null)
                                            }}
                                            variant={"filled"}

                                        />
                                    </Box>
                                </Grid>
                                <Grid item xs={24} md={12} lg={12}>
                                    <Box style={{ textAlign: "center" }}>
                                        <InputForm
                                            name="sRequirement"
                                            label={i18n(`${i18nField}.sRequirement`)}
                                            required={true}
                                            styles={{ width: "90%" }}
                                            fullWidth
                                            multiline={true}
                                            variant={"filled"}
                                            rows={4}
                                            maxLength={1000}
                                            shrink={(form.getValues('sRequirement') != "" && form.getValues('sRequirement') != undefined) ? true : false}
                                        />
                                    </Box>
                                </Grid>

                                <Grid item xs={24} md={12} lg={12}>
                                    <Box style={{ textAlign: "center" }}>
                                        <InputForm
                                            name="sName"
                                            label={i18n(`${i18nField}.sName`)}
                                            required={true}
                                            fullWidth
                                            styles={{ width: "90%" }}
                                            variant={"filled"}
                                            maxLength={100}
                                            shrink={(form.getValues('sName') != "" && form.getValues('sName') != undefined) ? true : false}
                                        />
                                    </Box>
                                </Grid>
                                <Grid item xs={24} md={12} lg={12}>
                                    <Box style={{ textAlign: "center" }}>
                                        <InputForm
                                            name="sEmail"
                                            label={i18n(`${i18nField}.sEmail`)}
                                            required={true}
                                            fullWidth
                                            styles={{ width: "90%" }}
                                            variant={"filled"}
                                            maxLength={300}
                                            //onChange={() => { emailValidation() }}                                                                  
                                            shrink={(form.getValues('sEmail') != "" && form.getValues('sEmail') != undefined) ? true : false}

                                        />
                                    </Box>
                                </Grid>
                                <Grid item xs={24} md={12} lg={12}>
                                    <Box style={{ textAlign: "center" }}>
                                        <InputForm
                                            name="sTel"
                                            label={i18n(`${i18nField}.sTel`)}
                                            required={true}
                                            fullWidth
                                            variant={"filled"}
                                            styles={{ width: "90%" }}
                                            maxLength={20}
                                            shrink={(form.getValues('sTel') != "" && form.getValues('sTel') != undefined) ? true : false}
                                        />
                                        {/* <InputNumber
                                            name="sTel"
                                            label={i18n(`${i18nField}.sTel`)}

                                            type={"number"}
                                            size="small"
                                            required={true}
                                            fullWidth
                                            integerDigits={20}
                                            styles={{ width: "90%" }}

                                        /> */}
                                    </Box>
                                </Grid>
                                <Grid item xs={24} md={12} lg={12} >
                                    <Box style={{ textAlign: "center", width: "80% !important" }}>
                                        {/* <SelectForm
                                            name="lstContact"
                                            placeholder="- ระบุช่องทาง -"
                                            label={i18n(`${i18nField}.lstContact`)}
                                            required={true}
                                            fullWidth
                                            width={{ width: "90%" }}
                                            options={lstDataContact}
                                            defaultValue={""}
                                            variant={"filled"}
                                        /> */}

                                        <AutoCompleteSelect
                                            required
                                            name="lstContact"
                                            label={i18n(`${i18nField}.lstContact`)}
                                            width={{ width: "90%" }}
                                            options={lstDataContact}
                                            onChange={(v) => {
                                                form.setValue('lstContact', v ? v : null)
                                            }}
                                            variant={"filled"}

                                        />
                                    </Box>
                                </Grid>
                                <Grid item xs={24} md={12} lg={12} style={{ paddingTop: "30px", paddingBottom: "30px" }}>
                                    <Box style={{ textAlign: "center" }}>
                                        <BtnSend
                                            txt="ส่ง/Send"
                                            onClick={() => { form.handleSubmit(onSubmit)() }}
                                        ></BtnSend>
                                        <>&nbsp;&nbsp;</>
                                        <BtnSendCancel
                                            txt="ยกเลิก/Cancel"
                                            onClick={() => OnClear()}
                                        ></BtnSendCancel>
                                    </Box>
                                </Grid>


                            </Grid>

                            <Grid item xs={12} md={6} style={{ paddingLeft: "3%", backgroundColor: "#213750" }}>

                                <Grid item xs={24} md={12} lg={12} style={{ paddingTop: "30px" }} className="pad-bottom">
                                    <Grid container>
                                        <Grid item xs={4} md={2} lg={2} className="cont-list">
                                            {/* <a href={sMailTo} target={"_top"} className="cont-detail cont-cursor"><img src={ImgEmail} /></a> */}
                                            <a href={sMailTo} target={"_top"} className="cont-detail cont-cursor">
                                                <EmailOutlined className="cont-icon"></EmailOutlined>
                                            </a>
                                        </Grid>
                                        <Grid item xs={20} md={10} lg={10}>
                                            <div className="cont-text">
                                                <div className="cont-title">E-mail</div>
                                                <a href={sMailTo} target={"_top"} className="cont-detail cont-cursor">{props.data.sEmail}</a>
                                            </div>
                                        </Grid>
                                    </Grid>
                                </Grid>
                                <Grid item xs={24} md={12} lg={12} className="pad-bottom">
                                    <Grid container>
                                        <Grid item xs={4} md={2} lg={2}>
                                            {/* <img src={ImgPhone} /> */}
                                            <PhoneInTalkOutlined className="cont-icon"></PhoneInTalkOutlined>
                                        </Grid>
                                        <Grid item xs={20} md={10} lg={10}>
                                            <div className="cont-text">
                                                <div className="cont-title">Phone</div>
                                                <div className="cont-detail">{props.data.sTel}</div>
                                                <div className="cont-detail">{props.data.sTelExt}</div>
                                            </div>
                                        </Grid>
                                    </Grid>
                                </Grid>

                                <Grid item xs={24} md={12} lg={12} className="pad-bottom">
                                    <Grid container>
                                        <Grid item xs={4} md={2} lg={2}>
                                            {/* <img src={ImgFax} /> */}
                                            <FaxOutlined className="cont-icon"></FaxOutlined>
                                        </Grid>
                                        <Grid item xs={20} md={10} lg={10}>
                                            <div className="cont-text">
                                                <div className="cont-title">Fax</div>
                                                <div className="cont-detail">{props.data.sTelFax}</div>
                                            </div>
                                        </Grid>
                                    </Grid>
                                </Grid>
                                <Grid item xs={24} md={12} lg={12} className="pad-bottom">
                                    <Grid container>
                                        <Grid item xs={4} md={2} lg={2} className="cont-list">
                                            {/* <a href={props.data.sOpenMap} target={"_blank"} className="cont-detail cont-cursor"><img src={ImgMap} /></a> */}
                                            <a href={props.data.sOpenMap} target={"_blank"} className="cont-detail cont-cursor"><MapOutlined className="cont-icon"></MapOutlined></a>
                                        </Grid>
                                        <Grid item xs={20} md={10} lg={10}>
                                            <div className="cont-text">
                                                <div className="cont-title">Map</div>
                                                <a href={props.data.sOpenMap} target={"_blank"} className="cont-detail cont-cursor">{props.data.sOpenMapDesc}</a>
                                                <><br></br></>
                                                <a href={props.dataFire.sOpenMap} target={"_blank"} className="cont-detail cont-cursor">{props.dataFire.sOpenMapDesc}</a>
                                            </div>
                                        </Grid>
                                    </Grid>
                                </Grid>

                                <Grid item xs={24} md={12} lg={12} className="pad-bottom">

                                    <Grid container>
                                        <Grid item xs={4} md={2} lg={2} className="cont-list">
                                            {/* <a href={props.data.sFacebook} target={"_blank"} className="cont-detail cont-cursor"><img src={ImgFacebook} /></a> */}
                                            <a href={props.data.sFacebook} target={"_blank"} className="cont-detail cont-cursor"><FacebookOutlined className="cont-icon"></FacebookOutlined></a>
                                        </Grid>
                                        <Grid item xs={20} md={10} lg={10}>
                                            <div className="cont-text">
                                                <div className="cont-title">Folllow Me</div>
                                                <a href={props.data.sFacebook} target={"_blank"} className="cont-detail cont-cursor">{props.data.sValueFBDesc}</a>
                                                <><br></br></>
                                                <a href={props.dataFire.sFacebook} target={"_blank"} className="cont-detail cont-cursor">{props.dataFire.sFacebookText}</a>
                                            </div>
                                        </Grid>
                                    </Grid>
                                </Grid>


                            </Grid>

                        </Grid>
                    </Grid>

                </form>

            </FormProvider>



        </>
    );
}
interface pContact {
    data?: any;
    dataFire?: any;


}
