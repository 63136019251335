import React, { useState, useEffect } from "react";
import { makeStyles } from "@mui/styles";
import { Theme } from "@mui/material/styles";
import Zoom from "@mui/material/Zoom";
import useScrollTrigger from "@mui/material/useScrollTrigger";
import PropTypes from "prop-types";
import Slide from '@mui/material/Slide';

interface Props {
  children: React.ReactElement;
}

function HideOnScroll({ children }: Props) {
  const trigger = useScrollTrigger({threshold:0});
  return (
    <Zoom  in={!trigger}>
      {children}
    </Zoom>
  );
}

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    zIndex:1000,
    display: "flex",
    bottom: theme.spacing(8),
    left: "25px",
    position: "fixed",
    // paddingLeft: "40px",
    width:"46.7px",
    height:"87.5px",
    // justifyContent:"center"
    // justifyContent:"flex-start",
    // width:"100vw",
  }
}));

export const ScrollToBottom = (props) => {
  const { children } = props;
  const classes = useStyles();
  const trigger = useScrollTrigger({
    disableHysteresis: true,
    threshold:0,
  });
  const handleClick = (event) => {
    window.scrollTo({
        top: document.documentElement.scrollHeight,
        behavior: "smooth",
      });
    // let getBotton = document.getElementById("toButtom")
    // if(getBotton !== null){
    //   getBotton.style.display = "none"
    // }
  };

  useEffect(() => {
  }, []);

  return (
    <HideOnScroll>
      <Zoom in={!trigger}>
        <div
          onClick={handleClick}
          role="presentation"
          className={classes.root}
          id="toButtom"
        >
          {children}
        </div>
      </Zoom>
    </HideOnScroll>
  );
};

ScrollToBottom.propTypes = {
  children: PropTypes.element.isRequired,
};
