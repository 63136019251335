import React, { useState, useEffect } from "react";
import FormGroup from "@mui/material/FormGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import Checkbox from "@mui/material/Checkbox";
import FormLabel from "@mui/material/FormLabel";
import FormControl from "@mui/material/FormControl";
import { Box, Divider } from "@mui/material";
import { Controller, useFormContext } from "react-hook-form";
import FormErrors from "../formErrors";

export function CheckBoxUI(props: CheckBoxUI) {
  const {
    id,
    labelPlacement,
    type,
    disabled,
    label,
    options,
    name,
    required,
    AllowCheckAll,
    defaultValues,
    labelCheckAll,
    isClear,
  } = props;

  const {
    register,
    setValue,
    getValues,
    watch,
    control,
    formState: { touchedFields, errors, isSubmitted },
  } = useFormContext();

  const [checkValue, setCheckValue] = useState([]);
  useEffect(() => {
    setValue(name, checkValue);

  }, [checkValue]);
  
  useEffect(() => {
    if(isClear){
     setCheckValue([])
    }
   },[isClear]) 

  useEffect(() => {
   if(isClear){
    setCheckValue([])
   }
  },[isClear])  

  const handleChange1 = async (isChecked) => {
    if (isChecked)
      return setCheckValue((options || []).map((m) => m.value) as never);
    else setCheckValue([]);
  };

  const handleChange2 = async (isChecked, value) => {
    const index = checkValue.indexOf(value as never);

    if (isChecked) {
      return setCheckValue((state) => [...state, value] as never);
    }

    if (!isChecked && index > -1)
      return setCheckValue((state) => {
        state.splice(index, 1);
        return state;
      });
  };

  return (
    <>
      <Controller
        {...register(name)}
        control={control}
        name={name}
        rules={{ required: required }}
        render={({ field }) => (
          <>
            {type == "group" ? (
              <FormControl
                sx={{ m: 1 }}
                component="fieldset"
                variant="standard"
              >
                <FormLabel component="legend">{label}</FormLabel>
                <FormGroup>
                  {AllowCheckAll && (
                    <>
                      <FormControlLabel
                        label={labelCheckAll || "เลือกทั้งหมด"}
                        control={
                          <Checkbox
                            checked={
                              checkValue.length === (options || []).length
                            }
                            indeterminate={
                              checkValue.length !== (options || []).length &&
                              checkValue.length > 0
                            }
                            onChange={(event) => {
                              handleChange1(event.target.checked),
                                setValue(name, checkValue);
                              props.onChange && props.onChange(checkValue);
                            }}
                            value={watch(name)}
                          />
                        }
                      />
                    </>
                  )}
                  {checkValue &&
                    <Box sx={{ display: "flex", flexDirection: "column", ml: "8px" }}>
                      {(options || []).map((m) => (
                        <FormControlLabel
                          key={m.value}
                          label={m.label}
                          labelPlacement={"end"}
                          control={
                            <Checkbox
                              // key={m.value}
                              checked={checkValue.includes(m.value as never)}
                              onChange={(event) => {
                                handleChange2(event.target.checked, m.value);
                                setValue(name, checkValue);
                                props.onChange && props.onChange(checkValue);
                              }}
                              value={watch(name)}

                            />
                          }
                        />
                      ))}
                    </Box>
                  }
                  {/* <h3>ID's: {JSON.stringify(checkValue)}</h3> */}
                </FormGroup>
              </FormControl>
            ) : (
              <Box sx={{ display: "flex", flexDirection: "column", ml: "8px" }}>
                {(options || []).map((m) => (
                  <FormControlLabel
                    key={m.value}
                    label={m.label}
                    labelPlacement={"end"}
                    control={
                      <Checkbox
                        // key={m.value}
                        checked={checkValue.includes(m.value as never)}
                        onChange={(event) => {
                          handleChange2(event.target.checked, m.value);
                          setValue(name, checkValue);
                          props.onChange && props.onChange(checkValue);
                        }}
                        value={watch(name)}
                      />
                    }
                  />
                ))}
              </Box>
            )}
          </>
        )}
      />
    </>
  );
}

interface CheckBoxUI {
  name: string;
  id?: string;
  labelPlacement?: "bottom" | "end" | "start" | "top";
  type?: "group" | "single";
  disabled?: boolean;
  label?: string;
  required?: boolean;
  options?: any[];
  AllowCheckAll?: boolean;
  defaultValues?: any;
  labelCheckAll?: string;
  isClear?: boolean;
  onChange?: (value: any) => void;
}
