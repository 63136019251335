import { createRoot } from 'react-dom/client';
import App from './App';
import { BrowserRouter as Router } from 'react-router-dom';
import ReactDOM from "react-dom";
import { createBrowserHistory } from 'history';
import { store } from "src/store";
import { Provider } from "react-redux";
(async function () {
  ReactDOM.render(

    <Provider store={store}>
      <Router>
        <App />
      </Router>
    </Provider>,
    document.getElementById("root")
  );
})();