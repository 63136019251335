export const CalculateRegister = (props: any) => {
    const { nTotalPerson, nTrainingFee, nVATRate, nWHTRate, IsNoTax
        , setnSummary, setnVat, setnSummaryVAT, setnNoWHT, setnTotal } = props;
    let cal1 = 0 as any;
    let cal2 = 0 as any;
    let cal3 = 0 as any;
    let cal4 = 0 as any;
    let cal5 = 0 as any;
    let c1 = 0 as any;
    let c2 = 0 as any;
    let c3 = 0 as any;
    //จำนวนเงินที่ลงทะเบียนคือเงินที่รวม Vat มาแล้ว
    //step 1 ค่าอบรมรวมภาษีมูลค่าเพิ่ม / Summary+VAT (จำนวนเงิน * จำนวนคน)
    //step 2 ภาษีมูลค่าเพิ่ม 7% / VAT (cal1 * 7)/107
    //step 3 (ค่าอบรมรวมภาษีมูลค่าเพิ่ม / Summary+VAT) - (ภาษีมูลค่าเพิ่ม 7% / VAT)
    //step 4 ((รวมค่าอบรม / Summary) * 3) / 100
    //step 5 (ค่าอบรมรวมภาษีมูลค่าเพิ่ม / Summary+VAT) - (หักภาษี ณ ที่จ่าย 3% / WHT)

    cal1 = (nTotalPerson * nTrainingFee).toFixed(2);
    c1 = (nVATRate * 100).toFixed(2);
    c2 = ((nVATRate * 100) + 100).toFixed(2);
    cal2 = ((cal1 * c1) / c2).toFixed(2);

    cal3 = (cal1 - cal2).toFixed(2);
    c3 = (nWHTRate * 100).toFixed(2);

    cal4 = IsNoTax == true ? (0).toFixed(2) : ((cal3 * c3) / 100).toFixed(2);
    cal5 = (cal1 - cal4).toFixed(2);

    setnSummary(cal3)
    setnVat(cal2)
    setnSummaryVAT(cal1)
    setnNoWHT(cal4)
    setnTotal(cal5)
}