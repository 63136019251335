//React
import React, { useEffect, useState } from "react";
//other - Mui
import { FnBlock_UI } from "../../BlockUI";
import { useNavigate } from "react-router";
import { Axios } from "src/CommonFunction/TS_Axios";
import { AlertMsg, AlertTitle, DialogConfirm, DialogConfirmDelete, SwAlert } from "src/Components/Alert/Sweetalert";
import { Process_System, ResultAPI, lnkToHomePage } from "src/CommonFunction/TS_function";
import { DialogCustomUI } from "src/Components/Dialog/DialogCustomUI";
import { Box, Checkbox, DialogActions, DialogContent, Divider, Fade, FormControlLabel, Grid, Modal, Typography } from "@mui/material";
import { GridColumns } from '@mui/x-data-grid';
import { i18n } from "src/i18n";
//component Input

//Button
import { BtnAddOnTable, BtnBack, BtnCancelForm, BtnEditOnTable, BtnOK, BtnSave, BtnSaveForm } from "../../Button";

//Yup
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { FormProvider, useForm } from "react-hook-form";
import yupFormSchemas from "src/Components/FormItem/yup/yupFormSchemas";
import { InputForm } from "src/Components/FormItem/Input";
import { CheckBox } from "@mui/icons-material";
import PopupSuccess from "./PopupSuccess";

/**
 * 
 * @param props 
 * IsOpen
 * setIsOpen
 * @returns 
 */
const PopupConfirm = (props) => {

    const { IsOpenModal, setIsOpenModal, SaveData, sCourseID, sScheduleID, sPriceID, arrPerson, sRegisterID, isWaiting } = props

    const navigate = useNavigate();
    const { BlockUI, UnBlockUI } = FnBlock_UI();

    const [IsOpenSuccess, setIsOpenSuccess] = useState(false)
    const [IsCheck, setIsCheck] = useState(false)
    const [isCenter, setAling] = useState(true);
    const [nWidth, setWidth] = useState(window.innerWidth);
    const schema = yup.object().shape({
        nPersonTrain: yupFormSchemas.string("จำนวนผู้เข้าอบรม", { required: true }),
    });
    const formReserve = useForm({
        resolver: yupResolver(schema),
        mode: 'all',
        shouldFocusError: true,
        criteriaMode: "firstError"
    })
    useEffect(() => {

        if (nWidth >= 600) {
            setAling(false);
        } else {
            setAling(true);
        }

    }, [nWidth]);
    useEffect(() => {
        window.addEventListener("resize", updateDimensions);
        return () => window.removeEventListener("resize", updateDimensions);
    }, []);
    const updateDimensions = () => {
        setWidth(window.innerWidth);
    }
    useEffect(() => {
        setIsCheck(false)
    }, [IsOpenModal])

    const handleCheck = async () => {
        setIsOpenModal(false);
        let Param = {
            sCourseID: sCourseID,
            sScheduleID: sScheduleID,
            sPriceID: sPriceID,
            sRegID: sRegisterID,
            lstParticipant: arrPerson,
            isWaiting: isWaiting,
        }

        await Axios(
            `Classroom/Registration_Validate`, Param, "POST", async (res) => {
                ResultAPI(true, res, null, () => {
                    if (res.isSuccess) {
                        setIsOpenModal(false)
                        SaveData();
                    }
                    else {
                        if (res.sStatus == "Expired") {
                            SwAlert.Warning(undefined, i18n("common.SessionTimeOut"), lnkToHomePage);

                        } else {
                            SwAlert.Warning(AlertTitle.Warning, res.sMessage, () => { });
                        }

                    }
                })
            },
            null,
            null,
            null,
            null
        );
    }

    const elementAction = () => {
        return (
            <Grid container spacing={1} direction="row" justifyContent="center" alignItems="center">
                <Grid item><BtnOK IsDisabled={!IsCheck} onClick={() => { handleCheck() }} /></Grid>
                <Grid item><BtnCancelForm onClick={() => { setIsOpenModal(false) }} /></Grid>
            </Grid>
        )

    }

    return (
        <DialogCustomUI
            open={IsOpenModal}
            textHead="ยืนยันการดำเนินการ/Confirmation"
            fullWidth
            questionIcon
            handleClose={() => { setIsOpenModal(false) }}
            isSetHeadColor={true}
            titleBgColor={"#43a7f3"}
            titleColor={"#fff"}
            isShowPopupConfirm={true}
            elementAction={elementAction}
        >
            <DialogContent sx={{
                display: "flex"
                , flexDirection: "column"
                , alignItems: "center", m: "1em", paddingBottom: "0px"
            }}>
                <Grid container spacing={2} direction="row" justifyContent="center" alignItems="center">
                    <Grid item xs={12} md={12} style={{ padding: '0px' }}>
                        <Divider />
                    </Grid>
                    <Grid item xs={12} md={12}>
                        <Grid container spacing={2} direction="row" justifyContent="start" alignItems="center">
                            <Grid item xs={12} md={12}>
                                <Typography>
                                    โปรดตรวจทานข้อมูลและอ่านเงื่อนไขการชำระเงินก่อนการยืนยัน
                                </Typography>
                                <Typography>
                                    Please read the terms and conditions carefully.
                                </Typography>
                            </Grid>
                            <Grid item xs={12} md={12}>
                                {/* <InputForm /> */}
                            </Grid>
                            <Grid item xs={12} md={12}>
                                <Typography>
                                    หากต้องการลงทะเบียนให้กดปุ่ม <><b>"ยืนยัน"</b></>
                                </Typography>
                                <Typography>
                                    Press <><b>"OK"</b></> to Confirm
                                </Typography>
                            </Grid>
                            <Grid item xs={12} md={12}>
                                <FormControlLabel
                                    control={
                                        <Checkbox
                                            onChange={(e) => { setIsCheck(e.target.checked) }}
                                        />}
                                    label={
                                        <>
                                            <Typography>
                                                อ่านและยอมรับเงื่อนไขการชำระเงินเรียบร้อยแล้ว
                                            </Typography>
                                            <Typography>
                                                I have read and understand the terms & conditions of this agreement.
                                            </Typography>
                                        </>
                                    }
                                />
                            </Grid>
                        </Grid>
                    </Grid>
                    <PopupSuccess
                        IsOpenModal={IsOpenSuccess}
                        setIsOpenModal={setIsOpenSuccess}
                    />
                </Grid>
            </DialogContent>
            <DialogActions sx={{ borderTop: "1px solid rgba(0,0,0,0.3)" }}>
                <Grid container spacing={1} direction="row" justifyContent={(!isCenter) ? "right" : "center"} alignItems={(!isCenter) ? "right" : "center"}>
                    <Grid item><BtnOK txt="ยืนยัน/Ok" IsDisabled={!IsCheck} onClick={() => { handleCheck() }} /></Grid>
                    <Grid item><BtnCancelForm onClick={() => { setIsOpenModal(false) }} /></Grid>
                </Grid>
            </DialogActions>

        </DialogCustomUI>

        // <Modal
        //     open={IsOpenModal}
        //     onClose={() => { setIsOpenModal(false) }}
        //     aria-labelledby="modal-modal-title"
        //     aria-describedby="modal-modal-description"
        //     onBackdropClick={() => { setIsOpenModal(false)}}
        // >
        //     <Fade in={IsOpenModal}>
        //         <Box sx={{
        //             position: 'absolute' as 'absolute',
        //             top: '50%',
        //             left: '50%',
        //             minWidth: '50%',
        //             transform: 'translate(-50%, -50%)',
        //             bgcolor: 'background.paper',
        //             boxShadow: 24,
        //             padding: '16px'
        //         }}>
        //             <Grid container spacing={2} direction="row" justifyContent="center" alignItems="center">
        //                 <Grid item xs={12} md={12}>
        //                     {/* <img src="" alt="LOGO" style={{ width: '10px', height: '10px' }} /> */}
        //                     <Typography variant="h6" component="h2">
        //                         ยืนยันการดำเนินการ/Conformation
        //                     </Typography>
        //                 </Grid>
        //                 <Grid item xs={12} md={12} style={{padding:'0px'}}>
        //                     <Divider />
        //                 </Grid>
        //                 <Grid item xs={12} md={12}>
        //                     <Grid container spacing={2} direction="row" justifyContent="start" alignItems="center">
        //                         <Grid item xs={12} md={12}>
        //                             <Typography>
        //                                 โปรดตรวจทานข้อมูลและอ่านเงื่อนไขการชำระเงินก่อนการยืนยัน
        //                             </Typography>
        //                             <Typography>
        //                                 Please read the terms and conditions carefully.
        //                             </Typography>
        //                         </Grid>
        //                         <Grid item xs={12} md={12}>
        //                             {/* <InputForm /> */}
        //                         </Grid>
        //                         <Grid item xs={12} md={12}>
        //                             <Typography>
        //                                 หากต้องการลงทะเบียนให้กดปุ่ม <><b>"ยืนยัน"</b></>
        //                             </Typography>
        //                             <Typography>
        //                                 Press <><b>"OK"</b></> to Confirm
        //                             </Typography>
        //                         </Grid>
        //                         <Grid item xs={12} md={12}>
        //                             <FormControlLabel
        //                                 control={
        //                                     <Checkbox 
        //                                         onChange={(e)=>{setIsCheck(e.target.checked)}}
        //                                     />}
        //                                 label={
        //                                     <>
        //                                         <Typography>
        //                                             อ่านและยอมรับเงื่อนไขการชำระเงินเรียบร้อยแล้ว
        //                                         </Typography>
        //                                         <Typography>
        //                                             I have read and understand the terms & conditions of this agreement.
        //                                         </Typography>
        //                                     </>
        //                                 }
        //                             />
        //                         </Grid>
        //                     </Grid>
        //                 </Grid>
        //                 <Grid item xs={12} md={12} style={{padding:'0px'}}>
        //                     <Divider />
        //                 </Grid>
        //                 <Grid item xs={12} md={12}>
        //                     <Grid container spacing={1} direction="row" justifyContent="right" alignItems="center">
        //                         <Grid item><BtnOK IsDisabled={!IsCheck} onClick={()=>{handleCheck()}}/></Grid>
        //                         <Grid item><BtnCancelForm onClick={()=>{setIsOpenModal(false)}}/></Grid>
        //                     </Grid>
        //                 </Grid>
        //                 <PopupSuccess
        //                     IsOpenModal={IsOpenSuccess}
        //                     setIsOpenModal={setIsOpenSuccess}
        //                 />
        //             </Grid>
        //         </Box>
        //     </Fade>

        // </Modal>
    )
}

export default PopupConfirm