import React from 'react'
import {
    Autocomplete,
    TextField,
} from "@mui/material";
import { MdSearch } from 'react-icons/md';

export default function AutoCompleteInTable({
    lstOption,
    funcOnChange,
    objValue,
    small = false,
    disClearable,
    label = null,
    disabled = false,
    sxCustom = {}
}) {
    return (
        <Autocomplete
            renderOption={(props, option) => {
                return (
                    <li {...props} key={option.value}>
                        {option.label}
                    </li>
                );
            }}
            disableClearable={disClearable}
            disabled={disabled}
            size={small ? "small" : "medium"}
            value={{ label: objValue.label, value: objValue.value }}
            onChange={(event: any, newValue: any) => {
                funcOnChange(event, newValue)
            }}
            options={lstOption}
            getOptionLabel={(item) => {
                return `${item.label}`;
            }}
            renderInput={(params) => (
                <TextField
                    label={null}
                    sx={{
                        ".MuiInputBase-input ":{
                            width:"20px !important",
                        },
                        ".MuiOutlinedInput-root": {
                            padding: "0px 5px !important",
                            " fieldset > legend > span": {
                                padding: "0px !important"
                            }
                        }, ...sxCustom
                    }}
                    {...params}
                    InputProps={{
                        endAdornment: (
                               <MdSearch size={20}/>
                        ),
                    }}
                />
            )}
        />
    )
}
