import BlockUI1, { FnBlock_UI } from "src/Components/BlockUI";
import { useEffect, useRef, useState } from "react";
import { WhatsNewsHome } from "src/Components/WhatsNewsHome";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import { Axios, AxiosPost } from "src/CommonFunction/TS_Axios";
import { InputForm } from "src/Components/FormItem/Input";
import { i18n } from "src/i18n";
import * as yup from "yup";
import { yupResolver } from '@hookform/resolvers/yup';
import yupFormSchemas from 'src/Components/FormItem/yup/yupFormSchemas';
import { FormProvider, useForm } from "react-hook-form";
import Logo from "src/Layout/img/oba-logo.png";
import imgLogin from "src/Layout/img/activity-1.jpg";
import { Process_System } from "src/CommonFunction/TS_function";
import { AlertMsg, AlertTitle, SwAlert } from "src/Components/Alert/Sweetalert";
import { useNavigate } from 'react-router-dom';
import { ResultAPI } from 'src/CommonFunction/TS_function';
import {
    BtnSearch,
    BtnLogin,
    BtnDownload,
    BtnSingUp

} from "src/Components/Button";

const i18nField = 'entities.authen.fields';
const Controller = 'AuthenLogin';

const authen = () => {
    const { BlockUI, UnBlockUI } = FnBlock_UI();
    const [loading, setLoading] = useState(false);
    const navigate = useNavigate();
    const objSchema = {
        sUsername: yupFormSchemas.string(i18n(`${i18nField}.sUsername`), { required: true }),
        sPassword: yupFormSchemas.string(i18n(`${i18nField}.sPassword`), { required: true }),

    };

    const schema = yup.object().shape(objSchema);

    const form = useForm({
        resolver: yupResolver(schema),
        mode: "all",


    });

    const GotoPage = (sRedirect) => {
        navigate(sRedirect);
    }
    const onSubmit = () => {
        // var oData = {
        //     sUsername: form.getValues("sUsername"),
        //     sPassword: form.getValues('sPassword'),

        // };
        //onLogin(oData);
         SwAlert.Login("เข้าสู่ระบบ/Login");
    };
    const onLogin = (oData) => {
        AxiosPost(`${Controller}/Login`, oData, res => {
            ResultAPI(true, res, null, () => {
                if (res.Status === Process_System.process_Success) {
                    if (res.token) {
                        localStorage.setItem(process.env.REACT_APP_JWT_KEY as string, res.token);
                        GotoPage(process.env.REACT_APP_API_URL + res.sRedirect);
                        window.location.reload();
                        FnBlock_UI();
                    }
                    else if (res.sRedirect) {
                        GotoPage(process.env.REACT_APP_API_URL + res.sRedirect);
                        window.location.reload();
                    }

                }


                else {
                    SwAlert.Warning(AlertTitle.Warning, res.Message, () => { });
                }
            })
        }, null, BlockUI, UnBlockUI,null)
    };

    const onKeyDown = (e) => {
        if (e.ctrlKey && e.altKey && e.which === 81) {
            var Params = {
                sUsername: '0010000858',
                sPassword: "1234",
            };
            onLogin(Params);
        }
    }
    
    return (
        <>
            <FormProvider {...form}>
                <form onSubmit={form.handleSubmit(onSubmit)}>
                    <Grid xs={24} md={12} item style={{ margin: "30px" }}>
                        <Grid container>
                            <Grid item xs={12} md={6}>
                                <div
                                    className="blog-card spring-fever"
                                    style={{ background: `url(${imgLogin}) 100% center no-repeat` }}
                                >
                                    <Box style={{ textAlign: "left", paddingTop: "1%", paddingLeft: "2%", paddingBottom: "0" }}>

                                        <p style={{ fontSize: "50px" }}>สมัครเข้าใช้งาน<><br></br></>เว็บไซต์ OBA</p>
                                    </Box>
                                    <Box style={{ textAlign: "left", paddingLeft: "25%", paddingTop: "0" }}>

                                        <BtnSingUp
                                            txt="สมัครสมาชิก/Sign Up"

                                        ></BtnSingUp>
                                    </Box>
                                </div>
                            </Grid>

                            <Grid item xs={12} md={6} style={{ border: "0.1px solid #00000036" }}>
                                <Grid item xs={24} md={12} lg={12}>
                                    <Box style={{ textAlign: "center", paddingTop: "7%" }}>
                                        <img src={Logo} style={{ width: "200px", height: "auto" }} />
                                    </Box>
                                </Grid>
                                <Grid item xs={24} md={12} lg={12}>
                                    <Box style={{ textAlign: "center" }}>
                                        <InputForm
                                            name="sUsername"
                                            label={i18n(`${i18nField}.sUsername`)}
                                            required={true}
                                            fullWidth
                                            styles={{ width: "50%" }}
                                            onKeyDown={onKeyDown}
                                        />
                                    </Box>
                                </Grid>
                                <Grid item xs={24} md={12} lg={12}>
                                    <Box style={{ textAlign: "center" }}>
                                        <InputForm
                                            name="sPassword"
                                            label={i18n(`${i18nField}.sPassword`)}
                                            required={true}
                                            fullWidth
                                            styles={{ width: "50%" }}
                                            isPassword={true}
                                        />
                                    </Box>
                                </Grid>
                                <Grid item xs={24} md={12} lg={12}>
                                    <Box style={{ textAlign: "left", paddingLeft: "25%", paddingRight: "25%" }}>
                                        <BtnLogin
                                            txt="เข้าสู่ระบบ/Login"
                                            onClick={() => { form.handleSubmit(onSubmit)() }}
                                        ></BtnLogin>
                                    </Box>
                                </Grid>
                                <Grid item xs={24} md={12} lg={12}>
                                    <Box style={{ textAlign: "left", paddingTop: "2%", paddingLeft: "25%" }}>
                                        <a href={process.env.REACT_APP_API_URL + "ForgetUserName"} style={{ color: "rgb(0, 0, 255)" }} className="text-primary">ลืมชื่อผู้ใช้งาน/Forgot Username</a>
                                    </Box>
                                </Grid>
                                <Grid item xs={24} md={12} lg={12}>
                                    <Box style={{ textAlign: "left", paddingLeft: "25%" }}>
                                        <a href={process.env.REACT_APP_API_URL + "ForgetPassword"} style={{ color: "rgb(0, 0, 255)" }} className="text-primary">ลืมรหัสผ่าน/Forgot Password</a>
                                    </Box>
                                </Grid>
                            </Grid>

                        </Grid>
                    </Grid>
                </form>
            </FormProvider>
        </>
    )

}
export default authen;
