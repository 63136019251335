import BlockUI1, { FnBlock_UI } from "src/Components/BlockUI";
import { useEffect, useRef, useState } from "react";
import { WhatsNewsHome } from "src/Components/WhatsNewsHome";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import { Axios } from "src/CommonFunction/TS_Axios";
import { CourseUpdateHome } from "src/Components/CourseUpdateHome";
import { KnowledgeSharingHome } from "src/Components/KnowledgeSharingHome";
import { Banner } from "src/Components/Banner";
import { NewsHome } from "src/Components/NewsHome";
import defaultBanner1 from "src/Layout/img/default-banner/de-banner1.jpg";
import defaultBanner2 from "src/Layout/img/default-banner/de-banner2.jpg";
import defaultBanner3 from "src/Layout/img/default-banner/de-banner3.jpg";
import defaultBanner4 from "src/Layout/img/default-banner/de-banner4.jpg";
import AOS from "aos";
import "aos/dist/aos.css";
import { IconButton } from "@mui/material";
import { useNavigate } from "react-router";
import secureLocalStorage from "react-secure-storage";
import { Helmet } from "react-helmet";

const home = () => {
  const navigate = useNavigate();

  const { BlockUI, UnBlockUI } = FnBlock_UI();
  const [whatsNews, setWhatsNews] = useState([] || undefined);
  const [news, setNews] = useState([] || undefined);
  const [course, setCourse] = useState([] || undefined);
  const [generalKnowledge, setGeneralKnowledge] = useState([] || undefined);
  const [banner, setBanner] = useState([] || undefined);
  const [siteCourse, setSiteMapCourse] = useState("");
  const [siteKnowledge, setSiteKnowledge] = useState("");
  const [siteNews, setSiteNews] = useState("");
  const getAuth =
    localStorage.getItem(process.env.REACT_APP_JWT_KEY as string) != null ? localStorage.getItem(process.env.REACT_APP_JWT_KEY as string) : undefined;

  useEffect(() => {
    secureLocalStorage.setItem("sTitleSeo","OR Academy ");
    document.title = "OR Academy ";
    secureLocalStorage.setItem("sDescSeo", "OR Academy สถาบันพัฒนาศักยภาพผู้นำและธุรกิจ");

    AOS.init();
    AOS.refresh();
    setData();
  }, [getAuth]);

  const setData = async () => {
    let resBanner = await loadBanner();
    let resWhatsNews = await loadWhatsNews();
    let resCourse = await loadCourseUpdate();
    let resGeneralKnowledge = await loadGeneralKnowledge();
    let resNews = await loadNews();
    let resSiteMap = await loadSiteMapContent();
    setBanner(resBanner);
    setNews(resNews);
    setWhatsNews(resWhatsNews);
    setCourse(resCourse);
    setGeneralKnowledge(resGeneralKnowledge);
    setNews(resNews);
    setSiteMapCourse(resSiteMap.sCourse);
    setSiteKnowledge(resSiteMap.sKnowledge);
    setSiteNews(resSiteMap.sNews);

  };

  const loadWhatsNews = async () => {
    let result = null as any;
    await Axios(
      `Home/GetWhatNawsList`,
      {},
      "Get",
      async (res) => {
        result = res;
      },
      null,
      BlockUI,
      UnBlockUI,
      () => { }
    );
    return result;
  };

  const loadCourseUpdate = async () => {
    let result = null as any;
    await Axios(
      `Home/GetCourseUpdateList`,
      "sMode=TOP",
      "Get",
      async (res) => {
        result = res;
      },
      null,
      BlockUI,
      UnBlockUI,
      () => { }
    );
    return result;
  };

  const loadGeneralKnowledge = async () => {
    let result = null as any;
    await Axios(
      `Home/GetGeneralKnowledgeList`,
      "sMode=top",
      "Get",
      async (res) => {
        result = res;
      },
      null,
      BlockUI,
      UnBlockUI,
      () => { }
    );
    return result;
  };

  const loadBanner = async () => {
    let result = null as any;
    await Axios(
      `Home/GetBannerList`,
      {},
      "Get",
      async (res) => {
        result = res;
        if (res.length === 0) {
          result.push({
            sPath: defaultBanner1,
            nType: 1,
          });
          result.push({
            sPath: defaultBanner2,
            nType: 1,
          });
          result.push({
            sPath: defaultBanner3,
            nType: 1,
          });
          result.push({
            sPath: defaultBanner4,
            nType: 1,
          });
        }
      },
      null,
      BlockUI,
      UnBlockUI,
      () => { }
    );
    return result;
  };

  const loadNews = async () => {
    let result = null as any;
    await Axios(
      `Home/GetNewsList`,
      "sMode=top",
      "Get",
      async (res) => {
        result = res;
      },
      null,
      BlockUI,
      UnBlockUI,
      () => { }
    );
    return result;
  };
  const loadSiteMapContent = async () => {
    let result = null as any;
    await Axios(
      `Home/GetSiteMapContent`,
      {},
      "Get",
      async (res) => {
        result = res;
      },
      null,
      BlockUI,
      UnBlockUI,
      () => { }
    );
    return result;
  };

  return (
    <>
      {/* <p style={{fontSize:100,textAlign:"center"}}>HOME</p> */}
      <Grid container columnSpacing={0} rowSpacing={0} sx={{ "&.MuiGrid-root": { marginTop: 0, display: "block" } }}>
        <Grid item md={12} xs={12}>
          <Banner dataSource={banner || []} page="Home" />
        </Grid>
        <Grid item md={12} xs={12} data-aos="fade-zoom-in" data-aos-duration="2000">
          <WhatsNewsHome dataSource={whatsNews || []} />
        </Grid>
        <Grid item md={12} xs={12}>
          <KnowledgeSharingHome dataSource={generalKnowledge || []} siteMap={siteKnowledge} />
        </Grid>
        {course.length > 0 &&
          <Grid item md={12} xs={12}>
            <CourseUpdateHome dataSource={course || []} siteMap={siteCourse} />
          </Grid>
        }
        <Grid item md={12} xs={12}>
          <NewsHome dataSource={news || []} siteMap={siteNews} courselength={course.length || 0} />
        </Grid>
      </Grid>
      <IconButton
        size="large"
        onClick={() => { navigate(process.env.REACT_APP_API_URL + `historyFAQ_list`); }}
        style={{
          position: "fixed",
          bottom: "0%",
          right: "0%",
          zIndex: 5,
          width: '50px',
          height: '50px',
          fontSize: '16px',
          border: '1px solid'
        }}
        sx={{
          backgroundColor: "#0075ff",
          color: "white",
          ":hover": { bgcolor: "#0057be" },
          margin: "1%",
        }}
      >
        FAQ
      </IconButton>
    </>
  );
};

export default home;
