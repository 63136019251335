import { Button, Grid, Box } from "@mui/material";
import Typography from "@mui/material/Typography";
import { useEffect, useState } from "react";
import { Axios } from "src/CommonFunction/TS_Axios";
import { FnBlock_UI } from "../BlockUI";
import logo from "../../Layout/img/logo-Profeil.png";
import { useNavigate } from "react-router";
import sharingContent from "../../Layout/img/panel-sharing-content.jpg";
import img1 from "../../Layout/img/panel-course-cover.jpg";
import wave from "../../Layout/img/wave-blue-internal-header.png";
import bg from "../../Layout/img/bg-digital.png";
import bgProfile from "../../Layout/img/bg-polygon-grey-horizon.png";
import "./SelectProfile.css"
const Profile = () => {
    const { BlockUI, UnBlockUI } = FnBlock_UI();
    const [Data, setData] = useState({} as any);
    const searchParams = location.search;
    const str = new URLSearchParams(searchParams).get('str');
    const navigate = useNavigate();
    const [sCompanyName, setCompanyName] = useState("");

    const GotoPage = (sRedirect) => {
        navigate(sRedirect);
    }
    useEffect(() => {
        OnGetData();
    }, []);

    const OnGetData = async () => {
        await Axios(
            `ProfilePage/GetData_Profile`, { str: str }, "POST", async (res) => {
                setCompanyName(res.sCompanyName);
                setData(res.lstProfile || []);
            },
            null,
            BlockUI,
            UnBlockUI,
            () => { }
        );
    };
    const onSubmit = async (item) => {
        let oData = {
            sUsername: item.sName,
            nUserID: item.nUserID,
            nUserTypeID: item.nUserTypeID,
            nProfileID: item.nProfileID,
        }
        console.log("Save", oData)
        await Axios(
            `AuthenLogin/LoginProfile`, oData, "POST", async (res) => {
                if (res.token) {
                    localStorage.setItem(process.env.REACT_APP_JWT_KEY as string, res.token);
                    GotoPage(process.env.REACT_APP_API_URL + res.sRedirect);
                    window.location.reload();
                    FnBlock_UI();
                }
            },
            null,
            BlockUI,
            UnBlockUI,
            () => { }
        );
    };
    return (
        <>
            <Box
                style={{
                    backgroundImage: `url(${bg})`,
                    minHeight: "100vh",
                    minWidth: "100vw",
                    backgroundPositionX: "center",
                    backgroundSize: "cover",
                    backgroundRepeat: "no-repeat",

                }}
            >
                <Grid container>
                    <Grid item xs={24} md={12}>

                        <Box>
                            <Typography
                                sx={{
                                    fontSize: {
                                        xs: "2em",
                                        lg: "2.5em",
                                    },
                                    lineHeight: 1,
                                    color: "rgba(255,255,255,0.9)",
                                    width: "100%",
                                    textAlign: "center",
                                    paddingTop: "0.6em",
                                    paddingLeft: "1.2em",
                                    paddingRight: "1.2em",
                                    wordBreak: "break-word",
                                    paddingBottom:"1em"

                                }}
                            >
                                {sCompanyName}
                            </Typography>
                        </Box>

                    </Grid>
                </Grid>

                <Grid container className="container">

                    {(Data ? (Data.length > 0 ? Data : []) : []).map(
                        (item) => (
                            <Grid item xs={12} sm={12} md={6} lg={6} sx={{ justifyContent: "center" }} className="divProfile">
                                {/* <Button variant="text" sx={{ width: "100%", height: "100%", justifyContent: "center" }} onClick={() => onSubmit(item)}>
                                    <Grid container sx={{ border: "groove", width: "90%", marginTop: "2%", backgroundColor: "#f2f2f2" }}>
                                        <Grid item xs={2} sx={{ marginTop: 1 }}>
                                            <img src={logo} style={{ width: 50, height: 50, marginLeft: 5 }} />
                                        </Grid>
                                        <Grid item xs={8} sx={{ marginTop: 1 }}>
                                            <Typography align="left" sx={{ fontSize: 17 }} color="#1769aa">{item.sPrefix} {item.sName}</Typography>
                                            <Typography align="left" sx={{ fontSize: 17 }} color="#2196f3">{item.sPosition}</Typography>
                                        </Grid>

                                    </Grid >
                                </Button> */}

                              
                                <Grid container sx={{ width: "100%", justifyContent: "center",padding:"0 10px 0 10px" }}>
                                    <a className="box-profile" onClick={() => onSubmit(item)}>

                                        <div className="box-profile-name">
                                            <Grid container>
                                                <Grid item xs={3} sm={3} sx={{ paddingLeft: 0 }}>
                                                    <img src={logo} style={{ width: 50, height: 50, marginLeft: 5 }} />
                                                </Grid>
                                                <Grid item xs={9} sm={9} >
                                                    <Typography align="left" sx={{ fontSize: "1.2em", fontWeight: "500",wordBreak:"break-word" }} color="#1769aa">{item.sPrefix} {item.sName}</Typography>
                                                    <Typography align="left" sx={{ fontSize: "1.2em", fontWeight: "500",wordBreak:"break-word"  }} color="#2196f3">{item.sPosition}</Typography>
                                                </Grid>
                                            </Grid>
                                        </div>


                                    </a>
                                  
                                </Grid>


                            </Grid>
                        )
                    )}

                </Grid >

            </Box>
        </>
    );
}
export default Profile;


