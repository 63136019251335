import { useEffect, useRef, useState } from "react";
import { FormProvider, useForm } from "react-hook-form";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import { Axios, AxiosGetAsync } from "src/CommonFunction/TS_Axios";
import { BreadcrumbsUI } from "src/Components/BreadcrumbsUI";
import { FnBlock_UI } from "src/Components/BlockUI";

export function MenuBar(props: navBar) {
  const [lstNevMenu, setMenu] = useState([] || undefined);
  const { BlockUI, UnBlockUI } = FnBlock_UI();
  const form = useForm({
    mode: "all",
  });
  // const GetMenuBar = async () => {
  //     let result = {} as any;
  //     await Axios(
  //         `Master/GetNevBarMenu`,
  //         { nMenuID: props.nMenuID, nContentID: props.nContentID },
  //         "Get",
  //         async (res) => {
  //             setMenu(res);
  //             console.log(lstNevMenu);
  //         },
  //         null,
  //         // BlockUI,
  //         // UnBlockUI,

  //         () => { }
  //     );
  //     return result;
  // };
  const GetData = async () => {
    let resNevBar = await GetMenuBar();
    setMenu(resNevBar);
  };
  const GetMenuBar = async () => {
    let result = {} as any;
    result = await AxiosGetAsync("Master/GetNavBarCMS", { sContentID: props.sContentID });
    return result;
    
  };
  useEffect(() => {
    GetData();
  }, [props.sContentID]);
  return (
    <>
      <FormProvider {...form}>
        <form>
          <Grid xs={24} md={12} item>

            <Grid xs={24} md={12} item>
              {props.sContentID == "" ? (
                <BreadcrumbsUI isShowHome={false} isShowBG={false} items={props.items} />
              ) : (
                <BreadcrumbsUI isShowHome={false} isShowBG={true} items={lstNevMenu} />
              )}
            </Grid>

          </Grid>
        </form>
      </FormProvider>
    </>
  );
}
interface navBar {

  sDataDetailID?: string;
  sContentID?: string;
  sMenuName?: string;
  items?: any;
}
