import * as React from 'react';
import Backdrop from '@mui/material/Backdrop';
import CircularProgress from '@mui/material/CircularProgress';
import { useSelector, useDispatch } from 'react-redux'
import { openBlockUI, closeBlockUI } from 'src/store/createSlice'
import "../Block/Block.css";
import book from "../../Layout/img/loader-book.gif";
const BlockUI = (props) => {

  const isOpent = useSelector((state: any) => state.counterSlice.open)

  return (
    <div>
      <Backdrop
        sx={{
          color: "#fff",
          zIndex: (theme) => theme.zIndex.drawer + 1,
          cursor: isOpent ? "progress" : "",
        }}
        open={isOpent}
      >
        {/* <CircularProgress color="inherit" /> */}
        <div className="block-ui-container">       
          <div className="bookshelf_wrapper">
            <ul className="books_list">
              <li className="book_item_1 first"></li>
              <li className="book_item_2 second"></li>
              <li className="book_item_3 third"></li>
              <li className="book_item_1 fourth"></li>
              <li className="book_item_2 fifth"></li>
              <li className="book_item_3 sixth"></li>
            </ul>
            <div className="shelf"></div>
            <div className="textBlock">NOW LOADING</div>
          </div>
        </div>

        {/* <div id="SITE_LOADING">
          <div className="bookshelf_wrapper">
            <div className="loader">
              <img src={book} width="100" className="loader-img" />
              <div className="loader-text">Loading...</div>
            </div>
          </div>
        </div> */}
      </Backdrop>
    </div>
  );
};

export const FnBlock_UI = () => {
  const Dispatch = useDispatch();
  const BlockUI = () => Dispatch(openBlockUI());
  const UnBlockUI = () => Dispatch(closeBlockUI());
  return { BlockUI, UnBlockUI };
};


export default BlockUI;
