import Typography from "@mui/material/Typography";
import Divider from "@mui/material/Divider";
import Grid from "@mui/material/Grid";
import "./profile.css";
import { BtnCustomText, BtnDetail, BtnHistory, BtnLeftWithOutText, BtnSeeDetail } from "../Button";
import Pagination from "@mui/material/Pagination";
import Box from "@mui/material/Box";
import { useEffect, useState } from "react";
import { usePagination } from "../PaginationUI/Pagination";
import defaultProfile from "src/Layout/img/def-instructor.jpg";
import { DialogUI } from "../Dialog";
import { VscBook } from "react-icons/vsc";
import { AiOutlineStar } from "react-icons/ai";
import { IoSchoolOutline } from "react-icons/io5";
import { TbAward } from "react-icons/tb";
import { IsNullOrUndefined, ParseHtml } from "src/CommonFunction/TS_function";
import { Tooltip, styled, tooltipClasses, TooltipProps, IconButton } from "@mui/material";
import Card from "@mui/material/Card";
import { FaQuestionCircle, FaTags } from "react-icons/fa";
import { AxiosPost, AxiosPostImage } from "src/CommonFunction/TS_Axios";
import { FnBlock_UI } from "src/Components/BlockUI";
export const ProfileUI = (props: ProfileUI) => {
  const { BlockUI, UnBlockUI } = FnBlock_UI();
  const { dataSource, ListData, nCount, setPage,page } = props;
  
  
  const PER_PAGE = 8;
  const count = Math.ceil(nCount / PER_PAGE);
  const _DATA = usePagination(dataSource || [], PER_PAGE);
  const NoMaxWidthTooltip = styled(({ className, ...props }: TooltipProps) => (
    <Tooltip {...props} classes={{ popper: className }} />
  ))({
    [`& .${tooltipClasses.tooltip}`]: {
      maxWidth: 'none',
    },
  });
  const handleChange = (e, p) => {
    setPage(p);
    _DATA.jump(p);
    // window[`scrollTo`]({ top: 250, behavior: `smooth` });
  };
 
//   useEffect(() => {
//     let nCountPage = (nCount) / 10;
//     let nPageNews = Math.ceil(nCountPage);
// 
   
//     setPage(nPageNews);
//   }, [nCount]);

  useEffect(() => {
    handleChange(null, page);
  }, [dataSource]);

  const ToggleHistory = ({ lstAchievement }) => {
    const [toggleThisElement, setToggleThisElement] = useState(false);
    const [isOpenAchievement, setOpenAchievement] = useState(false);

    const onClickViewAll = () => {
      setOpenAchievement(true);
    }

    const onCloseAchievement = () => {
      setOpenAchievement(false);
    }

    return (
      <>
        <DialogUI
          open={isOpenAchievement}
          textHead={"ประวัติการสอน"}
          fullWidth
          maxWidth="sm"
          hBGColor="#1976d2"
          hColor="#FFFFFF"
          handleClose={onCloseAchievement}
          handleClickCancel={onCloseAchievement}
        >

          <Grid container sx={{ m: "1em" }} >
            {lstAchievement.map((m, i) => (
              <>
                <Grid item xs={12} sx={{ m: "0.4em", display: "flex" }} >
                  {/* <Grid item xs={0.5} sx={{ display: "flex", alignItems: "flex-start", justifyContent: "center" }}>
                    <VscBook size={20} />
                  </Grid>
                  <Grid item xs={1.5} >
                    <Typography sx={{ display: "flex", alignItems: "center", justifyContent: "center" }}>
                      {m.sYear}
                    </Typography>
                  </Grid>
                  <Grid item xs={10} >
                    <Typography sx={{ display: "flex", alignItems: "center" }}>
                      {m.sName}
                    </Typography>
                  </Grid> */}
                  <table style={{ width: "100%" }}>
                    <tbody>
                      <tr>
                        <td style={{ width: "20%", textAlign: "center", verticalAlign: "top" }}>
                          {m.sYear}
                        </td>
                        <td style={{ width: "78%", textAlign: "left", verticalAlign: "top" }}>
                          {m.sName}
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </Grid>
              </>
            ))}
          </Grid>
        </DialogUI>

        <BtnHistory
          txt="ประวัติการสอน"
          onClick={() => setToggleThisElement((prev) => !prev)}
          isExpand={toggleThisElement}
          isShowExpand
          fullWidth
        />
        {/* </Divider> */}
        {toggleThisElement &&
          <>
            {lstAchievement.length > 0 ?

              <div className="profile-bio">
                {lstAchievement.map((m, i) => (
                  <>
                    {i < 3 &&
                      <>
                        <Typography sx={{ mt: "8px", mx: "10px", fontWeight: "bold" }}>{m.sYear}</Typography>
                        <Typography sx={{ mx: "10px" }}>{m.sName}</Typography>
                      </>
                    }
                  </>
                ))}
                {lstAchievement.length > 3 &&
                  // <Typography
                  //   sx={{
                  //     mx: "20px", my: "10px",
                  //     fontWeight: "bold",
                  //     display: "flex",
                  //     justifyContent: "flex-end",
                  //     cursor: "pointer",
                  //   }}
                  //   onClick={onClickViewAll}>
                  //   View All
                  // </Typography>
                  <Grid xs={12} item sx={{ display: "flex", flexDirection: { xs: "column", sm: "row" }, justifyContent: "center" }}>
                    <Grid item sx={{ textAlign: "center", maxWidth: "85%", minWidth: "85%", alignSelf: "center" }}>
                      <BtnCustomText txt="View All" backgroundColor="#0b286c" color="#fff" mx="8px 0 8px 0 !important"
                        onClick={() => { onClickViewAll() }}
                      />
                    </Grid></Grid>
                }
              </div>
              :
              <>
                <div className="profile-bio">
                  <Typography sx={{
                    mx: "10px",
                    fontWeight: "bold",
                    height: "150px",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                  }}>
                    ไม่มีข้อมูล
                  </Typography>
                </div>
              </>
            }
          </>
        }
      </>
    );
  };

  const ToggleExternal = ({ cType, sName, sEducation, sExperience, sExpertice, sURLImage, sWord }) => {
    const [isOpenExternal, setOpenExternal] = useState(false);
    const onClickExternal = () => {
      setOpenExternal(true);
    }
    let NewsExpertice = "";
    if (sExpertice !== null) {
      NewsExpertice = sExpertice.replace("\n", "<br/>");
    }

    const onCloseExternal = () => {
      setOpenExternal(false);
    }

    return (
      <>
        <DialogUI
          open={isOpenExternal}
          textHead={"วิทยากรภายนอก"}
          fullWidth
          maxWidth="sm"
          hBGColor="#58D68D"
          hColor="#FFFFFF"
          handleClose={onCloseExternal}
          handleClickCancel={onCloseExternal}
        >
          <Grid container>
            <Grid container xs={12} sx={{ display: "flex" }}>
              <Grid item xs={12} sm={12} md={9} lg={9}>
                <Typography sx={{ fontSize: "1.5em" }}>{sName}</Typography>
                <Typography sx={{ fontSize: "1.4em", color: "rgba(0,0,0,0.5)" }}>วิทยากรภายนอก</Typography>

              </Grid>
              <Grid item xs={12} sm={12} md={3} lg={3} style={{ textAlign: "center" }}>
                <img
                  style={{ width: "150px", height: "160px" }}
                  src={sURLImage || defaultProfile}
                  onError={({ currentTarget }) => {
                    currentTarget.onerror = null;
                    currentTarget.src = defaultProfile;
                  }} />
              </Grid>
            </Grid>
            <Grid item xs={12}>
              <Divider />
            </Grid>
            <Grid item xs={12}>
              <Typography sx={{ fontSize: "1.3em", display: "flex", alignItems: "center", mt: "1em" }}><IoSchoolOutline size={25} />&nbsp;การศึกษา</Typography>
              <Typography sx={{ fontSize: "1em", m: "1em" }}>{ParseHtml(sEducation)}</Typography>
              <Divider />
            </Grid>
            <Grid item xs={12}>
              <Typography sx={{ fontSize: "1.3em", display: "flex", alignItems: "center", mt: "1em" }}><AiOutlineStar size={25} />&nbsp;ประสบการณ์</Typography>
              <Typography sx={{ fontSize: "1em", m: "1em" }}>{ParseHtml(sExperience)}</Typography>
              <Divider />
            </Grid>
            <Grid item xs={12}>
              <Typography sx={{ fontSize: "1.3em", display: "flex", alignItems: "center", mt: "1em" }}><TbAward size={25} />&nbsp;ความเชี่ยวชาญ</Typography>
              <Typography sx={{ fontSize: "1em", m: "1em" }}>{ParseHtml(NewsExpertice)}</Typography>
            </Grid>
          </Grid>
        </DialogUI>
        {cType === "O" &&
          <BtnCustomText txt="รายละเอียด" backgroundColor="#0b286c" mx={"0"} maxWidth={"50%"}
            onClick={() => { onClickExternal() }}
          />
        }
        {(cType === "I" && !IsNullOrUndefined(sWord)) &&
          <NoMaxWidthTooltip title={sWord}>
            <IconButton sx={{ color: "#3997df", cursor: "unset !important" }} disableRipple={true} >
              <FaTags />
            </IconButton>
          </NoMaxWidthTooltip>
        }
      </>
    )
  }

 
  const ExportImage = async (url, sID) => {
    var oData = {
      sUrl: url,
    }
    await AxiosPostImage(`InstructorPage/PrintImage`, oData, (res) => {
      let imageUrl = res;
      let Img = document.getElementById(sID) as any;
    
      Img.src = imageUrl;
      
      return imageUrl;
    }, null, BlockUI, UnBlockUI)
  };
  return (
    <>
      <Box
        sx={{
          justifyContent: "center",
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
        }}
      >

        <Grid container >
          {/* <div ref={oFormRef}> */}
          {(_DATA.currentData()).map((m) => {

             let sID = "imgProfile_" + m.nID;
             let byteImg = m.cType == "I" ? ExportImage(m.sURLImage,sID) : null;

            
            return (
              <>
                <Grid item xs={12} sm={6} md={6} lg={3} sx={{ display: "flex", justifyContent: "center", paddingTop: "4em" }}>
                  <Card sx={{ maxWidth: 350, width: 350, height: "100%", minHeight: 50, boxShadow: "none", background: "transparent", overflow: "hidden", position: "relative" }}>
                    <Grid style={{ width: "100%", height: "100%" }}>
                      <div style={{ textAlign: "center" }}>
                        <img
                          id={sID}
                          className="imgProfile"
                          src={m.cType == "I" ? byteImg : (m.sURLImage !== "" ? m.sURLImage : defaultProfile)}
                         // src={(m.sURLImage !== "" ? m.sURLImage : defaultProfile)}
                          onError={({ currentTarget }) => {
                            currentTarget.onerror = null;
                            currentTarget.src = defaultProfile;
                          }}
                          style={{

                            aspectRatio: "1/1",
                            maxWidth: "70%",
                            maxHeight: "70%",
                            width: "100%",
                            borderRadius: "50%",
                            boxShadow: "0 0 0.5em 0 rgba(0, 0, 0, 0.25)",
                            marginTop: "0.5em"
                          }}
                        />
                        <div className="profile-card">

                          <header>

                            <h1>
                              {m.sName}
                            </h1>
                            <h2>{m.sOrganization || "-"}</h2>
                            <Divider sx={{ fontSize: 20 }} />
                            <h2 style={{ color: "#043fb6", display: "flex", alignItems: "center", justifyContent: "space-between", height: "50px" }}>
                              {m.sTypeName || "-"}
                              <ToggleExternal
                                cType={m.cType}
                                sName={m.sName}
                                sEducation={m.sEducation}
                                sExperience={m.sExperience}
                                sExpertice={m.sExpertice}
                                sURLImage={m.sURLImage}
                                sWord={m.sWord}
                              />
                            </h2>
                          </header>
                          <ToggleHistory lstAchievement={m.lstAchievement || []} />
                        </div>
                      </div>



                    </Grid>
                  </Card>






                </Grid >
              </>
            )

          })}
          {/* </div> */}
        </Grid>

        <Grid sx={{ m: { xs: "2em 0em 3em 0em", lg: "5em 2em" }, }}>
          <Pagination
            count={count}
            page={page}
            variant="outlined"
            shape="rounded"
            showFirstButton
            showLastButton
            onChange={handleChange}
          />
        </Grid>
      </Box>
    </>
  );
};

interface ProfileUI {
  dataSource?: any;
  ListData?: any;
  nCount?: any;
  setPage?: any;
  page?: any;
}
