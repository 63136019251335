//React
import React, { useEffect, useState } from "react";
import logo from "src/Layout/img/oba-logo.png";
//other - Mui
import { useNavigate } from "react-router";
import { AlertMsg, AlertTitle, DialogConfirm, DialogConfirmDelete, SwAlert } from "src/Components/Alert/Sweetalert";
import { FnBlock_UI, ResultAPI } from "src/CommonFunction/TS_function";
import { Box, Button, Grid, styled, Typography } from "@mui/material";
import { GridColumns } from '@mui/x-data-grid';
import bgMain from "src/Layout/img/bg-login.jpg";

import { Axios, AxiosPost } from "src/CommonFunction/TS_Axios";
//component Input
import InputNumberForm from "src/Components/FormItem/InputNumber";
import Paper from '@mui/material/Paper';
import useMediaQuery from '@mui/material/useMediaQuery';

//Button

//Yup
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { FormProvider, useForm } from "react-hook-form";
import yupFormSchemas from "src/Components/FormItem/yup/yupFormSchemas";
import { BreadcrumbsUI } from "src/Components/BreadcrumbsUI";
import { i18n } from "src/i18n";
import { Process_System } from "src/CommonFunction/TS_function";
import {
    BtnSearch,
    BtnLogin,
    BtnDownload,
    BtnSingUp

} from "src/Components/Button";
import { InputForm } from "src/Components/FormItem/Input";

const ContainerBG = styled("div")({
    backgroundImage: `url(${bgMain})`,
    backgroundPositionX: "center",
    backgroundPosittionY: "center",
    backgroundSize: "cover",
    backgroundRepeat: "no-repeat",
    backgroundAttachment: "fixed",
    width: '100vw',
    height: '88vh',
    position: 'relative'
});

const i18nField = 'entities.authen.fields';
const Controller = 'AuthenLogin';

const LoginBypass = () => {

    const { BlockUI, UnBlockUI } = FnBlock_UI();
    const navigate = useNavigate();
    const matches = useMediaQuery('(min-width:600px)');

    const objSchema = {
        sUsername: yupFormSchemas.string(i18n(`${i18nField}.sUsername`), { required: true }),
        sPassword: yupFormSchemas.string(i18n(`${i18nField}.sPassword`), { required: true }),

    };

    const schema = yup.object().shape(objSchema);

    const form = useForm({
        resolver: yupResolver(schema),
        mode: "all",


    });

    const GotoPage = (sRedirect) => {
        navigate(sRedirect);
    }

    const onLogin = () => {
        var Params = {
            sUsername: form.getValues("sUsername") ?? "",
            sPassword: form.getValues("sPassword") ?? "",
        };
        AxiosPost(`${Controller}/LoginBypass`, Params, res => {
            ResultAPI(true, res, null, () => {
                if (res.Status === Process_System.process_Success) {
                    if (res.token) {
                        localStorage.setItem(process.env.REACT_APP_JWT_KEY as string, res.token);
                        GotoPage(process.env.REACT_APP_API_URL + res.sRedirect);
                        window.location.reload();
                        FnBlock_UI();
                    }
                    else if (res.sRedirect) {
                        GotoPage(process.env.REACT_APP_API_URL + res.sRedirect);
                        window.location.reload();
                    }
                }
                else {
                    SwAlert.Warning(AlertTitle.Warning, res.Message, () => { });
                }
            })
        }, null, BlockUI, UnBlockUI)
    };

    const onKeyDown = (e) => {
        if (e.ctrlKey && e.altKey && e.which === 81) {
            onLogin();
        }
    }

    return (
        <ContainerBG>
            <Paper elevation={3} style={{ width: matches ? '50vw' : '90vw', position: 'absolute', top: '20%', left: matches ? '24%' : '5%', backgroundColor: 'rgb(255 255 255 / 50%)', borderRadius: '12px', padding: '16px' }}>
                <FormProvider {...form}>
                    <form onSubmit={form.handleSubmit(onLogin)}>
                        <Grid container direction={'row'} justifyContent={'center'} >
                            <Grid item xs={12} style={{ textAlign: "center", margin: '18px 0px' }}>
                                <Typography style={{ color: '#122768', fontWeight: 600, fontSize: matches ? '2rem' : '1.5rem' }}>ทางลัดผู้ใช้งาน</Typography>
                            </Grid>
                            <Grid item xs={12} >
                                <hr style={{ margin: '0' }} />
                            </Grid>
                            <Grid item xs={12} >
                                <Box style={{ textAlign: "center" }}>
                                    <InputForm
                                        name="sUsername"
                                        label={i18n(`${i18nField}.sUsername`)}
                                        required={true}
                                        fullWidth
                                        styles={{ width: matches ? "90%" : "93%" }}
                                        onKeyDown={onKeyDown}
                                    />
                                </Box>
                            </Grid>
                            <Grid item xs={12} >
                                <Box style={{ textAlign: "center" }}>
                                    <InputForm
                                        name="sPassword"
                                        label={i18n(`${i18nField}.sPassword`)}
                                        required={true}
                                        fullWidth
                                        styles={{ width: matches ? "90%" : "93%" }}
                                        isPassword={true}
                                        onKeyDown={onKeyDown}
                                    />
                                </Box>
                            </Grid>
                            <Grid item xs={12} >
                                <hr style={{ marginBottom: '10px' }} />
                            </Grid>
                            <Grid item xs={8} md={5}>
                                <BtnLogin  
                                   sName ="btnLogin"
                                    txt="เข้าสู่ระบบ/Login"
                                    onClick={() => { form.handleSubmit(onLogin)() }}
                                />
                            </Grid>
                        </Grid>
                    </form>
                </FormProvider>
            </Paper>


        </ContainerBG>
    )
}

export default LoginBypass