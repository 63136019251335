import { yupResolver } from "@hookform/resolvers/yup";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router";
import { FnBlock_UI } from "../BlockUI";
import * as yup from "yup";
import { Axios } from "src/CommonFunction/TS_Axios";
import { FormProvider, useForm } from "react-hook-form";
import { Box, Grid } from "@mui/material";
import { BreadcrumbsUI } from "../BreadcrumbsUI";
import { DataGridMui, initRows, PaginationInterface } from "../Table/DataGridMui";
import { GridColumns } from '@mui/x-data-grid';
import { BtnAddOnTable, BtnCustomSearch, BtnEditOnTable, BtnSearch, BtnCustomText } from "../Button";
import { Process_System, ResultAPI, lnkToHomePage } from "src/CommonFunction/TS_function";
import { AlertMsg, AlertTitle, DialogConfirmDelete, SwAlert } from "src/Components/Alert/Sweetalert";
import { InputForm } from "../FormItem/Input";
import AutoCompleteSelect from "../FormItem/AutoCompleteSelect";
import { i18n } from "src/i18n";

const ManageStaffTable = () => {
    const navigate = useNavigate();
    const { BlockUI, UnBlockUI } = FnBlock_UI();
    const [loadingTable, setLoadingTable] = useState(true);
    const searchParams = location.search;

    const [dataRow, setDataRow] = useState<PaginationInterface>({
        ...initRows,
        sSortExpression: "sPersonalID",
        sSortDirection: "asc"
    });

    const objSchema = {};
    const schema = yup.object().shape(objSchema);

    const form = useForm({
        resolver: yupResolver(schema),
        shouldUnregister: false,
        shouldFocusError: true,
        mode: "all"
    });

    const arrOptionsAction = [
        { "value": "Y", "label": "ใช้งาน" },
        { "value": "N", "label": "ไม่ใช้งาน" },
    ]

    useEffect(() => {
        getUserData()
        loadData(dataRow);
    }, [])

    const getUserData = () => {
        Axios("Master/onPageLoad", {}, "GET", async (result) => {

            if (result.IsExpired) {
                SwAlert.Warning(undefined, i18n("common.SessionTimeOut"), lnkToHomePage);
            }
        })

    }

    const loadData = async (p: PaginationInterface = dataRow) => {
        setLoadingTable(true)
        var param = {
            ...p,
            sNameSearch: form.getValues("sNameSearch") ? form.getValues("sNameSearch") : null,
            sStatusSearch: form.getValues("sStatusSearch") ? form.getValues("sStatusSearch").value : null
        }
        await Axios("StaffPage/GetData_MangeStaffList", param, "POST", async (res) => {
            setLoadingTable(false)
            setDataRow({
                ...p,
                arrRows: res.lstMangeStaff,
                nDataLength: res.nDataLength === 0 ? res.nDataLength : (res.nDataLength > 10 ? res.nDataLength : 10),
                nPageIndex: res.nPageIndex || 1,
            });
        }, null, BlockUI, UnBlockUI, null)
    };

    const goToEdit = (sID: string, sUserTypeID: string) => {
        // window.localStorage.setItem("sSubRouteName", item.sSection);
        navigate(process.env.REACT_APP_API_URL + `ManageStaffForm?sID=${encodeURI(sID)}&sUserTypeID=${sUserTypeID}`);
    };

    const OnDelete = async (e) => {
        DialogConfirmDelete(() => {
            Axios("StaffPage/DeleteData_MangeStaffDetail", { lstRemoveData: e }, "POST", async (res) => {
                ResultAPI(true, res, null, () => {
                    if (res.Status === Process_System.process_Success) {
                        SwAlert.Success(AlertTitle.Success, AlertMsg.DeleteComplete, () => {
                            loadData(dataRow);
                        });
                    }
                    else {
                        SwAlert.Warning(AlertTitle.Warning, res.Message, () => { });
                    }
                })
            }, null, BlockUI, UnBlockUI, null)
        })

    };

    const dataColumn: GridColumns = [
        {
            field: "nNo",
            headerName: "ที่",
            headerAlign: "center",
            editable: false,
            width: 80,
            resizable: true,
            sortable: false,
            align: "center",
        },
        {
            field: "sPersonalID",
            headerName: "เลขประจำตัว",
            headerAlign: "center",
            align: "left",
            resizable: true,
            sortable: true,
            flex: 4,
            minWidth: 200,
        },
        {
            field: "sName",
            headerName: "ชื่อ - นามสกุล",
            headerAlign: "center",
            align: "left",
            resizable: true,
            sortable: true,
            flex: 4,
            minWidth: 200,
        },
        {
            field: "sUserName",
            headerName: "ชื่อผู้ใช้งาน",
            headerAlign: "center",
            align: "left",
            resizable: true,
            sortable: true,
            flex: 4,
            minWidth: 200,
        },
        {
            field: "sPosition",
            headerName: "ตำแหน่ง",
            headerAlign: "center",
            align: "left",
            resizable: true,
            sortable: true,
            flex: 4,
            minWidth: 200,
        },
        {
            field: "sEmail",
            headerName: "E-mail",
            headerAlign: "center",
            align: "left",
            resizable: true,
            sortable: true,
            flex: 4,
            minWidth: 200,
        },
        {
            field: "sStatus",
            headerName: "สถานะ",
            headerAlign: "center",
            align: "center",
            resizable: true,
            sortable: true,
            flex: 4,
            minWidth: 200,
            renderCell: (item) => {
                var txt = item.row.sStatus == "Y" ? "ใช้งาน" : "ไม่ใช้งาน"
                return (
                    <div>{txt}</div>
                );
            }
        },
        {
            renderHeader: () => <BtnAddOnTable
                onClick={() => { navigate(process.env.REACT_APP_API_URL + `ManageStaffForm`) }}
                txt={"Add"}
            />,
            headerName: "Button (Add/Edit)",
            field: "Button (Add/Edit)",
            type: "actions",
            width: 100,
            resizable: false,
            sortable: false,
            getActions: (item) => {
                return [
                    (
                        <BtnEditOnTable
                            onClick={() => goToEdit(item.row.sID, item.row.sUserTypeID)}
                        />
                    )
                ];
            },
        },
    ];

    return (
        <Grid container>
            <FormProvider {...form}>

                <Grid item xs={12}>
                    <BreadcrumbsUI
                        isShowHome={false}
                        items={[
                            {
                                menuName: "ข้อมูลพนักงาน/Staff",
                                path: "ManageStaffTable",
                                IsOnClick: false
                            },

                        ]}
                    />
                </Grid>

                {/* <Grid item container spacing={2} xs={12} flex={1} marginX={2} justifyContent="center" sx={{ marginTop: 5 }}>
                    <Grid item xs={12} md={12} container spacing={2} direction="row" justifyContent="right" alignItems="center">                     
                            <Grid item xs={12} md={4}>
                                <InputForm
                                    name="sNameSearch"
                                    label="ค้นหาจาก ชื่อผู้ใช้งาน, ชื่อ-นามสกุล, E-mail, ตำแหน่ง"
                                    fullWidth
                                />
                            </Grid>
                            <Grid item xs={12} md={2}>
                                <AutoCompleteSelect
                                    name="sStatusSearch"
                                    label="สถานะ"
                                    options={arrOptionsAction}
                                    width={{ width: "100%" }}
                                />
                            </Grid>
                            <Grid item>
                                <BtnCustomSearch txt="ค้นหา/Search" onClick={() => loadData()} />
                               
                            </Grid>
                        
                    </Grid>
                    <Grid item xs={12} >
                        <Box sx={{ width: "100%" }}>
                            <DataGridMui
                                isShowCheckBox
                                isLoading={loadingTable}
                                Columns={dataColumn}
                                onDelete={OnDelete}
                                OnLoadData={(p: PaginationInterface) => {
                                    loadData(p)
                                }}
                                Rows={dataRow}
                                HiddenToolHead={true}
                                sxCustomHeader={{
                                    color: "#fff !important"

                                }}
                                backgroundHeaderCustom={"#122768"}
                            />
                        </Box>
                    </Grid>
                    <Grid item xs={12} sx={{ marginTop: 5 }}>
                    </Grid>
                </Grid> */}

                <Grid container flexWrap={"wrap"} sx={{  marginTop: 5 }}>
                    <Grid item xs={12} sx={{ paddingRight: "1em",mb: "1em", display: "flex", justifyContent: "flex-end", flexDirection: { xs: "column", lg: "row" } }}>
                        <Grid item xs={12} md={4} sx={{ px: { lg: "0.5em" }, maxWidth: "100% !important" }}>
                            <InputForm
                                name="sNameSearch"
                                label="ค้นหาจาก ชื่อผู้ใช้งาน, ชื่อ-นามสกุล, E-mail, ตำแหน่ง"
                                fullWidth
                            />
                        </Grid>
                        <Grid item xs={12} md={4} sx={{ px: { lg: "0.5em" }, maxWidth: "100% !important" }}>
                            <AutoCompleteSelect
                                name="sStatusSearch"
                                label="สถานะ"
                                options={arrOptionsAction}
                                width={{ width: "100%" }}
                            />
                        </Grid>


                        <Grid item sx={{ display: "flex", justifyContent: "center", paddingLeft: "8px" }}>
                            <BtnCustomText txt="ค้นหา/Search" onClick={() => loadData()} />
                        </Grid>
                    </Grid>

                    <Grid item xs={12}>
                        <Grid item xs={12} flex={1} marginX={2} justifyContent="center" >
                            <Box sx={{ width: "100%" }}>
                                <DataGridMui
                                    isShowCheckBox
                                    isLoading={loadingTable}
                                    Columns={dataColumn}
                                    onDelete={OnDelete}
                                    OnLoadData={(p: PaginationInterface) => {
                                        loadData(p)
                                    }}
                                    Rows={dataRow}
                                    HiddenToolHead={true}
                                    sxCustomHeader={{
                                        color: "#fff !important"

                                    }}
                                    backgroundHeaderCustom={"#122768"}
                                />
                            </Box>
                        </Grid>
                    </Grid>
                </Grid>


            </FormProvider >
        </Grid >
    );
}
export default ManageStaffTable