import { Grid, Typography } from '@mui/material';
import React from 'react';
import { useEffect, useState } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { Axios } from 'src/CommonFunction/TS_Axios';
import { FnBlock_UI } from 'src/Components/BlockUI';
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { InputForm } from 'src/Components/FormItem/Input';
import { SelectForm } from 'src/Components/FormItem/Select';
import { BtnCustomSearch, BtnCustomText, BtnEditFilmRed } from 'src/Components/Button';
import Pagination from "@mui/material/Pagination";
import { Margin } from '@mui/icons-material';
import { GridColumns } from "@mui/x-data-grid-pro";
import Box from "@mui/material/Box";
import { DataGridMui as Table, initRows, PaginationInterface } from "src/Components/Table/DataGridMui";
import { fromUnixTime } from 'date-fns';
import AutoCompleteSelect from "src/Components/FormItem/AutoCompleteSelect";
import { useNavigate } from "react-router-dom";


const HistoryLearning = () => {
  const [lstData, setData] = useState<any[]>([]);
  const { BlockUI, UnBlockUI } = FnBlock_UI();
  const [lstCategory, setlstCategory] = useState([]);
  const navigate = useNavigate();

  const objSchema = {};
  const schema = yup.object().shape(objSchema);
  const form = useForm({
    mode: "all",
    resolver: yupResolver(schema),
    shouldFocusError: true,
  });

  const [dataRow, setDataRow] = useState<PaginationInterface>({
    ...initRows,
  });

  const routeChange = (row) => {
    navigate(process.env.REACT_APP_API_URL + "OnlineDetail?sID=" + row.ssID + "&c=" + row.sCat + "&str=" + row.sStr);
  };

  const dataColumn: GridColumns = [

    {
      field: "nRow",
      headerName: "ที่",
      headerAlign: "center",
      align: "center",
      sortable: false,
      minWidth: 5,
    },
    {
      field: "sTopicName",
      headerName: "หลักสูตร",
      headerAlign: "center",
      align: "left",
      sortable: true,
      flex:2,
      minWidth: 300,
      renderCell: (item) => {
        return (
          <>
            <Typography component={"label"} onClick={() => routeChange(item.row)} style={{ color: "#228dbd", textDecoration: "underline", cursor: "pointer" }}>
              {item.row.sTopicName}
            </Typography>
          </>
        );
      },
    },
    {
      field: "finLearning",
      headerName: "สถานะการเรียน",
      headerAlign: "center",
      align: "center",
      sortable: true,
      minWidth: 180,
      flex:2,
      renderCell: (item) => {
        return (
          <>
            <Typography component={"label"} style={{ color: item.row.finLearning == "ยังไม่จบหลักสูตร" ? "red" : "back", cursor: "pointer" }}>
              {item.row.finLearning}
            </Typography>
          </>
        );
      },
    },
    {
      field: "sfinEvaluation",
      headerName: "แบบประเมิน",
      headerAlign: "center",
      align: "center",
      sortable: true,
      minWidth: 200,
      type: "actions",
      getActions: (item) => {
        return item.row.sfinEvaluation == "ยังไม่ได้ทำแบบประเมิน" ?
          [
            <div >
              <Typography component={"label"} onClick={() => routeChange(item.row)} style={{ color: "red", textDecoration: "underline", cursor: "pointer", marginRight: "10px" }}>
                {"ยังไม่ทำแบบประเมิน"}
              </Typography>
              <BtnEditFilmRed txt="ทำแบบประเมิน" onClick={() => routeChange(item.row)} />
            </div>
          ]
          : [<div> ทำแบบประเมินแล้ว </div>]
      },
    },
  ];

  useEffect(() => {
    GetHistoryLearning(dataRow)
  }, []);

  const GetHistoryLearning = async (p: PaginationInterface) => {

    var param = {
      nPageSize: p.nPageSize || 10,
      nPageIndex: p.nPageIndex || 1,
      sSortExpression: p.sSortExpression || "",
      sSortDirection: p.sSortDirection.toUpperCase() || "",
      sCode: "",
      sCoursename: form.getValues("sSearch") || "",
      lstCategory: form.getValues("lstCategory") != undefined ? [form.getValues("lstCategory").value] : [],
    };
    let result = {} as any;
    await Axios(
      `HistoryPage/GetData_HistoryLearning`,
      param || {},
      "Post",

      async (res) => {
        res.lstLearnPage.forEach((e, index) => {
          e.nRow = index + 1;
        })

        result = res;
        setDataRow({
          ...p,
          arrRows: res.lstLearnPage,
          nDataLength: res.nDataLength === 0 ? res.nDataLength : (res.nDataLength > 10 ? res.nDataLength : 10),
          nPageIndex: res.nPageIndex,
        });
        setlstCategory(res.lstCategory);
      },
      null,
      BlockUI,
      UnBlockUI,
      null
    );
    return result;
  };

  return (
    <>
      <FormProvider {...form}>
        <form>
          <Grid container>
            <Grid item xs={12} sx={{ mb: "1em", display: "flex", justifyContent: "flex-end" ,flexDirection:{xs:"column",lg:"row"}}} >
              <Grid item xs={12} lg={3} sx={{px: { lg: "0.5em"}}}>
                <InputForm
                  name="sSearch"
                  placeholder="ค้นหาจากรหัส,ชื่อหลักสูตร"
                  showClear
                  fullWidth
                  mx={0}
                />
              </Grid>
              <Grid item xs={12} lg={3} sx={{px: { lg: "0.5em"}}}>
                <AutoCompleteSelect
                  required
                  name="lstCategory"
                  label={" - All Category "}
                  width={{ width: "100%" }}
                  options={lstCategory}
                  IsShowIcon={true}
                  onChange={(v) => {
                    form.setValue('lstCategory', v ? v : null)
                  }}
                  mx={0}
                />
              </Grid>
              <Grid item xs={12} lg={1} sx={{justifyContent: "center" }}>
                <BtnCustomText txt="ค้นหา/Search" onClick={() => { GetHistoryLearning(dataRow) }} />
              </Grid>
            </Grid>
            <Grid item xs={12} sm={12}>
              <Table
                HiddenToolHead
                maxRowNoScroll ={50}
                Rows={dataRow}
                Columns={dataColumn}
                OnLoadData={(p) => GetHistoryLearning(p)}
                backgroundHeaderCustom={"#0a286c"}
                sxCustomHeader={{ color: "#FFF" }}
                sxCustomTable={{
                  ".MuiDataGrid-cell": {
                      minHeight: "35px !important",
                      maxHeight: "35px !important"
                  },
                  ".MuiDataGrid-row": {
                      minHeight: "35px !important",
                      maxHeight: "35px !important"
                  },
              }}
              />
            </Grid>
          </Grid>
        </form>
      </FormProvider>

    </>
  )
}

export default HistoryLearning