//React
import React, { useEffect, useState } from "react";
//other - Mui
import { useNavigate } from "react-router";
import { DialogCustomUI } from "src/Components/Dialog/DialogCustomUI";
import {
  Box,
  Divider,
  Grid,
  Typography,
  DialogActions,
  DialogContent,
} from "@mui/material";
import { FnBlock_UI } from "src/Components/BlockUI";
import { BtnCancelForm, BtnOK } from "src/Components/Button";
import { IsNullOrUndefined } from "src/CommonFunction/TS_function";
import { FAQ_Table } from 'src/pages/TrainingHistory/DialogTable/FAQ_Table';
const DetailModal = (props) => {
  const { IsOpenModal, setIsOpenModal, DataDetail = null } = props;

  const navigate = useNavigate();
  const { BlockUI, UnBlockUI } = FnBlock_UI();

  const OnClear = async () => {
    setIsOpenModal(false);
  };

  const Cancel = async () => { };
  useEffect(() => { }, [IsOpenModal]);

  return (
    <DialogCustomUI
      open={IsOpenModal}
      textHead="รายละเอียด/Detail"
      fullWidth
      questionIcon
      handleClose={() => {
        setIsOpenModal(false);
      }}
      isSetHeadColor={true}
      titleBgColor={"#43a7f3"}
      titleColor={"#fff"}
      isShowPopupCancel={true}
    // elementAction={elementAction}
    >
      <DialogContent
        sx={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          m: "1em",
          paddingBottom: "0px",
        }}
      >
        <Grid
          container
          spacing={2}
          direction="row"
          justifyContent="center"
          alignItems="center"
        >
          <Grid item xs={12} md={12} style={{ padding: "0px" }}>
            <Divider />
          </Grid>
          <Grid item xs={12} md={12}>
            <Grid
              container
              spacing={4}
              direction="row"
              justifyContent="flex-start"
              alignItems="center"
            >
              <Grid item xs={12} md={12}>
                <Typography><b>ชื่อหลักสูตร / Course Name</b></Typography>
                <Typography style={{wordBreak:"break-word"}}>{DataDetail != null ? DataDetail["sCourseNameFull"] ?? "-" : "-"}</Typography>
              </Grid>
              <Grid item xs={12} md={12}>
                <Typography><b>คำถาม / Question</b></Typography>
                <Typography style={{wordBreak:"break-word"}}>{DataDetail != null ? DataDetail["sQuestion"] ?? "-" : "-"}</Typography>
                <>

                  {

                    ((DataDetail != null && DataDetail.lstFileQuestion) || []).length > 0 ?
                      (

                        <Box>
                          <FAQ_Table lstFile={DataDetail.lstFileQuestion}></FAQ_Table>
                        </Box>
                      )

                      :
                      <></>
                  }

                </>
              </Grid>
              <Grid item xs={12} md={12}>
                <Typography><b>คำตอบ / Answer</b></Typography>
                <Typography style={{wordBreak:"break-word"}}>{DataDetail != null ? DataDetail["sAnswer"] ?? "-" : "-"}</Typography>

                <>

                  {

                    ((DataDetail != null && DataDetail.lstFileAnswer) || []).length > 0 ?
                      (

                        <Box>
                          <FAQ_Table lstFile={DataDetail.lstFileAnswer}></FAQ_Table>
                        </Box>
                      )

                      :
                      <></>
                  }

                </>
              </Grid>
              <Grid item xs={12} md={6}>
                <Typography><b>ผู้ถาม / Question from</b></Typography>
                <Typography style={{wordBreak:"break-word"}}>{DataDetail != null ? DataDetail["sQuestionByName"] ?? "-" : "-"}</Typography>
              </Grid>
              <Grid item xs={12} md={6}>
                <Typography><b>วันที่ส่งคำถาม / Question date</b></Typography>
                <Typography style={{wordBreak:"break-word"}}>{DataDetail != null ? (!IsNullOrUndefined(DataDetail["sQuestionDate"]) && !IsNullOrUndefined(DataDetail["sQuestionTime"])) ? DataDetail["sQuestionDate"] + " " + DataDetail["sQuestionTime"] : "-" : "-"}</Typography>
              </Grid>
              <Grid item xs={12} md={6}>
                <Typography><b>ผู้ตอบคำถาม / Answer from</b></Typography>
                <Typography style={{wordBreak:"break-word"}}>{DataDetail != null ? DataDetail["sAnswerByName"] ?? "-" : "-"}</Typography>
              </Grid>
              <Grid item xs={12} md={6}>
                <Typography><b>วันที่ตอบคำถาม / Answer date</b></Typography>
                <Typography style={{wordBreak:"break-word"}}>{DataDetail != null ? (!IsNullOrUndefined(DataDetail["sAnswerDate"]) && !IsNullOrUndefined(DataDetail["sAnswerTime"])) ? (DataDetail["sAnswerDate"] ?? "") + " " + (DataDetail["sAnswerTime"] ?? "") : "-" : "-"}</Typography>
              </Grid>
              <Grid item xs={12} md={6}>
                <Typography><b>สถานะ / Status</b></Typography>
                <Typography style={{wordBreak:"break-word"}}>{DataDetail != null ? DataDetail["sStatusName"] ?? "-" : "-"}</Typography>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </DialogContent>
      <DialogActions sx={{ borderTop: "1px solid rgba(0,0,0,0.3)" }}>
        <Grid
          container
          spacing={1}
          direction="row"
          justifyContent="right"
          alignItems="center"
        >
          <Grid item>
            <BtnCancelForm txt={'ปิด / Close'} onClick={() => OnClear()} />
          </Grid>
        </Grid>
      </DialogActions>
    </DialogCustomUI>
  );
};

export default DetailModal;
