import React, { useState, useEffect } from "react";
import {
    LicenseInfo,
    DataGridPro,
    GridRowModel,
    GridOverlay,
    useGridApiRef,
    GridToolbarFilterButton,
    GridToolbarContainer,
    GridPanelWrapper,
    GridToolbarColumnsButton,
    GridRowId,
} from "@mui/x-data-grid-pro";
import {
    styled,
    Box,
    Pagination,
    Stack,
    Select,
    MenuItem,
    Typography,
    TextField,
    Autocomplete,
    Button,
    Checkbox,
    Tooltip,
} from "@mui/material";
import {
    ExpandMore,
    ExpandLess,
    Add,
    UnfoldMore,
    SaveAlt,
} from "@mui/icons-material";
import PropTypes from "prop-types";
import { BtnSearch, BtnCancel } from "src/Components/Button";
import { FormProvider, useForm } from "react-hook-form";
import AutoCompleteInTable from "src/Components/Table/AutoCompleteInTable";
import ClearIcon from "@mui/icons-material/Clear";
import DateRange from "src/Components/Table/DatepickerInTable";

LicenseInfo.setLicenseKey(
    "5b31a40e910ea0c2a35371ae57c5b5beT1JERVI6MzYzMjEsRVhQSVJZPTE2NzQyOTE1NTAwMDAsS0VZVkVSU0lPTj0x"
);

export interface PaginationInterface {
    nPageIndex: number;
    nPageSize: number;
    arrRows: GridRowModel[];
    nDataLength: number;
    sSortExpression: string;
    sSortDirection: string;
    sID: string;
}

export interface FilterFieldInterface {
    sFieldName: string;
    sLabel?: string;
    sInputMode?:
    | "text"
    | "numeric"
    | "none"
    | "search"
    | "email"
    | "decimal"
    | "tel"
    | "url";
    sTypeFilterMode: "input" | "select" | "multiselect" | "daterange";
    sType?: string;
    lstDataSelect?: OptionSelect[];
}
interface OptionSelect {
    value: any;
    label: any;
    keyId: any;
}

export const initRows: PaginationInterface = {
    nPageIndex: 1,
    nPageSize: 10,
    // rows: [],
    arrRows: [],
    nDataLength: 0,
    sSortExpression: "",
    sSortDirection: "",
    sID: "",
};

const StyledGridOverlay = styled(GridOverlay)(({ theme }) => ({
    flexDirection: "column",
}));
const GridToolbarClearFilterButton = styled(Button)(({ theme }) => ({
    fontSize: "0.8125rem",
    padding: "4px 5px",
}));

export function DataGridMui(props) {
    const {
        minHeightCustom,
        onCickRow,
        maxRowNoScroll,
        sxCustomHeader,
        sxCustomTable,
        backgroundHeaderCustom,
        lstPageSize,
        Rows,
        Columns,
        isLoading,
        OnLoadData,
        isShowCheckBox,
        isExpan,
        filterField,
        onDelete,
        disableColumnMenu,
        isNotShowTotal,
        isNotShowPagination,
        isCustomOnFilter,
        onFilterCustom,
        isRowSelectable,
        isExportExcel,
        onExportExcel,
        HiddenToolHead,
        HandleDataMode,
        ContentExpand,
        onExpand,
        nExpandRowLength,
        id,
        rowReordering,
        onRowOrderChange,
        defaultExpand
    } = props;
    const apiRef = useGridApiRef();
    let lstPageData: any = [];
    let lstPage: any = [];
    //console.log("isExpan",isExpan)
    const [lstSelectRowId, setLstSelectRowId] = useState([]);
    const [detailPanelExpandedRowIds, setDetailPanelExpandedRowIds] =
        React.useState<GridRowId[]>([]);
    const CustomNoRowsOverlay = () => {
        return (
            <StyledGridOverlay>
                <Box>No Data</Box>
            </StyledGridOverlay>
        );
    };

    const clearFilter = () => {
        let cloneData = {
            nPageSize: Rows.nPageSize,
            nPageIndex: 1,
            sSortExpression: "",
            sSortDirection: "",
            arrRows: [],
        };
        if (isCustomOnFilter) {
            onFilterCustom(cloneData, null);
        } else {
            OnLoadData(cloneData);
        }
    };
    const QuickSearchToolbar = (isFilter: boolean) => {
        return (
            <Stack
                className="head-container"
                sx={{
                    px: 0.5,
                    pb: 0.5,
                    bgcolor: "white",
                }}
                direction="row"
                justifyContent="start"
            >
                <GridToolbarContainer>
                    <Tooltip title={"Show columns"}>
                        <GridToolbarColumnsButton />
                    </Tooltip>
                    {isFilter ? (
                        // <GridToolbarFilterButton disableTouchListener={isLoading} />
                        <></>
                    ) : null}
                    {filterField &&
                        filterField.some(
                            (item) =>
                                Rows[item.sFieldName] ||
                                (Rows[item.sFieldName] == 0 && item.sTypeFilterMode == "select")
                        ) ? (
                        <Tooltip title={"Clear filters"}>
                            <GridToolbarClearFilterButton
                                disabled={isLoading}
                                onClick={() => clearFilter()}
                            >
                                <ClearIcon sx={{ marginRight: "0.2em" }} />
                                Clear Filter
                            </GridToolbarClearFilterButton>
                        </Tooltip>
                    ) : null}
                    {isExportExcel ? (
                        <Tooltip title={"Export excel"}>
                            <GridToolbarClearFilterButton
                                disabled={isLoading}
                                onClick={() => onExportExcel()}
                            >
                                <SaveAlt sx={{ marginRight: "0.2em" }} />
                                Export excel
                            </GridToolbarClearFilterButton>
                        </Tooltip>
                    ) : null}
                </GridToolbarContainer>
            </Stack>
        );
    };


    useEffect(() => {
        if (nExpandRowLength == null) {
            setDetailPanelExpandedRowIds([]);
        }
    }, [nExpandRowLength])

    // const handleDetailPanelExpandedRowIdsChange = React.useCallback(
    //     (newIds: GridRowId[]) => {
    //         let nLastIndex = newIds.length - 1;
    //         let nLastId = newIds[nLastIndex];
    //         setDetailPanelExpandedRowIds(nLastId ? [nLastId] : []);
    //         nLastId && onExpand(nLastId);
    //     },
    //     []
    // );

    const handleDetailPanelExpandedRowIdsChange = (newIds: GridRowId[]) => {
        let nLastIndex = newIds.length - 1;
        let nLastId = newIds[nLastIndex];
        console.log(newIds)
        
        setDetailPanelExpandedRowIds(nLastId ? [nLastId] : []);
        if (nLastId) {
            let objCourse = Rows.arrRows.find(f => f.sID + "" == nLastId)
            onExpand(objCourse)
        }
        else {
            onExpand(null)
        }
    }


    const CustomPagination = () => {
        const maxRowSizePerPage = Rows.nPageIndex * Rows.nPageSize;
        const rowTotalCount = Rows.nDataLength;
        const minRowSizePerPage =
            Rows.nPageIndex > 1 ? (Rows.nPageIndex - 1) * Rows.nPageSize + 1 : 1;
        const pageCount = Math.ceil(rowTotalCount / Rows.nPageSize);
        const sumMaxRow =
            rowTotalCount > maxRowSizePerPage ? maxRowSizePerPage : rowTotalCount;
        lstPageData = [];
        lstPage = [];
        for (let i = 0; i < pageCount; i++) {
            lstPageData.push({ label: i + 1, value: i });
            lstPage.push(<MenuItem value={i}>{i + 1}</MenuItem>);
        }
        return rowTotalCount < 10 && !isNotShowTotal ? (
            <Box sx={{ display: "block", width: "100%" }}>
                <Stack
                    direction="row"
                    justifyContent="space-between"
                    alignContent="center"
                    sx={{ px: 2 }}
                >
                    {lstSelectRowId.length > 0 && onDelete != undefined ? (
                        <BtnCancel
                            isCircleWithOutText
                            onClick={() => onDelete(lstSelectRowId)}
                        />
                    ) : (
                        <Box></Box>
                    )}
                    <Stack justifyContent="center">
                        <Typography></Typography>
                        {/* <Typography>Total : {rowTotalCount}</Typography> */}
                    </Stack>
                </Stack>
            </Box>
        ) : (
            !isNotShowPagination && (
                <Stack
                    direction="row"
                    sx={{ px: 2, minWidth: "550px" }}
                    justifyContent="space-between"
                    alignItems="center"
                    flex={1}
                    spacing={1}
                >
                    {lstSelectRowId.length > 0 && onDelete != undefined ? (
                        <BtnCancel
                            isCircleWithOutText
                            txt={"Remove"}
                            onClick={() => onDelete(lstSelectRowId)}
                        />
                    ) : (
                        <Box></Box>
                    )}
                    <Stack direction="row" alignItems="center" spacing={1}>
                        <Typography component={"span"}>Per page:</Typography>
                        <Select
                            label=""
                            size="small"
                            disabled={isLoading}
                            autoWidth
                            sx={{
                                height: 30,
                                ".MuiOutlinedInput-notchedOutline > legend": {
                                    width: 0,
                                },
                            }}
                            value={lstPageSize.indexOf(Rows.nPageSize)}
                            onChange={(e) => {
                                apiRef.current.setPageSize(lstPageSize[e.target.value]);
                            }}
                        >
                            {lstPageSize.map((item, index) => (
                                <MenuItem key={`selPageSize_${index}`} value={index}>
                                    {item}
                                </MenuItem>
                            ))}
                        </Select>
                        <Typography>{`${minRowSizePerPage > sumMaxRow ? sumMaxRow : minRowSizePerPage
                            } - ${sumMaxRow} of ${rowTotalCount}`}</Typography>
                        <Typography component={"span"}>Go to</Typography>
                        <AutoCompleteInTable
                            disabled={isLoading}
                            small
                            sxCustom={{
                                ".MuiOutlinedInput-root": {
                                    padding: "4.5px 14px !important",
                                    height: "30px",
                                    ".MuiOutlinedInput-notchedOutline > legend": {
                                        width: 0,
                                    },
                                },
                            }}
                            objValue={{ label: Rows.nPageIndex, value: Rows.nPageIndex - 1 }}
                            funcOnChange={(event, newValue) => {
                                let cloneData = {
                                    ...Rows,
                                    nPageIndex: newValue.label,
                                    arrRows: [],
                                };
                                if (newValue.label != Rows.nPageIndex) OnLoadData(cloneData);
                            }}
                            lstOption={lstPageData}
                            disClearable
                        />
                        <Pagination
                            color="primary"
                            count={pageCount}
                            page={Rows.nPageIndex}
                            variant="outlined"
                            shape="rounded"
                            onChange={(event, value) => {
                                if (!isLoading) {
                                    let cloneData = { ...Rows, nPageIndex: value, arrRows: [] };
                                    OnLoadData(cloneData);
                                }
                            }}
                        />
                    </Stack>
                </Stack>
            )
        );
    };

    const CustoUnderTable = () => {
        const maxRowSizePerPage = Rows.nPageIndex * Rows.nPageSize;
        const rowTotalCount = Rows.nDataLength;
        const minRowSizePerPage =
            Rows.nPageIndex > 1 ? (Rows.nPageIndex - 1) * Rows.nPageSize + 1 : 1;
        const pageCount = Math.ceil(rowTotalCount / Rows.nPageSize);
        const sumMaxRow =
            rowTotalCount > maxRowSizePerPage ? maxRowSizePerPage : rowTotalCount;
        lstPageData = [];
        lstPage = [];
        for (let i = 0; i < pageCount; i++) {
            lstPageData.push({ label: i + 1, value: i });
            lstPage.push(<MenuItem value={i}>{i + 1}</MenuItem>);
        }
        return rowTotalCount < 10 && !isNotShowTotal ? (
            <Box sx={{ display: "block", width: "100%" }}>
                <Stack
                    direction="row"
                    justifyContent="space-between"
                    alignContent="center"
                    sx={{ px: 2 }}
                >
                    {lstSelectRowId.length > 0 && onDelete != undefined ? (
                        <BtnCancel
                            isCircleWithOutText
                            onClick={() => onDelete(lstSelectRowId)}
                        />
                    ) : (
                        <Box></Box>
                    )}
                    <Stack justifyContent="center">
                        <Typography>Total : {rowTotalCount}</Typography>
                    </Stack>
                </Stack>
            </Box>
        ) : (
            !isNotShowPagination && (
                <Stack
                    direction="row"
                    sx={{ px: 2, minWidth: "550px" }}
                    justifyContent="space-between"
                    alignItems="center"
                    flex={1}
                    spacing={1}
                >
                    {lstSelectRowId.length > 0 && onDelete != undefined ? (
                        <BtnCancel
                            isCircleWithOutText
                            txt={"Remove"}
                            onClick={() => onDelete(lstSelectRowId)}
                        />
                    ) : (
                        <Box></Box>
                    )}
                    <Stack direction="row" alignItems="center" spacing={1}>
                        <Typography component={"span"}>Per page:</Typography>
                        <Select
                            label=""
                            size="small"
                            disabled={isLoading}
                            autoWidth
                            sx={{
                                height: 30,
                                ".MuiOutlinedInput-notchedOutline > legend": {
                                    width: 0,
                                },
                            }}
                            value={lstPageSize.indexOf(Rows.nPageSize)}
                            onChange={(e) => {
                                apiRef.current.setPageSize(lstPageSize[e.target.value]);
                            }}
                        >
                            {lstPageSize.map((item, index) => (
                                <MenuItem key={`selPageSize_${index}`} value={index}>
                                    {item}
                                </MenuItem>
                            ))}
                        </Select>
                        <Typography>{`${minRowSizePerPage > sumMaxRow ? sumMaxRow : minRowSizePerPage
                            } - ${sumMaxRow} of ${rowTotalCount}`}</Typography>
                        <Typography component={"span"}>Go to</Typography>
                        <AutoCompleteInTable
                            disabled={isLoading}
                            small
                            sxCustom={{
                                ".MuiOutlinedInput-root": {
                                    padding: "4.5px 14px !important",
                                    height: "30px",
                                    ".MuiOutlinedInput-notchedOutline > legend": {
                                        width: 0,
                                    },
                                },
                            }}
                            objValue={{ label: Rows.nPageIndex, value: Rows.nPageIndex - 1 }}
                            funcOnChange={(event, newValue) => {
                                let cloneData = {
                                    ...Rows,
                                    nPageIndex: newValue.label,
                                    arrRows: [],
                                };
                                if (newValue.label != Rows.nPageIndex) OnLoadData(cloneData);
                            }}
                            lstOption={lstPageData}
                            disClearable
                        />
                        <Pagination
                            color="primary"
                            count={pageCount}
                            page={Rows.nPageIndex}
                            variant="outlined"
                            shape="rounded"
                            onChange={(event, value) => {
                                if (!isLoading) {
                                    let cloneData = { ...Rows, nPageIndex: value, arrRows: [] };
                                    OnLoadData(cloneData);
                                }
                            }}
                        />
                    </Stack>
                </Stack>
            )
        );
    };
    const CustomFilterPanel = () => {
        const form = useForm();
        const [innerStateRow, setInnerState] = useState(Rows);
        const _onSubmit = (e) => {
            let cloneData = {
                nPageSize: innerStateRow.nPageSize,
                nPageIndex: 1,
                sSortExpression: "",
                sSortDirection: "",
                rows: [],
            };
            filterField.forEach((item) => {
                if (
                    (e[`inp_filter_${item.sFieldName}`] != undefined &&
                        e[`inp_filter_${item.sFieldName}`] != "") ||
                    (item.sTypeFilterMode == "select" &&
                        e[`inp_filter_${item.sFieldName}`] == 0)
                ) {
                    if (
                        item.sTypeFilterMode == "daterange" &&
                        e[`inp_filter_${item.sFieldName}`][0] != null &&
                        e[`inp_filter_${item.sFieldName}`][1] != null
                    ) {
                        let dFirstDate = new Date(e[`inp_filter_${item.sFieldName}`][0].year(), e[`inp_filter_${item.sFieldName}`][0].month(), e[`inp_filter_${item.sFieldName}`][0].date(), 0, 0, 0)
                        let dLastDate = new Date(e[`inp_filter_${item.sFieldName}`][1].year(), e[`inp_filter_${item.sFieldName}`][1].month(), e[`inp_filter_${item.sFieldName}`][1].date(), 23, 59, 59)
                        cloneData[`lst${item.sFieldName}`] = [
                            dFirstDate.getTime(),
                            dLastDate.getTime(),
                        ];
                    }
                    cloneData[item.sFieldName] = e[`inp_filter_${item.sFieldName}`];
                }
            });
            if (isCustomOnFilter) {
                onFilterCustom(cloneData, e);
            } else {
                OnLoadData(cloneData);
            }
        };

        return (
            <div style={{ maxHeight: 200, overflow: "auto", width: "100%" }}>
                <Stack style={{ padding: "10px" }} direction="column" spacing={1}>
                    <FormProvider {...form}>
                        {filterField.map((item) => {
                            const labelFilter = item.sLabel
                                ? item.sLabel
                                : Columns.find((f) => f.field == item.sFieldName).headerName;

                            // if (item.sTypeFilterMode == "daterange") {
                            //   form.setValue(
                            //     `inp_filter_dUpdateDate`, [moment(), moment()])
                            // } else {

                            form.setValue(
                                `inp_filter_${item.sFieldName}`,
                                innerStateRow[item.sFieldName]
                                    ? innerStateRow[item.sFieldName]
                                    : null
                            );
                            // }
                            switch (item.sTypeFilterMode) {
                                case "input": {
                                    return (
                                        <TextField
                                            key={`tf_${item.sFieldName}`}
                                            type={item.sType || "text"}
                                            inputProps={{
                                                inputMode:
                                                    item.sInputMode != null ? item.sInputMode : "text",
                                            }}
                                            size="small"
                                            autoComplete="off"
                                            label={labelFilter}
                                            {...form.register(`inp_filter_${item.sFieldName}`)}
                                        />
                                    );
                                }
                                case "select": {
                                    return (
                                        <Autocomplete
                                            key={`ac_${item.sFieldName}`}
                                            renderOption={(props, option) => {
                                                return (
                                                    <li {...props} key={option.value}>
                                                        {option.label}
                                                    </li>
                                                );
                                            }}
                                            defaultValue={
                                                innerStateRow[item.sFieldName] != undefined
                                                    ? {
                                                        label: item.lstDataSelect.find(
                                                            (f) => f.value == innerStateRow[item.sFieldName]
                                                        ).label,
                                                        value: innerStateRow[item.sFieldName],
                                                    }
                                                    : null
                                            }
                                            size="small"
                                            {...form.register(`inp_filter_${item.sFieldName}`)}
                                            onChange={(e, v) => {
                                                console.log(v);
                                                form.setValue(
                                                    `inp_filter_${item.sFieldName}`,
                                                    v?.value ?? null
                                                );
                                                innerStateRow[item.sFieldName] = v?.value ?? null;
                                                setInnerState({ ...innerStateRow });
                                            }}
                                            options={item.lstDataSelect}
                                            getOptionLabel={(itemOption: any) => {
                                                return `${itemOption.label}`;
                                            }}
                                            renderInput={(params) => (
                                                <TextField label={labelFilter} {...params} />
                                            )}
                                        />
                                    );
                                }
                                // case "multiselect": {
                                //     return (
                                //         <MultiSelect
                                //             key={`ms_${item.sFieldName}`}
                                //             // valueData={
                                //             //     innerStateRow[item.sFieldName]
                                //             //         ? innerStateRow[item.sFieldName]
                                //             //         : []
                                //             // }
                                //             onChange={(s) => {
                                //                 innerStateRow[item.sFieldName] = s;
                                //                 setInnerState({ ...innerStateRow });
                                //             }}
                                //             // onDeleteChip={(e) => {
                                //             //     innerStateRow[item.sFieldName] = innerStateRow[
                                //             //         item.sFieldName
                                //             //     ].filter((f) => f != e);
                                //             //     setInnerState({ ...innerStateRow });
                                //             // }}
                                //             // small
                                //             name={`inp_filter_${item.sFieldName}`}
                                //             // options={item.lstDataSelect}
                                //             label={labelFilter}
                                //         />
                                //     );
                                // }
                                case "daterange": {
                                    return (
                                        <DateRange
                                            key={`dr_${item.sFieldName}`}
                                            value={
                                                innerStateRow[item.sFieldName]
                                                    ? innerStateRow[item.sFieldName]
                                                    : [null, null]
                                            }
                                            small
                                            name={`inp_filter_${item.sFieldName}`}
                                            label={labelFilter}
                                        />
                                    );
                                }
                            }
                        })}
                        <BtnSearch txt={"Search"} onClick={form.handleSubmit(_onSubmit)} />
                    </FormProvider>
                </Stack>
            </div>
        );
    };

    return isExpan == true ? (
        <>
            <DataGridPro
                rowReordering={rowReordering}
                onRowOrderChange={onRowOrderChange}
                sx={{
                    minHeight: Rows.arrRows.length < maxRowNoScroll ? 0 : minHeightCustom,
                    " .MuiDataGrid-main": {
                        overflowX: "hidden",
                        overflowY: "auto",
                        ".MuiDataGrid-columnHeaders > .MuiDataGrid-columnHeadersInner > .MuiDataGrid-columnHeader--sortable > .MuiDataGrid-columnHeaderDraggableContainer > div ":
                        {
                            ".MuiDataGrid-iconButtonContainer": {
                                visibility: "initial",
                                width: "auto",
                            },
                            ".MuiDataGrid-columnHeaderTitle": {
                                overflow: "visible",
                            },
                        },
                    },
                    // " .MuiDataGrid-overlay": {
                    //   bgcolor: "white",
                    // },
                    " .MuiDataGrid-columnHeaders": {
                        background: backgroundHeaderCustom,
                        ...sxCustomHeader,
                    },
                    " .MuiDataGrid-cell": {
                        color: "#333333",
                        ".MuiDataGrid-detailPanelToggleCell.Mui-disabled": {
                            display: "none"
                        }
                        // backgroundColor: "white",
                    },
                    " .MuiDataGrid-footerContainer": {
                        // backgroundColor: "white",
                        overflowX: "auto",
                        overflowY: "hidden",
                        maxWidth: "100vw",
                    },
                    ".MuiDataGrid-columnHeader, .MuiDataGrid-cell": {
                        borderRight: `1px solid rgba(224, 224, 224, 1)`,
                    },
                    " .MuiDataGrid-columnSeparator": {
                        opacity: "0!important",
                    },
                    " .MuiDataGrid-columnsContainer, .MuiDataGrid-cell": {
                        borderBottom: `1px solid rgba(224, 224, 224, 1)`,
                    },
                    " .row-odd": {
                        bgcolor: "#F1F1F1",
                    },
                    ".MuiDataGrid-row": {
                        borderTop: "0.1px solid rgba(0,0,0,0.2) !important"
                    },
                    ...sxCustomTable,
                }}
                density="compact"
                localeText={{
                    columnsPanelHideAllButton: "ซ่อนทั้งหมด",
                    columnsPanelShowAllButton: "แสดงทั้งหมด",
                    columnsPanelTextFieldLabel: "ค้นหาคอลัมน์",
                    columnsPanelTextFieldPlaceholder: "คอลัมน์",
                    toolbarColumns: "คอลัมน์",
                    checkboxSelectionHeaderName: "เลือก",
                }}
                getRowClassName={(params) =>
                    params.indexRelativeToCurrentPage % 2 === 0 ? "row-even" : "row-odd"
                }
                rows={isLoading ? [] : Rows.arrRows}
                getRowId={(item) => item.sID}
                columns={Columns}
                pagination
                disableSelectionOnClick
                apiRef={apiRef}
                checkboxSelection={isShowCheckBox}
                loading={isLoading}
                autoHeight={true}
                getRowHeight={() => 'auto'}
                // autoHeight={
                //     id === "main" ? false : Rows.arrRows.length < Rows.nPageSize
                // }
                onSelectionModelChange={(itm) => {
                    setLstSelectRowId(itm as any);
                    const selectedIDs = new Set(itm);
                    const selectedRows = Rows.arrRows.filter((row) =>
                        selectedIDs.has(row.sID)
                    );
                    //props.onSelect(selectedRows) //ปิดเพราะพอเรียก OnDelete แล้ว error
                }}
                filterMode={HandleDataMode}
                paginationMode={HandleDataMode}
                onRowClick={(e) => onCickRow(e)}
                sortingMode={HandleDataMode}
                pageSize={Rows.nPageSize}
                rowCount={Rows.nDataLength}
                page={Rows.nPageIndex}
                disableMultipleColumnsSorting
                onSortModelChange={(model, detail) => {
                    if (model.length > 0) {
                        let cloneData = {
                            ...Rows,
                            sSortExpression: model[0].field,
                            sSortDirection: model[0].sort,
                            arrRows: [],
                        };
                        OnLoadData(cloneData);
                    } else {
                        let cloneData = {
                            ...Rows,
                            sSortExpression: "",
                            sSortDirection: "",
                            arrRows: [],
                        };
                        OnLoadData(cloneData);
                    }
                }}
                hideFooterSelectedRowCount
                components={{
                    FilterPanel:
                        !HiddenToolHead && filterField.length > 0 && !isLoading
                            ? CustomFilterPanel
                            : () => null,
                    Toolbar: () =>
                        !HiddenToolHead ? QuickSearchToolbar(filterField.length > 0) : null,
                    Pagination: CustomPagination as any,
                    NoRowsOverlay: CustomNoRowsOverlay,
                    ColumnSortedDescendingIcon: () => <ExpandMore sx={{ color: "white" }} />,
                    ColumnSortedAscendingIcon: () => <ExpandLess sx={{ color: "white" }} />,
                    ColumnUnsortedIcon: () => <UnfoldMore sx={{ fontSize: "1em", color: "white" }} />,
                    
                }}
                disableColumnMenu={disableColumnMenu}
                onPageSizeChange={(pageSize) => {
                    let cloneData = {
                        ...Rows,
                        nPageSize: pageSize,
                        nPageIndex: 1,
                        rows: [],
                    };
                    OnLoadData(cloneData);
                }}
                isRowSelectable={isRowSelectable}
                rowThreshold={0}
                getDetailPanelHeight={
                  
                    ContentExpand
                        ? () =>
                            nExpandRowLength > 0 ?
                                nExpandRowLength * 50 :
                                nExpandRowLength === 0 ? 220 : 0
                                
                               
                        : null as any
                }
                
                // getDetailPanelContent={ContentExpand ? () => ContentExpand : null as any}
               getDetailPanelContent={(row) => ContentExpand ? ContentExpand(row) : null}
               detailPanelExpandedRowIds={detailPanelExpandedRowIds.length > 0 ? detailPanelExpandedRowIds : [] as any}
                onDetailPanelExpandedRowIdsChange={
                    handleDetailPanelExpandedRowIdsChange
                }
            />
        </>
    ) :( <>
        <DataGridPro
            rowReordering={rowReordering}
            onRowOrderChange={onRowOrderChange}
            sx={{
                minHeight: Rows.arrRows.length < maxRowNoScroll ? 0 : minHeightCustom,
                " .MuiDataGrid-main": {
                    overflowX: "hidden",
                    overflowY: "auto",
                    ".MuiDataGrid-columnHeaders > .MuiDataGrid-columnHeadersInner > .MuiDataGrid-columnHeader--sortable > .MuiDataGrid-columnHeaderDraggableContainer > div ":
                    {
                        ".MuiDataGrid-iconButtonContainer": {
                            visibility: "initial",
                            width: "auto",
                        },
                        ".MuiDataGrid-columnHeaderTitle": {
                            overflow: "visible",
                        },
                    },
                },
                // " .MuiDataGrid-overlay": {
                //   bgcolor: "white",
                // },
                " .MuiDataGrid-columnHeaders": {
                    background: backgroundHeaderCustom,
                    ...sxCustomHeader,
                },
                " .MuiDataGrid-cell": {
                    color: "#333333",
                    ".MuiDataGrid-detailPanelToggleCell.Mui-disabled": {
                        display: "none"
                    }
                    // backgroundColor: "white",
                },
                " .MuiDataGrid-footerContainer": {
                    // backgroundColor: "white",
                    overflowX: "auto",
                    overflowY: "hidden",
                    maxWidth: "100vw",
                },
                ".MuiDataGrid-columnHeader, .MuiDataGrid-cell": {
                    borderRight: `1px solid rgba(224, 224, 224, 1)`,
                },
                " .MuiDataGrid-columnSeparator": {
                    opacity: "0!important",
                },
                " .MuiDataGrid-columnsContainer, .MuiDataGrid-cell": {
                    borderBottom: `1px solid rgba(224, 224, 224, 1)`,
                },
                " .row-odd": {
                    bgcolor: "#F1F1F1",
                },
                ".MuiDataGrid-row": {
                    borderTop: "0.1px solid rgba(0,0,0,0.2) !important"
                },
                ...sxCustomTable,
            }}
            density="compact"
            localeText={{
                columnsPanelHideAllButton: "ซ่อนทั้งหมด",
                columnsPanelShowAllButton: "แสดงทั้งหมด",
                columnsPanelTextFieldLabel: "ค้นหาคอลัมน์",
                columnsPanelTextFieldPlaceholder: "คอลัมน์",
                toolbarColumns: "คอลัมน์",
                checkboxSelectionHeaderName: "เลือก",
            }}
            getRowClassName={(params) =>
                params.indexRelativeToCurrentPage % 2 === 0 ? "row-even" : "row-odd"
            }
            rows={isLoading ? [] : Rows.arrRows}
            getRowId={(item) => item.sID}
            columns={Columns}
            pagination
            disableSelectionOnClick
            apiRef={apiRef}
            checkboxSelection={isShowCheckBox}
            loading={isLoading}
            autoHeight={true}
            getRowHeight={() => 'auto'}
            // autoHeight={
            //     id === "main" ? false : Rows.arrRows.length < Rows.nPageSize
            // }
            onSelectionModelChange={(itm) => {
                setLstSelectRowId(itm as any);
                const selectedIDs = new Set(itm);
                const selectedRows = Rows.arrRows.filter((row) =>
                    selectedIDs.has(row.sID)
                );
                //props.onSelect(selectedRows) //ปิดเพราะพอเรียก OnDelete แล้ว error
            }}
            filterMode={HandleDataMode}
            paginationMode={HandleDataMode}
            onRowClick={(e) => onCickRow(e)}
            sortingMode={HandleDataMode}
            pageSize={Rows.nPageSize}
            rowCount={Rows.nDataLength}
            page={Rows.nPageIndex}
            disableMultipleColumnsSorting
            onSortModelChange={(model, detail) => {
                if (model.length > 0) {
                    let cloneData = {
                        ...Rows,
                        sSortExpression: model[0].field,
                        sSortDirection: model[0].sort,
                        arrRows: [],
                    };
                    OnLoadData(cloneData);
                } else {
                    let cloneData = {
                        ...Rows,
                        sSortExpression: "",
                        sSortDirection: "",
                        arrRows: [],
                    };
                    OnLoadData(cloneData);
                }
            }}
            hideFooterSelectedRowCount
            components={{
                FilterPanel:
                    !HiddenToolHead && filterField.length > 0 && !isLoading
                        ? CustomFilterPanel
                        : () => null,
                Toolbar: () =>
                    !HiddenToolHead ? QuickSearchToolbar(filterField.length > 0) : null,
                Pagination: CustomPagination as any,
                NoRowsOverlay: CustomNoRowsOverlay,
                ColumnSortedDescendingIcon: () => <ExpandMore sx={{ color: "white" }} />,
                ColumnSortedAscendingIcon: () => <ExpandLess sx={{ color: "white" }} />,
                ColumnUnsortedIcon: () => <UnfoldMore sx={{ fontSize: "1em", color: "white" }} />,
            }}
            disableColumnMenu={disableColumnMenu}
            onPageSizeChange={(pageSize) => {
                let cloneData = {
                    ...Rows,
                    nPageSize: pageSize,
                    nPageIndex: 1,
                    rows: [],
                };
                OnLoadData(cloneData);
            }}
            isRowSelectable={isRowSelectable}
            rowThreshold={0}
            getDetailPanelHeight={
                ContentExpand
                    ? () =>
                        nExpandRowLength > 0 ?
                            nExpandRowLength * 50 :
                            nExpandRowLength === 0 ? 220 : 0
                    : null as any
            }
            
            // getDetailPanelContent={ContentExpand ? () => ContentExpand : null as any}
          //  getDetailPanelContent={(row) => ContentExpand ? ContentExpand(row) : null}
           // detailPanelExpandedRowIds={detailPanelExpandedRowIds.length > 0 ? detailPanelExpandedRowIds : [] as any}
            onDetailPanelExpandedRowIdsChange={
                handleDetailPanelExpandedRowIdsChange
            }
        />
    </>
    )
    ;

}
DataGridMui.defaultProps = {
    Rows: initRows,
    isLoading: false,
    isShowCheckBox: false,
    filterField: [],
    onDelete: undefined,
    disableColumnMenu: true,
    lstPageSize: [10, 25, 50, 100],
    backgroundHeaderCustom: "#eeeeee",
    maxRowNoScroll: 10,
    minHeightCustom: 500,
    onCickRow: () => { },
    isCustomOnFilter: false,
    onFilterCustom: () => { },
    HiddenToolHead: false,
    HandleDataMode: "server",
    isExpan: false,
};

DataGridMui.propTypes = {
    Rows: PropTypes.object,
    HiddenToolHead: PropTypes.bool,
    Columns: PropTypes.array.isRequired,
    OnLoadData: PropTypes.func.isRequired,
    isLoading: PropTypes.bool,
    isShowCheckBox: PropTypes.bool,
    isExpan: PropTypes.bool,
    disableColumnMenu: PropTypes.bool,
    filterField: PropTypes.array,
    onDelete: PropTypes.func,
    isNotShowTotal: PropTypes.bool,
    isNotShowPagination: PropTypes.bool,
    lstPageSize: PropTypes.array,
    backgroundHeaderCustom: PropTypes.string,
    sxCustomHeader: PropTypes.any,
    sxCustomTable: PropTypes.any,
    maxRowNoScroll: PropTypes.number,
    minHeightCustom: PropTypes.number,
    onCickRow: PropTypes.func,
    isCustomOnFilter: PropTypes.bool,
    onFilterCustom: PropTypes.func,
    isRowSelectable: PropTypes.func,
    onExportExcel: PropTypes.func,
    isExportExcel: PropTypes.bool,
    HandleDataMode: PropTypes.oneOf(["server", "client"]),
    ContentExpand: PropTypes.any,
    onExpand: PropTypes.func,
    nExpandRowLength: PropTypes.number,
    id: PropTypes.string,
    rowReordering: PropTypes.bool,
    onRowOrderChange: PropTypes.func,
    isShowButtonUnderTable: PropTypes.bool,
    /**
    * ตอนเลือก Check Box
    */
    onSelect: PropTypes.func,
};
