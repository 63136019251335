import './App.css';
import { Routes, Route, useRoutes, useLocation } from "react-router-dom";
import CssBaseline from "@mui/material/CssBaseline";
import route from "./router/route"
import { ThemeProvider, createTheme } from "@mui/material/styles";
import theme from "./themes";
import BlockUI from "src/Components/BlockUI";
import { useEffect, useLayoutEffect } from 'react';
import { AlertMsg, AlertTitle, SwAlert } from './Components/Alert/Sweetalert';
import jwtDecode, { JwtPayload } from 'jwt-decode'
import { lnkToHomePage } from "src/CommonFunction/TS_function";
import ReactGA from 'react-ga4';

// ReactGA.initialize('G-3HTSJ33B23');
// ReactGA.send("pageview");
//#region set Top 0 Change Page
const Wrapper = ({ children }) => {
  const location = useLocation();
  useEffect(() => {
    window.location.reload
    document.documentElement.scrollTo(0, 0);
  }, [location.pathname]);

  useEffect(() => {
    window.location.reload
    document.documentElement.scrollTo(0, 0);
  }, [window.location.href]);

  const JWT = localStorage.getItem(process.env.REACT_APP_JWT_KEY as string);
  if (JWT !== null) {
    const decodedToken: JwtPayload = jwtDecode(JWT);
    if ((decodedToken?.exp as number) < (new Date().getTime() / 1000)) {
      window.localStorage.removeItem(process.env.REACT_APP_JWT_KEY as string)
      //SwAlert.Warning(undefined, AlertMsg.SessionTimeOut, () => { window.location.reload() })

    }
  }

  return children
}
//#endregion


function App() {
  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />
      <Wrapper>
        <a href={process.env.REACT_APP_API_URL} id="lnkToLogin" />
        <a href={process.env.REACT_APP_API_URL + "home"} id="lnkToHomePage" />
        <Routes>
          {route.map((o: any) => {
            return (
              <Route
                index={o.exact}
                path={process.env.REACT_APP_API_URL + o.path}
                key={o.path}
                element={
                  o.layout ? (
                    <o.layout propsLayout={o.propsLayout} menuID={o.menuID} hMenuID={o.hMenuID}>
                      <o.component />
                    </o.layout>
                  ) : (
                    <>
                      <o.component />
                    </>
                  )
                }
              />
            );
          })}
        </Routes>
      </Wrapper>
      <BlockUI />
    </ThemeProvider>
  );
}

export default App;
