import { Card, Grid, Typography } from '@mui/material';
import CardContent from '@mui/material/CardContent';
import './StepperCustom.css'
import useMediaQuery from '@mui/material/useMediaQuery';
import { useEffect, useState } from 'react';
import NameBox from "src/Components/Register/RegisterNameBox";

const StepperCustom = (item) => {

    const MediaQuery300 = useMediaQuery('(min-width:300px)');
    const MediaQuery400 = useMediaQuery('(min-width:400px)');
    const MediaQuery500 = useMediaQuery('(min-width:500px)');
    const MediaQuery800 = useMediaQuery('(min-width:800px)');
    const MediaQuery1080 = useMediaQuery('(min-width:1080px)');

    const [nMinHeigth, setnMinHeigth] = useState(110);

    useEffect(() => {
        setnMinHeigth(MediaQuery800 == true ? 110 : MediaQuery500 == true ? 190 : MediaQuery400 == true ? 300 : MediaQuery300 == true ? 300 : 220)
    }, [MediaQuery500, MediaQuery800, MediaQuery1080])

    return (
        <>
            <div className={"boxStepper arrow-rightStepper " + item.sNameBox.classname}>
                <Grid container  alignItems="center" sx={{ width: 350, height: 110 ,paddingLeft:"-1px"}}  >
                    <Grid item xs={12} >
                        <Grid container  >
                            <Grid item xs={12}>
                                <Typography style={{fontSize:'18px',color:'#ffffff', paddingLeft:"15px" ,paddingTop:"10px"}} >
                                    {item.sNameBox.sName}
                                </Typography>
                            </Grid>
                        </Grid>
                        <Grid item xs={12}>
                            <div  style={{fontSize:'14px',color:'#ffffff',paddingLeft:"15px"}} >
                                {item.sNameBox.sDesc}
                            </div>
                        </Grid>
                    </Grid>
                </Grid>
            </div>
        </>

      
    )
}
export default StepperCustom

