import TextField from "@mui/material/TextField";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { useEffect, useState } from "react";
import { useFormContext } from "react-hook-form";
import moment from "moment";
import { AdapterMoment } from "@mui/x-date-pickers/AdapterMoment";
import { IconButton, InputAdornment, Tooltip } from "@mui/material";
import { AccountCircle, Clear } from "@mui/icons-material";

const localeMap = (lang) => {
  switch (lang) {
    case 'th':
      moment.locale("th");
      break;
    case 'en':
      moment.locale("en");
      break;
    default:
      break;
  }
};

export function DatePickerUI(props: DatePickerUI) {
  const {
    name,
    views,
    fullWidth,
    label,
    closeOnSelect,
    disabled,
    openTo,
    showToolbar,
    disableFuture,
    minDate,
    maxDate,
    small,
    format
  } = props;
  const [value, setValues] = useState<any>(null);

  const {
    control,
    register,
    getValues,
    setValue,
    watch,
    formState: { touchedFields, errors, isSubmitted },
  } = useFormContext();

  const ReplaceThFormat = (text) => {
    return text.toLowerCase().replaceAll("d", "ว").replaceAll("m", "ด").replaceAll("y", "ป");
  }


  const formatDefalut = format || "DD/MM/YYYY";
  const MapPlaceHolder = {
    en: formatDefalut,
    th: ReplaceThFormat(formatDefalut),
  };

  const [locale] = useState<keyof typeof MapPlaceHolder>(() => "th");


  useEffect(() => {
    localeMap(locale);
  }, [])

  const ClearFields = (e) => {
    setValues("");
    setValue(name, "");
    props.onClearFields && props.onClearFields(e);
    // (document.getElementById("DatePickerField") as HTMLInputElement).value = "";
    // (document.getElementsByClassName("DatePickerField") as any).value = "";
    // <HTMLElement[]<any>document.getElementsByClassName('slide')
  }

  return (
    <LocalizationProvider dateAdapter={CustomAdapter} locale={locale}>
      <DatePicker
        className="DatePickerField"
        {...register(name)}
        views={views || ['day']}
        label={label}
        closeOnSelect={closeOnSelect || true}
        disabled={disabled || false}
        showToolbar={showToolbar || false}
        disableFuture={disableFuture || false}
        inputFormat={formatDefalut}
        minDate={minDate}
        maxDate={maxDate}
        openTo={openTo || "day"}
        value={value || null}
        InputProps={{
          readOnly: true,
          startAdornment: (
            value ? (
              <InputAdornment position="start">
                <Tooltip onClick={(e) => {
                  setValues("");
                  setValue(name, "");
                  props.onClearFields && props.onClearFields(e);
                }} title={""}>
                  <IconButton component='span'>
                    <Clear sx={{ fontSize: "16px" }} />
                  </IconButton>
                </Tooltip>
              </InputAdornment>
            ) : (<></>)
          ),
        }}
        onChange={(e) => {
          setValues(e);
          setValue(name, e)
          props.onChange && props.onChange(e);
        }}
        renderInput={(params) => {
          // let startValue = value ? value.format(formatDefalut) : "";
          let startValue = params.inputProps?.value || "" as any;
          delete params.inputProps?.value
          if (value && startValue && locale === "th") {
            let countYear = (formatDefalut.toLowerCase().match(new RegExp("y", "g")) || []).length
            let sYearStart = (value.year() + 543).toString();
            let indexOfYear = formatDefalut.toLowerCase().indexOf("y");
            let subStrStart = countYear != 4 ? sYearStart.substr(countYear) : sYearStart;
            startValue = startValue.substring(0, indexOfYear) + subStrStart + startValue.substring(indexOfYear + subStrStart.length);
          }
          return (
            <>
              <TextField
                id="DatePickerField"
                {...params}
                {...register(name)}
                name={name}
                sx={{ width: "100%" }}
                label={label}
                value={startValue}
                helperText={null}
                fullWidth={fullWidth || false}
                placeholder=""
                autoComplete={"off"}
                size={small ? "small" : "medium"}
                onKeyDown={e => e.preventDefault()}
              />
            </>
          )
        }}
      />
    </LocalizationProvider>
  );
}

interface DatePickerUI {
  name: string;
  /**
   * Ex. ["day", "month", "year"]
   */
  views?: any[];
  fullWidth?: boolean;
  label?: string;
  format?: string;
  closeOnSelect?: boolean;
  disabled?: boolean;
  showToolbar?: boolean;
  disableFuture?: boolean;
  maxDate?: Date;
  minDate?: Date;
  small?: boolean;
  openTo?: "day" | "month" | "year";
  onChange?: (value: any) => void;
  onClearFields?: (value: any) => void;
}

class CustomAdapter extends AdapterMoment {
  // REF : https://th-samranrit0193.medium.com/reactjs-materialui-buddhist-era-%E0%B8%9B%E0%B8%B5-%E0%B8%9E-%E0%B8%A8-1f293af00382
  constructor({ locale, formats, instance }) {
    super({ locale, formats, instance });
  }

  calYear(moment) {
    switch (this.locale) {
      case "th":
        return (parseInt(moment.format('YYYY')) + 543).toString();
      case "en":
        return parseInt(moment.format('YYYY'));
      default:
        return parseInt(moment.format('YYYY'));
    }
  }

  renderLabel(moment, format) {
    switch (format) {
      case "year":
        return this.calYear(moment);
      case "month":
        return moment.format("MMMM");
      case "dayOfMonth":
        return moment.format("D");
      case "monthAndYear":
        return `${moment.format("MMMM ")} ${this.calYear(moment)}`;
      default:
        return moment.format(format);
    }
  }

  startOfMonth = (date) => date ? date.clone().startOf("month") : moment(new Date());

  format = (date, formatKey) => {
    return date ? this.renderLabel(date, formatKey) : moment(new Date());
  };
}