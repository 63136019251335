import React from "react";
import { Label } from "@mui/icons-material";
import { Box, Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Fade, Grid, Modal, Typography } from "@mui/material";
import { useEffect, useState } from "react";
import { GridColumns, GridRowParams } from "@mui/x-data-grid-pro";
import { FnBlock_UI } from "src/Components/BlockUI";
import { DataGridMui as Table, initRows, PaginationInterface } from "src/Components/Table/DataGridMui";
import { TextField } from "@material-ui/core";
import AddIcon from "@mui/icons-material/Add";
import { Axios } from "src/CommonFunction/TS_Axios";
import { Divider } from "@mui/material";
import { InputForm } from "src/Components/FormItem/Input";
import SaveIcon from "@mui/icons-material/Save";
import CheckIcon from "@mui/icons-material/Check";
import ClearIcon from "@mui/icons-material/Clear";
import UploadFile from "src/Components/ElementInForm/UploadFile/UploadFile";
import { Extension, lnkToHomePage } from "src/CommonFunction/TS_function";
import { FormProvider, useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { BtnEditOnTable, BtnRemoveOnTable } from "src/Components/Button";
import { AlertMsg, AlertTitle, SwAlert } from "src/Components/Alert/Sweetalert";
import yupFormSchemas, { MsgValidate } from "src/Components/FormItem/yup/yupFormSchemas";
import { i18n } from "src/i18n";
import "./dialog.css"
interface itemDoc {
  nID: number;
  sID: string;
  sDocName: string;
  sFileName: string;
  sFileSysName: string;
  sFilePath: string;
  sFileType: string;
  isNew: boolean;
  isChange: boolean;
}

export default function FileDialog(prop) {
  const { open, handleClose, sRegID } = prop;
  const { BlockUI, UnBlockUI } = FnBlock_UI();
  const [loadingTable, setLoadingTable] = useState(false);
  const [lstData, setLstData] = useState([]);
  const [isAddDoc, setIsAddDoc] = useState(false);
  const [arrFile, setArrFile] = useState<any[]>([]);
  const [sNumber, setNumber] = useState("");
  const [sTotalAmount, setTotalAmount] = useState("");
  const [idDoc, setIdDoc] = useState(1);
  const [isEdit, setIsEdit] = useState(false);
  const [idEdit, setIdEdit] = useState(1);
  const [disabled, setDisabled] = useState(true);
  const [oData, setData] = useState<any>({});
  const tagColor = "#1a237e";
  const [dataRow, setDataRow] = useState<PaginationInterface>({
    ...initRows,
    sSortExpression: "",
    sSortDirection: "",
  });

  const objSchema = {
    sDocName: yupFormSchemas.string("ชื่อเอกสาร", {
      required: true,
    }),
  };
  const schema = yup.object().shape(objSchema);
  const form = useForm({
    mode: "all",
    resolver: yupResolver(schema),
    shouldFocusError: true,
  });

  const dataColumn: GridColumns = [
    {
      field: "sID",
      headerName: "ที่",
      headerAlign: "center",
      editable: false,
      width: 50,
      resizable: true,
      filterable: false,
      align: "center",
      sortable: false,
    },
    {
      renderHeader: (item) => {
        return [
          <Grid item>
            <Grid item xs={12} justifyContent={"center"}>
              <Typography color="#fff" sx={{ ml: "25px" }}>
                ชื่อเอกสาร
              </Typography>
            </Grid>
            <Grid item xs={12}>
              <Typography variant="body2" color="#fff">
                Document Name
              </Typography>
            </Grid>
          </Grid>,
        ];
      },
      field: "sDocName",
      headerAlign: "center",
      align: "left",
      flex: 1,
      resizable: true,
      minWidth: 300,
    },
    {
      renderHeader: (item) => {
        return [
          <Grid item>
            <Grid item xs={12} justifyContent={"center"}>
              <Typography color="#fff">ไฟล์แนบ</Typography>
            </Grid>
            <Grid item xs={12}>
              <Typography color="#fff" variant="body2" sx={{ ml: "12px" }}>
                File
              </Typography>
            </Grid>
          </Grid>,
        ];
      },
      field: "sFileName",
      headerAlign: "center",
      align: "left",
      flex: 1,
      resizable: true,
      minWidth: 300,
      renderCell: (item) => {
        return (
          <a href={item.row.sFilePath} download target="_blank">
            {item.row.sFileSysName}
          </a>
        );
      },
    },
    {
      renderHeader: (item) => "",
      field: "Button (Add/Edit)",
      headerName: "",
      headerAlign: "center",
      type: "actions",
      width: 120,
      resizable: false,
      sortable: false,
      getActions: (item) => {
        return [<BtnEditOnTable txt="Edit" onClick={() => editDoc(item.row)} />, <BtnRemoveOnTable onClick={() => removeDoc(item.row)} />];
      },
    },
  ];

  useEffect(() => {
    if (open) {
      resetData();
      loadData(dataRow);
    }
  }, [open]);

  useEffect(() => { }, [idEdit, idDoc]);

  const loadData = async (p: PaginationInterface) => {
    let param = "sRegisID=" + sRegID;
    let result = {} as any;
    await Axios(
      `Classroom/GetDocumentRegister`,
      param,
      "Get",
      async (res) => {
        result = res;
        res.lstAttach.forEach((e, index) => {
          e.sID = index + 1;
          e.isChanged = true;
        });
        setIdDoc(res.lstAttach.length + 1);
        setDataRow({
          ...p,
          arrRows: res.lstAttach,
        });
      },
      null,
      BlockUI,
      UnBlockUI,
      null
    );
    setData(result);
    setNumber(result.sNumber);
    setTotalAmount(result.sTotalAmount);
    return result;
  };

  const saveDataRow = (e, sType) => {
    if (arrFile.length == 0) {
      SwAlert.Warning(
        AlertTitle.Warning,
        `กรุณาแนบไฟล์`,
        () => {
          return false;
        }
      );
    } else {
      var path = "/UploadFiles/Temp/";
      if (sType === "Add") {
        let fFile: any = {};
        if (arrFile.length > 0) {
          arrFile.forEach((f: any) => {
            fFile.sFileName = f.sFileName;
            fFile.sFileSysName = f.sSysFileName;
            fFile.sFilePath = f.sFileLink;
            fFile.sFileType = f.sFileType;
          });
        }
        let itemDoc = {
          nID: idDoc,
          sID: idDoc.toString(),
          sDocName: e.sDocName,
          sFileName: fFile.sFileName,
          sFileSysName: fFile.sFileSysName,
          sFilePath: fFile.sFilePath,
          sFileType: fFile.sFileType,
          isNew: true,
          isChange: false,
        } as itemDoc;
        let cloneRow = dataRow;
        cloneRow.arrRows = [...cloneRow.arrRows, itemDoc];
        setDataRow(cloneRow);
        form.setValue("sDocName", "");
        setArrFile([]);
        setIdDoc(idDoc + 1);
        setIsAddDoc(false);
      } else {
        let fFile: any = {};
        if (arrFile.length > 0) {
          arrFile.forEach((f: any) => {
            fFile.sFileName = f.sFileName;
            fFile.sFileSysName = f.sSysFileName;
            fFile.sFilePath = f.sFileLink;
            fFile.sFileType = f.sFileType;
          });
        }
        let cloneRow = dataRow;
        let itemRow = cloneRow.arrRows.filter((f) => f.nID == idEdit);
        if (itemRow.length > 0) {
          itemRow[0].sDocName = e.sDocName;
          itemRow[0].sFileName = fFile.sFileName;
          itemRow[0].sFileSysName = fFile.sFileSysName;
          itemRow[0].sFilePath = fFile.sFilePath;
          itemRow[0].sFileType = fFile.sFileType;
          itemRow[0].isNew = itemRow[0].isNew ? true : false;
          itemRow[0].isChange = itemRow[0].isNew ? false : true;
        }
        setDataRow({ ...cloneRow });

        form.setValue("sDocName", "");
        setArrFile([]);
        setIsEdit(false);
        setIsAddDoc(false);
      }
      setDisabled(false);
    }
  };

  const removeDoc = (oRow) => {
    let cloneRow = dataRow;
    cloneRow.arrRows = cloneRow.arrRows.filter((f) => f.nID !== oRow.nID);
    let itemRow = cloneRow.arrRows;
    let nNewID = 0;
    if (itemRow.length > 0) {
      itemRow.forEach((i) => {
        nNewID += 1;
        i.nID = nNewID;
        i.sID = nNewID.toString();
      });
    }
    form.setValue("sDocName", "");
    setArrFile([]);
    setIsAddDoc(false);
    setIdDoc(nNewID + 1);
    setDataRow({ ...cloneRow });
    setDisabled(false);
  };

  const editDoc = (data) => {
    setIsEdit(true);
    setIdEdit(data.nID);
    form.setValue("sDocName", data.sDocName);
    // var File =
    //   {
    //     nFile_ID: idEdit,
    //     sFileName: data.sFileName,
    //     sSysFileName: data.sFileSysName,
    //     sFilePath: data.sFilePath,
    //     sFileLink: data.sFilePath,
    //     sFileType: data.sFileType,
    //     IsComplete: true,
    //   }
    // ;
    // let getFile:any[] = arrFile;
    // getFile.push(File);
    // console.log('getFile',getFile);
    // setArrFile([...getFile]);
    setArrFile([
      {
        nFile_ID: idEdit,
        sFileName: data.sFileName,
        sSysFileName: data.sFileSysName,
        sFilePath: data.sFilePath,
        sFileLink: data.sFilePath,
        sFileType: data.sFileType,
        IsComplete: true,
      },
    ]);
    // setArrFile(File);
    setIsAddDoc(true);
  };

  const onSaveData = async () => {
    handleClose();
    SaveFile();
    // if (dataRow.arrRows.length == 0) {
    //   SwAlert.Warning(
    //     AlertTitle.Warning,
    //     `กรุณาระบุข้อ`,
    //     () => {
    //       return false;
    //     }
    //   );
    // } else {


    // }
  };

  const SaveFile = async () => {
    var lstFile: any = dataRow.arrRows || [];
    if (dataRow.arrRows.length > 0) {
      for (var i = 0, len = lstFile.length; i < len; i++) {
        delete lstFile[i].sID;
      }
    }
    // delete lstFile.sID;
    var param = {
      sRegID: sRegID,
      lstFile: lstFile,
    };
    let result = {} as any;
    await Axios(
      `Classroom/SaveChangesDocFile`,
      param || {},
      "Post",
      async (res) => {
        if (res.isSuccess) {
          SwAlert.Success(AlertTitle.Success, AlertMsg.SaveComplete, () => {
            //resetData();
            handleClose();
          });
        }
        else {
          SwAlert.Warning(undefined, i18n("common.SessionTimeOut"), lnkToHomePage);

        }

      },
      null,
      BlockUI,
      UnBlockUI,
      null
    );

  }

  const resetData = () => {
    form.setValue("sDocName", "");
    let defaultRow = dataRow;
    defaultRow.arrRows = [];
    setDataRow(defaultRow);
    setIdDoc(1);
    setIsAddDoc(false);
    setArrFile([]);
    setNumber("");
    setTotalAmount("");
    setData({});
    setIsEdit(false);
    setIdEdit(1);
    setDisabled(true);
  };

  const closeAddDoc = () => {
    form.setValue("sDocName", "");
    setArrFile([]);
    setIsAddDoc(false);
    setIsEdit(false);
  }

  return (
    <Dialog open={open} onClose={handleClose} aria-labelledby="alert-dialog-title" aria-describedby="alert-dialog-description" fullWidth={true} maxWidth={"lg"}>
      <DialogContent>
        <Grid container>
          <Grid
            item
            container
            spacing={2}
            sx={{
              pb: 3,
            }}>
            <Grid item xs={12}>
              <Typography color={tagColor}>ชื่อหลักสูตร / Course Name</Typography>
              {/* <TextField name="sCourseName" id="standard-basic" variant="standard" fullWidth value={oData.sTrainingCourseName} /> */}
              <div style={{ padding: "10px 0 5px 0;",textAlign:"left" }} className="dialog-content" id="standard-basic">{oData.sTrainingCourseName}</div>
            </Grid>

            <Grid item xs={6}>
              <Typography color={tagColor}>รุ่น / Session</Typography>
              {/* <TextField name="sSession" id="standard-basic" variant="standard" fullWidth value={oData.sScheduleName} /> */}
            </Grid>
            <Grid item xs={6}>
              <Typography color={tagColor}>วันที่อบรม / Date</Typography>
              {/* <TextField name="sDate" id="standard-basic" variant="standard" fullWidth value={oData.sTraniningDate} /> */}
            </Grid>

            <Grid container className="dialog-item dialog-pad-left-16">
              <Grid item xs={6}>
                <div style={{ padding: "10px 0 5px 0;" }} className="dialog-content" id="standard-basic">{oData.sScheduleName}</div>
              </Grid>
              <Grid item xs={6} className="dialog-pad-left-10">
                <div style={{ padding: "10px 0 5px 0;" }} className="dialog-content" id="standard-basic">{oData.sTraniningDate}</div>
              </Grid>
            </Grid>



            <Grid item xs={6}>
              <Typography color={tagColor}>สถานที่อบรม / Location</Typography>
              {/* <TextField name="sLocation" id="standard-basic" variant="standard" fullWidth value={oData.sLocation} /> */}

            </Grid>
            <Grid item xs={6}>
              <Typography color={tagColor}>ตัวเลือกราคา / Price option</Typography>
              {/* <TextField name="sOption" id="standard-basic" variant="standard" fullWidth value={oData.sOption} /> */}

            </Grid>

            <Grid container className="dialog-item dialog-pad-left-16">
              <Grid item xs={6}>
                <div style={{ padding: "10px 0 5px 0;" }} className="dialog-content" id="standard-basic">{oData.sLocation}</div>
              </Grid>
              <Grid item xs={6} className="dialog-pad-left-10">
                <div style={{ padding: "10px 0 5px 0;" }} className="dialog-content" id="standard-basic">{oData.sOption}</div>
              </Grid>
            </Grid>


            <Grid item xs={6}>
              <Typography color={tagColor}>ผู้เข้าอบรม / Participants</Typography>
              {/* <TextField name="sParticipants" id="standard-basic" variant="standard" fullWidth value={sNumber} /> */}
            </Grid>
            <Grid item xs={6}>
              <Typography color={tagColor}>จำนวนเงินที่ต้องชำระ / Total Amount</Typography>
              {/* <TextField name="sAmount" id="standard-basic" variant="standard" fullWidth value={sTotalAmount} /> */}
            </Grid>

            <Grid container className="dialog-item dialog-pad-left-16">
              <Grid item xs={6}>
                <div style={{ padding: "10px 0 5px 0;" }} className="dialog-content" id="standard-basic">{sNumber}</div>
              </Grid>
              <Grid item xs={6} className="dialog-pad-left-10">
                <div style={{ padding: "10px 0 5px 0;" }} className="dialog-content" id="standard-basic">{sTotalAmount}</div>
              </Grid>
            </Grid>

            <Grid item xs={6}>
              <Typography color={tagColor}>เอกสารแนบ / Attached Files</Typography>
            </Grid>
            <Grid item xs={12}>
              <Button onClick={() => setIsAddDoc(true)} startIcon={<AddIcon />} style={{ color: "#fff" }} variant="contained">
                เพิ่มเอกสาร
              </Button>
            </Grid>
            <Grid item md={12} xs={12}>
              <Table
                HiddenToolHead
                Rows={dataRow}
                Columns={dataColumn}
                OnLoadData={(p) => loadData(p)}
                backgroundHeaderCustom={"#0a286c"}
                sxCustomHeader={{ color: "#FFF" }}
                isNotShowPagination={false}
              />
            </Grid>
            {isAddDoc ? (
              <FormProvider {...form}>
                <Grid item xs={12} spacing={2}>
                  <Box sx={{ boxShadow: 1, p: 2 }}>
                    <Grid item md={12} xs={12}>
                      <div style={{ height: "10px" }}></div>
                      <Divider />
                      <div style={{ height: "10px" }}></div>
                    </Grid>
                    <Grid container md={12} xs={12} style={{ display: "flex", alignItems: "center" }}>
                      <Grid item xs={12} sm={12} md={3} lg={3} >
                        <Typography> ชื่อเอกสาร / Document Name</Typography>
                      </Grid>
                      <Grid item xs={12} sm={12} md={9} lg={9}>
                        <InputForm name="sDocName" fullWidth maxLength={500} />
                      </Grid>
                    </Grid>
                    <Grid item md={12} xs={12}>
                      <div style={{ height: "10px" }}></div>
                      <Divider />
                      <div style={{ height: "10px" }}></div>
                    </Grid>
                    <Grid container md={12} xs={12} style={{ display: "flex", alignItems: "center" }}>
                      <Grid item xs={12} sm={12} md={3} lg={3}>
                        <Typography> ไฟล์แนบ / File</Typography>
                      </Grid>
                      <Grid item xs={12} sm={12} md={9} lg={9} style={{ paddingTop: "0.5em" }}>
                        <UploadFile
                          keyID={"fFile"}
                          sTitle={""}
                          sSubTitle={""}
                          nLimitFile={50}
                          IsRequired={false}
                          arrFile={arrFile || []}
                          setarrFile={setArrFile}
                          IsFolder={false}
                          IsFile={true}
                          IsMultiple={false}
                          isFileChange={true}
                          Extension={Extension.OBAFile}
                          IsDrag={false}
                        />
                      </Grid>
                    </Grid>

                    <Grid container item md={12} xs={12} style={{ display: "flex", alignItems: "center" }}>

                      <div style={{ paddingLeft: 0, paddingTop: "0.5em", paddingRight: "0.5em", paddingBottom: "0.5em" }}>
                        <Button variant="contained" startIcon={<SaveIcon />} onClick={form.handleSubmit((e) => saveDataRow(e, isEdit ? "Edit" : "Add"))}>บันทึก / Save</Button>
                      </div>
                      <div style={{ paddingLeft: 0, paddingTop: "0.5em", paddingRight: "0.5em", paddingBottom: "0.5em" }}>
                        <Button variant="contained" color="error" startIcon={<SaveIcon />} onClick={() => closeAddDoc()}>ยกเลิก / Cancle</Button>
                      </div>

                    </Grid>
                  </Box>
                </Grid>
              </FormProvider>
            ) : null}
          </Grid>
        </Grid>
      </DialogContent>
      <DialogActions>
        <Button variant="contained" color="success" startIcon={<CheckIcon />} onClick={() => onSaveData()} disabled={disabled}>
          บันทึกการเปลี่ยนแปลง / Save Change
        </Button>
        <Button variant="contained" color="error" startIcon={<ClearIcon />} onClick={handleClose}>
          ปิด / Close
        </Button>
      </DialogActions>
    </Dialog>
  );
}
