import { useEffect, useState } from "react";
import { Button, Grid, IconButton, InputAdornment } from "@mui/material";
import Typography from "@mui/material/Typography";
import { Visibility, VisibilityOff } from "@mui/icons-material";
import { FormProvider, useForm } from "react-hook-form";
import { InputForm } from "src/Components/FormItem/Input";
import * as yup from "yup";
import yupFormSchemas, { MsgValidate, RegexpPasswork } from "src/Components/FormItem/yup/yupFormSchemas";
import { yupResolver } from "@hookform/resolvers/yup";
import logo from "src/Layout/img/oba-logo.png";
import logoreset from "src/Layout/img/logo-Reset.jpg";
import { Axios } from "src/CommonFunction/TS_Axios";
import { Process_System, ResultAPI } from "src/CommonFunction/TS_function";
import { useNavigate } from 'react-router-dom';
import { AlertMsg, AlertTitle, SwAlert } from "../Alert/Sweetalert";

const PasswordSuccess = () => {
    const navigate = useNavigate();
    let params = new URLSearchParams(window.location.search);
    const objSchema = {
        sPassword: yupFormSchemas.string("Password", {
            required: true,
            matches: RegexpPasswork(),
            labelmatches: MsgValidate.Passwork,
        }),
        sConfirmPWD: yupFormSchemas.string("Confirm Password", { required: true }
        ).oneOf([yup.ref("sPassword")], "Invalid Confirm Password"),
    }

    const schema = yup.object().shape(objSchema);
    const form = useForm({
        resolver: yupResolver(schema),
        shouldUnregister: false,
        shouldFocusError: true,
        mode: "all",
    });

    useEffect(() => {
        onPageLoad();
    }, []);

    const onPageLoad = () => {
        let obj = {
            sUserID: params.get("str"),
            sMode: params.get("mode"),
            sEmailID: params.get("mail"),
        }
        Axios("ResetPassWord/onPageLoadSaveNewPassword", obj, "POST", (res) => {
            if (res.Status === Process_System.process_Success) {
                if (res.isExpire) {
                    SwAlert.Warning(AlertTitle.Warning, "ลิงค์นี้ได้หมดอายุแล้ว", () => {
                        navigate(process.env.REACT_APP_API_URL + "Home");
                    });
                }
            }
            else {
                SwAlert.Warning(AlertTitle.Warning, res.Message, () => {
                    navigate(process.env.REACT_APP_API_URL + "Home");
                });
            }
        })
    }
    const onSubmit = () => {
        SwAlert.Confirm(AlertTitle.Confirm, "ต้องการส่งข้อมูลหรือไม่?", () => onSaveNewPassword(), () => { })
    }

    const onSaveNewPassword = async () => {
        let obj = {
            sUserID: params.get("str"),
            sMode: params.get("mode"),
            sPassword: form.getValues("sPassword"),
        }
        await Axios(
            `ResetPassWord/SaveNewPassWord`, obj, "POST", async (res) => {
                if (res.Status === Process_System.process_Success) {
                    SwAlert.Warning(AlertTitle.Success, "บันทึกรหัสผ่านใหม่เรียบร้อยแล้ว", () => {
                        window.location.reload();
                    });
                    navigate(process.env.REACT_APP_API_URL + "Home");
                }
                else {
                    SwAlert.Warning(AlertTitle.Warning, res.Message, () => {
                        window.location.reload();
                    });
                }
            })
    }
    return (
        <FormProvider {...form}>
            <form onSubmit={form.handleSubmit(onSubmit)}>
                <Grid container sx={{ marginTop: "1%" }}>
                    <img src={logo} style={{ width: "10%", height: "50%", margin: "auto auto", }} />
                    <Grid item xs={12} sx={{ marginTop: "2%" }}>
                        <Typography align="center" variant="h5">ตั้งรหัสผ่านใหม่/Password</Typography>
                    </Grid>
                    <Grid container>
                    <Grid item xs={12} md={6} sx={{ borderRadius: 1, width: "30%", marginTop: "2%", paddingLeft: "2%" }}>
                        <Grid item sx={{ margin: "3%" }}>
                            <Typography fontSize={20} color="#0000CD">การกรอกรหัสผ่าน</Typography>
                        </Grid>
                        <Grid item spacing={3} sx={{ margin: "3%" }}>
                            <Typography fontSize={18}>1.ต้องมีความยาวอย่างน้อย 8-15 ตัวอักษร</Typography>
                            <Typography fontSize={18}>2.ต้องประกอบด้วยภาษาอังกฤษตัวพิมพ์ใหญ่อย่างน้อย 1 ตัวอักษร และตัวพิมพ์เล็กอย่างน้อย 1 ตัวอักษร</Typography>
                            <Typography fontSize={18}>3.ต้องประกอบด้วยตัวเลขอย่างน้อย 1 ตัวอักษร และอักขระพิเศษอย่างน้อย 1 ตัวอักษร</Typography>
                        </Grid>
                        <Grid item sx={{ margin: "3%" }}>
                            <Typography fontSize={20} color="#0000CD">Password Policy</Typography>
                        </Grid>
                        <Grid item spacing={3} sx={{ margin: "3%" }}>
                            <Typography fontSize={18}>1.Must be at least 8-15 characters.</Typography>
                            <Typography fontSize={18}>2.Must contain at least 1 uppercase letter and at least 1 lowercase letter.</Typography>
                            <Typography fontSize={18}>3.Must contain at least 1 character and at least 1 number.</Typography>
                        </Grid>
                        <Grid item xs={12} sx={{ marginTop: "5%" }}></Grid>
                    </Grid>


                    <Grid item  xs={12} md={6} sx={{ borderRadius: 1, width: "30%", marginTop: "2%" }}>
                        <Grid container justifyContent="center" style={{ marginTop: "10px" }}>
                            <img src={logoreset} style={{ width: "10%", height: "10%", }} />
                        </Grid>
                        <div style={{ padding: "3% 15%" }}>
                            <Grid item xs={12} marginLeft={"8px"}>
                                <Typography fontSize={18}>รหัสผ่านใหม่ /New Password</Typography>
                            </Grid>
                            <Grid container>
                                <Grid item xs={12} lg={12}>
                                    <InputForm
                                        name="sPassword"
                                        label="รหัสผ่าน/Password"
                                        required={true}
                                        fullWidth
                                        isPassword={true}
                                    />
                                </Grid>
                                <Grid item xs={12} marginLeft={"8px"}>
                                    <Typography fontSize={18}>ยืนยันรหัสผ่านใหม่ /New Password Confirmation </Typography>
                                </Grid>
                                <Grid item xs={12} lg={12}>
                                    <InputForm
                                        name="sConfirmPWD"
                                        label="ยืนยันรหัสผ่านใหม่/New Password"
                                        required={true}
                                        fullWidth
                                        //type={showCPassword ? "text" : "Password"}
                                        isPassword={true}
                                    />
                                </Grid>
                                <Grid item xs={12} style={{ marginTop: "10px" }}>
                                    <Grid container justifyContent={'center'} alignItems={'center'}>
                                        <Grid item>
                                            <Button fullWidth
                                                onClick={() => { form.handleSubmit(onSubmit)() }}
                                                sx={{
                                                    backgroundColor: "#196F3D", fontSize: 15, color: "White", ":hover": { bgcolor: "#27AE60" }
                                                }}>ตกลง/Submit</Button>
                                        </Grid>
                                    </Grid>
                                </Grid>
                            </Grid>
                        </div>
                    </Grid>
                    </Grid>

                </Grid >
            </form>
        </FormProvider >
    );
}
export default PasswordSuccess


