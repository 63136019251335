import { yupResolver } from "@hookform/resolvers/yup";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router";
import { FnBlock_UI } from "../BlockUI";
import * as yup from "yup";
import { Axios } from "src/CommonFunction/TS_Axios";
import { FormProvider, useForm } from "react-hook-form";
import { Box, Grid } from "@mui/material";
import { BreadcrumbsUI } from "../BreadcrumbsUI";
import { DataGridMui, initRows, PaginationInterface } from "../Table/DataGridMui";
import { GridColumns } from '@mui/x-data-grid';
import { BtnAddOnTable, BtnEditOnTable } from "../Button";
import { Process_System, ResultAPI } from "src/CommonFunction/TS_function";
import { AlertMsg, AlertTitle, DialogConfirmDelete, SwAlert } from "src/Components/Alert/Sweetalert";
import { Typography } from "@mui/material";



const ManageProfileTable = () => {
    const navigate = useNavigate();
    const { BlockUI, UnBlockUI } = FnBlock_UI();
    const [loadingTable, setLoadingTable] = useState(true);
    const [arrProfile, setarrProfile] = useState([]);
    const searchParams = location.search;
    const sID = new URLSearchParams(searchParams).get('sID');

    const [dataRow, setDataRow] = useState<PaginationInterface>({
        ...initRows,
        sSortExpression: "sID",
        sSortDirection: "asc"
    });

    const objSchema = {};
    const schema = yup.object().shape(objSchema);

    const form = useForm({
        resolver: yupResolver(schema),
        shouldUnregister: false,
        shouldFocusError: true,
        mode: "all"
    });

    useEffect(() => {
        loadData(dataRow);
    }, [])


    const loadData = async (p: PaginationInterface) => {
        setLoadingTable(true)
        BlockUI();
        await Axios("ProfilePage/GetData_MangeProfileList", p || {}, "POST", async (res) => {
            UnBlockUI();
            ResultAPI(true, res, null, () => {
                setLoadingTable(false)
                setarrProfile(res.lstMangeProfile)
                setDataRow({
                    ...p,
                    arrRows: res.lstMangeProfile || [],
                    nDataLength: res.nDataLength === 0 ? res.nDataLength : (res.nDataLength > 10 ? res.nDataLength : 10),
                    nPageIndex: res.nPageIndex || 1,
                });

            });
        }, null, BlockUI, UnBlockUI, null)
    };

    const goToEdit = (sID: string, item: any) => {
        window.localStorage.setItem("sSubRouteName", item.sSection);
        navigate(process.env.REACT_APP_API_URL + `ManageProfile_Edit?sID=${encodeURI(sID)}`);
    };

    const OnDelete = async (e) => {
        DialogConfirmDelete(() => {
            Axios("ProfilePage/DeleteData_MangeProfileDetail", { lstRemoveData: e }, "POST", async (res) => {
                ResultAPI(true, res, res.Message, () => {
                    if (res.Status === Process_System.process_Success) {
                        SwAlert.Success(AlertTitle.Success, AlertMsg.DeleteComplete, () => {
                            loadData(dataRow);
                        });

                    }
                    else {
                        SwAlert.Warning(AlertTitle.Warning, res.Message, () => { });
                    }
                })
            }, null, BlockUI, UnBlockUI, null)
        })
    };


    const dataColumn: GridColumns = [
        {
            field: "nNo",
            headerName: "ที่",
            headerAlign: "center",
            editable: false,
            width: 80,
            resizable: true,
            sortable: false,
            align: "center",           
        },
        {
            field: "sPersonalID",
            headerName: "เลขประจำตัว",
            headerAlign: "center",
            align: "left",
            resizable: true,
            sortable: true,
            flex: 4,
            minWidth: 200,
            renderCell: (item) => {              
                return (
                    <>
                        <Typography component={"label"} style={{ wordBreak: "break-word" }}>
                            {item.value || ""}
                        </Typography>
                    </>
                );
            },

        },
        {
            field: "sName",
            headerName: "ชื่อ - นามสกุล",
            headerAlign: "center",
            align: "left",
            resizable: true,
            sortable: true,
            flex: 4,
            minWidth: 200,
            renderCell: (item) => {
                return (
                    <>
                        <Typography component={"label"} style={{ wordBreak: "break-word" }}>
                            {item.value || ""}
                        </Typography>
                    </>
                );
            },

        },
        {
            field: "sPosition",
            headerName: "ตำแหน่ง",
            headerAlign: "center",
            align: "left",
            resizable: true,
            sortable: true,
            flex: 4,
            minWidth: 200,
            renderCell: (item) => {
                return (
                    <>
                        <Typography component={"label"} style={{ wordBreak: "break-word" }}>
                            {item.value || ""}
                        </Typography>
                    </>
                );
            },
        },
        {
            field: "sEmail",
            headerName: "E-mail",
            headerAlign: "center",
            align: "left",
            resizable: true,
            sortable: true,
            flex: 4,
            minWidth: 200,
            renderCell: (item) => {
                return (
                    <>
                        <Typography component={"label"} style={{ wordBreak: "break-word" }}>
                            {item.value || ""}
                        </Typography>
                    </>
                );
            },
        },
        {
            field: "sTel",
            headerName: "เบอร์โทรศัพท์",
            headerAlign: "center",
            align: "left",
            resizable: true,
            sortable: true,
            flex: 4,
            minWidth: 200,
            renderCell: (item) => {
                return (
                    <>
                        <Typography component={"label"} style={{ wordBreak: "break-word" }}>
                            {item.value || ""}
                        </Typography>
                    </>
                );
            },
        },
        {
            renderHeader: () => <BtnAddOnTable
                onClick={() => { navigate(process.env.REACT_APP_API_URL + `ManageProfile_Edit`); }}

                txt={"Add"}
            />,
            headerName: "Button (Add/Edit)",
            field: "Button (Add/Edit)",
            type: "actions",
            width: 100,
            resizable: false,
            sortable: false,
            getActions: (item) => {               
                return [
                    (!item.row.isDelete || item.row.isPin) ? <></>:(
                        <BtnEditOnTable
                            onClick={() => goToEdit(item.row.sID, item.row)}
                        />
                    )
                ];
            },
        },
    ];

    return (
        // <FormProvider {...form}>
        <Grid container>
            <Grid item xs={12}>
                <BreadcrumbsUI
                    //  isShowBG={true}
                    isShowHome={false}
                    items={[
                        {
                            menuName: "จัดการโปรไฟล์/Manage Profile",
                            path: "ManageProfileTable",
                            IsOnClick: false
                        },

                    ]}
                />
            </Grid>

            <Grid item xs={12} flex={1} marginX={2} justifyContent="center" sx={{ marginTop: 5 }}>
                <Grid item xs={12} >
                    <Box sx={{ width: "100%" }}>
                        <DataGridMui
                            HiddenToolHead={true}
                            isShowCheckBox
                            isRowSelectable={(p) => !p.row.isPin && p.row.isDelete}
                            isLoading={loadingTable}
                            Columns={dataColumn}
                            onDelete={OnDelete}
                            OnLoadData={(p: PaginationInterface) => {
                                loadData(p)
                            }}
                            Rows={dataRow}
                            sxCustomHeader={{
                                color: "#fff !important"

                            }}
                            backgroundHeaderCustom={"#122768"}
                        />
                    </Box>
                </Grid>
                <Grid item xs={12} sx={{ marginTop: 5 }}>
                </Grid>
            </Grid>
        </Grid >
        // </FormProvider >
    );
}
export default ManageProfileTable


