import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import logo from "src/Layout/img/oba-logo.png";
import { Button, FormControlLabel, Grid, Input, Radio, RadioGroup, Typography } from "@mui/material";
import { useEffect, useState } from "react";
import { FormProvider, useForm } from "react-hook-form";
import yupFormSchemas from "src/Components/FormItem/yup/yupFormSchemas";
import { Axios } from "src/CommonFunction/TS_Axios";
import { FnBlock_UI } from "src/Components/BlockUI";
import { InputForm } from "src/Components/FormItem/Input";
import { AlertMsg, AlertTitle, SwAlert } from "src/Components/Alert/Sweetalert";
import { BreadcrumbsUI } from "../BreadcrumbsUI";
import AutoCompleteSelect from "../FormItem/AutoCompleteSelect";
import RadioFormItem from "../FormItem/RadioFormItem/RadioIFormtem";

const ForgetUserName = () => {
    const { BlockUI, UnBlockUI } = FnBlock_UI();
    const [UserType, setUserType] = useState<number>(0) as any;
    const [prefix, setPrefix] = useState([]);
    const [type, setType] = useState<number>(0);
    const [prefixValue, setPrefixValue] = useState("");
    const [prefixUserType, setPrefixUserType] = useState("");

    //Regex for form
    var Regex13 = /^\d{13}$/;
    var Regex9 = /^[a-zA-Z0-9]{9}$/;
    const [objSchema, setSchema] = useState({
        // sTax: yupFormSchemas.string("เลขประจำตัวผู้เสียภาษี/Taxpayer Id. No.", { required: true, matches: Regex13, labelmatches: "เลขประจำตัวผู้เสียภาษี/Taxpayer Id. No. ไม่ถูกต้อง" }),
        // sCitizenID: yupFormSchemas.string("เลขที่บัตรประชาชน / Citizen ID", { required: true, matches: Regex13, labelmatches: "เลขที่บัตรประชาชน / Citizen ID ไม่ถูกต้อง" }),
        // sPassport: yupFormSchemas.string("เลขที่หนังสือเดินทาง / Passport ID", { required: true, matches: Regex9, labelmatches: "เลขที่หนังสือเดินทาง / Passport ID ไม่ถูกต้อง" }),
        sPrefix: yupFormSchemas.object("ประเภท", { required: true }),
    });

    useEffect(() => {
        setSchema({ ...objSchema })
    }, [UserType, type])

    const schema = yup.object().shape(objSchema);
    const form = useForm({
        resolver: yupResolver(schema),
        mode: "all",
    });

    useEffect(() => {
        getData();
    }, []);

    const getData = async () => {
        let res = await loadPrefix();
        setPrefix(res.lstUserType)
    }

    const loadPrefix = async () => {
        let result = [] as any;
        await Axios(`ForgetUsername/SetFilter_Prefix`, {}, "post", async (res) => {
            result = res;
        },
            null,
            BlockUI,
            UnBlockUI,
            () => { }
        );
        return result;
    }

    const onSubmit = async () => {
        let sTax = form.getValues("sTax");
        let sCitizenID = form.getValues("sCitizenID");
        let sPassport = form.getValues("sPassport");
        var objData = {
            "sUserTypeID": prefixValue,
            "sUserType": prefixUserType,
            "nGroupType": UserType,
            "sCitizenID": UserType == 1 ? sTax : (type == 1 ? sCitizenID : sPassport)
        };
        if (UserType == 0) {
            SwAlert.Warning(AlertTitle.Warning, "โปรดระบุข้อมูลของท่าน/Please enter information", () => { });
        } else {
            SwAlert.Confirm(AlertTitle.Confirm, "ต้องการส่งข้อมูลหรือไม่?", () => onSendRequest(objData), () => { })
        }
    };

    const onSendRequest = async (objData) => {
        let result = null as any;
        await Axios(
            `ForgetUsername/SendEmail_ForgetUsername`,
            objData,
            "Post",
            async (res) => {
                result = res;
                if (res.Status !== "Failed") {
                    SwAlert.Success(AlertTitle.Success, AlertMsg.SaveComplete, () => OnClear());
                }
                else {
                    SwAlert.Warning(AlertTitle.Warning, res.Message, () => { });
                }
            },
            null,
            BlockUI,
            UnBlockUI,
            null
        );
        return result;

        // await Axios(`ForgetUsername/EditData_RequireNewUserName`, objData, "POST", async (res) => {
        //     ResultAPI(true, res, null, () => {
        //         if (res.Status) {
        //             SwAlert.Success(AlertTitle.Success, "ส่งข้อมูลเรียบร้อย", () => OnClear());
        //         }
        //         else {
        //             SwAlert.Warning(AlertTitle.Warning, res.Message, () => { });
        //         }
        //     })
        // }, null, BlockUI, UnBlockUI)
    }

    const OnClear = async () => {
        form.reset();
        setUserType(0);
        setType(0);
        form.setValue('sTax', "");
        form.setValue('sCitizenID', "");
        form.setValue('sPassport', "");
        form.setValue('radio', 0);
        form.setValue('type', 0);
    }

    const setValidate1 = (e) => {
        if (e === "1") {
            objSchema["sTax"] = yupFormSchemas.string("เลขประจำตัวผู้เสียภาษี/Taxpayer Id. No.", { required: true, matches: Regex13, labelmatches: "เลขประจำตัวผู้เสียภาษี/Taxpayer Id. No. ไม่ถูกต้อง" });
            objSchema["sCitizenID"] = yupFormSchemas.string("เลขที่บัตรประชาชน / Citizen ID", { required: false, matches: Regex13, labelmatches: "เลขที่บัตรประชาชน / Citizen ID ไม่ถูกต้อง" });
            objSchema["sPassport"] = yupFormSchemas.string("เลขที่หนังสือเดินทาง / Passport ID", { required: false, matches: Regex9, labelmatches: "เลขที่หนังสือเดินทาง / Passport ID ไม่ถูกต้อง" });
        }
        if (e === "2") {
            objSchema["sTax"] = yupFormSchemas.string("เลขประจำตัวผู้เสียภาษี/Taxpayer Id. No.", { required: false, matches: Regex13, labelmatches: "เลขประจำตัวผู้เสียภาษี/Taxpayer Id. No. ไม่ถูกต้อง" });
            objSchema["sCitizenID"] = yupFormSchemas.string("เลขที่บัตรประชาชน / Citizen ID", { required: false, matches: Regex13, labelmatches: "เลขที่บัตรประชาชน / Citizen ID ไม่ถูกต้อง" });
            objSchema["sPassport"] = yupFormSchemas.string("เลขที่หนังสือเดินทาง / Passport ID", { required: false, matches: Regex9, labelmatches: "เลขที่หนังสือเดินทาง / Passport ID ไม่ถูกต้อง" });
        }

        setSchema({ ...objSchema })
    }

    const setValidate2 = (e) => {
        if (e === 1) {
            objSchema["sTax"] = yupFormSchemas.string("เลขประจำตัวผู้เสียภาษี/Taxpayer Id. No.", { required: false, matches: Regex13, labelmatches: "เลขประจำตัวผู้เสียภาษี/Taxpayer Id. No. ไม่ถูกต้อง" });
            objSchema["sCitizenID"] = yupFormSchemas.string("เลขที่บัตรประชาชน / Citizen ID", { required: true, matches: Regex13, labelmatches: "เลขที่บัตรประชาชน / Citizen ID ไม่ถูกต้อง" });
            objSchema["sPassport"] = yupFormSchemas.string("เลขที่หนังสือเดินทาง / Passport ID", { required: false, matches: Regex9, labelmatches: "เลขที่หนังสือเดินทาง / Passport ID ไม่ถูกต้อง" });
        }
        else if (e === 2) {
            objSchema["sTax"] = yupFormSchemas.string("เลขประจำตัวผู้เสียภาษี/Taxpayer Id. No.", { required: false, matches: Regex13, labelmatches: "เลขประจำตัวผู้เสียภาษี/Taxpayer Id. No. ไม่ถูกต้อง" });
            objSchema["sCitizenID"] = yupFormSchemas.string("เลขที่บัตรประชาชน / Citizen ID", { required: false, matches: Regex13, labelmatches: "เลขที่บัตรประชาชน / Citizen ID ไม่ถูกต้อง" });
            objSchema["sPassport"] = yupFormSchemas.string("เลขที่หนังสือเดินทาง / Passport ID", { required: true, matches: Regex9, labelmatches: "เลขที่หนังสือเดินทาง / Passport ID ไม่ถูกต้อง" });
        }

        setSchema({ ...objSchema })
    }

    return (
        <>
            <Grid container>
                <Grid item xs={12}>
                    <BreadcrumbsUI
                        items={[
                            {
                                Key: "1",
                                menuName: "ลืมชื่อผู้ใช้งาน/Forgot Username",
                                IsOnClick: false,
                            },
                        ]}
                        isShowHome={false}
                    />
                </Grid>
                <Grid item xs={12} sx={{ marginTop: "3%" }}>
                    <FormProvider {...form}>
                        <Grid xs={12} sx={{ display: "flex", justifyContent: "center" }}>
                            <img src={logo} style={{ width: "10%", height: "auto", margin: "auto", textAlign: "center" }} />
                        </Grid>
                        <Grid item xs={12} sx={{ marginTop: "1%" }}>
                            <Typography align="center" fontSize={18}>โปรดระบุข้อมูลของท่าน เราจะส่งลิ้งค์สำหรับแจ้ง Username ไปยังอีเมลของท่าน</Typography>
                        </Grid>
                        <Grid item xs={12} sx={{ my: 1 }}>
                            <Typography align="center" fontSize={18}>Please enter information below. We'll send you the Username.</Typography>
                        </Grid>
                        <Grid item xs={12}>
                            <Grid container spacing={2} sx={{ my: 1 }}>
                                <Grid item xs={12} sx={{ display: "flex", justifyContent: "center" }}>
                                    <AutoCompleteSelect
                                        mx={0}
                                        my={1}
                                        required
                                        name="sPrefix"
                                        label={"ประเภท"}
                                       
                                        options={prefix}
                                        IsShowIcon={false}
                                        disableClearable={true}
                                        width="350px"
                                        onChange={(v) => {
                                            setPrefixValue(v.value)
                                            setPrefixUserType(v.sUserType)
                                            form.setValue('sPrefix', v ? v.value : null)
                                        }}
                                    />
                                </Grid>
                                <Grid item xs={12} sx={{ display: "flex", justifyContent: "center" }}>
                                    <RadioFormItem
                                        IsNewLine
                                        name="UserType"
                                        options={[
                                            { "value": 1, "label": "ผู้แทนจำหน่าย / คู่ค้า,ผู้ค้า / บริษัทในเครือ" },
                                            { "value": 2, "label": "บุคคลธรรมดา/Personal" },
                                        ]}
                                        value={form.getValues("UserType")}
                                        onChange={(e) => {
                                            setUserType(e);
                                            setType(0);
                                            form.setValue('type', 0);
                                            form.setValue('sTax', "");
                                            form.setValue('sCitizenID', "");
                                            form.setValue('sPassport', "");
                                            setValidate1(e);
                                        }}
                                    />
                                    
                                </Grid>
                            </Grid>
                        </Grid>
                        {UserType == 1 &&
                            <Grid item xs={12} sx={{ my: 1 }}>
                                <Grid container spacing={2} justifyContent={'center'} alignItems={'center'}>
                                    <Grid item>
                                        <InputForm
                                            maxLength={13}
                                            fullWidth
                                            width={350}
                                            name="sTax"
                                            required={true}
                                            label="เลขประจำตัวผู้เสียภาษี /Taxpayer Identification No."
                                            showCoutTest={false}
                                        />
                                    </Grid>
                                </Grid>
                            </Grid>}
                        {UserType == 2 &&
                            <>
                                <Grid item xs={12} sx={{ my: 1 }}>
                                    <Grid container spacing={2} justifyContent={'center'} alignItems={'center'}>
                                        <Grid item>
                                            <AutoCompleteSelect
                                                mx={0}
                                                my={1}
                                                required
                                                name="type"
                                                label={"ประเภท"}
                                                width="350px"
                                                disableClearable={true}
                                                options={[
                                                    { label: "เลขประจำตัวประชาชน/Citizen ID", value: 1 },
                                                    { label: "เลขที่หนังสือเดินทาง/Passport", value: 2 }
                                                ]}
                                                IsShowIcon={false}
                                                onChange={(v) => {
                                                    setType(v.value)
                                                    form.setValue('sTax', "")
                                                    form.setValue('sCitizenID', "")
                                                    form.setValue('sPassport', "")
                                                    form.setValue('type', v ? v.value : null)
                                                    setValidate2(v.value);
                                                }}
                                            />
                                        </Grid>
                                    </Grid>
                                </Grid>
                                {type === 1 &&
                                    <Grid item xs={12} sx={{ my: 1 }}>
                                        <Grid container spacing={2} justifyContent={'center'} alignItems={'center'}>
                                            <Grid item>
                                                <InputForm
                                                    maxLength={13}
                                                    name="sCitizenID"
                                                    label="เลขประจำตัวประชาชน/Citizen ID"
                                                    width={350}
                                                    required={true}
                                                    fullWidth
                                                    showCoutTest={false}
                                                />
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                }
                                {type === 2 &&
                                    <Grid item xs={12} sx={{ my: 1 }}>
                                        <Grid container spacing={2} justifyContent={'center'} alignItems={'center'}>
                                            <Grid item>
                                                <InputForm
                                                    maxLength={9}
                                                    name="sPassport"
                                                    label="เลขที่หนังสือเดินทาง/Passport"
                                                    width={350}
                                                    required={true}
                                                    fullWidth
                                                    showCoutTest={false}
                                                />
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                }
                            </>
                        }
                        <Grid item xs={12} sx={{ my: 1 }}>
                            <Grid container justifyContent={'center'} alignItems={'center'}>
                                <Grid item >
                                    <Button
                                        onClick={form.handleSubmit(() => { onSubmit() })}
                                        sx={{
                                            borderRadius: "16px",
                                            backgroundColor: "#196F3D", fontSize: 15, color: "White", ":hover": { bgcolor: "#458522" },
                                            width: "100%"
                                        }}
                                    >
                                        ส่ง/Send
                                    </Button>
                                </Grid>
                            </Grid>
                        </Grid>
                    </FormProvider >
                </Grid>


               
            </Grid>
        </>
    );
}
export default ForgetUserName