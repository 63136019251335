import { yupResolver } from "@hookform/resolvers/yup";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router";
import { FnBlock_UI } from "../BlockUI";
import * as yup from "yup";
import { Axios } from "src/CommonFunction/TS_Axios";
import { FormProvider, useForm } from "react-hook-form";
import { Box, Grid, Paper, Typography } from "@mui/material";
import { BreadcrumbsUI } from "../BreadcrumbsUI";
import { Process_System, ResultAPI } from "src/CommonFunction/TS_function";
import { InputForm } from "../FormItem/Input";
import { BtnSendForm } from "../Button";
import RadioFormItem from "../FormItem/RadioFormItem/RadioIFormtem";
import yupFormSchemas from "../FormItem/yup/yupFormSchemas";
import { AlertMsg, AlertTitle, DialogConfirm, DialogConfirmDelete, SwAlert } from "src/Components/Alert/Sweetalert";

export interface dataChoice {
    QN_ID: number,
    QN_EvalType: string,
    QQ_ID: number,
    QQ_Set: string,
    INS_ID: number,
    nChoice: number,
}

const QuestionnaireNew = () => {
    const navigate = useNavigate();
    const { BlockUI, UnBlockUI } = FnBlock_UI();
    const [lstData, setlstData] = useState({} as any)
    const [arraChoice, setarraChoice] = useState<dataChoice[]>([]);
    const [objSchema, setSchema] = useState({});

    const arrScore = [
        { "value": 4, "label": "ดีมาก" },
        { "value": 3, "label": "ดี" },
        { "value": 2, "label": "พอใช้" },
        { "value": 1, "label": "ควรปรับปรุง" }
    ]

    const schema = yup.object().shape(objSchema);

    const form = useForm({
        resolver: yupResolver(schema),
        shouldUnregister: false,
        shouldFocusError: true,
        mode: "all"
    });
    useEffect(() => {
        setlstData(loadData())

    }, [])

    useEffect(() => {
        onCreateschema(lstData)
    }, [lstData])



    const loadData = async () => {
        // เปลี่ยนตรงนี้รับค่าจาก URL
        const search = window.location.search;
        const paramsearch = new URLSearchParams(search);
        console.log("params",search)
        let sCourse = (Number(paramsearch.get('c') ));
        let sSchedule = (Number(paramsearch.get('s') ));
        let Instructor =paramsearch.get('i');
        console.log("sCourse",sCourse)
        console.log("sSchedule",sSchedule)
        console.log("Instructor",Instructor)
        let id = 25;
        if (id) {
            let Param = {
                nCourseIDSeach: sCourse,
                nScheduleIDSeach:sSchedule,
                lstInstructorID: Instructor
            }
            await Axios(`Questionnaire/GetData_Questionnaire`, Param, "POST", async (res) => {
                ResultAPI(true, res, null, () => {
                    if (res.Status == Process_System.process_Success) {
                        let d = res.lstQuestionnaire
                        //ใส่ข้อมูล
                        setlstData(d)
                        console.log("lstData", d)
                    }
                })
            },
                null,
                BlockUI,
                BlockUI,
                null
            );
        }
    };

    const handleChange = (nChoice, nQuestionnaireID, cType, nQuestionID, nsetHead, sEmpCode) => {

        let objarr: dataChoice = {
            QN_ID: nQuestionnaireID,
            QN_EvalType: cType,
            QQ_ID: nQuestionID,
            QQ_Set: nsetHead,
            INS_ID: sEmpCode,
            nChoice:parseInt(nChoice),

        }
        var chkarraChoice = [...arraChoice];
        if (sEmpCode) {
            var chkData = chkarraChoice.filter(w => w.QN_ID == nQuestionnaireID && w.QQ_ID == nQuestionID && w.INS_ID == sEmpCode && w.QN_EvalType == cType)[0]
            if (chkData) {

                chkData.nChoice = parseInt(nChoice);
            }
            else {
                chkarraChoice.push(objarr)
            }
        }
        else {
            var chkData = chkarraChoice.filter(w => w.QN_ID == nQuestionnaireID && w.QQ_ID == nQuestionID && w.QN_EvalType == cType)[0]
            if (chkData) {

                chkData.nChoice = parseInt(nChoice);
            }
            else {
                chkarraChoice.push(objarr)
            }
        }
        // if (chkarraChoice.some(w => w.QN_ID == nQuestionnaireID && w.QQ_ID == nQuestionID && w.QN_EvalType == cType  && w.INS_ID == sEmpCode )) {
        //     chkarraChoice = chkarraChoice.filter(w => w.QN_ID != nQuestionnaireID && w.QQ_ID != nQuestionID && w.QN_EvalType != cType && w.INS_ID != sEmpCode );
        // };
        // if (chkarraChoice.some(w => w.nOderMain == indexMain && w.nOderSuB == indexSub )) {
        //     chkarraChoice = chkarraChoice.filter(w => w.nOderMain != indexMain && w.nOderSuB != indexSub );
        // };
        setarraChoice([...chkarraChoice]);
    }

    const onSubmit = (event) => {
        let Param = {
            nCourseID: lstData.nCourseID,
            nScheduleID: lstData.nScheduleID,
            lstSelection: arraChoice,
            sComment: form.getValues("comment"),
        }
        console.log("Param", Param)
        DialogConfirm(() => {
            Axios(`Questionnaire/GetSave_Questionnaire`, Param, "POST", async (res) => {
                ResultAPI(true, res, res.Message, () => {
                    if (res.Status == Process_System.process_Success) {
                        SwAlert.Success(AlertTitle.Success, AlertMsg.SaveComplete, () => {
                            navigate(process.env.REACT_APP_API_URL + `home`);
                        });
                    }
                    else {
                        SwAlert.Warning(AlertTitle.Warning, res.Message, () => { });
                    }
                })
            },
                null,
                BlockUI,
                UnBlockUI,
                null
            );
        })
    }

    const onCreateschema = (lstData) => {
        var schema = {
            ...objSchema
        }
        if (lstData && lstData.lstEvaluation && lstData.lstEvaluation.length > 0) {
            lstData.lstEvaluation.forEach((item, index) => {
                item.lstnQuestionMain.forEach((itemSubMain, indexSubMain,) => {
                    if (itemSubMain.lstQuestionSub.length > 0) {
                        itemSubMain.lstQuestionSub.forEach((itemSubSub, indexSubSub,) => {
                            var name = `score-${index + "_" + indexSubMain + "-" + indexSubSub}`
                            schema[name] = yupFormSchemas.string("คำตอบ", { required: true })
                        });
                    }
                    else {
                        var name = `score-${index + "_" + indexSubMain + "-" + "0"}`
                        schema[name] = yupFormSchemas.string("คำตอบ", { required: true })
                    }
                });
            });
            setSchema({ ...schema })
        }
        form.clearErrors()
    }


    return (
        <FormProvider {...form}>
            <Grid container direction="row" justifyContent="center" alignItems="start">
                <Grid item xs={12}>
                    <BreadcrumbsUI
                        isShowHome={false}
                        items={[
                            {
                                menuName: "แบบประเมินหลักสูตร",
                                path: "QuestionnaireNew",
                                IsOnClick: false
                            },
                        ]}
                    />
                </Grid>

                <Grid item xs={10} style={{ padding: '24px 8px' }} sx={{ marginTop: 3 }}>
                    <Grid container spacing={2} direction="row" justifyContent="center" alignItems="start">
                        {/* ///Grid ใหญ่/// */}
                        {/* ข้อมูลแบบประเมิน */}
                        <Grid item xs={12}>
                            <Grid item xs={12} container sx={{ borderBottom: "1px solid #ccc" }}>
                                <Grid item xs={12} md={2} style={{ paddingLeft: '10px' }}>
                                    <Typography fontSize={"17px"} color={"#337ab7"}>หลักสูตร</Typography>
                                </Grid>
                                <Grid item xs={10}>
                                    {lstData["sCourseName"]}
                                </Grid>
                            </Grid>
                            <Grid item xs={12} container sx={{ borderBottom: "1px solid #ccc" }}>
                                <Grid item xs={12} md={2} style={{ paddingLeft: '10px' }}>
                                    <Typography fontSize={"17px"} color={"#337ab7"}>รุ่นที่</Typography>
                                </Grid>
                                <Grid item xs={10}>
                                    {lstData["ScheduleName"]}
                                </Grid>
                            </Grid>

                            <Grid item xs={12} container sx={{ borderBottom: "1px solid #ccc" }}>
                                <Grid item xs={12} md={2} style={{ paddingLeft: '10px' }}>
                                    <Typography fontSize={"17px"} color={"#337ab7"}>วันที่อบรม</Typography>
                                </Grid>
                                <Grid item xs={10}>
                                    {lstData["ScheduleDate"]}
                                </Grid>
                            </Grid>
                            <Grid item xs={12} container sx={{ borderBottom: "1px solid #ccc" }}>
                                <Grid item xs={12} md={2} style={{ paddingLeft: '10px' }}>
                                    <Typography fontSize={"17px"} color={"#337ab7"}>สถานที่อบรม</Typography>
                                </Grid>
                                <Grid item xs={10}>
                                    {lstData["ScheduleLocation"]}
                                </Grid>
                            </Grid>
                        </Grid>
                        <Grid item xs={12}>
                            {/* questionnaire */}
                            {lstData.lstEvaluation != null ? lstData.lstEvaluation.map((item, index) => {
                                let nNO = 1;
                                return (
                                    <Grid item xs={12}>
                                        <Grid item xs={12} className="questionnaire-title" sx={{ borderBottom: "1px solid #ccc" }} style={{ paddingLeft: '15px' }}>
                                            <Typography fontSize={"16px"} color={"#337ab7"}>{item.sTitle}
                                                <span style={{ color: "#000" }}> {item.sTitleS} </span></Typography>
                                        </Grid>

                                        <Grid item xs={12}>
                                            {item.lstnQuestionMain != null && item.lstnQuestionMain.length > 0 ? item.lstnQuestionMain.map((itemSubMain, indexSubMain,) => {
                                                return (
                                                    <Grid item xs={12}>
                                                        {itemSubMain.lstQuestionSub.length > 0 &&
                                                            <Grid item xs={12}>
                                                                <Grid item xs={12} className="questionnaire-titlesub" sx={{ borderBottom: "1px solid #ccc" }} style={{ paddingLeft: '35px' }}>
                                                                    <Typography fontSize={"16px"} color={"#000"}> {itemSubMain.sName}</Typography>
                                                                </Grid>
                                                                <Grid item xs={12}>
                                                                    {itemSubMain.lstQuestionSub != null && itemSubMain.lstQuestionSub.length > 0 ? itemSubMain.lstQuestionSub.map((itemSubSub, indexSubSub,) => {
                                                                        return (
                                                                            <Grid container direction="row" className="questionnaire-lesson" xs={12}>
                                                                                <Grid container direction="row" className="border-bottom" justifyContent={"space-between"} alignItems={"center"} xs={12}>
                                                                                    <Grid item style={{ paddingLeft: '50px' }}>
                                                                                        <Typography fontSize={"16px"}> {itemSubSub.sNameSub}</Typography>
                                                                                    </Grid>
                                                                                    <Grid item style={{ paddingLeft: '50px' }} >
                                                                                        <RadioFormItem
                                                                                            //name={`score-${indexSubSub}`}
                                                                                            name={`score-${index + "_" + indexSubMain + "-" + indexSubSub}`}
                                                                                            options={arrScore}
                                                                                            value={form.getValues(`score-${index + "_" + indexSubMain + "-" + indexSubSub}`)}
                                                                                            onChange={(e) =>
                                                                                                handleChange(e, item.nQuestionnaireID, item.cType,
                                                                                                    itemSubSub.nQuestionIDSub, itemSubSub.nsetHead, item.sEmpCode)}
                                                                                        />
                                                                                    </Grid>
                                                                                </Grid>
                                                                            </Grid>
                                                                        )
                                                                    }) : null}
                                                                </Grid>
                                                            </Grid>
                                                        }
                                                        {itemSubMain.lstQuestionSub.length == 0 &&
                                                            <Grid container direction="row" className="questionnaire-lesson" xs={12} style={{ backgroundColor: '#f5f5f5' }} >
                                                                <Grid container direction="row" className="border-bottom" justifyContent={"space-between"} alignItems={"center"} xs={12}>
                                                                    <Grid item style={{ paddingLeft: '35px' }} >
                                                                        <Typography fontSize={"16px"} color={"#000"}>{itemSubMain.sName}</Typography>
                                                                    </Grid>
                                                                    <Grid item style={{ paddingLeft: '50px' }} >
                                                                        <RadioFormItem
                                                                            name={`score-${index + "_" + indexSubMain + "-" + "0"}`}
                                                                            options={arrScore}
                                                                            value={form.getValues(`score-${index + "_" + indexSubMain + "-" + "0"}`)}
                                                                            onChange={(e) =>

                                                                                handleChange(e, item.nQuestionnaireID, item.cType,
                                                                                    itemSubMain.nQuestionID, 0, 0)}
                                                                        />
                                                                    </Grid>
                                                                </Grid>
                                                            </Grid>
                                                        }
                                                    </Grid>
                                                )
                                            }) : null}
                                        </Grid>
                                    </Grid>
                                )
                            }) : null}
                        </Grid>
                        {/* comment */}
                        <Grid item xs={12} sx={{ pr: "0px !important", pl: "0px !important" }} >
                            <Grid item xs={12} >
                                <label>ความคิดเห็น / หลักสูตรที่สนใจเพิ่มเติม</label>
                            </Grid>
                            <Grid item xs={12} >
                                <InputForm
                                    name="comment"
                                    rows={7}
                                    fullWidth
                                    multiline
                                    maxLength={1000}
                                    styles={{ margin: 0 }}
                                />
                            </Grid>
                        </Grid>
                        {/* send */}
                        <Grid item xs={12} container justifyContent="right">
                            <Grid item>
                                <BtnSendForm
                                    onClick={form.handleSubmit((e) => onSubmit(e))}
                                />
                            </Grid>
                        </Grid>
                        {/* ///Grid ใหญ่/// */}
                    </Grid>
                </Grid>
            </Grid>
        </FormProvider>
    );
}
export default QuestionnaireNew