import React, { useEffect, useState } from "react";
import { Axios } from "src/CommonFunction/TS_Axios";
import BlockUI1, { FnBlock_UI } from "src/Components/BlockUI";
import { Grid, Box, Typography, Card } from "@mui/material";
import { FormProvider, useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import yupFormSchemas from "src/Components/FormItem/yup/yupFormSchemas";
import { GridColumns } from "@mui/x-data-grid-pro";
import { DataGridMui as Table, initRows, PaginationInterface } from "src/Components/Table/DataGridMui";
import { InputForm } from "src/Components/FormItem/Input";
import { BtnBack, BtnCustomText, BtnDetail, BtnSearch } from "src/Components/Button";
import AutoCompleteSelect from "src/Components/FormItem/AutoCompleteSelect";
import { SelectForm } from "src/Components/FormItem/Select";
import HistoryRegisterCourse from "./HistoryRegisterCourse";
import HistoryTrainEmp from "./HistoryTrainEmp";
import HistoryListExamResult from "./HistoryListExamResult";
import HistoryLearning from "./HistoryLearning";
import HistoryTrainUserLogin from "./HistoryTrainUserLogin";
import { Banner } from "../../Components/Banner";
import { BreadcrumbsUI } from "../../Components/BreadcrumbsUI";

const TradingHistory = () => {
  const [isOwner, setShowTabStaff] = useState(true);
  const lstTabsHistory = [
    { value: 1, label: "ประวัติการลงทะเบียนหลักสูตร", cOwner: 1, cStaff: 0 },
    { value: 2, label: "ประวัติการอบรมพนักงาน", cOwner: 1, cStaff: 0 },
    { value: 3, label: "ประวัติการอบรม", cOwner: 0, cStaff: 1 },
    { value: 4, label: "ประวัติการเข้าเรียน", cOwner: 1, cStaff: 1 },
    { value: 5, label: "ผลการทำแบบทดสอบ", cOwner: 1, cStaff: 1 },

  ];

  const [banner, setBanner] = useState([] || undefined);
  const [idHistoryTabs, setIdHistoryTabs] = useState<Number>(1);

  const HistoryRegis = () => {
    return (
      <HistoryRegisterCourse />
    );
  };

  const HistoryTrain = () => {
    return (
      <Grid item xs={12}>
        <HistoryTrainEmp />
      </Grid>
    );
  };

  const HistoryAttend = () => {
    return (
      <Grid item xs={12}>
        <HistoryLearning />
      </Grid>
    );
  };

  const Tab_HistoryTrainUserLogin = () => {
    return (
      <Grid item xs={12}>
        {<HistoryTrainUserLogin />}
      </Grid>
    );
  };

  const HistoryResult = () => {
    return (
      <Grid item xs={12}>
        <HistoryListExamResult />
      </Grid>
    );
  };

  const onPageLoad = async () => {

    await Axios("Master/onPageLoad", {}, "GET", async (result) => {
      if (result.IsStaff) {
        setShowTabStaff(false);
        setIdHistoryTabs(3);
      } else {
        setShowTabStaff(true);
        setIdHistoryTabs(1);
      }
    })
  }
  useEffect(() => {
    onPageLoad();
  }, [])
  return (
    <Grid container>
      {/* <Grid container sx={{ mt: { xs: "2em" }, width: "90vw", minHeight: "60vh", mb: "3opx" }}> */}
      <Grid item xs={12}>
        <BreadcrumbsUI
          isShowHome={false}
          items={[
            {
              Key: "1",
              menuName: " ประวัติการอบรม/Training History",
              IsOnClick: false,
            },
          ]}
        />
      </Grid>
      <Grid item xs={12} sx={{ marginTop: "10px", padding: "15px" }}>
        <Grid container
          style={{
            display: "flex",
            flexDirection: "row",
            // justifyContent: "space-around",
          }}
          sx={{ mx: {lg:"1.5em"} }}
          >
          {lstTabsHistory.map((item) =>
            ((isOwner && item.cOwner === 1) || (!isOwner && item.cStaff === 1)) &&
            (
              <Grid item xs={12} sm={3} lg={2}>
                <Card
                  sx={{
                    // width: "15%",
                    height: "50px",
                    cursor: "pointer",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    bgcolor: idHistoryTabs == item.value ? "#0a286c" : "#fff",
                    color: idHistoryTabs == item.value ? "#fff" : "#0a286c",
                    m: {xs:"0.5em 1em",sm:"0.5em 0.5em"},
                    //fix code
                  }}
                  onClick={() => setIdHistoryTabs(item.value)}>
                  <Typography align="center" variant={"body2"}>
                    {item.label}
                  </Typography>
                </Card>
              </Grid>
            )
          )}
        </Grid>
        <Grid item xs={12} sx={{ padding: "15px" }} >
          {
            idHistoryTabs === 1 ? <HistoryRegis />
              : idHistoryTabs === 2 ? <HistoryTrain />
                : idHistoryTabs === 3 ? <Tab_HistoryTrainUserLogin />
                  : idHistoryTabs === 4 ? <HistoryAttend />
                    : <HistoryResult />
          }
        </Grid>
      </Grid>
    </Grid>
  );
};

export default TradingHistory;
