import { useState, useEffect } from "react";
import * as React from "react";
import { Autocomplete, TextField, Box, Chip, Typography, FormHelperText, Grid, IconButton } from '@mui/material';
import CheckBoxOutlineBlankIcon from "@mui/icons-material/CheckBoxOutlineBlank";
import CheckBoxIcon from "@mui/icons-material/CheckBox";
import { useFormContext, Controller } from 'react-hook-form';
import FormErrors from 'src/Components/FormItem/formErrors';
import { IsNullOrUndefined, ResultAPI } from 'src/CommonFunction/TS_function';
import SearchIcon from '@mui/icons-material/Search';
import axios from "axios"
import qs from "qs";
import { InputForm } from 'src/Components/FormItem/Input'
import OutlinedInput from '@mui/material/OutlinedInput';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import ListItemText from '@mui/material/ListItemText';
import Select, { SelectChangeEvent } from '@mui/material/Select';
import Checkbox from '@mui/material/Checkbox';
import ClearIcon from "@mui/icons-material/Clear";
import InputAdornment from "@mui/material/InputAdornment";

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      // minWidth: 120,
      // marginLeft:"10px",
    },
  },

};

/**
 * Auto Complete 
 * สามารถ เลื่อกได้มากกว่า 1 รายการ
 */
export const SelectForm = (props: SelectForm) => {
  const {
    name,
    multiple = false,
    required,
    label,
    placeholder,
    fullWidth = false,
    disabled,
    externalErrorMessage,
    urlAPI,
    paramUrl,
    isClearable = true,
    limitTag = 3,
    onChange,
    textFilterCont = 3,
    helperText,
    size = "small",
    shrink = undefined,
    showClear = true,
    width,
    options,
    mode,
    defaultValue,
    variant
  } = props;
  const {
    register,
    setValue,
    getValues,
    control,
    watch,
    formState: { errors, isSubmitted, touchedFields },
  } = useFormContext();

  const errorMessage = FormErrors.errorMessage(
    name,
    errors,
    touchedFields,
    isSubmitted,
    externalErrorMessage,
  );
  const handleClearClick = () => {
    setValue(name, undefined);
    setValueSelect(undefined);
    props.onChange && props.onChange(null);
  };
  const [valueSelect, setValueSelect] = useState<any>(name ? name : (mode == "multiple" ? [] : undefined))

  useEffect(() => {
    if (name) {
      setValueSelect(getValues(name));

    }
  }, [name]);
  const handleClick = () => {
    setValue(name, undefined);
    setValueSelect(undefined);
    props.onChange && props.onChange(null);

  };
  const handleChange = (event) => {
    setValue(name, event.target.value);
    props.onChange && props.onChange(event.target.value);
    setValueSelect(getValues(name));
  };

  return (
    <Controller
      {...register(name)}
      control={control}
      name={name}
      rules={{ required: required }}
      render={({ field }) => (
        <FormControl
          sx={{
            m: 1,
            width: width,
          }}

          fullWidth={fullWidth}
          size={size || "small"}
          variant={variant || "outlined"}
        >
          <InputLabel
            htmlFor="input"
            sx={{
              "::after": {
                content: required ? '" * "' : '""',
                color: "#d32f2f",
                fontWeight: 500,
              },
              color: errors && errors[name] ? "#d32f2f!important" : "",
            }}
          >
            {label}
          </InputLabel>
          <Select
            {...field}
            defaultValue={defaultValue}
            labelId="demo-multiple-checkbox-label"
            multiple={multiple}
            value={watch(name) || defaultValue}
            // onChange={handleChange}
            onChange={(e) => {
              // field.onChange(e.target.value);
              // setValueSelect(e.target.value);
              if (e.target.value) {
                handleChange(e);
                field.onChange(e.target.value);
                setValueSelect(e.target.value);
              } else {
                handleClick();
              }
            }}
            onBlur={() => {
              field.onBlur();
              props.onBlur && props.onBlur();
            }}
            input={<OutlinedInput label={label + "  * "} />}
            renderValue={
              getValues(name)
                ? multiple
                  ? (selected) => (selected || []).join(", ")
                  : undefined
                : undefined
            }
            MenuProps={MenuProps}
            displayEmpty
            error={!!Boolean(errorMessage)}
            size={size || "small"}
            //value={valueSelect || ""}
            // renderValue={getValues(name) ? (selected) => (
            //     <Box sx={{ display: "flex", flexWrap: "wrap", gap: 0.5 }}>
            //       {multiple
            //         ? selected.map((value) => <Chip key={value} label={value} />)
            //         : selected.map((value) => value)}
            //     </Box>
            //   ) : undefined}
            endAdornment={
              <InputAdornment position="end">
                {showClear && (
                  <IconButton
                    onClick={(e) => {
                      field.onChange(e);
                      handleClearClick();
                    }}
                    sx={[{ display: watch(name) ? "" : "none" }]}
                  >
                    <ClearIcon />
                  </IconButton>
                )}
              </InputAdornment>
            }
            sx={variant == "filled" ?
              [
                { textAlign: "initial" },
                { m: 0 },

                {
                  // ".MuiListItemText-root": {
                  //   paddingTop: "10px"
                  // },
                  root: {
                    ": MuiListItemText": {
                      root: { marginTop: "0px" },
                    },
                  },
                  ".MuiOutlinedInput-input": {
                    paddingTop: "15px !important",
                    paddingBottom: "5px !important"
                  }
                },

              ]
              :
              [
                { textAlign: "initial" },
                { m: 0 },

                {                
                  root: {
                    ": MuiListItemText": {
                      root: { marginTop: "0px" },
                    },
                  },
                },

              ]
            }
          // sx={[
          //   { textAlign: "initial" },
          //   { m: 0 },

          //   {
          //     ".MuiListItemText-root": {
          //       paddingTop: "10px"
          //     },                            
          //     root: {
          //       ": MuiListItemText": {
          //         root: { marginTop: "0px" },
          //       },
          //     },
          //   },            

          // ]}
          >
            {((options || []).length > 0 ? options : []).map((el) => (
              <MenuItem key={el.value} value={el.value}>
                {multiple && (
                  <Checkbox
                    checked={getValues(el.value).indexOf(el.value) > -1}
                  />
                )}
                <ListItemText primary={el.label} />
              </MenuItem>
            ))}
          </Select>
          {!!Boolean(errorMessage) && (
            <FormHelperText
              sx={{
                color: "#d32f2f",
              }}
            >
              {errorMessage}
            </FormHelperText>
          )}
        </FormControl>
      )}
    />
  );
}

interface SelectForm extends InputForm {
  /**
   * ถ้าเป็นจริงจะเลือกได้มากว่า 1
   */
  multiple?: boolean;
  /**
   * API ที่ต้องการไปดึงข้อมูล
   */
  urlAPI?: string;
  /**
   * parameter ที่ต้องการส่งไปเพิ่ม
   */
  paramUrl?: any;
  /**
   * ถ้าเป็นจริงจะแสดง ปุ่ม Clear
   */
  showClear?: boolean;
  isClearable?: boolean;
  /**
    * ถ้าเป็น multiple 
    * limitTags คือจำนวนรายการที่สามารถเลือกได้
    * default 3
    */
  limitTag?: any;
  /**
   * จำนวนตัวอักษรที่จะไปต้องการค้นหา
   * default 3
   */
  textFilterCont?: number;
  /**
  * ระบุความกว้าง
  */
  width?: any;
  options?: any;
  mode?: "multiple" | "tags";
  defaultValue?: any;
  variant?: any;
  onBlur?: () => void;
  onChange?: (e) => void;
}

export default SelectForm;
