import * as React from "react";
import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import FormControl from "@mui/material/FormControl";
import FormLabel from "@mui/material/FormLabel";
import { useEffect } from "react";

export function RadioUI(props: RadioUI) {
  const { name ,row,label,defaultValue,option} = props;
  const [value, setValue] = React.useState("");

  useEffect(() => {
    setValue(props.defaultValue);
  }, [props.defaultValue]);

  return (
    <>
      <FormControl>
        <FormLabel id="demo-radio-buttons-group-label">{label}</FormLabel>
        <RadioGroup name={name} row={row} defaultValue={defaultValue || ""}>
          {(option || []).map((m) => (
            <FormControlLabel
              value={m.values}
              key={m.values}
              control={
                <Radio
                  onChange={(e) => {
                    setValue(e.target.value);
                    props.onChange && props.onChange(e.target.value);
                  }}
                />
              }
              label={m.label}
            />
          ))}
        </RadioGroup>
      </FormControl>
    </>
  );
}

interface RadioUI {
  name: string;
  label?: string;
  row?: boolean;
  option?:any[];
  defaultValue?: any;
  onChange?: (value: any) => void;
}