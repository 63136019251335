import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { FormProvider, useForm } from "react-hook-form";
import yupFormSchemas from "../FormItem/yup/yupFormSchemas";
import { Axios } from "src/CommonFunction/TS_Axios";

import { useEffect, useState } from "react";
import { useNavigate } from "react-router";
import { FnBlock_UI } from "../BlockUI";

import { Checkbox, FormControlLabel, Grid, IconButton, Typography } from "@mui/material";
import { BreadcrumbsUI } from "../BreadcrumbsUI";
import { BtnBackRegister, BtnCancelForm, BtnCustomText, BtnSaveForm } from "../Button";
import { lnkToHomePage, Process_System, ResultAPI } from "src/CommonFunction/TS_function";
import { AlertMsg, AlertTitle, DialogConfirm, DialogConfirmDelete, SwAlert } from "src/Components/Alert/Sweetalert";
import { InputForm } from "../FormItem/Input";
import Cards from "../CardHeader/Cards";
import AutoCompleteSelect from "../FormItem/AutoCompleteSelect";
import { styled } from '@mui/material/styles';
import { FaQuestionCircle } from "react-icons/fa";
import Tooltip, { TooltipProps, tooltipClasses } from '@mui/material/Tooltip';
import InputNumberForm from "../FormItem/InputNumber";
import HaftHeaderCards from "../CardHeader/HaftHeaderCards";
import RadioFormItem from "../FormItem/RadioFormItem/RadioIFormtem";
import UploadFile from "src/Components/ElementInForm/UploadFile/UploadFile";
import { Extension } from "src/CommonFunction/TS_function";
import { i18n } from "src/i18n";


const AccountEdit = () => {

    var Regex5 = /^\d{5}$/;
    var Regex10 = /^\d{10}$/;
    var Regex13 = /^\d{13}$/;
    var Regex20 = /^\d{20}$/;
    var Regex9 = /^[a-zA-Z0-9]{9}$/;
    const navigate = useNavigate();
    const { BlockUI, UnBlockUI } = FnBlock_UI();
    const searchParams = location.search;
    const sIDADD = new URLSearchParams(searchParams).get('sID');
    const [arrOptionVendor, setarrOptionVendor] = useState([] as any)
    const [arrOptionPrefix, setarrOptionPrefix] = useState([] as any)
    const [isEditMode, setisEditMode] = useState(false)
    const [sNameTitle, setsNameTitle] = useState("");
    const [isHeadOffice, setisHeadOffice] = useState(false)
    const [isWaitDealerCode, setisWaitDealerCode] = useState(false)
    const [chkWaitDealerCode, setchkWaitDealerCode] = useState(false)
    const [isShowBtnSaveChange, setShowBtnSaveChange] = useState(false)
    const [lstData, setlstData] = useState({} as any)
    const [sBusinessGroup, setsBusinessGroup] = useState("");
    const [sTypeID, setTypeID] = useState("citizenID");
    const [arrFile, setArrFile] = useState<any[]>([]);

    // svae / check
    const [sID, setsID] = useState("")
    const [sUserID, setsUserID] = useState("")
    const [sContactID, setsContactID] = useState("")
    const [isStaff, setnisStaff] = useState(null);
    const [nUserType, setnUserType] = useState(0);
    const [nUserTypeID, setnUserTypeID] = useState(0);
    const [sMode, setsMode] = useState("ADD");
    const [objSchema, setobjSchema] = useState({} as any);
    const [objGlobal] = useState({} as any);
    const [isContactPoint, setisContactPoint] = useState(false)


    const NoMaxWidthTooltip = styled(({ className, ...props }: TooltipProps) => (
        <Tooltip {...props} classes={{ popper: className }} />
    ))({
        [`& .${tooltipClasses.tooltip}`]: {
            maxWidth: 'none',
        },
    });

    const schema = yup.object().shape(objSchema);

    const valiDateFrom = (isStaff, nUserType, isHeadOffice, chkWaitDealerCode) => {
        //#region Schema
        let obj = objGlobal;
        if (isStaff) {
            //เป็น staff

            obj = {
                // ไม่ส่งไปsave
                sSatffUserName: yupFormSchemas.string(""),

                // Company 4
                sSatffEmployeeCode: yupFormSchemas.string("รหัสพนักงาน/Emplyee Code ", { required: nUserTypeID == 4 ? true : false, matches: Regex10, labelmatches: "รหัสพนักงาน/Emplyee Code ไม่ถูกต้อง" }),
                ssSatffOfficeName: yupFormSchemas.string("สถานที่ปฏิบัติงาน", { required: nUserTypeID == 4 ? true : false }),
                ssSatffUnitName: yupFormSchemas.string("หน่วยงานที่ปฏิบัติงาน", { required: nUserTypeID == 4 ? true : false }),
                ///ใช้รวม
                sSatffPrefixTH: yupFormSchemas.object("คำนำหน้า (ภาษาไทย)/Prefix (TH)", { required: true }),
                sSatffNameTH: yupFormSchemas.string("ชื่อ - นามสกุล (ภาษาไทย)/Name-Surname (TH) ", { required: true }),
                sSatffPrefixEN: yupFormSchemas.object("คำนำหน้า (ภาษาอังกฤษ)/Prefix (EN)", { required: true }),
                sSatffNameEN: yupFormSchemas.string("ชื่อ - นามสกุล (ภาษาอังกฤษ)/Name-Surname (EN) ", { required: true }),
                sIdentityID: yupFormSchemas.string("เลขที่บัตรประชาชน / Citizen ID ", { required: sTypeID == "citizenID" ? true : false, matches: Regex13, labelmatches: "เลขที่บัตรประชาชน / Citizen ID ไม่ถูกต้อง" }),
                sIdentityPassID: yupFormSchemas.string("เลขที่หนังสือเดินทาง / Passport ID ", { required: sTypeID == "passportID" ? true : false, matches: Regex9, labelmatches: "เลขที่หนังสือเดินทาง / Passport ID ไม่ถูกต้อง" }),
                sSatffGender: yupFormSchemas.string("เพศ/Gender", { required: true }),
                ssSatffPosition: yupFormSchemas.string("ตำแหน่ง", { required: true }),
                sStaffTel: yupFormSchemas.string("เบอร์โทรศัพท์/Telephone ", { required: true, min: 9 }),
                sStaffEmail: yupFormSchemas.email("อีเมล/E-mail ", { required: true })
            }
        }
        else {
            //ไม่เป็น staf
            if (nUserType == 3) {
                //เป็น Dealer
                obj = {
                    ...objGlobal,
                    sUserName: yupFormSchemas.string(""),
                    sName: yupFormSchemas.string("ชื่อบริษัท / Company Name ", { required: true }),
                    TaxID: yupFormSchemas.string("เลขประจำตัวผู้เสียภาษี /Taxpayer Identification No. ", { required: true }),
                    // Dealer 2
                    sDealerCode: yupFormSchemas.integer("หมายเลขผู้แทนจำหน่าย / Ship To / Sold To ", { required: nUserTypeID == 2 && !chkWaitDealerCode ? true : false }),
                    sBranch: yupFormSchemas.string("รหัสสาขา/Branch No.", { required: (nUserTypeID == 2 && !isHeadOffice) ? true : false, matches: Regex5, labelmatches: "รหัสสาขา/Branch No. ไม่ถูกต้อง" }),

                    //vender 5
                    sBusinessGroup: yupFormSchemas.object("กลุ่มธุรกิจ/Bussiness ", { required: nUserTypeID == 5 ? true : false }),
                    sTel: yupFormSchemas.string("เบอร์โทรศัพท์/Telephone ", { required: true, min: 9 }),
                    sEmail: yupFormSchemas.email("อีเมล/E-mail ", { required: false }),
                    sAddress: yupFormSchemas.string("ที่อยู่ / Address (ต้องลงท้ายด้วยรหัสไปรษณีย์) ",{ required: true, min: 40 ,labelmin: "ที่อยู่ / Address (ต้องลงท้ายด้วยรหัสไปรษณีย์) ต้องมีความยาวรวมไม่น้อยกว่า 40 ตัวอักษร"}),
                    //Contact
                    sContactPrefixTH: yupFormSchemas.object("คำนำหน้า/Prefix ", { required: true }),
                    sContactUserName: yupFormSchemas.string("ชื่อ - นามสกุล / Name - Surname ", { required: true }),
                    sContactPersonalID: yupFormSchemas.string("เลขประจำตัวประชาชน ", { required: true, matches: Regex13, labelmatches: "เลขประจำตัวประชาชน/Citizen ID ไม่ถูกต้อง" }),
                    sContactPosition: yupFormSchemas.string("ตำแหน่ง ", { required: true }),
                    sContactTel: yupFormSchemas.string("เบอร์โทรศัพท์/Telephone ", { required: true, min: 9 }),
                    sContactEmail: yupFormSchemas.email("อีเมล/E-mail ", { required: true }),
                }
            }
            else {
                //เป็น Member
                obj = {
                    ...objGlobal,
                    sNameTH: yupFormSchemas.string("ชื่อ - นามสกุล ภาษาไทย / Name - Surname (TH) ", { required: true }),
                    sNameEN: yupFormSchemas.string("ชื่อ - นามสกุล ภาษาอังกฤษ / Name - Surname (EN) ", { required: true }),
                    sCitizenID: yupFormSchemas.string("ตำแหน่ง ", { required: true, matches: Regex13, labelmatches: "เลขประจำตัวประชาชน/Citizen ID ไม่ถูกต้อง" }),
                    sTel: yupFormSchemas.string("เบอร์โทรศัพท์/Telephone ", { required: true, min: 9 }),
                    sEmail: yupFormSchemas.email("อีเมล/E-mail ", { required: true }),
                    sAddress: yupFormSchemas.string("ที่อยู่ / Address (ต้องลงท้ายด้วยรหัสไปรษณีย์) ",{ required: true, min: 40 ,labelmin: "ที่อยู่ / Address (ต้องลงท้ายด้วยรหัสไปรษณีย์) ต้องมีความยาวรวมไม่น้อยกว่า 40 ตัวอักษร"}),

                }
            }
        }
        //#endregion  Schema
        setobjSchema(obj);
    }

    const [initialValues] = useState(() => {
        valiDateFrom(isStaff, 0, false, false);

    });

    const form = useForm({
        resolver: yupResolver(schema),
        mode: 'all',
        shouldFocusError: true,
        criteriaMode: "firstError",
        defaultValues: initialValues as any,
    });

    useEffect(() => {
        getUserData()
    }, [])


    useEffect(() => {
        GetDataInitial();
        setsMode("Edit")
    }, [])

    //#region Prefix
    const arrPrefixTH = [
        { "value": "0", "label": "คุณ" },
        { "value": "1", "label": "นาย" },
        { "value": "2", "label": "นาง" },
        { "value": "3", "label": "น.ส." }
    ]

    const arrPrefixEN = [
        { "value": "1", "label": "Mr." },
        { "value": "2", "label": "Mrs." },
        { "value": "3", "label": "Ms." },
    ]

    const arrOptionsTypeID = [
        { "value": "citizenID", "label": "เลขที่บัตรประชาชน / Citizen ID" },
        { "value": "passportID", "label": "เลขที่หนังสือเดินทาง / Passport ID" },
    ]

    const arrOptionsGender = [
        { "value": "M", "label": "ชาย" },
        { "value": "F", "label": "หญิง" },
    ]

    const GetDataInitial = async () => {
        let option = [] as any;
        let lstVendor = [] as any;
        let lstPrefix = [] as any;
        await Axios(
            `ProfilePage/SetFilter_Prefix`, {}, "POST", async (res) => {
                ResultAPI(true, res, null, () => {
                    lstPrefix = res.lstPrefix
                    setarrOptionPrefix(res.lstPrefix)
                })
            },
            null,
            BlockUI,
            UnBlockUI,
            null
        );

        await Axios(`AccountPage/SetFilter_Vendor`, {}, "POST", async (res) => {
            ResultAPI(true, res, null, () => {
                lstVendor = res.lstVendor
                setarrOptionVendor(lstVendor)

            })
        },
            null,
            BlockUI,
            UnBlockUI,
            null
        );
        loadData(lstPrefix, lstVendor)
    };
    //#endregion Prefix

    const getUserData = () => {
        Axios("Master/onPageLoad", {}, "GET", async (result) => {
            if (result.IsExpired) {
                SwAlert.Warning(undefined, i18n("common.SessionTimeOut"), lnkToHomePage);
            }
            // else if (!result.nUserTypeID) {
            //     //navigate('/login')
            //     //navigate(process.env.REACT_APP_API_URL + `home`);
            //     SwAlert.Warning(undefined, i18n("common.NoPermission"), lnkToHomePage);
            // }
        })

    }

    const loadData = async (lstPrefix, lstVendor) => {
        await Axios("AccountPage/GetData_AccountPage", {}, "POST", async (res) => {
            ResultAPI(true, res, null, () => {
                console.log("res", res)
                if (res.Status == Process_System.process_Success) {
                    let resData = res.objAccountDetail;
                    let resContact = resData.oContactDetail;
                    let resSatff = resData.oStaffDetail;
                    let lstFile = resData.lstFile;
                    let find = lstVendor.find(f => f.value == resData.sBusinessGroup)
                    let isStaff = resData.isStaff
                    let isWaitDealerCode = resData.isWaitDealerCode
                    console.log("resData", resData)
                    console.log("isStaff", isStaff)

                    //#region setState
                    //setState
                    setlstData(resData)
                    setnisStaff(isStaff);
                    setsNameTitle(resData.sNameTitle);
                    setisHeadOffice(resData.isHeadOffice);
                    setisWaitDealerCode(isWaitDealerCode);
                    setchkWaitDealerCode(isWaitDealerCode);
                    setsBusinessGroup(find ? find : "-")

                    //ID
                    setsUserID(resData.sUserID)
                    setnUserType(resData.nUserType);
                    setnUserTypeID(resData.nUserTypeID);
                    setsID(isStaff == false ? resData.sUserID : resSatff.sID)
                    setsContactID(isStaff == false ? (resData.nUserType == 3 ? (resContact != null ? resContact.sContactID : "") : "") : "")

                    //#endregion setState
                    /// file
                    if (lstFile != null) {
                        if (lstFile.length > 0)
                            setArrFile([...lstFile]);
                    }

                    //#region SetFrom
                    //Dealer
                    form.setValue("sUserName", resData.sUserName);
                    form.setValue("sName", resData.sName);
                    form.setValue("sDealerCode", resData.sDealerCode);
                    form.setValue("TaxID", resData.TaxID);
                    form.setValue("sBusinessGroup", find ? find : null);
                    form.setValue("sBranch", resData.sBranch);

                    // Member
                    form.setValue("sNameTH", resData.sNameTH);
                    form.setValue("sNameEN", resData.sNameEN);
                    form.setValue("sCitizenID", resData.TaxID);

                    // Dealer + Member
                    form.setValue("sTel", resData.sTel);
                    form.setValue("sEmail", resData.sEmail);
                    form.setValue("sAddress", resData.sAddress);

                    //Contact
                    if (resContact != null) {
                        let Prefix = lstPrefix.find(f => f.label == resContact.sContactPrefixTH)
                        setisContactPoint(resContact.isContactPoint)
                        form.setValue("sContactPrefixTH", Prefix ? Prefix : null);
                        form.setValue("sContactUserName", resContact.sContactUserName);
                        form.setValue("sContactPersonalID", resContact.sContactPersonalID);
                        form.setValue("sContactPosition", resContact.sContactPosition);
                        form.setValue("sContactTel", resContact.sContactTel);
                        form.setValue("sContactEmail", resContact.sContactEmail);

                    }
                    //Satff
                    if (resSatff != null) {
                        let prefixTH = arrPrefixTH.find(f => f.label == resSatff.sPrefixTH)
                        let prefixEN = arrPrefixEN.find(f => f.label == resSatff.sPrefixEN)
                        let rdTypeID = resSatff.sPersonalID ? "citizenID" : "passportID"
                        setTypeID(rdTypeID)

                        form.setValue("sSatffUserName", resSatff.sSatffUserName);
                        form.setValue("sSatffEmployeeCode", resSatff.sEmployeeCode);
                        form.setValue("sSatffPrefixEN", prefixEN ? prefixEN : null);
                        form.setValue("sSatffPrefixTH", prefixTH ? prefixTH : null);
                        form.setValue("sSatffNameTH", resSatff.sNameTH);
                        form.setValue("sSatffNameEN", resSatff.sNameEN);
                        form.setValue("sTypeID", rdTypeID)
                        form.setValue("sIdentityID", resSatff.sPersonalID)
                        form.setValue("sIdentityPassID", resSatff.sPassportID)
                        form.setValue("sSatffGender", resSatff.cGender)
                        form.setValue("ssSatffPosition", resSatff.sPosition)
                        form.setValue("ssSatffOfficeName", resSatff.sOfficeName)
                        form.setValue("ssSatffUnitName", resSatff.sUnitName)
                        form.setValue("sStaffTel", resSatff.sStaffTel);
                        form.setValue("sStaffEmail", resSatff.sStaffEmail);

                    }
                    //#endregion SetFrom
                    valiDateFrom(isStaff, resData.nUserType, resData.isHeadOffice, chkWaitDealerCode)
                }
            })

        }, null, BlockUI, UnBlockUI, null)
    };

    const onEdit = () => {
        setisEditMode(true)
        setShowBtnSaveChange(true);
    };

    const GoToList = () => {
        location.reload();
    };

    const onChangePrefix = (e, type) => {
        if (e) {
            if (e.value == 1) {
                form.setValue("sSatffGender", "M")
            } else if (e.value == 2 || e.value == 3) {
                form.setValue("sSatffGender", "F")
            }
            if (type == 'en') {

                var objPrefix = arrPrefixTH.find(f => f.value == e.value);
                if (form.getValues("sSatffPrefixTH") == null) {
                    if (objPrefix) {
                        form.setValue("sSatffPrefixTH", objPrefix)
                    }
                } else if (form.getValues("sSatffPrefixTH").value != 0) {

                    if (objPrefix) {
                        form.setValue("sSatffPrefixTH", objPrefix)
                    }
                }
            } else {
                var objPrefix = arrPrefixEN.find(f => f.value == e.value);
                if (form.getValues("sSatffPrefixEN") == null) {
                    if (objPrefix) {
                        form.setValue("sSatffPrefixEN", objPrefix)
                    }
                } else if (form.getValues("sSatffPrefixEN").value != 0) {

                    if (objPrefix) {
                        form.setValue("sSatffPrefixEN", objPrefix)
                    }
                }
            }
        }
    };

    const setValuepersonID = (event) => { setTypeID(event) };

    const SaveData = async () => {
        let oForm = form.getValues();
        let sAddresss =  oForm.sAddress;
        var sAddress = sAddresss.trim();
        var arrAddress = sAddress.split(' ');
        var sPostalCode = arrAddress[arrAddress.length - 1];
        if (sPostalCode.length == 5) {
            let Param = {}
            if (isStaff) {

                Param = {
                    sUserID: sUserID,
                    sContactID: sContactID,
                    isStaff: isStaff,
                    nUserType: nUserType,
                    nUserTypeID: nUserTypeID,
                    sMode: sMode,
                    sID: sID,
                    //nUserTypeID 4  Com
                    sSatffEmployeeCode: oForm.sSatffEmployeeCode,
                    sOfficeName: oForm.ssSatffOfficeName,
                    sUnitName: oForm.ssSatffUnitName,

                    //ใช้รวม
                    sPrefixTH: oForm.sSatffPrefixTH.label,
                    sNameTH: oForm.sSatffNameTH,
                    sPrefixEN: oForm.sSatffPrefixEN.label,
                    sNameEN: oForm.sSatffNameEN,
                    sPersonalID: oForm.sIdentityID,
                    sPassportID: oForm.sIdentityPassID,
                    cGender: oForm.sSatffGender,
                    sPosition: oForm.ssSatffPosition,
                    sStaffTel: oForm.sStaffTel,
                    sStaffEmail: oForm.sStaffEmail,
                    rdlStaffID: sTypeID == "citizenID" ? "C" : "P"
                }
            }
            else {
                if (nUserType == 3) {
                    Param = {
                        sUserID: sUserID,
                        sContactID: sContactID,
                        isStaff: isStaff,
                        nUserType: nUserType,
                        nUserTypeID: nUserTypeID,
                        sMode: sMode,
                        sID: sID,
                        // Dealer 2
                        sDealerCodes: oForm.sDealerCode,
                        isWaitDealerCode: chkWaitDealerCode,
                        sBranchs: oForm.sBranch,
                        isHeadOffices: isHeadOffice,

                        //vender 5
                        nBusinessID: !oForm.sBusinessGroup ? null : oForm.sBusinessGroup.value,

                        //ตัวกลาง 
                        sNames: oForm.sName,
                        sTaxID: oForm.TaxID,
                        sTels: oForm.sTel,
                        sEmails: oForm.sEmail,
                        sAddresss: oForm.sAddress,
                        lstFileSave: arrFile,
                        //Contact
                        sContactPrefixTH: oForm.sContactPrefixTH.label,
                        sContactUserName: oForm.sContactUserName,
                        sContactPersonalID: oForm.sContactPersonalID,
                        sContactPosition: oForm.sContactPosition,
                        sContactTel: oForm.sContactTel,
                        sContactEmail: oForm.sContactEmail,

                    }
                }
                else {
                    //Member
                    Param = {
                        sUserID: sUserID,
                        sContactID: sContactID,
                        isStaff: isStaff,
                        nUserType: nUserType,
                        nUserTypeID: nUserTypeID,
                        sMode: sMode,
                        sID: sID,
                        sNameTH: oForm.sNameTH,
                        sNameEN: oForm.sNameEN,
                        sTaxID: oForm.sCitizenID,
                        sTels: oForm.sTel,
                        sEmails: oForm.sEmail,
                        sAddresss: oForm.sAddress,
                        lstFileSave: arrFile
                    }
                }
            }
            DialogConfirm(() => {
                Axios(`AccountPage/SaveData_AccountPageDetail`, Param, "POST", async (res) => {
                    ResultAPI(true, res, res.Message, () => {
                        if (res.Status == Process_System.process_Success) {
                            SwAlert.Success(AlertTitle.Success, AlertMsg.SaveComplete, () => {
                                navigate(process.env.REACT_APP_API_URL + "AccountEdit")
                                setisEditMode(false)
                                setShowBtnSaveChange(false);
                            });
                        }
                        else {
                            SwAlert.Warning(AlertTitle.Warning, res.Message, () => { });
                        }
                    })
                },
                    null,
                    BlockUI,
                    UnBlockUI,
                    null
                );
            })
        }
        else {
            SwAlert.Warning(AlertTitle.Warning, "โปรดระบุ <b>ที่อยู่</b> โดยลงท้ายด้วยชื่อจังหวัดและรหัสไปรษณีย์ (เช่น กรุงเทพมหานคร 10400)");
        }
    };

    return (
        <FormProvider {...form}>
            <Grid container>

                {/* BreadcrumbsUI*/}
                <Grid item xs={12}>
                    <BreadcrumbsUI
                        isShowHome={false}
                        items={[
                            {
                                menuName: "จัดการข้อมูลผู้ใช้งาน/Manage User",
                                path: process.env.REACT_APP_API_URL + "AccountEdit",
                                IsOnClick: false
                            },
                        ]}
                    />
                </Grid>

                {/* From InPut */}
                <Grid item xs={12}>
                    <Grid container spacing={2} direction="row" justifyContent="center" alignItems="start">
                        <Grid item xs={12} md={10}>

                            {/* checkStaff */}
                            {isStaff != true &&
                                <Grid item xs={12} flex={1} marginX={2} style={{ padding: '24px 8px' }}>
                                    <Grid item container xs={12} justifyContent={"flex-end"}>
                                        <BtnCustomText txt={"แก้ไขข้อมูล/Edit Info"}
                                            backgroundColor={"#122768"}
                                            width={"13em !important"}
                                            mx={"8px"}
                                            onClick={() => onEdit()}
                                        />
                                    </Grid>

                                    {/* Div Dealer / Member */}
                                    <Grid item container xs={12} justifyContent={"center"} alignItems="start" direction="row" >
                                        <Grid item container xs={12} justifyContent={"center"} alignItems="start" direction="row" >
                                            <Cards skey={"oCard1"} sTitle={sNameTitle} sColor={"#122768"}>
                                                <Grid container spacing={2} direction="row" alignItems="start">
                                                    <Grid item xs={12}>
                                                        <Grid item container spacing={2} direction="row" alignItems="start" >
                                                            <Grid item xs={12} md={5} >
                                                                <InputForm
                                                                    fullWidth
                                                                    name={"sUserName"}
                                                                    label="ชื่อผู้ใช้งาน/Username "
                                                                    showClear={false}
                                                                    disabled={true}
                                                                />
                                                            </Grid>
                                                        </Grid>
                                                    </Grid>

                                                    {/* Div Dealer */}
                                                    {nUserType == 3 &&
                                                        <Grid item container xs={12} justifyContent={"center"} alignItems="start" direction="row" >
                                                            {/* Div isWaitDealerCode */}
                                                            {isWaitDealerCode == true &&
                                                                <Grid item xs={12}>
                                                                    <Grid item container spacing={2} direction="row" alignItems="start" sx={{ paddingLeft: "25px" }}>
                                                                        <FormControlLabel
                                                                            control={
                                                                                <Checkbox
                                                                                    name={"chkWaitDealerCode"}
                                                                                    disabled={isEditMode == false}
                                                                                    checked={chkWaitDealerCode}
                                                                                    onChange={(event) => {
                                                                                        setchkWaitDealerCode(event.target.checked);
                                                                                        valiDateFrom(isStaff, nUserType, isHeadOffice, event.target.checked)
                                                                                        form.setValue("sBranch", "00000");
                                                                                    }}
                                                                                />
                                                                            }
                                                                            label={
                                                                                <>
                                                                                    <Typography>
                                                                                        กำลังรอหมายเลขผู้แทนจำหน่าย/ Waiting for Ship To / Sold To
                                                                                    </Typography>
                                                                                </>
                                                                            }
                                                                        />
                                                                    </Grid>
                                                                </Grid>
                                                            }

                                                            {/* Div TypeDealer */}
                                                            {nUserTypeID == 2 &&
                                                                <Grid item xs={12}>
                                                                    <Grid item container xs={12} alignItems="start" direction="row" spacing={2}  >
                                                                        <Grid item xs={10} md={4}>
                                                                            <InputForm
                                                                                fullWidth
                                                                                //disabled={chkWaitDealerCode == true || isEditMode == false}
                                                                                disabled={!isWaitDealerCode}
                                                                                required={!chkWaitDealerCode}
                                                                                name={"sDealerCode"}
                                                                                label="หมายเลขผู้แทนจำหน่าย / Ship To / Sold To "
                                                                                showClear={false}
                                                                                maxLength={10}
                                                                            />
                                                                        </Grid>

                                                                        <Grid item xs={2} md={6} sx={{ marginTop: 1, paddingLeft: "10px" }}  >
                                                                            <NoMaxWidthTooltip title={"หมายเลขผู้แทนจำหน่าย (Ship To/Sold To) ท่านจะได้รับเมื่อท่านจดทะเบียนผู้ค้ากับ ปตท. แล้วเรียบร้อย"}>
                                                                                <IconButton
                                                                                    sx={{ color: "#3997df", cursor: "unset !important" }}
                                                                                    disableRipple={true}
                                                                                >
                                                                                    <FaQuestionCircle />
                                                                                </IconButton>
                                                                            </NoMaxWidthTooltip>
                                                                        </Grid>

                                                                    </Grid>
                                                                </Grid>
                                                            }

                                                            <Grid item xs={12} sx={{ marginTop: 2 }}>
                                                                <InputForm
                                                                    disabled={isEditMode == false}
                                                                    fullWidth
                                                                    required={true}
                                                                    name={"sName"}
                                                                    label={nUserTypeID == 2 ? " ชื่อสถานประกอบการ/Trade Name " : "ชื่อบริษัท / Company Name"}
                                                                    showClear={false}
                                                                    maxLength={255}
                                                                />
                                                            </Grid>

                                                            <Grid item xs={12} sx={{ marginTop: 2 }} >
                                                                <Grid item container xs={12} alignItems="start" direction="row" spacing={2} >
                                                                    <Grid item xs={12} md={6}>
                                                                        <Grid item xs={12} md={8}>
                                                                            <InputNumberForm
                                                                                disabled={isEditMode == false}
                                                                                name="TaxID"
                                                                                label="เลขประจำตัวผู้เสียภาษี /Taxpayer Identification No. "
                                                                                type={"number"}
                                                                                required={true}
                                                                                allowMinus={false}
                                                                                fullWidth
                                                                                digits={0}
                                                                                maxLength={20}
                                                                                integerDigits={20}
                                                                                textAlign={"left"}
                                                                                thousandSeparator={false}
                                                                                allowLeadingZeros={true}

                                                                            />
                                                                        </Grid>
                                                                    </Grid>

                                                                    {/* Div TypeVendor */}
                                                                    {nUserTypeID == 5 &&
                                                                        <Grid item xs={12} md={6}>
                                                                            <AutoCompleteSelect
                                                                                disabled={isEditMode == false}
                                                                                name="sBusinessGroup"
                                                                                label="กลุ่มธุรกิจ/Bussiness "
                                                                                required={true}
                                                                                fullWidth={false}
                                                                                width={{ width: "100%" }}
                                                                                options={arrOptionVendor}
                                                                                disableClearable={false}
                                                                                IsShowIcon={true}
                                                                            />
                                                                        </Grid>
                                                                    }

                                                                    {/* Div TypeDealer */}
                                                                    {nUserTypeID == 2 &&
                                                                        <Grid item xs={12} md={6}>
                                                                            <Grid container xs={12} alignItems="start" direction="row"   >
                                                                                <Grid item xs={12} md={6} >
                                                                                    <InputForm
                                                                                        disabled={isHeadOffice == true || isEditMode == false}
                                                                                        fullWidth
                                                                                        required={true}
                                                                                        name={"sBranch"}
                                                                                        label="รหัสสาขา/Branch No. "
                                                                                        showClear={false}
                                                                                        maxLength={5}
                                                                                    />
                                                                                    {/* 
                                                                                    <InputNumberForm
                                                                                        name="sBranch"
                                                                                        label="รหัสสาขา/Branch No. "
                                                                                        integerDigits={5}
                                                                                        allowLeadingZeros={true}
                                                                                        digits={0}
                                                                                        textAlign={"left"}
                                                                                        thousandSeparator={false}
                                                                                        disabled={isHeadOffice == true || isEditMode == false}
                                                                                        fullWidth
                                                                                    /> */}
                                                                                </Grid>

                                                                                <Grid item xs={12} md={6} sx={{ paddingLeft: "12px" }} >
                                                                                    <FormControlLabel
                                                                                        control={
                                                                                            <Checkbox
                                                                                                disabled={isEditMode == false}
                                                                                                checked={isHeadOffice}
                                                                                                onChange={(event) => {
                                                                                                    setisHeadOffice(event.target.checked);
                                                                                                    valiDateFrom(isStaff, nUserType, event.target.checked, chkWaitDealerCode)
                                                                                                    form.setValue("sBranch", (isHeadOffice == false ? "00000" : ""));

                                                                                                }}
                                                                                            />
                                                                                        }
                                                                                        label={<Typography>สํานักงานใหญ่ / Head Office</Typography>}
                                                                                    />
                                                                                </Grid>

                                                                            </Grid>
                                                                        </Grid>
                                                                    }

                                                                </Grid>
                                                            </Grid>

                                                        </Grid>
                                                    }

                                                    {/* Div Member */}
                                                    {nUserType == 4 &&
                                                        <Grid item container xs={12} justifyContent={"center"} alignItems="start" direction="row" spacing={2} >
                                                            <Grid item xs={12}>
                                                                <Grid item container xs={12} alignItems="start" direction="row" spacing={2}  >
                                                                    <Grid item xs={12} md={6} >
                                                                        <InputForm
                                                                            disabled={isEditMode == false}
                                                                            fullWidth
                                                                            required={true}
                                                                            name={"sNameTH"}
                                                                            label="ชื่อ - นามสกุล ภาษาไทย / Name - Surname (TH)  "
                                                                            showClear={false}
                                                                            maxLength={255}
                                                                        />
                                                                    </Grid>

                                                                    <Grid item xs={12} md={6} >
                                                                        <InputForm
                                                                            disabled={isEditMode == false}
                                                                            fullWidth
                                                                            required={true}
                                                                            name={"sNameEN"}
                                                                            label="ชื่อ - นามสกุล ภาษาอังกฤษ / Name - Surname (EN) "
                                                                            showClear={false}
                                                                            maxLength={255}
                                                                        />
                                                                    </Grid>
                                                                </Grid>
                                                            </Grid>

                                                            <Grid item xs={12} >
                                                                <Grid item container xs={12} alignItems="start" direction="row" spacing={2}  >
                                                                    <Grid item xs={12} md={4}>
                                                                        <InputNumberForm
                                                                            disabled={isEditMode == false}
                                                                            name="sCitizenID"
                                                                            label="เลขประจำตัวประชาชน/Citizen ID "
                                                                            type={"number"}
                                                                            required={true}
                                                                            allowMinus={false}
                                                                            fullWidth
                                                                            digits={0}
                                                                            maxLength={13}
                                                                            integerDigits={13}
                                                                            textAlign={"left"}
                                                                            thousandSeparator={false}
                                                                            allowLeadingZeros={true}
                                                                        />
                                                                    </Grid>

                                                                </Grid>
                                                            </Grid>

                                                        </Grid>
                                                    }
                                                    <Grid item xs={12} >
                                                        <Grid item container xs={12} alignItems="start" direction="row" spacing={2} sx={{ marginTop: -3 }} >
                                                            <Grid item xs={12} md={6}>
                                                                <Grid item xs={12} md={8}>
                                                                    <InputForm
                                                                        disabled={isEditMode == false}
                                                                        fullWidth
                                                                        required={true}
                                                                        name={"sTel"}
                                                                        label="เบอร์โทรศัพท์/Telephone "
                                                                        showClear={false}
                                                                        maxLength={50}
                                                                    />
                                                                </Grid>
                                                            </Grid>

                                                            <Grid item xs={12} md={6}>
                                                                <InputForm
                                                                    disabled={isEditMode == false}
                                                                    fullWidth
                                                                    required={nUserType == 4 ? true : false}
                                                                    name={"sEmail"}
                                                                    label="อีเมล/E-mail"
                                                                    showClear={false}
                                                                    maxLength={254}
                                                                />
                                                            </Grid>

                                                        </Grid>
                                                    </Grid>

                                                    <Grid item xs={12}>
                                                        <InputForm
                                                            disabled={isEditMode == false}
                                                            name="sAddress"
                                                            label="ที่อยู่ / Address (ต้องลงท้ายด้วยรหัสไปรษณีย์) "
                                                            placeholder="ที่อยู่ / Address (ต้องลงท้ายด้วยรหัสไปรษณีย์)"
                                                            fullWidth
                                                            required
                                                            showClear={false}
                                                            multiline={true}
                                                            rows={3}
                                                        />
                                                    </Grid>

                                                    <Grid item xs={12} sx={{ marginTop: -2 }}>
                                                        <Grid item container xs={12} alignItems="start" direction="row" sx={{ paddingLeft: "12px" }}>
                                                            <Grid item xs={12} md={12}>
                                                                <UploadFile
                                                                    disabled={isEditMode == false}
                                                                    keyID={"sFile"}
                                                                    sTitle={"เอกสารอ้างอิง/Document Files"}
                                                                    sSubTitle={""}
                                                                    nLimitFile={50}
                                                                    IsRequired={false}
                                                                    arrFile={arrFile || []}
                                                                    setarrFile={setArrFile}
                                                                    IsFolder={false}
                                                                    IsFile={true}
                                                                    IsMultiple={true}
                                                                    isFileChange={true}
                                                                    Extension={Extension.OBAFile}
                                                                    IsDrag={false}
                                                                />
                                                            </Grid>
                                                        </Grid>
                                                    </Grid>

                                                </Grid>
                                            </Cards>
                                        </Grid>

                                        {/* Div ว่าง */}
                                        <Grid item xs={12} sx={{ marginTop: 5 }}>
                                        </Grid>

                                        {/* Div Contact */}
                                        {nUserType == 3 &&
                                            <Grid item xs={12} >
                                                <Cards skey={"oCard1"} sTitle={"บุคคลที่สามารถติดต่อได้/Contact Point"} sColor={"#122768"}>
                                                    <Grid container spacing={2} direction="row" alignItems="start">
                                                        <Grid item xs={12}>
                                                            <Grid item container xs={12} alignItems="start" direction="row" spacing={2}>
                                                                <Grid item xs={12} md={6}>
                                                                    <AutoCompleteSelect
                                                                        disabled={isEditMode == false}
                                                                        name="sContactPrefixTH"
                                                                        label="คำนำหน้า/Prefix "
                                                                        required={true}
                                                                        fullWidth={false}
                                                                        width={{ width: "53%" }}
                                                                        options={arrOptionPrefix}
                                                                        disableClearable={false}
                                                                        IsShowIcon={false}
                                                                    />
                                                                </Grid>

                                                                <Grid item xs={12} md={6}>
                                                                    <InputForm
                                                                        disabled={isEditMode == false}
                                                                        fullWidth
                                                                        required={true}
                                                                        name={"sContactUserName"}
                                                                        label="ชื่อ - นามสกุล / Name - Surname "
                                                                        showClear={false}
                                                                        maxLength={200}
                                                                    />
                                                                </Grid>
                                                            </Grid>
                                                        </Grid>

                                                        <Grid item xs={12}>
                                                            <Grid item container xs={12} alignItems="start" direction="row" spacing={2}>
                                                                <Grid item xs={12} md={6}>
                                                                    <Grid item xs={12} md={8}>
                                                                        <InputNumberForm
                                                                            disabled={isEditMode == false}
                                                                            name="sContactPersonalID"
                                                                            label="เลขประจำตัวประชาชน/Citizen ID "
                                                                            type={"number"}
                                                                            required={true}
                                                                            allowMinus={false}
                                                                            fullWidth
                                                                            digits={0}
                                                                            maxLength={13}
                                                                            integerDigits={13}
                                                                            textAlign={"left"}
                                                                            thousandSeparator={false}
                                                                            allowLeadingZeros={true}
                                                                        />
                                                                    </Grid>
                                                                </Grid>

                                                                <Grid item xs={12} md={6}>
                                                                    <InputForm
                                                                        disabled={isEditMode == false}
                                                                        fullWidth
                                                                        required={true}
                                                                        name={"sContactPosition"}
                                                                        label="ตำแหน่ง "
                                                                        showClear={false}
                                                                        maxLength={100}
                                                                    />
                                                                </Grid>
                                                            </Grid>
                                                        </Grid>

                                                        <Grid item xs={12}>
                                                            <Grid item container xs={12} alignItems="start" direction="row" spacing={2}>
                                                                <Grid item xs={12} md={6}>
                                                                    <Grid item xs={12} md={8}>
                                                                        <InputForm
                                                                            disabled={isEditMode == false}
                                                                            fullWidth
                                                                            required={true}
                                                                            name={"sContactTel"}
                                                                            label="เบอร์โทรศัพท์/Telephone "
                                                                            showClear={false}
                                                                            maxLength={50}
                                                                        />
                                                                    </Grid>
                                                                </Grid>

                                                                <Grid item xs={12} md={6}>
                                                                    <InputForm
                                                                        disabled={isEditMode == false}
                                                                        fullWidth
                                                                        required={true}
                                                                        name={"sContactEmail"}
                                                                        label="อีเมล/E-mail"
                                                                        showClear={false}
                                                                        maxLength={254}
                                                                    />
                                                                </Grid>
                                                            </Grid>
                                                        </Grid>

                                                    </Grid>
                                                </Cards>
                                            </Grid>
                                        }

                                    </Grid>
                                </Grid>
                            }

                            {/* Div Staff */}
                            {isStaff == true &&
                                <Grid item xs={12} flex={1} marginX={2} style={{ padding: '24px 8px' }}>
                                    {/* Div Dealer */}
                                    <Grid item container xs={12} justifyContent={"center"} sx={{ marginTop: 3 }} alignItems="start" direction="row" >
                                        <Grid item container xs={12} justifyContent={"center"} alignItems="start" direction="row" >
                                            <HaftHeaderCards skey={"hHCards01"} sTitle={sNameTitle} sColor={"#122768"} >
                                                <Grid item xs={12} style={{ padding: '5px 8px' }}>
                                                    <Grid container spacing={2} direction="row" justifyContent="start" alignItems="start">
                                                        <Grid item xs={12} md={6}>
                                                            {nUserTypeID == 2 &&
                                                                <Typography><b>หมายเลขผู้แทนจำหน่าย Ship To/Sold To : </b> {lstData["sDealerCode"]}</Typography>
                                                            }
                                                            {nUserTypeID != 2 &&
                                                                <Typography><b> ชื่อสถานประกอบการ/Trade Name : </b> {lstData["sName"]} </Typography>
                                                            }
                                                        </Grid>
                                                        <Grid item xs={12} md={6}>
                                                            <Typography><b>เลขประจำตัวผู้เสียภาษี  / Taxpayer Identification No. : </b> {lstData["TaxID"]}</Typography>
                                                        </Grid>
                                                        <Grid item xs={12} md={6}>
                                                            {nUserTypeID == 4 &&
                                                                <Typography><b>สาขา/Branch : </b> {lstData["isHeadOffice"] == true ? "สำนักงานใหญ่" : lstData["sBranch"]}</Typography>
                                                            }
                                                            {nUserTypeID == 5 &&
                                                                <Typography><b>กลุ่มธุรกิจ/Bussiness : </b> {sBusinessGroup}</Typography>
                                                            }
                                                            {(nUserTypeID != 4 && nUserTypeID != 5) &&
                                                                <Typography><b> ชื่อสถานประกอบการ/Trade Name : </b> {lstData["sName"]}</Typography>
                                                            }
                                                        </Grid>
                                                        <Grid item xs={12} md={6}>
                                                            {nUserTypeID != 4 &&
                                                                <Typography><b>สาขา/Branch : </b> {lstData["isHeadOffice"] == true ? "สำนักงานใหญ่" : lstData["sBranch"]}</Typography>
                                                            }
                                                        </Grid>
                                                        <Grid item xs={12} md={6}>
                                                            <Typography><b>เบอร์โทรศัพท์/Telephone : </b> {lstData["TaxID"]}</Typography>
                                                        </Grid>
                                                        <Grid item xs={12} md={6}>
                                                            <Typography><b>อีเมล/E-mail : </b> {lstData["sEmail"]}</Typography>
                                                        </Grid>
                                                        <Grid item xs={12} md={12}>
                                                            <Typography><b>ที่อยู่/Address : </b> {lstData["sAddress"]}</Typography>
                                                        </Grid>
                                                    </Grid>
                                                </Grid>
                                            </HaftHeaderCards>
                                        </Grid>

                                        {/* Div ว่าง */}
                                        <Grid item xs={12} sx={{ marginTop: 5 }}>
                                            <Grid item container xs={12} justifyContent={"flex-end"}>
                                                <BtnCustomText txt={"แก้ไขข้อมูล/Edit Info"}
                                                    width={"13em !important"}
                                                    backgroundColor={"#122768"}
                                                    onClick={() => onEdit()}
                                                />
                                            </Grid>
                                        </Grid>

                                        {/* Div saff */}

                                        <Grid item xs={12} >
                                            <Cards skey={"oCard1"} sTitle={"ข้อมูลผู้ใช้งาน/User Info"} sColor={"#122768"}>
                                                <Grid container spacing={2} direction="row" alignItems="start">
                                                    <Grid item xs={12}>
                                                        <Grid container xs={12} alignItems="start" direction="row" spacing={2}>
                                                            <Grid item xs={12} md={4}>
                                                                <InputForm
                                                                    fullWidth
                                                                    name={"sSatffUserName"}
                                                                    label="ชื่อผู้ใช้งาน/Username "
                                                                    showClear={false}
                                                                    disabled={true}
                                                                />
                                                            </Grid>

                                                            {nUserTypeID == 4 &&
                                                                <Grid item xs={12} md={4}>
                                                                    <InputNumberForm
                                                                        disabled={isEditMode == false}
                                                                        name="sSatffEmployeeCode"
                                                                        label="รหัสพนักงาน/Emplyee Code "
                                                                        type={"number"}
                                                                        required={true}
                                                                        allowMinus={false}
                                                                        fullWidth
                                                                        digits={0}
                                                                        maxLength={10}
                                                                        integerDigits={10}
                                                                        textAlign={"left"}
                                                                        thousandSeparator={false}
                                                                        allowLeadingZeros={true}
                                                                    />
                                                                </Grid>
                                                            }

                                                        </Grid>
                                                    </Grid>

                                                    <Grid item xs={12}>
                                                        <Grid container xs={12} alignItems="start" direction="row" spacing={2}>
                                                            <Grid item xs={12} md={4}>
                                                                <AutoCompleteSelect
                                                                    disabled={isEditMode == false}
                                                                    name="sSatffPrefixTH"
                                                                    label="คำนำหน้า (ภาษาไทย)/Prefix (TH) "
                                                                    required={true}
                                                                    fullWidth={false}
                                                                    width={{ width: "80%" }}
                                                                    options={arrPrefixTH}
                                                                    disableClearable={false}
                                                                    IsShowIcon={false}
                                                                    onChange={(e) => onChangePrefix(e, 'th')}
                                                                />
                                                            </Grid>

                                                            <Grid item xs={12} md={8}>
                                                                <InputForm
                                                                    disabled={isEditMode == false}
                                                                    fullWidth
                                                                    required={true}
                                                                    name={"sSatffNameTH"}
                                                                    label="ชื่อ - นามสกุล (ภาษาไทย)/Name-Surname (TH) "
                                                                    showClear={false}
                                                                    maxLength={255}
                                                                />
                                                            </Grid>
                                                        </Grid>
                                                    </Grid>

                                                    <Grid item xs={12}>
                                                        <Grid container xs={12} alignItems="start" direction="row" spacing={2}>
                                                            <Grid item xs={12} md={4}>
                                                                <AutoCompleteSelect
                                                                    disabled={isEditMode == false}
                                                                    name="sSatffPrefixEN"
                                                                    label="คำนำหน้า (ภาษาอังกฤษ)/Prefix (EN) "
                                                                    required={true}
                                                                    fullWidth={false}
                                                                    width={{ width: "80%" }}
                                                                    options={arrPrefixEN}
                                                                    disableClearable={false}
                                                                    IsShowIcon={false}
                                                                />
                                                            </Grid>

                                                            <Grid item xs={12} md={8}>
                                                                <InputForm
                                                                    disabled={isEditMode == false}
                                                                    fullWidth
                                                                    required={true}
                                                                    name={"sSatffNameEN"}
                                                                    label="ชื่อ - นามสกุล (ภาษาอังกฤษ)/Name-Surname (EN) "
                                                                    showClear={false}
                                                                    maxLength={255}
                                                                />
                                                            </Grid>
                                                        </Grid>
                                                    </Grid>

                                                    <Grid item xs={12}>
                                                        <Grid container xs={12} alignItems="start" direction="row" spacing={2}>
                                                            <Grid item xs={12} md={6}>
                                                                <RadioFormItem
                                                                    disable={isEditMode == false}
                                                                    name="sTypeID"
                                                                    required={true}
                                                                    label="เลขประจำตัว"
                                                                    options={arrOptionsTypeID}
                                                                    value={form.getValues("sTypeID")}
                                                                    onChange={(e) => setValuepersonID(e)}
                                                                />
                                                            </Grid>

                                                            <Grid item xs={12} md={6} >
                                                                <RadioFormItem
                                                                    disable={isEditMode == false}
                                                                    name="sSatffGender"
                                                                    required={true}
                                                                    label="เพศ/Gender"
                                                                    options={arrOptionsGender}
                                                                    value={form.getValues("sSatffGender")}
                                                                />
                                                            </Grid>
                                                        </Grid>


                                                        <Grid container xs={12} alignItems="start" direction="row" spacing={2} justifyContent="space-between" >

                                                            <Grid item xs={12} md={4}>
                                                                {sTypeID == "citizenID" &&
                                                                    <InputForm
                                                                        disabled={isEditMode == false}
                                                                        name="sIdentityID"
                                                                        required={true}
                                                                        fullWidth
                                                                        maxLength={13}
                                                                        showCoutTest={false}
                                                                        showClear={false}
                                                                    />
                                                                }
                                                                {sTypeID == "passportID" &&
                                                                    <InputForm
                                                                        disabled={isEditMode == false}
                                                                        name="sIdentityPassID"
                                                                        required={true}
                                                                        fullWidth
                                                                        maxLength={9}
                                                                        showCoutTest={false}
                                                                        showClear={false}
                                                                    />
                                                                }
                                                            </Grid>

                                                            <Grid item xs={12} md={6}>
                                                                <InputForm
                                                                    disabled={isEditMode == false}
                                                                    fullWidth
                                                                    required={true}
                                                                    name={"ssSatffPosition"}
                                                                    label="ตำแหน่ง "
                                                                    showClear={false}
                                                                    maxLength={100}
                                                                />
                                                            </Grid>

                                                        </Grid>
                                                    </Grid>

                                                    {nUserTypeID == 4 &&
                                                        <Grid item xs={12}>
                                                            <Grid container xs={12} alignItems="start" direction="row" spacing={2} justifyContent="space-between">
                                                                <Grid item xs={12} md={4}>
                                                                    <InputForm
                                                                        disabled={isEditMode == false}
                                                                        fullWidth
                                                                        required={true}
                                                                        name={"ssSatffOfficeName"}
                                                                        label="สถานที่ปฏิบัติงาน "
                                                                        showClear={false}
                                                                        maxLength={100}
                                                                    />
                                                                </Grid>

                                                                <Grid item xs={12} md={6}>
                                                                    <InputForm
                                                                        disabled={isEditMode == false}
                                                                        fullWidth
                                                                        required={true}
                                                                        name={"ssSatffUnitName"}
                                                                        label="หน่วยงานที่ปฏิบัติงาน "
                                                                        showClear={false}
                                                                        maxLength={100}
                                                                    />

                                                                </Grid>
                                                            </Grid>
                                                        </Grid>
                                                    }

                                                    <Grid item xs={12}>
                                                        <Grid container xs={12} alignItems="start" direction="row" spacing={2} justifyContent="space-between">
                                                            <Grid item xs={12} md={4}>
                                                                <InputForm
                                                                    disabled={isEditMode == false}
                                                                    fullWidth
                                                                    required={true}
                                                                    name={"sStaffTel"}
                                                                    label="เบอร์โทรศัพท์/Telephone "
                                                                    showClear={false}
                                                                    maxLength={50}
                                                                />
                                                            </Grid>

                                                            <Grid item xs={12} md={6}>
                                                                <InputForm
                                                                    disabled={isEditMode == false}
                                                                    fullWidth
                                                                    required={true}
                                                                    name={"sStaffEmail"}
                                                                    label="อีเมล/E-mail"
                                                                    showClear={false}
                                                                    maxLength={254}
                                                                />
                                                            </Grid>
                                                        </Grid>
                                                    </Grid>
                                                </Grid>
                                            </Cards>
                                        </Grid>
                                    </Grid>
                                </Grid>
                            }
                            {/* Div Back / Save */}
                            <Grid item xs={12} sx={{ marginTop: -2 }} >
                                {isShowBtnSaveChange == true &&
                                    <Grid container spacing={2} direction="row" justifyContent="center" alignItems="center">
                                        <Grid item><BtnSaveForm onClick={form.handleSubmit(() => { SaveData() })} /></Grid>
                                        <Grid item> <BtnCancelForm onClick={() => GoToList()} /></Grid>
                                    </Grid>
                                }
                            </Grid>

                            <Grid item xs={12} sx={{ marginTop: 5 }}>
                            </Grid>

                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
        </FormProvider >
    );
}
export default AccountEdit


