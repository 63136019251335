import { yupResolver } from "@hookform/resolvers/yup";
import {
    CardContent,
    Checkbox,
    FormControlLabel,
    Grid,
    IconButton,
    styled,
    Tooltip,
    tooltipClasses,
    TooltipProps,
    Typography,
} from "@mui/material";
import { Box, width } from "@mui/system";
import { useEffect, useState } from "react";
import { FormProvider, useForm } from "react-hook-form";
import { AccordionUI } from "src/Components/AccordionUI";
import { CheckBoxUI } from "src/Components/FormItem/CheckBox";
import { InputForm } from "src/Components/FormItem/Input";
import NameBox from "src/Components/Register/RegisterNameBox";
import * as yup from "yup";
import { FaQuestionCircle } from "react-icons/fa";
import { BtnCustomText, BtnLogin } from "src/Components/Button";
import { Banner } from "src/Components/Banner";
import imgCover from "src/Layout/img/vision-cover.jpg";
import UploadFile from "src/Components/ElementInForm/UploadFile/UploadFile";
import { Decrypt, Extension, GetQueryString, IsNullOrUndefined } from "src/CommonFunction/TS_function";
import yupFormSchemas from "src/Components/FormItem/yup/yupFormSchemas";
import { AutoCompleteSelect } from "src/Components/FormItem/AutoCompleteSelect";
import { useNavigate } from "react-router";
import { Axios } from "src/CommonFunction/TS_Axios";
import { FnBlock_UI } from "src/Components/BlockUI";
import { AlertMsg, AlertTitle, SwAlert } from "src/Components/Alert/Sweetalert";
import InputNumberForm from "src/Components/FormItem/InputNumber";
import { CgOverflow } from "react-icons/cg";


const data = [
    {
        id: 0,
        sName: "กรอกข้อมูลคำขอ",
        sDesc: "ระบุข้อมูลของท่านหรือธุรกิจของท่าน",
        classname: "request",
    },
    {
        id: 1,
        sName: "ส่งคำขอ",
        sDesc: "ส่งคำขอสมัครเข้าใช้งาน เพื่อให้ OR Academy ตรวจสอบข้อมูลและอนุมัติคำขอ",
        classname: "send",
    },
    {
        id: 2,
        sName: "รออนุมัติคำขอ",
        sDesc: "เป็นขั้นตอน OR Academy ตรวจสอบข้อมูลและอนุมัติคำขอ",
        classname: "approve",
    },
    {
        id: 3,
        sName: "เสร็จสิ้นการสมัคร",
        sDesc: "ท่านจะได้รับอีเมลผลคำขอการสมัครเข้าใช้งานพร้อม Username",
        classname: "complete",
    },
];

const Registration = () => {
    const navigate = useNavigate();
    const location = window.location.search;
    const urlParams = new URLSearchParams(location);
    const paramnUserTypeID = GetQueryString("nUserTypeID");
    const paramnUserType = GetQueryString("nUserType");
    const { BlockUI, UnBlockUI } = FnBlock_UI();
    const [dissDealerCode, setDissDealerCode] = useState(false);
    const [disHeadoffice, setDisHeadoffice] = useState(false);

    const [banner, setBanner] = useState([] || undefined);
    const [arrFileDoc, setArrFileDoc] = useState<any[]>([]);
    const [nUserTypeID, setnUserTypeID] = useState(0);
    const [nUserType, setnUserType] = useState(0);
    const [prefix, setPrefix] = useState<any[]>([]);
    const [fVender, setFVender] = useState<any[]>([]);
    const registerMode = nUserTypeID;
    const registerType = nUserType;
    const [valuePrefix, setValuePrefix] = useState(null) as any;
    const [valueBusiness, setValueBusiness] = useState(null) as any;
    const [objSchema, setobjSchema] = useState({} as any);
    const [objGlobal] = useState({} as any);
    const [isHeadOffice, setisHeadOffice] = useState(false)
    const [isWaitDealerCode, setisWaitDealerCode] = useState(false)
    const [chkWaitDealerCode, setchkWaitDealerCode] = useState(false)
    const [sheaderName, setsheaderName] = useState(null) as any;
    const [arrOptionVendor, setarrOptionVendor] = useState([] as any)

    const NoMaxWidthTooltip = styled(({ className, ...props }: TooltipProps) => (
        <Tooltip {...props} classes={{ popper: className }} />
    ))({
        [`& .${tooltipClasses.tooltip}`]: {
            maxWidth: 'none',
        },
    });

    const sID = GetQueryString("sID");
    const sItem = GetQueryString("sItem");
    const schema = yup.object().shape(objSchema);
    //Regex for form
    var Regex5 = /^\d{5}$/;
    var Regex13 = /^\d{13}$/;
    var Regex10 = /^\d{10}$/;
    var regexEmail = /^(([^<>()[\]\.,;:\s@\"]+(\.[^<>()[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i;
    const valiDateFrom = (nUserType, nUserTypeID, chkWaitDealerCode, isHeadOffice) => {
        //#region Schema       
        let obj = objGlobal;
        //ไม่เป็น staf
        if (nUserType == 3) {
            //เป็น Dealer
            obj = {
                ...objGlobal,

                sDealerCode: yupFormSchemas.string("หมายเลขผู้แทนจำหน่าย / Ship To / Sold To", { required: nUserTypeID == 2 && !chkWaitDealerCode ? true : false }),
                sBranchs: yupFormSchemas.string("รหัสสาขา / Branch No.", { required: (nUserTypeID == 2 && !isHeadOffice) ? true : false, matches: Regex5, labelmatches: "รหัสสาขา/Branch No. ไม่ถูกต้อง" }),
                sCus: yupFormSchemas.string("ชื่อสถานประกอบการ / Trade Name", { required: nUserTypeID == 2 ? true : false }),

                //Company
                sCompanyName: yupFormSchemas.string("ชื่อบริษัท / Company Name", { required: ((nUserTypeID == 4 || (nUserType == 3 && nUserTypeID != 2 && nUserTypeID != 5)) || nUserTypeID == 5) ? true : false }),

                //Vender
                nBusinessID: yupFormSchemas.object("กลุ่มธุรกิจ/Business", { required: nUserTypeID == 5 ? true : false }),

                //Member
                nCitizenID: yupFormSchemas.string("เลขประจำตัวประชาชน / Citizen ID", { required: nUserType == 4 ? true : false, matches: Regex13, labelmatches: "เลขประจำตัวประชาชน / Citizen ID ไม่ถูกต้อง" }),
                sNameTH: yupFormSchemas.string("ชื่อ - นามสกุล (ภาษาไทย) / Name - Surname (TH)", { required: nUserType == 4 ? true : false }),
                sNameEN: yupFormSchemas.string("ชื่อ - นามสกุล (ภาษาอังกฤษ) / Name - Surname (EN)", { required: nUserType == 4 ? true : false }),

                //All
                sTels: yupFormSchemas.string("เบอร์โทรศัพท์ / Telephone", { required: true, min: 9 }),
                sEmails: yupFormSchemas.string("อีเมล / Email", { required: false, matches: regexEmail, labelmatches: "อีเมล / Email ไม่ถูกต้อง" }),
                sAddresss: yupFormSchemas.string("ที่อยู่ / Address (ต้องลงท้ายด้วยรหัสไปรษณีย์) ",{ required: true, min: 40 ,labelmin: "ที่อยู่ / Address (ต้องลงท้ายด้วยรหัสไปรษณีย์) ต้องมีความยาวรวมไม่น้อยกว่า 40 ตัวอักษร"}),

                // sTaxID: yupFormSchemas.string("เลขประจำตัวผู้เสียภาษี/Taxpayer Identification No.", { required: true, matches: Regex13, labelmatches: "เลขประจำตัวผู้เสียภาษี/Taxpayer Identification No. ไม่ถูกต้อง" }),
                sTaxID: yupFormSchemas.string("เลขประจำตัวผู้เสียภาษี /Taxpayer Identification No. ", { required: true }),
                //ContactPoint
                sContactPrefixTH: yupFormSchemas.object("คำนำหน้า / Prefix", { required: true }),
                sContactUserName: yupFormSchemas.string("ชื่อ - นามสกุล / Name - Surname", { required: true }),
                sContactPersonalID: yupFormSchemas.string("เลขประจำตัวประชาชน / Citizen ID", { required: true, matches: Regex13, labelmatches: "เลขประจำตัวประชาชน / Citizen ID ไม่ถูกต้อง" }),
                sContactPosition: yupFormSchemas.string("ตำแหน่ง / Position", { required: true }),
                sContactTel: yupFormSchemas.string("เบอร์โทรศัพท์ / Telephone", { required: true, min: 9 }),
                sContactEmail: yupFormSchemas.string("อีเมล / Email", { required: true, matches: regexEmail, labelmatches: "อีเมล / Email ไม่ถูกต้อง" }),
            }
        }
        else {
            //เป็น Member
            obj = {
                ...objGlobal,

                nCitizenID: yupFormSchemas.string("เลขประจำตัวประชาชน / Citizen ID", { required: true, matches: Regex13, labelmatches: "เลขประจำตัวประชาชน / Citizen ID ไม่ถูกต้อง" }),
                sNameTH: yupFormSchemas.string("ชื่อ - นามสกุล (ภาษาไทย) / Name - Surname (TH)", { required: true }),
                sNameEN: yupFormSchemas.string("ชื่อ - นามสกุล (ภาษาอังกฤษ) / Name - Surname (EN)", { required: true }),
                //All
                sTels: yupFormSchemas.string("เบอร์โทรศัพท์ / Telephone", { required: true, min: 9 }),
                sEmails: yupFormSchemas.string("อีเมล / Email", { required: true, matches: regexEmail, labelmatches: "อีเมล / Email ไม่ถูกต้อง" }),
                sAddresss: yupFormSchemas.string("ที่อยู่ / Address (ต้องลงท้ายด้วยรหัสไปรษณีย์) ",{ required: true, min: 40 ,labelmin: "ที่อยู่ / Address (ต้องลงท้ายด้วยรหัสไปรษณีย์) ต้องมีความยาวรวมไม่น้อยกว่า 40 ตัวอักษร"}),
            }
        }
        //#endregion  Schema
        setobjSchema(obj);
    }

    const [initialValues] = useState(() => {
        valiDateFrom(0, 0, false, false);

    });

    const form = useForm({
        resolver: yupResolver(schema),
        mode: 'all',
        shouldFocusError: true,
        criteriaMode: "firstError",
        defaultValues: initialValues as any,
    });

    useEffect(() => {
        setData();
    }, [])

    const getDecrypt = async (param) => {
        let result = null as any;
        await Axios(`Master/Decrypt`, "sValue=" + param, "Get", async (res) => { result = res; },
            null, BlockUI, UnBlockUI, () => { }
        );
        return result;
    };

    const getDataEdit = async (resPrefix, resVendor) => {
        let res = await onGetData_AccountSignUp();
        if (res.sID !== null) {
            setisHeadOffice(res.isHeadOffices);
            setisWaitDealerCode(res.isWaitDealerCode);
            setchkWaitDealerCode(isWaitDealerCode);

            form.setValue("sID", res.sID);
            form.setValue("sDealerCode", res.sDealerCode);
            form.setValue("sName", res.sName);
            form.setValue("sNameTH", res.sName);
            form.setValue("sCus", res.sName);
            form.setValue("sCompanyName", res.sName);
            form.setValue("sNameEN", res.sNameEN);
            form.setValue("sBranchs", res.sBranchs);
            form.setValue("sTels", res.sTels);
            form.setValue("sEmails", res.sEmails);
            form.setValue("sAddresss", res.sAddresss);
            let findBusiness = await resVendor.find(f => f.value == res.nBusinessID);
            setValueBusiness(findBusiness);
            form.setValue("nBusinessID", findBusiness);
            form.setValue("sTaxID", res.sTaxID);
            form.setValue("nCitizenID", res.sTaxID);
            setArrFileDoc(res.lstFile);
            let findPrefix = await resPrefix.find(f => f.label == res.sContactPrefixTH);
            setValuePrefix(findPrefix);
            form.setValue("sContactPrefixTH", findPrefix);
            form.setValue("sContactUserName", res.sContactUserName);
            form.setValue("sContactPersonalID", res.sContactPersonalID);
            form.setValue("sContactPosition", res.sContactPosition);
            form.setValue("sContactTel", res.sContactTel);
            form.setValue("sContactEmail", res.sContactEmail);
        }
    }

    const setData = async () => {
        let resnUserTypeID = await getDecrypt(paramnUserTypeID);
        setnUserTypeID(Number(resnUserTypeID));
        let resnUserType = await getDecrypt(paramnUserType);
        setnUserType(Number(resnUserType));
        let resBanner = await loadBanner();
        setBanner(resBanner || []);
        let resPrefix = await onGetPrefix();
        setPrefix(resPrefix);
        let resVendor = await onFilterVendor();
        console.log("resVendor", resVendor)
        let resHead = await onGetData_AccountSignUp();

        setsheaderName(resHead.sUserTypeNameTH);
        // setFVender(resVendor.lstVendor);
        //setarrOptionVendor(resVendor.lstVendor)
        let sName = "ข้อมูลผู้สมัคร/Personal Info";

        switch (resnUserTypeID) {
            case "2"://Dealer
                sName = "ข้อมูลสถานประกอบการ/Dealer Info";
                break;

            case "3"://Member
                sName = "ข้อมูลผู้สมัคร/Personal Info";
                break;

            case "4"://Company
                sName = "ข้อมูลบริษัท/Company Info";
                break;

            case "5"://Vender
                sName = "ข้อมูลคู่ค้า,ผู้ค้า/Vendor Info";
                break;
            default: {
                switch (resnUserType) {
                    case "3": {
                        sName = "ข้อมูลบริษัท/Company Info";
                    } break;
                    case "4": {
                        sName = "ข้อมูลผู้สมัคร/Personal Info";

                    } break;
                }

            } break;
        }
        setsheaderName(sName);
        getDataEdit(resPrefix, resVendor);
        valiDateFrom(resnUserType, resnUserTypeID, chkWaitDealerCode, isHeadOffice)
    };

    const loadBanner = async () => {
        let result = [] as any;
        result.push({
            sPath: imgCover,
        });
        return result;
    };

    const onFilterVendor = async () => {
        let result = {} as any;
        await Axios(`AccountPage/SetFilter_Vendor`, {}, "post", async (res) => {
            result = res.lstVendor;
            setarrOptionVendor(res.lstVendor)
        },
            null, null, null, () => { }
        );
        return result;
    }

    const onGetPrefix = async () => {
        let result = {} as any;
        await Axios(`ProfilePage/SetFilter_Prefix`, {}, "post", async (res) => {
            if (res.Status === "Success") {
                result = res.lstPrefix;
            }
        }, null, null, null, () => { });
        return result;
    };

    const onGetData_AccountSignUp = async () => {
        let result = {} as any;

        await Axios(`RegisterPage/GetData_AccountSignUp`,
            { sID: sID, sItem: sItem, }, "post", async (res) => {
                if (res.Status === "Success") {
                    result = res.objSignUpDetail;
                }
            }, null, BlockUI, UnBlockUI, () => { }
        );
        return result;
    };

    const OnSaveData_AccountSignUp = async (param) => {
        //console.log(param)
        let result = {} as any;
        await Axios(`RegisterPage/SaveData_AccountSignUp`, param, "post",
            async (res) => {
                if (res.Status === "Success") {
                    SwAlert.Success(AlertTitle.Success, AlertMsg.SaveComplete, () => { navigate(process.env.REACT_APP_API_URL + "Register"); })
                } else {
                    SwAlert.Warning(AlertTitle.Warning, res.Message)
                }
            }, null, BlockUI, UnBlockUI, () => { }
        );
        return result;
    };
    
    const onSubmit = async () => {
        let sAddresss = form.getValues("sAddresss");
        var sAddress = sAddresss.trim();
        var arrAddress = sAddress.split(' ');
        var sPostalCode = arrAddress[arrAddress.length - 1];
        if (sPostalCode.length == 5) {
            SwAlert.ConfirmYN(AlertTitle.Confirm, "ท่านต้องการบันทึกข้อมูลใช่หรือไม่?", () => {
                let param = {};
                switch (registerMode) {
                    case 2://Dealer
                        param = {
                            sID: sID,
                            nUserTypeID: nUserTypeID,
                            nUserType: nUserType,
                            isWaitDealerCode: chkWaitDealerCode,
                            //  isWaitDealerCode: (form.getValues("isWaitDealerCode")[0] === 1 ? true : false) || false,

                            sDealerCode: form.getValues("sDealerCode"),
                            sTaxID: form.getValues("sTaxID"),
                            sName: form.getValues("sCus"),
                            // isHeadOffices: (form.getValues("isHeadOffices")[0] === 1 ? true : false) || false,
                            isHeadOffices: isHeadOffice,
                            sBranchs: form.getValues("sBranchs"),
                            sTels: form.getValues("sTels"),
                            sEmails: form.getValues("sEmails"),
                            sAddresss: form.getValues("sAddresss"),
                            lstFile: arrFileDoc,

                            //ContactPoint
                            sContactPrefixTH: form.getValues("sContactPrefixTH").label,
                            sContactUserName: form.getValues("sContactUserName"),
                            sContactPersonalID: form.getValues("sContactPersonalID"),
                            sContactPosition: form.getValues("sContactPosition"),
                            sContactTel: form.getValues("sContactTel"),
                            sContactEmail: form.getValues("sContactEmail"),
                        }
                        break;

                    case 3://Member
                        param = {
                            sID: sID,
                            nUserTypeID: nUserTypeID,
                            nUserType: nUserType,
                            sTaxID: form.getValues("nCitizenID"),
                            sName: form.getValues("sNameTH"),
                            sNameEN: form.getValues("sNameEN"),

                            sTels: form.getValues("sTels"),
                            sEmails: form.getValues("sEmails"),
                            sAddresss: form.getValues("sAddresss"),
                            lstFile: arrFileDoc,
                        }
                        break;

                    case 4://Company
                        param = {
                            sID: sID,
                            nUserTypeID: nUserTypeID,
                            nUserType: nUserType,
                            sName: form.getValues("sCompanyName"),
                            sTaxID: form.getValues("sTaxID"),
                            sTels: form.getValues("sTels"),
                            sEmails: form.getValues("sEmails"),
                            sAddresss: form.getValues("sAddresss"),
                            lstFile: arrFileDoc,

                            //ContactPoint
                            sContactPrefixTH: form.getValues("sContactPrefixTH").label,
                            sContactUserName: form.getValues("sContactUserName"),
                            sContactPersonalID: form.getValues("sContactPersonalID"),
                            sContactPosition: form.getValues("sContactPosition"),
                            sContactTel: form.getValues("sContactTel"),
                            sContactEmail: form.getValues("sContactEmail"),
                        }
                        break;

                    case 5://Vender
                        param = {
                            sID: sID,
                            nUserTypeID: nUserTypeID,
                            nUserType: nUserType,
                            nBusinessID: form.getValues("nBusinessID").value,
                            sName: form.getValues("sCompanyName"),
                            sTaxID: form.getValues("sTaxID"),
                            sTels: form.getValues("sTels"),
                            sEmails: form.getValues("sEmails"),
                            sAddresss: form.getValues("sAddresss"),
                            lstFile: arrFileDoc,

                            //ContactPoint
                            sContactPrefixTH: form.getValues("sContactPrefixTH").label,
                            sContactUserName: form.getValues("sContactUserName"),
                            sContactPersonalID: form.getValues("sContactPersonalID"),
                            sContactPosition: form.getValues("sContactPosition"),
                            sContactTel: form.getValues("sContactTel"),
                            sContactEmail: form.getValues("sContactEmail"),
                        }
                        break;
                    default:
                        switch (nUserType) {
                            case 3: {
                                param = {
                                    sID: sID,
                                    nUserTypeID: nUserTypeID,
                                    nUserType: nUserType,
                                    sName: form.getValues("sCompanyName"),
                                    sTaxID: form.getValues("sTaxID"),
                                    sTels: form.getValues("sTels"),
                                    sEmails: form.getValues("sEmails"),
                                    sAddresss: form.getValues("sAddresss"),
                                    lstFile: arrFileDoc,

                                    //ContactPoint
                                    sContactPrefixTH: form.getValues("sContactPrefixTH").label,
                                    sContactUserName: form.getValues("sContactUserName"),
                                    sContactPersonalID: form.getValues("sContactPersonalID"),
                                    sContactPosition: form.getValues("sContactPosition"),
                                    sContactTel: form.getValues("sContactTel"),
                                    sContactEmail: form.getValues("sContactEmail"),
                                }
                            } break;
                            case 4: {
                                param = {
                                    sID: sID,
                                    nUserTypeID: nUserTypeID,
                                    nUserType: nUserType,
                                    sTaxID: form.getValues("nCitizenID"),
                                    sName: form.getValues("sNameTH"),
                                    sNameEN: form.getValues("sNameEN"),

                                    sTels: form.getValues("sTels"),
                                    sEmails: form.getValues("sEmails"),
                                    sAddresss: form.getValues("sAddresss"),
                                    lstFile: arrFileDoc,
                                }
                            } break;
                        }
                        break;
                }
                console.log("param", param)
                OnSaveData_AccountSignUp(param)
            });
        }
        else {

            SwAlert.Warning(AlertTitle.Warning, "โปรดระบุ <b>ที่อยู่</b> โดยลงท้ายด้วยชื่อจังหวัดและรหัสไปรษณีย์ (เช่น กรุงเทพมหานคร 10400)" );
        }

    }

    const onClickClose = () => {
        navigate(process.env.REACT_APP_API_URL + "Register");
    }

    return (
        <>
            <Grid container style={{ overflow: "hidden" }} sx={{ justifyContent: "center" }} >
                <Grid item xs={12} >
                    <Banner
                        dataSource={banner || []}
                        height={50}
                        textTH="สมัครสมาชิก"
                        textENG="Sign Up"
                    />
                </Grid>
                <Grid item xs={12} container sx={{ justifyContent: "center", mt: "2em" }}>
                    <Typography align="center" color="#03a9f4" variant="h5">
                        ขั้นตอนการสมัครเข้าใช้งานระบบ OR Academy
                    </Typography>
                </Grid>
                <Grid item xs={12}
                    container
                    justifyContent="center"
                    alignItems="center"
                    sx={{
                        mt: "2em",
                    }}
                >
                    {data.map((item, index) => (
                        <Grid item key={index}>
                            <NameBox sNameBox={item} />
                        </Grid>
                    ))}
                </Grid>

                {/* Forn Input */}
                <Grid item xs={12} sx={{ m: { xs: "2em  1em", sm: "3em 4em", paddingLeft: "5px", paddingRight: "6px" } }}>
                    <FormProvider {...form}>
                        <form >
                            <Grid item xs={12} sx={{ justifyContent: "center" }}>
                                {/* Heder */}
                                <AccordionUI
                                    showExpaned={false}
                                    defaultExpand={true}
                                    headerName={sheaderName}
                                    color="#3997df"
                                >
                                    <Grid container spacing={1} columnSpacing={5} sx={{ px: { xs: "0px", sm: "2em" } }}>
                                        {/* Row 1 */}
                                        {/* div Dealer*/}
                                        {nUserTypeID == 2 &&
                                            <Grid item xs={12} >
                                                <Grid container sx={{ paddingLeft: "12px" }}>
                                                    <FormControlLabel
                                                        control={
                                                            <Checkbox
                                                                name={"chkWaitDealerCode"}
                                                                checked={chkWaitDealerCode}
                                                                onChange={(event) => {
                                                                    setchkWaitDealerCode(event.target.checked);
                                                                    valiDateFrom(nUserType, nUserTypeID, event.target.checked, isHeadOffice)
                                                                    if (nUserTypeID == 2 && event.target.checked && nUserType == 3) form.resetField("sDealerCode");
                                                                    form.setValue("sDealerCode", "");
                                                                }}
                                                            />
                                                        }
                                                        label={
                                                            <>
                                                                <Typography>
                                                                    กำลังรอหมายเลขผู้แทนจำหน่าย/ Waiting for Ship To / Sold To
                                                                    <NoMaxWidthTooltip title={"หมายเลขผู้แทนจำหน่าย (Ship To/Sold To) ท่านจะได้รับเมื่อท่านจดทะเบียนผู้ค้ากับ ปตท. แล้วเรียบร้อย"}>
                                                                        <IconButton sx={{ color: "#3997df", cursor: "unset !important" }} disableRipple={true} >
                                                                            <FaQuestionCircle />
                                                                        </IconButton>
                                                                    </NoMaxWidthTooltip>
                                                                </Typography>
                                                            </>
                                                        }
                                                    />
                                                </Grid>
                                            </Grid>
                                        }
                                        {/* div Member*/}
                                        {nUserType == 4 &&
                                            <>
                                                <Grid xs={12} md={6} item>
                                                    <InputForm
                                                        name="sNameTH"
                                                        label="ชื่อ - นามสกุล (ภาษาไทย) / Name - Surname (TH)"
                                                        placeholder="ชื่อ - นามสกุล (ภาษาไทย) / Name - Surname (TH)"
                                                        maxLength={255}
                                                        fullWidth
                                                        required
                                                    />
                                                </Grid>
                                                <Grid xs={12} md={6} item>
                                                    <InputForm
                                                        name="sNameEN"
                                                        label="ชื่อ - นามสกุล (ภาษาอังกฤษ) / Name - Surname (EN)"
                                                        placeholder="ชื่อ - นามสกุล (ภาษาอังกฤษ) / Name - Surname (EN)"
                                                        maxLength={255}
                                                        fullWidth
                                                        required
                                                    />
                                                </Grid>
                                            </>
                                        }
                                        {/* div Company*/}
                                        {(nUserTypeID == 4 || (nUserType == 3 && nUserTypeID != 2 && nUserTypeID != 5)) &&
                                            <>
                                                <Grid xs={12} md={6} item>
                                                    <InputForm
                                                        name="sCompanyName"
                                                        label="ชื่อบริษัท / Company Name"
                                                        placeholder="ชื่อบริษัท / Company Name"
                                                        maxLength={255}
                                                        fullWidth
                                                        required
                                                    />
                                                </Grid>
                                                <Grid xs={12} md={6} item>
                                                    <InputForm
                                                        name="sTaxID"
                                                        label="เลขประจำตัวผู้เสียภาษี / Taxpayer Identification No."
                                                        placeholder="เลขประจำตัวผู้เสียภาษี / Taxpayer Identification No."
                                                        maxLength={13}
                                                        fullWidth
                                                        required
                                                    />
                                                </Grid>
                                            </>
                                        }
                                        {/* div Vender*/}
                                        {nUserTypeID == 5 &&

                                            <Grid xs={12} item>
                                                <InputForm
                                                    name="sCompanyName"
                                                    label="ชื่อบริษัท / Company Name"
                                                    placeholder="ชื่อบริษัท / Company Name"
                                                    maxLength={255}
                                                    fullWidth
                                                    required
                                                />
                                            </Grid>
                                        }

                                        {/* Row 2 */}
                                        {nUserTypeID != 4 &&
                                            <>
                                                {nUserTypeID == 2 &&
                                                    <>
                                                        {/* div Dealer*/}
                                                        <Grid xs={12} md={6} item >
                                                            <InputNumberForm
                                                                name="sDealerCode"
                                                                label="หมายเลขผู้แทนจำหน่าย /  Ship To / Sold To. "
                                                                type={"number"}
                                                                allowMinus={false}
                                                                fullWidth
                                                                digits={0}
                                                                maxLength={10}
                                                                integerDigits={10}
                                                                textAlign={"left"}
                                                                thousandSeparator={false}
                                                                allowLeadingZeros={true}
                                                                disabled={chkWaitDealerCode == true}
                                                                required={!chkWaitDealerCode}
                                                            />
                                                        </Grid>
                                                        <Grid xs={12} md={6} item sx={{ paddingLeft: "12px" }}>
                                                            <Grid container sx={{ paddingLeft: "12px" }}>
                                                                <FormControlLabel
                                                                    control={
                                                                        <Checkbox
                                                                            checked={isHeadOffice}
                                                                            onChange={(event) => {
                                                                                setisHeadOffice(event.target.checked);
                                                                                valiDateFrom(nUserType, nUserTypeID, chkWaitDealerCode, event.target.checked);
                                                                                if (nUserTypeID == 2 && event.target.checked && nUserType == 3) form.resetField("sBranchs");
                                                                                form.setValue("sBranchs", (event.target.checked == true ? "00000" : ""));

                                                                            }}
                                                                        />
                                                                    }
                                                                    label={<Typography>สํานักงานใหญ่ / Head Office</Typography>}
                                                                />
                                                            </Grid>
                                                        </Grid>
                                                    </>
                                                }
                                                {/* div Member*/}
                                                {(nUserType == 4) &&
                                                    <>
                                                        <Grid xs={12} md={6} item>
                                                            <InputForm
                                                                name="nCitizenID"
                                                                label="เลขประจำตัวประชาชน / Citizen ID"
                                                                placeholder="เลขประจำตัวประชาชน / Citizen ID"
                                                                maxLength={13}
                                                                fullWidth
                                                                required
                                                            />
                                                        </Grid>
                                                    </>
                                                }
                                                {/* div Vender*/}
                                                {nUserTypeID == 5 &&
                                                    <>
                                                        <Grid xs={12} md={6} item>
                                                            <InputForm
                                                                name="sTaxID"
                                                                label="เลขประจำตัวผู้เสียภาษี / Taxpayer Identification No."
                                                                placeholder="เลขประจำตัวผู้เสียภาษี / Taxpayer Identification No."
                                                                maxLength={13}
                                                                fullWidth
                                                                required
                                                            />
                                                        </Grid>
                                                        <Grid xs={12} md={6} item>
                                                            <AutoCompleteSelect
                                                                name="nBusinessID"
                                                                label="กลุ่มธุรกิจ/Bussiness "
                                                                required={true}
                                                                fullWidth={false}
                                                                width={{ width: "100%" }}
                                                                options={arrOptionVendor}
                                                                disableClearable={false}
                                                                IsShowIcon={true}
                                                            />
                                                        </Grid>
                                                    </>
                                                }
                                            </>}


                                        {/* Row 3 */}
                                        {nUserTypeID == 2 &&
                                            <>
                                                {/* div Dealer*/}
                                                <Grid xs={12} md={6} item>
                                                    <InputNumberForm
                                                        name="sTaxID"
                                                        label="เลขประจำตัวผู้เสียภาษี /Taxpayer Identification No. "
                                                        type={"number"}
                                                        required={true}
                                                        allowMinus={false}
                                                        fullWidth
                                                        digits={0}
                                                        maxLength={13}
                                                        integerDigits={13}
                                                        textAlign={"left"}
                                                        thousandSeparator={false}
                                                        allowLeadingZeros={true}
                                                    />
                                                </Grid>
                                                <Grid xs={12} md={6} item>
                                                    <InputForm
                                                        disabled={isHeadOffice == true}
                                                        fullWidth
                                                        required={true}
                                                        name={"sBranchs"}
                                                        label="รหัสสาขา/Branch No. "
                                                        showClear={false}
                                                        maxLength={5}
                                                    />
                                                </Grid>
                                            </>}

                                        {/* Row 4 */}
                                        {nUserTypeID == 2 &&
                                            <>
                                                {/* div Dealer*/}
                                                <Grid xs={12} item>
                                                    <InputForm
                                                        name="sCus"
                                                        label="ชื่อสถานประกอบการ / Trade Name"
                                                        placeholder="ชื่อสถานประกอบการ / Trade Name"
                                                        maxLength={255}
                                                        fullWidth
                                                        required={true}
                                                    />
                                                </Grid>
                                            </>}
                                        <Grid xs={12} md={6} item>
                                            <InputForm
                                                name="sTels"
                                                label="เบอร์โทรศัพท์ / Telephone"
                                                placeholder="เบอร์โทรศัพท์ / Telephone"
                                                maxLength={50}
                                                fullWidth
                                                required
                                            />
                                        </Grid>
                                        <Grid xs={12} md={6} item>
                                            <InputForm
                                                name="sEmails"
                                                label="อีเมล / Email"
                                                placeholder="อีเมล / Email"
                                                maxLength={254}
                                                fullWidth
                                                required={nUserType == 4 ? true : false}
                                            />
                                        </Grid>
                                        <Grid xs={12} item>
                                            <Grid container direction="row" alignItems="center">
                                                <Grid xs={12} item>
                                                    <InputForm
                                                        name="sAddresss"
                                                        label="ที่อยู่ / Address (ต้องลงท้ายด้วยรหัสไปรษณีย์) "
                                                        placeholder="ที่อยู่ / Address (ต้องลงท้ายด้วยรหัสไปรษณีย์)"
                                                        fullWidth
                                                        required
                                                        showClear={false}
                                                        multiline={true}
                                                        rows={3}
                                                    />
                                                </Grid>
                                            </Grid>
                                        </Grid>
                                        <Grid xs={12} item>
                                            <Grid container direction="row" alignItems="center" sx={{ paddingLeft: "10px" }}>
                                                <Grid xs={12} item>
                                                    <UploadFile
                                                        keyID={"sFile"}
                                                        sTitle={"เอกสารอ้างอิง/Document Files"}
                                                        sSubTitle={""}
                                                        nLimitFile={50}
                                                        IsRequired={false}
                                                        arrFile={arrFileDoc || []}
                                                        setarrFile={setArrFileDoc || []}
                                                        IsFolder={false}
                                                        IsFile={true}
                                                        IsMultiple={true}
                                                        isFileChange={true}
                                                        Extension={Extension.OBAFile}
                                                        IsDrag={false}
                                                    />
                                                </Grid>
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                </AccordionUI>
                                {/* ContactPoint */}
                                {(nUserType == 3) &&
                                    <AccordionUI
                                        showExpaned={false}
                                        defaultExpand={true}
                                        headerName="บุคคลที่สามารถติดต่อได้ / Contact Point"
                                        color="#3997df"
                                    >
                                        <Grid container spacing={1} columnSpacing={5} sx={{ px: { xs: "0px", sm: "2em" } }}>
                                            <Grid item xs={12} md={6}>
                                                <AutoCompleteSelect
                                                    mx={1}
                                                    my={1}
                                                    required
                                                    name="sContactPrefixTH"
                                                    label={"คำนำหน้า / Prefix"}
                                                    width={{ width: "100%" }}
                                                    options={prefix}
                                                    IsShowIcon={false}
                                                    defaultValue={valuePrefix}
                                                />
                                            </Grid>
                                            <Grid item xs={12} md={6}>
                                                <Grid xs={12} item>
                                                    <InputForm
                                                        name="sContactUserName"
                                                        label="ชื่อ - นามสกุล / Name - Surname"
                                                        placeholder="ชื่อ - นามสกุล / Name - Surname"
                                                        maxLength={200}
                                                        fullWidth
                                                        required
                                                    />
                                                </Grid>
                                            </Grid>
                                            <Grid item xs={12} md={6}>
                                                <InputForm
                                                    name="sContactPersonalID"
                                                    label="เลขประจำตัวประชาชน / Citizen ID"
                                                    placeholder="เลขประจำตัวประชาชน / Citizen ID"
                                                    maxLength={13}
                                                    fullWidth
                                                    required
                                                />
                                            </Grid>
                                            <Grid item xs={12} md={6}>
                                                <InputForm
                                                    name="sContactPosition"
                                                    label="ตำแหน่ง / Position"
                                                    placeholder="ตำแหน่ง / Position"
                                                    maxLength={100}
                                                    fullWidth
                                                    required
                                                />
                                            </Grid>
                                            <Grid item xs={12} md={6}>
                                                <InputForm
                                                    name="sContactTel"
                                                    label="เบอร์โทรศัพท์ / Telephone"
                                                    placeholder="เบอร์โทรศัพท์ / Telephone"
                                                    maxLength={50}
                                                    fullWidth
                                                    required
                                                />
                                            </Grid>
                                            <Grid item xs={12} md={6}>
                                                <InputForm
                                                    name="sContactEmail"
                                                    label="อีเมล / Email"
                                                    placeholder="อีเมล / Email"
                                                    maxLength={254}
                                                    fullWidth
                                                    required
                                                />
                                            </Grid>
                                        </Grid>
                                    </AccordionUI>
                                }
                                {registerMode !== 0 &&
                                    <Grid item xs={12} sx={{ display: "flex", justifyContent: "center" }}>
                                        <BtnCustomText txt="สมัครสมาชิก/Sign Up" backgroundColor="green" width="180px"
                                            onClick={form.handleSubmit(() => { onSubmit() })}
                                        />
                                        <BtnCustomText txt="ยกเลิก/Cancel" backgroundColor="white" color="black" width="180px"
                                            onClick={() => { onClickClose() }}
                                        />
                                    </Grid>
                                }

                            </Grid>
                        </form>
                    </FormProvider>
                </Grid>
            </Grid>
        </>
    );
};

export default Registration;
