import { Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Grid, Slide } from '@mui/material'
import React from 'react'
import { AiOutlineCheckCircle, AiOutlineClose, AiOutlineCloseCircle } from 'react-icons/ai';
import { BiEdit } from 'react-icons/bi';
import { BsQuestionCircle } from 'react-icons/bs';
import { TransitionProps } from '@mui/material/transitions';
import { BtnCancelForm, BtnOK } from '../Button';
const Transition = React.forwardRef(function Transition(
    props: TransitionProps & {
        children: React.ReactElement;
    },
    ref: React.Ref<unknown>,
) {
    return <Slide direction="down" ref={ref} {...props} />;
});

export function DialogCustomUI(props: DialogCustomUI) {
    const {
        open,
        fullScreen = false,
        fullWidth = false,
        questionIcon = false,
        textHead,
        isShowConfirm = false,
        isShowCancle = false,
        testIcon = false,
        isSendAns = false,
        isSlideDown = false,
        isSetHeadColor = false,
        titleColor = "#000",
        titleBgColor = "#fff",
        maxWidth = "sm",
        hBGColor = "rgb(255,255,255)",
        hColor = "rgb(0,0,0)",
        isShowPopupConfirm = false,
        isShowPopupCancel = false,
        isShowPopupSuccess = false,
        elementAction
    } = props;

    return (
        <Dialog
            fullScreen={fullScreen}
            fullWidth={fullWidth}
            open={open}
            maxWidth={maxWidth}
            onClose={props.handleClose}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
            scroll="paper"
            TransitionComponent={isSlideDown ? Transition : undefined}
            sx={{
                "& .MuiDialog-container": {
                    "& .MuiPaper-root": {
                        width: "100%",
                    },
                },
                "& .MuiDialogTitle-root": {
                    backgroundColor: isSetHeadColor ? titleBgColor : "",
                    color: isSetHeadColor ? titleColor : ""
                },


            }}
        >
            {textHead &&
                <DialogTitle id="alert-dialog-title" sx={{
                    display: "flex", alignItems: "center"
                    , borderBottom: "1px solid rgba(0,0,0,0.3)"
                    , bgcolor: hBGColor
                    , color: hColor
                }}
                >
                    <>
                        {questionIcon ?
                            <>
                                {/* <BsQuestionCircle style={{ marginRight: "8px" }} />{textHead} */}
                                <p 
                                style={{
                                    WebkitLineClamp: 1,
                                    overflow: "hidden",
                                    whiteSpace: "nowrap",
                                    textOverflow: "ellipsis",
                                    flexWrap: "nowrap",
                                    margin: "0px",
                                    WebkitBoxOrient: "vertical",
                                    verticalAlign:"middle"
                                }}
                            >
                                <BsQuestionCircle style={{ marginRight: "8px", verticalAlign:"middle" }} />
                                <span style={{verticalAlign:"middle",padding:"1.5em 0 0 0"}}>{textHead}</span>

                            </p>
                            </>
                            :
                            <>
                                {testIcon ?
                                    <>
                                        <BiEdit style={{ marginRight: "8px" }} />{textHead}
                                    </>
                                    :
                                    <>
                                        {textHead}
                                    </>
                                }
                            </>
                        }
                    </>
                </DialogTitle>}
                {props.children}
        </Dialog >
    )
}

interface DialogCustomUI {
    open: boolean;
    fullScreen?: boolean;
    fullWidth?: boolean;
    questionIcon?: boolean;
    children?: any;
    textHead?: string,
    isShowConfirm?: boolean;
    isShowCancle?: boolean;
    testIcon?: boolean;
    isSendAns?: boolean;
    maxWidth?: "xs" | "sm" | "md" | "lg" | "xl";
    hBGColor?: string;
    hColor?:string;
    isSlideDown?: boolean;
    isSetHeadColor?: boolean;
    titleBgColor?: string;
    titleColor?: string;
    handleClose?: (value: any) => void;
    handleClickConfirm?: (value: any) => void;
    handleClickCancel?: (value: any) => void;
    isShowPopupConfirm?: boolean;
    isShowPopupCancel?: boolean;
    isShowPopupSuccess?: boolean;
    elementAction?: any;
}