import Swal, { SweetAlertIcon } from "sweetalert2";
import { i18n } from 'src/i18n';
import './Sweetalert.css';
//#region Function Login
import { loginAuthen } from "src/CommonFunction/TS_login";
import Logo from "src/Layout/img/oba-logo.png";
import { Children, HtmlHTMLAttributes, useState } from "react";
import { Rating } from "@mui/material";
import StarIcon from '@mui/icons-material/Star';

//#endregion Function Login
export const AlertTitle = {
  Success: i18n("common.Success"),
  Warning: i18n("common.Warning"),
  Error: i18n("common.Error"),
  Info: i18n("common.Info"),
  Confirm: i18n("common.confirm"),
  Hint: i18n("common.Hint"),
  Duplicate: i18n("common.Duplicate"),
};

export const AlertMsg = {
  SaveComplete: i18n("common.SaveComplete"),
  DeleteComplete: i18n("common.DeleteComplete"),
  Error: i18n("common.Error"),
  ConfirmSave: i18n("common.ConfirmSave"),
  ConfirmSaveDraft: i18n("common.confirmDraft"),
  ConfirmDelete: i18n("common.ConfirmDelete"),
  ConfirmApprove: i18n("common.confirmApprove"),
  ConfirmSubmit: i18n("common.confirmSubmit"),
  ConfirmReject: i18n("common.confirmReject"),
  ConfirmCancel: i18n("common.ConfirmCancel"),
  NoPermission: i18n("common.NoPermission"),
  SessionTimeOut: i18n("common.SessionTimeOut"),
  DeleteFail: i18n("common.DeleteFail"),
  UploadImage: i18n("common.UploadImage"),
  ImportComplete: i18n("common.ImportComplete"),
  SaveDraftComplete: i18n("common.SaveDraftComplete"),
  SaveSubmitComplete: i18n("common.SaveSubmitComplete"),
  SaveRequestEditComplete: i18n("common.SaveRequestEditComplete"),
  confirmImport: i18n("common.confirmImport"),
  SaverejectComplete: i18n("common.SaverejectComplete"),
  confirmRequestEdit: i18n("common.confirmRequestEdit"),
  confirmLogout: i18n("common.confirmLogout"),
  SaveCancelComplete: i18n("common.SaveCancelComplete"),
  SaveApproveComplete: i18n("common.SaveApproveComplete"),
};

export const AlertIcon = {
  info: "info" as SweetAlertIcon,
  success: "success" as SweetAlertIcon,
  error: "error" as SweetAlertIcon,
  warning: "warning" as SweetAlertIcon,
  question: "question" as SweetAlertIcon,
};

export const AlertButtonText = {
  OK: i18n("common.ok"),
  Cancel: i18n("common.cancel"),
  Close: i18n("common.close"),
  Yes: i18n("common.yes"),
  No: i18n("common.no"),
  Confirm: i18n("common.btnConfirm")
};

function GetMessage(text: string) {
  return i18n(`${text}`);
}

export const DialogConfirm = (funcYes: Function, funcNo?: Function, Title = AlertTitle.Confirm, Message = AlertMsg.ConfirmSave) => {
  SwAlert.Confirm(Title, Message, funcYes, funcNo);
};


export const SwAlert_Title = (sTitle) => {
  return "<strong>" + sTitle + "</strong>";
};

export const DialogConfirmOtherMessage = (
  sTitle: string,
  sMessage: string,
  funcYes: Function,
  funcNo?: Function
) => {
  SwAlert.Confirm(GetMessage(sTitle), GetMessage(sMessage), funcYes, funcNo);
};

export const DialogConfirmDelete = (funcYes: any, funcNo?: any) => {
  SwAlert.Confirm(AlertTitle.Confirm, AlertMsg.ConfirmDelete, funcYes, funcNo);
};
//#region Function Login

const sSiteFUserName = process.env.REACT_APP_API_URL + "ForgetUserName";
const sPathFPassword = process.env.REACT_APP_API_URL + "ForgetPassword";
const sPathRegister = process.env.REACT_APP_API_URL + "Register";

//#endregion Function Login
export const SwAlert = {

  Common: (sTitle: string | undefined, sMessage: string, fnOK?: Function) => {
    Swal.fire({
      title: SwAlert_Title(!sTitle ? "" : sTitle + ""),
      html: "<b>" + sMessage + "</b>",
      confirmButtonText: AlertButtonText.Close,
      allowOutsideClick: false,
      allowEscapeKey: false,
    }).then((result) => {
      if (result.value) {
        if (fnOK) fnOK();
      }
    });
  },
  Info: (sTitle: string | undefined, sMessage: string, fnOK?: Function) => {
    Swal.fire({
      title: SwAlert_Title(!sTitle ? AlertTitle.Success : sTitle + ""),
      html: "<b>" + sMessage + "</b>",
      icon: AlertIcon.info,
      confirmButtonText: AlertButtonText.Close,
      allowOutsideClick: false,
      allowEscapeKey: false,
    }).then((result) => {
      if (result.value) {
        if (fnOK) {
          fnOK();
        } else {
          Swal.close();
        }
      }
    });
  },
  Success: (sTitle: string | undefined, sMessage: string, fnOK?: Function) => {
    Swal.fire({
      title: SwAlert_Title(!sTitle ? AlertTitle.Success : sTitle + ""),
      html: "<b>" + sMessage + "</b>",
      icon: AlertIcon.success,
      confirmButtonText: AlertButtonText.Close,
      allowOutsideClick: false,
      allowEscapeKey: false,
    }).then((result) => {
      if (result.value) {
        if (fnOK) {
          fnOK();
        } else {
          Swal.close();
        }
      }
    });
  },
  Error: (sTitle: string | undefined, sMessage: string, fnOK?: Function) => {
    Swal.fire({
      icon: AlertIcon.error,
      title: SwAlert_Title(!sTitle ? AlertTitle.Error : sTitle + ""),
      html: "<b>" + sMessage + "</b>",
      confirmButtonText: AlertButtonText.Close,
      allowOutsideClick: false,
      allowEscapeKey: false,
    }).then((result) => {
      if (result.value) {
        if (fnOK) {
          fnOK();
        } else {
          Swal.close();
        }
      }
    });
  },
  Warning: (sTitle: string | undefined, sMessage: string, fnOK?: Function, sMessage2?: string | undefined) => {
    Swal.fire({
      icon: AlertIcon.warning,
      title: SwAlert_Title(!sTitle ? AlertTitle.Warning : sTitle + ""),
      html: "<b>" + sMessage + "</b>" + "<br/>" + (sMessage2 !== undefined ? "<b>" + sMessage2 + "</b>" : ""),
      confirmButtonText: AlertButtonText.Close,
      allowOutsideClick: false,
      allowEscapeKey: false,
    }).then((result) => {
      if (result.value) {
        if (fnOK) {
          fnOK();
        } else {
          Swal.close();
        }
      }
    });
  },
  Confirm: (
    sTitle: string | undefined,
    sMessage: string,
    fnYes?: Function,
    fnNo?: Function
  ) => {
    Swal.fire({
      title: SwAlert_Title(!sTitle ? AlertTitle.Confirm : sTitle + ""),
      html: "<b>" + sMessage + "</b>",
      icon: AlertIcon.question,
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: AlertButtonText.Confirm,
      cancelButtonText: AlertButtonText.Cancel,
      showLoaderOnConfirm: true,
      allowOutsideClick: false,
      preConfirm: () => {
        return new Promise(() => {
          //resolve, reject
          Swal.showLoading();

          if (fnYes) {
            fnYes();
            Swal.close();
          } else {
            Swal.close();
          }
        });
      },
    }).then((result) => {
      if (result.dismiss === Swal.DismissReason.cancel) {
        if (fnNo) {
          fnNo();
          Swal.close();
        } else {
          Swal.close();
        }
      }
    });
  },
  ConfirmYN: (
    sTitle: string | undefined,
    sMessage: string,
    fnYes?: Function,
    fnNo?: Function
  ) => {
    Swal.fire({
      title: SwAlert_Title(!sTitle ? AlertTitle.Confirm : sTitle + ""),
      html: "<b>" + sMessage + "</b>",
      icon: AlertIcon.question,
      allowOutsideClick: false,
      allowEscapeKey: false,
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: AlertButtonText.Yes,
      cancelButtonText: AlertButtonText.No,
      // closeOnConfirm: false,
      // closeOnCancel: false
    }).then((result) => {
      if (result.value) {
        if (fnYes) fnYes();
        else Swal.close();
      } else if (result.dismiss === Swal.DismissReason.cancel) {
        if (fnNo) fnNo();
        else Swal.close();
      }
    });
  },

  HtmlWarning: (sTitle: string | undefined, sMessage: string, fnOK?: Function) => {
    Swal.fire({
      icon: AlertIcon.warning,
      title: SwAlert_Title(!sTitle ? AlertTitle.Warning : sTitle + ""),
      html: sMessage,
      confirmButtonText: AlertButtonText.Close,
      allowOutsideClick: false,
      allowEscapeKey: false,
    }).then((result) => {
      if (result.value) {
        if (fnOK) {
          fnOK();
        } else {
          Swal.close();
        }
      }
    });
  },

  Login: (sText: string | undefined) => {
    Swal.fire({
      // imageUrl: Logo,
      // imageHeight: 60,
      // imageWidth: 90,
      customClass: 'swal2-wide',
      // showClass: {
      //   popup: 'slow-animation'
      // },
      // hideClass: {
      //   popup: 'animate__fadeOutUp'
      // },
      html: `
      <div class="col-xs-24">
      <img class="swl2-img-custom" src="`+ Logo + `">
      </div>
      <div class="col-xs-24">
      <input type="text" id="login" class="swal2-input" tabindex="0" placeholder="Username" autocomplete="off">
      </div>
      <div class="col-xs-24">
    <input type="password" id="password" class="swal2-input" tabindex="0" placeholder="Password" autocomplete="off">
    </div>
    <div class="col-xs-24"  style="justify-content: center !important;text-align: center !important;padding-top: 3px;">
    <span class="form-label" style="color: red;font-size: 0.8em !important;" id="lbMessage"></span>
    </div>
    <div class="col-xs-24">
    <button type="button"  id="btnLogin"  role="button" tabindex="0" class="swal2-custom-confirm swal2-btn-custom">`+ (!sText ? 'Sign in' : sText) + ` </button> 
    </div>
    <div class="col-xs-24" style="margin-top: 0.6em;justify-content: center !important;text-align: center !important;">
    <a href=`+ sSiteFUserName + ` class="text-primary swl2-font">ลืมชื่อผู้ใช้งาน/Forgot Username</a>
    </div>
    <div class="col-xs-24" style="justify-content: center !important;text-align: center !important;">
    <a href=`+ sPathFPassword + ` class="text-primary swl2-font">ลืมรหัสผ่าน/Forgot Password</a>
    </div>
    <div class="col-xs-24 swl2-custom-break"></div>
    <div class="col-xs-24">
          <button type="button" id="btnRegister" role="button" tabindex="0" class="swal2-custom-confirm swal2-btn-sign-up-custom">สมัครสมาชิก/Sign Up</button>
        </div>
    `,
      confirmButtonText: (!sText ? 'Sign in' : sText),
      allowOutsideClick: false,
      showCloseButton: true,
      showCancelButton: false,
      showConfirmButton: false,
      focusConfirm: false,
      didOpen: () => {
        const btnLogin = (document.getElementById("btnLogin") as HTMLInputElement);
        const $txtLogin = (document.getElementById("login") as HTMLInputElement);
        const $txtPassword = (document.getElementById("password") as HTMLInputElement);
        const $MuiDialog = (document.getElementsByClassName("MuiDialog-container") as any);
        const btnRegister = (document.getElementById("btnRegister") as HTMLInputElement); //ปิดก่อนเดี๋ยวเปิดนะ
       if($MuiDialog.length > 0) $MuiDialog[0].removeAttribute("tabindex")
        btnLogin.addEventListener('click', () => {
          const login = $txtLogin.value;
          const password = $txtPassword.value
          if (!login || !password) {
            //const htmlMsg = '<div class="swal2-validation-message my-validation-message" id="swal2-validation-message" style="display: flex;"><i class="fa fa-info-circle"></i> Please enter login and password</div>';
            (document.getElementById("lbMessage") as HTMLInputElement).innerText = `กรุณากรอกชื่อผู้ใช้งานและรหัสผ่าน`;
            // (document.getElementById("lbMessage") as HTMLInputElement).innerHTML = htmlMsg;

          } else {
            (document.getElementById("lbMessage") as HTMLInputElement).innerText = '';
            loginAuthen(login, password);
          }
        });

        // $txtLogin.addEventListener('keypress', (e: KeyboardEvent) => {
        //   if (e.keyCode == 13) { $txtPassword.focus() }
        //   else if (e.altKey && e.ctrlKey && e.keyCode === 17) {
        //     loginAuthen("610046", "1234");
        //   } else if (e.altKey && e.ctrlKey && e.keyCode === 23) {
        //     loginAuthen("user_002", "1234");
        //   }else {
        //     return false;
        //   }
        // });

        $txtPassword.addEventListener('keypress', (e: KeyboardEvent) => {
          if (e.keyCode == 13) { btnLogin.click(); return false; }
        });
        //ปิดก่อนเดี๋ยวเปิดนะ
        btnRegister.addEventListener('click', () => {
          window.location.href = sPathRegister;
        });



      },
    })

  },
};