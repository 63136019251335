import { useState, useEffect } from "react";
import { useFormContext, Controller, useWatch } from "react-hook-form";
import {
    Autocomplete,
    TextField,
    FormControl,
    InputLabel,
    FormHelperText,
    OutlinedInput,
} from "@mui/material";
import {
    ArrowDropDown,
} from "@mui/icons-material";
import PropTypes, { InferProps } from "prop-types";
import { InputForm } from 'src/Components/FormItem/Input';
import FormErrors from 'src/Components/FormItem/formErrors';
import { SxProps } from "@mui/material";
export const AutoCompleteSelect = (props: AutoCompleteSelectForm) => {
    const {
        name,
        required,
        label,
        placeholder,
        fullWidth = false,
        disabled,
        externalErrorMessage,
        onChange,
        helperText,
        size = "small",
        shrink = undefined,
        showClear = true,
        width,
        options,
        variant,
        isOptionTwoLanguage = false,
        hidden = false,
        rounded = false,
        disableClearable = false,
        freeSolo = false,
        customValue = null,
        defaultValue,
        IsShowIcon = true,
        mx = 1,
        my = 1,
        maxLength,
        isCurve = false,
        isshowCount = false,
    } = props;
    const {
        control,
        register,
        getValues,
        setValue,
        watch,
        formState: { touchedFields, errors, isSubmitted },
    } = useFormContext();

    const errorMessage = FormErrors.errorMessage(
        name,
        errors,
        touchedFields,
        isSubmitted,
        externalErrorMessage,
    );
    const handleChange = (event) => {
        setValue(name, event);
        props.onChange && props.onChange(event);
    };
    const langCode = localStorage.getItem("language") || "th";
    const [sValueText, setValueText] = useState<string>(watch(name));
    const maxLengthText: SxProps = {
        "::after": {
            content: isshowCount ? (maxLength ? `"${sValueText ? sValueText.length : 0}/${maxLength}"` : '""') : '""',
            position: "absolute",
            bottom: "0px",
            right: "10px",
            color: "#989898",
            fontSize: "10px",
        },


    };
    const FieldName = useWatch({
        control,
        name: name,
    });
    const [Focus, SetFocus] = useState(false);
    useEffect(() => {
        let sText = getValues(name);
        if (sText != undefined && sText != null) {
            setValueText(sText.label)
        } else {
            setValueText("");
            setValue(name, null);

        }

    }, [watch(name)])

    return (
        <Controller
            {...register(name)}
            control={control}
            name={name}
            rules={{ required: required }}
            render={({ field }) => (
                <FormControl
                    hiddenLabel={hidden || false}
                    disabled={disabled}
                    sx={{
                        width: width,
                        mx: mx,
                        my: my,
                        "label.MuiInputLabel-shrink": {
                            top: "0!important",
                        },
                        "div.MuiOutlinedInput-root": {
                            borderRadius: rounded ? '2em' : '',
                        },
                        "label.MuiInputLabel-formControl": {
                            color: Boolean(errors[name]) ? "#d32f2f" : ""
                        },
                        "label.MuiInputLabel-formControl > span": {
                            color: "#d32f2f"
                        },
                        "div.MuiOutlinedInput-root fieldset.MuiOutlinedInput-notchedOutline": {
                            borderColor: Boolean(errors[name]) ? '#d32f2f' : '',
                        },
                        "div.MuiFormControl-root": {
                            borderRadius: "4px",
                        },

                        "div.MuiFilledInput-root": {
                            backgroundColor: "#fff !important",
                        },

                        ".MuiFormHelperText-root": {
                            color: "#d32f2f"
                        },


                    }}>
                    <Autocomplete
                        {...field}
                        popupIcon={IsShowIcon && <ArrowDropDown />}

                        hidden={hidden || false}
                        renderOption={(props, option: any) => {
                            return (
                                <li {...props} key={option.value}>
                                    {isOptionTwoLanguage ? option[`label_${langCode}`] : option.label}
                                </li>
                            );
                        }}
                        disabled={disabled || undefined}
                        value={
                            (customValue
                                ? options.find((f) => f[customValue.key] === watch(name))
                                : watch(name) || null) || (defaultValue ? defaultValue : null)
                        }
                        fullWidth={fullWidth || undefined}
                        disableClearable={disableClearable || undefined}
                        clearOnBlur={true}
                        size={size as "small" | "medium"}
                        freeSolo={freeSolo || undefined}
                        onChange={(e, v) => {
                            let value = v ? (customValue ? v[customValue.key] : v) : v;
                            setValueText(v);
                            setValue(name, value);
                            handleChange(value);
                            field.onChange(value);
                        }}
                        onInputChange={(e, v, r) => {
                            if (freeSolo) {
                                setValueText(v)
                                setValue(name, { label: v, value: undefined });
                                field.onChange({ label: v, value: undefined });
                                //onChange && onChange({ label: v, value: undefined })
                            }
                        }}
                        options={options || []}
                        getOptionLabel={(itemOption: any) => {
                            return `${isOptionTwoLanguage ? itemOption[`label_${langCode}`] : itemOption.label}`
                        }}
                        isOptionEqualToValue={(option, value) =>
                            option.value === value.value
                        }
                        renderInput={(params) => <TextField
                            variant={variant || "outlined"}
                            required={required || false}
                            //disabled={disabled || undefined}
                            label={label}
                            sx={[
                                { ...maxLengthText },

                                { borderRadius: isCurve ? "16px" : "" },
                                { backgroundColor: "transparent !important" },

                            ]}
                            {...params}

                            inputProps={{
                                ...params.inputProps
                                , maxLength: maxLength ? maxLength : null
                                , name: name                              
                                , autoComplete: 'new-password'
                            }}
                            
                            InputLabelProps={{
                                shrink: FieldName || Focus ? true : false,
                            }}
                            onBlur={(e) => {
                                field.onBlur();
                                SetFocus(false);
                                props.onBlur && props.onBlur(e);
                            }}
                            onFocus={() => {
                                SetFocus(true);
                            }}
                            onChange={(e) => {
                                setValueText(e.target.value);

                                //onChange && onChange(e.target.value);
                            }}
                            error={!!Boolean(errorMessage)}
                            helperText={errorMessage || helperText}
                        //rows={rows || undefined}
                        />}
                    />

                    {/* {errors && errors[name] ?
                        <FormHelperText sx={{ color: "red" }} >{errors[name].message}</FormHelperText>
                        :
                        null} */}
                </FormControl>
            )}
        />
    );
}
interface AutoCompleteSelectForm extends InputForm {
    required?: boolean,
    name: string,
    options?: any,
    label?: string,
    size?: any,
    fullWidth?: boolean,
    disableClearable?: boolean,
    freeSolo?: boolean,
    onChange?: (e) => void,
    disabled?: boolean,
    hidden?: boolean,
    shrink?: boolean,
    sxLabel?: any,
    small?: boolean,
    rounded?: boolean,
    customValue?: any,
    isOptionTwoLanguage?: boolean,
    width?: any,
    defaultValue?: any
    IsShowIcon?: boolean,
    mx?: number,
    my?: number,
    /**
  * จำนวนตัวอักษร
  */
    maxLength?: number;
    /**
    * แสดงจำนวนที่กรอก
    * default true
    */
    isshowCount?: boolean;
    isCurve?: boolean;
    /**
 * แสดงให้กด Clear
 * default true
 */
    showClear?: boolean;
}

export default AutoCompleteSelect;