import { Box, Card, Grid, Pagination, Typography } from "@mui/material";
import { Axios } from "src/CommonFunction/TS_Axios";
import { useEffect, useRef, useState, useCallback } from "react";
import { useNavigate } from "react-router";
import { BtnCustomText, BtnSeeDetail } from "../Button";
import defaultImage from "../../Layout/img/topic-3.jpg";
import icon1 from "../../Layout/img/news-ico.png";
import "src/Components/NewsHome/NewsHome.css";
import * as yup from "yup";
import yupFormSchemas from "src/Components/FormItem/yup/yupFormSchemas";
import { yupResolver } from "@hookform/resolvers/yup";
import { FormProvider, useForm } from "react-hook-form";
import "./NewsStyle.css";
import { SelectForm } from "../FormItem/Select";
import { InputForm } from "../FormItem/Input";
import { DatePickerUI } from "../FormItem/DatePicker";
import { BreadcrumbsUI } from "../BreadcrumbsUI";
import { FnBlock_UI } from "../BlockUI";
import { usePagination } from "../PaginationUI/Pagination";
import { Banner } from "../Banner";
import { NoDataUI } from "../NoData";
import moment from "moment";
import { MenuBar } from "../System/NavBar/MenuBar";
import LogoBanner from "src/Layout/img/news-cover.jpg";
import LightGallery from 'lightgallery/react';
// import styles
import 'lightgallery/css/lightgallery.css';
import 'lightgallery/css/lg-zoom.css';
import 'lightgallery/css/lg-thumbnail.css';
// import plugins if you need
import lgThumbnail from 'lightgallery/plugins/thumbnail';
import lgZoom from 'lightgallery/plugins/zoom';
import { GetQueryString } from "src/CommonFunction/TS_function";
import imgCover from "src/Layout/img/news-cover.jpg";
import AutoCompleteSelect from "src/Components/FormItem/AutoCompleteSelect";
import { useLocation } from "react-router";

export function NewsList(props: NewsList) {
    const { dataSource } = props;
    const { BlockUI, UnBlockUI } = FnBlock_UI();
    const [navbar, setNavBar] = useState({} || undefined); //show navbar
    const [showNodata, setShowNodata] = useState(false); //show data not found
    const [NewsPage, setNewsPage] = useState() as any;
    const [banner, setBanner] = useState([] || undefined); //show content
    const [StartDate, setStartDate] = useState(undefined); //show content
    const [EndDate, setEndDate] = useState(undefined); //show content
    const [data, setData] = useState<any[]>([]);
    const [Page, setPage] = useState(1); //page
    const navigate = useNavigate();
    const location = useLocation();
    const searchParams = location.search;
    const sID = new URLSearchParams(searchParams).get('str') as string;
    const sContentID = GetQueryString('str');
    const [sNameTH, setNameTH] = useState("");
    const [sNameENG, setNameENG] = useState("");
    const oFormRef = useRef() as any;
    const [sScreenSize, setScreenSize] = useState('lg');
    const [nWidth, setWidth] = useState(window.innerWidth);
    const [nHeight, setHeight] = useState(window.innerHeight);
    const lightGallery = useRef<any>(null);
    const objSchema = {
        sStart: yupFormSchemas.datetime("sStart", { required: false }),
        sEnd: yupFormSchemas.datetime("sEnd", { required: false })

    }
    const schema = yup.object().shape(objSchema);
    const form = useForm({
        resolver: yupResolver(schema),
        shouldUnregister: false,
        shouldFocusError: true,
        mode: "all",
    });

    const PER_PAGE = 10;
    const count = NewsPage;
    const _DATA = usePagination(NewsPage || [], PER_PAGE);
    const OnPageLoad = async () => {
        let resBanner = await loadBanner();
        let NamePage = await loadNavBar();
        setBanner(resBanner)
        setNameTH(NamePage.menuNameTH);
        setNameENG(NamePage.menuNameENG);
        NewsList();

    }
    // useEffect(() => {
    //     NewsList();
    // }, []);


    useEffect(() => {
        OnPageLoad();
    }, [Page]);

    useEffect(() => {
        form.setValue("nSort", Sort[0]);
        window.addEventListener("resize", updateDimensions);
        return () => window.removeEventListener("resize", updateDimensions);
    }, []);

    useEffect(() => {

        if (nWidth >= 1200) {
            setScreenSize('lg')
        } else if (nWidth >= 992) {
            setScreenSize('md')
        } else if (nWidth >= 768) {
            setScreenSize('sm')
        } else {
            setScreenSize('xs')
        }
    }, [nWidth]);

    const updateDimensions = () => {
        setWidth(window.innerWidth);
        setHeight(window.innerHeight);
    }

    const NewsList = async () => {
        var sStartDate = moment(form.getValues("sStart"), "DD/MM").format("DD/MM/YYYY HH:mm");
        var sEndDate = moment(form.getValues("sEnd"), "DD/MM").format("DD/MM/YYYY HH:mm");
        let nPageNews = {
            nID: Page,
            sSearch: form.getValues("sSearch") || "",
            sStart: sStartDate || null,
            sEnd: sEndDate || null,
            // dStart: moment(form.getValues("sStart")).set({ "hour": 0, "minute": 0, "second": 0 }).toDate().getTime() || null,
            // dEnd: moment(form.getValues("sEnd")).set({ "hour": 23, "minute": 59, "second": 59 }).toDate().getTime() || null,
            nSort: form.getValues("nSort") != undefined ? form.getValues("nSort").value : 1,
        }

        await Axios("News/GetData_News", nPageNews, "POST", async (result) => {
            setShowNodata(false);
            if (result.lstNews === null || result.lstNews.length === 0) setShowNodata(true);

            //let resBanner = await loadBanner();
            // let resNavBar = await loadNavBar();

            let nCountPage = (result.nPage) / 10;
            let nPageNews = Math.ceil(nCountPage);

            setData(result.lstNews);
            setNewsPage(nPageNews);
            // setBanner(resBanner)
            // setNavBar(resNavBar);
        },
            setShowNodata(true),
            BlockUI,
            UnBlockUI,
            () => { }
        );
    };

    const loadBanner = async () => {
        let result = [] as any;
        result.push({
            sPath: LogoBanner,
        });
        return result;
    };

    const loadNavBar = async () => {
        let result = {} as any;
        let param = "sContentID=" + sContentID;
        await Axios(`Master/GetNamePage`, param, "Get", async (res) => {
            result = res;
        },
            null,
            null,
            null,
            () => { }
        );
        return result;
    };

    const Sort = [
        { label: "ใหม่-เก่า", value: 1 },
        { label: "เก่า-ใหม่", value: 2 },
    ]

    //pagination
    const handleChange = (e, p) => {
        setPage(p);
        _DATA.jump(p);
        //window[`scrollTo`]({ top: 150, behavior: `smooth` });
        if (oFormRef.current != null) {
            window.scrollTo({
                top: oFormRef.current.offsetTop - 100,
                left: 0,
                behavior: "smooth",
            });
        }
    };

    const gotoNewsList = (sID: string) => {
        navigate(process.env.REACT_APP_API_URL + `NewsDetail?sID=${encodeURI(sID)}` + '&str=' + sContentID);
    };

    //#region Open Image
    const DivImage = (sURLImage) => {
        return (
            <LightGallery
                elementClassNames="custom-classname"
                dynamic
                dynamicEl={[
                    {
                        src: sURLImage.sURLImage || defaultImage,
                    },
                ]}
                onInit={onInit}
                plugins={[lgThumbnail]}
            >

                <img
                    src={(sURLImage.sURLImage) || defaultImage}
                    onError={({ currentTarget }) => {
                        currentTarget.onerror = null;
                        currentTarget.src = defaultImage;
                    }}
                    style={{
                        // minHeight: "240px",
                        aspectRatio: "1/1",
                        maxWidth: "100%",
                        width: "100%"
                    }}
                />

            </LightGallery>

        );
    }
    const checkFile = async (sUrl) => {
        setTimeout(() => {
            lightGallery.current.refresh([
                {
                    src: sUrl || defaultImage,
                },
            ]);
            openGallery();
        }, 50);


    }

    const openGallery = useCallback(() => {
        lightGallery.current.openGallery();
    }, []);
    const onInit = useCallback((detail: any) => {
        if (detail) {
            lightGallery.current = detail.instance;
        }
    }, []);

    //#endregion Open Image

    return (
        <Grid container>
            <Grid item xs={12}>
                <Banner
                    dataSource={banner}
                    height={50}
                    textTH={sNameTH}
                    textENG={sNameENG}
                />
            </Grid>
            <Grid item xs={12}>
                <MenuBar sContentID={sContentID} />
            </Grid>
            <Grid container xs={12}
                columnSpacing={0}
                rowSpacing={0}
                sx={{ "&.MuiGrid-root": { marginTop: 0, display: "block !important" } }}>
                <FormProvider {...form}>
                    <Grid container>
                        <Grid container flexWrap={"wrap"} sx={{ paddingRight: "1em", marginTop: 2 }}>
                            <Grid item xs={12} sx={{ mb: "1em", m: "2em", display: "flex", justifyContent: "flex-end", flexDirection: { xs: "column", lg: "row" } }}>

                                <Grid item xs={24} sm={24} md={24} lg={2.5} sx={{ px: { lg: "0.5em" }, maxWidth: "100% !important", mt: "0.5em", paddingLeft: "0.5em", paddingTop: "0.5em" }}>
                                    <DatePickerUI
                                        label="วันที่เริ่ม"
                                        name="sStart"
                                        small
                                        fullWidth
                                        onChange={(e) => setStartDate(e)}
                                        maxDate={EndDate ? EndDate : undefined}
                                        onClearFields={(e) => setStartDate(undefined)}
                                    />
                                </Grid>
                                <Grid item xs={24} sm={24} md={24} lg={2.5} sx={{ px: { lg: "0.5em" }, maxWidth: "100% !important", mt: "0.5em", paddingLeft: "0.5em", paddingTop: "0.5em" }}>
                                    <DatePickerUI
                                        label="ถึงวันที่"
                                        name="sEnd"
                                        small
                                        fullWidth
                                        onChange={(e) => setEndDate(e)}
                                        minDate={StartDate ? StartDate : undefined}
                                        onClearFields={(e) => setEndDate(undefined)}
                                    />
                                </Grid>

                                <Grid item xs={12} sm={12} md={6} lg={6} sx={{ px: { lg: "0.5em" }, maxWidth: "100% !important", paddingRight: "0.5em", paddingTop: "0.5em" }}>
                                    <InputForm
                                        fullWidth
                                        name={"sSearch"}
                                        maxLength={100}
                                        label="ค้นหา"
                                        onKeyPress={NewsList}
                                    //mx={0}
                                    />
                                </Grid>

                                <Grid item xs={12} sm={12} md={1.5} lg={1.5} sx={{ px: { lg: "0.5em" }, maxWidth: "100% !important", paddingRight: "0.5em", paddingTop: "0.5em" }}>
                                    <AutoCompleteSelect
                                        required

                                        name="nSort"
                                        label={"เรียงโดย"}
                                        width={{ width: "100%" }}
                                        options={Sort}
                                        IsShowIcon={false}
                                        defaultValue={Sort[0]}
                                        onChange={(v) => {
                                            form.setValue('nSort', v ? v : null)
                                            NewsList();
                                        }}
                                    />
                                </Grid>



                                <Grid item sx={{ display: "flex", justifyContent: "center", paddingTop: "0.5em", paddingLeft: "0.5em" }}>
                                    <BtnCustomText
                                        txt="ค้นหา/Search"
                                        onClick={NewsList}
                                    />
                                </Grid>


                            </Grid>
                        </Grid>
                    </Grid>


                    {/* <Grid container justifyContent="flex-end" >
                        <Grid item xs={12}
                            sx={{
                                display: "flex",
                                justifyContent: "flex-end",
                                flexDirection: { xs: "column", sm: "row" },
                                mt: "2em",
                                px: { xs: "4em", xl: "5em" },
                            }}
                        >
                            <Grid item xs={12} sm={3} lg={3.5} sx={{ mt: "0.5em", pr: "1em" }} >
                                <DatePickerUI
                                    label="วันที่เริ่ม"
                                    name="sStart"
                                    small
                                    fullWidth
                                    onChange={(e) => setStartDate(e)}
                                    maxDate={EndDate ? EndDate : undefined}
                                    onClearFields={(e) => setStartDate(undefined)}
                                />
                            </Grid>
                            <Grid item xs={12} sm={3} lg={3.5} sx={{ mt: "0.5em", pr: "1em" }}>
                                <DatePickerUI
                                    label="ถึงวันที่"
                                    name="sEnd"
                                    small
                                    fullWidth
                                    onChange={(e) => setEndDate(e)}
                                    minDate={StartDate ? StartDate : undefined}
                                    onClearFields={(e) => setEndDate(undefined)}
                                />
                            </Grid>
                            <Grid item xs={12} sm={3} md={4} lg={3.5} xl={4} sx={{ pr: "0.5em" }}>
                                <InputForm
                                    fullWidth
                                    name={"sSearch"}
                                    maxLength={100}
                                    label="ค้นหา"
                                    onKeyPress={NewsList}
                                    mx={0}
                                />
                            </Grid>

                            <Grid item xs={12} sm={3} md={2} lg={1.5} xl={1} sx={{ display: "flex", justifyContent: "center" }}>
                                <BtnCustomText
                                    txt="ค้นหา/Search"
                                    onClick={NewsList}
                                />
                            </Grid>
                        </Grid>

                        <Grid item xs={8} sm={7} md={5} sx={{ px: { xs: "4em", xl: "5em" }, }}>
                            <AutoCompleteSelect
                                required
                                mx={0}
                                name="nSort"
                                label={"เรียงโดย"}
                                width={{ width: "100%" }}
                                options={Sort}
                                IsShowIcon={false}
                                defaultValue={Sort[0]}
                                onChange={(v) => {
                                    form.setValue('nSort', v ? v : null)
                                    NewsList();
                                }}
                            />
                        </Grid>
                    </Grid> */}
                    <Grid ref={oFormRef}>
                        <Grid container
                            sx={{
                                // display: "flex",
                                // justifyContent: "left",
                                // mx: "3%"
                                // p: 3,
                                // padding: PER_PAGE != data.length ? "0 3em" : "",
                                px: { xs: "2em", lg: "4em" },
                                justifyContent: PER_PAGE == data.length ? "center" : "left"
                            }}
                        >
                            {(data.length > 0 ? data : []).map((e, i) => {
                                if (data.length > 0) {
                                    return (

                                        <Grid item xs={12} sm={6} md={4} lg={2.4} sx={{ p: { xs: "2em", lg: "1em" }, height: "100%" }}>
                                            <Card sx={{ height: "100%", minHeight: 50, position: "relative" }}>
                                                <Grid className="NewsList" style={{ width: "100%", height: "100%" }}>
                                                    <Grid className="img-hover-zoom-NewsList" style={{ width: "100%", height: "100%" }}>
                                                        <a onClick={() => checkFile(e.sFilePath)}
                                                            onError={({ currentTarget }) => {
                                                                currentTarget.onerror = null;
                                                                currentTarget.href = defaultImage;
                                                            }}
                                                        >
                                                            <DivImage sURLImage={(e.sFilePath) || defaultImage}></DivImage>
                                                        </a>                   
                                                    </Grid>
                                                    <footer>
                                                        <a style={{ backgroundColor: "rgb(247 134 42)", margin: "-10%", zIndex: 10 }}>
                                                            <img
                                                                id="NewsListimg"
                                                                src={icon1}
                                                                style={{
                                                                    width: 35,
                                                                    height: 35,
                                                                    filter: "grayscale(1) invert(1)",
                                                                }}
                                                            />
                                                        </a>
                                                    </footer>
                                                </Grid>
                                                <Grid style={{
                                                    padding: "8px",
                                                    paddingBottom: "32px",
                                                    margin: "8px 0",
                                                    minHeight: "260px"
                                                }}>
                                                    <p
                                                        className="titleNews"
                                                    >
                                                        {(e.sTitle)}
                                                    </p>
                                                    <p
                                                        className=""
                                                        style={{
                                                            whiteSpace: "normal",
                                                            wordBreak: "break-word",
                                                            overflow: "hidden",
                                                            textOverflow: "ellipsis",
                                                            WebkitLineClamp: 5,
                                                            display: "-webkit-box",
                                                            WebkitBoxOrient: "vertical",
                                                            fontSize: "14px",
                                                            color: "#3d3d3d"
                                                        }}
                                                    >
                                                        {(e.sDesc)}
                                                    </p>
                                                </Grid>
                                                <Box display="flex" justifyContent="center" sx={{ marginTop: "2%", marginBottom: "5%", position: "absolute", bottom: 0, width: "100%", left: 0, right: 0, padding: "0 8px" }}>
                                                    <BtnSeeDetail
                                                        fullWidth
                                                        color="rgb(237, 108, 2)"
                                                        hover="#de6808"
                                                        txt="อ่านต่อ/Read more"
                                                        onClick={() => gotoNewsList(e.sID)}
                                                    />
                                                </Box>
                                            </Card>
                                        </Grid >

                                    )
                                }
                            })}

                        </Grid>


                    </Grid>
                    <NoDataUI isShow={showNodata} />

                    <Grid
                        container
                        justifyContent="center"
                        alignItems="center"
                        sx={{ my: "2em" }}
                    >
                        <Pagination
                            count={count}
                            page={Page}
                            variant="outlined"
                            shape="rounded"
                            showFirstButton
                            showLastButton
                            size={sScreenSize == 'lg' ? "medium" : "small"}
                            onChange={handleChange}
                        />
                    </Grid>
                </FormProvider >
            </Grid >
        </Grid >
    );
}

interface NewsList {
    dataSource?: any;
    onClick?: (value: any) => void;
};