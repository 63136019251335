import Grid from "@mui/material/Grid";
import React,{ useEffect } from "react";
import "./WhatsNewsHome.css";
import img1 from "../../Layout/img/activity-1.jpg";
import img2 from "../../Layout/img/activity-2.jpg";
import { BtnSeeDetail } from "../Button";
import { FaArrowRight } from "react-icons/fa";
import { Box, Typography } from "@mui/material";
import { useNavigate } from "react-router";
import AOS from "aos";

const data = [
  {
    nID: 1,
    sPath: img1,
    sTitle: "กิจกรรมของ OR Academy ทั้งที่จัดขึ้นภายในและภายนอกสถาบัน",
    sDesc: "OR Academy ให้บริการจัดอบรมหลักสูตรทั้งแบบ Class Room และ Online ดูรายละเอียดและสมัครเรียนได้แล้ววันนี้",
  },
  {
    nID: 2,
    sPath: img2,
    sTitle: "กิจกรรมของ OR Academy ทั้งที่จัดขึ้นภายในและภายนอกสถาบัน",
    sDesc: "OR Academy ให้บริการจัดอบรมหลักสูตรทั้งแบบ Class Room และ Online ดูรายละเอียดและสมัครเรียนได้แล้ววันนี้",
  },
];

export function WhatsNewsHome(props: WhatsNewsHome) {
  const { dataSource } = props;
  const navigate = useNavigate();

  useEffect(() => {
    AOS.init();
    AOS.refresh();
}, []);

  const gotoDetail = (sID: string) => {
    navigate(process.env.REACT_APP_API_URL + `WhatsNewDetails?nIDC=${encodeURI(sID)}`);
  };
  return (
    <>
      <Grid container columnSpacing={0} rowSpacing={0} sx={{ "&.MuiGrid-root": { marginTop: 0 } }}>
        {(dataSource.length > 0 ? dataSource : []).map((m, i) => {
          return (
            <>
              <Grid key={m.nID} xs={12} md={dataSource.length === 1 ? 12 : 6} item sx={{ borderRadius: "unset" }}>
                <div
                  className="blog-card spring-fever"
                  style={{
                    background: `url(${m.sPath}) 100% center no-repeat`,
                    backgroundSize: "cover",
                    backgroundPosition: "center center",
                  }}>
                  <div
                    className="card-info"
                    style={{
                      color: "white",
                      textAlign: "left",
                    }}>
                    <div className="ArrowIcon" style={{ width: "50px", marginRight: "20px", marginTop: "37px" }}>
                      <FaArrowRight size="30" />
                    </div>
                    <div>
                      <p
                        className="p1"
                        style={{
                          whiteSpace: "normal",
                          marginBottom: "0px",
                        }}>
                        {m.sTitle}
                      </p>
                      <p
                        className="p2"
                        style={{
                          whiteSpace: "normal",
                        }}>
                        {m.sDesc}
                      </p>
                      {/* <Grid item xs={12}> */}
                        <Box className="BoxButton" data-aos="fade-up" data-aos-duration="1000">
                          <BtnSeeDetail color="primary" txt="รายละเอียดเพิ่มเติม/Detail" onClick={() => gotoDetail(m.sID)} />
                        </Box>
                      {/* </Grid> */}
                    </div>
                  </div>
                  <div className="gradient-overlay"></div>
                  <div className="color-overlay"></div>
                </div>
              </Grid>
            </>
          );
        })}
      </Grid>
    </>
  );
}

interface WhatsNewsHome {
  dataSource?: any;
  onClick?: (value: any) => void;
}
