import { Grid, Typography } from '@mui/material'
import { GridColumns } from '@mui/x-data-grid-pro';
import moment from 'moment';
import { useEffect, useState } from 'react';
import { Axios } from 'src/CommonFunction/TS_Axios';
import { FnBlock_UI, GetQueryString } from 'src/CommonFunction/TS_function';
import { DataGridMui, PaginationInterface, initRows, FilterFieldInterface } from "src/Components/Table/DataGridMui";
import { BtnCustomText } from '../Button';
import { Process_System } from "src/CommonFunction/TS_function";
import { AlertMsg, AlertTitle, SwAlert } from '../Alert/Sweetalert';
import { useNavigate } from 'react-router';
import { BreadcrumbsUI } from '../BreadcrumbsUI';

const RenewStaff = () => {
    const { BlockUI, UnBlockUI } = FnBlock_UI();
    const navigate = useNavigate();

    const [dataRenew, setDataRenew] = useState<any[]>([]);
    const [dataSelect, setDataSelect] = useState<any[]>([]);

    const [dataRow, setDataRow] = useState<PaginationInterface>({
        ...initRows,
    });
    const nID = GetQueryString('str');
    const nProfileID = GetQueryString('pid');
    const sMode = GetQueryString('mode');

    useEffect(() => {
        getData();
    }, [])

    const getData = async () => {
        await GetDataRenewStaff(dataRow);
        let nIDs = await getDecrypt(nID);
        let nProfileIDs = await getDecrypt(nProfileID);
        let sModes = await getDecrypt(sMode);
        let param = {
            nID: nIDs,
            nProfileID: nProfileIDs,
            sMode: sModes,
        }
        AutoLogin(param)
    }

    const GetDataRenewStaff = async (p: PaginationInterface) => {

        var param = {
            nPageSize: p.nPageSize || 10,
            nPageIndex: p.nPageIndex || 1,
            sSortExpression: p.sSortExpression || "",
            sSortDirection: p.sSortDirection.toUpperCase() || "",
        };

        let result = {} as any;
        await Axios(
            `RenewStaff/GetData_RenewStaff`,
            param || {},
            "Post",
            async (res) => {
                result = res;
                setDataRenew(res.lstobjReNew);
                setDataRow({
                    ...p,
                    arrRows: res.lstobjReNew,
                    nDataLength: res.nDataLength > 10 ? res.nDataLength : 10,
                    nPageIndex: res.nPageIndex,
                });
            },
            null,
            BlockUI,
            UnBlockUI,
            () => { }
        );
        return result;
    };

    const getDecrypt = async (param) => {
        let result = null as any;
        await Axios(
            `Master/Decrypt`,
            "sValue=" + param,
            "Get",
            async (res) => {
                result = res;
            },
            null,
            BlockUI,
            UnBlockUI,
            () => { }
        );
        return result;
    };

    const AutoLogin = async (param) => {
        let result = null as any;
        await Axios(
            `AuthenLogin/AutoLog`,
            param,
            "post",
            async (res) => {
                if (res.Status === Process_System.process_Success) {
                    if (res.token) {
                        localStorage.setItem(process.env.REACT_APP_JWT_KEY as string, res.token);
                        // GotoPage(process.env.REACT_APP_API_URL + res.sRedirect);
                    }
                    // else if (res.sRedirect) {
                    //     // GotoPage(process.env.REACT_APP_API_URL + res.sRedirect);
                    // }

                }
                else {
                    (document.getElementById("lbMessage") as HTMLInputElement).innerText = res.Message;
                }
            },
            null,
            BlockUI,
            UnBlockUI,
            () => { }
        );
        return result;
    };

    const saveRenew = async (param) => {
        let result = null as any;
        await Axios(
            `RenewStaff/GetSave_RenewStaff`,
            param = {
                lstobjReNewSave: param
            },
            "Post",
            async (res) => {
                result = res;
                if (res.Status === "Success") {
                    SwAlert.Success(AlertTitle.Success, AlertMsg.SaveComplete);
                    navigate(process.env.REACT_APP_API_URL + "home");
                } else {
                    SwAlert.Warning(AlertTitle.Warning, res.Message);
                }
            },
            null,
            BlockUI,
            UnBlockUI,
            null
        );
        return result;
    };

    const onSave = () => {
        if (dataSelect.length > 0) {
            saveRenew(dataSelect)
        }
    }
    const onSelect = (e) => {
        setDataSelect(e)
    }

    const dataColumn: GridColumns = [
        {
            field: "nRow",
            headerName: "ที่",
            headerAlign: "center",
            align: "center",
            sortable: false,
            minWidth: 5,
        },
        {
            field: "sPersonalID",
            headerName: "เลขประจำตัว",
            headerAlign: "center",
            align: "center",
            sortable: false,
            flex: 0.5,
        },
        {
            field: "sName",
            headerName: "ชื่อ - นามสกุล",
            headerAlign: "center",
            align: "left",
            sortable: false,
            flex: 1,
        },
        {
            field: "sPosition",
            headerName: "ตำแหน่ง",
            headerAlign: "center",
            align: "left",
            sortable: false,
            flex: 1,
        },
        {
            field: "sEmail",
            headerName: "Email",
            headerAlign: "center",
            align: "left",
            sortable: false,
            flex: 1,
        },
    ];

    return (
        <>
            <Grid container>
                <Grid item xs={12}>
                    <BreadcrumbsUI
                        isShowHome={false}
                        items={[
                            {
                                menuName: "ข้อมูลพนักงาน/Staff",
                                IsOnClick: false
                            },

                        ]}
                    />
                </Grid>
               
                <Grid item xs={12} sx={{ m: "3em 3em 0em 3em" }}>
                    <DataGridMui
                        HiddenToolHead
                        isShowCheckBox
                        Columns={dataColumn}
                        Rows={dataRow}
                        OnLoadData={(p) => GetDataRenewStaff(p)}
                        backgroundHeaderCustom={"#0a286c"}
                        sxCustomHeader={{ color: "#FFF" }}
                        onSelect={(e) => onSelect(e)}
                    />
                </Grid>
                <Grid item xs={12} sx={{ m: "2em", display: "flex", justifyContent: "center", alignItems: "center" }}>
                    <BtnCustomText txt="บันทึก" backgroundColor="green" width="100px" onClick={onSave} />
                </Grid>
            </Grid>
        </>
    )
}

export default RenewStaff