import { useEffect, useState } from "react";
import { Axios,AxiosGetAsync } from "src/CommonFunction/TS_Axios";
import Grid from "@mui/material/Grid";
import { ContactUsForm } from "src/Components/System/ContactUs/ContactUsForm";
import BlockUI1, { FnBlock_UI } from "src/Components/BlockUI";
import { ParseHtml } from "src/CommonFunction/TS_function";
import { MenuBar } from "src/Components/System/NavBar/MenuBar";
import "src/Components/System/ContactUs/ContactUsForm.css";
import YouTube, { YouTubeProps } from "react-youtube";
import ReactPlayer from "react-player";
import { Banner } from "src/Components/Banner";
import { useLocation } from "react-router";
var getYouTubeID = require('get-youtube-id');
const i18nField = "entities.contactUs.fields";
const ContactUs = () => {
  const [lstData, setData] = useState<any[]>([]);
  const [lstSettingOBA, setSettingOBA] = useState<any[]>([]);
  const [lstSettingFire, setSettingFire] = useState<any[]>([]);
  const [banner, setBanner] = useState([] || undefined); 
  const { BlockUI, UnBlockUI } = FnBlock_UI();
  const [sNameTH, setNameTH] = useState(""); 
  const [sNameENG, setNameENG] = useState(""); 

  const location = useLocation();
  const searchParams = location.search;
  const sID = new URLSearchParams(searchParams).get('str') as string;

  const GetContactUs = async () => {
    let result = {} as any;
    await Axios(
      `Master/GetContactUs`,
      {},
      "GET",
      async (res) => {
        result = res;
      },
      null,
      BlockUI,
      UnBlockUI,
      () => {}
    );
    return result;
  };
  const GetSetting = async () => {
    let result = {} as any;
    await Axios(
      `Master/GetSettingContact`,
      {},
      "GET",
      async (res) => {
        result = res;
      },
      null,
      BlockUI,
      UnBlockUI,
      () => {}
    );
    return result;
  };
  const opts: YouTubeProps["opts"] = {
    height: "390",
    width: "640",
    playerVars: {
      autoplay: 0,
    },
  };

  const onPlayerReady: YouTubeProps["onReady"] = (event) => {
    event.target.pauseVideo();
  };
  const GetData = async () => {
    let resBanner = await loadBanner();
    let resContact = await GetContactUs();
    let resSetting = await GetSetting();
    setData(resContact.lstContent);
    setNameTH(resContact.sContentNameTH);
    setNameENG(resContact.sContentNameENG);
    setBanner(resBanner);
    setSettingOBA(resSetting.lstAddressOBA);
    setSettingFire(resSetting.lstAddressFire);
  };

  const loadBanner = async () => {
    let result = {} as any;
        result = await AxiosGetAsync("Master/GetBannerCMS", {  sContentID: sID });       
        return result;
  };
  useEffect(() => {
    GetData();
  }, []);
  //#region Detail
  const BoxCMSDetail = () => {
    return (
      <Grid item sx={{ mx: "2%", marginY: "2rem", pl: 0 }}>
        {(lstData ? (lstData.length > 0 ? lstData : []) : []).map(
          (
            m //1=content html, 2 = image, 3 = video, 4 = link
          ) =>
            m.nContentTypeID == 1 ? (
              <>
                <Grid item xs={24} md={12} lg={12}>
                  <p
                    className="sContentHTML"
                    style={{
                      whiteSpace: "normal",
                      textOverflow: "ellipsis",
                      margin: "5px 10px",
                    }}
                  >
                    {ParseHtml(m.sContentHTML)}
                  </p>
                </Grid>
              </>
            ) : m.nContentTypeID == 2 ? (
              <>
                <Grid
                  item
                  xs={24}
                  md={12}
                  lg={12}
                  sx={{ marginBottom: "3%", textAlign: "center" }}
                >
                  <div
                  //className="img-hover-zoom-contact"
                  >
                    <img src={m.sFilePath} style={{width:"100%"}} />
                  </div>

                  <p className="cont-description">{m.sDesc}</p>
                </Grid>
              </>
            ) : m.nContentTypeID == 3 ? (
              <>
                <Grid
                  item
                  xs={24}
                  md={12}
                  lg={12}
                  sx={{ marginBottom: "3%", textAlign: "center" }}
                >
                  <ReactPlayer
                    url={m.sFilePath}
                    style={{ textAlign: "center" }}
                    width="auto"
                    height="auto"
                    controls={true}
                  />
                  <p className="cont-description">{m.sDesc}</p>
                </Grid>
              </>
            ) : (
              <>
                <Grid
                  item
                  xs={24}
                  md={12}
                  lg={12}
                  sx={{ marginBottom: "3%", textAlign: "center" }}
                >
                  <YouTube
                    videoId={getYouTubeID(m.sURL)}
                    opts={opts}
                    onReady={onPlayerReady}
                  />
                  <p className="cont-description">{m.sDesc}</p>
                  {/* <div className="cont-text">
                                                <a href={m.sURL} target="_blank" className="link-title">{m.sDesc}</a>
                                                <p className="cont-description">{(m.sDesc)}</p>
                                            </div> */}
                </Grid>
              </>
            )
        )}
      </Grid>
    );
  };
  const BoxMap = (data) => {
    return (
      <Grid item xs={24} md={12} lg={12}>
        {data.data.sMap != "" ? (
          <iframe
            src={data.data.sMap}
            width="100%"
            height="350"
            frameBorder={1}
            style={{ border: "1px solid #999999", backgroundColor: "#F0EDE5" }}
            allowFullScreen
          ></iframe>
        ) : (
          ""
        )}
      </Grid>
    );
  };
  const BoxAddress = (data) => {
    let mailTo = ("mailto:") +data.data.sEmail;
    return (
      <Grid item xs={24} md={12} lg={12}>
        <table className="contact-info">
          <tr className="valign-top">
            {/* <td rowSpan={2}>
              <i className="fa fa-location-arrow fa-3x text-success"></i>
            </td> */}
            <td>
              <div className="place-TH">{data.data.sNameTH}</div>
            </td>
          </tr>
          <tr className="valign-top">
            <td>
              <div className="place-EN">{data.data.sNameEN}</div>
            </td>
          </tr>
            <tr className="valign-top">
            <td>
              <div className="place-EN">{data.data.sNameTH2}</div>
            </td>
          </tr>
          <tr>
            <td>&nbsp;</td>
          </tr>
          <tr className="valign-top">
            {/* <td rowSpan={2}>
              <i className="fa fa-map-marker fa-3x text-danger"></i>
            </td> */}
            <td>
              <div className="address-TH">{data.data.sAddressTH}</div>
            </td>
          </tr>
          <tr className="valign-top">
            <td>
              <div className="address-EN">{data.data.sAddressEN}</div>
            </td>
          </tr>
          <tr>
            <td>&nbsp;</td>
          </tr>
          <tr>
            {/* <td></td> */}
            <td>
              <a
                href={mailTo}
                target="_top"
                className="address-TH text-primary"
              >
                {data.data.sEmail}
              </a>
            </td>
          </tr>
          <tr>
            <td>&nbsp;</td>
          </tr>
          <tr>
            {/* <td></td> */}
            <td>
              <div className="address-TH">{data.data.sTel}</div>
              <div className="address-TH">{data.data.sTelExt}</div>
            </td>
          </tr>
          <tr>
            <td>&nbsp;</td>
          </tr>
        </table>
      </Grid>
    );
  };
  //#endregion Detail

  return (
    <>
      <Grid
        container
        columnSpacing={0}
        rowSpacing={0}
        sx={{ "&.MuiGrid-root": { marginTop: 0, display: "block" } }}
      >
        {banner.length > 0 ? (
          <Grid item xs={12}>
            <Banner
              dataSource={banner}
              height={50}
              textTH={sNameTH}
              textENG={sNameENG}
            />
          </Grid>
        ) : (
          <></>
        )}

        <Grid item>
          <MenuBar sContentID={sID} />
        </Grid>
        <Grid item xs={24} md={12} lg={12} style={{ margin: "30px" }}>
          <Grid container>
            <Grid item xs={12} md={6} lg={6}>
              <BoxAddress data={lstSettingOBA} />
            </Grid>

            <Grid item xs={12} md={6} lg={6}>
              <BoxMap data={lstSettingOBA} />
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={24} md={12} lg={12} style={{ margin: "30px" }}>
          <Grid container>
            <Grid item xs={12} md={6} lg={6}>
              <BoxAddress data={lstSettingFire} />
            </Grid>

            <Grid item xs={12} md={6} lg={6}>
              <BoxMap data={lstSettingFire} />
            </Grid>
          </Grid>
        </Grid>

        <Grid item xs={24} md={12} lg={12} style={{ margin: "30px" }}>
          <BoxCMSDetail />
        </Grid>
        <Grid item>
          <ContactUsForm data={lstSettingOBA} dataFire={lstSettingFire}></ContactUsForm>
        </Grid>
        <Grid item>
        </Grid>
      </Grid>
    </>
  );
};
export default ContactUs;
