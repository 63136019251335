import { yupResolver } from "@hookform/resolvers/yup";
import { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router";
import { FnBlock_UI } from "../BlockUI";
import * as yup from "yup";
import { Axios } from "src/CommonFunction/TS_Axios";
import { FormProvider, useForm } from "react-hook-form";
import { Box, Button, Grid, Radio } from "@mui/material";
import { BreadcrumbsUI } from "../BreadcrumbsUI";
import { DataGridMui, initRows, PaginationInterface } from "../Table/DataGridMui";
import { GridColumns } from '@mui/x-data-grid';
import { BtnAddOnTable, BtnCancelForm, BtnEditOnTable, BtnSaveForm } from "../Button";
import { Encrypt, IsNumberic, Process_System, ResultAPI,lnkToHomePage } from "src/CommonFunction/TS_function";
import { AlertMsg, AlertTitle, DialogConfirm, DialogConfirmDelete, SwAlert } from "src/Components/Alert/Sweetalert";
import { InputForm } from "../FormItem/Input";
import AutoCompleteSelect from "../FormItem/AutoCompleteSelect";
import RadioFormItem from "../FormItem/RadioFormItem/RadioIFormtem";
import yupFormSchemas from "src/Components/FormItem/yup/yupFormSchemas";
import InputNumberForm from "../FormItem/InputNumber";
import { fromUnixTime } from "date-fns/esm";
import { i18n } from "src/i18n";

const ManageStaffForm = () => {
    const navigate = useNavigate();
    const { BlockUI, UnBlockUI } = FnBlock_UI();
    const [loadingTable, setLoadingTable] = useState(true);
    const [arrProfile, setarrProfile] = useState([]);
    const searchParams = location.search;
    const IsShowPassword = false;
    const [nUserTypeID, setUserTypeID] = useState(0);
    const sID = new URLSearchParams(searchParams).get('sID');
    const [sMode, setMode] = useState("Add");
    const [objStaff, setStaff] = useState(null);
    const [sTypeID, setTypeID] = useState("citizenID");

    //Regex for form
    var Regex10 = /^\d{10}$/;
    var Regex13 = /^\d{13}$/;
    var Regex9 = /^[a-zA-Z0-9]{9}$/;

    const [objSchema, setSchema] = useState<any>({
        sPrefixTH: yupFormSchemas.object("คำนำหน้า (ภาษาไทย)/Prefix (TH)", { required: true }),
        sFullNameTH: yupFormSchemas.string("ชื่อ - นามสกุล (ภาษาไทย)/Name-Surname (TH)", { required: true }),
        sPrefixEN: yupFormSchemas.object("คำนำหน้า (ภาษาอังกฤษ)/Prefix (EN)", { required: true }),
        sFullNameEN: yupFormSchemas.string("ชื่อ - นามสกุล (ภาษาอังกฤษ)/Name-Surname (EN)", { required: true }),
        sTypeID: yupFormSchemas.string("เลขประจำตัว", { required: true }),
        sIdentityID: yupFormSchemas.string("เลขที่บัตรประชาชน / Citizen ID", { required: (sTypeID == "citizenID" ? true : false), matches: Regex13, labelmatches: "เลขที่บัตรประชาชน / Citizen ID ไม่ถูกต้อง" }),
        sIdentityPassID: yupFormSchemas.string("เลขที่หนังสือเดินทาง / Passport ID", { required: (sTypeID == "passportID" ? true : false), matches: Regex9, labelmatches: "เลขที่หนังสือเดินทาง / Passport ID ไม่ถูกต้อง" }),
        sGender: yupFormSchemas.string("เพศ/Gender", { required: true }),
        sTel: yupFormSchemas.string("เบอร์โทรศัพท์", { required: true, min: 9 }),
        sEmail: yupFormSchemas.string("อีเมล/E-mail", { required: true }),
        sPosition: yupFormSchemas.string("ตำแหน่ง", { required: true }),
        sUsername: yupFormSchemas.string("ผู้ใช้งาน/Username", { required: true }),
        sPassword: yupFormSchemas.string("รหัสผ่าน/Password", { required: true }),
        sAction: yupFormSchemas.string("สถานะ", { required: true }),
    });
    const schema = yup.object().shape(objSchema);
    const form = useForm({
        resolver: yupResolver(schema),
        shouldUnregister: false,
        shouldFocusError: true,
        mode: "all"
    });

    const arrPrefixTH = [
        { "value": "0", "label": "คุณ" },
        { "value": "1", "label": "นาย" },
        { "value": "2", "label": "นาง" },
        { "value": "3", "label": "น.ส." }
    ]

    const arrPrefixEN = [
        { "value": "1", "label": "Mr." },
        { "value": "2", "label": "Mrs." },
        { "value": "3", "label": "Ms." },
    ]

    const arrOptionsTypeID = [
        { "value": "citizenID", "label": "เลขที่บัตรประชาชน / Citizen ID" },
        { "value": "passportID", "label": "เลขที่หนังสือเดินทาง / Passport ID" },
    ]

    const arrOptionsGender = [
        { "value": "M", "label": "ชาย" },
        { "value": "F", "label": "หญิง" },
    ]

    const arrOptionsAction = [
        { "value": "Y", "label": "ใช้งาน" },
        { "value": "N", "label": "ไม่ใช้งาน" },
    ]

    useEffect(() => {
        getUserData()
        var nType = new URLSearchParams(searchParams).get('sUserTypeID')
        if (nType) {
            setUserTypeID(+nType)
        }
        if (sID) {
            loadData()
        } else {
            form.setValue("sTypeID", "citizenID")
            form.setValue("sGender", "M")
            form.setValue("sAction", "Y")
        }
    }, [])

    useEffect(() => {
        if (nUserTypeID == 4) {
            var schema = {
                ...objSchema,
                sEmployee: yupFormSchemas.string("รหัสพนักงาน", { required: true }),
                sDepartment: yupFormSchemas.string("หน่วยงานที่ปฏิบัติงาน", { required: true }),
                sCompany: yupFormSchemas.string("สถานที่ปฏิบัติงาน", { required: true }),
            }
            setSchema(schema)
        }
    }, [nUserTypeID])

    const getUserData = () => {
        Axios("Master/onPageLoad", {}, "GET", async (result) => {
            if (result.nUserTypeID) {
                setUserTypeID(result.nUserTypeID)
            } else if (result.IsExpired) {
                SwAlert.Warning(undefined, i18n("common.SessionTimeOut"), lnkToHomePage);
            }
        })

    }

    const loadData = () => {
        Axios("StaffPage/GetData_MangeStaffDetail", { sID: sID }, "POST", async (res) => {
            setStaff(res.objMangeStaffDetail)
            var data = res.objMangeStaffDetail
            let rdTypeID = data.sPersonalID ? "citizenID" : "passportID"

            setTypeID(rdTypeID)

            var prefixTH = arrPrefixTH.find(f => f.label == data.sPrefixTH)
            var prefixEN = arrPrefixEN.find(f => f.label == data.sPrefixEN)
            form.setValue("sEmployee", data.sEmployeeCode)
            form.setValue("sPrefixTH", prefixTH ? prefixTH : { "value": "0", "label": "คุณ" })
            form.setValue("sFullNameTH", data.sNameTH)
            form.setValue("sPrefixEN", prefixEN ? prefixEN : { "value": "1", "label": "Mr." })
            form.setValue("sFullNameEN", data.sNameEN)
            form.setValue("sTypeID", data.sPersonalID ? "citizenID" : "passportID")
            form.setValue("sIdentityID", data.sPersonalID)
            form.setValue("sIdentityPassID", data.sPassportID)
            form.setValue("sGender", data.cGender)
            form.setValue("sTel", data.sTel)
            form.setValue("sEmail", data.sEmail)
            form.setValue("sPosition", data.sPosition)
            form.setValue("sDepartment", data.sUnitName)
            form.setValue("sCompany", data.sOfficeName)
            form.setValue("sUsername", data.sUserName)
            form.setValue("sPassword", data.sPassword)
            form.setValue("sAction", data.sStatus)
        }, null, BlockUI, UnBlockUI, null)
    }

    const getEncrypt = async (param) => {
        let result = null as any;
        await Axios(
            `Master/Encrypt`,
            "sValue=" + param,
            "Get",
            async (res) => {
                result = res;
            },
            null,
            BlockUI,
            UnBlockUI,
            null
        );
        return result;
    };

    const onSubmit = async () => {
        let oForm = form.getValues();
        var data = {
            nUserID: objStaff ? objStaff['nUserID'] : 0,
            nUserTypeID: nUserTypeID ? +nUserTypeID : 0,
            nStaffID: objStaff ? objStaff['nStaffID'] : 0,
            sStaffID: objStaff ? await getEncrypt(objStaff['nStaffID']) : await getEncrypt(0),
            sNameTH: oForm.sFullNameEN,
            sNameEN: oForm.sFullNameTH,
            sUserName: oForm.sUsername,
            sPosition: oForm.sPosition,
            sPersonalID: oForm.sTypeID == "citizenID" ? oForm.sIdentityID : null,
            sPassportID: oForm.sTypeID == "passportID" ? oForm.sIdentityPassID : null,
            sStatus: oForm.sAction,
            sEmail: oForm.sEmail,
            sPrefixTH: oForm.sPrefixTH.label,
            sPrefixEN: oForm.sPrefixEN.label,
            cGender: oForm.sGender,
            sTel: oForm.sTel,
            sPassword: oForm.sPassword,
            sUnitName: oForm.sDepartment,
            sOfficeName: oForm.sCompany,
            sEmployeeCode: oForm.sEmployee
        }
        DialogConfirm(() => {
            Axios("StaffPage/SaveData_MangeStaffDetail", data, "POST", async (result) => {
                ResultAPI(true, result, null, () => {
                    if (result.Status === Process_System.process_Success) {
                        SwAlert.Success(AlertTitle.Success, AlertMsg.SaveSubmitComplete, () => {
                            navigate(process.env.REACT_APP_API_URL + `ManageStaffTable`);
                        });
                    }
                    else {
                        SwAlert.Warning(AlertTitle.Warning, result.Message, () => { });
                    }
                })
            },
                null,
                BlockUI,
                UnBlockUI,
                null
            );
        })
    }

    const setValuepersonID = (event) => {

        setTypeID(event)
        console.log("event", event)
        console.log("objStaff", objStaff)
        switch (event) {
            case "citizenID":
                delete objSchema["sIdentityPassID"];
                delete objSchema["sIdentityID"];
                objSchema["sIdentityID"] = yupFormSchemas.string("เลขที่บัตรประชาชน / Citizen ID", { required: true, matches: Regex13, labelmatches: "เลขที่บัตรประชาชน / Citizen ID ไม่ถูกต้อง" });
                objSchema["sIdentityPassID"] = yupFormSchemas.string("เลขที่หนังสือเดินทาง / Passport ID", { required: false, matches: Regex9, labelmatches: "เลขที่หนังสือเดินทาง / Passport ID ไม่ถูกต้อง" });
                form.setValue("sIdentityPassID", "")
                form.clearErrors("sIdentityPassID")
                break;
            case "passportID":
                delete objSchema["sIdentityPassID"];
                delete objSchema["sIdentityID"];
                objSchema["sIdentityID"] = yupFormSchemas.string("เลขที่บัตรประชาชน / Citizen ID", { required: false, matches: Regex13, labelmatches: "เลขที่บัตรประชาชน / Citizen ID ไม่ถูกต้อง" });
                objSchema["sIdentityPassID"] = yupFormSchemas.string("เลขที่หนังสือเดินทาง / Passport ID", { required: true, matches: Regex9, labelmatches: "เลขที่หนังสือเดินทาง / Passport ID ไม่ถูกต้อง" });
                form.setValue("sIdentityID", "")
                form.clearErrors("sIdentityID")
                break;
        }
        // if (objStaff) {
        //     var staff = objStaff['sPersonalID'] ? "citizenID" : "passportID"
        //     if (event == staff) {
        //         if (event == "citizenID") {
        //             delete objSchema["sIdentityPassID"];
        //             delete objSchema["sIdentityID"];
        //             objSchema["sIdentityID"] = yupFormSchemas.string("เลขที่บัตรประชาชน / Citizen ID", { required: true, matches: Regex13, labelmatches: "เลขที่บัตรประชาชน / Citizen ID ไม่ถูกต้อง" }),
        //                 objSchema["sIdentityPassID"] = yupFormSchemas.string("เลขที่หนังสือเดินทาง / Passport ID", { required: false, matches: Regex9, labelmatches: "เลขที่หนังสือเดินทาง / Passport ID ไม่ถูกต้อง" }),
        //                 form.setValue("sIdentityID", objStaff['sPersonalID'])
        //         } else {
        //             delete objSchema["sIdentityPassID"];
        //             delete objSchema["sIdentityID"];
        //             objSchema["sIdentityID"] = yupFormSchemas.string("เลขที่บัตรประชาชน / Citizen ID", { required: false, matches: Regex13, labelmatches: "เลขที่บัตรประชาชน / Citizen ID ไม่ถูกต้อง" }),
        //                 objSchema["sIdentityPassID"] = yupFormSchemas.string("เลขที่หนังสือเดินทาง / Passport ID", { required: true, matches: Regex9, labelmatches: "เลขที่หนังสือเดินทาง / Passport ID ไม่ถูกต้อง" }),
        //                 form.setValue("sIdentityPassID", objStaff['sPassportID'])
        //         }
        //         setSchema({ ...objSchema });
        //     }
        // }
        setSchema({ ...objSchema });
    }

    const onChangePrefix = (e, type) => {

        if (e) {
            if (e.value == 1) {
                form.setValue("sGender", "M")
            } else if (e.value == 2 || e.value == 3) {
                form.setValue("sGender", "F")
            }
            if (type == 'en') {

                var objPrefix = arrPrefixTH.find(f => f.value == e.value);
                if (form.getValues("sPrefixTH") == null) {
                    if (objPrefix) {
                        form.setValue("sPrefixTH", objPrefix)
                    }
                } else if (form.getValues("sPrefixTH").value != 0) {

                    if (objPrefix) {
                        form.setValue("sPrefixTH", objPrefix)
                    }
                }
            } else {
                var objPrefix = arrPrefixEN.find(f => f.value == e.value);
                if (form.getValues("sPrefixEN") == null) {
                    if (objPrefix) {
                        form.setValue("sPrefixEN", objPrefix)
                    }
                } else if (form.getValues("sPrefixEN").value != 0) {

                    if (objPrefix) {
                        form.setValue("sPrefixEN", objPrefix)
                    }
                }
            }
        }

    }

    return (
        <FormProvider {...form}>
            <Grid container spacing={2} direction="row" justifyContent="center" alignItems="center">
                <Grid item xs={12}>
                    <BreadcrumbsUI
                        isShowHome={false}
                        items={[
                            {
                                menuName: "ข้อมูลพนักงาน/Staff",
                                path: "ManageStaffTable",
                                IsOnClick: true
                            },

                        ]}
                    />
                </Grid>
                <Grid container spacing={2} direction="row" justifyContent="center" alignItems="center" sx={{ p: "4rem", pt: 4 }}>
                    {nUserTypeID == 4 &&
                        <Grid item container spacing={5} direction="row">
                            <Grid item md={3} xs={12}>
                                <InputForm
                                    name="sEmployee"
                                    label="รหัสพนักงาน"
                                    required={true}
                                    fullWidth
                                    maxLength={10}
                                />
                            </Grid>
                        </Grid>
                    }
                    <Grid item container spacing={5} direction="row">
                        <Grid item md={3} xs={12}>
                            <AutoCompleteSelect
                                name="sPrefixTH"
                                label="คำนำหน้า (ภาษาไทย)/Prefix (TH)"
                                required={true}
                                options={arrPrefixTH}
                                width={{ width: "100%" }}
                                onChange={(e) => onChangePrefix(e, 'th')}
                            />
                        </Grid>
                        <Grid item md={6} xs={12}>
                            <InputForm
                                name="sFullNameTH"
                                label="ชื่อ - นามสกุล (ภาษาไทย)/Name-Surname (TH)"
                                required={true}
                                fullWidth
                                maxLength={200}
                            />
                        </Grid>
                    </Grid>
                    <Grid item container spacing={5} direction="row">
                        <Grid item md={3} xs={12}>
                            <AutoCompleteSelect
                                name="sPrefixEN"
                                label="คำนำหน้า (ภาษาอังกฤษ)/Prefix (EN)"
                                required={true}
                                width={{ width: "100%" }}
                                options={arrPrefixEN}
                                onChange={(e) => onChangePrefix(e, 'en')}
                            />
                        </Grid>
                        <Grid item md={6} xs={12}>
                            <InputForm
                                name="sFullNameEN"
                                label="ชื่อ - นามสกุล (ภาษาอังกฤษ)/Name-Surname (EN)"
                                required={true}
                                fullWidth
                                maxLength={200}
                            />
                        </Grid>
                    </Grid>
                    <Grid item container spacing={4} direction="row">
                        <Grid item md={6} xs={12}>
                            <RadioFormItem
                                name="sTypeID"
                                required={true}
                                label="เลขประจำตัว"
                                options={arrOptionsTypeID}
                                value={form.getValues("sTypeID")}
                                onChange={(e) => setValuepersonID(e)}
                            />
                            {sTypeID == "citizenID" &&
                                <InputForm
                                    name="sIdentityID"
                                    required={true}
                                    fullWidth
                                    maxLength={13}
                                />
                            }
                            {sTypeID == "passportID" &&
                                <InputForm
                                    name="sIdentityPassID"
                                    required={true}
                                    fullWidth
                                    maxLength={9}
                                />
                            }
                        </Grid>
                        <Grid item md={6} xs={12} sx={{ display: "flex", alignItems: "center" }}>
                            <RadioFormItem
                                name="sGender"
                                required={true}
                                label="เพศ/Gender"
                                options={arrOptionsGender}
                                value={form.getValues("sGender")}
                            />
                        </Grid>
                    </Grid>
                    <Grid item container spacing={4} direction="row">
                        <Grid item xs={12} md={6}>
                            {/* <InputNumberForm
                                name="sTel"
                                label="เบอร์โทรศัพท์"
                                integerDigits={10}
                                allowLeadingZeros={true}
                                digits={0}
                                textAlign={"left"}
                                thousandSeparator={false}
                                required={true}
                                fullWidth
                            /> */}
                            <InputForm
                                fullWidth
                                required={true}
                                name={"sTel"}
                                label="เบอร์โทรศัพท์/Telephone "
                                showClear={false}
                                maxLength={50}
                            />
                        </Grid>
                        <Grid item xs={12} md={6}>
                            <InputForm
                                name="sEmail"
                                label="E-mail"
                                required={true}
                                type={"email"}
                                fullWidth
                                maxLength={254}
                            />
                        </Grid>
                    </Grid>
                    <Grid item container spacing={4} direction="row">
                        <Grid item xs={12} md={6}>
                            <InputForm
                                name="sPosition"
                                label="ตำแหน่ง"
                                required={true}
                                fullWidth
                                maxLength={100}
                            />
                        </Grid>
                        {nUserTypeID == 4 &&
                            <Grid item xs={12} md={6}>
                                <InputForm
                                    name="sDepartment"
                                    label="หน่วยงานที่ปฏิบัติงาน"
                                    required={true}
                                    fullWidth
                                    maxLength={100}
                                />
                            </Grid>
                        }
                    </Grid>
                    {nUserTypeID == 4 &&
                        <Grid item container spacing={4} direction="row">
                            <Grid item xs={12} md={6}>
                                <InputForm
                                    name="sCompany"
                                    label="สถานที่ปฏิบัติงาน"
                                    required={true}
                                    fullWidth
                                    maxLength={100}
                                />
                            </Grid>
                        </Grid>
                    }
                    <Grid item container spacing={4} direction="row">
                        <Grid item xs={12} md={6}>
                            <InputForm
                                name="sUsername"
                                label="ผู้ใช้งาน/Username"
                                required={true}
                                fullWidth
                                maxLength={50}
                            />
                        </Grid>
                        <Grid item xs={12} md={6}>
                            <InputForm
                                name="sPassword"
                                label="รหัสผ่าน/Password"
                                required={true}
                                isPassword={true}
                                fullWidth
                                maxLength={32}
                            />
                        </Grid>
                    </Grid>
                    <Grid item container spacing={4} direction="row">
                        <Grid item xs={12} md={6}>
                            <RadioFormItem
                                name="sAction"
                                required={true}
                                label="สถานะ"
                                options={arrOptionsAction}
                                value={form.getValues("sAction")}
                            />
                        </Grid>
                    </Grid>
                    <Grid item container spacing={2} direction="row" justifyContent="center" alignItems="center">
                        <Grid item><BtnSaveForm onClick={form.handleSubmit(() => { onSubmit() })} /></Grid>
                        {/* <Grid item>
                            <BtnSaveForm onClick={form.handleSubmit((e) => onSubmit(e))} />
                        </Grid> */}
                        <Grid item>
                            <BtnCancelForm onClick={() => navigate(process.env.REACT_APP_API_URL + 'ManageStaffTable')} />
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
        </FormProvider>
    );

}

export default ManageStaffForm;