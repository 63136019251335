import React from 'react'
import { createTheme } from '@mui/material/styles';
import { blue } from '@mui/material/colors';
import CssBaseline from '@mui/material/CssBaseline';
import SearchIcon from '@mui/icons-material/Search';
// or
const theme = createTheme({
    palette: {
        primary: {
            main: blue[700]
        },
        info: {
            main: "#5bc0de",
            light: "#46b8da",
            dark: "#00a5d6",
        }
    },

    typography: {
        body1: {
            fontSize: "1em"
        },
        "fontFamily": `"Prompt"`,
    },

    components: {
        
        MuiAutocomplete: {
            defaultProps: {
                popupIcon: <SearchIcon />

            },
            styleOverrides: {
                popupIndicator: {
                    backgroundColor: "#0000000",
                }
            }
        },
        MuiUseMediaQuery: {
            defaultProps: {
                noSsr: true,
            },
        },
        MuiInputLabel: {
            styleOverrides: {
                //    root:{
                //        top:"-10px"
                //    },
                shrink: {
                    top: "0px"
                }
            }
        },
        MuiOutlinedInput: {
            styleOverrides: {
                root: {
                    backgroundColor: "white",
                }
            }
        },
        MuiTextField: {
            styleOverrides: {
                root: {
                    backgroundColor: "white",
                }
            }
        },
        MuiSelect: {
            styleOverrides: {

            }
        }, MuiListItemText: {
            styleOverrides: {
                root: {
                    marginBottom: "0px",
                    marginTop: "0px"
                }
            }
        },

    },
    breakpoints: {
        values: {
            xs: 0,
            sm: 600,
            md: 900,
            lg: 1200,
            xl: 1536
        }
    }
});
export default theme;