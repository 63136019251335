import { useEffect, useRef, useState } from "react";
import BlockUI1, { FnBlock_UI } from "src/Components/BlockUI";
import Grid from "@mui/material/Grid";
import Authen from "../../Components/System/authen/authen";
import sCover from "src/Layout/img/vision-cover.jpg";
import { MenuBar } from "src/Components/System/NavBar/MenuBar";
import { Banner } from "src/Components/Banner";
import { Axios } from "src/CommonFunction/TS_Axios";

const login = () => {
  const [banner, setBanner] = useState([] as any);
  const { BlockUI, UnBlockUI } = FnBlock_UI();

  const items: any[] = [
    {
      Key: "1",
      menuName: "เข้าสู่ระบบ/Login",
      IsOnClick: false,
    },
  ];

  const onPageLoad = () => {
    let result = [] as any;
    result.push({
      sPath: sCover,
    });
    setBanner(result);
  };

  useEffect(() => {
    onPageLoad();
  }, []);

  return (
    <>
      <Grid
        container
        columnSpacing={0}
        rowSpacing={0}
        sx={{ "&.MuiGrid-root": { marginTop: 0, display: "block" } }}
      >
        {/* <Grid item>
                    <Banner sImg={sCover} sTitleTH="เข้าสู่ระบบ" sTitleEN="Login"/>
                </Grid> */}
        <Grid item xs={12}>
          <Banner
            dataSource={banner || []}
            height={50}
            textTH="เข้าสู่ระบบ"
            textENG="Login"
          />
        </Grid>
        <Grid item>
          <MenuBar sContentID="" items={items} />
        </Grid>

        <Grid item>
          <Authen />
        </Grid>
      </Grid>
    </>
  );
};
export default login;
