import React, { Fragment, useState } from "react";
import Grid from "@mui/material/Grid";
// import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
import { makeStyles } from "@mui/styles";
import { Theme } from "@mui/material/styles";

import ItemCard from "./Item/ItemCard";

const useStyles = makeStyles((theme: Theme) => ({
  lstFile: {
    listStyle: "none",
    paddingLeft: 0,
  },
  WordColor: {
    color: "#fff",
    backgroundColor: "#2372ba",
    borderColor: "#2372ba",
    margin: theme.spacing(1),
    "&:hover": {
      backgroundColor: "rgb(56, 151, 234)",
    },
  },
  ExcelColor: {
    color: "#fff",
    backgroundColor: "#14a73c",
    borderColor: "#14a73c",
    margin: theme.spacing(1),
    "&:hover": {
      backgroundColor: "rgb(66, 201, 127)",
    },
  },
  PDFColor: {
    color: "#fff",
    backgroundColor: "#ff0000",
    borderColor: "#ff0000",
    margin: theme.spacing(1),
    "&:hover": {
      backgroundColor: "rgb(255, 79, 79)",
    },
  },
  PowerPointColor: {
    color: "#fff",
    backgroundColor: "#f26522",
    borderColor: "#f26522",
    margin: theme.spacing(1),
    "&:hover": {
      backgroundColor: "rgb(255, 79, 79)",
    },
  },

  FileItem: {
    margin: "0",
    padding: "20px 16px 20px 22px",
    borderBottom: "1px solid #ebeef1",
    animationDuration: ".6s",
  },
  ColumnAction: {
    margin: "0 16px",
  },
  Title: {
    fontWeight: "bold",
  },
  DangerColor: {
    color: "#fff",
    backgroundColor: "#ed3847",
    borderColor: "#ed3847",
    margin: theme.spacing(1),
    "&:hover": {
      backgroundColor: "rgb(220, 53, 69)",
    },
  },
  WarningColor: {
    color: "#fff",
    backgroundColor: "#ffce3d",
    borderColor: "#ffce3d",
    margin: theme.spacing(1),
    "&:hover": {
      backgroundColor: "rgb(252, 189, 0)",
    },
  },
  IConColor: {
    fill: "currentColor",
    width: "1em",
    height: "1em",
    display: "inline-block",
    fontSize: "1.5rem",
    transition: "fill 200ms cubic-bezier(0.4, 0, 0.2, 1) 0ms",
  },
}));

//style
const getItemStyle = (isDragging, draggableStyle) => ({
  userSelect: "none",
  margin: `0 20px 0 0`,
  ...draggableStyle,
});

const getListStyle = (isDraggingOver) => ({
  background: isDraggingOver ? "#e0f5ff" : "",
  display: "flex",
  padding: 10,
  overflow: "auto",
});

const ItemListCard = (props: any) => {
  const classes = useStyles();

  let arrFile = props.arrFile ? props.arrFile : [];

  // a little function to help us with reordering the result
  const reorder = (list, startIndex, endIndex) => {
    const result = Array.from(list);
    const [removed] = result.splice(startIndex, 1);
    result.splice(endIndex, 0, removed);

    return result;
  };

  const onDragEnd = (result) => {
    // dropped outside the list
    if (!result.destination) {
      return;
    }

    const items: any = reorder(
      arrFile,
      result.source.index,
      result.destination.index
    );
    props.SetarrFile([...items]);
  };

  //แบ่ง Array เป็นชุดละ 3
  let arrObjGroup:any = [];
  let arrInItem:any = [];

  arrFile.forEach((item, index) => {
    item["index"] = index;

    if ((index + 1) % 3 == 0) {
      arrInItem.push(item);
      arrObjGroup.push(arrInItem);
      arrInItem = [];
    } else {
      arrInItem.push(item);
      //ถ้าเป็น Index สุดท้าย
      if (index + 1 == arrFile.length) {
        arrObjGroup.push(arrInItem);
      }
    }
  });

  return (
    <Fragment>
      <Grid item xs={12}>
        {arrObjGroup.map((itemH, index) =>
          itemH.map((itemS, index) => (
            <ItemCard
              key={index}
              sFileType={itemS.sFileType}
              sFileName={itemS.sFileName}
              sSize={itemS.sSizeName}
              IsComplete={itemS.IsComplete}
              IsProgress={itemS.IsProgress}
              IsNewTab={itemS.IsNewTab}
              sProgress={itemS.sProgress}
              sFolderName={itemS.sFolderName}
              onDelete={props.onDelete}
              nFile_ID={itemS.nFile_ID}
              onLoad={props.onLoad}
              sUrl={itemS.sUrl}
              sFileLink={itemS.sFileLink}
              disabled={props.disabled}
              sTextInput1={props.sTextInput1}
              IsReqInput1={props.IsReqInput1}
              sTextInput2={props.sTextInput2}
              IsReqInput2={props.IsReqInput2}
              IsSelectNewTab={props.IsSelectNewTab}
              arrFile={props.arrFile}
              SetarrFile={props.SetarrFile}
              form={props.form}
              sInput1={itemS.sInput1}
              sInput2={itemS.sInput2}
            />
          ))
        )}
      </Grid>
    </Fragment>
  );
};
export default ItemListCard;
