import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import Box from "@mui/system/Box";
import React, { useEffect, useState } from "react";
import { Encrypt, ParseHtml } from "src/CommonFunction/TS_function";
import { BtnLeftWithOutText, BtnRightWithOutText, BtnSeeDetail } from "../Button";
import icon1 from "../../Layout/img/news-ico.png";
import "./NewsHome.css";
// import defaultImage from "../../Layout/img/default-image.png";
import { useNavigate } from "react-router-dom";
import img1 from "../../Layout/img/panel-course-cover.jpg";
import defaultImage from "../../Layout/img/pic-default/de-news.jpg";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import CardMedia from "@mui/material/CardMedia";
import { Button, CardActionArea, CardActions } from "@mui/material";
import IconButton from "@mui/material/IconButton";
import PhotoCamera from "@mui/icons-material/PhotoCamera";
import { makeStyles } from "@material-ui/core/styles";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import AOS from "aos";

export function NewsHome(props: NewsHome) {
  const { dataSource } = props;
  const getCon = document.getElementById("containerNews");
  const [left, setLeft] = useState(0);
  const navigate = useNavigate();
  const [sliderRef, setSliderRef] = useState<any>(null);
  const [checkSlide, setCheckSlide] = useState(0);

  const onClickLeft = () => {
    if (getCon) {
      if (window.innerWidth < 601) {
        getCon.scrollLeft -= 370;
        setLeft(getCon.scrollLeft);
      } else {
        getCon.scrollLeft -= 310;
        setLeft(getCon.scrollLeft);
      }
    }
  };

  const onClickRight = () => {
    if (getCon) {
      if (window.innerWidth < 601) {
        getCon.scrollLeft += 370;
        setLeft(getCon.scrollLeft);
      } else {
        getCon.scrollLeft += 310;
        setLeft(getCon.scrollLeft);
      }
    }
  };
  var settings = {
    arrows: false,
    swipeToSlide: false,
    draggable: false,
    dots: false,
    infinite: false,
    speed: 500,
    slidesToShow: 4,
    slidesToScroll: 4,
    initialSlide: 0,
    responsive: [
      {
        breakpoint: 1500,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 3,
          infinite: false,
          dots: false,
          swipeToSlide: true,
          draggable: true,
        },
      },
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
          infinite: false,
          dots: false,
          swipeToSlide: true,
          draggable: true,
        },
      },
      {
        breakpoint: 700,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          swipeToSlide: true,
          draggable: true,
          initialSlide: 1,
        },
      },
    ],
    afterChange: (currentSlide) => {
      if (currentSlide - checkSlide == 2) {
        if (checkSlide != dataSource.length - 1) {
          if (currentSlide + 2 == dataSource.length) {
            setCheckSlide(dataSource.length - 1)
          } else {
            setCheckSlide(currentSlide)
          }
        }
      }
      else if (currentSlide == 0) {
        setCheckSlide(currentSlide)
      }
    }
  };

  const useStyles = makeStyles({
    media: {
      transition: "1s",
      "&:hover": {
        transform: "scale(1.1)",
      },
    },
  });

  const classes = useStyles();

  useEffect(() => {
    AOS.init();
    AOS.refresh();
  }, []);

  const gotoNewsDetail = (sID: any) => {
    navigate(process.env.REACT_APP_API_URL + `NewsDetail?sID=` + sID + "&str=" + props.siteMap);
  };

  return (
    <Box>
      <Grid
        container
        sx={{
          // display: "flex",
          // justifyContent: "flex-end",
          backgroundColor: "#ebf6fd",
        }}>
        {props.courselength === 0 &&
          <Grid item xs={12} lg={3}></Grid>
        }
        <Grid item xs={12} lg={props.courselength === 0 ? 9 : 12}>
          <div className="wrapperNews">
            <div className="parentNews">
              <div className="childNews" style={{ backgroundImage: `url(${img1}`, display: "flex" }}>
                <div className="arrowRightCourse">
                  <div className="lineCourse"></div>
                  <div className="pointCourse"></div>
                </div>
                <Box>
                  <Typography
                    sx={{
                      fontSize: {
                        xs: "1.5em",
                        lg: "2em",
                      },
                      lineHeight: 1,
                      color: "rgba(255,255,255,0.9)",
                    }}>
                    NEWS
                  </Typography>
                </Box>
                <Typography
                  data-aos="fade-left"
                  onClick={() => navigate(process.env.REACT_APP_API_URL + "NewsList?str=" + props.siteMap)}
                  sx={{
                    fontSize: "1em",
                    justifyContent: "flex-end",
                    ml: { xs: "", lg: "50px" },
                    mr: { xs: "30px", lg: "" },
                    lineHeight: 1,
                    display: "flex",
                    width: "100%",
                    color: "rgba(255,255,255,0.9)",
                    cursor: "pointer",
                    ":hover": {
                      textDecoration: "underline",
                    },
                  }}>
                  VIEW ALL
                </Typography>
              </div>
            </div>
          </div>
        </Grid>
      </Grid>
      <Grid container spacing={0} sx={{ mt: "20px" }}>
        <Grid
          item
          md={12}
          xs={12}
          // sx={{ ml: 3 }}
          style={
            {
              // display: "flex",
              // flexDirection: "row",
              // justifyContent: "space-around",
              // flexWrap: "wrap",
              // textAlign:"center"
            }
          }>
          <Slider {...settings} style={{ width: "100%" }} ref={setSliderRef}>

            {(dataSource.length > 0 ? dataSource : []).map((m) => (
              <Card sx={{ maxWidth: 345, mb: 5, ml: { xs: 3, md: 4 } }}>
                <CardActionArea>
                  <CardMedia
                    className={classes.media}
                    component="img"
                    // height="200"
                    image={m.sPath || defaultImage}
                    alt="green iguana"
                    onError={({ currentTarget }) => {
                      currentTarget.onerror = null;
                      currentTarget.src = defaultImage;
                    }}
                    style={{ height: "100%",minHeight:"330px" }}
                  />               
                  <IconButton
                    color="primary"
                    aria-label="upload picture"
                    component="label"
                    style={{ backgroundColor: "rgb(247 134 42)" }}
                    sx={{
                      position: "absolute",
                      zIndex: 2,
                      right: "44%",
                      top: "50%",
                      transform: "translateY(50%)",
                    }}>
                    <img
                      src={icon1}
                      style={{
                        width: 35,
                        height: 35,
                        filter: "grayscale(1) invert(1)",
                      }}
                    />
                  </IconButton>
                  <CardContent sx={{ maxHeight: 220, height: 220, minHeight: 220, mt: 2 }}>
                    <Typography
                      gutterBottom
                      variant="h6"
                      component="div"
                      sx={{
                        display: "-webkit-box",
                        overflow: "hidden",
                        WebkitBoxOrient: "vertical",
                        WebkitLineClamp: 2,
                      }}>
                      {m.sTitle}
                    </Typography>
                    <Typography
                      variant="body2"
                      color="text.secondary"
                      sx={{
                        display: "-webkit-box",
                        overflow: "hidden",
                        WebkitBoxOrient: "vertical",
                        WebkitLineClamp: 3,
                      }}>
                      {ParseHtml(m.sDesc)}
                    </Typography>
                  </CardContent>
                </CardActionArea>
                <CardActions sx={{ pb: 4 }}>
                  <BtnSeeDetail fullWidth color="rgb(237, 108, 2)" hover="#de6808" txt="อ่านต่อ/Read more" onClick={() => gotoNewsDetail(m.sID)} />
                </CardActions>
              </Card>


            ))}



          </Slider>
        </Grid>
        <Grid
          container
          xs={12}
          sx={{
            alignItems: "center",
            borderTop: "1px solid rgba(0,0,0,0.1)",
            // height: "5vw",
            pt: 3,
            pb: 3,
          }}>
          <div className="BlockNewsArrow"></div>
          <Grid
            item
            xs={6}
            sx={{
              display: "flex",
              justifyContent: "flex-end",
              mb: 0,
              pr: "10px",
            }}>
            <div className="BtnLeft">
              <BtnLeftWithOutText onClick={sliderRef?.slickPrev} color={checkSlide === 0 ? "default" : "primary"} />
            </div>
          </Grid>
          <Grid item xs={6} sx={{ display: "flex", justifyContent: "flex-start", pl: "10px" }}>
            <div className="BtnRight">
              <BtnRightWithOutText onClick={sliderRef?.slickNext} color={checkSlide === dataSource.length - 1 ? "default" : "primary"} />
            </div>
          </Grid>
        </Grid>
      </Grid>
    </Box>
  );
}

interface NewsHome {
  dataSource?: any;
  onClick?: (value: any) => void;
  siteMap?: string;
  courselength: number;
}
