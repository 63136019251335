//React
import React, { useEffect, useState } from "react";
//other - Mui
import { FnBlock_UI } from "../BlockUI";
import { useNavigate } from "react-router";
import { Axios } from "src/CommonFunction/TS_Axios";
import { AlertMsg, AlertTitle, DialogConfirm, DialogConfirmDelete, SwAlert } from "src/Components/Alert/Sweetalert";
import { Process_System, ResultAPI,lnkToHomePage } from "src/CommonFunction/TS_function";

import { Box, Grid } from "@mui/material";
import { DataGridMui, initRows, PaginationInterface } from "../Table/DataGridMui";
import { GridColumns } from '@mui/x-data-grid';

//component Input
import { BreadcrumbsUI } from "../BreadcrumbsUI";
import { SelectForm } from "../FormItem/Select";
import { InputForm } from "../FormItem/Input";
import InputNumberForm from "../FormItem/InputNumber";
//Button
import { BtnAddOnTable, BtnBack, BtnCancelForm, BtnEditOnTable, BtnSave, BtnSaveForm } from "../Button";

//Yup
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { FormProvider, useForm } from "react-hook-form";
import yupFormSchemas from "../FormItem/yup/yupFormSchemas";
import Cards from "../CardHeader/Cards";
import InputNumberFormItem from "../FormItem/InputNumber/InputNumberFormItem";
import AutoCompleteForm from "../FormItem/AutoComplete";
import AutoCompleteSelect from "../FormItem/AutoCompleteSelect";
import { i18n } from "src/i18n";


const ManageProfile_Edit = () => {

  const navigate = useNavigate();
  const { BlockUI, UnBlockUI } = FnBlock_UI();

  const [arrOptionPrefix, setarrOptionPrefix] = useState([] as any)
  const [sProfileID, setsProfileID] = useState("")

  var Regex10 = /^\d{10}$/;
  var Regex13 = /^\d{13}$/;
  // var Regex = /[^0-9][+-]?[0-9]{1,10}[^0-9]/;

  const schema = yup.object().shape({
    sPrefix: yupFormSchemas.object("คำนำหน้า/Prefix", { required: true }),
    sName: yupFormSchemas.string("ชื่อ - นามสกุล / Name - Surname", { required: true }),
    sCitizenID: yupFormSchemas.string("เลขประจำตัวประชาชน/Citizen ID", { required: true, matches: Regex13, labelmatches: "เลขประจำตัวประชาชน/Citizen ID ไม่ถูกต้อง" }),
    sPosition: yupFormSchemas.string("ตำแหน่ง/Position", { required: true }),
    sTelephone: yupFormSchemas.string("เบอร์โทรศัพท์/Telephone", { required: true, min: 9 }),
    sEmail: yupFormSchemas.email("อีเมล/E-mail", { required: true }),
  });
  const form = useForm({
    resolver: yupResolver(schema),
    mode: 'all',
    shouldFocusError: true,
    criteriaMode: "firstError"
  })

  useEffect(() => {
    onPageLoad();
  }, [])

  useEffect(() => {
    EditDataFromID();
  }, [arrOptionPrefix])

  const onPageLoad = () => {
    Axios("Master/onPageLoad", {}, "GET", async (result) => {
      if (result.IsExpired) {
        SwAlert.Warning(undefined, i18n("common.SessionTimeOut"), lnkToHomePage);
      }
      else  {     
        GetDataInitial();
      }
    }, null, BlockUI, UnBlockUI, null)

  }

  const GetDataInitial = async () => {
    let option = [] as any;
    await Axios(
      `ProfilePage/SetFilter_Prefix`, {}, "POST", async (res) => {
        ResultAPI(true, res, null, () => {
          setarrOptionPrefix(res.lstPrefix)
        })
      },
      null,
      BlockUI,
      UnBlockUI,
      () => { }
    );
  }

  const SaveData = async () => {
    let oForm = form.getValues();
    let Param = {
      sProfileID: sProfileID,
      sPrefix: oForm.sPrefix ? oForm.sPrefix.label : "",
      sName: oForm.sName,
      sPosition: oForm.sPosition,
      sPersonalID: oForm.sCitizenID,
      sEmail: oForm.sEmail,
      sTel: oForm.sTelephone
    }

    DialogConfirm(() => {
      Axios(
        `ProfilePage/SaveData_MangeProfileDetail`, Param, "POST", async (res) => {
          ResultAPI(true, res, res.Message, () => {
            if (res.Status == Process_System.process_Success) {
              SwAlert.Success(AlertTitle.Success, AlertMsg.SaveComplete, () => {
                navigate(process.env.REACT_APP_API_URL + `ManageProfileTable`);
              });
            }
            else {
              SwAlert.Warning(AlertTitle.Warning, res.Message, () => { });
            }
          })
        },
        null,
        BlockUI,
        UnBlockUI,
        null
      );
    })


  }

  const EditDataFromID = async () => {
    const search = window.location.search;
    const params = new URLSearchParams(search);
    let id = params.get("sID");
    if (id) {
      setsProfileID(id)
      let Param = {
        sID: id,
        Status: "",
        Message: ""
      }
      await Axios(
        `ProfilePage/GetData_MangeProfileDetail`, Param, "POST", async (res) => {
          ResultAPI(true, res, null, () => {
            if (res.Status == Process_System.process_Success) {
              let find = arrOptionPrefix.find(f => f.label == res.objMangeProfile.sPrefix)
              // console.log("find", find)
              // console.log("0000 :", res.objMangeProfile)
              form.setValue("sName", res.objMangeProfile.sName);
              form.setValue("sCitizenID", res.objMangeProfile.sPersonalID);
              form.setValue("sPosition", res.objMangeProfile.sPosition);
              form.setValue("sTelephone", res.objMangeProfile.sTel);
              form.setValue("sEmail", res.objMangeProfile.sEmail);
              form.setValue("sPrefix", find ? find : null);
            }
          })
        },
        null,
        BlockUI,
        UnBlockUI,
        null
      );

    }
  }

  return (
    <FormProvider {...form}>
      <Grid container direction="row" justifyContent="center" alignItems="start">
        <Grid item xs={12}>
          <BreadcrumbsUI
            isShowHome={false}
            items={[
              {
                menuName: "จัดการโปรไฟล์/Manage Profile",
                path: "ManageProfileTable",
                IsOnClick: true
              },
            ]}
          />
        </Grid>
        <Grid item xs={12} style={{ padding: '24px 8px' }}>
          <Grid container spacing={2} direction="row" justifyContent="center" alignItems="start">
            <Grid item xs={12}>
              <Grid container spacing={2} direction="row" justifyContent="center" alignItems="start">
                <Grid item xs={12} md={10}>
                  <Cards skey={"oCard1"} sTitle={"โปรไฟล์/Profile"} sColor={"#122768"}>
                    <Grid container spacing={2} direction="row" justifyContent="center" alignItems="start">
                      <Grid item xs={12} md={6}>
                        <AutoCompleteSelect
                          name="sPrefix"
                          label="คำนำหน้า/Prefix"
                          required={true}
                          fullWidth={false}
                          width={{ width: "45%" }}
                          options={arrOptionPrefix}
                          disableClearable={false}
                          IsShowIcon={false}
                        />
                      </Grid>
                      <Grid item xs={12} md={6}>
                        <InputForm
                          fullWidth
                          required={true}
                          name={"sName"}
                          label="ชื่อ - นามสกุล / Name - Surname"
                          showClear={false}
                          maxLength={200}
                        />
                      </Grid>
                      <Grid item xs={12} md={6}>
                        <Grid container spacing={2} direction="row" justifyContent="start" alignItems="start">
                          <Grid item xs={12} md={8}>
                            <InputNumberForm
                              name="sCitizenID"
                              label="เลขประจำตัวประชาชน/Citizen ID"
                              type={"number"}
                              required={true}
                              fullWidth
                              digits={0}
                              maxLength={13}
                              integerDigits={13}
                              textAlign={"left"}
                              thousandSeparator={false}
                              allowMinus={false}
                            // format={"#-####-#####-##-#"}
                            />
                          </Grid>
                        </Grid>

                      </Grid>
                      <Grid item xs={12} md={6}>
                        <InputForm
                          fullWidth
                          required={true}
                          name={"sPosition"}
                          label="ตำแหน่ง/Position"
                          showClear={false}
                          maxLength={100}
                        />
                      </Grid>
                      <Grid item xs={12} md={6}>
                        <InputForm
                          fullWidth
                          required={true}
                          name={"sTelephone"}
                          label="เบอร์โทรศัพท์/Telephone "
                          showClear={false}
                          maxLength={50}
                        />
                        {/* <InputNumberForm
                          name="sTelephone"
                          label="เบอร์โทรศัพท์/Telephone"
                          type={"number"}
                          required={true}
                          allowMinus={false}
                          fullWidth
                          digits={0}
                          maxLength={10}
                          integerDigits={10}
                          textAlign={"left"}
                          thousandSeparator={false}
                          allowLeadingZeros={true}
                        // format={"###-#######"}
                        /> */}
                      </Grid>
                      <Grid item xs={12} md={6}>
                        <InputForm
                          fullWidth
                          required={true}
                          name={"sEmail"}
                          label="อีเมล/E-mail"
                          showClear={false}
                          maxLength={254}
                        />
                      </Grid>
                    </Grid>

                  </Cards>
                </Grid>
              </Grid>
            </Grid>
            <Grid item xs={12}>
              <Grid container spacing={2} direction="row" justifyContent="center" alignItems="center">
                <Grid item><BtnSaveForm onClick={form.handleSubmit(() => { SaveData() })} /></Grid>
                <Grid item><BtnCancelForm onClick={() => { navigate(process.env.REACT_APP_API_URL + `ManageProfileTable`) }} /></Grid>

              </Grid>
            </Grid>
          </Grid>
        </Grid>
        {/* <Grid item xs={12} sx={{ marginTop: 5 }}>
        </Grid> */}
      </Grid>
    </FormProvider>
  )
}

export default ManageProfile_Edit