//React
import React, { useEffect, useRef, useState } from "react";
//other - Mui
import { FnBlock_UI } from "../../BlockUI";
import { useNavigate, useLocation } from "react-router";
import { Axios, AxiosPostFilePDF } from "src/CommonFunction/TS_Axios";
import { AlertMsg, AlertTitle, DialogConfirm, DialogConfirmDelete, SwAlert } from "src/Components/Alert/Sweetalert";
import { Process_System, ResultAPI, IsNullOrUndefined, GetQueryString, DecryptApi, lnkToHomePage } from "src/CommonFunction/TS_function";
import Collapse from '@mui/material/Collapse';
import { Box, Checkbox, Divider, Fade, FormControlLabel, Grid, Tooltip, IconButton, Typography } from "@mui/material";
import { GridColumns } from '@mui/x-data-grid';
import { ParseHtml } from "src/CommonFunction/TS_function";
//component Input
import { DataGridMui, initRows, PaginationInterface } from "../../Table/DataGridMui";
import AutoCompleteSelect from "src/Components/FormItem/AutoCompleteSelect";
import InputNumberForm from "src/Components/FormItem/InputNumber";
import RadioFormItem from "src/Components/FormItem/RadioFormItem/RadioIFormtem";
import AutoCompleteForm from "src/Components/FormItem/AutoComplete";
import AutoCompleteFreeSolo from "src/Components/FormItem/AutoComplete/AutoCompleteFreeSolo";
import { FaQuestionCircle } from "react-icons/fa";
//Button
import { BtnAddOnTable, BtnBack, BtnBackRegister, BtnCancelForm, BtnConfirmRegister, BtnCancelRegister, BtnDeleteOnForm, BtnDownload, BtnEditOnForm, BtnEditOnTable, BtnOK, BtnSave, BtnSaveForm, BtnPrintRegister } from "../../Button";

//Yup
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { FormProvider, useForm } from "react-hook-form";
import yupFormSchemas from "src/Components/FormItem/yup/yupFormSchemas";
import { InputForm } from "src/Components/FormItem/Input";
import { BreadcrumbsUI } from "src/Components/BreadcrumbsUI";
import HaftHeaderCards from "src/Components/CardHeader/HaftHeaderCards";
import Cards from "src/Components/CardHeader/Cards";
import useMediaQuery from '@mui/material/useMediaQuery';
import PopupCancel from "./PopupCancel";
import PopupConfirm from "./PopupConfirm";
import PopupSuccess from "./PopupSuccess";
import PopupConfirmPrint from "./PopupConfirmPrint";
import moment from "moment";
import { i18n } from "src/i18n";
import { CalculateRegister } from "./Component/CallFunction";
const Classroom_RegisterWaiting = () => {
    const location = useLocation();
    const searchParams = location.search;
    const oFormRef = useRef() as any;
    const oTableRef = useRef() as any;

    let sCourseID = GetQueryString('c');
    let sScheduleID = GetQueryString('s');
    let sPriceID = GetQueryString('p');
    let sRegisID = GetQueryString('str');

    const matches = useMediaQuery('(min-width:900px)');
    const navigate = useNavigate();
    const { BlockUI, UnBlockUI } = FnBlock_UI();

    const [loadingTable, setLoadingTable] = useState(false);
    const [arrOptionPrefix, setarrOptionPrefix] = useState([] as any)
    const [dataRow, setDataRow] = useState<PaginationInterface>({
        ...initRows,
        sSortExpression: "nID",
        sSortDirection: "desc"
    });
    const [dataRow2, setDataRow2] = useState<PaginationInterface>({
        ...initRows,
        sSortExpression: "nID",
        sSortDirection: "desc"
    });
    const [sIdUpdate, setsIdUpdate] = useState("")
    const [IsResetInputForm, setIsResetInputForm] = useState(false)
    const [isOwner, setShowUsername] = useState(true);
    const [lstStaff, setOptionStaff] = useState(true);
    const [sEmpIDAdd, setEmpID] = useState("");
    const [isWriting, setIsWriting] = useState(false);

    //Detail Page for Cal
    const [oTrainingCourse, setoTrainingCourse] = useState({} as any)
    const [oCustomer, setoCustomer] = useState({} as any)
    const [sMode, setsMode] = useState("")
    const [nTrainingFee, setnTrainingFee] = useState(0.0)
    const [nWHTRate, setnWHTRate] = useState(1);
    const [nVATRate, setnVATRate] = useState(1);
    const [sRegisterID, setsRegisterID] = useState("")
    const [lstlstParticipants, setlstParticipants] = useState([] as any)
    const [isShowBtnSaveChange, setShowBtnSaveChange] = useState(false)
    //Calculate
    const [nSummary, setnSummary] = useState(0)
    const [nVat, setnVat] = useState(0)
    const [nSummaryVAT, setnSummaryVAT] = useState(0)
    const [nNoWHT, setnNoWHT] = useState(0)
    const [nTotal, setnTotal] = useState(0)
    //Yup validate
    const [IsNoTax, setIsNoTax] = useState(false)
    const [IsYupsPersonID, setIsYupsPersonID] = useState(false);    //true 
    const [IsYupsPassport, setIsYupsPassport] = useState(false);
    const [IsUpdateData, setIsUpdateData] = useState(false)
    const [IsDisableForm, setIsDisableForm] = useState(false)
    const [IsSetValueFromFreeSolo, setIsSetValueFromFreeSolo] = useState(false)
    //Modal
    const [IsOpenModal_cancel, setIsOpenModal_cancel] = useState(false)
    const [IsOpenModal_confirm, setIsOpenModal_confirm] = useState(false)
    const [IsOpenModal_success, setIsOpenModal_success] = useState(false)
    const [IsOpenModal_Print, setIsOpenModal_Print] = useState(false)
    const [IsModeShowForm, setIsModeShowForm] = useState(false)

    const [IsOR, setOR] = useState(false);
    //Regex for form
    var Regex10 = /^\d{10}$/;
    var Regex13 = /^\d{13}$/;
    var Regex9 = /^[a-zA-Z0-9]{9}$/;
    //var Regex6 = /[0-9a-zA-Z]{6,}/;
    var Regex6 = /^.{6,}$/;
    var RegexMore9 = /^[+]*[(]{0,1}[0-9]{1,4}[)]{0,1}[-\s\./0-9]*$/;

    const arrOptionsTypeID = [
        { "value": "1", "label": "เลขที่บัตรประชาชน / Citizen ID" },
        { "value": "2", "label": "เลขที่หนังสือเดินทาง / Passport ID" },
    ]

    const arrOptionsGender = [
        { "value": "M", "label": "ชาย" },
        { "value": "F", "label": "หญิง" },
    ]

    let objSchema = {
        sPrefix: yupFormSchemas.object("คำนำหน้า/Prefix", { required: true }),
        sName: yupFormSchemas.object("ชื่อ - นามสกุล / Name - Surname", { required: true }),
        sTypeID: yupFormSchemas.string("เลขประจำตัว", { required: false }),
        // sPersonID: yupFormSchemas.string("เลขที่บัตรประชาชน/Citizen ID", { required: false}),    //เลขที่หนังสือเดินทาง/Passport ID
        sPersonID: yupFormSchemas.string("เลขที่บัตรประชาชน/Citizen ID", { required: IsYupsPersonID, matches: Regex13, labelmatches: "เลขประจำตัวประชาชน/Citizen ID ไม่ถูกต้อง" }),    //เลขที่หนังสือเดินทาง/Passport ID
        // sPassport: yupFormSchemas.string("เลขที่หนังสือเดินทาง / Passport ID", { required: false}),    //เลขที่หนังสือเดินทาง/Passport ID
        sPassport: yupFormSchemas.string("เลขที่หนังสือเดินทาง / Passport ID", { required: IsYupsPassport, matches: Regex9, labelmatches: "เลขที่หนังสือเดินทาง / Passport ID ไม่ถูกต้อง" }), // matches: Regex9, labelmatches: "เลขที่หนังสือเดินทาง / Passport ID ไม่ถูกต้อง" }),    //เลขที่หนังสือเดินทาง/Passport ID
        sGender: yupFormSchemas.string("เพศ/Gender", { required: true }),
        sTelephone: yupFormSchemas.string("เบอร์โทรศัพท์/Telephone No.", { required: true }),
        sEmail: yupFormSchemas.email("อีเมล/E-mail", { required: true }),
        sPosition: yupFormSchemas.string("ตำแหน่ง/Position", { required: true }),
        sUsername: yupFormSchemas.string("ชื่อผู้ใช้/Username", { required: (isOwner && !IsDisableForm) ? true : false, matches: (isOwner && !IsDisableForm) ? Regex6 : null, labelmatches: "ชื่อผู้ใช้ต้องมีอักขระอย่างน้อย 6 ตัวอักษร" }),///^.{6,}$/
    };

    const [schemaObj, setSchemaObj] = useState({} as any);

    const schema = yup.object().shape(Object.assign(objSchema, schemaObj));

    const form = useForm({
        resolver: yupResolver(schema),
        mode: 'all',
        shouldFocusError: true,
        criteriaMode: "firstError",
    })
    const GoToList = () => {
        navigate(process.env.REACT_APP_API_URL + "TrainingHistory");

    }
    const GoToHome = () => {
        navigate(process.env.REACT_APP_API_URL + "home");

    }
    const onPageLoad = async () => {

        await Axios("Master/onPageLoad", {}, "GET", async (result) => {
            setOR(result.IsOR);
            if (IsNullOrUndefined(sRegisID)) {
                if (result.IsOR) {
                    GetUserOR();
                }
            }
            if (result.nUserType == 3) {
                setShowUsername(true);
            } else {

                setShowUsername(false);
            }
        })
    }
    const GetStaff = async (data) => {
        await Axios(
            `Classroom/GetStaffPost`, data, "POST", async (res) => {
                setOptionStaff(res);
                // console.log(lstStaff)
            },
            null,
            BlockUI,
            UnBlockUI,
            null
        );

    }
    const GetPrice = async (isNoTax, sPrice, nCount) => {
        let strParam = "isNoTax=" + isNoTax + "&" + "sPrice=" + sPrice + "&" + "nCount=" + nCount;
        await Axios(
            `Classroom/CheckPrice`, strParam, "Get", async (res) => {
                ResultAPI(true, res, null, () => {
                    setnSummary(res.nSum);
                    setnVat(res.nVAT);
                    setnSummaryVAT(res.nSumVAT);
                    setnNoWHT(res.nTax);
                    setnTotal(res.nExpense);
                });
            }
            ,
            null,
            BlockUI,
            UnBlockUI,
            null)


    }
    useEffect(() => {
        onPageLoad();

        GetDataInitial();
        if (!IsNullOrUndefined(sRegisID)) {
            setsRegisterID(sRegisID);
            SetDataInitial();


        } else if (!IsNullOrUndefined(sCourseID) && !IsNullOrUndefined(sScheduleID) && !IsNullOrUndefined(sPriceID)) {
            GetData();
        }

        form.setValue("sTypeID", "1");
        form.setValue("sGender", "M");
    }, [])

    useEffect(() => {
        if (lstlstParticipants.length > 0) {

            setDataRow({
                ...dataRow,
                arrRows: lstlstParticipants,
                nDataLength: lstlstParticipants.length,
            });
            setlstParticipants([]);
        }
        let nTotalPerson = dataRow.arrRows.length;
        if (nTotalPerson > 0 && nTrainingFee) {
            GetStaff(dataRow.arrRows);
            //GetPrice(IsNoTax,nTrainingFee,nTotalPerson);
            // let calSummary = ((nTotalPerson * nTrainingFee)*100)/100;
            // let calSummary: any = (Math.floor((nTotalPerson * nTrainingFee) * 100) / 100).toFixed(2);
            // let calVat: any = (Math.floor((calSummary * nVATRate) * 100) / 100).toFixed(2);
            // let calSummaryVAT: any = +(calSummary) + +(calVat);
            // // let calNoWHT = IsNoTax == true ? 0 : ((calSummary * nWHTRate)*100)/100;
            // let calNoWHT: any = IsNoTax == true ? 0 : (Math.floor((calSummary * nWHTRate) * 100) / 100).toFixed(2);
            // let calTotal: any = +(calSummaryVAT) - +(calNoWHT);

            let oData = {
                nTotalPerson: nTotalPerson
                , nTrainingFee: nTrainingFee
                , nVATRate: nVATRate
                , nWHTRate: nWHTRate
                , IsNoTax: IsNoTax
                , setnSummary: setnSummary
                , setnVat: setnVat
                , setnSummaryVAT: setnSummaryVAT
                , setnNoWHT: setnNoWHT
                , setnTotal: setnTotal
              };
              CalculateRegister(oData);
            // let calSummary = 0 as any;
            // let calVat = 0 as any;
            // let calVat_Person = 0 as any;
            // let calSummaryVAT = 0 as any;
            // let calSummaryVAT_Person = 0 as any;
            // let calNoWHT = 0 as any;
            // let calNoWHT_Person = 0 as any;
            // let calTotal = 0 as any;

            // calSummary = (nTotalPerson * nTrainingFee).toFixed(2);

            // calVat_Person = (nVATRate * nTrainingFee).toFixed(2);
            // calVat = ((+calVat_Person) * nTotalPerson).toFixed(2);

            // calSummaryVAT_Person = ((+nTrainingFee) + (+calVat_Person)).toFixed(2);
            // calSummaryVAT = ((+calSummaryVAT_Person) * nTotalPerson).toFixed(2);

            // calNoWHT_Person = IsNoTax == true ? 0 : ((nTrainingFee) * (+nWHTRate)).toFixed(2);
            // calNoWHT = IsNoTax == true ? 0 : (+calNoWHT_Person * nTotalPerson).toFixed(2);
            // calTotal = (+calSummaryVAT) - (+calNoWHT);


            // setnSummary(calSummary)
            // setnVat(calVat)
            // setnSummaryVAT(calSummaryVAT)
            // setnNoWHT(calNoWHT)
            // setnTotal(calTotal)
        }
        else {
            GetStaff([]);
            setnSummary(0)
            setnVat(0)
            setnSummaryVAT(0)
            setnNoWHT(0)
            setnTotal(0)
        }
        loadData(dataRow);
    }, [dataRow.arrRows, IsNoTax, nTrainingFee, nVATRate, nWHTRate, lstlstParticipants])
    const Print = async () => {
        var oData = {
            sInvoiceID: sRegisID,
        }

        let sInvoice = await DecryptApi(sRegisID);
        let fileName =
            "OBA_Invoice_" + sInvoice +
            "_" +
            moment(new Date()).format("YYYYMMDDHHMMSSFFF") +
            ".pdf";
        await Axios("Classroom/PrintInvoice", oData, "POST", async (result) => {
            savePDF(result, fileName);
        }, null,
            BlockUI,
            UnBlockUI,
            null
        );

    }
    const savePDF = (result, fileName) => {
        const url = window.URL.createObjectURL(new Blob([result]));
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', fileName); //or any other extension
        document.body.appendChild(link);
        link.click();
    }
    const GetDataInitial = async () => {
        await Axios(
            `ProfilePage/SetFilter_Prefix`, {}, "POST", async (res) => {
                ResultAPI(true, res, null, () => {
                    setarrOptionPrefix(res.lstPrefix)
                })
            },
            null,
            BlockUI,
            UnBlockUI,
            () => { }
        );
    }

    const GetData = async () => {
        let strParam = "sCourseID=" + sCourseID + "&" + "sScheduleID=" + sScheduleID + "&" + "sPriceID=" + sPriceID;
        await Axios(
            `Classroom/Registration_Create`, strParam, "Get", async (res) => {
                ResultAPI(true, res, null, () => {
                    if (res.isSuccess) {
                        if (res.isPermission) {
                            if (res.oTrainingCourse != null) {
                                let sFee = res.oTrainingCourse.sTrainingFee.replace(",", "")
                                setnTrainingFee(parseFloat(sFee))

                            }
                            else {
                                setnTrainingFee(0)
                            }
                            setoTrainingCourse(res.oTrainingCourse)
                            setoCustomer(res.oCustomer)
                            setsMode(res.sMode)
                            setnWHTRate(res.nWHTRate)
                            setnVATRate(res.nVATRate)
                        } else {
                            if (!IsNullOrUndefined(res.sMessage)) {

                                SwAlert.Warning(undefined, res.sMessage, lnkToHomePage);
                            } else {
                                SwAlert.Warning(undefined, i18n("common.NoPermission"), lnkToHomePage);
                            }
                        }
                    } else {
                        if (res.sMessage == "Expired") {

                            SwAlert.Warning(undefined, i18n("common.SessionTimeOut"), lnkToHomePage);
                          } else {
                            SwAlert.Warning(undefined, res.sMessage, lnkToHomePage);
              
                          }
              
                    }
                })
            },
            null,
            BlockUI,
            UnBlockUI,
            null
        );


    }
    const SetDataInitial = async () => {
        let Param = "sRegID=" + sRegisID;
        await Axios(
            `Classroom/RegistrationWaiting_Get`, Param, "Get", async (res) => {
                ResultAPI(true, res, null, () => {
                    if (res.isSuccess) {
                        if (res.isPermission) {
                            setlstParticipants(res.lstParticipants);
                            setIsNoTax(res.isNoTax);
                            if (res.oTrainingCourse != null) {
                                let sFee = res.oTrainingCourse.sTrainingFee.replace(",", "")
                                setnTrainingFee(parseFloat(sFee))

                            }
                            else {
                                setnTrainingFee(0)
                            }
                            setoTrainingCourse(res.oTrainingCourse)
                            setoCustomer(res.oCustomer)
                            setsMode(res.sMode)
                            setnWHTRate(res.nWHTRate)
                            setnVATRate(res.nVATRate)
                        }
                        else {

                            SwAlert.Warning(undefined, i18n("common.NoPermission"), lnkToHomePage);
                        }
                    } else {

                        SwAlert.Warning(undefined, i18n("common.SessionTimeOut"), lnkToHomePage);
                    }
                })

            },
            null,
            BlockUI,
            UnBlockUI,
            null
        );


    }
    const CheckUser = async () => {
        if (isOwner) {
            let sUsername = form.getValues("sUsername")
            let sEmpID = sEmpIDAdd === "" ? "0" : sEmpIDAdd;
            let Param = "sUsername=" + sUsername + "&sEmpID=" + sEmpID;
            await Axios(
                `Classroom/CheckUserStaff`, Param, "Get", async (res) => {
                    ResultAPI(true, res, null, () => {

                        if (res.isSuccess === true) {
                            AddTolist();

                        }
                        else {
                            SwAlert.Warning(AlertTitle.Warning, res.sMessage, () => { });
                        }

                    })
                },
                null,
                BlockUI,
                UnBlockUI,
                null
            );
        } else {
            AddTolist();

        }

    }
    const SaveData = async () => {

        let Param = {
            sCourseID: sCourseID,
            sScheduleID: sScheduleID,
            sPriceID: sPriceID,
            nPrice: nSummary,
            sAmount: nSummary + "|" + nVat + "|" + nNoWHT + "|" + nTotal,
            isNoTax: IsNoTax,
            nRate_VAT: nVATRate,
            nRate_WHT: nWHTRate,
            lstParticipant: dataRow.arrRows,

        }
        Axios(
            `Classroom/RegistrationWaiting_Submit`, Param, "POST", async (res) => {
                ResultAPI(true, res, "", () => {
                    if (res.isSuccess) {

                        SwAlert.Success(AlertTitle.Warning, AlertMsg.SaveComplete + "<br>กรุณารอเจ้าหน้าที่ติดต่อกลับก่อนดำเนินการชำระเงิน", () => { GoToList() });
                    }
                    else {
                        SwAlert.Warning(AlertTitle.Warning, res.sMessage, () => { });
                    }
                })
            },
            null,
            BlockUI,
            UnBlockUI,
            null
        );
    }
    const SaveChangeData = async () => {
        let Param = {
            sRegID: sRegisterID,
            sAmount: nSummary + "|" + nVat + "|" + nNoWHT + "|" + nTotal,
            isNoTax: IsNoTax,
            lstParticipant: dataRow.arrRows,
        }
        Axios(
            `Classroom/RegistrationWaiting_SaveChange`, Param, "POST", async (res) => {
                ResultAPI(true, res, "", () => {
                    if (res.isSuccess) {

                        SwAlert.Success(AlertTitle.Warning, AlertMsg.SaveComplete + "<br>กรุณารอเจ้าหน้าที่ติดต่อกลับก่อนดำเนินการชำระเงิน", () => { GoToList() });
                    }
                    else {
                        SwAlert.Warning(AlertTitle.Warning, res.sMessage, () => { });
                    }
                })
            },
            null,
            BlockUI,
            UnBlockUI,
            null
        );
    }
    const AddTolist = async () => {
        let GetForm = form.getValues();
        let sPersonalID = GetForm.sTypeID == "1" ? GetForm.sPersonID : GetForm.sPassport;
        let oCheck = await CheckDupPerson(sPersonalID);

        if (oCheck.isFound) {
            SwAlert.Warning(AlertTitle.Warning, oCheck.Message);

        }
        else {

            BlockUI();
            setIsResetInputForm(false)

            let lstData = [] as any;
            let arrPerson = [...dataRow.arrRows];
            const ids = arrPerson.map(object => { return object.sID });
            const max = arrPerson.length == 0 ? 0 : Math.max(...ids);
            let nMaxID = max + 1;

            let IschkCitizenID = GetForm.sTypeID == "1" && arrPerson.filter(f => { return (f.sCitizenID == GetForm.sPersonID) }).length > 0;
            let IschkPasssportID = GetForm.sTypeID == "2" && arrPerson.filter(f => { return (f.sPassportID == GetForm.sPassport) }).length > 0;
            let IschkUsername = isOwner ? arrPerson.filter(f => { return (f.sUsername == GetForm.sUsername) }).length > 0 : false;
            let IsDup = IschkCitizenID || IschkPasssportID || IschkUsername;

            let a = arrPerson.filter(a => { return (a.sCitizenID == GetForm.sPersonID) });

            if (!IsUpdateData)   //Add new
            {
                if (!IsDup) {
                    lstData.push({
                        sID: arrPerson.length == 0 ? "1" : nMaxID + "",
                        nID: arrPerson.length == 0 ? 1 : nMaxID,
                        nOrder: arrPerson.length == 0 ? 1 : nMaxID,
                        nPersonID: GetForm.sPersonID != null && GetForm.sPersonID != "" ? GetForm.sPersonID : GetForm.sPassport,
                        sCitizenID: GetForm.sPersonID || "",
                        sPassportID: GetForm.sPassport || "",
                        sPrefix: GetForm.sPrefix.label,
                        sName: GetForm.sName.label,
                        cGender: GetForm.sGender,
                        nAge: GetForm.sName.nAge || 0,
                        sTel: GetForm.sTelephone,
                        sEmail: GetForm.sEmail,
                        sPosition: GetForm.sPosition,
                        sUsername: GetForm.sUsername,
                        value: GetForm.sName.value,
                        label: GetForm.sName.label,
                        nRoomTypeID: GetForm.sName.nRoomTypeID || 0,
                        IsDelete: false,
                        IsNew: true,
                    })
                    let lstConcat = arrPerson.concat(lstData)
                    setDataRow({
                        ...dataRow,
                        arrRows: lstConcat,
                        nDataLength: lstConcat.length,
                    });
                    ClearForm();
                    setIsModeShowForm(false)
                    setShowBtnSaveChange(true);
                    UnBlockUI();
                    if (oTableRef.current != null) {
                        window.scrollTo({
                            top: oTableRef.current.offsetTop - 150,
                            left: 0,
                            behavior: "smooth",
                        });
                    }
                }
                else {

                    SwAlert.Warning(AlertTitle.Warning, "มีข้อมูลบุคคลนี้ในรายชื่อผู้เข้าอบรมแล้ว", () => { UnBlockUI(); });
                }
            }
            else    //Update Data in table
            {
                let IschkCitizenID = GetForm.sTypeID == "1" && dataRow.arrRows.filter(f => { return (f.sCitizenID == GetForm.sPersonID) && f.nID != sIdUpdate }).length > 0;
                let IschkPasssportID = GetForm.sTypeID == "2" && dataRow.arrRows.filter(f => { return (f.sPassportID == GetForm.sPassport) && f.nID != sIdUpdate }).length > 0;
                let IschkUsername = isOwner ? arrPerson.filter(f => { return (f.sUsername == GetForm.sUsername && f.nID != sIdUpdate) }).length > 0 : false;
                let IsDup = IschkCitizenID || IschkPasssportID || IschkUsername;

                if (!IsDup) {
                    let indx = dataRow.arrRows.findIndex(f => f.nID == sIdUpdate);
                    if (indx > -1) {

                        // let find = Option.find(f => f.label == rowData.sPrefix)
                        // form.setValue("sPrefix", find ? find : null);

                        //update feild in list 
                        dataRow.arrRows[indx].nPersonID = GetForm.sPersonID != null && GetForm.sPersonID != "" ? GetForm.sPersonID : GetForm.sPassport;
                        dataRow.arrRows[indx].sCitizenID = GetForm.sPersonID;
                        dataRow.arrRows[indx].sPassportID = GetForm.sPassport;
                        dataRow.arrRows[indx].sPrefix = GetForm.sPrefix.label;
                        dataRow.arrRows[indx].sName = GetForm.sName.label;
                        dataRow.arrRows[indx].cGender = GetForm.sGender;
                        dataRow.arrRows[indx].nAge = GetForm.sName.nAge || 0;
                        dataRow.arrRows[indx].sTel = GetForm.sTelephone;
                        dataRow.arrRows[indx].sEmail = GetForm.sEmail;
                        dataRow.arrRows[indx].sPosition = GetForm.sPosition;
                        dataRow.arrRows[indx].sUsername = GetForm.sUsername;
                        dataRow.arrRows[indx].value = GetForm.sName.value;
                        dataRow.arrRows[indx].label = GetForm.sName.label;
                        dataRow.arrRows[indx].nRoomTypeID = GetForm.sName.nRoomTypeID || 0;
                        dataRow.arrRows[indx].nEmployeeID = GetForm.sName.nEmployeeID || null;

                        let lstConcat = (dataRow.arrRows || []).concat(lstData)
                        setDataRow({
                            ...dataRow,
                            arrRows: lstConcat,
                            nDataLength: lstConcat.length,
                        });
                        ClearForm();
                        setIsModeShowForm(false);
                        setShowBtnSaveChange(true);
                        UnBlockUI();
                        if (oTableRef.current != null) {
                            window.scrollTo({
                                top: oTableRef.current.offsetTop - 150,
                                left: 0,
                                behavior: "smooth",
                            });
                        }
                    }
                    else {
                        SwAlert.Warning(AlertTitle.Warning, "ไม่สามารถอัพเดทข้อมูลได้", () => { UnBlockUI(); });
                    }
                }
                else {

                    SwAlert.Warning(AlertTitle.Warning, "มีข้อมูลบุคคลนี้ในรายชื่อผู้เข้าอบรมแล้ว", () => { UnBlockUI(); });
                }
            }
        }

    }

    const ClearForm = () => {
        setIsWriting(false);
        setIsDisableForm(false)
        setIsUpdateData(false);


        Object.keys(form.getValues()).forEach((key => {
            if (key == "sPrefix") {
                form.setValue(key, null);
            }
            else {
                form.setValue(key, "");
            }
            form.clearErrors(key);
        }))
        form.setValue("sPrefix", null);
        form.setValue("sName", null)
        //form.setValue("sName", null);
        form.setValue("sPersonID", "");
        form.setValue("sPassport", "");
        form.setValue("sTelephone", "");
        form.setValue("sEmail", "");
        form.setValue("sPosition", "");
        form.setValue("sUsername", "");
        form.setValue("sTypeID", "1");
        form.setValue("sGender", "M");
        setIsYupsPersonID(true)
        setIsYupsPassport(false)
        //setEmpID("")
    }

    const SetFormEditTable = (rowData) => {
        setIsWriting(true);
        if (oFormRef.current != null) {
            window.scrollTo({
                top: oFormRef.current.offsetTop - 100,
                left: 0,
                behavior: "smooth",
            });
        }
        console.log("rowData", rowData)


        form.clearErrors();
        setIsUpdateData(true);
        setIsModeShowForm(true);
        setShowBtnSaveChange(true);
        setsIdUpdate(rowData.nID)
        let Option = arrOptionPrefix;
        let find = Option.find(f => f.label == rowData.sPrefix)
        form.setValue("sPrefix", find ? find : null);

        form.setValue("sName", { value: rowData.value, label: rowData.label })
        form.setValue("sGender", rowData.cGender)
        form.setValue("sTelephone", rowData.sTel)
        form.setValue("sEmail", rowData.sEmail)
        form.setValue("sPosition", rowData.sPosition)
        form.setValue("sUsername", rowData.sUsername)

        if (rowData.sCitizenID == "" || rowData.sCitizenID == null) {
            //passsport
            form.setValue("sTypeID", "2")
            form.setValue("sPassport", rowData.sPassportID)
            setIsYupsPersonID(false)
            setIsYupsPassport(false)

            form.setValue("sPersonID", "")
        }
        else {
            //citizen
            form.setValue("sTypeID", "1")
            form.setValue("sPersonID", rowData.sCitizenID)
            form.setValue("sPassport", "")
            setIsYupsPersonID(false)
            setIsYupsPassport(false)
        }
        setEmpID(rowData.value);
        setIsDisableForm((IsSetValueFromFreeSolo || (rowData.value == null || rowData.value == "")) ? false : true)

    }
    const DeleteDataTable = (rowData) => {
        let arrPerson = [...dataRow.arrRows];
        let indx = arrPerson.findIndex(f => f.value == rowData.value);
        if (indx > -1) {
            arrPerson[indx].IsDelete = true;
        }

        let lstNodelete = arrPerson.filter(f => f.IsDelete == false);
        let arrData = lstNodelete.length > 0 ? lstNodelete : [];

        setDataRow({
            ...dataRow,
            arrRows: arrData,
            nDataLength: arrData.length,
        });
        setShowBtnSaveChange(true);
        ClearForm();
    }

    const HandlePersonAuto = async (oValue) => {

        form.setValue("sName", oValue ? oValue : null)   //ต้องเซตตรงนี้อีกรอบน่ะ เพราะ Freesolo มันไม่ให้เซตค่า
        // console.log("oValue", oValue)
        // console.log("IsSetValueFromFreeSolo", IsSetValueFromFreeSolo)
        if (oValue != null && oValue != undefined && oValue != "") {
            //Check Dup
            setEmpID(oValue.value);
            let chkDataDup = dataRow.arrRows.filter(f => f.value == oValue.value);
            if (chkDataDup.length == 0) {
                form.clearErrors();
                let find = arrOptionPrefix.find(f => f.label == oValue.sPrefix)

                form.setValue("sPrefix", find ? find : null);

                form.setValue("sGender", !IsNullOrUndefined(oValue.cGender) ? oValue.cGender : "M")
                form.setValue("sTelephone", oValue.sTel)
                form.setValue("sEmail", oValue.sEmail)
                form.setValue("sPosition", oValue.sPosition)
                form.setValue("sUsername", oValue.sUsername)

                if ((oValue.sCitizenID == "" || oValue.sCitizenID == null) && (oValue.value != "" && oValue.value != null)) {
                    //passsport
                    form.setValue("sTypeID", "2")
                    form.setValue("sPassport", oValue.sPassportID)
                    setIsYupsPersonID(false)
                    setIsYupsPassport(false)

                    form.setValue("sPersonID", "")
                }
                else {
                    //citizen
                    form.setValue("sTypeID", "1")
                    form.setValue("sPersonID", oValue.sCitizenID)
                    form.setValue("sPassport", "")
                    setIsYupsPersonID(false)
                    setIsYupsPassport(false)
                }
                //setIsDisableForm(IsSetValueFromFreeSolo ? false : true)
                setIsDisableForm(oValue.value == null || oValue.value == "" ? false : true)

            }
            else  //Data Dup
            {

                SwAlert.Warning(AlertTitle.Warning, "มีข้อมูลบุคคลนี้ในรายชื่อผู้เข้าอบรมแล้ว", () => {

                });
            }
            //Set form
        }
        else {
            ClearForm()
        }
    }

    const handleConfirmRegister = () => {
        if (dataRow.arrRows.length == 0) {
            if (sMode == "NEW") {
                SwAlert.Warning(AlertTitle.Warning, "โปรดระบุรายชื่อผู้เข้าอบรมก่อนยืนยันการลงทะเบียน", () => { });
            } else if (sMode == "EDIT") {
                SwAlert.Warning(AlertTitle.Warning, "โปรดระบุรายชื่อผู้เข้าอบรมก่อนบันทึกการเปลี่ยนแปลงรายชื่อ", () => { });
            }
        }
        else {
            if (sMode == "NEW" && isWriting) {
                SwAlert.Warning(AlertTitle.Warning, "โปรดบันทึกรายละเอียดผู้เข้าอบรมให้เสร็จสิ้นก่อนยืนยันการลงทะเบียน", () => { });
            }
            else if (sMode == "EDIT" && isWriting) {

                SwAlert.Warning(AlertTitle.Warning, "โปรดบันทึกรายละเอียดผู้เข้าอบรมให้เสร็จสิ้นก่อนบันทึกการเปลี่ยนแปลงรายชื่อ", () => { });
            }
            else {

                //setIsOpenModal_confirm(true)

                if (sMode == "NEW") {
                    SwAlert.ConfirmYN(AlertTitle.Confirm, "ท่านต้องการยืนยันการลงทะเบียน Waiting List หรือไม่?", () => {
                        SaveData();
                    });
                } else {
                    SwAlert.ConfirmYN(AlertTitle.Confirm, "ท่านต้องการบันทึกการเปลี่ยนแปลงข้อมูลหรือไม่?", () => {
                        SaveChangeData();
                    });
                }


            }

        }
    }

    const handleCancelRegister = () => {

        setIsOpenModal_cancel(true)

    }
    //   const handlePrintRegister = () => {

    //     setIsOpenModal_Print(true)

    //   }

    const formatNumber = (num, digit) => {
        if (num != null && num != "") {
            return parseFloat(num).toFixed(digit).replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,')
        }
        else {
            return num
        }
    }

    const handleShowForm = () => {
        setIsResetInputForm(true)
        if (oFormRef.current != null) {
            window.scrollTo({
                top: oFormRef.current.offsetTop - 100,
                left: 0,
                behavior: "smooth",
            });
        }
        form.reset();
        ClearForm();
        setIsModeShowForm(true);
        setIsDisableForm(false);

        // form.setValue("sName", null)
    }

    const handleCancelForm = () => {
        setIsResetInputForm(false)
        if (oTableRef.current != null) {
            window.scrollTo({
                top: oTableRef.current.offsetTop - 150,
                left: 0,
                behavior: "smooth",
            });
        }
        setIsModeShowForm(false);
        setIsUpdateData(false);
        ClearForm();
    }

    const onDefaultGender = (oValue) => {
        if (oValue && oValue.value) {
            //Value ผู้ชาย
            if (oValue.cValue == '1' || oValue.cValue == '4' || oValue.cValue == '5') {
                form.setValue("sGender", "M")
            }
            else {
                form.setValue("sGender", "F")
            }
        }
    }

    const CheckDupPerson = async (sPersonalID) => {
        let result: any = {};
        let data = {
            sRegID: sRegisID,
            sCourseID: sCourseID,
            sScheduleID: sScheduleID,
            sPriceID: sPriceID,
            sPersonalID: sPersonalID,
            isWaiting: false,

        };
        await Axios(
            `Classroom/CheckDupRegisClassroom`, data, "POST", async (res) => {
                result = res;
            },
            null,
            BlockUI,
            UnBlockUI,
            null
        );
        return result;
    }

    const dataColumn: GridColumns = [
        {
            renderHeader: () => {
                return (
                    // IsModeShowForm == true ? <></> : <BtnAddOnTable onClick={() => { handleShowForm() }} txt={"เพิ่ม/Add"} />
                    <>
                        {!IsOR &&
                            (<>
                                {(sMode != "VIEW" && sMode != "CANCEL") &&
                                    <BtnAddOnTable onClick={() => { handleShowForm() }} txt={"เพิ่ม/Add"} />
                                }
                            </>)

                        }
                    </>

                )
            },
            headerName: "",
            field: "sID",
            type: "actions",
            editable: false,
            resizable: false,
            disableReorder: true,
            width: 100,
            minWidth: 100,
            align: "center",
            sortable: false,

            renderCell: (item) => {
                return (
                    <>
                        {!IsOR &&
                            (
                                <>
                                    {(sMode != "CANCEL" && sMode != "VIEW") &&
                                        <Grid container spacing={1} justifyContent="center" alignItems="start">
                                            <Grid item><BtnDeleteOnForm onClick={() => { SwAlert.ConfirmYN(AlertTitle.Warning, AlertMsg.ConfirmDelete, () => { DeleteDataTable(item.row) }); }} /></Grid>
                                            <Grid item><BtnEditOnForm onClick={() => { SetFormEditTable(item.row) }} /></Grid>
                                        </Grid>
                                    }
                                </>
                            )
                        }

                    </>
                );
            },
        },
        {
            field: "nPersonID",
            headerName: (IsOR ? "รหัสพนักงาน" : "เลขประจำตัว"),
            headerAlign: "center",
            editable: false,
            width: 250,
            resizable: false,
            disableReorder: true,
            align: "center",
            sortable: false,
            renderCell: (item) => {
                let sCode = IsOR ? item.row.sEmpCode : item.row.nPersonID+"";         
                return (
                  <>
                    <Typography component={"label"}>
                      {sCode}
                    </Typography>
                  </>
                );
              },
        },
        {
            field: "sName",
            headerName: "ชื่อ-นามสกุล/Name & Surname",
            headerAlign: "center",
            editable: false,
            width: 320,
            minWidth: 320,
            resizable: false,
            disableReorder: true,
            align: "left",
            sortable: false,
            flex: 1,
        },
        {
            field: "cGender",
            headerName: "เพศ/Gender",
            headerAlign: "center",
            editable: false,
            width: 150,
            resizable: false,
            disableReorder: true,
            align: "center",
            sortable: false,
            renderCell: (item) => {
                return (
                    <>
                        <Typography component={"label"}>
                            {item.row.cGender == "F" ? "หญิง" : "ชาย"}
                        </Typography>
                    </>
                );
            },
        },
        {
            field: "sTel",
            headerName: "เบอร์โทรฯ/Telephone No.",
            headerAlign: "center",
            editable: false,
            width: 200,
            resizable: false,
            disableReorder: true,
            align: "center",
            sortable: false,
        },
        {
            field: "sPosition",
            headerName: "ตำแหน่ง/Position",
            headerAlign: "center",
            editable: false,
            width: 250,
            resizable: true,
            disableReorder: true,
            align: "center",
            sortable: false,
        },
        {
            field: "sFullUnitName",
            headerName: "หน่วยงานที่สังกัด",
            headerAlign: "center",
            editable: false,
            resizable: false,
            disableReorder: true,
            width: 300,
            align: "center",
            sortable: false,     
          },
    ];
    const loadData = async (p: PaginationInterface) => {
        setLoadingTable(true)
        BlockUI();
        let data = {
            ...p,
            lstData: dataRow.arrRows
        }
        await Axios("Classroom/LoadDataPeople", data, "POST", async (res) => {
            UnBlockUI();
            ResultAPI(true, res, null, () => {
                setLoadingTable(false)

                setDataRow2({
                    ...p,
                    arrRows: res.lstData || [],
                    nDataLength: res.nDataLength === 0 ? res.nDataLength : (res.nDataLength > 10 ? res.nDataLength : 10),
                    nPageIndex: res.nPageIndex || 1,
                });

            });
        }, null, BlockUI, UnBlockUI, null)
    };
    const GetUserOR = async () => {

        await Axios(
            `Classroom/ListParticipantOR`, {}, "POST", async (res) => {
                ResultAPI(true, res, "", () => {
                    setlstParticipants(res);
                })
            },
            null,
            BlockUI,
            UnBlockUI,
            null
        );
    }
    return (
        <FormProvider {...form}>
            <Grid container direction="row" justifyContent="center" alignItems="start">
                <Grid item xs={12}>
                    <BreadcrumbsUI
                        isShowHome={false}
                        items={[
                            {
                                menuName: "ลงทะเบียนรอคิวเข้าอบรม / Register Waiting List",
                                path: "TrainingHistory",
                                IsOnClick: true
                            },
                        ]}
                    />
                </Grid>
                {/* Detail */}
                <Grid item xs={12}>
                    <Grid container spacing={2} direction="row" justifyContent="center" alignItems="start" style={{ padding: matches ? '2em 5em' : '2em 1em' }}>
                        <Grid item xs={12}>
                            {/* Card */}
                            <Grid container spacing={4} direction="row" justifyContent="center" alignItems="start">
                                <Grid item xs={12} md={6}>
                                    <HaftHeaderCards
                                        skey={"hHCards01"}
                                        sTitle={"หลักสูตร / Training Course"}
                                        sColor={"#122768"}
                                    >
                                        <Grid container spacing={2} direction="row" justifyContent="start" alignItems="start">
                                            <Grid item xs={12} md={12}>
                                                <Typography><b>ชื่อหลักสูตร / Course Name</b></Typography>
                                                <Typography>{oTrainingCourse["sTrainingCourseName"] == "" ? "-" : oTrainingCourse["sTrainingCourseName"]}</Typography>
                                            </Grid>
                                            <Grid item xs={12} md={6}>
                                                <Typography><b>รุ่น / Session</b></Typography>
                                                <Typography>{oTrainingCourse["sSession"] == "" ? "-" : oTrainingCourse["sSession"]}</Typography>
                                            </Grid>
                                            <Grid item xs={12} md={6}>
                                                <Typography><b>วันที่อบรม / Date</b></Typography>
                                                <Typography>{oTrainingCourse["sCourseDate"] == "" ? "-" : oTrainingCourse["sCourseDate"]}</Typography>
                                            </Grid>
                                            <Grid item xs={12} md={12}>
                                                <Typography><b>สถานที่อบรม / Location</b></Typography>
                                                <Typography>{oTrainingCourse["sPlace"] == "" ? "" : oTrainingCourse["sPlace"]}<>{!IsNullOrUndefined(oTrainingCourse["sPlace"]) && !IsNullOrUndefined(oTrainingCourse["sRoom"]) ? <>, </> : <></>}</>{oTrainingCourse["sRoom"] == "" ? "" : oTrainingCourse["sRoom"]}</Typography>
                                            </Grid>
                                            <Grid item xs={12} md={12}>
                                                <Typography><b>ตัวเลือกราคา / Price option</b></Typography>
                                                <Typography>{oTrainingCourse["sPriceOption"] == "" ? "-" : oTrainingCourse["sPriceOption"]}</Typography>
                                            </Grid>
                                            <Grid item xs={12} md={12}>
                                                <Typography><b>ค่าอบรม / Training Fee</b></Typography>
                                                <Typography>{oTrainingCourse["sTrainingFee"] == "" ? "-" : oTrainingCourse["sTrainingFee"]} บาท/ท่าน</Typography>
                                            </Grid>
                                        </Grid>

                                    </HaftHeaderCards>
                                </Grid>
                                <Grid item xs={12} md={6}>
                                    <HaftHeaderCards
                                        skey={"hHCards01"}
                                        sTitle={" ผู้ลงทะเบียน / Customer"}
                                        sColor={"#122768"}
                                    >
                                        <Grid container spacing={2} direction="row" justifyContent="start" alignItems="start">
                                            <Grid item xs={12} md={12}>
                                                <Typography><b>รหัสลูกค้า / Customer ID</b></Typography>
                                                <Typography>{oCustomer["sCustomerID"] == "" ? "-" : oCustomer["sCustomerID"]}</Typography>
                                            </Grid>
                                            <Grid item xs={12} md={12}>
                                                <Typography><b>ชื่อผู้ลงทะเบียน / Name</b></Typography>
                                                <Typography>{oCustomer["sName"] == "" ? "-" : oCustomer["sName"]}</Typography>
                                            </Grid>
                                            <Grid item xs={12} md={12}>
                                                <Typography><b>ที่อยู่ / Address</b></Typography>
                                                <Typography>{oCustomer["sAddress"] == "" ? "-" : oCustomer["sAddress"]}</Typography>
                                            </Grid>
                                            <Grid item xs={12} md={12}>
                                                <Typography><b>หมายเลขโทรศัพท์ / Tel.</b></Typography>
                                                <Typography>{oCustomer["sTel"] == "" ? "-" : oCustomer["sTel"]}</Typography>
                                            </Grid>
                                            <Grid item xs={12} md={12}>
                                                {/* อีเมลล์ ไม่ถูกต้อง */}
                                                <Typography><b>อีเมล / E-mail</b></Typography>
                                                <Typography>{oCustomer["sEmail"] == "" ? "-" : oCustomer["sEmail"]}</Typography>
                                            </Grid>
                                        </Grid>
                                    </HaftHeaderCards>
                                </Grid>
                            </Grid>
                        </Grid>
                        <Grid item xs={12}>
                            {/*  รายชื่อผู้เข้าอบรม / List of Participants */}
                            <Cards skey={"oCardlist"} sTitle={"รายชื่อผู้เข้าอบรม / List of Participants"} sColor={"#122768"}>
                                <Grid container spacing={4} direction="row" justifyContent="center" alignItems="start">
                                    {/* table */}
                                    <Grid item xs={12}>
                                        <div ref={oTableRef} >
                                            <Box sx={{ width: "100%" }}>
                                                <DataGridMui
                                                    HiddenToolHead={true}
                                                    isShowCheckBox={false}
                                                    Rows={dataRow2}
                                                    isLoading={loadingTable}
                                                    Columns={dataColumn}
                                                    sxCustomHeader={{ backgroundColor: '#d6ebfd', color: '#000000' }}
                                                    OnLoadData={(p: PaginationInterface) => {
                                                        loadData(p)
                                                    }}
                                                />
                                            </Box>
                                        </div>
                                    </Grid>
                                    {IsModeShowForm && <Grid item xs={12}>
                                        <Divider />
                                    </Grid>}
                                    <Grid item xs={24} md={12}>
                                        <div ref={oFormRef} >
                                            {IsModeShowForm &&
                                                // <Collapse in={IsModeShowForm}>   //ใช้ แล้วไม่เซตค่าของ autocompete
                                                <Grid container spacing={2} direction="row" justifyContent="center" alignItems="start" style={{ padding: matches ? '0em 5em' : '0em 1em' }}>
                                                    <Grid item xs={12} md={6}>
                                                        <AutoCompleteSelect
                                                            name="sPrefix"
                                                            label="คำนำหน้า/Prefix"
                                                            required={true}
                                                            fullWidth={true}
                                                            width={{ width: matches ? "45%" : "100%" }}
                                                            options={arrOptionPrefix}
                                                            onChange={(e) => { onDefaultGender(e) }}
                                                            disableClearable={false}
                                                            IsShowIcon={false}
                                                            disabled={IsDisableForm}
                                                        />
                                                    </Grid>
                                                    <Grid item xs={12} md={6}>
                                                        <AutoCompleteSelect
                                                            required={true}
                                                            name={"sName"}
                                                            label="ชื่อ - นามสกุล /Name-Surname"
                                                            width={{ width: "100%" }}
                                                            options={lstStaff}
                                                            freeSolo={true}
                                                            disabled={IsDisableForm}
                                                            IsShowIcon={false}
                                                            defaultValue={""}
                                                            onChange={(v) => {
                                                                HandlePersonAuto(v);

                                                                if (v.value == null || v.value == "") {
                                                                    setIsUpdateData(false);
                                                                    setIsDisableForm(false)
                                                                }
                                                            }}
                                                            maxLength={200}
                                                            isshowCount={true}
                                                        />

                                                    </Grid>
                                                    <Grid item xs={12} md={6}>
                                                        <RadioFormItem
                                                            name="sTypeID"
                                                            required={true}
                                                            label="เลขประจำตัว"
                                                            options={arrOptionsTypeID}
                                                            disable={IsDisableForm}

                                                            value={form.getValues("sTypeID")}
                                                            onChange={(e) => {
                                                                if (e == "1")     //citizen    
                                                                {
                                                                    setIsYupsPersonID(true)
                                                                    setIsYupsPassport(false)
                                                                    form.setValue("sPersonID", "")
                                                                }
                                                                else      //passsport
                                                                {
                                                                    setIsYupsPersonID(false)
                                                                    setIsYupsPassport(true)
                                                                    form.setValue("sPassport", "")
                                                                }
                                                                form.clearErrors("sPersonID")
                                                                form.clearErrors("sPassport")
                                                            }}
                                                        />
                                                        {form.getValues("sTypeID") == "1" ?
                                                            <InputNumberForm
                                                                name="sPersonID"
                                                                label=""
                                                                type={"number"}
                                                                required={true}
                                                                disabled={IsDisableForm}
                                                                fullWidth
                                                                digits={0}
                                                                maxLength={13}
                                                                integerDigits={13}
                                                                textAlign={"left"}
                                                                thousandSeparator={false}
                                                                allowMinus={false}
                                                                allowLeadingZeros={true}
                                                            />
                                                            :
                                                            <InputForm
                                                                fullWidth
                                                                required={true}
                                                                name={"sPassport"}
                                                                disabled={IsDisableForm}
                                                                label=""
                                                                showClear={false}
                                                                maxLength={9}
                                                            />
                                                        }
                                                    </Grid>

                                                    <Grid item xs={12} md={6}>
                                                        <RadioFormItem
                                                            name="sGender"
                                                            disable={IsDisableForm}
                                                            required={true}
                                                            label="เพศ/Gender"
                                                            options={arrOptionsGender}
                                                            value={form.getValues("sGender")}
                                                        />
                                                        {
                                                            //   <InputNumberForm
                                                            //   name="sTelephone"
                                                            //   label="เบอร์โทรศัพท์/Telephone"
                                                            //   type={"number"}
                                                            //   required={true}
                                                            //   allowMinus={true}
                                                            //   fullWidth
                                                            //   disabled={IsDisableForm}
                                                            //   digits={0}
                                                            //   maxLength={50}
                                                            //   integerDigits={10}
                                                            //   textAlign={"left"}
                                                            //   thousandSeparator={false}
                                                            //   allowLeadingZeros={true}                           

                                                            // />
                                                            <InputForm
                                                                fullWidth
                                                                required={true}
                                                                name={"sTelephone"}
                                                                label="เบอร์โทรศัพท์/Telephone"
                                                                disabled={IsDisableForm}
                                                                maxLength={50}
                                                                showClear={false}
                                                            />

                                                        }
                                                    </Grid>

                                                    <Grid item xs={12} md={6}>
                                                        <InputForm
                                                            fullWidth
                                                            required={true}
                                                            name={"sEmail"}
                                                            label="อีเมล/E-mail"
                                                            disabled={IsDisableForm}
                                                            showClear={false}
                                                            maxLength={254}
                                                        />
                                                    </Grid>
                                                    <Grid item xs={12} md={6}>
                                                        <InputForm
                                                            fullWidth
                                                            required={true}
                                                            name={"sPosition"}
                                                            label="ตำแหน่ง/Position"
                                                            disabled={IsDisableForm}
                                                            showClear={false}
                                                            maxLength={100}
                                                        />
                                                    </Grid>
                                                    {/* <Grid item xs={24} md={12}> */}
                                                    <Grid item xs={12} md={6}>

                                                        {isOwner &&
                                                            <InputForm
                                                                fullWidth
                                                                required={(isOwner && !IsDisableForm) ? true : false}
                                                                name={"sUsername"}
                                                                label="ชื่อผู้ใช้/Username"
                                                                disabled={IsDisableForm}
                                                                showClear={false}
                                                                maxLength={30}
                                                            />


                                                        }


                                                    </Grid>
                                                    <Grid item xs={12} md={6} style={{ paddingLeft: '1.5em' }}>
                                                        {
                                                            (isOwner) ?
                                                                <>
                                                                    <Typography variant="caption" style={{ color: "#005f8b", fontWeight: "bold" }}>หลักเกณฑ์การตั้งชื่อผู้ใช้งาน</Typography>
                                                                    <Typography variant="caption">{ParseHtml("<br>1. ตั้งชื่อผู้ใช้ให้มีความยาว 6–30 ตัว โดยอาจประกอบไปด้วยตัวอักษร ตัวเลข หรือสัญลักษณ์ต่างๆ<br>2. ชื่อผู้ใช้มีตัวอักษร (a-z) ตัวเลข (0-9) เครื่องหมายขีดกลาง (-) เครื่องหมายขีดล่าง (_) และเครื่องหมายจุด (.) ได้<br>3. ตัวอย่างชื่อผู้ใช้งานที่แนะนำ ใช้ชื่อจริงตามด้วยะเครื่องหมายจุด (.) และตามด้วยตัวอักษารแรกของนามสกุล<br>ตัวอย่างเช่น sompong.k เป็นต้น")}</Typography>
                                                                </>
                                                                : <></>
                                                        }


                                                    </Grid>
                                                    {/* </Grid> */}

                                                    <Grid item xs={12} md={12}>
                                                        <Grid container spacing={2} direction="row" justifyContent="center" alignItems="center" paddingTop="0.9em">
                                                            <Grid item><BtnSaveForm onClick={form.handleSubmit(() => { CheckUser() })} /></Grid>
                                                            <Grid item><BtnCancelForm onClick={() => { handleCancelForm() }} /></Grid>
                                                        </Grid>
                                                    </Grid>
                                                </Grid>
                                                //  {/* </Collapse> */}
                                            }
                                        </div>
                                    </Grid>
                                </Grid>
                            </Cards>
                        </Grid>
                        <Grid item xs={12}>
                            {/* ค่าใช้จ่าย / Expenses */}
                            <Cards skey={"oCardExpenses"} sTitle={"ค่าใช้จ่าย / Expenses"} sColor={"#122768"}>
                                <Grid container spacing={2} direction="row" justifyContent="center" alignItems="start">
                                    <Grid item xs={12} md={6}>
                                        <Typography><b>รวมค่าอบรม / Summary</b></Typography>
                                    </Grid>
                                    <Grid item xs={12} md={6} style={{ textAlign: matches ? 'right' : 'left' }}>
                                        <Typography><b>{formatNumber(nSummary, 2)}</b>  บาท/Bath</Typography>
                                    </Grid>
                                    <Grid item xs={12} style={{ paddingTop: '8px' }}>
                                        <Divider />
                                    </Grid>
                                    <Grid item xs={12} md={6}>
                                        <Typography><b>ภาษีมูลค่าเพิ่ม</b></Typography>
                                    </Grid>
                                    <Grid item xs={12} md={6} style={{ textAlign: matches ? 'right' : 'left' }}>
                                        <Typography>{formatNumber(nVat, 2)}  บาท/Bath</Typography>
                                    </Grid>
                                    <Grid item xs={12} style={{ paddingTop: '8px' }}>
                                        <Divider />
                                    </Grid>
                                    <Grid item xs={12} md={6}>
                                        <Typography><b>ค่าอบรมรวมภาษีมูลค่าเพิ่ม / Summary+VAT</b></Typography>
                                    </Grid>
                                    <Grid item xs={12} md={6} style={{ textAlign: matches ? 'right' : 'left' }}>
                                        <Typography><b>{formatNumber(nSummaryVAT, 2)}</b>  บาท/Bath</Typography>
                                    </Grid>
                                    <Grid item xs={12} style={{ paddingTop: '8px' }}>
                                        <Divider />
                                    </Grid>
                                    <Grid item xs={12} md={6}>
                                        <Typography><b>หักภาษี ณ ที่จ่าย / Withholiding Tax (WHT)</b></Typography>
                                        <FormControlLabel
                                            control={
                                                <Checkbox
                                                    checked={IsNoTax}
                                                    onChange={(event) => {
                                                        setIsNoTax(event.target.checked);
                                                        setShowBtnSaveChange(true);
                                                    }}
                                                    disabled={(sMode == "CANCEL" || sMode == "VIEW") ? true : false}
                                                />
                                            }
                                            label={
                                                <>
                                                    <Typography style={{ color: 'red' }}>
                                                        ยกเว้นการหักภาษี ณ ที่จ่าย / No Withholding Tax (No WHT)
                                                    </Typography>
                                                </>
                                            }
                                        />
                                    </Grid>
                                    <Grid item xs={12} md={6} style={{ textAlign: matches ? 'right' : 'left' }}>
                                        <Typography>{formatNumber(nNoWHT, 2)}  บาท/Bath</Typography>
                                    </Grid>
                                    <Grid item xs={12} style={{ paddingTop: '8px' }}>
                                        <Divider />
                                    </Grid>
                                    <Grid item xs={12} md={6}>
                                        <Typography><b>รวมเงินที่ต้องชำระ / Total</b></Typography>
                                    </Grid>
                                    <Grid item xs={12} md={6} style={{ textAlign: matches ? 'right' : 'left' }}>
                                        <Typography><b style={{ color: '#0ba200', fontSize: '1.3em' }}>{formatNumber(nTotal, 2)}</b>  บาท/Bath</Typography>
                                    </Grid>
                                    <Grid item xs={12} style={{ paddingTop: '8px' }}>
                                        <Divider />
                                    </Grid>
                                </Grid>
                            </Cards>
                        </Grid>
                        <Grid item xs={12}>
                            {/* เงื่อนไขการชำระเงิน / Term of payment */}
                            <Cards skey={"oCardExpenses"} sTitle={"เงื่อนไขการชำระเงิน / Term of payment"} sColor={"#1a08f4"}>
                                <Typography>
                                    1. ผู้สมัครอบรม ต้องชำระเงินค่าอบรมก่อนวันอบรมอย่างน้อย 30 วัน เพื่อสำรองที่นั่งให้กับผู้เข้าอบรม สถาบันฯ ขอสงวนสิทธิ์ให้กับผู้สมัครที่ชำระเงินครบถ้วนก่อนภายในกำหนด
                                </Typography>
                                <Typography>
                                    2. ทำการโอนเงินเข้าบัญชี ตามเอกสารแจ้งการชำระเงินจากระบบ
                                </Typography>
                                <Typography>
                                    3. เมื่อดำเนินการโอนเงินแล้วต้องส่งสำเนาหลักฐานการโอนเงินของธนาคาร (ใบ Pay-in) และ สำเนาหนังสือรับรองหัก ณ ที่จ่าย(กรณีนิติบุคคล) มายัง <a href="mailto:oracademy@pttor.com" target={"_top"} >oracademy@pttor.com</a>
                                </Typography>
                                <Typography>
                                    4. ในวันจัดอบรมผู้สมัครจะต้องนำหนังสือรับรองหัก ณ ที่จ่าย ที่พิมพ์จากระบบพร้อมลงนามประทับตราบริษัท ส่งคืนให้เจ้าหน้าที่สถาบันฯ(กรณีนิติบุคคล)
                                </Typography>
                                <Typography>
                                    5. ผู้สมัครจะได้รับใบเสร็จ/ใบกำกับภาษี หลังสิ้นสุดการอบรม
                                </Typography>
                                <Typography>
                                    6. กรณียกเลิกหลักสูตรโดยผู้สมัครอบรม ต้องยกเลิกล่วงหน้าอย่างน้อย 15 วันก่อนการอบรม ทั้งนี้จะเป็นการเปลี่ยนแปลงรุ่น/หลักสูตรการอบรมให้ทดแทนการคืนเงิน
                                </Typography>
                                <Typography>
                                    7. กรณียกเลิกหลักสูตรโดย OR สถาบันฯจะพิจารณาเปลี่ยนแปลงรุ่น/หลักสูตรทดแทนการคืนเงิน หากไม่สามารถจัดหลักสูตรใดได้เลย สถาบันฯจะดำเนินการคืนค่าอบรมตามกระบวนการของบริษัท ปตท. น้ำมันและการค้าปลีก จำกัด (มหาชน)
                                </Typography>

                            </Cards>
                        </Grid>
                    </Grid>
                </Grid>
                {/* button */}
                <Grid item xs={12}>
                    {sMode == "NEW" ?
                        <Grid container spacing={2} direction="row" justifyContent="start" alignItems="start" style={{ padding: matches ? '0em 5em 2em 5em' : '0em 1em 2em 1em' }}>
                            <Grid item><BtnConfirmRegister onClick={() => { handleConfirmRegister() }} /></Grid>
                            <Grid item><BtnBackRegister onClick={() => GoToList()} /> </Grid>
                        </Grid>
                        :
                        <Grid container spacing={2} direction="row" justifyContent="start" alignItems="start" style={{ padding: matches ? '0em 5em 2em 5em' : '0em 1em 2em 1em' }}>
                            {sMode == "EDIT" && isShowBtnSaveChange ?
                                <Grid item><BtnConfirmRegister onClick={() => { handleConfirmRegister() }} txt="บันทึกการเปลี่ยนแปลงรายชื่อ / Save changes" />
                                </Grid>
                                : <></>}
                            <Grid item><BtnBackRegister onClick={() => { GoToList() }} /> </Grid>
                            {/* {(sMode == "EDIT" || sMode == "VIEW") && !isShowBtnSaveChange ?
                <Grid item> <BtnPrintRegister onClick={() => { handlePrintRegister() }} /></Grid>
                : <></>} */}
                            {sMode == "EDIT" ?
                                <Grid item><BtnCancelRegister onClick={() => { handleCancelRegister() }} /></Grid>
                                : <></>}
                        </Grid>
                    }

                </Grid>
            </Grid>
            <PopupCancel
                IsOpenModal={IsOpenModal_cancel}
                setIsOpenModal={setIsOpenModal_cancel}
                sRegisID={sRegisterID}
                isWaiting={true}
            />
            {/* <PopupConfirm
                IsOpenModal={IsOpenModal_confirm}
                setIsOpenModal={setIsOpenModal_confirm}
                SaveData={sMode == "NEW" ? SaveData : SaveChangeData}
                sCourseID={sCourseID}
                sScheduleID={sScheduleID}
                sPriceID={sPriceID}
                arrPerson={dataRow.arrRows}
                sRegisterID={sRegisterID}
            />
            <PopupSuccess
                IsOpenModal={IsOpenModal_success}
                setIsOpenModal={setIsOpenModal_success}
            /> */}
        </FormProvider >
    )
}

export default Classroom_RegisterWaiting