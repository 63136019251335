import { Button, Grid, DialogActions, DialogContent } from "@mui/material";
import Typography from "@mui/material/Typography";
import { FormProvider, useForm } from "react-hook-form";
import logo from "src/Layout/img/oba-logo.png";
import logoReUser from "src/Layout/img/logo-ReUser.png";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import yupFormSchemas from "src/Components/FormItem/yup/yupFormSchemas";
import { InputForm } from "src/Components/FormItem/Input";
import { Axios } from "src/CommonFunction/TS_Axios";
import { FnBlock_UI } from "src/Components/BlockUI";
import { Process_System } from "src/CommonFunction/TS_function";
import { useNavigate } from "react-router-dom";
import {  AlertTitle, SwAlert } from "../Alert/Sweetalert";
import { BreadcrumbsUI } from "../BreadcrumbsUI";
import { useState } from "react";
import { DialogCustomUI } from "../Dialog/DialogCustomUI";

const ResetPassword = () => {
    const { BlockUI, UnBlockUI } = FnBlock_UI();

    const [IsOpenModal_success, setIsOpenModal_success] = useState(false);
    const [Email, setEmail] = useState("");

    const objSchema = {
        sUsername: yupFormSchemas.string("Username", { required: true })
    }

    const schema = yup.object().shape(objSchema);

    const form = useForm({
        resolver: yupResolver(schema),
        shouldUnregister: false,
        shouldFocusError: true,
        mode: "all",
    });

    const onSendEmail = async () => {
        var objData = {
            sUsername: form.getValues("sUsername"),
        };
        if(IsOpenModal_success) setIsOpenModal_success(false);
        await Axios(`ResetPassWord/SendEmailResetPassword`, objData, "POST", async (res) => {
            if (res.Status === Process_System.process_Success) {
                setEmail(res.sEmail)
                setIsOpenModal_success(true);
            }
            else {
                SwAlert.Warning(AlertTitle.Warning, "ไม่พบข้อมูล กรุณาตรวจสอบ ชื่อผู้ใช้งาน/Username", () => {});
            }
        }, null, BlockUI, UnBlockUI);
    }

    const onSubmit = () => {
        SwAlert.Confirm(AlertTitle.Confirm, "ต้องการส่งข้อมูลหรือไม่?", () => onSendEmail(), () => { })
    }

    return (
        <Grid container >
            <Grid item xs={12}>
                <BreadcrumbsUI
                    items={[
                        {
                            Key: "1",
                            menuName: "ลืมรหัสผ่าน/Forgot Password",
                            IsOnClick: false,
                        },
                    ]}
                    isShowHome={false}
                />
            </Grid>
            <Grid item xs={12} sx={{ marginTop: "3%" }}>
                <Grid container >
                    <img src={logo} style={{ width: "10%", height: "auto", margin: "auto auto", }} />
                    <Grid item xs={12} sx={{ marginTop: "1%" }}>
                        <Typography align="center" fontSize={18}>โปรดระบุ ชื่อผู้ใช้งาน/Username ของท่าน เราจะส่งลิ้งค์สำหรับตั้งรหัสผ่านใหม่ไปยังอีเมลของท่าน</Typography>
                    </Grid>
                    <Grid item xs={12} >
                        <Typography align="center" fontSize={18}>Please enter the Username. We'll send you a link to reset  your password. </Typography>
                    </Grid>
                    <FormProvider {...form}>
                        <Grid item xs={2} lg={4}></Grid>
                            <Grid item xs={8} lg={4} justifyContent="center" alignItems="center" sx={{marginTop: "2%"}}>
                                <InputForm
                                    fullWidth
                                    name={"sUsername"}
                                    required={true}
                                    maxLength={100}
                                    label="Username"
                                />
                        </Grid>
                        <Grid item xs={2} lg={4}></Grid>
                        <Grid item xs={2} lg={4}></Grid>
                        <Grid item xs={8} lg={4} sx={{ paddingLeft: "15px" }} direction="row" textAlign={"center"}>
                            <Button
                                onClick={() => { form.handleSubmit(onSubmit)() }}
                                sx={{
                                    borderRadius: "16px",
                                    backgroundColor: "#196F3D", fontSize: 15, color: "White", ":hover": { bgcolor: "#458522" },
                                    width: "50%"
                                }}>
                                    ส่ง/Send
                            </Button>
                        </Grid>
                        <Grid item xs={2} lg={4}></Grid>
                    </FormProvider>
                </Grid>
            </Grid>
            <Grid item xs={12} sx={{ marginTop: "5%" }} ></Grid>
            <DialogCustomUI
                open={IsOpenModal_success}
                handleClose={() => { setIsOpenModal_success(false) }}
                titleBgColor={"#43a7f3"}
                titleColor={"#fff"}
            >
                <DialogContent sx={{
                    display: "flex"
                    , flexDirection: "column"
                    , alignItems: "center", m: "1em", paddingBottom: "50px"
                }}>
                    <Grid container spacing={2} direction="row" justifyContent="center" alignItems="center">
                        <Grid item xs={12} md={12}>
                            <Typography textAlign={"center"} variant="h4" paddingBottom={"35px"} fontWeight={"bold"}>
                                Success
                            </Typography>
                            <Typography>
                                ระบบได้ทำการส่งลิ้งค์สำหรับเปลี่ยนรหัสผ่านไปยัง
                            </Typography>
                            <Typography>
                                E-mail : {Email}
                            </Typography><br />
                            <Typography>
                                โปรดคลิกลิ้งค์ในอีเมลเพื่อกำหนดรหัสผ่านใหม่
                            </Typography>
                        </Grid>
                    </Grid>
                </DialogContent>
                <DialogActions>
                    <Grid container spacing={2} direction="row" justifyContent="center" alignItems="center" paddingBottom={"20px"}>
                        <Button
                            variant="contained"
                            size="medium"
                            onClick={() => { form.handleSubmit(onSubmit)() }}
                            sx={{ border: "1px solid #366d21", color: "#fff", backgroundColor: "#366d21", ":hover": { bgcolor: "#458522" } }}
                        >
                            ส่งอีกครั้ง/Resend
                        </Button>
                        <Button
                            variant="contained"
                            size="medium"
                            onClick={() => { setIsOpenModal_success(false) }}
                            sx={{ border: "1px solid #797979", color: "#000", backgroundColor: "#ffffff", marginLeft: "15px", ":hover": { bgcolor: "#79797942" }, }}
                        >
                            ตกลง/OK
                        </Button>
                    </Grid>
                </DialogActions>

            </DialogCustomUI>
        </Grid>
    );
}
export default ResetPassword



