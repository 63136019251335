//React
import React, { useEffect, useState } from "react";
//other - Mui
import { FnBlock_UI } from "../../BlockUI";
import { useNavigate } from "react-router";
import { Axios } from "src/CommonFunction/TS_Axios";
import { AlertMsg, AlertTitle, DialogConfirm, DialogConfirmDelete, SwAlert } from "src/Components/Alert/Sweetalert";
import { Process_System, ResultAPI } from "src/CommonFunction/TS_function";
import { DialogCustomUI } from "src/Components/Dialog/DialogCustomUI";
import { Box, Divider, Fade, Slide, Grid, Modal, Typography, Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle } from "@mui/material";
import { ParseHtml } from "src/CommonFunction/TS_function";
//component Input

//Button
import { BtnCancelForm, BtnEditOnTable, BtnOK, } from "../../Button";

//Yup
import { i18n } from "src/i18n";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { FormProvider, useForm } from "react-hook-form";
import yupFormSchemas from "src/Components/FormItem/yup/yupFormSchemas";
import { InputForm } from "src/Components/FormItem/Input";
import { TransitionProps } from '@mui/material/transitions';
const i18nField = "entities.RegisReject.fields";
/**
 * 
 * @param props 
 * IsOpen
 * setIsOpen
 * @returns 
 */
const Transition = React.forwardRef(function Transition(
    props: TransitionProps & {
        children: React.ReactElement;
    },
    ref: React.Ref<unknown>,
) {
    return <Slide direction="down" ref={ref} {...props} />;
});

const PopupCancel = (props) => {

    const { IsOpenModal, setIsOpenModal, sRegisID, isWaiting } = props

    const navigate = useNavigate();
    const { BlockUI, UnBlockUI } = FnBlock_UI();
    const [isCenter, setAling] = useState(true);
    const [nWidth, setWidth] = useState(window.innerWidth);
    const schema = yup.object().shape({
        //nPersonTrain: yupFormSchemas.string("จำนวนผู้เข้าอบรม", { required: true }),
        sReason: yupFormSchemas.string(i18n(`${i18nField}.sReason`), { required: true }),

    });
    const formReserve = useForm({
        resolver: yupResolver(schema),
        mode: 'all',
        shouldFocusError: true,
        criteriaMode: "firstError"
    })
    const OnClear = async () => {
        setIsOpenModal(false);
        formReserve.reset();
        formReserve.setValue("sReason", "");

    }
    const GoToList = () => {
        navigate(process.env.REACT_APP_API_URL + "TrainingHistory");

    }
    const Cancel = async () => {
        let Param = {
            sRegID: sRegisID,
            sReason: formReserve.getValues("sReason"),
            isWaiting : isWaiting,
        }
        await Axios(
            `Classroom/Registration_Cancel`, Param, "POST", async (res) => {
                ResultAPI(true, res, "", () => {
                    if (res.isSuccess) {
                        setIsOpenModal(false);
                        GoToList();
                    }
                    else {
                        setIsOpenModal(false);
                        SwAlert.Warning(AlertTitle.Warning, res.sMessage, () => { });
                    }
                })
            },
            null,
            BlockUI,
            UnBlockUI,
            null
        );
    }
    useEffect(() => {

        if (nWidth >= 600) {
            setAling(false);
        } else {
            setAling(true);
        }
    }, [nWidth]);
    useEffect(() => {
        window.addEventListener("resize", updateDimensions);
        return () => window.removeEventListener("resize", updateDimensions);
    }, []);
    const updateDimensions = () => {
        setWidth(window.innerWidth);
    }
    useEffect(() => {

    }, [IsOpenModal])

    const elementAction = () => {
        return (
            <Grid container spacing={1} direction="row" justifyContent="right" alignItems="center">
                <Grid item><BtnOK onClick={() => { formReserve.handleSubmit(Cancel)() }} /></Grid>
                <Grid item><BtnCancelForm onClick={() => OnClear()} /></Grid>
            </Grid>
        )
    }

    return (
        <DialogCustomUI
            open={IsOpenModal}
            textHead="ยืนยันการดำเนินการ/Confirmation"
            fullWidth
            questionIcon
            handleClose={() => { setIsOpenModal(false) }}
            isSetHeadColor={true}
            titleBgColor={"#43a7f3"}
            titleColor={"#fff"}
            isShowPopupCancel={true}
            elementAction={elementAction}
        >
            <DialogContent sx={{
                display: "flex"
                , flexDirection: "column"
                , alignItems: "center", m: "1em", paddingBottom: "0px"
            }}>
                <Grid container spacing={2} direction="row" justifyContent="center" alignItems="center">

                    <Grid item xs={12} md={12} style={{ padding: '0px' }}>
                        <Divider />
                    </Grid>
                    <Grid item xs={12} md={12}>
                        <FormProvider {...formReserve}>
                            <form>
                                <Grid container spacing={2} direction="row" justifyContent="start" alignItems="center">
                                    <Grid item xs={12} md={12}>
                                        <Typography>
                                            {ParseHtml("&nbsp;&nbsp;")}ต้องการยกเลิกการลงทะเบียนเข้าอบรมหรือไม่
                                        </Typography>
                                        <Typography>
                                            {ParseHtml("&nbsp;&nbsp;")}Cancel your registration?
                                        </Typography>
                                    </Grid>
                                    <Grid item xs={12} md={12}>
                                        <InputForm
                                            name="sReason"
                                            label={i18n(`${i18nField}.sReason`)}
                                            required={true}
                                            styles={{ width: "90%" }}
                                            fullWidth
                                            multiline={true}
                                            rows={4}
                                            maxLength={1000}
                                            shrink={(formReserve.getValues('sReason') != "" && formReserve.getValues('sReason') != undefined) ? true : false}
                                        />
                                    </Grid>
                                    <Grid item xs={12} md={12}>
                                        <Typography>
                                            {ParseHtml("&nbsp;&nbsp;")}หากต้องการยกเลิกการลงทะเบียนให้กดปุ่ม <><b>"ยืนยัน"</b></>
                                        </Typography>
                                        <Typography>
                                            {ParseHtml("&nbsp;&nbsp;")}Press <><b>"OK"</b></> to Confirm
                                        </Typography>
                                    </Grid>
                                </Grid>
                            </form>
                        </FormProvider>
                    </Grid>
                </Grid>

            </DialogContent>
            <DialogActions sx={{ borderTop: "1px solid rgba(0,0,0,0.3)" }}>
                <Grid container spacing={1} direction="row" justifyContent={(!isCenter) ? "right" : "center"} alignItems={(!isCenter) ? "right" : "center"}>
                    <Grid item><BtnOK txt="ยืนยัน/OK" onClick={() => { formReserve.handleSubmit(Cancel)() }} /></Grid>
                    <Grid item><BtnCancelForm onClick={() => OnClear()} /></Grid>

                </Grid>
            </DialogActions>

        </DialogCustomUI>

        // <Modal

        //     open={IsOpenModal}
        //     onClose={() => { setIsOpenModal(false) }}
        //     aria-labelledby="modal-modal-title"
        //     aria-describedby="modal-modal-description"
        //     onBackdropClick={() => { setIsOpenModal(false); }}           
        // >
        //     <Fade in={IsOpenModal}>
        //         <Box sx={{
        //             position: 'absolute' as 'absolute',
        //             top: '50%',
        //             left: '50%',
        //             minWidth: '50%',
        //             transform: 'translate(-50%, -50%)',
        //             bgcolor: 'background.paper',
        //             boxShadow: 24,
        //             padding: '16px'
        //         }}>
        //     <Grid container spacing={2} direction="row" justifyContent="center" alignItems="center">
        //         <Grid item xs={12} md={12}>
        //             {/* <img src="" alt="LOGO" style={{width:'10px',height:'10px'}}/> */}
        //             <Typography variant="h6" component="h2">
        //                 ยืนยันการดำเนินการ/Conformation
        //             </Typography>
        //         </Grid>
        //         <Grid item xs={12} md={12} style={{ padding: '0px' }}>
        //             <Divider />
        //         </Grid>
        //         <Grid item xs={12} md={12}>
        //             <FormProvider {...formReserve}>
        //                 <form>
        //                     <Grid container spacing={2} direction="row" justifyContent="start" alignItems="center">
        //                         <Grid item xs={12} md={12}>
        //                             <Typography>
        //                                 {ParseHtml("&nbsp;&nbsp;")}ต้องการยกเลิกการลงทะเบียนเข้าอบรมหรือไม่
        //                             </Typography>
        //                             <Typography>
        //                                 {ParseHtml("&nbsp;&nbsp;")}Cancel your registration?
        //                             </Typography>
        //                         </Grid>
        //                         <Grid item xs={12} md={12}>
        //                             <InputForm
        //                                 name="sReason"
        //                                 label={i18n(`${i18nField}.sReason`)}
        //                                 required={true}
        //                                 styles={{ width: "90%" }}
        //                                 fullWidth
        //                                 multiline={true}
        //                                 rows={4}
        //                                 maxLength={1000}
        //                                 shrink={(formReserve.getValues('sReason') != "" && formReserve.getValues('sReason') != undefined) ? true : false}
        //                             />
        //                         </Grid>
        //                         <Grid item xs={12} md={12}>
        //                             <Typography>
        //                                 {ParseHtml("&nbsp;&nbsp;")}หากต้องการยกเลิกการลงทะเบียนให้กดปุ่ม <><b>"ยืนยัน"</b></>
        //                             </Typography>
        //                             <Typography>
        //                                 {ParseHtml("&nbsp;&nbsp;")}Press <><b>"OK"</b></> to Confirm
        //                             </Typography>
        //                         </Grid>
        //                     </Grid>
        //                 </form>
        //             </FormProvider>
        //         </Grid>
        //         <Grid item xs={12} md={12} style={{ padding: '0px' }}>
        //             <Divider />
        //         </Grid>
        //         <Grid item xs={12} md={12}>
        //             <Grid container spacing={1} direction="row" justifyContent="right" alignItems="center">
        //                 <Grid item><BtnOK onClick={() => { formReserve.handleSubmit(Cancel)() }} /></Grid>
        //                 <Grid item><BtnCancelForm onClick={() => OnClear()} /></Grid>

        //             </Grid>
        //         </Grid>
        //     </Grid>
        //     </Box>
        //     </Fade>

        // </Modal>
    )
}

export default PopupCancel