import { useEffect, useRef, useState } from "react";
import { Dialog, DialogTitle, DialogContent, DialogActions, Grid, Box, SxProps, Stack, Divider } from "@mui/material";
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import { styled } from '@mui/material/styles';
import { FnBlock_UI } from 'src/Components/BlockUI';
import { Axios, AxiosGetAsync } from 'src/CommonFunction/TS_Axios';
import { BsFlagFill } from 'react-icons/bs';
import { MdLocationPin } from 'react-icons/md';
import { BiCalendar } from 'react-icons/bi';
import { useForm } from "react-hook-form";
import DialogPreview from "src/Components/FormItem/Dialog/DialogPreview";
import { IsNullOrUndefined, ParseHtml } from "src/CommonFunction/TS_function";
import "./ClassroomDetail.css";
import { BtnDetail, BtnCancelForm } from 'src/Components/Button';
import { ClassroomPrice } from 'src/Components/System/ClassroomModal/ClassroomPrice';
import "src/Components/System/ClassColor.css"
export function ClassroomDetailModal(props: pClassroom) {

    const [open, setOpen] = useState(false);
    const [lstData, setData] = useState([] as any);
    const [objData, setobjData] = useState({
        open: false,
        lstData: {} as any
    });

    const { BlockUI, UnBlockUI } = FnBlock_UI();

    const GetClassroomDetail = async () => {
        // BlockUI();
        let result = {} as any;
        // result = await AxiosGetAsync("Classroom/GetClassroomDetail", { nCourseID: props.nID, nScheduleID: props.nScheduleID });
        // setData(result);
        // setobjData({
        //     open: true,
        //     lstData: result
        // })
        let strParam = "nCourseID=" + props.nID + "&" + "nScheduleID=" + props.nScheduleID;
        await Axios(
            `Classroom/GetClassroomDetail`, strParam, "Get", async (res) => {
                result = res;
                setData(res);
                setobjData({
                    open: true,
                    lstData: res
                })

            },
            null,
            BlockUI,
            UnBlockUI,
            null
        );
        // UnBlockUI();
        return result;
    };
    // const GetData = async () => {
    //     let res = await GetClassroomDetail();

    //     setData(res);

    // };
    const BootstrapDialog = styled(Dialog)(({ theme }) => ({
        '& .MuiDialogContent-root': {
            padding: theme.spacing(2),
        },
        '& .MuiDialogActions-root': {
            padding: theme.spacing(1),
        },
    }));

    useEffect(() => {
        if (props.isOpen) {
            if (props.nID) {
                // setOpen(props.isOpen)
                GetClassroomDetail();
                // console.log("GetClassroomDetail", props.isOpen)

            }
        }
    }, [props.isOpen]);

    useEffect(() => {
        setOpen(true)
    }, [lstData]);

    const handleClickOpen = () => {
        setOpen(true);
    };
    const handleClose = () => {
        setOpen(false);
        if (props.onClose) {
            props.onClose(false)
        }
    };

    const BootstrapDialogTitle = (props: DialogTitleProps) => {
        const { children, onClose, ...other } = props;

        return (
            <DialogTitle sx={{ m: 0, p: 2, paddingLeft: "1.5em !important" }} className="card-titie-dialog-color" {...other}>
                {children}
                {onClose ? (
                    <IconButton
                        aria-label="close"
                        onClick={() => { onClose() }}
                        sx={{
                            position: 'absolute',
                            right: 8,
                            top: 8,
                            color: "#fff"
                        }}
                    >
                        <CloseIcon />
                    </IconButton>
                ) : null}
            </DialogTitle>
        );
    };

    return (
        <>

            <div >
                <BootstrapDialog
                    onClose={() => { handleClose() }}
                    aria-labelledby="customized-dialog-title"
                    open={objData.open}
                    maxWidth={"md"}
                    fullWidth={true}

                >
                    <BootstrapDialogTitle id="customized-dialog-title" onClose={() => { handleClose() }}>
                        <div style={{ paddingTop: "0.7em" }}>
                            {objData.lstData.sTitle}
                        </div>
                    </BootstrapDialogTitle>

                    <DialogContent dividers sx={{ paddingLeft: "1.5em !important" }}>
                        <Typography gutterBottom >
                            <p className="m-title-detail"
                                style={{
                                    WebkitLineClamp: 1,
                                    overflow: "hidden",
                                    whiteSpace: "nowrap",
                                    textOverflow: "ellipsis",
                                    flexWrap: "nowrap",
                                    margin: "0px",
                                    WebkitBoxOrient: "vertical",
                                }}
                            >
                                <BsFlagFill
                                    color="rgb(251 107 42)"
                                    size="20"
                                    style={{
                                        marginRight: "5px",
                                    }}
                                />
                                {ParseHtml("&nbsp;&nbsp;")}{objData.lstData.sScheduleName}

                            </p>
                            <p className="m-title-detail"
                                style={{
                                    display: "flex",
                                    alignItems: "center",
                                    margin: "0px",
                                }}
                            >
                                <BiCalendar
                                    color="rgb(0 0 255)"
                                    size="20"
                                    style={{
                                        marginRight: "5px",
                                    }}
                                />
                                {ParseHtml("&nbsp;&nbsp;")}{objData.lstData.sClassDate}

                            </p>
                            <p className="m-title-detail"
                                style={{
                                    display: "flex",
                                    alignItems: "center",
                                    margin: "0px",
                                }}
                            >
                                <MdLocationPin
                                    color="rgb(255 0 0)"
                                    size="20"
                                    style={{ marginRight: "5px" }}
                                />
                                {ParseHtml("&nbsp;&nbsp;")}{objData.lstData.sPlace}<>{!IsNullOrUndefined(objData.lstData.sPlace) && !IsNullOrUndefined(objData.lstData.sRoom) ? <>,</> : <></>}</>{objData.lstData.sRoom}
                            </p>

                        </Typography>
                        <Typography gutterBottom>

                            {ParseHtml("&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;")} {ParseHtml(objData.lstData.sDesc)}
                        </Typography>
                        <Typography gutterBottom>
                            <p className="m-title-content">เนื้อหาหลักสูตร/Course Content</p>
                            {ParseHtml(objData.lstData.sCourseContent)}

                        </Typography>
                        <Typography gutterBottom>

                            <p className="m-title-content">ผลลัพธ์จากการอบรม/Learning Outcomes</p>
                            {ParseHtml(objData.lstData.sLearningOutcome)}

                        </Typography>
                        <Typography gutterBottom>
                            <p className="m-title-content">คุณสมบัติ,ระดับของผู้อบรม</p>
                            {ParseHtml(objData.lstData.sQualification)}

                        </Typography>
                       
                        {

                            // (!objData.lstData.isExpire) ?
                            ((objData.lstData.lstPrice || []).length > 0 ? objData.lstData.lstPrice : []).map(
                                (m) => {

                                    let price: { sID: string, nCourseID: number, nPriceID: number, nScheduleID: number, sPriceSchedule: string, sPrice: string, sNameRoom: string, isRegisterable: boolean, sMsgRegisterable: string, isWaitingList: boolean }[] = [
                                        { "sID": m.nCourseID, "nCourseID": m.nCourseID, "nPriceID": m.nPriceID, "nScheduleID": m.nScheduleID, "sPriceSchedule": m.sPriceSchedule, "sPrice": m.sPrice, "sNameRoom": m.sNameRoom, "isRegisterable": m.oRegis.isRegisterable, "sMsgRegisterable": m.oRegis.sMsgRegisterable, "isWaitingList": m.oRegis.isWaitingList },
                                    ];

                                    return (<>
                                        <Box>

                                            <ClassroomPrice dataPrice={price} sField={m.sNameRoom} dataColumn={m.dataColumn} isExpire={objData.lstData.isExpire}></ClassroomPrice>

                                        </Box></>)

                                }
                            )
                            // :
                            // (objData.lstData.lstPrice.length > 0 && !IsNullOrUndefined(objData.lstData.lstPrice[0].oRegis.sMsgRegisterable)) ?

                            //     <Typography gutterBottom style={{ textAlign: "center" }}>


                            //         <Divider style={{ paddingBottom: "0.2em" }} />
                            //         <p style={{ color: "#db0000", fontWeight: "550", padding: "0.1em", fontSize: "1.3em" }}>{objData.lstData.lstPrice[0].oRegis.sMsgRegisterable}</p>
                            //         <Divider style={{ paddingTop: "0.2em" }} />
                            //     </Typography>
                            //     :
                            //     <></>

                        }

                        {!IsNullOrUndefined(objData.lstData.sContactPerson) &&
                            <Typography gutterBottom>
                                <p className="m-title-content">ติดต่อสอบถามผู้ดูแลหลักสูตร/Contact Person</p>
                                {ParseHtml(objData.lstData.sContactPerson)}
                            </Typography>
                        }
                    </DialogContent>
                    <DialogActions>
                        <BtnCancelForm txt={"ย้อนกลับ/Back"} onClick={handleClose} />
                    </DialogActions>
                </BootstrapDialog>
            </div>

        </>
    );

}
interface pClassroom {
    nID: number;
    nScheduleID: number;
    isOpen?: any;
    setIsOpenModal?: any;
    onClose?: (e) => any;
}
interface DialogTitleProps {
    id: string;
    children?: React.ReactNode;
    onClose: () => void;
}