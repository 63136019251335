import { Box, Grid } from '@mui/material';
import { AiOutlineEye } from 'react-icons/ai';
import { FaRegLightbulb } from 'react-icons/fa';
import { useNavigate } from 'react-router';
import defaultImage from "../../Layout/img/topic-2.jpg";
import { BtnSeeDetail } from '../Button';

const GeneralTopic = (item) => {
    const navigate = useNavigate();

    const gotoDetail = (nIDC: string) => {
        item.fn(nIDC)
    };

    return (
        <>
            <Grid item xs={12} sx={{ m:"1em",display:"flex",justifyContent:"center",alignItems:"center",flexDirection:"column"}}>
                {item.Detail.isPin && (
                    <Grid container
                        justifyContent="flex-end"
                        alignItems="flex-end"
                        >
                        <a
                            style={{
                                backgroundColor: "red",
                                color: "white",
                                borderRadius: "16px",
                                height: "30px",
                                marginBottom: "-25px",
                                marginLeft: "5px",
                                width: "45px",
                                fontSize: "12px",
                                alignItems: "center",
                                display: "flex",
                                justifyContent: "center",
                                padding: "0 4px",
                                position:"absolute",
                            }}
                        >
                            New
                        </a>
                    </Grid>
                )}
                <div className="courseSharingGeneral">
                    <div className="img-hover-zoomSharing">
                        <img src={item.Detail.sURLImage || defaultImage}
                            onError={({ currentTarget }) => {
                                currentTarget.onerror = null;
                                currentTarget.src = defaultImage;
                            }} />
                    </div>
                    <footer>
                        <a style={{

                            height: "40px",
                            width: "40px",
                        }}>
                            <FaRegLightbulb size={25} color="white" />
                        </a>
                        <a
                            style={{
                                backgroundColor: "Gainsboro",
                                borderRadius: "16px",
                                height: "25px",
                                margin: "0",
                                width: "55px",
                                marginTop: "-5px",
                                marginLeft: "50px",
                            }}
                        >
                            <AiOutlineEye
                                size={20} />
                            <p style={{

                                paddingLeft: "5px"
                            }}>
                                {item.Detail.nconutView}
                            </p>
                        </a>
                    </footer>
                </div>
                <p
                    style={{
                        fontSize: "15px",
                        height: "45px",
                        WebkitBoxOrient: "vertical",
                        display: "webkitBox",
                        WebkitLineClamp: 2,
                        overflow: "hidden",
                        textOverflow: "ellipsis",
                        marginTop: "50px",
                        wordBreak: "break-word",
                    }}
                >
                    {item.Detail.sNames}
                </p>
                <Grid xs={12} justifyContent="center" sx={{ marginTop: "8%" }}>
                    <BtnSeeDetail
                        fullWidth
                        txt="อ่านต่อ/Read more"
                        color="primary"
                        onClick={() => gotoDetail(item.Detail.sID)}
                    />
                </Grid>
                <Grid
                    item
                    xs={12}
                    sx={{ marginTop: "8px" }}
                >
                </Grid>
            </Grid>
        </>
    )
}
export default GeneralTopic
