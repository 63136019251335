import { useEffect, useRef, useState } from "react";
import { DataGridMui, PaginationInterface, initRows, FilterFieldInterface } from "src/Components/Table/DataGridMui";
import { GridColumns } from "@mui/x-data-grid-pro";
import { Box } from "@mui/material";
import {
    BtnViewOnTable,
    BtnDetail
} from "src/Components/Button";
import { Axios, AxiosGetAsync } from "src/CommonFunction/TS_Axios";
import { ParseHtml } from "src/CommonFunction/TS_function";
import { useNavigate } from 'react-router';
import { FnBlock_UI } from "../../BlockUI";
import { AlertMsg, AlertTitle, DialogConfirm, DialogConfirmDelete, SwAlert } from "src/Components/Alert/Sweetalert";
import "src/Components/System/ClassColor.css"
export function ClassroomPrice(props: pClassroomPrice) {
    const navigate = useNavigate();
    const { BlockUI, UnBlockUI } = FnBlock_UI();
    const onPageLoad = async (nCourseID, nScheduleID, nPriceID, isWaitingList) => {
        let result = {} as any;
        // result = await AxiosGetAsync("Classroom/GetPath", { nCourseID: nCourseID, nScheduleID: nScheduleID, nPriceID: nPriceID, isWaiting: isWaitingList });

        // if (!result.isSuccess) {
        //     SwAlert.Warning(AlertTitle.Warning, AlertMsg.SessionTimeOut, () => { UnBlockUI(); });
        // } else {
        //     navigate(process.env.REACT_APP_API_URL + result.sMessage);
        // }
        let strParam = "nCourseID=" + nCourseID + "&" + "nScheduleID=" + nScheduleID + "&nPriceID=" + nPriceID + "&isWaiting=" + isWaitingList;
        await Axios(
            `Classroom/GetPath`, strParam, "Get", async (res) => {
                result = res;
                if (!result.isSuccess) {
                    SwAlert.Login("เข้าสู่ระบบ/Login")
                    //SwAlert.Warning(AlertTitle.Warning, AlertMsg.SessionTimeOut, () => { UnBlockUI(); });
                } else {
                    navigate(process.env.REACT_APP_API_URL + result.sMessage);
                }

            },
            null,
            BlockUI,
            UnBlockUI,
            () => { SwAlert.Login("เข้าสู่ระบบ/Login") }

        );

    }
    const dataColumn: GridColumns = [

        {
            field: "",
            headerName: props.sField,
            headerAlign: "left",
            align: "left",
            sortable: false,
            flex: 1,
            editable: false,
            maxWidth: 1000,
            minWidth: 840,
            resizable: true,
            renderCell: (item) => {
                return (
                    <table style={{ width: "100%", marginTop: "0.5em" }}>
                        <tbody>
                            <tr>
                                <td rowSpan={2} style={{ width: "50%", textAlign: "left" }}>
                                    <div>
                                        ค่าใช้จ่าย (รวมภาษี) <span style={{ color: "#db0000", fontWeight: "550" }}>{item.row.sPriceSchedule}</span>  บาท/ท่าน
                                    </div>
                                </td>

                                <td style={{ width: "50%", textAlign: "right" }}>
                                    {(item.row.isRegisterable) ?
                                        <>
                                            <BtnDetail
                                                txt={item.row.isWaitingList ? "ลงทะเบียน Waiting List/Register Waiting List" : "ลงทะเบียน/Register"}
                                                color={item.row.isWaitingList ? "rgb(237, 108, 2)" : "#366d21"}
                                                hover={item.row.isWaitingList ? "#de6808" : "#188e32"}
                                                onClick={() => onPageLoad(item.row.nCourseID, item.row.nScheduleID, item.row.nPriceID, item.row.isWaitingList)}
                                            />

                                        </>
                                        :
                                        <>
                                            <div>
                                                <span style={{ color: "#db0000", fontWeight: "550" }}>{item.row.sMsgRegisterable}</span>
                                            </div>
                                        </>
                                    }

                                </td>
                            </tr>
                            <tr>
                                <td colSpan={2} style={{ width: "100%", textAlign: "right",paddingBottom:"0.2em" }}>
                                    {(props.isExpire && item.row.isRegisterable) ?
                                        <div className="m-login-content">*กรุณาเข้าสู่ระบบก่อนลงทะเบียน</div>
                                        : <></>}
                                </td>
                            </tr>
                        </tbody>
                    </table>
                )
            },

        },

    ];
    const [dataRow, setDataRow] = useState<any>({
        ...initRows,
    });
    const onLoadDataTable = (p: PaginationInterface) => {
        let dataFilter2 = props.dataPrice;
        setDataRow({
            ...p,
            arrRows: dataFilter2,
            nDataLength: 10,
            nPageIndex: 1,
        });

    }
    useEffect(() => {
        onLoadDataTable(initRows);
    }, [])

    return (
        <>
            <Box>
                <DataGridMui
                    Columns={dataColumn}
                    OnLoadData={(p: PaginationInterface) => {
                        onLoadDataTable(p)
                    }}
                    Rows={dataRow}
                    isNotShowTotal={true}
                    isNotShowPagination={true}
                    HiddenToolHead={true}
                    sxCustomTable={{
                        ".MuiDataGrid-virtualScrollerContent": {
                            height: "65px !important",
                        },
                        ".MuiDataGrid-cell": {
                            minHeight: "65px !important",
                            maxHeight: "65px !important"
                        },
                        ".MuiDataGrid-row": {
                            minHeight: "65px !important",
                            maxHeight: "65px !important"
                        },
                        ".MuiDataGrid-footerContainer": {
                            display: "none !important",

                        },

                    }}
                    sxCustomHeader={{
                        color: "#fff !important"

                    }}
                    backgroundHeaderCustom={"#122768"}
                />
            </Box>
            <Box sx={{ paddingTop: 3 }}></Box>
        </>
    );

}
interface pClassroomPrice {
    dataPrice?: any;
    dataColumn?: any;
    sField?: any;
    isExpire: any;

}