import { Button, Card, Grid, Typography } from '@mui/material';
import React from 'react';
import { useEffect, useState } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { Axios } from 'src/CommonFunction/TS_Axios';
import { FnBlock_UI } from 'src/Components/BlockUI';
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { InputForm } from 'src/Components/FormItem/Input';
import { DataGridMui as Table, initRows, PaginationInterface } from "src/Components/Table/DataGridMui";
import { DateRangePickerUI } from 'src/Components/FormItem/DateRangePicker';
import SelectForm from 'src/Components/FormItem/Select';
import { BtnCustomIcon, BtnCustomSearch, BtnCustomText, BtnSearch } from 'src/Components/Button';
import Search from '@mui/icons-material/Search';
import { GridColumns } from '@mui/x-data-grid-pro';
import AutoCompleteSelect from 'src/Components/FormItem/AutoCompleteSelect';
import { DatePickerUI } from "src/Components/FormItem/DatePicker";
import { ControlPointSharp } from '@mui/icons-material';

const HistoryTrainEmp = () => {
  const lstTabsHistoryTrain = [
    { value: 1, label: "รายหลักสูตร" },
    { value: 2, label: "รายบุคคล" },
  ];
  const { BlockUI, UnBlockUI } = FnBlock_UI();
  const [idHistoryTrainTabs, setIdHistoryTrainTabs] = useState<Number>(1);
  const [dataRowCourse, setDataRowCourse] = useState<PaginationInterface>({
    ...initRows,
  });
  const [dataRowEmp, setDataRowEmp] = useState<PaginationInterface>({
    ...initRows,
  });

  const [objExpand, setObjExpand] = useState<any>(null);
  const [objByEmp, setObjByEmp] = useState<any>(null);
  const [StartDate, setStartDate] = useState(undefined);
  const [EndDate, setEndDate] = useState(undefined);
  const [totallst, setTotalLst] = useState(0);

  const objSchema = {};
  const schema = yup.object().shape(objSchema);
  const form = useForm({
    mode: "all",
    resolver: yupResolver(schema),
    shouldFocusError: true,
  })

  useEffect(() => {

  }, [])

  const Sort = [
    { value: "1", label: "ผ่าน" },
    { value: "2", label: "ไม่ผ่าน" },
    { value: "3", label: "รอบันทึกผล" },
  ]

const [lstYearCourse, setDataYear] = useState<any[]>([]);

  const dataColumnCourse: GridColumns = [
    {
      field: "nRow",
      headerName: "ที่",
      headerAlign: "center",
      editable: false,
      width: 70,
      resizable: false,
      filterable: false,
      align: "center",
      sortable: false,
    },
    {
      field: "sCourseName",
      headerName: "หลักสูตร",
      headerAlign: "center",
      align: "left",
      flex: 1.5,
      resizable: true,
      minWidth: 500,
    },
    {
      field: "sTrainDate",
      headerName: "วันที่อบรม",
      headerAlign: "center",
      align: "center",
      flex: 1,
      resizable: true,
      minWidth: 300,
      maxWidth:300,
    },
    {
      field: "sGen",
      headerName: "รุ่น",
      headerAlign: "center",
      align: "center",
      flex: 1,
      resizable: true,
      minWidth: 100,
      maxWidth:100,
    },
    {
      field: "sResult",
      headerName: "ผลการอบรม",
      headerAlign: "center",
      align: "center",
      flex: 1.5,
      resizable: true,
      minWidth: 350,
      maxWidth:350,
    },
  ];

  const dataColumnEmp: GridColumns = [
    {
      field: "nRow",
      headerName: "ที่",
      headerAlign: "center",
      editable: false,
      width: 70,
      resizable: false,
      filterable: false,
      align: "center",
      sortable: false,
    },
    {
      field: "sEmpName",
      headerName: "ผู้เข้าอบรม",
      headerAlign: "center",
      align: "left",
      flex: 1.5,
      resizable: true,
      minWidth: 500,
    },
    {
      field: "sTrainDate",
      headerName: "วันที่อบรม",
      headerAlign: "center",
      align: "center",
      flex: 1,
      resizable: true,
      minWidth: 300,
      maxWidth:300,
    },
    {
      field: "sGen",
      headerName: "รุ่น",
      headerAlign: "center",
      align: "center",
      flex: 1,
      resizable: true,
      minWidth: 100,
      maxWidth:100,
    },
    {
      field: "sResult",
      headerName: "ผลการอบรม",
      headerAlign: "center",
      align: "center",
      flex: 1.5,
      resizable: true,
      minWidth: 350,
      maxWidth:350,
    },
  ];

  const loadDataCourse = async (p: PaginationInterface) => {
    setObjByEmp(null)
    setObjExpand(null)

    // let date = form.getValues("dateRange");
    // var sStart = form.getValues("sStart");
    // var sEnd = form.getValues("sEnd");
    // if (sStart.length != 0) {
    //   sStart = sStart.format("YYYY")
    // }
    // if (sEnd.length != 0) {
    //   sEnd = sEnd.format("YYYY")
    // }

    var YearStart = "";
    if (form.getValues("nYearStart") != undefined) {
      YearStart = form.getValues("nYearStart").value;
    }

    var YearEnd ="";
    if (form.getValues("nYearEnd") != undefined) {
      YearEnd = form.getValues("nYearEnd").value;
    }
    

    var Status = {
      value: "",
      label: "",
    };
    if (form.getValues("cResult") != undefined) {
      Status = form.getValues("cResult");
    }

    let param = {
      nPageSize: p.nPageSize || 10,
      nPageIndex: p.nPageIndex || 1,
      sSortExpression: p.sSortExpression || "",
      sSortDirection: p.sSortDirection.toUpperCase() || "",
      sCourseSearch: form.getValues("sCourseSearch") || "",
      sEmpSearch: form.getValues("sEmpSearch") || "",
      sYearBegin: YearStart+"" || "",
      sYearEnd: YearEnd+"" || "",
      sResult: Status.value || "",
    };
    let result = {} as any;
    await Axios(
      `HistoryPage/GetData_HistoryTrainEmp2`,
      param || {},
      "Post",
      async (res) => {
        setDataYear(res.lstYear);
        setDataRowCourse({
          ...p,
          arrRows: res.lstByCourse,
          nDataLength: res.nDataLength === 0 ? res.nDataLength : (res.nDataLength > 10 ? res.nDataLength : 10),
          nPageIndex: res.nPageIndex,
        });
        
      },
      null,
      BlockUI,
      UnBlockUI,
      null
    );
    return result;
  };
  const loadDataEmp = async (p: PaginationInterface) => {
    setObjByEmp(null)
    setObjExpand(null)
    let date = form.getValues("dateRange");
    // var sStart = form.getValues("sStart");
    // var sEnd = form.getValues("sEnd");
    // if (sStart.length != 0) {
    //   sStart = sStart.format("YYYY")
    // }
    // if (sEnd.length != 0) {
    //   sEnd = sEnd.format("YYYY")
    // }

    
    var YearStart  = "";
    if (form.getValues("nYearStart") != undefined) {
      YearStart = form.getValues("nYearStart").value;
    }

    var YearEnd = "";
    if (form.getValues("nYearEnd") != undefined) {
      YearEnd = form.getValues("nYearEnd").value;
    }

    var Status = {
      value: "",
      label: "",
    };
    if (form.getValues("cResult") != undefined) {
      Status = form.getValues("cResult");
    }
    let param = {
      nPageSize: p.nPageSize || 10,
      nPageIndex: p.nPageIndex || 1,
      sSortExpression: p.sSortExpression || "",
      sSortDirection: p.sSortDirection.toUpperCase() || "",
      sCourseSearch: form.getValues("sCourseSearch") || "",
      sEmpSearch: form.getValues("sEmpSearch") || "",
      sYearBegin: YearStart+"" || "",
      sYearEnd: YearEnd+"" || "",
      sResult: Status.value || "",
    };
    let result = {} as any;
    await Axios(
      `HistoryPage/GetData_HistoryTrainEmp3`,
      param || {},
      "Post",
      async (res) => {
        result = res;
        setDataYear(res.lstYear);
        setDataRowEmp({
          ...p,
          arrRows: res.lstByEmp,
          nDataLength: res.nDataLength === 0 ? res.nDataLength : (res.nDataLength > 10 ? res.nDataLength : 10),
          nPageIndex: res.nPageIndex,
        });
      },
      null,
      BlockUI,
      UnBlockUI,
      null
    );
    return result;
  };


  const onClickTabs = (value) => {
    if (value == 1) {
      loadDataCourse(dataRowCourse);
    }
    if (value == 2) {
      loadDataEmp(dataRowEmp);
    }
    setIdHistoryTrainTabs(value)
  }

  useEffect(() => {
    loadDataCourse(dataRowCourse);
  }, []);


  const loadDataExpand = (row) => {
    setObjExpand(row);
  };

  const ExpandElementCourse = (ObjExpand) => {
    return ObjExpand.row.lstEmp &&  ObjExpand.row.lstEmp.length > 0 ? (
      <div>
        {
          ObjExpand.row.lstEmp.map((item) =>
            <div style={{ display: "flex",overflowX:"hidden" }}>
              <div style={{ minWidth: "50px" }}>
              </div>
              <div style={{ marginTop: "10px", minWidth: "70px",textAlign:"center",fontSize:"1em" }}>
              {item && item.sID || ""}
              </div>
              <div style={{ marginTop: "10px", width: "750px",textAlign:"left",fontSize:"1em" }}>
                &nbsp; {item && item.sEmpName || ""}
              </div>
              <div style={{ marginTop: "10px", minWidth: "300px",textAlign:"center",fontSize:"1em" }}>
                {item && item.sTrainDateBegin + " - " + item.sTrainDateEnd || ""}
              </div>
              <div style={{ marginTop: "10px", minWidth:"100px" ,textAlign:"center",fontSize:"1em" }}>
                {item && item.sGen || ""}
              </div>
              <div style={{ marginTop: "10px", minWidth: "350px",textAlign:"center",fontSize:"1em" }}>
                {item && item.sResult || ""}
              </div>
            </div>
          )
        }
      </div>
    ): null;
  };

  const loadDataByEmp = (row) => {
    setObjByEmp(row);
  };

  
  const ExpandElementByEmp = (ObjByEmp) => {
    return ObjByEmp.row.lstCourse && ObjByEmp.row.lstCourse.length > 0 ? (
      <div>
        {
          ObjByEmp.row.lstCourse.map((item) =>
            <div style={{ display: "flex",overflowX:"hidden" }}>
            <div style={{ minWidth: "50px" }}>
            </div>
            <div style={{ marginTop: "10px", minWidth: "70px",textAlign:"center",fontSize:"1em" }}>
              {item && item.sID || ""}
              </div>
              <div style={{ marginTop: "10px", width: "750px",textAlign:"left",fontSize:"1em" }}>
                &nbsp; {item && item.sCourseName || ""}
              </div>
              <div style={{ marginTop: "10px", minWidth: "300px",textAlign:"center",fontSize:"1em" }}>
                {item && item.sTrainDateBegin + " - " + item.sTrainDateEnd || ""}
              </div>
              <div style={{ marginTop: "10px", minWidth:"100px" ,textAlign:"center",fontSize:"1em" }}>
                {item && item.sGen || ""}
              </div>
              <div style={{ marginTop: "10px", minWidth:"350px" ,textAlign:"center",fontSize:"1em" }}>
                {item && item.sResult || ""}
              </div>
            </div>
          )
        }
      </div>
    ):null;
  };

  return (
    <>
      <FormProvider {...form}>
        <form>
          <Grid container >
            <Grid item xs={12} sx={{ display: "flex", m: "0em 1em 1em 1em" }}>
              {lstTabsHistoryTrain.map((item) => (
                <Grid item xs={12} sm={2} lg={1.5}>
                  <Card
                    sx={{
                      height: "40px",
                      cursor: "pointer",
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      bgcolor: idHistoryTrainTabs == item.value ? "#1976d2" : "#fff",
                      color: idHistoryTrainTabs == item.value ? "#fff" : "#1976d2",
                      mr: "10px"
                    }}
                    onClick={() => onClickTabs(item.value)}
                  >
                    <Typography align="center" variant="subtitle1">
                      {item.label}
                    </Typography>
                  </Card>
                </Grid>
              ))
              }
            </Grid>
            
            <Grid container sx={{ display: "flex", m: "0em 1em 1em 1em",justifyContent:"flex-end" }}>
              <Grid item xs={12} lg={2} sx={{px: { lg: "0.5em"}}}>
                <InputForm
                  name="sCourseSearch"
                  placeholder="ค้นหาจากชื่อหลักสูตร"
                  showClear
                  fullWidth
                  mx={0}
                />
              </Grid>
              <Grid item xs={12} lg={2} sx={{px: { lg: "0.5em"}}}>
                <InputForm
                  name="sEmpSearch"
                  placeholder="ค้นหาจากชื่อพนักงาน"
                  showClear
                  fullWidth
                  mx={0}
                />
              </Grid>
              <Grid item xs={12} lg={2} sx={{px: { lg: "0.5em"}}}>
                {/* <DatePickerUI
                  label="วันที่เริ่ม"
                  name="sStart"
                  small
                  onChange={(e) => setStartDate(e)}
                  maxDate={EndDate ? EndDate : undefined}
                  onClearFields={(e)=> setStartDate(undefined)}
                /> */
                <AutoCompleteSelect name={"nYearStart"} fullWidth width={"100%"} options={lstYearCourse || []} label={"ปีเริ่มต้น"} mx={0} />
                }
              </Grid>
              <Grid item xs={12} lg={2}sx={{px: { lg: "0.5em"}}} >
                {/* <DatePickerUI
                  label="ถึงวันที่"
                  name="sEnd"
                  small
                  onChange={(e) => setEndDate(e)}
                  minDate={StartDate ? StartDate : undefined}
                  onClearFields={(e)=> setEndDate(undefined)}
                /> */}
                 <AutoCompleteSelect name={"nYearEnd"} fullWidth width={"100%"} options={lstYearCourse || []} label={"ปีสิ้นสุด"} mx={0} />
              </Grid>
              <Grid item xs={12} lg={2} sx={{px: { lg: "0.5em"}}}>
                <AutoCompleteSelect name={"cResult"} fullWidth width={"100%"} options={Sort || []} label={"ผลการอบรม"} mx={0} />
              </Grid>
              <Grid item xs={12} lg={1} sx={{ justifyContent: "center" }}>
                {
                  idHistoryTrainTabs === 1 ? <BtnCustomText txt="ค้นหา/Search" onClick={() => { loadDataCourse(dataRowCourse) }} /> :
                    idHistoryTrainTabs === 2 ? <BtnCustomText txt="ค้นหา/Search" onClick={() => { loadDataEmp(dataRowEmp) }}  /> : null
                }
                
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={12}>
            {
              idHistoryTrainTabs === 1 ?
                <Table
                  HiddenToolHead
                  Rows={dataRowCourse}
                  Columns={dataColumnCourse}
                  isExpan={true}
                  OnLoadData={(p) => loadDataCourse(p)}
                  backgroundHeaderCustom={"#0a286c"}
                  sxCustomHeader={{ color: "#FFF" }}
                  isRowSelectable ={()=> true}
                  sxCustomTable={{
                    ".MuiDataGrid-cell": {
                        minHeight: "35px !important",
                        maxHeight: "35px !important"
                    },
                    ".MuiDataGrid-row": {
                        minHeight: "35px !important",
                        maxHeight: "35px !important"
                    },
                }}
                  ContentExpand= {(row)=> ExpandElementCourse(row)} 
                  // {ExpandElementCourse
                  //   // objExpand != null ? ExpandElementCourse(objExpand) : <div></div> 
                  // }
                 nExpandRowLength={objExpand == null? null : objExpand.lstEmp.length }
                 onExpand={(row) =>loadDataExpand(row) }
                /> :
                idHistoryTrainTabs === 2 ?
                  <Table
                    HiddenToolHead
                    Rows={dataRowEmp}
                    Columns={dataColumnEmp}
                    isExpan={true}
                    OnLoadData={(p) => loadDataEmp(p)}
                    backgroundHeaderCustom={"#0a286c"}
                    sxCustomHeader={{ color: "#FFF" }}
                    sxCustomTable={{
                      ".MuiDataGrid-cell": {
                          minHeight: "35px !important",
                          maxHeight: "35px !important"
                      },
                      ".MuiDataGrid-row": {
                          minHeight: "35px !important",
                          maxHeight: "35px !important"
                      },
                  }}
              //   ContentExpand= {(row)=> ExpandElementByEmp(row)} 
              //  ContentExpand={ ExpandElementByEmp(objByEmp)   }
              //   nExpandRowLength={objByEmp == null? null : objByEmp.lstCourse.length }
              //  onExpand={(row) => loadDataByEmp(row)}

                  ContentExpand= {(row)=> {
                 return ExpandElementByEmp(row)} }
                 nExpandRowLength={objByEmp == null? null : objByEmp.lstCourse.length }
                 onExpand={(row) =>loadDataByEmp(row) }
                  /> : null
            }
          </Grid>
        </form>
      </FormProvider>
    </>
  )
}

export default HistoryTrainEmp