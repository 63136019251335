import React from 'react';
import _get from 'lodash/get';
import moment from 'moment';
import { setLocale as setYupLocale } from 'yup';
import { isNull } from 'util';
import enLocal from 'date-fns/locale/en-US';
import 'moment/locale/th';
import dictionaryEN from './en'

let currentLanguageCode = 'en';

const languages: {
  [key: string]: {
    id: string;
    label: string;
    flag: string;
    dateFns: any;
    dictionary: any;
  };
} = {
  en: {
    id: 'en',
    label: 'Thai',
    flag: '/images/flags/United-States.png',
    dateFns: enLocal,
    dictionary: dictionaryEN,
  },

};

export async function init() {
  // currentLanguageCode =
  //   localStorage.getItem('language') || 'th';
  currentLanguageCode = 'en';
  setLanguageCode(currentLanguageCode);

  if (currentLanguageCode === 'en') {
    await initEn();
  }

}

async function initEn() {
  const language = languages['en'];
  language.dictionary = (
    await import('./en')
  ).default;

  if (language.dictionary.validation) {
    setYupLocale(language.dictionary.validation);
  }

  return language;
}

export function getLanguage() {
  return languages[getLanguageCode()];
}

function format(message, args) {
  if (!message) {
    return null;
  }
  try {
    return message.replace(/{(\d+)}/g, function (
      match,
      number,
    ) {
      return typeof args[number] != 'undefined'
        ? args[number]
        : match;
    });
  } catch (error) {
    console.error(message, error);
    throw error;
  }
}

export function getLanguages() {
  return Object.keys(languages).map((language) => {
    return languages[language];
  });
}

export function getLanguageCode() {
  return currentLanguageCode;
}

export function setLanguageCode(arg) {
  if (!languages[arg]) {
    throw new Error(`Invalid language ${arg}.`);
  }
  moment.locale(arg);
  localStorage.setItem('languagePTTSafetyGroup', arg);

}

export function i18nExists(key) {
  if (!getLanguage()) {
    return false;
  }

  const message = _get(getLanguage().dictionary, key);
  return Boolean(message);
}

export function i18n(key, ...args) {
  if (!getLanguage()) {
    return key;
  }

  const message = _get(getLanguage().dictionary, key);

  if (!message) {
    return key;
  }

  return format(message, args);
}

export function i18nHtml(key, ...args) {
  return (
    <span
      dangerouslySetInnerHTML={{
        __html: i18n(key, ...args),
      }}
    />
  );
}
