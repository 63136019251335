//#region Function Login
import { FnBlock_UI } from "src/Components/BlockUI";
import { AxiosPost } from "src/CommonFunction/TS_Axios";
import { ResultAPI } from 'src/CommonFunction/TS_function';
import { Process_System } from "src/CommonFunction/TS_function";
import Dialog from '@mui/material/Dialog';
//#endregion Function Login
//const { BlockUI, UnBlockUI } = FnBlock_UI();
export const GotoPage = (sRedirect) => {
    window.location.href = sRedirect;
}
export const loginAuthen = (
    sUsername,
    sPassword,
) => {
    var oData = {

        sUsername: sUsername,
        sPassword: sPassword
    }
    AxiosPost(`AuthenLogin/Login`, oData, res => {
        ResultAPI(true, res, null, () => {
            if (res.Status === Process_System.process_Success) {
                if (res.token) {
                    localStorage.setItem(process.env.REACT_APP_JWT_KEY as string, res.token);
                    GotoPage(process.env.REACT_APP_API_URL + res.sRedirect);
                    
                    FnBlock_UI();
                }
                else if (res.sRedirect) {
                    GotoPage(process.env.REACT_APP_API_URL + res.sRedirect);
                  
                }

            }
            else {
                (document.getElementById("lbMessage") as HTMLInputElement).innerText = res.Message;
            }
        })
    }, null, null, null)



}

