import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { FormProvider, useForm } from "react-hook-form";
import { Button, CardContent, Grid, Input, Stack } from "@mui/material";
import Typography from "@mui/material/Typography";

import yupFormSchemas from "src/Components/FormItem/yup/yupFormSchemas";
import { InputForm } from "src/Components/FormItem/Input";
import { Axios } from "src/CommonFunction/TS_Axios";
import { FnBlock_UI, ResultAPI } from "src/CommonFunction/TS_function";
import { useNavigate } from "react-router";
import { BreadcrumbsUI } from "../BreadcrumbsUI";

const ResetPassword = () => {

    const { BlockUI, UnBlockUI } = FnBlock_UI();
    const navigate = useNavigate();
    //#region   step2 Yup validate
    const objSchema = {
        sUsername: yupFormSchemas.string("Username", { required: true })
    }

    const schema = yup.object().shape(objSchema);

    const form = useForm({
        resolver: yupResolver(schema),
        shouldUnregister: false,
        shouldFocusError: true,
        mode: "all",
    });

  
    const onBack = () => {
        navigate(
          process.env.REACT_APP_API_URL + `ForgetPasswordfail`
        );
      };
    const GotoPage = () => {
        navigate(
          process.env.REACT_APP_API_URL + `PasswordSuccess`
        );
      };
    const onSubmit = async () => {
        var oData = {
            sUsername: form.getValues("sUsername"),
        };
       
        await Axios(
            `ResetPassWord/SendEmailResetPassword`, oData, "POST", async (res) => {
                ResultAPI(true, res, null, () => {             
                    if (res.Status === 200) {
                        GotoPage();
                    }
                    else {                      
                        onBack();
                    }
                })
            }, null, BlockUI, UnBlockUI)
    }
    return (
        <Grid container justifyContent="center">
              <Grid item xs={12}>
                <BreadcrumbsUI
                    items={[
                        {
                            Key: "1",
                            menuName: "ลืมรหัสผ่าน/Forgot Password",
                            IsOnClick: false,
                        },
                    ]}
                />
            </Grid>
            <Grid item xs={12} >
                <Typography align="center" color="#03a9f4" variant="h5">โปรดระบุชื่อผู้ใช้งานของท่านเพื่อรับลิ้งค์สำหรับเปลี่ยนรหัสผ่าน</Typography>
            </Grid>   
            <Grid item xs={6}>
                <Grid container justifyContent="center">
                <Grid item xs={3}></Grid>  
                    <Grid item xs={9} sx={{marginTop: "2%" }}>
                        <Typography fontSize={18}>ชื่อผู้ใช้งาน/UserName*</Typography>
                    </Grid>                  
                    <FormProvider {...form}>                     
                        <Grid item xs={7} >
                            <InputForm
                                fullWidth
                                name={"sUsername"}
                                required={true}
                                maxLength={100}
                                label="Username"
                            />
                        </Grid>
                    
                        <Grid item xs={12}>
                        <Typography align="center" color="#FF0000" fontSize={18}>ไม่พบข้อมูล กรุณาตรวจสอบ "ชื่อผู้ใช้งาน/Username"</Typography>
                    </Grid>
                                      
                        <Grid item xs={2} sx={{ margin: "1%" }}  >
                            <Button fullWidth 
                            onClick={() => { form.handleSubmit(onSubmit)() }}
                                sx={{
                                    backgroundColor: "#196F3D", fontSize: 15, color: "White", ":hover": { bgcolor: "#196F3D" }
                                }}>ส่ง</Button>
                        </Grid>
                        <Grid item xs={2} sx={{margin: "1%" }} >
                            <Button fullWidth variant="outlined"
                                sx={{
                                    backgroundColor: "#FFFFFF", fontSize: 15,color: "Black", ":hover": { bgcolor: "#FFFFFF" }
                                }}>ยกเลิก</Button>
                        </Grid>
                    </FormProvider>
                </Grid>
            </Grid >
        </Grid>
    );
}
export default ResetPassword


