import React, { useState, useEffect } from "react";
import { makeStyles } from "@mui/styles";
import { Theme } from "@mui/material/styles";
import Zoom from "@mui/material/Zoom";
import useScrollTrigger from "@mui/material/useScrollTrigger";
import PropTypes from "prop-types";

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    // justifyContent:"center",
    // width:"100vw",
    // display:"flex",
    // bottom: theme.spacing(11),
    // position: "fixed",

    position: "fixed",
    bottom: theme.spacing(8),
    right: "15px",
    // paddingRight:"40px",
    width:"46.7px",
    height:"87.5px",
    // paddingBottom:"40px"
  },
}));

export const ScrollToTop = (props) => {
  const { children } = props;
  const classes = useStyles();
  const trigger = useScrollTrigger({
    disableHysteresis: true,
    threshold: 700,
  });

  const handleClick = (event) => {
    // const anchor = (event.target.ownerDocument || document).querySelector(
    //   "#back-to-top-anchor"
    // );
    // if (anchor) {
    //   anchor.scrollIntoView({behavior: "smooth", block: "center" });
    // }

    window[`scrollTo`]({ top: 0, behavior: `smooth` })
  };

  return (
    <Zoom in={trigger}>
      <div onClick={handleClick} role="presentation" className={classes.root}>
        {children}
      </div>
    </Zoom>
  );
};

ScrollToTop.propTypes = {
  children: PropTypes.element.isRequired,
};
