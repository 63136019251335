import { useEffect, useRef, useState } from "react";
import { DataGridMui, PaginationInterface, initRows } from "src/Components/Table/DataGridMui";
import { GridColumns } from "@mui/x-data-grid-pro";
import { Box } from "@mui/material";

import "src/Components/System/ClassColor.css"
export function FAQ_Table(props: FAQ) {  
    const dataColumn: GridColumns = [
        {
            field: "nRow",
            headerName: "ที่",
            headerAlign: "center",
            editable: false,
            width: 10,
            resizable: true,
            filterable: false,
            align: "center",
            sortable: false,
           
        },
        {
            field: "sFileName",
            headerName: "ชื่อไฟล์",
            headerAlign: "center",
            align: "left",
            resizable: true,
            sortable: false,
            filterable: false,
            flex: 2,
            minWidth: 50,
            renderCell: (item) => {              
                return (
                  <a href={item.row.sFileLink} download target="_blank">
                    {item.row.sFileName}
                  </a>
                );
              },          
        },

    ];
    const [dataRow, setDataRow] = useState<any>({
        ...initRows,
    });
    const onLoadDataTable = (p: PaginationInterface) => {
        let dataFilter = props.lstFile;
        setDataRow({
            ...p,
            arrRows: dataFilter,
            nDataLength: 10,
            nPageIndex: 1,
        });

    }
    useEffect(() => {
        onLoadDataTable(initRows);
    }, [])

    return (
        <>
            <Box>
                <DataGridMui
                    Columns={dataColumn}
                    OnLoadData={(p: PaginationInterface) => {
                        onLoadDataTable(p)
                    }}
                    Rows={dataRow}
                    isNotShowTotal={true}
                    isNotShowPagination={true}
                    HiddenToolHead={true}
                    sxCustomTable={{
                        ".MuiDataGrid-virtualScrollerContent": {
                            height: "50px !important",
                        },
                        ".MuiDataGrid-cell": {
                            minHeight: "50px !important",
                            maxHeight: "50px !important"
                        },
                        ".MuiDataGrid-row": {
                            minHeight: "50px !important",
                            maxHeight: "50px !important"
                        },
                        ".MuiDataGrid-footerContainer": {
                            display: "none !important",

                        },

                    }}
                    sxCustomHeader={{
                        color: "#fff !important"

                    }}
                    backgroundHeaderCustom={"#122768"}
                />
            </Box>
            <Box sx={{ paddingTop: 3 }}></Box>
        </>
    );

}
interface FAQ {
    lstFile: any;

}