import { Label } from "@mui/icons-material";
import { Box, Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Fade, Grid, Modal, Typography } from "@mui/material";
import { useEffect, useState } from "react";
import { GridColumns, GridRowParams } from '@mui/x-data-grid-pro';
import { FnBlock_UI } from 'src/Components/BlockUI';
import { DataGridMui as Table, initRows, PaginationInterface } from "src/Components/Table/DataGridMui";
import {  BtnCancelForm } from 'src/Components/Button';

export default function DetailScore(prop) {
    const { open, handleClose, lstDataScore, Score} = prop;
    const { BlockUI, UnBlockUI } = FnBlock_UI();
    const [loadingTable, setLoadingTable] = useState(false);
    const [lstData, setLstData] = useState([]);
    const [dataRow, setDataRow] = useState<PaginationInterface>({
        ...initRows,
        sSortExpression: "sPersonName",
        sSortDirection: "asc"
    });

    useEffect(() => {
        if (open){}
        console.log('Score', Score)
        setDataRow(
            {
                ...initRows,
                arrRows: lstDataScore,
                nDataLength: lstDataScore.length,
            }
        );
    }, [open]);

    //const loadDataTable = (p: PaginationInterface = dataRow) => {
        //setLoadingTable(true);
        //debugger
        //let res = [...lstDataScore]
        // res.forEach((e,index) => {
        //     e.nRow = index + 1;
        //     e.firstScore = e.nPreScore_GOT + '/' + e.nPreScore_MAX;
        //     e.afterScore = e.nPostScore_GOT + '/' + e.nPostScore_MAX;
        //   })
  
        //   setDataRow({
        //     ...p,
        //     arrRows: lstDataScore,
        //     nDataLength: res.nDataLength,
        //     nPageIndex: res.nPageIndex,
        //   });
        //BlockUI();
      
    //}

    const dataColumn: GridColumns = [
        {
            field: "sID",
            headerName: "ที่",
            headerAlign: "center",
            editable: false,
            resizable: false,
            align: "right",
            sortable: false,
            width: 10,
        },
        {
            field: "sExamDate",
            headerName: "วันที่บันทึกคะแนน",
            editable: false,
            headerAlign: "center",
            align: "center",
            resizable: false,
            sortable: false,
            width: 200,
        },
        {
            field: "sLessonName",
            headerName: "บทเรียน",
            headerAlign: "center",
            editable: false,
            resizable: false,
            sortable: false,
            align: "left",
            width: 750,
        },
        {
            field: "sTotal_Score",
            headerName: "คะแนนที่ได้",
            headerAlign: "center",
            editable: false,
            resizable: false,
            sortable: false,
            align: "center",
            width: 165,
        },
    ];

    return (<>
        <Dialog
            open={open}
            onClose={handleClose}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
            fullWidth={true}
            maxWidth={"lg"}
        >
            <DialogTitle id="alert-dialog-title">
                <div>{Score}</div>
                <hr />
            </DialogTitle>
            <DialogContent>
                <Grid container rowSpacing={2}>
                    <Grid item xs={12}>
                        <Grid container spacing={2} rowSpacing={2} alignContent="space-between" alignItems={"center"}>
                            <Grid item xs={12} style={{ marginBottom: "1rem" }}>
                                {/* <Grid container rowSpacing={1}>
                                    <Grid item xs={3} style={{ fontWeight: "bold" }}>
                                        หลักสูตร
                                    </Grid>
                                    <Grid item xs={3} style={{ fontWeight: "bold" }}>
                                        วันที่
                                    </Grid>
                                    <Grid item xs={3} style={{ fontWeight: "bold" }}>
                                        รอบ
                                    </Grid>
                                    <Grid item xs={3} style={{ fontWeight: "bold" }}>
                                        ผู้รับเหมา/บริษัท
                                    </Grid>
                                    {/* <Grid item xs={3}>
                                        5555
                                    </Grid>
                                    <Grid item xs={3}>
                                        {sDate}
                                    </Grid>
                                    <Grid item xs={3}>
                                        {sTime}
                                    </Grid>
                                    <Grid item xs={3}>
                                        {sCompany}
                                    </Grid>  
                                </Grid> */}
                            </Grid>
                            <div style={{ width: "100%", marginTop: 10 }}>
                                <Grid item xs={12}>
                                    <Table
                                        //isLoading={loadingTable}
                                        Columns={dataColumn}
                                        Rows={dataRow}
                                        //OnLoadData={(p) => loadDataTable(p)}
                                        OnLoadData={lstDataScore}
                                        isShowCheckBox={false}
                                        HiddenToolHead={true}
                                        isRowSelectable={(params: GridRowParams) => params.row.nPositionID > 0}
                                        isNotShowPagination
                                        backgroundHeaderCustom={"#0a286c"}
                                        sxCustomHeader={{ color: "#FFF" }}
                                    />
                                </Grid>
                            </div>
                        </Grid>
                    </Grid>

                </Grid>
            </DialogContent>
            <DialogActions>
                <BtnCancelForm txt={"ย้อนกลับ/Back"} onClick={handleClose} />
            </DialogActions>
        </Dialog>
    </>
    )
}