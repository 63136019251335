import { Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Slide } from '@mui/material'
import React from 'react'
import { AiOutlineCheckCircle, AiOutlineClose, AiOutlineCloseCircle } from 'react-icons/ai';
import { BiEdit } from 'react-icons/bi';
import { BsQuestionCircle } from 'react-icons/bs';
import { TransitionProps } from '@mui/material/transitions';
const Transition = React.forwardRef(function Transition(
    props: TransitionProps & {
        children: React.ReactElement;
    },
    ref: React.Ref<unknown>,
) {
    return <Slide direction="down" ref={ref} {...props} />;
});

export function DialogUI(props: DialogUI) {
    const {
        open,
        fullScreen = false,
        fullWidth = false,
        questionIcon = false,
        textHead,
        isShowConfirm = false,
        isShowCancle = false,
        testIcon = false,
        isSendAns = false,
        isSlideDown = false,
        isSetHeadColor = false,
        isShowClose = false,
        isShowDialogActions = false,
        titleColor = "#000",
        titleBgColor = "#fff",
        maxWidth = "md",
        hBGColor = "rgb(255,255,255)",
        hColor = "rgb(0,0,0)",
        height = "auto",
        style,
        className
    } = props;
    return (
        <Dialog
            fullScreen={fullScreen}
            fullWidth={fullWidth}
            open={open}
            maxWidth={maxWidth}
            onClose={props.handleClose}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
            scroll="paper"
            TransitionComponent={isSlideDown ? Transition : undefined}
            className={className}
            sx={{
                "& .MuiDialog-container": {
                    "& .MuiPaper-root": {
                        width: "100%",
                        height: height
                    },
                },
                "& .MuiDialogTitle-root": {
                    backgroundColor: isSetHeadColor ? titleBgColor : "",
                    color: isSetHeadColor ? titleColor : ""
                },
                zIndex:1000,

            }}
        >
            {textHead &&
                <DialogTitle id="alert-dialog-title" sx={{
                    display: "flex", alignItems: "center"
                    , borderBottom: "1px solid rgba(0,0,0,0.3)"
                    , bgcolor: hBGColor
                    , color: hColor
                }}
                >
                    <>
                        {questionIcon ?
                            <>
                                <BsQuestionCircle style={{ marginRight: "8px" }} />{textHead}
                            </>
                            :
                            <>
                                {testIcon ?
                                    <>
                                        <BiEdit style={{ marginRight: "8px" }} />{textHead}
                                    </>
                                    :
                                    <>
                                        {textHead}
                                    </>
                                }
                            </>
                        }
                    </>
                </DialogTitle>}
            <DialogContent sx={{
                display: "flex"
                , overflowX: "hidden"
                , flexDirection: "column"
                , alignItems: "center", m: "1em", paddingBottom: "0px", px: 0
            }} style={{...style}}>
                {/* <DialogContentText id="alert-dialog-description"> */}
                {props.children}
                {/* </DialogContentText> */}
            </DialogContent>
            {isShowDialogActions &&
                <DialogActions sx={{ borderTop: "1px solid rgba(0,0,0,0.3)" }}>
                    {isShowConfirm &&

                        <Button sx={{
                            bgcolor: "#366d21",
                            color: "rgb(255,255,255)",
                            width: "7em",
                            border: "1px solid #357b05 !important",
                            ":hover": {
                                bgcolor: "#188e32",
                                color: "rgb(255,255,255)",
                                border: "1px solid #357b05 !important",
                            }
                        }} onClick={props.handleClickConfirm}>
                            ยืนยัน
                        </Button>
                    }
                    {isSendAns &&
                        <Button sx={{
                            bgcolor: "rgb(69,177,70)",
                            color: "rgb(255,255,255)",
                            width: "7em",
                            ":hover": {
                                bgcolor: "rgb(69,177,70,0.8)",
                                color: "rgb(255,255,255)",
                            }
                        }}
                            onClick={props.handleClickConfirm}>
                            <AiOutlineCheckCircle style={{ marginRight: "5px" }} /> ส่งคำตอบ
                        </Button>
                    }
                    {isShowCancle &&
                        <Button sx={{
                            bgcolor: "#fff",
                            color: "#000",
                            width: "7em",
                            border: "1px solid #797979 !important",
                            ":hover": {
                                bgcolor: "#eaeaea",
                                color: "rgb(0,0,0)",
                                border: "1px solid #797979 !important",
                            }
                        }}
                            onClick={props.handleClickCancel}>
                            <AiOutlineCloseCircle style={{ marginRight: "5px" }} /> ยกเลิก
                        </Button>
                    }
                    {isShowClose &&
                        <Button sx={{
                            bgcolor: "rgba(255,0,0,1)",
                            color: "rgba(255,255,255,1)",
                            width: "7em",
                            border: "1px solid rgba(255,0,0,1) !important",
                            ":hover": {
                                bgcolor: "rgba(255,0,0,0.85)",
                                color: "rgba(255,255,255,1)",
                                border: "1px solid rgba(255,0,0,1) !important",
                            }
                        }}
                            onClick={props.handleClickCancel}>
                            <AiOutlineCloseCircle style={{ marginRight: "5px" }} /> ปิด
                        </Button>

                    }
                </DialogActions>
            }
        </Dialog >
    )
}

interface DialogUI {
    open: boolean;
    fullScreen?: boolean;
    fullWidth?: boolean;
    questionIcon?: boolean;
    children?: any;
    textHead?: string,
    isShowConfirm?: boolean;
    isShowCancle?: boolean;
    testIcon?: boolean;
    isSendAns?: boolean;
    maxWidth?: "xs" | "sm" | "md" | "lg" | "xl";
    hBGColor?: string;
    hColor?: string;
    isSlideDown?: boolean;
    isSetHeadColor?: boolean;
    isShowClose?: boolean;
    titleBgColor?: string;
    titleColor?: string;
    height?: string;
    isShowDialogActions?:boolean;
    handleClose?: (value: any) => void;
    handleClickConfirm?: (value: any) => void;
    handleClickCancel?: (value: any) => void;
    style?:any;
    className?:any;
}