import { Button, Grid, IconButton } from "@mui/material";
import Typography from "@mui/material/Typography";
import { FormProvider, useForm } from "react-hook-form";

import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import yupFormSchemas, { MsgValidate, RegexpPasswork } from "src/Components/FormItem/yup/yupFormSchemas";
import { InputForm } from "src/Components/FormItem/Input";
import { Axios } from "src/CommonFunction/TS_Axios";
import { useNavigate } from "react-router-dom";
import { useState, useEffect } from "react";
import { Visibility, VisibilityOff } from "@mui/icons-material";
import { AlertMsg, AlertTitle, SwAlert } from "src/Components/Alert/Sweetalert";
import { Process_System, lnkToHomePage } from "src/CommonFunction/TS_function";
import { FnBlock_UI } from 'src/Components/BlockUI';
import { i18n } from "src/i18n";
import { BreadcrumbsUI } from "src/Components/BreadcrumbsUI";
import logo from "src/Layout/img/oba-logo.png";
import bg from "src/Layout/img/bg-admin.jpg";
import Card from '@mui/material/Card';
import { CardActionArea, CardActions, CardMedia, CardContent, Box, Divider } from '@mui/material';
import "./SelectProfile.css";
import "src/Components/Password/ChangePassword.css";


const i18nField = 'entities.ChangePassword.fields';
const ChangePassword = () => {
    const navigate = useNavigate();
    const [Data, setData] = useState({})
    const [showPassword, setShowPassword] = useState(false);
    const [showNewPassword, setShowNewPassword] = useState(false);
    const { BlockUI, UnBlockUI } = FnBlock_UI();
    
    const objSchema = {
        sPassword: yupFormSchemas.string(i18n(`${i18nField}.sPassword`), {
            required: true,
            matches: RegexpPasswork(),
            labelmatches: MsgValidate.Passwork
        }),

        sNewPassword: yupFormSchemas.string(i18n(`${i18nField}.sConfirmNewPassword`), {
            required: true,
            matches: RegexpPasswork(),
            labelmatches: MsgValidate.Passwork
        }),

        sConfirmNewPassword: yupFormSchemas.string(i18n(`${i18nField}.sConfirmNewPassword`), {
            required: true,
            matches: RegexpPasswork(),
            labelmatches: MsgValidate.Passwork
        }).oneOf([yup.ref("sNewPassword")], "Invalid Confirm Password")
    }
    const schema = yup.object().shape(objSchema);
    const form = useForm({
        resolver: yupResolver(schema),
        shouldUnregister: false,
        shouldFocusError: true,
        mode: "all",
    });

    const handleConfirmPassword = () => {
        setShowNewPassword(showNewPassword);
    };

    const handleChangePassword = () => {
        setShowNewPassword(showNewPassword);
    };

    const handleChangeNewPassword = () => {
        setShowNewPassword(showNewPassword);
    };

    const handleClickShowConfirmPassword = () => {
        setShowNewPassword(!showNewPassword);
    };

    const handleClickShowPassword = () => {
        setShowNewPassword(!showNewPassword);
    };

    const handleClickShowNewPassword = () => {
        setShowNewPassword(!showNewPassword);
    };

    const handleMouseDownPassword = (event: React.MouseEvent<HTMLButtonElement>) => {
        event.preventDefault();
    };
    const onPageLoad = async () => {

        await Axios("Master/onPageLoad", {}, "GET", async (result) => {
            if (result.IsExpired) {
                SwAlert.Warning(undefined, i18n("common.SessionTimeOut"), lnkToHomePage);
            }
        })
    }

    const onSubmit = async (e) => {

        const NewPassword = form.getValues("sNewPassword");
        const NewPasswordConfirm = form.getValues("sConfirmNewPassword");

        if (NewPassword != NewPasswordConfirm) {

            SwAlert.Warning(AlertTitle.Warning, "รหัสผ่านใหม่ไม่ตรง", () => { });

        } else {
            SwAlert.Confirm(AlertTitle.Confirm, "ต้องการส่งข้อมูลหรือไม่?", () => onSave(), () => { })

        }
    }

    const onSave = async () => {
        let objSave = {
            sPassword: form.getValues("sPassword"),
            sNewPassword: form.getValues("sNewPassword"),
        }
        await Axios(`ResetPassWord/GetData_ChangePassword`, objSave, "POST", async (res) => {
            if (res.Status === Process_System.process_Success) {
                navigate(process.env.REACT_APP_API_URL + "home");
            } else {
                SwAlert.Warning(AlertTitle.Warning, res.Message, () => { });
            }

        },
            null,
            BlockUI,
            UnBlockUI,
            null
        );
    }
    useEffect(() => {
        onPageLoad();
    }, [])
    const BoxContent = () => {

        return (
            <Grid item xs={24} md={12} lg={12}>
                <table>
                    <tr className="valign-top">
                        <td>
                            <div className="change-password-title">การกรอกรหัสผ่าน</div>
                        </td>
                    </tr>
                    <tr className="valign-top">
                        <td>
                            <div className="change-password-desc">1. ต้องมีความยาวอย่างน้อย 8-15 ตัวอักษร</div>
                        </td>
                    </tr>
                    <tr>
                        <td className="change-password-desc">2. ต้องประกอบด้วยภาษาอังกฤษตัวพิมพ์ใหญ่อย่างน้อย 1 ตัวอักษร และตัวพิมพ์เล็กอย่างน้อย 1 ตัวอักษร</td>
                    </tr>

                    <tr>
                        <td className="change-password-desc">3. ต้องประกอบด้วยตัวเลขอย่างน้อย 1 ตัวอักษร และอักขระพิเศษอย่างน้อย 1 ตัวอักษร</td>
                    </tr>

                    <tr>
                        <td>&nbsp;</td>
                    </tr>

                    <tr>
                        <td className="change-password-title">Password Policy</td>
                    </tr>

                    <tr>
                        <td className="change-password-desc">1. Must be at least 8-15 characters.</td>
                    </tr>

                    <tr>
                        <td className="change-password-desc">2. Must contain at least 1 uppercase letter and at least 1 lowercase letter.</td>
                    </tr>

                    <tr>
                        <td className="change-password-desc">3. Must contain at least 1 character and at least 1 number.</td>
                    </tr>

                </table>
            </Grid>
        );
    };
    const FormPassword = () => {
        return (
            <Grid item xs={24} md={12} lg={12}>


                <Card className="change-password-card">

                    <CardActions>
                        <Grid container>

                            {/* <Grid item xs={2} ></Grid> */}
                            <Grid item xs={12} sm={12} justifyContent="center" alignItems="center" sx={{ marginTop: "2%", paddingLeft: "5%" }}>
                                {/* old password */}
                                <Grid item sx={{ marginTop: "3%" }}>
                                    <InputForm
                                        name={"sPassword"}
                                        required={true}
                                        maxLength={100}
                                        type={showNewPassword ? 'text' : 'password'}
                                        onChange={handleChangePassword}
                                        label="รหัสผ่าน/Passwordssss"
                                        styles={{ width: "85%" }}
                                    />
                                    <IconButton
                                        aria-label="toggle password visibility"
                                        onClick={handleClickShowPassword}
                                        onMouseDown={handleMouseDownPassword}
                                        edge="end"
                                        sx={{ marginTop: "2%" }}
                                    >
                                        {showNewPassword ? <VisibilityOff /> : <Visibility />}
                                    </IconButton>
                                </Grid>
                                {/* end old password */}

                                {/* new password */}
                                <Grid item sx={{ marginTop: "5%" }}>
                                    <InputForm
                                        name={"sNewPassword"}
                                        required={true}
                                        maxLength={100}
                                        type={showNewPassword ? 'text' : 'password'}
                                        onChange={handleChangeNewPassword}
                                        label="รหัสผ่านใหม่/New Password"
                                        styles={{ width: "85%" }}
                                    />
                                    <IconButton
                                        aria-label="toggle password visibility"
                                        onClick={handleClickShowNewPassword}
                                        onMouseDown={handleMouseDownPassword}
                                        edge="end"
                                        sx={{ marginTop: "2%" }}
                                    >
                                        {showNewPassword ? <VisibilityOff /> : <Visibility />}
                                    </IconButton>
                                </Grid>
                                {/* end new password */}

                                {/* confirm password */}
                                <Grid item sx={{ marginTop: "5%" }}>
                                    <InputForm
                                        required={true}
                                        name={"sConfirmNewPassword"}
                                        maxLength={100}
                                        type={showNewPassword ? 'text' : 'password'}
                                        onChange={handleConfirmPassword}
                                        label="ยืนยันรหัสผ่านใหม่/New Password Confirmation"
                                        styles={{ width: "85%" }}
                                    />
                                    <IconButton
                                        aria-label="toggle password visibility"
                                        onClick={handleClickShowConfirmPassword}
                                        onMouseDown={handleMouseDownPassword}
                                        edge="end"
                                        sx={{ marginTop: "2%" }}
                                    >
                                        {showNewPassword ? <VisibilityOff /> : <Visibility />}
                                    </IconButton>
                                </Grid>
                                {/* end confirm password */}
                            </Grid>
                            {/* <Grid item xs={2}></Grid> */}
                            {/* <Grid item xs={2}></Grid> */}

                            {/* send button */}
                            {/* <Grid item md={12}> */}
                            <Grid item sm={12} md={12} className="change-password-align">
                                <Grid item xs={8} md={4} justifyContent={'center'} alignItems={'center'}>
                                    <Button
                                        onClick={() => { form.handleSubmit(onSubmit)() }}
                                        sx={{
                                            borderRadius: "16px",
                                            backgroundColor: "#196F3D", fontSize: 15, color: "White", ":hover": { bgcolor: "#458522" },
                                            width: "100%"
                                        }}
                                    >
                                        ส่ง/Send
                                    </Button>
                                </Grid>
                            </Grid>
                            {/* </Grid> */}
                            {/* end send button */}


                        </Grid>

                    </CardActions>

                </Card>

            </Grid>
        );
    }
    // return (
    //     <FormProvider {...form}>
    //         {/* <form onSubmit={form.handleSubmit(onSubmit)}> */}

    //         <Grid container direction="row" justifyContent="center" alignItems="start">

    //             <Grid item xs={12}>
    //                 <BreadcrumbsUI
    //                     isShowHome={false}
    //                     items={[
    //                         {
    //                             Key: "1",
    //                             menuName: "เปลี่ยนรหัสผ่าน/Change Password",
    //                             IsOnClick: false,
    //                         },
    //                     ]}
    //                 />
    //             </Grid>



    //             {/* text in the left  */}
    //             <Grid item xs={12} style={{ marginTop: "2%" }} textAlign={"center"} justifyContent={'center'} alignItems={'center'}>
    //                 <img src={logo} style={{ width: "10%", height: "auto", margin: "auto", textAlign: "center", justifyContent: "center" }} />
    //             </Grid>
    //             <Grid item xs={6} sx={{ marginTop: "1%" }}>
    //                 <BoxContent />
    //                 {/* <Grid sx={{ mx: "10%" }}>
    //                     <Typography fontSize={18} fontWeight={600} style={{ color: "#005f8b", marginTop: "3%" }}>การกรอกรหัสผ่าน</Typography>
    //                     <Typography fontSize={16}>1.ต้องมีความยาวอย่างน้อย 8-15 ตัวอักษร</Typography>
    //                     <Typography fontSize={16}>2.ต้องประกอบด้วยภาษาอังกฤษตัวพิมพ์ใหญ่อย่างน้อย 1 ตัวอักษร
    //                         และตัวพิมพ์เล็กอย่างน้อย 1 ตัวอักษร</Typography>
    //                     <Typography fontSize={16}>3.ต้องประกอบด้วยตัวเลขอย่างน้อย 1 ตัวอักษร และอักขระพิเศษอย่างน้อย 1 ตัวอักษร</Typography>

    //                     <Typography fontSize={18} fontWeight={600} style={{ color: "#005f8b", marginTop: "3%" }}>Password Policy</Typography>
    //                     <Typography fontSize={16}>1.Must be at least 8-15 characters.</Typography>
    //                     <Typography fontSize={16}>2.Must contain at least 1 uppercase letter and at least 1 lowercase letter.</Typography>
    //                     <Typography fontSize={16}>3.Must contain at least 1 character and at least 1 number.</Typography>
    //                 </Grid> */}
    //             </Grid>
    //             {/* end text in the left */}


    //             {/* border: 1px solid;
    //             border-radius: 2em;
    //             padding-bottom: 1.5em;
    //             /* background-color: #cddbe2; */
    //             /* background-color: rgb(229, 235, 239); */
    //             /* background-image: linear-gradient(to left top, rgb(229, 235, 239), rgb(30 128 190 / 81%)); */}
    //             {/* <Grid item xs={6} sx={{ border: 1, marginTop: "1%" }}> */}

    //             <FormPassword />

    //             {/* <Grid item xs={12} sm={12} md={6} sx={{
    //                 marginTop: "1%"
    //                 , textAlign: "center"
    //                 // , borderRadius: "2em"
    //                 // , paddingBottom: "1.5em"
    //                 // , backgroundColor: "rgb(229, 235, 239)"
    //                 // , backgroundImage: "linear-gradient(to left top, rgb(229, 235, 239), rgb(30 128 190 / 81%))"

    //             }}>

    //                 <Card sx={{ justifyContent: "center", backgroundImage: "linear-gradient(to left top, rgb(229, 235, 239), rgb(30 128 190 / 81%))" }}>

    //                     <CardActions>
    //                         <Grid container>


    //                             <Grid item xs={12} sm={12} justifyContent="center" alignItems="center" sx={{ marginTop: "2%" }}>

    //                                 <Grid item sx={{ marginTop: "3%" }}>
    //                                     <InputForm
    //                                         name={"sPassword"}
    //                                         required={true}
    //                                         maxLength={100}
    //                                         type={showNewPassword ? 'text' : 'password'}
    //                                         onChange={handleChangePassword}
    //                                         label="รหัสผ่าน/Password"
    //                                         styles={{ width: "85%" }}
    //                                     />
    //                                     <IconButton
    //                                         aria-label="toggle password visibility"
    //                                         onClick={handleClickShowPassword}
    //                                         onMouseDown={handleMouseDownPassword}
    //                                         edge="end"
    //                                         sx={{ marginTop: "2%" }}
    //                                     >
    //                                         {showNewPassword ? <VisibilityOff /> : <Visibility />}
    //                                     </IconButton>
    //                                 </Grid>

    //                                 <Grid item sx={{ marginTop: "5%" }}>
    //                                     <InputForm
    //                                         name={"sNewPassword"}
    //                                         required={true}
    //                                         maxLength={100}
    //                                         type={showNewPassword ? 'text' : 'password'}
    //                                         onChange={handleChangeNewPassword}
    //                                         label="รหัสผ่านใหม่/New Password"
    //                                         styles={{ width: "85%" }}
    //                                     />
    //                                     <IconButton
    //                                         aria-label="toggle password visibility"
    //                                         onClick={handleClickShowNewPassword}
    //                                         onMouseDown={handleMouseDownPassword}
    //                                         edge="end"
    //                                         sx={{ marginTop: "2%" }}
    //                                     >
    //                                         {showNewPassword ? <VisibilityOff /> : <Visibility />}
    //                                     </IconButton>
    //                                 </Grid>

    //                                 <Grid item sx={{ marginTop: "5%" }}>
    //                                     <InputForm
    //                                         required={true}
    //                                         name={"sConfirmNewPassword"}
    //                                         maxLength={100}
    //                                         type={showNewPassword ? 'text' : 'password'}
    //                                         onChange={handleConfirmPassword}
    //                                         label="ยืนยันรหัสผ่านใหม่/New Password Confirmation"
    //                                         styles={{ width: "85%" }}
    //                                     />
    //                                     <IconButton
    //                                         aria-label="toggle password visibility"
    //                                         onClick={handleClickShowConfirmPassword}
    //                                         onMouseDown={handleMouseDownPassword}
    //                                         edge="end"
    //                                         sx={{ marginTop: "2%" }}
    //                                     >
    //                                         {showNewPassword ? <VisibilityOff /> : <Visibility />}
    //                                     </IconButton>
    //                                 </Grid>

    //                             </Grid>

    //                             <Grid item sm={12} md={12} className="change-password-align">
    //                                 <Grid item xs={8} md={4} justifyContent={'center'} alignItems={'center'}>
    //                                     <Button
    //                                         onClick={() => { form.handleSubmit(onSubmit)() }}
    //                                         sx={{
    //                                             borderRadius: "16px",
    //                                             backgroundColor: "#196F3D", fontSize: 15, color: "White", ":hover": { bgcolor: "#458522" },
    //                                             width: "100%"
    //                                         }}
    //                                     >
    //                                         ส่ง/Send
    //                                     </Button>
    //                                 </Grid>
    //                             </Grid>

    //                         </Grid>

    //                     </CardActions>

    //                 </Card>
    //             </Grid > */}
    //             <Grid item xs={12} sx={{ marginTop: "5%" }} ></Grid>
    //         </Grid>

    //         {/* </form> */}

    //     </FormProvider>

    // );

    return (
        <>
            <Grid
                container
                columnSpacing={0}
                rowSpacing={0}
                sx={{ "&.MuiGrid-root": { marginTop: 0, display: "block !important" } }}
            >


                <Grid item xs={12}>
                    <BreadcrumbsUI
                        isShowHome={false}
                        items={[
                            {
                                Key: "1",
                                menuName: "เปลี่ยนรหัสผ่าน/Change Password",
                                IsOnClick: false,
                            },
                        ]}
                    />
                </Grid>
                <Grid
                    container
                    columnSpacing={0}
                    rowSpacing={0}
                    direction="column"
                    alignItems="center"
                    justifyContent="center"
                    position="relative"
                    style={{ minHeight: '100vh' }}
                    sx={{ backgroundColor: '#fff' }}
                    overflow="hidden"
                    boxShadow={"inset 0 0px 11px 0 rgb(0 0 0 / 20%);"}
                >

                    <Grid className="circle" sx={{ display: { xs: 'none', md: 'none', lg: 'block' } }} ></Grid>

                    <Grid container lg={12} md={12} xs={12} justifyContent="center" width='888px' zIndex='1'
                        sx={{ boxShadow: '0 0 20px 1px rgba(0, 0, 0, 0.1)', position: 'relative' }} >

                        <Grid lg={12} className="polkadot-bg-in" overflow='hidden' sx={{ display: { xs: 'block', md: 'block', lg: 'block' } }} ></Grid>

                        <Grid lg={6} xs={12} position="relative" borderRadius={3}
                            sx={{ py: 4, px: 3, backgroundColor: '#fff', opacity: '0.9', overflow: 'hidden' }}>

                            <Grid container>
                                {/* <Box className="circle-small-1" sx={{ display: { xs: 'none', md: 'none', lg: 'block' } }} component="img"></Box> */}
                                <Box className="circle-small-2" sx={{ display: { xs: 'none', md: 'none', lg: 'block' } }} component="img"></Box>
                                <Grid item lg={12} md={12} xs={12}>
                                    <Grid item xs={12} sx={{ mt: 3 }}>
                                        <Typography fontSize={18} fontWeight={600} sx={{ color: '#005f8b' }}>การกรอกรหัสผ่าน</Typography>
                                    </Grid>
                                    <Grid item xs={12} sx={{ mt: 2 }}>
                                        <Typography fontSize={16}>1.ต้องมีความยาวอย่างน้อย 8-15 ตัวอักษร</Typography>
                                    </Grid>
                                    <Grid item xs={12} sx={{ mt: 2 }}>
                                        <Typography fontSize={16}>2.ต้องประกอบด้วยภาษาอังกฤษตัวพิมพ์ใหญ่อย่างน้อย 1 ตัวอักษรและตัวพิมพ์เล็กอย่างน้อย 1 ตัวอักษร</Typography>
                                    </Grid>
                                    <Grid item xs={12} sx={{ mt: 2 }}>
                                        <Typography fontSize={16}>3.ต้องประกอบด้วยตัวเลขอย่างน้อย 1 ตัวอักษร และอักขระพิเศษอย่างน้อย 1 ตัวอักษร</Typography>
                                    </Grid>
                                    <Divider variant="fullWidth" sx={{ mt: 2, borderBottomWidth: 4 }} />
                                </Grid>

                                <Grid item lg={12} md={12} xs={12}>
                                    <Grid item xs={12} sx={{ mt: 3 }}>
                                        <Typography fontSize={18} fontWeight={600} sx={{ color: '#005f8b' }}>Password Policy</Typography>
                                    </Grid>
                                    <Grid item xs={12} sx={{ mt: 2 }}>
                                        <Typography fontSize={16}>1.Must be at least 8-15 characters.</Typography>
                                    </Grid>
                                    <Grid item xs={12} sx={{ mt: 2 }}>
                                        <Typography fontSize={16}>2.Must contain at least 1 uppercase letter and at least 1 lowercase letter.</Typography>
                                    </Grid>
                                    <Grid item xs={12} sx={{ mt: 2 }}>
                                        <Typography fontSize={16}>3.Must contain at least 1 character and at least 1 number.</Typography>
                                    </Grid>
                                    <Divider variant="fullWidth" sx={{ mt: 2, borderBottomWidth: 4 }} />
                                </Grid>
                            </Grid>

                        </Grid>

                        <Grid lg={6} xs={12} position="relative" borderRadius={3}
                            sx={{ py: 4, px: 3, backgroundColor: '#fff', opacity: '0.9' }}>
                            <FormProvider {...form}>
                                <form>
                                    <Grid container>
                                        <Grid item lg={12} md={12} xs={12} >
                                            <Grid item sx={{ mt: 3 }}>
                                                <InputForm
                                                    name="sPassword"
                                                    label={i18n(`${i18nField}.sPassword`)}
                                                    onChange={handleChangePassword}
                                                    required
                                                    maxLength={100}
                                                    isPassword
                                                    fullWidth
                                                    mx={0}
                                                    showClear={false}
                                                />
                                            </Grid>
                                            <Grid item sx={{ mt: 3 }}>
                                                <InputForm
                                                    name="sNewPassword"
                                                    label={i18n(`${i18nField}.sNewPassword`)}
                                                    onChange={handleChangeNewPassword}
                                                    required
                                                    maxLength={100}
                                                    isPassword
                                                    fullWidth
                                                    mx={0}
                                                    showClear={false}
                                                />
                                            </Grid>
                                            <Grid item sx={{ mt: 3 }}>
                                                <InputForm
                                                    name="sConfirmNewPassword"
                                                    label={i18n(`${i18nField}.sConfirmNewPassword`)}
                                                    onChange={handleConfirmPassword}
                                                    required
                                                    maxLength={100}
                                                    isPassword
                                                    fullWidth
                                                    mx={0}
                                                    showClear={false}
                                                />
                                            </Grid>
                                            <Grid item sx={{ mt: 3 }} display='flex' justifyContent="center">
                                                <Button
                                                    sx={{
                                                        borderRadius: "16px",
                                                        backgroundColor: "#196F3D", fontSize: 15, color: "White", ":hover": { bgcolor: "#458522" },
                                                        width: "100%"
                                                    }}
                                                    onClick={() => { form.handleSubmit(onSubmit)() }}
                                                >ยืนยัน/Confirm
                                                </Button>
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                </form>
                            </FormProvider>
                        </Grid>

                    </Grid>

                    {/* <Box className="polka-dot" sx={{ display: { xs: 'none', md: 'none', lg: 'block' } }} ></Box> */}

                    <Box className="polkadot-bg" sx={{ display: { xs: 'none', md: 'none', lg: 'block' } }} ></Box>

                </Grid >
            </Grid >
        </>
    );

    // return (
    //     <Grid
    //         container
    //         columnSpacing={0}
    //         rowSpacing={0}
    //         sx={{ "&.MuiGrid-root": { marginTop: 0, display: "block !important" } }}
    //     >


    //         <Grid item xs={12}>
    //             <BreadcrumbsUI
    //                 isShowHome={false}
    //                 items={[
    //                     {
    //                         Key: "1",
    //                         menuName: "เปลี่ยนรหัสผ่าน/Change Password",
    //                         IsOnClick: false,
    //                     },
    //                 ]}
    //             />
    //         </Grid>

    //         <Grid item xs={12} md={12} lg={12} style={{ margin: "30px" }}>
    //             <Grid item xs={24} style={{ marginTop: "2%" }} textAlign={"center"} justifyContent={'center'} alignItems={'center'}>
    //                 <img src={logo} style={{ width: "10%", height: "auto", margin: "auto", textAlign: "center", justifyContent: "center" }} />
    //             </Grid>
    //         </Grid>

    //         <Grid item xs={12} md={12} lg={12} style={{ margin: "30px" }}>
    //             <Grid container>
    //                 <Grid item xs={12} md={6} lg={6}>
    //                     <BoxContent />
    //                 </Grid>

    //                 <Grid item xs={12} md={6} lg={6} style={{ paddingTop: "3%" }}>
    //                     <FormProvider {...form}>
    //                         <form>
    //                             <Card sx={{ backgroundImage: "linear-gradient(to left top, rgb(229, 235, 239), #275d8b)" }}>
    //                                 <Grid container>
    //                                     <Grid item xs={12} sm={12} sx={{ mt: "2%", mx: "5%" }}>
    //                                         <Grid item xs={12} sx={{ mt: 3 }}>
    //                                             <InputForm
    //                                                 name="sPassword"
    //                                                 label={i18n(`${i18nField}.sPassword`)}
    //                                                 onChange={handleChangePassword}
    //                                                 required
    //                                                 maxLength={100}
    //                                                 isPassword
    //                                                 fullWidth
    //                                                 mx={0}
    //                                                 showClear
    //                                             />
    //                                         </Grid>

    //                                         <Grid item xs={12} sx={{ mt: 2 }}>
    //                                             <InputForm
    //                                                 name="sNewPassword"
    //                                                 label={i18n(`${i18nField}.sNewPassword`)}
    //                                                 onChange={handleChangeNewPassword}
    //                                                 required
    //                                                 maxLength={100}
    //                                                 isPassword
    //                                                 fullWidth
    //                                                 mx={0}
    //                                                 showClear
    //                                             />
    //                                             {/* <InputForm
    //                                                     name={"sNewPassword"}
    //                                                     required={true}
    //                                                     maxLength={100}
    //                                                     type={showNewPassword ? 'text' : 'password'}
    //                                                     onChange={handleChangeNewPassword}
    //                                                     label={i18n(`${i18nField}.sNewPassword`)}
    //                                                     styles={{ width: "85%" }}
    //                                                 />
    //                                                 <IconButton
    //                                                     aria-label="toggle password visibility"
    //                                                     onClick={handleClickShowNewPassword}
    //                                                     onMouseDown={handleMouseDownPassword}
    //                                                     edge="end"
    //                                                     sx={{ marginTop: "2%" }}
    //                                                 >
    //                                                     {showNewPassword ? <VisibilityOff /> : <Visibility />}
    //                                                 </IconButton> */}
    //                                         </Grid>

    //                                         <Grid item xs={12} sx={{ mt: 3 }}>
    //                                             <InputForm
    //                                                 name="sConfirmNewPassword"
    //                                                 label={i18n(`${i18nField}.sConfirmNewPassword`)}
    //                                                 onChange={handleConfirmPassword}
    //                                                 required
    //                                                 maxLength={100}
    //                                                 isPassword
    //                                                 fullWidth
    //                                                 mx={0}
    //                                                 showClear
    //                                             />
    //                                             {/* <InputForm
    //                                                     required={true}
    //                                                     name={"sConfirmNewPassword"}
    //                                                     maxLength={100}
    //                                                     type={showNewPassword ? 'text' : 'password'}
    //                                                     onChange={handleConfirmPassword}
    //                                                     label={i18n(`${i18nField}.sConfirmNewPassword`)}
    //                                                     styles={{ width: "85%" }}
    //                                                 />
    //                                                 <IconButton
    //                                                     aria-label="toggle password visibility"
    //                                                     onClick={handleClickShowConfirmPassword}
    //                                                     onMouseDown={handleMouseDownPassword}
    //                                                     edge="end"
    //                                                     sx={{ marginTop: "2%" }}
    //                                                 >
    //                                                     {showNewPassword ? <VisibilityOff /> : <Visibility />}
    //                                                 </IconButton> */}
    //                                         </Grid>

    //                                     </Grid>
    //                                 </Grid>

    //                                 <Grid container my={1}>
    //                                     <Grid item xs={12} sm={12} sx={{ mt: "2%", mx: "5%" }}>
    //                                         <Grid item xs={12}>
    //                                             <Button
    //                                                 onClick={() => { form.handleSubmit(onSubmit)() }}
    //                                                 sx={{
    //                                                     borderRadius: "16px",
    //                                                     backgroundColor: "#196F3D", fontSize: 15, color: "White", ":hover": { bgcolor: "#458522" },
    //                                                     width: "100%"
    //                                                 }}
    //                                             >ส่ง/Send
    //                                             </Button>
    //                                         </Grid>
    //                                     </Grid>
    //                                 </Grid>
    //                             </Card>
    //                         </form>
    //                         {/* <FormPassword /> */}
    //                     </FormProvider>
    //                 </Grid>
    //             </Grid>
    //         </Grid>

    //     </Grid>
    // );
}
export default ChangePassword

