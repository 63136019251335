import * as React from 'react';
import { Box, Button, Card, Grid, Radio, TextField, Typography } from '@mui/material';
import CardContent from '@mui/material/CardContent';
import { FormProvider, useForm } from "react-hook-form";
import * as yup from "yup";
import yupFormSchemas from '../FormItem/yup/yupFormSchemas';
import { yupResolver } from "@hookform/resolvers/yup";
import { useEffect, useState } from 'react';
import { InputForm } from '../FormItem/Input';
import { GridColumns, DataGrid } from '@mui/x-data-grid';
import { Axios, AxiosPost } from 'src/CommonFunction/TS_Axios';
import { FnBlock_UI } from '../BlockUI';
import { Height } from '@mui/icons-material';
// import {
//     DataGridMui as Table,
//     initRows,
//     PaginationInterface
// } from "components/Table/DataGridMui";

const Classroom = () => {
    const { BlockUI, UnBlockUI } = FnBlock_UI();
    const [data, setData] = useState([]);
    const [selectedValue, setSelectedValue] = useState({});
    const [dataRow, setDataRow] = useState({});
    // const [dataRow, setDataRow] = useState<PaginationInterface>({
    //     ...initRows,
    // });

    const objSchema = {
        Username: yupFormSchemas.string("Username", { required: true })
    }
    const schema = yup.object().shape(objSchema);
    const form = useForm({
        resolver: yupResolver(schema),
        shouldUnregister: false,
        shouldFocusError: true,
        mode: "all",
    });

    useEffect(() => {
        getClassAssessment()
    }, []);

    const getClassAssessment = async () => {
        await Axios("News/GetData_News", {}, "POST", async (result) => {

        }, null,
            BlockUI,
            UnBlockUI,
            () => { }
        );
    };

    //Radio
    const handleChange = (e, item) => {
        selectedValue[item.sID] = e.target.value;
        setSelectedValue({ ...selectedValue });

        let tempList = dataRow;
        setDataRow({
            ...tempList,
        });
    };

    // const onGetDetail = (p: PaginationInterface) => {
    //     AxiosPost("UserPermission/EditData_FormCustomer", {}, (res) => {
    //         setDataRow({
    //             ...p,
    //             arrRows: res.lstPermission,
    //             nDataLength: res.nDataLength,
    //             nPageIndex: res.nPageIndex,
    //         });
    //     },
    //         null,
    //         BlockUI,
    //         UnBlockUI,
    //         () => { }
    //     );
    // };

    const rows = [
        { id: 1, lastName: 'Snow', firstName: 'Jon', age: 35 },
        { id: 2, lastName: 'Lannister', firstName: 'Cersei', age: 42 },
        { id: 3, lastName: 'Lannister', firstName: 'Jaime', age: 45 },
        { id: 4, lastName: 'Stark', firstName: 'Arya', age: 16 },
        { id: 5, lastName: 'Targaryen', firstName: 'Daenerys', age: null },
        { id: 6, lastName: 'Melisandre', firstName: null, age: 150 },
        { id: 7, lastName: 'Clifford', firstName: 'Ferrara', age: 44 },
        { id: 8, lastName: 'Frances', firstName: 'Rossini', age: 36 },
        { id: 9, lastName: 'Roxie', firstName: 'Harvey', age: 65 },
    ];

    const dataColumn: GridColumns = [
        {
            field: "lastName",
            headerName: "No.",
            headerAlign: "center",
            editable: false,
            width: 80,
            resizable: true,
            sortable: false,
            align: "center",
        },
        {
            field: "firstName",
            headerName: "Menu",
            headerAlign: "center",
            align: "left",
            resizable: true,
            sortable: false,
            flex: 4,
            minWidth: 200,
            renderCell: (item) => item.value || "",
        },
        {
            field: "age",
            headerName: "ดีมาก",
            headerAlign: "center",
            align: "center",
            resizable: true,
            sortable: false,
            minWidth: 200,
            renderCell: (item) => {
                return (
                    <Radio
                        checked={selectedValue[item.row.sID] == 0}
                        value={0}
                        onChange={(e) => handleChange(e, item.row)}
                        name={"Disabled-button" + item.row.sID}
                    />

                )
            },
        },
        {
            field: "isView",
            headerName: "ดี",
            headerAlign: "center",
            align: "center",
            resizable: true,
            sortable: false,
            minWidth: 200,
            renderCell: (item) => {
                return (
                    <Radio
                        checked={selectedValue[item.row.sID] == 1}
                        value={1}
                        onChange={(e) => handleChange(e, item.row)}
                        name={"ReadOnly-button" + item.row.sID}
                    />
                )
            },
        },
        {
            field: "isEdit",
            headerName: "พอใช้",
            headerAlign: "center",
            align: "center",
            resizable: true,
            sortable: false,
            minWidth: 200,
            renderCell: (item) => {
                return (
                    <Radio
                        checked={selectedValue[item.row.sID] == 2}
                        value={2}
                        onChange={(e) => handleChange(e, item.row)}
                        name={"Enabled-button" + item.row.sID}
                    />
                )
            },
        },
        {
            field: "isEdit",
            headerName: "ควรปรับปรุง",
            headerAlign: "center",
            align: "center",
            resizable: true,
            sortable: false,
            minWidth: 200,
            renderCell: (item) => {
                return (
                    <Radio
                        checked={selectedValue[item.row.sID] == 3}
                        value={2}
                        onChange={(e) => handleChange(e, item.row)}
                        name={"Enabled-button" + item.row.sID}
                    />
                )
            },
        },
    ];

    return (
        <Grid container>
            <Grid item xs={12} sx={{ backgroundColor: "#337ab7" }}>
                <Typography sx={{ mx: "5%", my: "1%", color: "#ffffff" }}>แบบประเมินหลักสูตร</Typography>
            </Grid>

            <FormProvider {...form}>
                <Grid container sx={{ mx: "5%", my: "2%" }}>
                    {/* {(data.length > 0 ? data : []).map((m) => {
                        <Grid itme>
                            <p
                                className="pHead"
                                style={{
                                    whiteSpace: "normal",
                                    textOverflow: "ellipsis",
                                    marginTop: "30px",
                                }}
                            >
                                {(m.sTitle)}
                            </p>
                        </Grid>
                    })} */}
                    {/* <Typography >แบบประเมินหลักสูตร</Typography> */}
                    <Grid item xs={12}>
                        {/* map */}
                        <p
                            className="course"
                        >
                            course
                        </p>

                        <p
                            className="รุ่น"
                        >
                            รุ่น
                        </p>

                        <p
                            className="date"
                        >
                            date
                        </p>

                        <p
                            className="place"
                        >
                            place
                        </p>
                    </Grid>
                    <Grid item xs={12}>
                        <Box sx={{ width: "100%" }}>
                            {/* <Table
                                Rows={dataRow}
                                Columns={dataColumn}
                                isNotShowTotal={true}
                                isNotShowPagination={true}
                                // OnLoadData={(p) => onGetDetail(p)}
                            /> */}
                            <DataGrid
                                rows={rows}
                                columns={dataColumn}
                                pageSize={5}
                                rowsPerPageOptions={[5]}
                                checkboxSelection
                            />
                        </Box>
                    </Grid>
                    <Grid item xs={12}>
                        <InputForm
                            fullWidth
                            label="ความคิดเห็น / หลักสูตรที่สนใจเข้าร่วมเพิ่มเติม"
                            name={"name"}
                            type={"text"}
                            // rows={5}
                            maxLength={1000}
                        />

                        {/* <TextField
                            fullWidth
                            label="ความคิดเห็น / หลักสูตรที่สนใจเข้าร่วมเพิ่มเติม"
                            // maxLength={1000}
                            name={''}
                        /> */}
                    </Grid>
                </Grid>
            </FormProvider>

        </Grid>
    )
}
export default Classroom

function async() {
    throw new Error('Function not implemented.');
}
