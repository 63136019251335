import { Card, CardMedia, Grid, Stack } from "@mui/material";
import Typography from "@mui/material/Typography";
import { useEffect, useState } from "react";
import { AiOutlineEye } from "react-icons/ai";
import ReactPlayer from "react-player";
import { Axios } from "src/CommonFunction/TS_Axios";
import { FnBlock_UI } from "../BlockUI";
import GeneralTopic from "./GeneralTopic";
import defaultImage from "../../Layout/img/topic-2.jpg";
import { ParseHtml, GetQueryString } from "src/CommonFunction/TS_function";
import imgCover from "src/Layout/img/vision-cover.jpg";
import { Banner } from "../Banner";
import { BreadcrumbsUI } from "../BreadcrumbsUI";
import { Padding } from "@mui/icons-material";
import { MenuBar } from "../../Components/System/NavBar/MenuBar";
import LightGallery from 'lightgallery/react';
import lgThumbnail from 'lightgallery/plugins/thumbnail';
import './General.css'
import zIndex from "@mui/material/styles/zIndex";

const GeneralPage = () => {
    const { BlockUI, UnBlockUI } = FnBlock_UI();
    const [DataDetail, setDataDetail] = useState({} as any);
    const [DataTopic, setDataTopic] = useState([]);
    const [Topic, setTopic] = useState({} as any);
    const searchParams = location.search;
    const nIDC = new URLSearchParams(searchParams).get('nIDC');
    const sContentID = GetQueryString('str');
    useEffect(() => {
        OnGetData(nIDC);
    }, [nIDC]);



    const OnGetData = async (nIDCX) => {

        await Axios(
            `GeneralKnowledgel/GetData_GeneralKnowledgelDetail`, { nIDC: (nIDCX + "") }, "POST", async (res) => {
                setDataTopic(res.lstTopic || []);
                setTopic(res.objDataDetail.lstDetail || []);
                setDataDetail(res.objDataDetail);
            },
            null,
            BlockUI,
            UnBlockUI,
            () => { }
        );
    };

    return (
        <Grid container>

            <Grid item xs={12}>
                {/* <BreadcrumbsUI
                    //  isShowBG={true}
                    isShowHome={false}
                    items={[
                        {
                            menuName: "ความรู้ทั่วไป/Knowledge",
                            path: process.env.REACT_APP_API_URL + "KnowledgeSharingList?str=2tTxyBjQ5aaFgQsWC3kvZQ%3d%3d",
                            IsOnClick: true
                        },

                    ]}
                /> */}
                <MenuBar sContentID={sContentID} />
            </Grid>
            <Grid item xs={12}>
                <Grid container sx={{ px: "1em" }}>
                    <Grid item xs={12} md={(DataTopic.length > 0) ? 8 : 12} lg={(DataTopic.length > 0) ? 9 : 12} >
                        <Grid container sx={{ textAlign: "left", pl: { xs: "3em", md: "4em" } }}>
                            <h1
                                className="sTitleNews"
                                style={{
                                    whiteSpace: "normal",
                                    textOverflow: "ellipsis",
                                    marginTop: "3",
                                    wordBreak: "break-word",
                                }}
                            >
                                {DataDetail.sNames}
                            </h1>
                            {DataDetail.isPin && (
                                <Typography
                                    style={{
                                        backgroundColor: "red",
                                        color: "white",
                                        borderRadius: "16px",
                                        height: "30px",
                                        marginTop: "30px",
                                        marginLeft: "10px",
                                        width: "45px",
                                        fontSize: "12px",
                                        alignItems: "center",
                                        display: "flex",
                                        justifyContent: "center",
                                        padding: "0 4px"
                                    }}
                                >
                                    New
                                </Typography>
                            )}
                        </Grid>
                        <Grid item xs={12} sx={{ mx: "3%" }}>
                            <Grid container
                                direction="row"
                                justifyContent="flex-end"
                                alignItems="flex-end">

                                <Typography
                                    align="center"
                                    style={{
                                        backgroundColor: "Gainsboro",
                                        borderRadius: "16px",
                                        height: "35px",
                                        // width: "90px",
                                        margin: "0",
                                        alignItems: "center",
                                        display: "flex",
                                        justifyContent: "center",
                                        padding: "0 4px"

                                    }}
                                >
                                    <AiOutlineEye
                                        size={20} />
                                    {/* <p style={{

                                        paddingLeft: "5px"
                                    }}> */}
                                    {DataDetail.nconutView}
                                    {/* </p> */}
                                </Typography>
                            </Grid>
                        </Grid>
                        {(Topic ? (Topic.length > 0 ? Topic : []) : []).map((m) => {
                            if (m.nContentTypeID == 1) {
                                return (
                                    <Grid item xs={12} sx={{ marginTop: "1%" }}>
                                        <p
                                            className="sContentHTML"
                                            style={{
                                                whiteSpace: "normal",
                                                textOverflow: "ellipsis",
                                                marginRight: "3%",
                                                marginLeft: "5%",
                                                wordBreak:"break-word",

                                            }}
                                        >
                                            {ParseHtml(m.sContentHTML)}
                                        </p>
                                    </Grid>
                                );
                            } else if (m.nContentTypeID == 2) {
                                return (
                                    <Grid item xs={12} sx={{ mx: "3%", marginTop: "3%" }}>
                                        <Grid container xs={12} justifyContent="center" alignItems="center" >
                                            <LightGallery
                                                speed={100}
                                                plugins={[lgThumbnail]}
                                                // mode="lg-fade"
                                                elementClassNames={"generalImg"}

                                            >
                                                <img
                                                    src={m.sURLImagesub || defaultImage}
                                                    onError={({ currentTarget }) => {
                                                        currentTarget.onerror = null;
                                                        currentTarget.src = defaultImage;
                                                    }}
                                                    style={{
                                                        maxWidth: "60%",
                                                    }}
                                                />
                                            </LightGallery>
                                        </Grid>
                                        <p
                                            className="sDesc"
                                            style={{
                                                whiteSpace: "normal",
                                                textOverflow: "ellipsis",
                                                textAlign:"center",
                                            }}
                                        >
                                            {m.sDescSub}
                                        </p>
                                    </Grid>
                                );
                            } else if (m.nContentTypeID == 3) {                            
                                return (
                                    <Grid item xs={12} sx={{ marginBottom: "3%", mx: "5%" }}>
                                        < Stack direction="column" alignItems="center" sx={{ mx: "5%", marginTop: "3%" }}>
                                            <ReactPlayer
                                                url={m.sURLImagesub}
                                                muted
                                                width="80%"
                                                height="500px"
                                                controls={true}
                                            />
                                        </Stack>
                                        <p
                                            className="sDesc"
                                            style={{
                                                whiteSpace: "normal",
                                                textOverflow: "ellipsis",
                                                textAlign:"center",

                                            }}
                                        >
                                            {m.sDescSub}
                                        </p>
                                    </Grid>
                                );
                            } else if (m.nContentTypeID == 4) {                             
                                return (
                                    <Grid item xs={12} sx={{ marginBottom: "3%", mx: "5%" }}>
                                        <Stack direction="column" alignItems="center" sx={{ mx: "5%", marginTop: "3%" }}>
                                            <ReactPlayer
                                                url={m.sURL}
                                                controls={true}
                                                width="80%"
                                                height="500px"
                                                muted
                                            />
                                        </Stack>
                                        <p
                                            className="sDesc"
                                            style={{
                                                whiteSpace: "normal",
                                                textOverflow: "ellipsis",
                                                textAlign:"center",
                                            }}
                                        >
                                            {m.sDescSub}
                                        </p>
                                    </Grid>
                                );
                            } 
                            // else {
                            //     return null;
                            // }
                        }
                        )}
                    </Grid>
                    {DataTopic.length > 0 && (
                        <Grid item xs={12} md={4} lg={3} sx={{ my: "2em", borderRadius: 2 }}>
                            {/* style={{ backgroundImage: "linear-gradient(to bottom right, #e5ebef, #63b2db)" }} */}
                            <div >
                                <Typography sx={{ pt: "2em", px: "2em", textAlign: "left", fontSize: { xs: "1em", lg: "1.2em" }, fontWeight: "600", color: "#3997df" }}>ความรู้ที่เกี่ยวข้อง<><br></br></>Related Knowledge</Typography>
                                <Grid container sx={{ mt: "2em" }}>
                                    {(DataTopic.length > 0 ? DataTopic : []).map((item) => {
                                        if (DataTopic.length > 0) {
                                            return (
                                                <Grid item xs={12} sm={6} md={12} sx={{ p: "2em" }}  >
                                                    <Card sx={{ width: "100%", }}>
                                                        <GeneralTopic Detail={item} fn={OnGetData} />
                                                    </Card>
                                                </Grid>
                                            )
                                        }
                                    })}
                                </Grid>
                            </div>
                        </Grid>
                    )}
                    <Grid item xs={12} sx={{ my: "2%", marginTop: "10px" }}>
                        <Grid container>
                            < Grid item xs={12} sx={{ textAlign: "left", marginRight: "10%", marginBottom: "3%" }}>
                                <p
                                    className="sUpdateDate"
                                    style={{
                                        whiteSpace: "normal",
                                        textOverflow: "ellipsis",
                                        fontStyle: "italic",
                                        color: "#A0A0A0",
                                        marginRight: "3%",
                                        marginLeft: "5%"

                                    }}
                                >
                                    {(DataDetail.sUpdateDate)}
                                </p>
                            </Grid>
                        </Grid>
                    </Grid>

                </Grid >
            </Grid>
        </Grid >
    );
}
export default GeneralPage


