import { Grid, Box, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import { Axios } from "src/CommonFunction/TS_Axios";
import Pagination from "@mui/material/Pagination";
import { usePagination } from "../../Components/PaginationUI/Pagination";
import { useLocation, useNavigate } from "react-router-dom";
import { InputForm } from "src/Components/FormItem/Input";
import { FormProvider, useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { FnBlock_UI } from "src/Components/BlockUI";
import { Decrypt, Encrypt } from "src/CommonFunction/TS_function";
import "./ResultSearch.css";
import { Block } from "../../Components/Block";
import { NoDataUI } from "src/Components/NoData";
import defaultImageOnline from "src/Layout/img/pic-default/de-online.jpg";
import defaultImageClassroom from "src/Layout/img/pic-default/de-classroom.jpg";
import defaultImageGeneral from "src/Layout/img/pic-default/de-knowledge.jpg";
import defaultImageInstructor from "src/Layout/img/def-instructor.jpg";
import defaultImageNews from "src/Layout/img/pic-default/de-news.jpg";
import defaultImageWhatNews from "src/Layout/img/pic-default/de-whatsnew.jpg";
import defaultImageActivity from "src/Layout/img/pic-default/de-activity.jpg";

import defaultImage from "src/Layout/img/default-image.png";
import { AccordionUI } from "src/Components/AccordionUI";
import { CheckBoxUI } from "src/Components/FormItem/CheckBox";
import { FaFilter, FaLock } from "react-icons/fa";
import { ClassroomDetail } from "src/Components/System/ClassroomModal/ClassroomDetail";
import { ClassroomDetailModal } from "src/Components/System/ClassroomModal/ClassroomDetailModal";
import { number } from "prop-types";
export interface UserProp {
  search: string;
}

const JWT = localStorage.getItem(process.env.REACT_APP_JWT_KEY as string);

const ResultSearch = () => {
  const { BlockUI, UnBlockUI } = FnBlock_UI();
  const [blocking, setBlocking] = useState(false);
  // const location = useLocation().state as UserProp;
  const location = window.location.search;
  const [dataResultSearch, setDataResultSearch] = useState([]);
  const [keywordSearch, setKeywordSearch] = useState("");
  const [showNodata, setShowNodata] = useState(false);
  const [lstCategory, setlstCategory] = useState([]) as any;
  const [isClear, setisClear] = useState(false);
  const [Course, setCourse] = useState([]) as any;
  const [objCourse, setobjCourse] = useState({}) as any;
  const [isopen, setisopen] = useState(false)
  const [nCourseID, setCourseID] = useState(0 as any);
  const [nScheduleID, setScheduleID] = useState(0 as any);
  const [isShowExpand, setShowExpand] = useState(true);
  const [nWidth, setWidth] = useState(window.innerWidth);
  const objSchema = {};
  const schema = yup.object().shape(objSchema);
  const form = useForm({
    mode: "all",
    resolver: yupResolver(schema),
    shouldFocusError: true,
  });
  const navigate = useNavigate();

  useEffect(() => {
    const urlParams = new URLSearchParams(location);
    let sSearch = Decrypt(urlParams.get("sSearch") + "");
    form.setValue("Search", sSearch);
    setKeywordSearch(sSearch);
    setData(sSearch);
  }, []);

  useEffect(() => {
    handleChange(null, 1);
  }, [dataResultSearch]);

  useEffect(() => {

    if (nWidth >= 600) {
      setShowExpand(true);
    } else {
      setShowExpand(false);
    }
  }, [nWidth]);
  useEffect(() => {
    window.addEventListener("resize", updateDimensions);
    return () => window.removeEventListener("resize", updateDimensions);
  }, []);
  const updateDimensions = () => {
    setWidth(window.innerWidth);
  }
  const SetOpen = () => {
    if (isShowExpand) {
      setShowExpand(false);
    } else {
      setShowExpand(true);
    }

  }

  let [page, setPage] = useState(1);
  const PER_PAGE = 10;

  const handleChange = (e, p) => {
    setPage(p);
    _DATA.jump(p);
    window[`scrollTo`]({ top: 0, behavior: `smooth` });
  };

  const setData = async (param) => {
    let resData = await onSearch(param);
    setDataResultSearch(resData);

  };

  const onSearch = async (param) => {
    let oSeach = {
      sSearch: form.getValues("Search"),
      sCategory: form.getValues("sCategory") || [],
    }
    let result = {} as any;
    // setBlocking(true);
    await Axios(
      `SearchPage/GetData_Search`,
      oSeach || {},
      "Post",
      async (res) => {
        setisClear(false)
        result = res.lstSearchPage;
        setCourse(res.lstSearchPage.filter(f => f.nCategoryID == 1))
        if (res.lstSearchPage === null || res.lstSearchPage.length === 0) {
          setShowNodata(true);
        } else {
          setShowNodata(false);
        }
        if (res.lstCategory) {
          setlstCategory(res.lstCategory || []);
        }
        setDataResultSearch(result);
        setKeywordSearch(oSeach.sSearch);
        // setBlocking(false);
      },
      null,
      BlockUI,
      UnBlockUI,
      () => { }
    );
    return result;
  };

  const onKeyPress = (e) => {
    if (e.key === "Enter") {
      let param = {
        sSearch: form.getValues("Search"),
        sCategory: form.getValues("sCategory") || [],
      }
      //  let param = form.getValues("Search");
      let url = "?sSearch=" + Encrypt(param.sSearch || "");
      window.history.pushState({}, "", window.location.pathname + "" + url);
      onSearch(param);
    }
  };

  const onSearchClick = (e) => {
    let param = form.getValues("Search");
    let oSeach = {
      sSearch: param,
      sCategory: form.getValues("sCategory") || [],
    }
    let url = "?sSearch=" + Encrypt(param.sSearch || "");
    window.history.pushState({}, "", window.location.pathname + "" + url);
    onSearch(oSeach);
  };

  const onClickClear = () => {
    setisClear(true)
    form.setValue("sCategory", []);

  }

  const count = Math.ceil((dataResultSearch || []).length / PER_PAGE);
  const _DATA = usePagination(dataResultSearch || [], PER_PAGE);

  const onClick = (e) => { 
    if (e.nCategoryID === 1) {
      let oData = Course.filter(f => f.nID == e.nID && f.nScheduleID == e.nScheduleID)[0]
      if (oData) {
        setobjCourse(oData);
        setisopen(true);
        setCourseID(oData.nID);
        setScheduleID(oData.nScheduleID);
      }
    }
    else {
      navigate(process.env.REACT_APP_API_URL + e.sPath);
    }
  };
  const onClickClose = (e) => {
    setisopen(e)
  };

  return (
    <>
      <FormProvider {...form}>
        <form>
          <Box sx={{ minHeight: { sm: "60vh" } }}>
            <Grid
              container
              sx={{ mt: { xs: "2em" }, width: "90vw", minHeight: "60vh" }}
            >
              <Grid
                item
                xs={12}
                sx={{
                  display: "flex",
                  alignItems: { xs: "flex-start", sm: "center" },
                  flexDirection: { xs: "column-reverse", sm: "row" },
                  flexWrap: { sm: "wrap" },
                  alignContent: { sm: "flex-start" },
                }}
              >
                <Grid item xs={12} sm={8}>
                  {dataResultSearch !== undefined && (
                    <>
                      {dataResultSearch.length > 0 ? (
                        <Typography className="rSearch">
                          ผลการค้นหา Result "{keywordSearch}" ทั้งหมดจำนวน{" "}
                          {dataResultSearch.length} รายการ
                        </Typography>
                      ) : (
                        <>
                          <Typography className="rSearch">
                            การค้นหาของคุณ "{keywordSearch}" ไม่ตรงกับเอกสารใด
                          </Typography>
                        </>
                      )}
                    </>
                  )}
                </Grid>
                <Grid item xs={12} sm={4}>
                  <InputForm
                    name="Search"
                    label="Search"
                    placeholder="Search"
                    showClear
                    isCurve
                    isSearch
                    fullWidth
                    onKeyPress={(e) => onKeyPress(e)}
                    onSearchClick={onSearchClick}
                  />
                </Grid>
              </Grid>
              <Grid item xs={12} sx={{ display: "flex", flexDirection: { xs: "column", sm: "row" } }}>
                <Grid item xs={12} sm={4} md={4} lg={3}>
                  <Grid item xs={12} sx={{ mx: 1, display: "flex", justifyContent: "space-between", height: "56px" }}>
                    <Typography
                      sx={{ display: "flex", alignItems: "center", color: "rgb(57, 151, 223)", cursor: "pointer",
                      ":active": {
                        color: "rgba(57, 151, 223,0.5)"
                      }
                     }}
                      onClick={SetOpen}
                    >
                      <FaFilter/>
                      ตัวกรอง/Filter
                    </Typography>
                    <Typography sx={{
                      color: "rgb(57, 151, 223)", cursor: "pointer", display: "flex", alignItems: "center",
                      ":active": {
                        color: "rgba(57, 151, 223,0.5)"
                      }
                    }} onClick={onClickClear}>
                      Clear Filter
                    </Typography>
                  </Grid>

                  <Grid item xs={12} sx={{ mx: 1 }}  >

                    <AccordionUI headerName="ประเภท /Category"
                      defaultExpand
                      
                      isExpaned={isShowExpand}
                    >
                      <CheckBoxUI
                        name="sCategory"
                        type="group"
                        AllowCheckAll
                        options={lstCategory}
                        isClear={isClear}
                        onChange={() => {
                          if (isClear) {
                            setisClear(false)
                          }

                        }}
                      />
                    </AccordionUI>
                  </Grid>
                </Grid>
                <Grid item xs={12} sm={12} md={8} lg={9} sx={{ pt: "20px", pl: "10px" }}>
                  <Grid item xs={12} >
                    <NoDataUI isShow={showNodata} />
                  </Grid>
                  <Grid>
                    {_DATA.currentData().map((m) => (
                      <>
                        <Grid
                          container
                          key={m.nID}
                          sx={{
                            mb: "10px",
                            cursor: "pointer",
                            boxShadow: "0 0 0.5em 0 rgba(0, 0, 0, 0.25)",
                            height: { xs: "90px", sm: "160px" },
                          }}
                          onClick={() => {
                            onClick(m);
                          }}
                        >
                          {m.nCourseType === 1 && !m.isPublic && JWT === null &&
                            <Grid item xs={12}
                              sx={{
                                position: "absolute",
                                bgcolor: "#FF0000",
                                width: "40px",
                                height: "40px",
                                borderBottomRightRadius: "30%"
                              }}>
                              <FaLock style={{ color: "#ffffff", margin: "5px" }} size={30} />
                            </Grid>
                          }
                          <Grid
                            item
                            xs={4}
                            sm={3}
                            lg={2}
                            sx={{ height: { xs: "90px", sm: "160px" } }}
                          >

                            {/*
                        หลักสูตร Online/Online course sContentID  1
                        หลักสูตร Classroom/Course Classroom    sContentID  2
                        ความรู้ทั่วไป/General Knowledge sContentID  3
                        ทำเนียบวิทยากร sContentID  10
                        ข่าวสาร/News sContentID  20
                        กิจกรรม/Activity  sContentID  21
                        ข่าวใหม่/WhatNew  sContentID  39

                        หลักสูตร Classroom/Course Classroom    nCategoryID  1
                        หลักสูตร Online/Online course nCategoryID  2
                        องค์ความรู้/Knowledge nCategoryID  3
                        ข่าวสาร/News nCategoryID 4
                        กิจกรรม/Activity nCategoryID  5
                        What's New  nCategoryID 6
                        วิทยากร/Instructor  nCategoryID  7
                        */}
                            <img
                              className="imgR"
                              src={m.sURLImage}
                              onError={({ currentTarget }) => {
                                currentTarget.onerror = null;
                                currentTarget.src =
                                  m.nCategoryID === 1
                                    ? defaultImageClassroom
                                    : m.nCategoryID === 2
                                      ? defaultImageOnline :
                                      m.nCategoryID === 3
                                        ? defaultImageGeneral :
                                        m.nCategoryID === 4
                                          ? defaultImageNews :
                                          m.nCategoryID === 5
                                            ? defaultImageActivity :
                                            m.nCategoryID === 6
                                              ? defaultImageWhatNews :
                                              m.nCategoryID === 7
                                                ? defaultImageInstructor
                                                : defaultImage;
                              }}
                            />

                          </Grid>
                          <Grid
                            item
                            xs={8}
                            sm={9}
                            lg={10}
                            sx={{
                              height: { xs: "90px", sm: "140px" },
                            }}
                          >
                            <p
                              className="pH"
                              style={{
                                whiteSpace: "normal",
                                margin: "15px 20px 0px 20px",
                                fontWeight: "bold"
                              }}
                            >
                              {m.sCode ? m.sCode + " - " : ""} {m.sName}
                            </p>
                            <Typography
                              sx={{
                                fontSize: {
                                  xs: "0.7em !important",
                                  lg: "1em !important",
                                },
                                m: "0px 20px 0px 20px",
                                color: "#3997df",
                                // width: { lg: "850px" },
                                overflowWrap: "break-word",
                                whiteSpace: "normal"
                              }}
                            >
                              {m.sContentName}
                            </Typography>
                            <p
                              className="pD"
                              style={{
                                fontSize: "0.8em",
                                margin: "0px 20px 0px 20px",
                                whiteSpace: "normal",
                                color: "rgba(0,0,0,0.8)",
                              }}
                            >
                              {m.sNameDetail}
                            </p>
                          </Grid>
                        </Grid>
                      </>
                    ))}
                  </Grid>
                </Grid>
              </Grid>
              <Grid
                container
                sx={{
                  m: "5em 2em",
                }}
              >
                <Grid xs={12} sm={3}>
                </Grid>
                <Grid xs={12} sm={9} sx={{ display: "flex", justifyContent: "center" }}>
                  <Pagination
                    count={count}
                    page={page}
                    variant="outlined"
                    shape="rounded"
                    showFirstButton
                    showLastButton
                    onChange={handleChange}
                  />
                </Grid>
              </Grid>
            </Grid>
          </Box>
          {/* <Block blocking={blocking} /> */}
          {/* <ClassroomDetail
            nID={objCourse.nID}
            nScheduleID={objCourse.nScheduleID}
            data={objCourse}
            isExpire={objCourse.isExpire}
            isopen={isopen}
            onClose={onClickClose}
          ></ClassroomDetail> */}
          {
            isopen ?
              <ClassroomDetailModal nID={nCourseID} nScheduleID={nScheduleID} isOpen={isopen} onClose={onClickClose}></ClassroomDetailModal>
              :
              null
          }


        </form>

      </FormProvider>
    </>
  );
};

export default ResultSearch;
