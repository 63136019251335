import * as React from "react";
import { styled } from "@mui/material/styles";
import ArrowForwardIosSharpIcon from "@mui/icons-material/ArrowForwardIosSharp";
import MdKeyboardArrowDown from "@mui/icons-material/ArrowForwardIosSharp";


import MuiAccordion, { AccordionProps } from "@mui/material/Accordion";
import MuiAccordionSummary, {
  AccordionSummaryProps,
} from "@mui/material/AccordionSummary";
import MuiAccordionDetails from "@mui/material/AccordionDetails";
import Typography from "@mui/material/Typography";
import { useState, useEffect } from "react";

const Accordion = styled((props: AccordionProps) => (
  <MuiAccordion disableGutters elevation={0} square {...props} />
))(({ theme }) => ({
  border: `1px solid ${theme.palette.divider}`,
  "&:not(:last-child)": {
    borderBottom: 0,
  },
  "&:before": {
    display: "none",
  },
}));

const AccordionSummary = styled((props: AccordionSummaryProps) => (
  <MuiAccordionSummary {...props} />
))(({ theme }) => ({

  "& .MuiAccordionSummary-expandIconWrapper": {
    transform: "rotate(90deg)",
  },
  "& .MuiAccordionSummary-expandIconWrapper.Mui-expanded": {
    transform: "rotate(-90deg)",
  },
  "& .MuiAccordionSummary-content": {
    marginLeft: theme.spacing(2),
  },
}));

const AccordionDetails = styled(MuiAccordionDetails)(({ theme }) => ({
  padding: theme.spacing(2),
  borderTop: "1px solid rgba(0, 0, 0, .125)",
}));

export function AccordionUI(props: AccordionUI) {
  const { headerName, defaultExpand = false, showExpaned = true, isExpaned = true,
    color = "rgb(255,255,255)",
    hPosition = false,
    marginLeft = 0,
    marginRight = 0,
    paddingLeft = 0,
  } = props;

  const [isOpen, setExpand] = React.useState(isExpaned);
  const SetOpen = () => {
    if (isOpen) {
      setExpand(false)
    } else {
      setExpand(true)
    }

  }
  useEffect(() => {
    setExpand(isExpaned)
  }, [isExpaned]);
  return (
    <div style={{ maxWidth: "100%" }}>
      <Accordion
        defaultExpanded={defaultExpand}
        expanded={isOpen}
        onChange={() => {
          SetOpen()
        }}
      >
        <AccordionSummary
          aria-controls="panel1d-content"
          id="panel1d-header"
          expandIcon={showExpaned && <MdKeyboardArrowDown sx={{ fontSize: "0.9rem" }}
            onClick={() => {
              SetOpen()
            }}
          />}
          sx={{
            bgcolor: color, color: color === "rgb(255,255,255)" ? "rgb(0,0,0)" : "rgb(255,255,255)",
            flexDirection: hPosition ? "row-reverse" : "",
            ml: marginLeft, mr: marginRight
          }}
        >
          <Typography>{headerName}</Typography>
        </AccordionSummary>
        <AccordionDetails sx={{ pl: paddingLeft }}>{props.children}</AccordionDetails>
      </Accordion>
    </div>
  );
}

interface AccordionUI {
  headerName?: string;
  children?: any;
  defaultExpand?: boolean;
  showExpaned?: boolean;
  color?: string;
  hPosition?: boolean;
  paddingLeft?: string;
  marginLeft?: string;
  marginRight?: string;
  isExpaned?: boolean;
}
