import { Box, Button, Card, CardActions, CardContent, CardMedia, Grid, Pagination } from "@mui/material";
import Typography from "@mui/material/Typography";
import { useEffect, useState, useRef } from "react";
import { FormProvider, useForm } from "react-hook-form";
import { Axios } from "src/CommonFunction/TS_Axios";
import { FnBlock_UI } from "../BlockUI";
import 'src/Components/Register/Register.css';
import * as yup from "yup";
import yupFormSchemas from "src/Components/FormItem/yup/yupFormSchemas";
import { yupResolver } from "@hookform/resolvers/yup";
import { InputForm } from "../FormItem/Input";
import sCover from "src/Layout/img/vision-cover.jpg";
import { SelectForm } from "../FormItem/Select";
import { DatePickerUI } from "../FormItem/DatePicker";
import { BreadcrumbsUI } from "../BreadcrumbsUI";
import { usePagination } from "../PaginationUI/Pagination";
import { BtnCustomText, BtnSearchWithOutText, BtnSeeDetail } from "../Button";
import { useNavigate } from "react-router-dom";
import icon from "../../Layout/img/logo-Event.png";
import "../Register/Register.css";
import defaultImage from "../../Layout/img/activity-2.jpg";
import moment from "moment";
import { Banner } from "../Banner";
import imgCover from "src/Layout/img/activity-cover.jpg";
import { NoDataUI } from "../NoData";
import AutoCompleteSelect from "../FormItem/AutoCompleteSelect";
import 'src/Components/EventPhoto/StyleEventPhoto.css';
import { GetQueryString } from "src/CommonFunction/TS_function";
import { MenuBar } from "../../Components/System/NavBar/MenuBar";


const EventPhoto = () => {
  const navigate = useNavigate();
  const { BlockUI, UnBlockUI } = FnBlock_UI();
  const [arrCard, setarrCard] = useState<any[]>([]);
  const [Page, setPage] = useState(1); //page
  const [CountPage, setCountPage] = useState() as any;
  const PER_PAGE = 10;
  const [banner, setBanner] = useState([] || undefined);
  const [StartDate, setStartDate] = useState(undefined);
  const [EndDate, setEndDate] = useState(undefined);

  const [showNodata, setShowNodata] = useState(false);
  const sContentID = GetQueryString('str');
  const [sNameTH, setNameTH] = useState("");
  const [sNameENG, setNameENG] = useState("");
  const oFormRef = useRef() as any;
  const objSchema = {
    sSearch: yupFormSchemas.string("sSearch"),
  };
  const schema = yup.object().shape(objSchema);
  const form = useForm({
    resolver: yupResolver(schema),
    shouldUnregister: false,
    shouldFocusError: true,
    mode: "all",
  });

  const goToAdd = (sID: string) => {
    navigate(
      process.env.REACT_APP_API_URL + `EventDetail?sID=${encodeURI(sID)}` + '&str=' + sContentID
    );
  };
  useEffect(() => {
    setData();
  }, [Page]);
  useEffect(() => {
    form.setValue("nSort", Sort[0]);
  }, []);

  const setData = async () => {
    let resBanner = await loadBanner();
    let resarrCard = await OnGetData();
    let resNavBar = await loadNavBar();
    setBanner(resBanner);
    setarrCard(resarrCard);
    setNameTH(resNavBar.menuNameTH);
    setNameENG(resNavBar.menuNameENG);
  };


  const OnGetData = async () => {
    let dStart = moment(form.getValues("dStart")).toDate();
    let dEnd = moment(form.getValues("dEnd")).toDate();
    let result = {} as any;
    let npage = {
      nID: Page,
      sSearch: form.getValues("sSearch") || "",
      nStart: dStart.valueOf(),
      nEnd: dEnd.valueOf(),
      nSort: form.getValues("nSort") != undefined ? form.getValues("nSort").value : 1,

    };
    await Axios(
      `EventphotoPage/GetData_Eventphoto`,
      npage,
      "POST",
      async (res) => {
        setShowNodata(false);
        setarrCard(res.lstEventPage);
        result = res.lstEventPage;
        let nCount = res.nPageAll / 10;
        let nPage = Math.ceil(nCount);
        setCountPage(nPage);
        if (res.lstEventPage.length === 0) {
          setShowNodata(true);
        };
      },
      setShowNodata(true),
      BlockUI,
      UnBlockUI,
      () => { }
    );
    return result;
  };
  const count = CountPage;
  const _DATA = usePagination(CountPage || [], PER_PAGE);

  const handleChange = (e, p) => {
    setPage(p);
    _DATA.jump(p);
    if (oFormRef.current != null) {
      window.scrollTo({
        top: oFormRef.current.offsetTop - 100,
        left: 0,
        behavior: "smooth",
      });
    }
  };

  const Sort = [
    { label: "ใหม่-เก่า", value: 1 },
    { label: "เก่า-ใหม่", value: 2 },
  ];

  const loadBanner = async () => {
    let result = [] as any;
    result.push({
      sPath: imgCover,
    });

    return result;
  };
  const loadNavBar = async () => {
    let result = {} as any;
    let param = "sContentID=" + sContentID;
    await Axios(`Master/GetNamePage`, param, "Get", async (res) => {
      result = res;
    },
      null,
      null,
      null,
      () => { }
    );
    return result;
  };

  return (
    <>
      <FormProvider {...form}>
        <Grid container>
          <Grid item xs={12}>
            <Banner
              dataSource={banner}
              height={50}
              textTH={sNameTH}
              textENG={sNameENG}
            />
          </Grid >

          <Grid item xs={12}> <MenuBar sContentID={sContentID} /></Grid>
          <Grid container sx={{ m: "2em" }}>
            <Grid container flexWrap={"wrap"} sx={{ paddingRight: "1em", marginTop: 2 }}>
              <Grid item xs={12} sx={{ mb: "1em", display: "flex", justifyContent: "flex-end", flexDirection: { xs: "column", lg: "row" } }}>

                <Grid item xs={24} sm={24} md={24} lg={2.5} sx={{ px: { lg: "0.5em" }, maxWidth: "100% !important", mt: "0.5em", paddingLeft: "0.5em", paddingTop: "0.5em" }}>
                  <DatePickerUI
                    label="วันที่เริ่ม"
                    name="dStart"
                    small
                    onChange={(e) => setStartDate(e)}
                    maxDate={EndDate ? EndDate : undefined}
                    onClearFields={(e) => setStartDate(undefined)}
                  />
                </Grid>
                <Grid item xs={24} sm={24} md={24} lg={2.5} sx={{ px: { lg: "0.5em" }, maxWidth: "100% !important", mt: "0.5em", paddingLeft: "0.5em", paddingTop: "0.5em" }}>
                  <DatePickerUI
                    label="ถึงวันที่"
                    name="dEnd"
                    small
                    onChange={(e) => setEndDate(e)}
                    minDate={StartDate ? StartDate : undefined}
                    onClearFields={(e) => setEndDate(undefined)}
                  />
                </Grid>

                <Grid item xs={12} sm={12} md={7.5} lg={7.5} sx={{ px: { lg: "0.5em" }, maxWidth: "100% !important", paddingRight: "0.5em", paddingTop: "0.5em" }}>
                  <InputForm
                    fullWidth
                    name={"sSearch"}
                    maxLength={100}
                    label="ค้นหา"
                    onKeyPress={OnGetData}

                  />
                </Grid>

                <Grid item xs={12} sm={12} md={1.5} lg={1.5} sx={{ px: { lg: "0.5em" }, maxWidth: "100% !important", paddingRight: "0.5em", paddingTop: "0.5em" }}>
                  <AutoCompleteSelect
                    required
                    name="nSort"
                    label={"เรียงโดย"}
                    width={{ width: "100%" }}
                    options={Sort}
                    IsShowIcon={false}
                    defaultValue={{ label: "ใหม่-เก่า", value: 1 }}
                    onChange={(v) => {
                      form.setValue('nSort', v ? v : null)
                      OnGetData();

                    }}

                  />
                </Grid>



                <Grid item sx={{ display: "flex", justifyContent: "center", paddingLeft: "0.5em", paddingTop: "0.5em" }}>
                  <BtnCustomText
                    txt="ค้นหา/Search"
                    onClick={OnGetData}
                  ></BtnCustomText>
                </Grid>


              </Grid>
            </Grid>
          </Grid>


          {/* <Grid
            container
            spacing={2}
            md={12}
            sx={{
              mt: "2em",
              mx: "3em",
              display: "flex",
              justifyContent: "flex-end",
            }}
          >
            <Grid item md={2} xs={12} sx={{ marginTop: 1 }}>
              <DatePickerUI
                label="วันที่เริ่ม"
                name="dStart"
                small
                onChange={(e) => setStartDate(e)}
                maxDate={EndDate ? EndDate : undefined}
                onClearFields={(e) => setStartDate(undefined)}
              />
            </Grid>
            <Grid item md={2} xs={12} sx={{ marginTop: 1 }}>
              <DatePickerUI
                label="ถึงวันที่"
                name="dEnd"
                small
                onChange={(e) => setEndDate(e)}
                minDate={StartDate ? StartDate : undefined}
                onClearFields={(e) => setEndDate(undefined)}
              />
            </Grid>
            <Grid item md={6} xs={12}>
              <InputForm
                fullWidth
                name={"sSearch"}
                maxLength={100}
                label="ค้นหา"
                onKeyPress={OnGetData}
                mx={0}
              />
            </Grid>

            <Grid
              item
              md={1.5}
              xs={12}
              sx={{ display: "flex", justifyContent: "center", maxWidth: "100% !important" }}
            >
              <BtnCustomText
                txt="ค้นหา/Search"
                onClick={OnGetData}
              ></BtnCustomText>
            </Grid>
          </Grid>

          <Grid container sx={{ justifyContent: "flex-end", marginTop: "2%", marginRight: 6 }} >
            <AutoCompleteSelect
              required
              name="nSort"
              label={"เรียงโดย"}
              width={"250px"}
              options={Sort}
              IsShowIcon={false}
              defaultValue={{ label: "ใหม่-เก่า", value: 1 }}
              onChange={(v) => {
                form.setValue('nSort', v ? v : null)
                OnGetData();

              }}

            />
          </Grid> */}


          <Grid
            container
             direction="row" 
            // justifyContent="start" 
            // alignItems="center"
            sx={{ paddingRight: { xs: "2em", lg: "4em" }, paddingLeft: { xs: "0", lg: "4em" }, justifyContent: PER_PAGE == arrCard.length ? "center" : "left" }}
          >
            {(arrCard || []).map((item) => {
              return (
                <Grid item xs={12} sm={6} md={4} lg={3} xl={2.4} sx={{ px: "1em !important" }}>
                  <Card sx={{
                    borderRadius: "0.5em", height: "455px",
                    position: 'relative',
                    margin: '8px 18px',
                    minWidth: "100%"
                  }}
                  >
                    <div style={{ padding: '8px' }}>
                      <CardMedia
                        component="img"
                        image={item.sURLImage || defaultImage}
                        alt=""
                        onError={({ currentTarget }) => {
                          currentTarget.onerror = null;
                          currentTarget.src = defaultImage;
                        }}
                        style={{ borderRadius: '0.5em', height: "250px" }}
                        className="zoom"
                      />
                    </div>
                    <div style={{ backgroundColor: "#4CAF50", width: '50px', height: '50px', borderRadius: '50%', position: 'absolute', top: '52%', left: '40%' }}>
                      <img
                        src={icon}
                        style={{ width: '30px', height: '30px', margin: '10px' }}
                      />
                    </div>

                    <CardContent style={{ padding: '8px' }}>
                      <div style={{ height: '20px', marginBottom: '8px', color: '#3997df', textAlign: 'right' }}>
                        <Typography gutterBottom variant="body2" component="div">
                          {item.nPic} ภาพ/Pictures
                        </Typography>
                      </div>
                      <div style={{ height: '60px', marginBottom: '8px' }}>
                        <Typography gutterBottom variant="subtitle2" component="div">
                          {item.sName.length > 80 ? item.sName.substring(0, 80) + "..." : item.sName}
                        </Typography>
                      </div>
                      <div style={{ height: '20px', marginBottom: '8px' }}>
                        <Typography variant="caption" color="text.secondary">
                          {item.dDateTH}
                        </Typography>
                      </div>
                    </CardContent>
                    <CardActions>
                      <BtnSeeDetail
                        fullWidth
                        style={{
                          fontSize: '12px'
                        }}
                        color="success"
                        txt="ดูอัลบั้ม/View Album"
                        onClick={() => goToAdd(item.sAlbumID)}
                      />
                    </CardActions>
                  </Card>
                </Grid>
              )
            })}
          </Grid>

          <NoDataUI isShow={showNodata} />
          <Grid
            container
            justifyContent="center"
            alignItems="center"
            sx={{ marginTop: "3%" }}
          >
            <Pagination
              count={count}
              page={Page}
              variant="outlined"
              shape="rounded"
              showFirstButton
              showLastButton
              onChange={handleChange}
            />
          </Grid>
          <Grid item xs={12} sx={{ marginTop: 10 }}>

          </Grid>

        </Grid>
      </FormProvider>
    </>
  );
};
export default EventPhoto;