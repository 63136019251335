import React, { Fragment, useState, MouseEvent, useEffect } from "react";
// import { withStyles } from "@material-ui/core/styles";
import { withStyles, makeStyles } from "@mui/styles";
import Menu, { MenuProps } from "@mui/material/Menu";
import Grid from "@mui/material/Grid";
import MenuItem from "@mui/material/MenuItem";
import { Button } from "@mui/material";
import Typography from "@mui/material/Typography";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import CloudUploadIcon from "@mui/icons-material/CloudUpload";
import DriveFolderUploadIcon from "@mui/icons-material/DriveFolderUpload";
import UploadFileIcon from "@mui/icons-material/UploadFile";
import axios from "axios";
import ItemListRow from "./Files/ItemListRow";
import ItemListCard from "./Files/ItemListCard";
// import { cFile } from "src/services/CommonInterface/CommonInterface";
import {
  Extension,
  IsNullOrUndefined,
} from "src/CommonFunction/TS_function";
import { DialogActionCreators } from "src/store/redux/DialogAlert";
import { useDispatch } from "react-redux";
import BlockUI1, { FnBlock_UI } from "src/Components/BlockUI";
import yupFormSchemas from "src/Components/FormItem/yup/yupFormSchemas";
import { AlertTitle, SwAlert } from "src/Components/Alert/Sweetalert";

interface cFile {
  nFile_ID: number;
  webkitRelativePath: string;
  sFolderName: string;
  sFileName: string;
  name: string;
  sSysFileName: string;
  nFileType_ID: number;
  size: number;
  nSize: number;
  sSizeName: string;
  type: string;
  dUploadBy: number;
  dUploadDate: Date;
  dSysncDate: Date;
  sPath: string;
  IsCompleted: boolean;
  IsProgress: boolean;
  lastModified: Date;
  lastModifiedDate: Date;
  IsNew: boolean;
  sInput1: string;
  sInput2: string;
  IsNewTab: boolean;
}


const StyledMenu = withStyles({
  paper: {
    marginTop: "20px",
    border: "1px solid #d3d4d5",
    // left: "87px !important",
    // top: "150px !important",
  },
})((props: MenuProps) => (
  <Menu
    elevation={0}
    // getContentAnchorEl={null}
    anchorOrigin={{
      vertical: "bottom",
      horizontal: "center",
    }}
    transformOrigin={{
      vertical: "top",
      horizontal: "center",
    }}
    {...props}
  />
));

const useStyles = makeStyles((theme) => ({
  DarkColor: {
    color: "#fff ",
    backgroundColor: "#212529",
    borderColor: "#212529",
    "&:hover": {
      backgroundColor: "rgb(84, 87, 89)",
    },
  },
  IConColor: {
    fill: "currentColor",
    width: "1em",
    height: "1em",
    display: "inline-block",
    fontSize: "1.5rem",
    transition: "fill 200ms cubic-bezier(0.4, 0, 0.2, 1) 0ms",
  },
}));

const StyledMenuItem = withStyles((theme) => ({
  root: {
    "&:focus": {
      backgroundColor: "#212529",
      "& .MuiListItemIcon-root, & .MuiListItemText-primary": {
        color: theme.palette.common.white,
      },
    },
  },
}))(MenuItem);

const UploadFile = (props) => {
  const dispatch = useDispatch();
  const { BlockUI, UnBlockUI } = FnBlock_UI();
  const [arrFileUpload, setarrFileUpload] = useState(
    props.arrFile as cFile | any
  );
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const [arrExtension, setarrExtension] = useState(
    props.Extension as any | any
  );
  const [nLimitFile, setnLimitFile] = useState(props.nLimitFile || 10);
  const [sLimitFile, setsLimitFile] = useState(props.sLimitFile || "MB");
  const [sExtension, setsExtension] = useState("");
  const [sAccept, setsAccept] = useState("");
  const handleClick = (event: MouseEvent<HTMLButtonElement>) => {
    props.IsMultiple === false || !props.IsFolder
      ? document
        .getElementById("contained-button-file" + props.keyID || "")?.click()
      : setAnchorEl(event.currentTarget);
  };
  const [IsFolder, setIsFolder] = useState(props.IsFolder || false);
  const [IsFile, setIsFile] = useState(props.IsFile || false);
  const [spercentageProgress, setpercentageProgress] = useState("");
  const [IsComplete, setIsComplete] = useState(false);

  const [arrMimeType, setarrMimeType] = useState<any>([]);
  const [arrSentFileComplete, setarrSentFileComplete] = useState<any>([]);
  const handleClose = () => {
    setAnchorEl(null);
  };
  let sPathApi =
    process.env.REACT_APP_API +
    "api/" +
    (props.apiPath ? props.apiPath : "UploadFileSevice/UploadFileToTemp");

  // const CheckMimeFileType = async (arrFile) => {
  //   //#region Check MIME FILE 2
  //   if (window.FileReader && window.Blob) {
  //     const loadMime = (file, callback) => {
  //       var mimes = [
  //         {
  //           mime: "image/jpeg",
  //           pattern: [0xff, 0xd8, 0xff],
  //           mask: [0xff, 0xff, 0xff],
  //         },
  //         {
  //           mime: "image/png",
  //           pattern: [0x89, 0x50, 0x4e, 0x47],
  //           mask: [0xff, 0xff, 0xff, 0xff],
  //         },
  //         {
  //           mime: "image/gif",
  //           pattern: [0x47, 0x49, 0x46, 0x38],
  //           mask: [0xff, 0xff, 0xff, 0xff],
  //         },
  //         {
  //           mime: "application/pdf",
  //           pattern: [0x25, 0x50, 0x44, 0x46],
  //           mask: [0xff, 0xff, 0xff, 0xff],
  //         },
  //         {
  //           mime: "video/avi",
  //           pattern: [0x52, 0x49, 0x46, 0x46], //+ 41 56 49 20
  //           mask: [0xff, 0xff, 0xff, 0xff],
  //         },
  //         {
  //           mime: "text/plain UTF-16BE BOM",
  //           pattern: [0xfe, 0xff, 0x00, 0x00],
  //           mask: [0xff, 0xff, 0x00, 0x00],
  //         },
  //         {
  //           mime: "text/plain UTF-16LE BOM",
  //           pattern: [0xff, 0xfe, 0x00, 0x00],
  //           mask: [0xff, 0xff, 0x00, 0x00],
  //         },
  //         {
  //           mime: "text/plain UTF-8 BOM",
  //           pattern: [0xef, 0xbb, 0xbf, 0x00],
  //           mask: [0xff, 0xff, 0xff, 0x00],
  //         },
  //       ];

  //       const check = (bytes, mime) => {
  //         for (var i = 0, l = mime.mask.length; i < l; ++i) {
  //           if ((bytes[i] & mime.mask[i]) - mime.pattern[i] !== 0) {
  //             return false;
  //           }
  //         }
  //         return true;
  //       };

  //       var blob = file.slice(0, 4); //read the first 4 bytes of the file

  //       var reader = new FileReader();
  //       reader.onloadend = function (e: any) {
  //         if (e.target.readyState === FileReader.DONE) {
  //           var bytes = new Uint8Array(e.target.result);
  //           for (var i = 0, l = mimes.length; i < l; ++i) {
  //             if (check(bytes, mimes[i])) return callback(mimes[i].mime);
  //           }

  //           return callback("unknown");
  //         }
  //       };
  //       reader.readAsArrayBuffer(blob);
  //     };

  //     arrFile.forEach((file) => {
  //       if (file.IsNew) {
  //         //New File
  //         loadMime(file, function (mime) {
  //           if (mime) {
  //             let arrMimeFileType = mime.split("/");
  //             if (
  //               arrMimeFileType.length > 0 &&
  //               arrExtension[0].indexOf(arrMimeFileType[1]) > -1
  //             ) {
  //               // ถูก Type
  //               let arrTemp:any = arrMimeType;
  //               arrTemp.push(true);
  //               setarrMimeType([...arrTemp]);
  //             } else {
  //               let arrTemp = arrMimeType;
  //               arrTemp.push(false);
  //               setarrMimeType([...arrTemp]);
  //               SwAlert.Warning(undefined, `Original file type not include in "${sExtension}" !`);
  //               return false;
  //             }
  //           }
  //         });
  //       } else {
  //         //Old File ไม่ต้องเช็ค Mime Type
  //         let arrTemp = arrMimeType;
  //         arrTemp.push(true);
  //         setarrMimeType([...arrTemp]);
  //       }
  //     });
  //   } else {
  //     console.log("Can't Check MIME Type");
  //   }

  //   //#endregion
  // };

  const SentFile = async (f) => {
    if (!f.IsComplete) {
      const formPayload = new FormData();
      f.IsProgress = true;
      f.sProgress = "0";
      setpercentageProgress("0");
      formPayload.append("file", f);
      if (props.dataID) {
        formPayload.append("dataID", props.dataID);
      }
      setIsComplete(false);
      try {
        BlockUI();
        let localJwtKey = process.env.REACT_APP_JWT_KEY as string;
        let auth_token =
          localStorage.getItem(localJwtKey) != null
            ? (localStorage.getItem(localJwtKey) as string)
            : undefined;
        await axios({
          url: sPathApi,
          method: "post",
          data: formPayload,
          headers: {
            "Content-Type": "multipart/form-data",
            Authorization: IsNullOrUndefined(auth_token)
              ? ""
              : `Bearer ${auth_token}`,
          },
          onUploadProgress: (progressEvent) => {
            const { loaded, total } = progressEvent;

            const percentageProgress = Math.floor((loaded / total) * 100);
            f.sProgress = percentageProgress + "";
            f.IsComplete = false;
            setpercentageProgress(percentageProgress + "");
            setIsComplete(false);
          },
        })
          .then(function (response) {
            UnBlockUI();
            f.IsProgress = false;
            f.IsComplete = response.data.IsCompleted;
            f.sFileLink = response.data.sFileLink;
            f.sRootURL = process.env.REACT_APP_API_URL;
            f.sFolderName = "";
            f.sFileName = response.data.sFileName;
            f.sSysFileName = response.data.sSaveToFileName;
            f.sInput1 = "";
            f.sInput2 = "";
            f.IsNewTab = false;
            f.sPath = response.data.sPath;
            f.sSaveToPath = response.data.sSaveToPath;
            f.sUrl = response.data.sUrl;
            f.nID = response.data.nID;
            setIsComplete(true);

            let arrComplete: any = arrSentFileComplete;
            arrComplete.push(true);
            setarrSentFileComplete([...arrComplete]);
          })
          .catch(function (error) {
            console.log("error : ", error);
            setIsComplete(false);
            f.IsProgress = false;
            f.IsComplete = false;
          });
      } catch (error) {
        console.log("error2 : " + error, f);
        f.IsProgress = false;
        f.IsComplete = false;
      }
    }

    return f;
  };

  const clearNotUploadFile = () => {
    //Delete arrFile
    let arrTemp = arrFileUpload.filter((f) => f.IsComplete === true);
    setarrFileUpload(arrTemp);
  };

  const handleClickFile = (arrFile) => {
    //ถ้าแนบไฟล์แบบ Image ให้เช็ค Original File ด้วย
    // if (ExtImage) {
    //   //Check Mime Type
    //   CheckMimeFileType(arrFile);
    // } else {
    //ถ้าไม่ใช่ไฟล์ภาพ ให้อัปโหลดเลย
    arrFileUpload.forEach((f) => {
      SentFile(f);
      setarrMimeType([]);
    });
    // }
  };

  useEffect(() => {
    if (
      arrMimeType.length > 0 &&
      arrFileUpload.length > 0 &&
      arrMimeType.length == arrFileUpload.length
    ) {
      if (arrMimeType.indexOf(false) == -1) {
        arrFileUpload.forEach((f) => {
          SentFile(f);
          setarrMimeType([]);
        });
      } else {
        // Delete file not upload
        clearNotUploadFile();
        setarrMimeType([]);
      }
    }
  }, [arrMimeType]);

  useEffect(() => {
    props.setarrFile([...arrFileUpload]);
  }, [IsComplete]);

  useEffect(() => {
    if (props.isFileChange) {
      setarrFileUpload(props.arrFile);
    } else {
      if (arrFileUpload.length == 0 && props.arrFile.length > 0) {
        setarrFileUpload([...props.arrFile]);
      }
    }
  }, [props.arrFile]);

  useEffect(() => {
    //Add Schema
    if (props.objSchema) {
      let arrTempPass = arrFileUpload.filter(
        // (f) => f.IsComplete === true && f.IsProgress === false
        (f) => f.IsComplete === true
      );
      if (arrTempPass.length > 0) {
        //Add Schema ใน Input ของ Card
        const setSchemaUploadFile = (sID) => {
          //Input 1
          if (props.sTextInput1) {
            props.objSchema["sInput1_" + sID] = yupFormSchemas.string(
              props.sTextInput1,
              {
                required: props.IsReqInput1,
              }
            );
          }
          //Input 2
          if (props.sTextInput2) {
            props.objSchema["sInput2_" + sID] = yupFormSchemas.string(
              props.sTextInput2,
              {
                required: props.IsReqInput2,
              }
            );
          }
        };

        if (arrTempPass.length > 0) {
          arrTempPass.forEach((item) => {
            setSchemaUploadFile(item.nFile_ID);
          });
        }
      }
    }
  });

  const handleClickReload = (nFile_ID) => {
    arrFileUpload.forEach((f) => {
      if (f.nFile_ID == nFile_ID) {
        return SentFile(f);
      }
    });
    props.setarrFile(arrFileUpload);
  };
  const handleClickDeleteFile = (nFile_ID) => {
    // console.log(nFile_ID)
    let arrNew = arrFileUpload.filter(w => w.nFile_ID != nFile_ID);
    setarrFileUpload([...arrNew]);

    // if (props.form) {
    //   props.form.unregister(`sInput1_${nFile_ID}`);
    //   props.form.unregister(`sInput2_${nFile_ID}`);
    // }
    // if (props.objSchema) {
    //   delete props.objSchema[`sInput1_${nFile_ID}`];
    //   delete props.objSchema[`sInput2_${nFile_ID}`];
    // }

    props.setarrFile(arrNew);
  };

  // useEffect(() => {
  //   props.setarrFile([...arrFileUpload]);
  // }, [arrFileUpload]);

  // useEffect(() => {
  //   props.setarrFile(
  //     arrFileUpload.filter(w => w.IsComplete == true )
  //   );
  // }, [arrSentFileComplete]);

  const classes = useStyles();
  const _addDirectory = (node) => {
    if (node) {
      node.webkitdirectory = true;
      node.directory = true;
    }
  };

  useEffect(() => {
    let arrTemp: any = [];
    let arrExt = [Extension as any];
    if (arrExtension === null || arrExtension === undefined) {
      let arrExtTemp = [];
      arrExt.map((f) => {
        for (var key in f) {
          let i = 0;
          for (var v in f[key]) {
            if (f[key][i] !== null && f[key][i] !== "") {
              arrTemp.push(f[key][i]);
            }
            i++;
          }
        }
      });
      arrTemp.push(arrExtTemp);
      setarrExtension([arrTemp]);
    } else {
      arrTemp = arrExtension;
      setarrExtension([arrTemp]);
    }
    let sFileType = "",
      sAcceptTemp = "";
    arrTemp.map((f, inx) => {
      if (f != "") {
        sFileType += (inx == 0 ? "." : " /.") + f;
        if (sAcceptTemp !== "") {
          sAcceptTemp += ", ";
        }
        sAcceptTemp += "." + f;
      }
    });
    setsExtension(sFileType);
    setsAccept(sAcceptTemp);
    if (props.nLimitFile != null && props.nLimitFile != undefined) {
      setnLimitFile(props.nLimitFile);
    }
  }, []);

  let IsOneLine = !props.IsMultiple && props.IsShowInOneLine;
  return (
    <Fragment>
      <Typography>
        <span
          style={{
            fontWeight: 400,
            fontSize: "1em",
            color: "rgba(0, 0, 0, 0.6)",
          }}
        >
          {" "}
          {props.sTitle || ""}{" "}
        </span>
        {props.IsRequired && <span style={{ color: "red" }}> *</span>}
        <span style={{ fontSize: "13px", color: "#B3B3B3" }}>
          {" "}
          {props.sSubTitle || ""}
        </span>
      </Typography>{" "}
      <Grid item container>
        <Grid
          item
          container
          justifyContent="flex-start"
          alignItems="center"
          md={IsOneLine ? 6 : 12}
          sm={12}
          xs={12}
          style={{ paddingTop: "0px" }}
        >
          <Grid item md={12} xs={12}>
            <Button
              aria-controls="customized-menu"
              aria-haspopup="true"
              variant="contained"
              className={classes.DarkColor}
              size="small"
              onClick={handleClick}
              startIcon={<CloudUploadIcon />}
              disabled={
                (props.IsMultiple != true && arrFileUpload.length > 0) ||
                props.disabled
              }
              sx={{
                paddingTop: "8px !important",
                paddingBottom: "8px !important",
                color: "#fff ",
                backgroundColor: "#00478f",
                borderColor: "#00478f",
                "&:hover": {
                  backgroundColor: "rgb(3, 90, 148)",
                },
              }}
            >
              {"อัพโหลดไฟล์"}
            </Button>
          </Grid>
          <Grid item md={12} xs={12}sx={{ paddingTop: "10px" }}>
            <div style={{ lineHeight: 1 }}>
              <Typography variant="caption" style={{ lineHeight: 1 }}>
                {"ประเภทไฟล์ที่อนุญาต"}: {sExtension}
              </Typography>
              <br />
              <Typography variant="caption" style={{ lineHeight: 1 }}>
                {"ขนาดไฟล์จำกัดสูงสุด"} {nLimitFile} {sLimitFile}.
              </Typography>
            </div>
          </Grid>
          <Grid item xs={12}>
            {props.sRemark && (
              <Typography variant="caption" style={{ color: "red" }}>
                {props.sRemark}
              </Typography>
            )}
          </Grid>
          <StyledMenu
            id="customized-menu"
            anchorEl={anchorEl}
            keepMounted
            open={Boolean(anchorEl)}
            onClose={handleClose}
          >
            {IsFolder ? (
              <StyledMenuItem>
                <input
                  id={"contained-button-file-folder" + props.keyID || ""}
                  name={"contained-button-file-folder" + props.keyID || ""}
                  onChange={(e) => {
                    let arrTemp = arrFileUpload as cFile | any;
                    //Check File Size
                    for (let i = 0; i < (e.target && e.target.files || [].length); i++) {
                      let nSize: any = e.target?.files && e.target?.files[i]?.size;
                      if (
                        parseInt(
                          (nSize / 1000000).toFixed(1)
                        ) > nLimitFile
                      ) {
                        e.preventDefault();
                        SwAlert.Warning(undefined, `File size limit up to ${nLimitFile} ${sLimitFile} !`);
                        return false;
                      }
                    }

                    for (let i = 0; i < (e.target && e.target.files || [].length); i++) {
                      const d = new Date();
                      let genID =
                        d.getMinutes() +
                        "" +
                        d.getSeconds() +
                        "" +
                        d.getMilliseconds() +
                        "";
                      let objFileAny: any = e.target?.files && e.target?.files[i]?.size;
                      let objFile = objFileAny as cFile | any;
                      objFile.sFileName = objFileAny.name;
                      objFile.nFile_ID = i + "_" + genID;
                      objFile.IsComplete = false;
                      objFile.IsProgress = true;
                      objFile.sSizeName =
                        (objFileAny / 1000000).toFixed(1) + "MB";
                      objFile.sFolderName =
                        objFile.webkitRelativePath != ""
                          ? objFile.webkitRelativePath.replace(
                            "/" + objFile.sFileName,
                            ""
                          )
                          : "";
                      objFile.sProgress = "0";
                      let arrfilename = (objFile.sFileName + "").split(".");
                      objFile.sFileType =
                        arrfilename[arrfilename.length - 1].toLowerCase();
                      objFile.IsNew = true;
                      arrTemp.push(objFile);
                    }

                    e.target.files = null;
                    setAnchorEl(null);

                    let IsPass = true,
                      IsPassName = true;
                    arrTemp.forEach((item) => {
                      if (arrExtension[0].indexOf(item.sFileType) < 0) {
                        IsPass = false;
                      }
                      let arrFileName = item.sFileName.split("_");
                      if (arrFileName.length > 2) {
                        if (arrFileName[0].toLowerCase() == "sessions") {
                          IsPassName = false;
                        }
                      } else {
                        IsPassName = false;
                      }
                    });
                    if (!IsPass) {
                      // arrTemp.length = 0;
                      clearNotUploadFile();
                      e.preventDefault();
                      SwAlert.Warning(undefined, `ประเภทไฟล์ที่อนุญาต ${sExtension} ขนาดไฟล์จำกัดสูงสุด ${nLimitFile} ${sLimitFile} `);
                    } else {
                      setarrFileUpload(arrTemp);
                      handleClickFile(arrTemp);
                    }
                  }}
                  onClick={(e: any) => {
                    e.target.value = "";
                  }}
                  ref={(node) => _addDirectory(node)}
                  // webkitdirectory=""
                  //  modirectory
                  // directory
                  multiple
                  type="file"
                  hidden
                  accept={sAccept}
                />
                <label
                  htmlFor={"contained-button-file-folder" + props.keyID || ""}
                  style={{
                    width: "100%",
                    display: "contents",
                    cursor: "pointer",
                  }}
                >
                  <ListItemIcon style={{ cursor: "pointer" }}>
                    <DriveFolderUploadIcon
                      // icon={outlineDriveFolderUpload}
                      className={classes.IConColor}
                    />
                  </ListItemIcon>
                  <ListItemText
                    primary={"โฟลเดอร์ อัพโหลด"}
                    style={{ cursor: "pointer" }}
                  />
                  {/* <Button variant="contained" component="span" hidden>
                Upload
              </Button> */}
                </label>
              </StyledMenuItem>
            ) : null}
            {IsFile &&
              (props.IsMultiple ||
                (props.IsMultiple != true && arrFileUpload.length == 0)) ? (
              <StyledMenuItem>
                <input
                  id={"contained-button-file" + props.keyID || ""}
                  name={"contained-button-file" + props.keyID || ""}
                  multiple={props.IsMultiple}
                  type="file"
                  hidden
                  onChange={(e) => {
                    let arrTemp = arrFileUpload as cFile | any;
                    let arrNewFile = [] as cFile | any;

                    //Check File Size
                    for (let i = 0; i < ((e.target && e.target.files || []).length); i++) {
                      let nSizeFileType = 1000000;
                      let nSize: any = e.target?.files && e.target?.files[i]?.size;
                      if (sLimitFile == "GB") {
                        nSizeFileType = 1000000000;
                      }
                      if (
                        parseInt(
                          (nSize / nSizeFileType).toFixed(1)
                        ) > nLimitFile
                      ) {
                        e.preventDefault();
                        // SwAlert.Warning(undefined, ".jpg/.png/.gif/.docx/.xlsx/.pdf/.rar/.zip",()=>{},`ขนาดไฟล์จำกัดสูงสุด  ${nLimitFile} ${sLimitFile}`);
                        SwAlert.Warning(undefined, `ประเภทไฟล์ที่อนุญาต ${sExtension} ขนาดไฟล์จำกัดสูงสุด ${nLimitFile} ${sLimitFile} `);

                        return false;
                      }
                    }
                    for (let i = 0; i < ((e.target && e.target.files || []).length); i++) {
                      const d = new Date();
                      let genID =
                        d.getMinutes() +
                        "" +
                        d.getSeconds() +
                        "" +
                        d.getMilliseconds() +
                        "";
                      // let objFileAny:any = e.target?.files && e.target?.files[i]?.size ;
                      let objFile = e.target.files![i] as cFile | any;
                      objFile.sFileName = e.target.files![i].name;
                      objFile.nFile_ID = i + "_" + genID;
                      objFile.IsComplete = false;
                      objFile.IsProgress = true;
                      let nSizeFileType = 1000000;
                      if (sLimitFile == "GB") {
                        nSizeFileType = 1000000000;
                      }
                      objFile.sSizeName =
                        (e.target.files![i].size / nSizeFileType).toFixed(1) +
                        "MB";
                      objFile.sFolderName =
                        objFile.webkitRelativePath != ""
                          ? objFile.webkitRelativePath.replace(
                            "/" + objFile.sFileName,
                            ""
                          )
                          : "";
                      objFile.sProgress = "0";
                      let arrfilename = (objFile.sFileName + "").split(".");
                      objFile.sFileType =
                        arrfilename[arrfilename.length - 1].toLowerCase();
                      objFile.IsNew = true;
                      arrTemp.push(objFile);
                      arrNewFile.push(objFile);
                    }
                    e.target.files = null;
                    setAnchorEl(null);
                    let IsPass = true,
                      IsPassName = true,
                      IsPassNameOthre = true;

                    arrTemp.forEach((item) => {
                      if (arrExtension[0].indexOf(item.sFileType) < 0) {
                        IsPass = false;
                      }

                      let arrFileName = item.sFileName.split("_");
                      if (!IsNullOrUndefined(props.sFileName)) {
                        if (arrFileName.length > 2) {
                          if (!IsNullOrUndefined(props.sFileName)) {
                            if (
                              arrFileName[0].toLowerCase() !=
                              props.sFileName.toLowerCase()
                            ) {
                              IsPassName = false;
                            }
                          } else {
                            if (arrFileName[0].toLowerCase() == "sessions") {
                              IsPassNameOthre = false;
                            }
                          }
                        } else {
                          IsPassName = false;
                        }
                      } else {
                        if (arrFileName[0].toLowerCase() == "sessions") {
                          IsPassNameOthre = false;
                        }
                      }
                    });
                    if (!IsPass) {
                      // arrTemp.length = 0;
                      clearNotUploadFile();
                      e.preventDefault();
                      SwAlert.Warning(undefined, `ประเภทไฟล์ที่อนุญาต ${sExtension} ขนาดไฟล์จำกัดสูงสุด ${nLimitFile} ${sLimitFile} `);
                    } else {
                      if (!IsPassName && !IsNullOrUndefined(props.sFileName)) {
                        // arrTemp.length = 0;
                        clearNotUploadFile();
                        e.preventDefault();
                        SwAlert.Warning(undefined, `Upload file name ` + props.sFileName + ` only.`);
                      } else {
                        if (IsNullOrUndefined(props.sFileName)) {
                          if (!IsPassNameOthre) {
                            // arrTemp.length = 0;
                            clearNotUploadFile();
                            e.preventDefault();
                            SwAlert.Warning(undefined, `Upload can't file name sessions.`);
                          } else {
                            setarrFileUpload(arrTemp);
                            handleClickFile(arrTemp);
                          }
                        } else {
                          setarrFileUpload(arrTemp);
                          handleClickFile(arrTemp);
                        }
                      }
                    }
                  }}
                  onClick={(e: any) => {
                    e.target.value = "";
                  }}
                  accept={sAccept}
                />
                <label
                  htmlFor={"contained-button-file" + props.keyID || ""}
                  style={{
                    width: "100%",
                    display: "contents",
                    cursor: "pointer",
                  }}
                >
                  <ListItemIcon style={{ cursor: "pointer" }}>
                    <UploadFileIcon className={classes.IConColor} />
                  </ListItemIcon>
                  <ListItemText
                    primary={"ไฟล์ อัพโหลด"}
                    style={{ cursor: "pointer" }}
                  />
                </label>
              </StyledMenuItem>
            ) : null}
          </StyledMenu>
        </Grid>
        <Grid item container md={IsOneLine ? 6 : 12} sm={12} xs={12} sx={{ mt: "1em" }}>
          {props.sTextInput1 || props.sTextInput2 || props.isColumnMode ? (
            <>
              {props.cComponentBeforeListItemCard ? (
                <props.cComponentBeforeListItemCard />
              ) : null}
              <ItemListCard
                arrFile={arrFileUpload}
                SetarrFile={setarrFileUpload}
                onDelete={handleClickDeleteFile}
                onLoad={handleClickReload}
                IsCanDel={
                  props.IsCanDel !== undefined && props.IsCanDel !== null
                    ? props.IsCanDel
                    : true
                }
                sTextInput1={props.sTextInput1}
                IsReqInput1={props.IsReqInput1}
                sTextInput2={props.sTextInput2}
                IsReqInput2={props.IsReqInput2}
                IsSelectNewTab={props.IsSelectNewTab}
                IsDrag={props.IsDrag || false}
                form={props.form}
                disabled={props.disabled}
              />
            </>
          ) : (
            <ItemListRow
              arrFile={arrFileUpload}
              SetarrFile={setarrFileUpload}
              onDelete={handleClickDeleteFile}
              onLoad={handleClickReload}
              IsCanDel={
                props.IsCanDel !== undefined && props.IsCanDel !== null
                  ? props.IsCanDel
                  : true
              }
              IsDrag={props.IsDrag || false}
              disabled={props.disabled}
              IsOneLine={IsOneLine}
              IsCanRename={props.IsCanRename}
              ArrFileRename={props.arrFile}
              SetarrFileRename={props.setarrFile}
              onOpenFile={props.onOpenFile}
              IsHiddenUploadBox={props.IsHiddenUploadBox}
              setStartVideoOn={props.setStartVideoOn}
              nStartVideoOn={props.nStartVideoOn}
              CannotSkipForward={props.CannotSkipForward}
              onVideoEnd={props.onVideoEnd}
            />
          )}
        </Grid>
      </Grid>
    </Fragment>
  );
};
export default UploadFile;
