import React, { useState } from 'react'
import { NoDataUI } from 'src/Components/NoData';
import { DatePickerUI } from 'src/Components/FormItem/DatePicker';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { DateRangePicker, DateRange, LicenseInfo } from '@mui/x-date-pickers-pro';
import addWeeks from 'date-fns/addWeeks';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { Box, TextField } from '@mui/material';
import { Controller, useFormContext } from 'react-hook-form';
import moment from 'moment';

LicenseInfo.setLicenseKey(
    "5b31a40e910ea0c2a35371ae57c5b5beT1JERVI6MzYzMjEsRVhQSVJZPTE2NzQyOTE1NTAwMDAsS0VZVkVSU0lPTj0x"
);

function getWeeksAfter(date: Date | null, amount: number) {
    return date ? addWeeks(date, amount) : undefined;
}


export const DateRangePickerUI = (props: DateRangePickerUI) => {
    const {
        name,
        minDate = null,
        maxDate = null,
        required = false,
        textCenter = " - ",
        disablePast = false,
        disabled = false,
        disableFuture = false,
        disableOpenPicker = false,
        startText = "วันที่เริ่มต้น",
        endText = "วันที่สิ้นสุด",
        format = "dd/MM/yyyy",
    } = props;
    const [values, setValues] = useState<DateRange<Date>>([null, null]);

    const {
        control,
        register,
        getValues,
        setValue,
        watch,
        formState: { touchedFields, errors, isSubmitted },
    } = useFormContext();

    return (
        <Controller
            {...register(name)}

            control={control}
            name={name}
            rules={{ required: required }}
            render={({ field }) => (
                <Box sx={{m:1}}>
                    <LocalizationProvider dateAdapter={AdapterDateFns}>
                        <DateRangePicker
                            disabled={disabled}
                            disablePast={disablePast}
                            value={values}
                            maxDate={maxDate}
                            minDate={minDate}
                            inputFormat={format}
                            disableFuture={disableFuture}
                            disableOpenPicker={disableOpenPicker}
                            startText={startText}
                            endText={endText}
                            onChange={(e, v) => {
                                field.onChange(e, v);
                                setValue(name, e);
                                setValues(e)
                                props.onChange && props.onChange(e);
                            }}
                            renderInput={(startProps, endProps) => (
                                <>
                                    <TextField size={"small"} {...startProps} />
                                    <Box sx={{ mx: 2 }}> {textCenter} </Box>
                                    <TextField size={"small"} {...endProps} />
                                </>
                            )}
                        />
                    </LocalizationProvider>
                </Box>
            )}
        />
    )
}

interface DateRangePickerUI {
    maxDate?: any;
    minDate?: any;
    required?: boolean;
    name: string;
    onChange?: (value: any) => void;
    format?: string;
    textCenter?: string;
    disablePast?: boolean;
    disabled?: boolean;
    disableFuture?: boolean;
    disableOpenPicker?: boolean;
    startText?: string;
    endText?: string;
}