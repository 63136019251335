import { yupResolver } from '@hookform/resolvers/yup';
import { Box, Divider, Grid, Pagination, Skeleton, TextField, Typography } from '@mui/material';
import React, { useEffect, useState } from 'react'
import { FormProvider, useForm } from 'react-hook-form'
import { Banner } from 'src/Components/Banner';
import { FnBlock_UI } from 'src/Components/BlockUI';
import { usePagination } from 'src/Components/PaginationUI/Pagination';
import * as yup from "yup";
import imgCover from "src/Layout/img/course-cover.jpg";
import { Axios } from 'src/CommonFunction/TS_Axios';

import './CourseList.css'
import { NoDataUI } from 'src/Components/NoData';
import { DatePickerUI } from 'src/Components/FormItem/DatePicker';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { DateRangePicker, DateRange, LicenseInfo } from '@mui/x-date-pickers-pro';
import addWeeks from 'date-fns/addWeeks';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { DateRangePickerUI } from 'src/Components/FormItem/DateRangePicker';
import { BtnBack, BtnCustomText, BtnDetail, BtnCustomSearch } from 'src/Components/Button';
import { InputForm } from 'src/Components/FormItem/Input';
import { FaFilter, FaLock } from 'react-icons/fa';
import { AccordionUI } from 'src/Components/AccordionUI';
import { CheckBoxUI } from 'src/Components/FormItem/CheckBox';
import { SelectForm } from 'src/Components/FormItem/Select';
import { MdLocationPin } from 'react-icons/md';
import { BsFlagFill } from 'react-icons/bs';
import { DecryptApi, ParseHtml, IsNullOrUndefined } from 'src/CommonFunction/TS_function';
import icon2 from "../../Layout/img/ico-course-2.png";
import icon4 from "../../Layout/img/ico-course-4.png";
import defaultImage from "src/Layout/img/course-1.jpg";
import { BiCalendar } from 'react-icons/bi';
import { BreadcrumbsUI } from 'src/Components/BreadcrumbsUI';
import { useNavigate } from 'react-router';
import { ClassroomDetail } from 'src/Components/System/ClassroomModal/ClassroomDetail';
import moment from "moment";
import AutoCompleteSelect from "src/Components/FormItem/AutoCompleteSelect";
import { GetQueryString } from "src/CommonFunction/TS_function";
import { MenuBar } from 'src/Components/System/NavBar/MenuBar';
import { CgChevronDoubleRightO } from 'react-icons/cg';
import { AlertTitle, SwAlert } from 'src/Components/Alert/Sweetalert';
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import CardMedia from "@mui/material/CardMedia";
import { CardActionArea, CardActions } from "@mui/material";
import IconButton from "@mui/material/IconButton";
import CalendarMonthIcon from "@mui/icons-material/CalendarMonth";
import LocationOnIcon from "@mui/icons-material/LocationOn";
import FlagIcon from "@mui/icons-material/Flag";
LicenseInfo.setLicenseKey(
    "5b31a40e910ea0c2a35371ae57c5b5beT1JERVI6MzYzMjEsRVhQSVJZPTE2NzQyOTE1NTAwMDAsS0VZVkVSU0lPTj0x"
);

function getWeeksAfter(date: Date | null, amount: number) {
    return date ? addWeeks(date, amount) : undefined;
}

const CourseList = () => {
    const [showNodata, setShowNodata] = useState(false);
    const { BlockUI, UnBlockUI } = FnBlock_UI();
    const [lstCourse, setlstCourse] = useState([]) as any;
    const [lstCategory, setlstCategory] = useState([]) as any;
    const [banner, setBanner] = useState([] || undefined);
    const [StartDate, setStartDate] = useState(undefined);
    const [EndDate, setEndDate] = useState(undefined);
    const [lstLanguage, setAfterCheck] = useState([]) as any;
    const [MenunameTH, setMenunameTH] = useState("");
    const [MenunameENG, setMenunameENG] = useState("");
    const [isClear, setClear] = useState(false);
    const [lstInstructor, setInstructor] = useState([]) as any;
    const navigate = useNavigate();
    const sContentID = GetQueryString('str');
    const isopen = GetQueryString('isopen');
    let [page, setPage] = useState(1);
    const [sDecryptID, setDecryptID] = useState("");
    const PER_PAGE = 9;
    const [isShowExpand, setShowExpand] = useState(true);
    const [nWidth, setWidth] = useState(window.innerWidth);

    const objSchema = {};
    const schema = yup.object().shape(objSchema);
    const form = useForm({
        mode: "all",
        resolver: yupResolver(schema),
        shouldFocusError: true,
    });
    const Sort = [
        { label: "ใหม่-เก่า", value: 1 },
        { label: "เก่า-ใหม่", value: 2 },
    ];

    useEffect(() => {

        DecryptID();
        setData();
    }, []);
    const DecryptID = async () => {
        let sDecryptID = await DecryptApi(sContentID);
        setDecryptID(sDecryptID);
    }
    useEffect(() => {
        handleChange(null, 1);
    }, [lstCourse]);
    useEffect(() => {

        if (nWidth >= 600) {
            setShowExpand(true);
        } else {
            setShowExpand(false);
        }
    }, [nWidth]);
    useEffect(() => {
        form.setValue("nSort", Sort[0]);
        window.addEventListener("resize", updateDimensions);
        return () => window.removeEventListener("resize", updateDimensions);
    }, []);
    const updateDimensions = () => {
        setWidth(window.innerWidth);
    }
    const GetInstructor = async () => {
        let result = [] as any;
        await Axios(`Course/GetInstructor`, {}, "Get", async (res) => {
            result = res;

        },
            null,
            BlockUI,
            UnBlockUI,
            () => { }
        );
        return result;
    }
    const handleChangeLanguage = async (event) => {
        await setAfterCheck(event);

    };
    const setData = async () => {
        var sStartDate = moment(form.getValues("dStart"), "DD/MM").format("DD/MM/YYYY HH:mm");
        var sEndDate = moment(form.getValues("dEnd"), "DD/MM").format("DD/MM/YYYY HH:mm");
        let param = {
            sSearch: form.getValues("sSearch") || "",
            sStartSort: sStartDate || null,
            sEndSort: sEndDate || null,
            nSort: form.getValues("nSort") || 1,
            slanguage: form.getValues("slanguage") || [],
            sClass: form.getValues("sClass") || [],
            lstSearchCategory: form.getValues("sCategory") || [],
            lstInstructor: form.getValues("lstInstructor") || [],
        }
        let resBanner = await loadBanner();
        let res = await loadCourse(param);
        let resNav = await loadNavBar();
        let resInstructor = await GetInstructor();
        setMenunameTH(resNav.menuNameTH || "");
        setMenunameENG(resNav.menuNameENG || "");
        setBanner(resBanner || []);
        setlstCourse(res.lstCourse || []);
        setlstCategory(res.lstCategory || []);
        setInstructor(resInstructor || []);
    };

    const loadCourse = async (param) => {
        let result = null as any;
        param ? {} : param;
        await Axios(
            // `Course/GetData_CoursewareList`,
            `Course/GetCourseList`,
            param || {},
            "Post",
            async (res) => {
                if (res.Status !== "Error") {
                    result = res;
                    if (res.lstCourse === null || res.lstCourse.length === 0) {
                        setShowNodata(true);
                    } else {
                        setShowNodata(false);
                    }
                }
            },
            null,
            BlockUI,
            UnBlockUI,
            () => { }
        );
        return result;
    };

    const loadBanner = async () => {
        let result = [] as any;
        result.push({
            sPath: imgCover,
        });
        return result;
    };

    const getEncrypt = async (param) => {
        let result = null as any;
        await Axios(
            `Master/Encrypt`,
            "sValue=" + param,
            "Get",
            async (res) => {
                result = res;
            },
            null,
            BlockUI,
            UnBlockUI,
            () => { }
        );
        return result;
    };

    const loadNavBar = async () => {
        let result = {} as any;
        let param = "sContentID=" + sContentID;
        await Axios(`Master/GetNamePage`, param, "Get", async (res) => {
            result = res;
        },
            null,
            null,
            null,
            () => { }
        );
        return result;
    };

    const count = Math.ceil((lstCourse || []).length / PER_PAGE);
    const _DATA = usePagination(lstCourse || [], PER_PAGE);
    const handleChange = (e, p) => {
        setPage(p);
        _DATA.jump(p);
        window[`scrollTo`]({ top: 880, behavior: `smooth` });
    };
    const onClickSearch = async () => {
        var sStartDate = moment(form.getValues("dStart"), "DD/MM").format("DD/MM/YYYY HH:mm");
        var sEndDate = moment(form.getValues("dEnd"), "DD/MM").format("DD/MM/YYYY HH:mm");
        let param = {
            sSearch: form.getValues("sSearch") || "",
            sStartSort: sStartDate || "",
            sEndSort: sEndDate || "",
            nSort: form.getValues("nSort") != undefined ? form.getValues("nSort").value : 1,
            slanguage: form.getValues("slanguage") || [],
            sClass: form.getValues("sClass") || [],
            lstSearchCategory: form.getValues("sCategory") || [],
            lstInstructor: form.getValues("lstInstructor") || [],
        }
        let resCourse = await loadCourse(param);
        setlstCourse(resCourse.lstCourse || []);
    }
    const onClickClear = () => {
        setClear(true);
        form.setValue("dateRange", []);
        form.setValue("sSearch", "");
        form.setValue("nSort", Sort[0]);
        form.setValue("slanguage", []);
        form.setValue("sClass", []);
        form.setValue("sCategory", []);
        form.setValue("lstInstructor", []);

    }

    const SetOpen = () => {
        if (isShowExpand) {
            setShowExpand(false);
        } else {
            setShowExpand(true);
        }

    }

    const onChangeSelect = async () => {
        let date = form.getValues("dateRange");
        let param = {
            sSearch: form.getValues("sSearch") || "",
            sStartSort: date ? date[0] : "",
            sEndSort: date ? date[1] : "",
            nSort: form.getValues("nSort"),
            slanguage: form.getValues("slanguage") || [],
            sClass: form.getValues("sClass") || [],
            lstSearchCategory: form.getValues("sCategory") || [],
            lstInstructor: form.getValues("lstInstructor") || [],
        };
        let resCourse = await loadCourse(param);
        setlstCourse(resCourse.lstCourse);
    }

    const onClickDetail = async (e) => {
        // let str = await getEncrypt(e.nID);//e.nID
        // let c = await getEncrypt(e.sCategoryID);//e.sCategoryID
        
        if (!e.isPublic && e.nCourseType === 1 && e.isExpire) {
            SwAlert.Warning(AlertTitle.Warning, "โปรด Login (เข้าสู่ระบบ) ก่อนเข้าเรียนหลักสูตรของ OR Academy ", () => { }, " หากท่านต้องการสมัครสมาชิกติดต่อที่ oracademy@pttor.com หรือ โทร. 02-196-6363")
        } else {
            navigate(process.env.REACT_APP_API_URL + "OnlineDetail?sID=" + e.sID + "&c=" + e.sCategoryID + "&str=" + sContentID)
        }
    }

    return (
        <FormProvider {...form}>
            {/* <form> */}
            <Grid container>
                <Grid item xs={12}>
                    <Banner
                        dataSource={banner || []}
                        height={50}
                        textTH={MenunameTH}
                        textENG={MenunameENG}
                    />
                </Grid>
                <Grid item xs={12}>

                    <MenuBar sContentID={sContentID} />
                </Grid>




                <Grid container sx={{ m: "1em" }}>
                    <Grid container flexWrap={"wrap"} sx={{ px: 0, marginTop: 1 }}>
                        <Grid item xs={12} sx={{ mb: "1em", display: "flex", justifyContent: { xs: "center", lg: "flex-end" }, flexDirection: { xs: "column", lg: "row" } }}>

                            <Grid item xs={24} sm={24} md={24} lg={2.5} sx={{ px: { lg: "0.5em" }, maxWidth: "100% !important", mt: "0.5em", paddingTop: "0.5em" }}>
                                <DatePickerUI
                                    label="วันที่เริ่ม"
                                    name="sStart"
                                    small
                                    fullWidth
                                    onChange={(e) => setStartDate(e)}
                                    maxDate={EndDate ? EndDate : undefined}
                                    onClearFields={(e) => setStartDate(undefined)}
                                />
                            </Grid>
                            <Grid item xs={24} sm={24} md={24} lg={2.5} sx={{ px: { lg: "0.5em" }, maxWidth: "100% !important", mt: "0.5em", paddingTop: "0.5em" }}>
                                <DatePickerUI
                                    label="ถึงวันที่"
                                    name="sEnd"
                                    small
                                    fullWidth
                                    onChange={(e) => setEndDate(e)}
                                    minDate={StartDate ? StartDate : undefined}
                                    onClearFields={(e) => setEndDate(undefined)}
                                />
                            </Grid>

                            <Grid item xs={12} sm={12} md={7.5} lg={7.5} sx={{ px: { lg: "0.5em" }, maxWidth: "100% !important", paddingTop: "0.5em" }}>
                                <InputForm
                                    name="sSearch"
                                    label="ค้นหาจากรหัสหลักสูตร, ชื่อหลักสูตร, คำอธิบายหลักสูตร, คำสำคัญ, ชื่อวิทยากร"
                                    fullWidth
                                    onKeyPress={onClickSearch}
                                    mx={0}
                                />
                            </Grid>

                            <Grid item xs={12} sm={12} md={1.5} lg={1.5} sx={{ px: { lg: "0.5em" }, maxWidth: "100% !important", paddingTop: "0.5em" }}>
                                <AutoCompleteSelect
                                    mx={0}
                                    my={1}
                                    required
                                    name="nSort"
                                    label={"เรียงโดย"}
                                    fullWidth
                                    width={{ width: "100%" }}
                                    options={Sort}
                                    IsShowIcon={false}
                                    defaultValue={Sort[0]}
                                    onChange={(v) => {
                                        form.setValue('nSort', v ? v : null)
                                        onClickSearch();
                                    }}
                                />
                            </Grid>



                            <Grid item sx={{ display: "flex", justifyContent: "center", paddingTop: "0.5em" }}>
                                <BtnCustomText
                                    txt='ค้นหา/Search'
                                    onClick={onClickSearch}

                                />
                            </Grid>


                        </Grid>
                    </Grid>

                    {/* <Grid item xs={12} sx={{ display: "flex", flexDirection: { xs: "column", md: "row" } }}>
                            <Grid item xs={12} md={4} lg={3} sx={{ display: "flex" }}>
                                <Grid item xs={12} md={6} sx={{ marginTop: 1, px: { xs: "0px", md: "0.3em" } }}>
                                    <DatePickerUI
                                        label="วันที่เริ่ม"
                                        name="dStart"
                                        small
                                        onChange={(e) => setStartDate(e)}
                                        maxDate={EndDate ? EndDate : undefined}
                                        onClearFields={(e) => setStartDate(undefined)}
                                    />
                                </Grid>
                                <Grid item xs={12} md={6} sx={{ marginTop: 1, px: { xs: "0px", md: "0.3em" } }}>
                                    <DatePickerUI
                                        label="ถึงวันที่"
                                        name="dEnd"
                                        small
                                        onChange={(e) => setEndDate(e)}
                                        minDate={StartDate ? StartDate : undefined}
                                        onClearFields={() => setEndDate(undefined)}
                                    />
                                </Grid>
                            </Grid>
                            <Grid item xs={12} md={8} lg={9} sx={{ display: "flex", flexDirection: { xs: "column", md: "row" } }}>
                                <Grid item xs={12} md={9} lg={10.5}>
                                    <InputForm
                                        name="sSearch"
                                        label="ค้นหาจากรหัสหลักสูตร, ชื่อหลักสูตร, คำอธิบายหลักสูตร, คำสำคัญ, ชื่อวิทยากร"
                                        fullWidth
                                        mx={0}
                                        onKeyPress={onClickSearch}
                                    />
                                </Grid>
                                <Grid item xs={12} md={3} lg={1.5} sx={{ display: "flex", justifyContent: "flex-end" }}>
                                    <BtnCustomText
                                        txt='ค้นหา/Search'
                                        onClick={onClickSearch}
                                        width="130px"
                                    />

                                </Grid>
                            </Grid>
                        </Grid> */}


                    <Grid item xs={12} md={4} lg={3} >
                        <Grid item xs={12} sx={{ m: 2, display: "flex", justifyContent: "space-between", height: "56px" }}>

                            <Typography sx={{
                                color: "rgb(57, 151, 223)", cursor: "pointer", display: "flex", alignItems: "center",
                                // ":hover":{color:"red"},
                                ":active": {
                                    color: "rgba(57, 151, 223,0.5)"
                                }
                            }} onClick={SetOpen}
                            //onClick={() => { setShowExpand(!isShowExpand) }}
                            >
                                <FaFilter />
                                ตัวกรอง/Filter
                            </Typography>
                            {/* <Typography sx={{ display: "flex", alignItems: "center" }}onClick={onClickClear}>
                                    <FaFilter />
                                    ตัวกรอง/Filter
                                </Typography> */}

                            <Typography sx={{
                                color: "rgb(57, 151, 223)", cursor: "pointer", display: "flex", alignItems: "center",
                                // ":hover":{color:"red"},
                                ":active": {
                                    color: "rgba(57, 151, 223,0.5)"
                                }
                            }} onClick={onClickClear}>
                                Clear Filter
                            </Typography>
                        </Grid>

                        <Grid item xs={12} sx={{ mx: 1 }} >
                            <AccordionUI
                                headerName="ภาษา / Program"
                                defaultExpand
                                isExpaned={isShowExpand}
                            >
                                <CheckBoxUI
                                    name="slanguage"
                                    type="group"
                                    options={[
                                        { label: "ภาษาไทย/Thai Program", value: "TH" },
                                        { label: "ภาษาอังกฤษ/English Program", value: "EN" },
                                    ]}
                                    isClear={isClear}
                                    onChange={(e) => {
                                        setClear(false);
                                        // handleChangeLanguage(e) 
                                    }
                                    }
                                />
                            </AccordionUI>
                        </Grid>
                        <Grid item xs={12} sx={{ mx: 1 }} >
                            <AccordionUI
                                headerName="รูปแบบการอบรม/Learning Methods "
                                defaultExpand
                                isExpaned={isShowExpand}
                            >
                                <CheckBoxUI
                                    name="sClass"
                                    type="group"
                                    isClear={isClear}
                                    options={[
                                        { label: "หลักสูตร Classroom/Onsite Training", value: "2" },
                                        { label: "หลักสูตร Online/Online Training", value: "1" },
                                    ]}
                                    onChange={() =>
                                        setClear(false)
                                        //onClickSearch()
                                    }
                                />
                            </AccordionUI>
                        </Grid>
                        <Grid item xs={12} sx={{ mx: 1 }} >
                            <AccordionUI
                                headerName="วิทยากร/Instructor"
                                defaultExpand
                                isExpaned={isShowExpand}
                            >
                                <CheckBoxUI
                                    name="lstInstructor"
                                    type="group"
                                    isClear={isClear}
                                    options={lstInstructor}
                                    onChange={() =>
                                        setClear(false)
                                        //onClickSearch()
                                    }
                                />
                            </AccordionUI>
                        </Grid>
                        <Grid item xs={12} sx={{ mx: 1 }}  >
                            <AccordionUI
                                headerName="หมวดหมู่/Category"
                                defaultExpand
                                isExpaned={isShowExpand}
                            >
                                <CheckBoxUI
                                    name="sCategory"
                                    type="group"
                                    AllowCheckAll
                                    options={lstCategory}
                                    isClear={isClear}
                                    onChange={() =>
                                        setClear(false)
                                        // onClickSearch()
                                    }
                                />
                            </AccordionUI>
                        </Grid>
                    </Grid>

                    <Grid item xs={12} md={8} lg={9}>
                        <Grid item xs={12} sx={{
                            display: "flex", alignItems: "center", mx: 1, py: "12px", marginTop: "16px",
                            justifyContent: "space-between", borderBottom: "1px solid rgba(0,0,0,0.3)"
                        }}>
                            <Grid item xs={12} md={12} sx={{ display: "flex" }}>
                                <Typography >
                                    จำนวน/Total: {lstCourse !== null ? lstCourse.length : 0} หลักสูตร/Course
                                </Typography>
                            </Grid>

                        </Grid>
                        <Grid
                            item
                            xs={12}
                            sx={{ display: "flex", flexWrap: "wrap", m: 1 }}
                        >
                            <NoDataUI isShow={showNodata} />
                            {/* {_DATA.currentData().map(
                                m => (
                                    <>
                                       
                                        <Grid item xs={12} sm={6} lg={4} sx={{ px: { xs: "1em", lg: "2.5em" }, py: { xs: "2em", lg: "2.5em" } }}>
                                            {!m.isPublic && m.nCourseType === 1 && m.isExpire &&
                                                <a style={{
                                                    backgroundColor: "#FF0000",
                                                    position: "absolute",
                                                    zIndex: 1000,
                                                    borderTopLeftRadius: "0.5em",
                                                    borderTopRightRadius: "0%",
                                                    borderBottomLeftRadius: "0%",
                                                    borderBottomRightRadius: "30%",
                                                    width: "40px",
                                                    height: "40px",
                                                    display: "flex",
                                                    justifyContent: "center",
                                                    alignItems: "center"
                                                }}>
                                                    <FaLock style={{ color: "#ffffff" }} size={30} />
                                                </a>
                                            }
                                            <div className="courseList">
                                                <div className="img-hover-zoom-List">
                                                    <img
                                                        src={m.sPath || defaultImage}
                                                        onError={({ currentTarget }) => {
                                                            currentTarget.onerror = null;
                                                            currentTarget.src = defaultImage;
                                                        }}
                                                    />
                                                </div>

                                                <footer>
                                                    {m.nCourseType === 1 ? (
                                                        <a style={{ backgroundColor: "#1976d2" }}>
                                                            <img
                                                                src={icon2}
                                                                style={{ width: 35, height: 25 }}
                                                            />
                                                        </a>
                                                    ) : (
                                                        <a style={{ backgroundColor: "#FF0000" }}>
                                                            <img
                                                                src={icon4}
                                                                style={{ width: 30, height: 30 }}
                                                            />
                                                        </a>
                                                    )}
                                                </footer>
                                            </div>
                                            {m.nCourseType === 1 ? (
                                                <>
                                                    <div style={{ padding: "2em 1em 0em 1em" }}>
                                                        <p
                                                            className="pHeadCourseList"
                                                            style={{
                                                                whiteSpace: "normal",
                                                            }}
                                                        >
                                                            {m.sTitle}
                                                        </p>
                                                        <p
                                                            className="pDescCourseList"
                                                            style={{
                                                                whiteSpace: "normal",
                                                                margin: "14.4px 0px",
                                                            }}
                                                        >
                                                            {ParseHtml(m.sDescMore)}
                                                        </p>
                                                    </div>
                                                    <Typography sx={{ m: "1em", wordWrap: "break-word", fontSize: "0.9em", height: "45px" }}>
                                                        {m.sNameCategory}
                                                    </Typography>
                                                </>
                                            ) : (
                                                <>
                                                    <div style={{ padding: "2em 1em 0em 1em" }}>
                                                        <p
                                                            className="pHeadCourseList"
                                                            style={{
                                                                whiteSpace: "normal",
                                                            }}
                                                        >
                                                            {m.sTitle}
                                                        </p>
                                                        <p
                                                            className="pDescCourseList"
                                                            style={{
                                                                whiteSpace: "normal",
                                                            }}
                                                        >
                                                            <p
                                                                style={{
                                                                    display: "flex",
                                                                    alignItems: "center",
                                                                    margin: "0px",
                                                                }}
                                                            >
                                                                <Grid xs={2} lg={1.5} xl={1}>
                                                                    <BsFlagFill
                                                                        color="rgb(251 107 42)"
                                                                        size="20"
                                                                        style={{
                                                                            marginRight: "5px",
                                                                        }}
                                                                    />
                                                                </Grid>
                                                                <Grid xs={10} lg={10.5} xl={11}>
                                                                    <p
                                                                        style={{
                                                                            WebkitLineClamp: 1,
                                                                            overflow: "hidden",
                                                                            whiteSpace: "nowrap",
                                                                            textOverflow: "ellipsis",
                                                                            flexWrap: "nowrap",
                                                                            margin: "0px",
                                                                            WebkitBoxOrient: "vertical",
                                                                        }}
                                                                    >
                                                                        {m.sScheduleName}
                                                                    </p>
                                                                </Grid>
                                                            </p>
                                                            <p
                                                                style={{
                                                                    display: "flex",
                                                                    alignItems: "center",
                                                                    margin: "0px",
                                                                }}
                                                            >
                                                                <Grid xs={2} lg={1.5} xl={1}>
                                                                    <BiCalendar
                                                                        color="rgb(0 0 255)"
                                                                        size="20"
                                                                        style={{
                                                                            marginRight: "5px",
                                                                        }}
                                                                    />
                                                                </Grid>
                                                                <Grid xs={10} lg={10.5} xl={11}>
                                                                    <p
                                                                        style={{
                                                                            WebkitLineClamp: 1,
                                                                            overflow: "hidden",
                                                                            whiteSpace: "nowrap",
                                                                            textOverflow: "ellipsis",
                                                                            flexWrap: "nowrap",
                                                                            margin: "0px",
                                                                            WebkitBoxOrient: "vertical",
                                                                        }}
                                                                    >
                                                                        {m.sClassDate}
                                                                    </p>
                                                                </Grid>
                                                            </p>
                                                            <p
                                                                style={{
                                                                    display: "flex",
                                                                    alignItems: "center",
                                                                    margin: "0px",
                                                                }}
                                                            >
                                                                <Grid xs={2} lg={1.5} xl={1}>
                                                                    <MdLocationPin
                                                                        color="rgb(255 0 0)"
                                                                        size="20"
                                                                        style={{ marginRight: "5px" }}
                                                                    />
                                                                </Grid>
                                                                <Grid xs={10} lg={10.5} xl={11}>
                                                                    <p
                                                                        style={{
                                                                            WebkitLineClamp: 1,
                                                                            overflow: "hidden",
                                                                            whiteSpace: "nowrap",
                                                                            textOverflow: "ellipsis",
                                                                            flexWrap: "nowrap",
                                                                            margin: "0px",
                                                                            WebkitBoxOrient: "vertical",
                                                                        }}
                                                                    >
                                                                        {m.sPlace} <>{!IsNullOrUndefined(m.sPlace) && !IsNullOrUndefined(m.sRoom) ? <>,</> : <></>}</> {m.sRoom}
                                                                    </p>
                                                                </Grid>
                                                            </p>

                                                        </p>
                                                    </div>
                                                    <Typography sx={{ m: "1em", wordWrap: "break-word", fontSize: "0.9em", height: "45px" }}>
                                                        {m.sNameCategory}
                                                    </Typography>
                                                </>
                                            )}


                                            <Box
                                                display="flex"
                                                justifyContent="center"
                                                sx={{ mx: { xl: "2em" } }}
                                            >
                                                {m.nCourseType === 1 ? <BtnDetail
                                                    color={"#1e4adb"}
                                                    hover={"#2e59e6"}
                                                    txt="รายละเอียดเพิ่มเติม/Detail"
                                                    fullWidth
                                                    onClick={() => onClickDetail(m)}
                                                /> : <ClassroomDetail
                                                    nID={m.nCourseID}
                                                    nScheduleID={m.nScheduleID}
                                                    data={m}
                                                    isExpire={m.isExpire}
                                                    isopen={(sDecryptID == m.nID + "") && isopen}
                                                ></ClassroomDetail>}
                                            </Box>
                                        </Grid>
                                    </>
                                )
                            )} */}

                            {/* <Grid container sx={{ px: { xs: "1em !important", md: "1em !important", lg: "3em !important" } }}> */}
                                {(_DATA.currentData() ? (_DATA.currentData().length > 0 ? _DATA.currentData() : []) : []).map((m) => (
                                    <Grid item xs={12} sm={6} lg={4} sx={{ px: { lg: "1em", sm: "0" }, py: "2em", justifyContent: "center", display: "flex" }}>
                                        <Card sx={{ width: 330, maxWidth: 330, mb: 2, border: "none", boxShadow: "none", background: "transparent", overflow: "hidden", position: "relative" }}>
                                            <CardActionArea>
                                                {!m.isPublic && m.nCourseType === 1 && m.isExpire &&
                                                    <IconButton
                                                        color="primary"
                                                        aria-label="upload picture"
                                                        component="label"
                                                        style={{ backgroundColor: "#FF0000", borderBottomRightRadius: "10%" }}
                                                        sx={{
                                                            position: "absolute",
                                                            zIndex: 2,
                                                            left: "0%",
                                                            top: "0%",
                                                            "&.MuiIconButton-root": {
                                                                borderTopLeftRadius: "0% !important",
                                                                borderTopRightRadius: "0% !important",
                                                                borderBottomLeftRadius: "0% !important",
                                                                borderBottomRightRadius: "40% !important",
                                                            }
                                                        }}>
                                                        <FaLock style={{ color: "#ffffff" }} size={30} />

                                                    </IconButton>
                                                }
                                                <CardMedia
                                                    component="img"
                                                    height="200"
                                                    image={m.sPath || defaultImage}
                                                    alt="green iguana"
                                                    onError={({ currentTarget }) => {
                                                        currentTarget.onerror = null;
                                                        currentTarget.src = defaultImage;
                                                    }}
                                                    sx={{
                                                        ":hover": { transform: "scale(1.1)" },
                                                        transition: "transform .5s ease",

                                                    }}
                                                />
                                                <IconButton
                                                    color="primary"
                                                    aria-label="upload picture"
                                                    component="label"
                                                    style={{ backgroundColor: m.nCourseType === 1 ? "#1e4adb" : "#FF0000" }}
                                                    sx={{
                                                        position: "absolute",
                                                        zIndex: 2,
                                                        right: "44%",
                                                        top: "38%",
                                                        transform: "translateY(50%)",
                                                    }}>
                                                    <img
                                                        src={m.nCourseType === 1 ? icon2 : icon4}
                                                        style={{
                                                            width: 35,
                                                            height: 35,
                                                        }}
                                                    />
                                                </IconButton>
                                                {m.nCourseType === 1 ? (
                                                    <CardContent sx={{ maxHeight: 180, height: 180, mt: 2 }}>
                                                        <Typography
                                                            gutterBottom
                                                            variant="h6"
                                                            component="div"
                                                            sx={{
                                                                display: "-webkit-box",
                                                                overflow: "hidden",
                                                                WebkitBoxOrient: "vertical",
                                                                WebkitLineClamp: 2,
                                                                height: "65px",
                                                            }}>
                                                            {m.sTitle}
                                                        </Typography>
                                                        <Typography
                                                            variant="body2"
                                                            color="text.secondary"
                                                            sx={{
                                                                display: "-webkit-box",
                                                                overflow: "hidden",
                                                                WebkitBoxOrient: "vertical",
                                                                WebkitLineClamp: 3,
                                                                height: "60px",
                                                            }}>
                                                            {ParseHtml(m.sDescMore)}
                                                        </Typography>
                                                        <Typography
                                                            variant="body2"
                                                            color="text.secondary"
                                                            sx={{
                                                                display: "-webkit-box",
                                                                overflow: "hidden",
                                                                WebkitBoxOrient: "vertical",
                                                                WebkitLineClamp: 1,
                                                                mt: 2,
                                                            }}>
                                                            {m.sNameCategory || ""}
                                                        </Typography>
                                                    </CardContent>
                                                ) : (
                                                    <CardContent sx={{ maxHeight: 180, height: 180, mt: 2 }}>
                                                        <Typography
                                                            gutterBottom
                                                            variant="h6"
                                                            component="div"
                                                            sx={{
                                                                display: "-webkit-box",
                                                                overflow: "hidden",
                                                                WebkitBoxOrient: "vertical",
                                                                WebkitLineClamp: 2,
                                                                height: "65px",
                                                            }}>
                                                            {m.sTitle}
                                                        </Typography>
                                                        <Typography variant="body2" style={{ display: "flex" }}>
                                                            <FlagIcon style={{ color: "rgb(251 107 42)", width: "20px", height: "20px", }} />
                                                            <p
                                                                style={{
                                                                    WebkitLineClamp: 1,
                                                                    overflow: "hidden",
                                                                    whiteSpace: "nowrap",
                                                                    textOverflow: "ellipsis",
                                                                    flexWrap: "nowrap",
                                                                    margin: "0px",
                                                                    WebkitBoxOrient: "vertical",
                                                                }}
                                                            >
                                                                {m.sScheduleName}
                                                            </p>
                                                        </Typography>
                                                        <Typography variant="body2" style={{ display: "flex" }}>
                                                            <CalendarMonthIcon style={{ color: "rgb(0 0 255)", width: "20px", height: "20px", }} />
                                                            <p
                                                                style={{
                                                                    WebkitLineClamp: 1,
                                                                    overflow: "hidden",
                                                                    whiteSpace: "nowrap",
                                                                    textOverflow: "ellipsis",
                                                                    flexWrap: "nowrap",
                                                                    margin: "0px",
                                                                    WebkitBoxOrient: "vertical",
                                                                }}
                                                            >
                                                                {m.sClassDate}
                                                            </p>
                                                        </Typography>
                                                        <Typography variant="body2" style={{ display: "flex" }}>
                                                            <LocationOnIcon style={{ color: "red", width: "20px", height: "20px", }} />
                                                            <p
                                                                style={{
                                                                    WebkitLineClamp: 1,
                                                                    overflow: "hidden",
                                                                    whiteSpace: "nowrap",
                                                                    textOverflow: "ellipsis",
                                                                    flexWrap: "nowrap",
                                                                    margin: "0px",
                                                                    WebkitBoxOrient: "vertical",
                                                                }}
                                                            >
                                                                {m.sPlace} <>{!IsNullOrUndefined(m.sPlace) && !IsNullOrUndefined(m.sRoom) ? <>,</> : <></>}</> {m.sRoom}
                                                            </p>
                                                        </Typography>
                                                        <Typography
                                                            variant="body2"
                                                            color="text.secondary"
                                                            sx={{
                                                                display: "-webkit-box",
                                                                overflow: "hidden",
                                                                WebkitBoxOrient: "vertical",
                                                                WebkitLineClamp: 1,
                                                                mt: 2,
                                                            }}>
                                                            {m.sNameCategory || ""}
                                                        </Typography>
                                                    </CardContent>
                                                )}
                                            </CardActionArea>
                                            <CardActions sx={{ pb: 4 }}>
                                                {m.nCourseType === 1 ? (
                                                    <BtnDetail onClick={() => onClickDetail(m)} color={"#1e4adb"} hover={"#2e59e6"} txt="รายละเอียดเพิ่มเติม/Detail" fullWidth />
                                                ) : (
                                                    <ClassroomDetail nID={m.nID} nScheduleID={m.nScheduleID} data={m} isExpire={m.isExpire}></ClassroomDetail>
                                                )}
                                            </CardActions>
                                        </Card>
                                    </Grid>
                                ))}

                            {/* </Grid> */}


                            <Grid
                                item
                                xs={12}
                                sx={{
                                    m: { xs: "0em 2em 2em 2em", lg: "5em 2em" },
                                    display: "flex",
                                    alignItems: "center",
                                    justifyContent: "center",
                                }}
                            >
                                <Pagination
                                    count={count}
                                    page={page}
                                    variant="outlined"
                                    shape="rounded"
                                    showFirstButton
                                    showLastButton
                                    onChange={handleChange}
                                />
                            </Grid>
                        </Grid>
                    </Grid>



                </Grid>
            </Grid>

            {/* </form> */}
        </FormProvider>
    )
}

export default CourseList