import React, { Fragment, useState } from "react";
import Grid from "@mui/material/Grid";
import { Popover, Button } from "@mui/material";
import { ExpandLess, ExpandMore } from "@mui/icons-material";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
// import { makeStyles } from '@mui/material/styles';
import { makeStyles } from "@mui/styles";

const useStyles = makeStyles({
  popover: {
    pointerEvents: "none",
    borderRadius: "unset !important",
  },
  popoverContent: {
    pointerEvents: "auto",
    borderRadius: "unset !important",
  },
});

function DropdownMenu(props) {
  const {
    menuTH,
    menuENG,
    children,
    color,
    nLevel,
    nType,
    hMenuIDRoute,
    menuID,
    isactive,
  } = props;

  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);
  const [aa, setAA] = useState(1);

  function handleClick(event) {
    setAnchorEl(event.currentTarget);
  }

  function handleClose() {
    setAnchorEl(null);
  }

  const childrenWithNewProps = React.Children.map(props.children, (child) =>
    React.cloneElement(child, { onClose: handleClose })
  );

  const [openedPopover, setOpenedPopover] = React.useState(false);
  const popoverAnchor = React.useRef(null);

  const classes = useStyles();

  const popoverEnter = ({ currentTarget }) => {
    setOpenedPopover(true);
  };

  const popoverLeave = ({ currentTarget }) => {
    setOpenedPopover(false);
  };
  return (
    <>
      {/* {nType === 2 && */}
      <Grid
        id={"G" + menuID}
        item
        xs
        sx={{
          //เพิ่ม bgcolor จาก HeadmenuItem
          bgcolor:
            // nLevel === 1
            //   ? hMenuIDRoute === menuID
            //     ? "#3997df"
            //     : "white"
            //   : "white",
            // nLevel === 1?
            isactive ? "#3997df" : "white",
          // : "white",
          color: "white",
          ":hover": {
            bgcolor: "#3997df",
            pr: "0px !important",
          },
          maxWidth:nLevel !== 1 ? "400px" : {xs:"140px",xl:"400px"},
        }}
      >
        <Button
          id={"B" + menuID}
          fullWidth
          sx={{
            color:isactive ? "white":"black",
              // nLevel == 1
              //   ? hMenuIDRoute === menuID
              //     ? "white"
              //     : "black"
              //   : "black",
            px: 2,
            height: nLevel !== 1 ? "80px" : "90px",
            maxWidth:nLevel !== 1 ? "400px" : {xs:"140px",xl:"400px"},
            fontSize: {xs:"0.8em",xl:"1em"},
            borderRadius: 0,
            // hMenuID
            background:
              // nLevel == 1? 
              isactive
                  ? "linear-gradient( rgba(255,255,255,1) 0%, rgba(255,255,255,1) 100%)"
                  : "linear-gradient( #000000 0%, #dbdbdb 145%)",
                // : "linear-gradient( #000000 0%, #dbdbdb 145%)",
            WebkitBackgroundClip: "text",
            WebkitTextFillColor: "transparent",
            borderBottom: nLevel > 1 ? "1px solid rgba(0,0,0,0.2)" : "",
            justifyContent: nLevel > 1 ? "space-between" : "center",
            "&.MuiButtonBase-root": {
              textTransform: "none",
              textAlign: nLevel > 1 ? "start" : "center ",
            },
            ":hover": {
              background:
                "linear-gradient( rgba(255,255,255,1) 0%, rgba(255,255,255,1) 100%)",
              WebkitBackgroundClip: "text",
              WebkitTextFillColor: "transparent",
              color: "white",
            },
          }}
          ref={popoverAnchor}
          onMouseEnter={popoverEnter}
          onMouseLeave={popoverLeave}
        >
          <p style={{ whiteSpace: "nowrap" }}>
            <label style={{ cursor: "pointer", fontWeight: "normal" }}>
              {menuTH}
            </label>
            <br />
            <label style={{ cursor: "pointer", fontWeight: "normal" }}>
              {menuENG}
            </label>
          </p>
          {nLevel > 1 ? (
            open ? (
              <ChevronLeftIcon />
            ) : (
              <ChevronRightIcon />
            )
          ) : open ? (
            <ExpandLess />
          ) : (
            <ExpandMore />
          )}
        </Button>
        {childrenWithNewProps != undefined && (
          <Popover
            id="mouse-over-popover"
            className={classes.popover}
            classes={{
              paper: classes.popoverContent,
            }}
            open={openedPopover}
            anchorEl={popoverAnchor.current}
            PaperProps={{
              onMouseEnter: popoverEnter,
              onMouseLeave: popoverLeave,
            }}
            anchorOrigin={{
              vertical: nLevel > 1 ? "top" : "bottom",
              horizontal: nLevel > 1 ? "right" : "left",
            }}
            transformOrigin={{
              vertical: "top",
              horizontal: nLevel > 1 ? "left" : "left",
            }}
          >
            <Grid container direction="column" alignItems="stretch">
              {childrenWithNewProps}
            </Grid>
          </Popover>
        )}
      </Grid>
      {/* } */}
    </>
  );
}

export default DropdownMenu;
