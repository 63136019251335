import { SentimentDissatisfiedSharp } from "@mui/icons-material";
import Grid from "@mui/material/Grid";
import React, { useCallback, useEffect, useState } from "react";
import { BtnDetail, BtnLeftWithOutText, BtnLogin, BtnRightWithOutText, BtnSeeDetail } from "../Button";
import "./CourseUpdateHome.css";
import icon2 from "../../Layout/img/ico-course-2.png";
import icon4 from "../../Layout/img/ico-course-4.png";
import img1 from "../../Layout/img/panel-course-cover.jpg";
import { number } from "prop-types";
import { Typography } from "@mui/material";
import Box from "@mui/system/Box";
import { ParseHtml, IsNullOrUndefined } from "src/CommonFunction/TS_function";
import { CgArrowLongRight } from "react-icons/cg";
import "./CourseUpdateHome.css";
import { BsEaselFill, BsFlagFill } from "react-icons/bs";
import { BiCalendar } from "react-icons/bi";
import { MdLocationPin } from "react-icons/md";
import defaultImage from "../../Layout/img/course-1.jpg";
// import defaultImage from "../../Layout/img/default-image.png";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import CardMedia from "@mui/material/CardMedia";
import { Button, CardActionArea, CardActions } from "@mui/material";
import IconButton from "@mui/material/IconButton";
import PhotoCamera from "@mui/icons-material/PhotoCamera";
import { makeStyles } from "@material-ui/core/styles";
import FlagIcon from "@mui/icons-material/Flag";
import CalendarMonthIcon from "@mui/icons-material/CalendarMonth";
import LocationOnIcon from "@mui/icons-material/LocationOn";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { ClassroomDetail } from "src/Components/System/ClassroomModal/ClassroomDetail";
import { useNavigate } from "react-router";
import { FaLock } from "react-icons/fa";
import { AlertTitle, SwAlert } from "../Alert/Sweetalert";

export function CourseUpdateHome(props: CourseUpdateHome) {
  const { dataSource } = props;
  const getCon = document.getElementById("container") as HTMLElement;
  // const [getCon,setgetCon] = useState({} as any);
  const [left, setLeft] = useState(0);
  const [sliderRef, setSliderRef] = useState<any>(null);
  const [checkSlide, setCheckSlide] = useState(0);
  const navigate = useNavigate();
  const useStyles = makeStyles({
    media: {
      transition: "1s",
      "&:hover": {
        transform: "scale(1.1)",
      },
    },
  });

  const classes = useStyles();
  const onClickLeft = () => {
    if (getCon.scrollLeft !== 0) {
      // setLeft((getCon.scrollLeft -= getCon.clientWidth));
      setLeft((getCon.scrollLeft = 0));
      getCon.focus({ preventScroll: true });
    }
  };

  const onClickRight = () => {
    if (getCon.scrollLeft <= 0) {
      setLeft((getCon.scrollLeft += getCon.offsetWidth));
    }
  };
  const onClickDetail = async (e) => {
    if (!e.isPublic && e.nCourseType === 1 && e.isExpire) {
      SwAlert.Warning(AlertTitle.Warning, "โปรด Login (เข้าสู่ระบบ) ก่อนเข้าเรียนหลักสูตรของ OR Academy ", () => { }, " หากท่านต้องการสมัครสมาชิกติดต่อที่ oracademy@pttor.com หรือ โทร. 02-196-6363")
    } else {
      navigate(process.env.REACT_APP_API_URL + "OnlineDetail?sID=" + e.sID + "&c=" + e.sCategoryID + "&str=" + props.siteMap);
    }
  };
  var settings = {
    arrows: false,
    dots: false,
    infinite: false,
    speed: 500,
    slidesToShow: 4,
    slidesToScroll: 4,
    initialSlide: 0,
    responsive: [
      {
        breakpoint: 1500,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 3,
          infinite: false,
          dots: false,
          swipe: true,
          draggable: true,
        },
      },
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
          infinite: false,
          dots: false,
        },
      },
      {
        breakpoint: 700,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
    afterChange: (currentSlide) => {
      // setCheckSlide(currentSlide)
      if (currentSlide - checkSlide == 4 || currentSlide - checkSlide < -4) {
        if (currentSlide * 2 >= dataSource.length - 1) {
          setCheckSlide(dataSource.length - 1);
        } else {
          setCheckSlide(currentSlide);
        }
      }
      else if (currentSlide - checkSlide == 2) {
        if (checkSlide != dataSource.length - 1) {
          if (currentSlide + 2 == dataSource.length) {
            setCheckSlide(dataSource.length - 1)
          } else {
            setCheckSlide(currentSlide)
          }
        }
        // else if(currentSlide + 2 == dataSource.length){
        //   console.log('max slide')
        //   setCheckSlide(dataSource.length - 1)
        // }
      }
      else if (currentSlide - checkSlide == 1 && dataSource.length % 2 == 0 || currentSlide - checkSlide == -1) {
        if (currentSlide + 1 == dataSource.length && currentSlide > checkSlide) {
          setCheckSlide(dataSource.length - 1)
        } else {
          setCheckSlide(currentSlide)
        }
      }
      // if(currentSlide > 0 ){
      //   if(currentSlide*2 > dataSource.length - 1){
      //     setCheckSlide(dataSource.length - 1)
      //   }else{
      //     setCheckSlide(currentSlide)
      //   }
      // }else{
      //   setCheckSlide(currentSlide)
      // }
    },
  };

  useEffect(() => { }, []);

  return (
    <>
      <Grid>
        <Grid
          container
          sx={{
            display: "flex",
            justifyContent: "flex-end",
            backgroundColor: "#ebf6fd",
          }}>
          <Grid item xs={12} md={3}></Grid>
          <Grid item xs={12} md={9}>
            {/* <img
            src={img1}
          /> */}
            <div className="wrapper">
              <div className="parent">
                <div className="child" style={{ backgroundImage: `url(${img1}`, display: "flex" }}>
                  {/* <span style={{ fontSize: "50px", color: "white" }}>&#11107;</span> */}
                  <div className="arrowRightCourse">
                    <div className="lineCourse"></div>
                    <div className="pointCourse"></div>
                  </div>
                  <Box>
                    <Typography
                      sx={{
                        fontSize: {
                          xs: "1.5em",
                          lg: "2em",
                        },
                        // marginLeft: "50px",
                        lineHeight: 1,
                        color: "rgba(255,255,255,0.9)",
                      }}>
                      COURSE
                    </Typography>
                    <Typography
                      sx={{
                        fontSize: {
                          xs: "0.8em",
                          lg: "1.2em",
                        },
                        // marginLeft: "50px",
                        lineHeight: 1,
                        color: "rgba(255,255,255,0.9)",
                      }}>
                      UPDATE
                    </Typography>
                  </Box>
                  <Typography
                    sx={{
                      fontSize: "1em",
                      justifyContent: "flex-end",
                      ml: { xs: "", lg: "50px" },
                      mr: { xs: "30px", lg: "" },
                      lineHeight: 1,
                      display: "flex",
                      width: "100%",
                      color: "rgba(255,255,255,0.9)",
                      cursor: "pointer",
                      ":hover": {
                        textDecoration: "underline",
                      },
                    }}
                    onClick={() => navigate(process.env.REACT_APP_API_URL + "CourseList?str=" + props.siteMap)}>
                    VIEW ALL
                  </Typography>
                </div>
              </div>
            </div>
          </Grid>
        </Grid>
        <Grid container spacing={0} sx={{ mt: "25px" }}>
          <Grid
            item
            // wrap="nowrap"
            xs={12}
            md={12}
            style={
              {
                // display: "flex",
                // flexDirection: "row",
                // justifyContent: "space-around",
                // flexWrap: "wrap",
                // textAlign:"center"
              }
            }>
            <Slider {...settings} style={{ width: "100%" }} ref={setSliderRef}>
              {(dataSource ? (dataSource.length > 0 ? dataSource : []) : []).map((m) => (
                <Card sx={{ maxWidth: 345, mb: 5, ml: { xs: 3, md: 4 } }}>
                  <CardActionArea>
                    {!m.isPublic && m.nCourseType === 1 && m.isExpire &&
                      <IconButton
                        color="primary"
                        aria-label="upload picture"
                        component="label"
                        style={{ backgroundColor: "#FF0000", borderBottomRightRadius: "10%" }}
                        sx={{
                          position: "absolute",
                          zIndex: 2,
                          left: "0%",
                          top: "0%",
                          "&.MuiIconButton-root": {
                            borderTopLeftRadius: "0% !important",
                            borderTopRightRadius: "0% !important",
                            borderBottomLeftRadius: "0% !important",
                            borderBottomRightRadius: "40% !important",
                          }
                        }}>
                        <FaLock style={{ color: "#ffffff" }} size={30} />
                        {/* <img
                        src={m.nCourseType === 1 ? icon2 : icon4}
                        style={{
                          width: 35,
                          height: 35,
                        }}
                      /> */}
                      </IconButton>
                    }
                    <CardMedia
                      component="img"
                      className={classes.media}
                      height="200"
                      image={m.sPath || defaultImage}
                      alt="green iguana"
                      onError={({ currentTarget }) => {
                        currentTarget.onerror = null;
                        currentTarget.src = defaultImage;
                      }}
                    />
                    <IconButton
                      color="primary"
                      aria-label="upload picture"
                      component="label"
                      style={{ backgroundColor: m.nCourseType === 1 ? "#1e4adb" : "#FF0000" }}
                      sx={{
                        position: "absolute",
                        zIndex: 2,
                        right: "44%",
                        top: "38%",
                        transform: "translateY(50%)",
                      }}>
                      <img
                        src={m.nCourseType === 1 ? icon2 : icon4}
                        style={{
                          width: 35,
                          height: 35,
                        }}
                      />
                    </IconButton>
                    {m.nCourseType === 1 ? (
                      <CardContent sx={{ maxHeight: 180, height: 180, mt: 2 }}>
                        <Typography
                          gutterBottom
                          variant="h6"
                          component="div"
                          sx={{
                            display: "-webkit-box",
                            overflow: "hidden",
                            WebkitBoxOrient: "vertical",
                            WebkitLineClamp: 2,
                            height: "65px",
                          }}>
                          {m.sTitle}
                        </Typography>
                        <Typography
                          variant="body2"
                          color="text.secondary"
                          sx={{
                            display: "-webkit-box",
                            overflow: "hidden",
                            WebkitBoxOrient: "vertical",
                            WebkitLineClamp: 3,
                            height: "60px",
                          }}>
                          {ParseHtml(m.sDescMore)}
                        </Typography>
                        <Typography
                          variant="body2"
                          color="text.secondary"
                          sx={{
                            display: "-webkit-box",
                            overflow: "hidden",
                            WebkitBoxOrient: "vertical",
                            WebkitLineClamp: 1,
                            mt: 2,
                          }}>
                          {m.sNameCategory || ""}
                        </Typography>
                      </CardContent>
                    ) : (
                      <CardContent sx={{ maxHeight: 180, height: 180, mt: 2 }}>
                        <Typography
                          gutterBottom
                          variant="h6"
                          component="div"
                          sx={{
                            display: "-webkit-box",
                            overflow: "hidden",
                            WebkitBoxOrient: "vertical",
                            WebkitLineClamp: 2,
                            height: "65px",
                          }}>
                          {m.sTitle}
                        </Typography>
                        <Typography variant="body2" style={{ display: "flex" }}>
                          <FlagIcon style={{ color: "rgb(251 107 42)", width: "20px", height: "20px", }} />
                          <p
                            style={{
                              WebkitLineClamp: 1,
                              overflow: "hidden",
                              whiteSpace: "nowrap",
                              textOverflow: "ellipsis",
                              flexWrap: "nowrap",
                              margin: "0px",
                              WebkitBoxOrient: "vertical",
                            }}
                          >
                            {m.sScheduleName}
                          </p>
                        </Typography>
                        <Typography variant="body2" style={{ display: "flex" }}>
                          <CalendarMonthIcon style={{ color: "rgb(0 0 255)", width: "20px", height: "20px", }} />
                          <p
                            style={{
                              WebkitLineClamp: 1,
                              overflow: "hidden",
                              whiteSpace: "nowrap",
                              textOverflow: "ellipsis",
                              flexWrap: "nowrap",
                              margin: "0px",
                              WebkitBoxOrient: "vertical",
                            }}
                          >
                            {m.sClassDate}
                          </p>
                        </Typography>
                        <Typography variant="body2" style={{ display: "flex" }}>
                          <LocationOnIcon style={{ color: "red", width: "20px", height: "20px", }} />
                          <p
                            style={{
                              WebkitLineClamp: 1,
                              overflow: "hidden",
                              whiteSpace: "nowrap",
                              textOverflow: "ellipsis",
                              flexWrap: "nowrap",
                              margin: "0px",
                              WebkitBoxOrient: "vertical",
                            }}
                          >
                            {m.sPlace} <>{!IsNullOrUndefined(m.sPlace) && !IsNullOrUndefined(m.sRoom) ? <>,</> : <></>}</> {m.sRoom}
                          </p>
                        </Typography>
                        <Typography
                          variant="body2"
                          color="text.secondary"
                          sx={{
                            display: "-webkit-box",
                            overflow: "hidden",
                            WebkitBoxOrient: "vertical",
                            WebkitLineClamp: 1,
                            mt: 2,
                          }}>
                          {m.sNameCategory || ""}
                        </Typography>
                      </CardContent>
                    )}
                  </CardActionArea>
                  <CardActions sx={{ pb: 4 }}>
                    {m.nCourseType === 1 ? (
                      <BtnDetail onClick={() => onClickDetail(m)} color={"#1e4adb"} hover={"#2e59e6"} txt="รายละเอียดเพิ่มเติม/Detail" fullWidth />
                    ) : (
                      <ClassroomDetail nID={m.nID} nScheduleID={m.nScheduleID} data={m} isExpire={m.isExpire}></ClassroomDetail>
                    )}
                  </CardActions>
                </Card>
              ))}
            </Slider>
          </Grid>
        </Grid>
        <Grid
          container
          xs={12}
          sx={{
            alignItems: "center",
            borderTop: "1px solid rgba(0,0,0,0.1)",
            // height: "5vw",
            pt: 3,
            pb: 3,
          }}>
          {/* <div id="BlockUICourse"></div> */}
          <Grid
            item
            xs={6}
            sx={{
              display: "flex",
              justifyContent: "flex-end",
              mb: 0,
              pr: "10px",
            }}>
            <BtnLeftWithOutText onClick={sliderRef?.slickPrev} color={checkSlide === 0 ? "default" : "primary"} />
          </Grid>
          <Grid item xs={6} sx={{ display: "flex", justifyContent: "flex-start", pl: "10px" }}>
            <BtnRightWithOutText onClick={sliderRef?.slickNext} color={checkSlide === dataSource.length - 1|| dataSource.length <= 4 ? "default" : "primary"} />
          </Grid>
        </Grid>
      </Grid>
    </>
  );
}

interface CourseUpdateHome {
  dataSource?: any;
  onClick?: (value: any) => void;
  siteMap?: string;
}
function AlertMsg(Warning: any, AlertMsg: any) {
  throw new Error("Function not implemented.");
}

