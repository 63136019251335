import Paper from "@mui/material/Paper";
import Box from "@mui/system/Box";
import { object } from "prop-types";
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { Axios } from "src/CommonFunction/TS_Axios";
import { FnBlock_UI } from "src/Components/BlockUI";
import { BtnEnterSite } from "src/Components/Button";
import defaultImage from "../../Layout/img/default-image.png"
import bg_intro from "../../Layout/img/bg_intro.png"
import { styled } from "@mui/material";
import { IsNullOrUndefined } from "src/CommonFunction/TS_function";
const intro = () => {
  const navigate = useNavigate();

  const { BlockUI, UnBlockUI } = FnBlock_UI();
  const [imgIntro, setImgintro] = useState("" as any);
  const a = window.innerHeight;
  const b = window.screen.height;
  const c = window.screen.availHeight;

  const ContainerBG = styled("div")({
    backgroundImage: `url(${bg_intro})`,
    backgroundPositionX: "center",
    backgroundPosittionY: "center",
    backgroundSize: "cover",
    backgroundRepeat: "no-repeat",
    backgroundAttachment: "fixed",
    width: '100vw',
    height: '88vh',
    position: 'relative'
  });


  useEffect(() => {
    setImg();
  }, []);

  const setImg = async () => {
    let res = await onPageLoad();
    setImgintro(res || "");
  };
  
  const onPageLoad = async () => {
    let result = {} as any;
    await Axios(`IntroPage/GetData_IntroPage`, "sMode='ALL'",  "Get",  async (res) => {
        if (IsNullOrUndefined(res.IntroPage)) {
          navigate(process.env.REACT_APP_API_URL + "home");
        } else {
          result = res.IntroPage.sURLImage;
        }
      },
      null,
      BlockUI,
      UnBlockUI,
      () => { }
    );
    return result;
  };

  return (
    <>
      <ContainerBG>
        <Box
          sx={{
            zIndex: 3,
            display: "flex",
            alignItems: "center",
            flexDirection: "column",
            marginTop: "20px",
            // maxHeight: "700px !important",
            // background: `url(${imgIntro}) 100% center no-repeat`,
            // backgroundSize: "cover",
            // backgroundPosition: "center center",
            // height: "auto",
            // width: "89%",
            // marginBottom: "35px",
            // minHeight: "500px",
            // height:"calc(100vh - 90px)",
          }}
        >
          <img
            src={imgIntro}
            onError={({ currentTarget }) => {
              currentTarget.onerror = null;
              currentTarget.src = defaultImage;
              currentTarget.style.backgroundSize = "50% 50%";
            }}
            style={{
              // height: "50%",
              // width: "89%",
              // minHeight: "55vh",
              maxWidth: "100%",
              // maxHeight: "calc(" + window.screen.availHeight + "px - 400px)",
              boxShadow: "0 0 0.5em 0 rgb(0 0 0 / 35%)",
              borderRadius: "0.5em",
              marginBottom: "20px",
              backgroundSize: "cover",
              backgroundPosition: "center center",
              maxHeight: "calc(100vh - 190px)",
            }}
          />
          <BtnEnterSite
            onClick={() => navigate(process.env.REACT_APP_API_URL + "home")}
          />
        </Box>
      </ContainerBG>
    </>
  );
};

export default intro;
