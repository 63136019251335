import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import sharingCover from "../../Layout/img/panel-sharing-cover.jpg";
import sharingContent from "../../Layout/img/panel-sharing-content.jpg";
import "./KnowledgeSharingHome.css";
import React, { useEffect, useState } from "react";
import { Encrypt, ParseHtml } from "src/CommonFunction/TS_function";
import Box from "@mui/material/Box";
import { BtnDetail, BtnLeftWithOutText, BtnRightWithOutText, BtnSeeDetail } from "../Button";
import icon1 from "../../Layout/img/ico-course-1.png";
import Divider from "@mui/material/Divider";
import { AiOutlineEye } from "react-icons/ai";
import { FaRegLightbulb } from "react-icons/fa";
import defaultImage from "../../Layout/img/pic-default/de-knowledge.jpg";
import { useNavigate } from "react-router-dom";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import CardMedia from "@mui/material/CardMedia";
import { Button, CardActionArea, CardActions } from "@mui/material";
import IconButton from "@mui/material/IconButton";
import PhotoCamera from "@mui/icons-material/PhotoCamera";
import { makeStyles } from "@material-ui/core/styles";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import AOS from "aos";

export function KnowledgeSharingHome(props: KnowledgeSharingHome) {
  const { dataSource } = props;
  const navigate = useNavigate();
  const getCon = document.getElementById("containerSharing") as HTMLElement;
  const [left, setLeft] = useState(0);
  const history = useNavigate();
  const [sliderRef, setSliderRef] = useState<any>(null);
  const [ checkSlide,setCheckSlide ] = useState(0);
  const useStyles = makeStyles({
    media: {
      transition: "1s",
      "&:hover": {
        transform: "scale(1.1)",
      },
    },
  });

  const classes = useStyles();
  const onClickLeft = () => {
    // if (getCon.scrollLeft !== 0) {
    //   setLeft((getCon.scrollLeft = 0));
    //   getCon.focus({ preventScroll: true });
    // }
  };

  const onClickRight = () => {
    // if (getCon.scrollLeft <= 0) {
    //   setLeft((getCon.scrollLeft += getCon.clientWidth));
    // }
  };

  const onClickDetail = (sID) => {
    navigate(process.env.REACT_APP_API_URL + `General?nIDC=` + sID + "&str="+ props.siteMap);
  };

  useEffect(() => {
    // AOS.init();
    // AOS.refresh();
  }, []);

  var settings = {
    arrows: false,
    dots: false,
    infinite: false,
    speed: 500,
    slidesToShow: 3,
    slidesToScroll: 3,
    initialSlide: 0,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
          infinite: false,
          dots: false,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
    afterChange: (currentSlide) => {
      // setCheckSlide(currentSlide)
      if (currentSlide - checkSlide == 3 || currentSlide - checkSlide  <= -3) {
        if (currentSlide * 2 >= dataSource.length - 1) {
          setCheckSlide(dataSource.length - 1);
        }else{
          setCheckSlide(currentSlide);
        }
      }
      else if(currentSlide - checkSlide == 2 || currentSlide - checkSlide  <= -2){
        if(checkSlide != dataSource.length - 1){
          if(currentSlide + 2 == dataSource.length){
            setCheckSlide(dataSource.length - 1)
          }else{
            setCheckSlide(currentSlide)
          }
        }
        // else if(currentSlide + 2 == dataSource.length){
        //   console.log('max slide')
        //   setCheckSlide(dataSource.length - 1)
        // }
      }
      // else if(currentSlide - checkSlide == 1 && dataSource.length % 2 == 0 || currentSlide - checkSlide == -1){
      //   if(currentSlide + 1 == dataSource.length && currentSlide > checkSlide){
      //     setCheckSlide(dataSource.length - 1)
      //   }else{
      //     setCheckSlide(currentSlide)
      //   }
      // }
    }
  };

  return (
    <Box className="BoxKnowledge" sx={{ minHeight: "calc(100vh - 90px" }}>
      {/* <Grid container rowSpacing={0}> */}
      <Grid item xs={12} lg={3}>
        <div className="wrapperSharing">
          <div className="parentSharing">
            <div
              className="childSharing"
              style={{
                backgroundImage: `url(${sharingCover}`,
                display: "flex",
              }}>
              <div className="arrowDownKnowledge">
                <div className="lineDownKnowledge"></div>
                <div className="pointDownKnowledge"></div>
              </div>
              <Box>
                <Typography
                  sx={{
                    fontSize: {
                      xs: "1.5em",
                      lg: "2.5em",
                    },
                    ml: { xs: "", lg: "50px" },
                    lineHeight: 1,
                    color: "rgba(255,255,255,0.9)",
                  }}
                  data-aos="fade-up" data-aos-duration="3000">
                  KNOWLEDGE
                </Typography>
                <Typography
                  sx={{
                    fontSize: {
                      xs: "0.8em",
                      lg: "1.2em",
                    },
                    ml: { xs: "", lg: "50px" },
                    lineHeight: 1,
                    color: "rgba(255,255,255,0.9)",
                  }}>
                  SHARING
                </Typography>
              </Box>
              <Typography
                sx={{
                  fontSize: "1em",
                  justifyContent: { xs: "flex-end", lg: "flex-start" },
                  ml: { xs: "", lg: "50px" },
                  mr: { xs: "30px", lg: "" },
                  lineHeight: 1,
                  display: "flex",
                  width: "100%",
                  color: "rgba(255,255,255,0.9)",
                  alignItems: "center",
                  cursor: "pointer",
                  ":hover": {
                    textDecoration: "underline",
                  },
                }}
                onClick={() => navigate(process.env.REACT_APP_API_URL + "KnowledgeSharingList?str="+ props.siteMap)}>
                VIEW ALL
                <div className="arrowRightKnowledge">
                  <div className="lineRightKnowledge"></div>
                  <div className="pointRightKnowledge"></div>
                </div>
              </Typography>
            </div>
          </div>
        </div>
      </Grid>
      <Grid
        container
        xs={12}
        // className={classes.drawerWidth}
        lg={9}
        sx={{
          background: `url(${sharingContent}) 100% center no-repeat`,
          backgroundSize: "cover",
          backgroundPosition: "center center",
          // minHeight: "calc(100vh - 90px)",
        }}
        >
        <Grid
          container
          // direction="row"
          // wrap="nowrap"
          item
          // xs={12}
          sx={{ mb: "8px",display:"block !important" }}
          style={{
            // display: "flex",
            // flexDirection: "row",
            // justifyContent: "space-around",
            // flexWrap: "wrap",
          }}>
          <Slider {...settings} style={{ width: "100%" }} ref={setSliderRef}>
            {(dataSource ? (dataSource.length > 0 ? dataSource : []) : []).map((m, i) => (
              <Card sx={{ maxWidth: 300, mt: 8, width: 290, border: "none", boxShadow: "none", ml: 5, background: "transparent", overflow: "hidden"}}>
                <CardActionArea>
                  <CardMedia
                    component="img"
                    className={classes.media}
                    height="300"
                    image={m.sPath || defaultImage}
                    alt="green iguana"
                    onError={({ currentTarget }) => {
                      currentTarget.onerror = null;
                      currentTarget.src = defaultImage;
                    }}
                    style={{
                      borderRadius: "50%",
                    }}
                  />
                  {m.isTagNew && (
                    <IconButton
                      color="primary"
                      aria-label="upload picture"
                      component="label"
                      style={{ backgroundColor: "#ff0000" }}
                      sx={{
                        position: "absolute",
                        zIndex: 2,
                        right: "0%",
                        top: "0%",
                        transform: "translateY(50%)",
                      }}>
                      <Typography variant="body2" sx={{ color: "#fff" }}>
                        New
                      </Typography>
                    </IconButton>
                  )}
                  <CardContent sx={{ display: "flex", justifyContent: "space-between", borderBottom: "1px solid #f5f5f5" }}>
                    <Typography variant="body2">General Knowledge</Typography>
                    <Typography variant="body2">{m.sDate}</Typography>
                  </CardContent>
                  <CardContent sx={{ maxHeight: 180, height: 180 }}>
                    <Typography
                      gutterBottom
                      variant="h6"
                      component="div"
                      sx={{
                        display: "-webkit-box",
                        overflow: "hidden",
                        WebkitBoxOrient: "vertical",
                        WebkitLineClamp: 2,
                      }}>
                      {m.sTitle}
                    </Typography>
                    <Typography
                      variant="body2"
                      color="text.secondary"
                      sx={{
                        display: "-webkit-box",
                        overflow: "hidden",
                        WebkitBoxOrient: "vertical",
                        WebkitLineClamp: 3,
                      }}>
                      {ParseHtml(m.sDesc)}
                    </Typography>
                  </CardContent>
                </CardActionArea>
                <CardActions sx={{ pb: 4 }}>
                  <BtnSeeDetail fullWidth color={"rgb(25, 118, 210)"} hover={"rgb(17, 82, 147)"} txt="อ่านต่อ/Read more" onClick={() => onClickDetail(m.sID)} />
                </CardActions>
              </Card>
            ))}
          </Slider>
        </Grid>
        <Grid
          container
          item
          xs={12}
          columnSpacing={3}
          sx={{
            // borderTop: "1px solid rgba(0,0,0,0.1)",
            alignContent: "center",
            mb:1,
            pb:2
          }}>
          <Grid
            item
            xs={6}
            sx={{
              display: "flex",
              justifyContent: "flex-end",
              // my: 2,
            }}>
            <BtnLeftWithOutText onClick={sliderRef?.slickPrev} color={checkSlide === 0 ? "default" : "primary"} />
          </Grid>
          <Grid
            item
            xs={6}
            sx={{
              display: "flex",
              justifyContent: "flex-start",
              // my: 2,
            }}>
            <BtnRightWithOutText onClick={sliderRef?.slickNext} color={checkSlide === dataSource.length - 1 || dataSource.length <= 3? "default" : "primary"}  />
          </Grid>
        </Grid>
      </Grid>
      {/* </Grid> */}
    </Box>
  );
}

interface KnowledgeSharingHome {
  dataSource?: any;
  onClick?: (value: any) => void;
  siteMap?:string;
}
