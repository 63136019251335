import * as yup from "yup";
import { i18n } from "src/i18n";
import moment from "moment";

const yupFormSchemas = {
  generic(label) {
    return yup.mixed().label(label);
  },
  object(label, config?) {
    config = config || {};

    let yupChain = yup
      .object()
      .label(label)
      .default(null)
      .nullable()
    if (config.required) {
      config.msg = "โปรดระบุ " + label;
      yupChain = yupChain.required(config.msg);
    }
    return yupChain;
  },
  string(label, config?) {
    config = config || {};
    let yupChain = yup
      .string()
      .transform((cv, ov) => {
        return ov === "" ? null : cv;
      })
      .nullable(true)
      .trim()
      .label(label);

    if (config.required) {
      config.msg = "โปรดระบุ " + label;
      yupChain = yupChain.required(config.msg);
    }

    if (config.min || config.min === 0) {
      yupChain = yupChain.min(config.min , config.labelmin);
    }

    if (config.max) {
      yupChain = yupChain.max(config.max);
    }

    if (config.matches) {
      yupChain = yupChain.matches(config.matches, config.labelmatches);
    }

    return yupChain;
  },
  boolean(label, config?) {
    return yup.bool().default(false).label(label);
  },
  relationToOne(label, config?) {
    config = config || {};

    let yupChain = yup
      .mixed()
      .nullable(true)
      .label(label)
      .transform((value, originalValue) => {
        if (!originalValue) {
          return null;
        }

        return originalValue.id;
      });

    if (config.required) {
      yupChain = yupChain.required();
    }

    return yupChain;
  },
  stringArray(label, config?) {
    config = config || {};

    let yupChain = yup
      .array()
      .compact()
      .ensure()
      .of(
        yup
          .string()
          .transform((cv, ov) => {
            return ov === "" ? null : cv;
          })
          .trim()
      )
      .label(label);

    if (config.required || config.min) {
      config.msg = "โปรดระบุ " + label;
      yupChain = yupChain.required(config.msg);
    }

    if (config.min || config.min === 0) {
      yupChain = yupChain.min(config.min);
    } else if (config.required) {
      yupChain = yupChain.min(1);
    }

    if (config.max) {
      yupChain = yupChain.max(config.max);
    }

    return yupChain;
  },
  array(label, config?) {
    config = config || {};

    let yupChain = yup.array().nullable(true).label(label);

    if (config.required || config.min) {
      yupChain = yupChain.required();
    }

    if (config.min || config.min === 0) {
      yupChain = yupChain.min(config.min);
    } else if (config.required) {
      yupChain = yupChain.min(1);
    }

    if (config.max) {
      yupChain = yupChain.max(config.max);
    }

    return yupChain;
  },
  relationToMany(label, config?) {
    config = config || {};

    let yupChain = yup
      .array()
      .nullable(true)
      .label(label)
      .transform((value, originalValue) => {
        if (!originalValue || !originalValue.length) {
          return [];
        }

        return originalValue.map((item) => item.id);
      });

    if (config.required || config.min) {
      yupChain = yupChain.required();
    }

    if (config.min || config.min === 0) {
      yupChain = yupChain.min(config.min);
    } else if (config.required) {
      yupChain = yupChain.min(1);
    }

    if (config.max) {
      yupChain = yupChain.max(config.max);
    }

    return yupChain;
  },
  integer(label, config?) {
    config = config || {};

    let yupChain = yup
      .number()
      .transform((cv, ov) => {
        return ov === "" ? null : cv;
      })
      .integer()
      .nullable(true)
      .label(label);
      if (config.required) {
        config.msg = "โปรดระบุ " + label;
        yupChain = yupChain.required(config.msg);
      }
    // if (config.required) {
    //   yupChain = yupChain.required();
    // }

    if (config.min || config.min === 0) {
      yupChain = yupChain.min(config.min);
    }

    if (config.max) {
      yupChain = yupChain.max(config.max);
    }

    return yupChain;
  },
  images(label, config?) {
    config = config || {};

    let yupChain = yup.array().nullable(true).label(label);

    if (config.required || config.min) {
      yupChain = yupChain.required();
    }

    if (config.min || config.min === 0) {
      yupChain = yupChain.min(config.min);
    } else if (config.required) {
      yupChain = yupChain.min(1);
    }

    if (config.max) {
      yupChain = yupChain.max(config.max);
    }

    return yupChain;
  },
  files(label, config?) {
    config = config || {};

    let yupChain = yup.array().compact().ensure().nullable(true).label(label);

    if (config.required || config.min) {
      yupChain = yupChain.required();
    }

    if (config.min || config.min === 0) {
      yupChain = yupChain.min(config.min);
    } else if (config.required) {
      yupChain = yupChain.min(1);
    }

    if (config.max) {
      yupChain = yupChain.max(config.max);
    }

    return yupChain;
  },
  enumerator(label, config?) {
    config = config || {};

    let yupChain = yup
      .string()
      .transform((cv, ov) => {
        return ov === "" ? null : cv;
      })
      .label(label)
      .nullable(true)
      .oneOf([null, ...(config.options || [])]);

    if (config.required) {
      yupChain = yupChain.required(i18n("validation.string.selected"));
    }

    return yupChain;
  },
  email(label, config?) {
    config = config || {};

    let yupChain = yup
      .string()
      .transform((cv, ov) => {
        return ov === "" ? null : cv;
      })
      .nullable(true)
      .trim()
      .label(label)
      .email();

    if (config.required) {
      config.msg = "โปรดระบุ " + label;
      yupChain = yupChain.required(config.msg);
    }

    if (config.min || config.min === 0) {
      yupChain = yupChain.min(config.min);
    }

    if (config.max) {
      yupChain = yupChain.max(config.max);
    }

    if (config.matches) {
      yupChain = yupChain.matches(config.matches);
    }

    return yupChain;
  },
  decimal(label, config?) {
    config = config || {};
    let yupChain = yup
      .number()
      .transform((cv, ov) => {
        return ov === "" ? null : cv;
      })
      .nullable(true)
      .label(label);

    if (config.required) {
      yupChain = yupChain.required();
    }

    if (config.min || config.min === 0) {
      yupChain = yupChain.min(config.min);
    }

    if (config.max) {
      yupChain = yupChain.max(config.max);
    }

    return yupChain;
  },
  datetime(label, config?) {
    config = config || {};
    let yupChain = yup
      .mixed()
      .nullable(true)
      .label(label)
      .transform((value, originalValue) =>
        value ? moment(originalValue, "YYYY-MM-DDTHH:mm:ss").toISOString() : null
      );

    if (config.required) {
      yupChain = yupChain.required();
    }

    return yupChain;
  },
  datetimeRange(label, config?) {
    config = config || {};
    let yupChain = yup.array().of(yup
      .mixed()
      .nullable(true)
      .label(label)
      .transform((value, originalValue) =>
        value ? moment(value).format("YYYY-MM-DDTHH:mm:ss") : null
      ));

    if (config.required) {
      yupChain = yupChain.required();
    }

    return yupChain;
  },
  date(label, config?) {
    config = config || {};
    let yupChain = yup
      .mixed()
      .nullable(true)
      .label(label)
      .test("is-date", i18n("validation.mixed.default"), (value) => {
        if (!value) {
          return true;
        }

        return moment(value, "YYYY-MM-DD").isValid();
      })
      .transform((value) =>
        value ? moment(value).format("YYYY-MM-DD") : null
      );

    if (config.required) {
      yupChain = yupChain.required();
    }

    return yupChain;
  },
};

export default yupFormSchemas;

export const RegexpPasswork = () => {

  let regexp1 = "((?=.*[A-Z])(?=.*[0-9])(?=.*[!@#\$%\^&\*])(?=.{8,}))";//พิมพ์ใหญ่และตัวเลขและอักขระพิเศษ
  let regexp2 = "((?=.*[A-Z])(?=.*[0-9])(?=.*[a-z])(?=.{8,}))";
  let regexp3 = "((?=.*[A-Z])(?=.*[0-9])(?=.*[!@#\$%\^&\*])(?=.{8,}))";
  let regexp4 = "((?=.*[a-z])(?=.*[0-9])(?=.*[!@#\$%\^&\*])(?=.{8,}))";
  let regexp5 = "((?=.*[a-z])(?=.*[A-Z])(?=.*[!@#\$%\^&\*])(?=.{8,}))";
  let regexp6 = "((?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#\$%\^&\*])(?=.{8,}))";//พิมพ์เล็กและพิมพ์ใหญ่และตัวเลขและอักขระพิเศษ 
  let egexp = "^(" + regexp1 + "|" + regexp2 + "|" + regexp3 + "|" + regexp4 + "|" + regexp5 + "|" + regexp6 + ")"
  return egexp;
}
export const MsgValidate = {
  PleaseSpecify: "โปรดระบุ",
  Passwork: "รหัสผ่านต้องมีอักขระอย่างน้อย 8 ตัวและมีอย่างน้อยสามรายการต่อไปนี้: ตัวพิมพ์ใหญ่, ตัวพิมพ์เล็ก, ตัวเลข, สัญลักษณ์",
  InvalidEmail: "อีเมลไม่ถูกต้อง",
  ConfirmPassword: "รหัสผ่านไม่ถูกต้อง"
}
