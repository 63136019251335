import React, { useState, useRef, useEffect } from 'react'
import { MobileDateRangePicker, LicenseInfo } from '@mui/x-date-pickers-pro';
import { AdapterMoment } from "@mui/x-date-pickers/AdapterMoment";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { Box, TextField } from "@mui/material"
import { useFormContext } from 'react-hook-form'
import PropTypes from "prop-types";
import moment from 'moment'

LicenseInfo.setLicenseKey(
    "5b31a40e910ea0c2a35371ae57c5b5beT1JERVI6MzYzMjEsRVhQSVJZPTE2NzQyOTE1NTAwMDAsS0VZVkVSU0lPTj0x"
);

const localeMap = (lang) => {
    switch (lang) {
        case 'th':
            moment.locale("th");
            break;
        case 'en':
            moment.locale("en");
            break;
        default:
            break;
    }
};



export default function DateRangeSigleBox({
    minDate, maxDate, name, label, calendarsCount, lableEnd, require,
    disabled, funcOnChange, small, required, funcOnAccept, value, inputSx, format
}) {
    const {
        register,
        setValue,
        getValues,
        watch,
        formState: { errors },
    } = useFormContext();



    const ReplaceThFormat = (text) => {
        return text.toLowerCase().replaceAll("d", "ว").replaceAll("m", "ด").replaceAll("y", "ป");
    }


    const formatDefalut = format;
    const MapPlaceHolder = {
        en: formatDefalut,
        th: ReplaceThFormat(formatDefalut),
    };

    const [locale] = React.useState<keyof typeof MapPlaceHolder>(() =>
        (window.localStorage && window.localStorage.language) ? window.localStorage.language : "th"
    );

    const [lstDate, setLstDate] = useState<any>([null, null]);
    const inpRef = useRef(null)

    useEffect(() => {
        localeMap(locale);
        setTimeout(() => {
            setLstDate(getValues(name) || [null, null])
        }, 50);
    }, [])



    return (
        <LocalizationProvider
            dateAdapter={CustomAdapter}
            locale={locale}
        >
            <MobileDateRangePicker
                label={label}
                startText={<>{label} {require ? <span style={{ color: "red" }}>{" *"}</span> : null} </>}
                disabled={disabled}
                {...register(name)}
                InputProps={{
                    readOnly: true,
                    onKeyDown: (e) => e.preventDefault(),
                    onChange: (e) => e.preventDefault()
                }}
                value={lstDate}
                onChange={(newValue, key) => {
                    if (!newValue.some(a => a == null))
                        setLstDate(newValue)
                    funcOnChange && funcOnChange(newValue)
                }}
                onAccept={(newValue) => {
                    setLstDate(newValue)
                    setValue(name, newValue);
                    funcOnAccept && funcOnAccept(newValue)
                }}
                showToolbar={false}
                inputFormat={formatDefalut}
                minDate={minDate || null}
                maxDate={maxDate || null}
                calendars={calendarsCount}
                inputRef={inpRef}
                DialogProps={{
                    PaperProps: {
                        sx:
                            { ".PrivateDateRangePickerToolbar-penIcon": { display: "none" } }
                    }
                }}
                renderInput={({ inputProps, ...startProps }, endProps) => {
                    let startValue = inputProps?.value  ;
                    delete inputProps?.value
                    let endValue = endProps.inputProps?.value;
                    if (locale === "th" && !lstDate.some(a => a == null)) {
                        let countYear = (format.toLowerCase().match(new RegExp("y", "g")) || []).length
                        let sYearStart = (lstDate[0].year() + 543).toString();
                        let indexOfYear = format.toLowerCase().indexOf("y");
                        let subStrStart = countYear != 4 ? sYearStart.substr(countYear) : sYearStart;
                        startValue = startValue.substring(0, indexOfYear) + subStrStart + startValue.substring(indexOfYear + subStrStart.length);

                        let sYearEnd = (lstDate[1].year() + 543).toString();
                        let subStrEnd = countYear != 4 ? sYearEnd.substr(countYear) : sYearEnd
                        endValue = endValue.substring(0, indexOfYear) + subStrEnd + endValue.substring(indexOfYear + subStrEnd.length);
                    }
                    return (
                        <TextField
                            onKeyDown={(e) => e.preventDefault()}
                            size={small ? "small" : "medium"}
                            sx={{
                                ...inputSx,
                                "label.MuiInputLabel-shrink": {
                                    top: "0!important",
                                }, width: "100%",
                                " > label": {
                                    top: !small ? "-10px" : "0",
                                    "::after": {
                                        content: required ? '" * "' : '""',
                                        color: "red",
                                        fontWeight: 500
                                    },
                                },
                                color: errors && errors[name] ? "red!important" : ""
                            }}
                            {...startProps}
                            inputProps={inputProps}
                            value={!lstDate.some(a => a == null) ? `${startValue}-${endValue}` : ""}
                        // error={errors && errors[name]}
                        />
                    )
                }}
            />
            {errors && errors[name] && <span style={{ color: "red", fontSize: 12 }}>{errors[name][0].message}</span>}
        </LocalizationProvider>
    )
}

DateRangeSigleBox.defaultProps = {
    label: "",
    lableEnd: "",
    small: false,
    required: false,
    calendarsCount: 1,
    format: "DD/MM/YYYY"
};

DateRangeSigleBox.propTypes = {
    name: PropTypes.string.isRequired,
    value: PropTypes.array.isRequired,
    minDate: PropTypes.instanceOf(moment.prototype),
    maxDate: PropTypes.instanceOf(moment.prototype),
    label: PropTypes.string,
    require: PropTypes.bool,
    calendarsCount: PropTypes.number,
    disabled: PropTypes.bool,
    lableEnd: PropTypes.string,
    required: PropTypes.bool,
    small: PropTypes.bool,
    funcOnChange: PropTypes.func,
    funcOnAccept: PropTypes.func,
    inputSx: PropTypes.any,
    format: PropTypes.string
};

class CustomAdapter extends AdapterMoment {
    // REF : https://th-samranrit0193.medium.com/reactjs-materialui-buddhist-era-%E0%B8%9B%E0%B8%B5-%E0%B8%9E-%E0%B8%A8-1f293af00382
    constructor({ locale, formats, instance }) {
        super({ locale, formats, instance });
    }

    calYear(moment) {
        switch (this.locale) {
            case "th":
                return (parseInt(moment.format('YYYY')) + 543).toString();
            case "en":
                return parseInt(moment.format('YYYY'));
            default:
                return parseInt(moment.format('YYYY'));
        }
    }

    renderLabel(moment, format) {
        switch (format) {
            case "year":
                return this.calYear(moment);
            case "month":
                return moment.format("MMMM");
            case "dayOfMonth":
                return moment.format("D");
            case "monthAndYear":
                return moment.format("MMMM YYYY");
            default:
                return moment.format(format);
        }
    }

    startOfMonth = (date) => date ? date.clone().startOf("month") : moment(new Date());

    format = (date, formatKey) => {
        return date ? this.renderLabel(date, formatKey) : moment(new Date());
    };
}