import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
// import {createStyles, makeStyles,} from '@mui/material/styles'
import { useFormContext, useWatch } from "react-hook-form";
import FormErrors from "../formErrors";
import {
  FormLabel,
  RadioGroup,
  Radio,
  FormControlLabel,
  FormControl,
  FormHelperText,
  InputLabel,
} from "@mui/material";
import { BiAngry } from "react-icons/bi";
import { HiCheckCircle } from "react-icons/hi";
import { MdOutlineRadioButtonChecked } from "react-icons/md";


function RadioFormItem(props) {
  const {
    label,
    name,
    hint,
    options,
    externalErrorMessage,
    required,
    disable,
    small,
    IsNewLine,
    value,
    isChangeIcon= false,
  } = props;

  const {
    register,
    setValue,
    watch,
    formState: { touchedFields, isSubmitted, errors },
  } = useFormContext();

  const errorMessage = FormErrors.errorMessage(
    name,
    errors,
    touchedFields,
    isSubmitted,
    externalErrorMessage,
  );

  const formHelperText = errorMessage || hint;
  return (
    <>
      <FormControl
        required={required}
        error={Boolean(errorMessage)}
        component="fieldset"
        size={"small"}
        disabled={disable}
        sx={{
          ".MuiFormControl-root": {
            asterisk: {
              color: '#d32f2f',
              '&$error': {
                color: '#d32f2f'
              },
            }
          },
          m: "8px"
        }}
      >
        <FormLabel
          component="legend"
          sx={{
            color: errors && errors[name] ? "red !important" : "",
            ".MuiFormLabel-root": {
              asterisk: {
                color: '#d32f2f',
                '&$error': {
                  color: '#d32f2f'
                },
              }
            },
            "span.MuiFormLabel-asterisk": {
              color: '#d32f2f',
            },
          }}
        >{label}
        </FormLabel>
        <RadioGroup
          id={name}
          name={name}
          
          value={String(watch(name) || '')}
          onChange={(e) => {
            setValue(name, e.target.value, { shouldValidate: true });
            props.onChange && props.onChange(e.target.value);
          }}
          onBlur={(event) => {
            props.onBlur && props.onBlur(event);
          }}
          row={props.row ? props.row : false}
          sx={{flexDirection: IsNewLine && "column"}}
        >
          {options.map((option) => (
            <>
              <FormControlLabel
                key={option.value}
                value={String(option.value)}
                //value={option.value}
                sx={{ color: option.color ? option.color : '#000000', m: (option.label === undefined ? "0" : "") }}
                control={
                <Radio size="small" 
                  checkedIcon={isChangeIcon ? <HiCheckCircle color="#007aff" size={20}/> : <MdOutlineRadioButtonChecked size={20}/> }
                />}
                label={option.label}
                checked={String(props.value) !== "" ? (String(props.value) === String(option.value) ? true : false) : false}
                disabled={disable || props.disabled == true ? true : (option.disabled == true ? true : false)}
                labelPlacement={"end"}
                />
            </>
          ))}
        </RadioGroup>
      </FormControl>
      {formHelperText && (
        <FormHelperText sx={{ color: "red" }}>
          {formHelperText}
        </FormHelperText>
      )}
    </>

  );
}

RadioFormItem.defaultProps = {
  required: false,
  row: true,
  small: true,
  IsNewLine: false,
};

RadioFormItem.propTypes = {
  name: PropTypes.string.isRequired,
  options: PropTypes.array.isRequired,
  label: PropTypes.string,
  hint: PropTypes.string,
  required: PropTypes.bool,
  externalErrorMessage: PropTypes.string,
  onChange: PropTypes.func,
  value: PropTypes.string,
  disable: PropTypes.bool,
  row: PropTypes.bool,
  small: PropTypes.bool,
  IsNewLine: PropTypes.bool,
  isChangeIcon: PropTypes.bool,
};

export default RadioFormItem;
