import { Grid } from "@mui/material";
import Typography from "@mui/material/Typography";
import { useEffect, useState } from "react";
import { FormProvider, useForm } from "react-hook-form";
import { Axios } from "src/CommonFunction/TS_Axios";
import { FnBlock_UI } from "../BlockUI";
import 'src/Components/Register/Register.css';
import { useNavigate } from "react-router";
import { BreadcrumbsUI } from "../BreadcrumbsUI";
import defaultImage from "../../Layout/img/activity-2.jpg";
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import {  GetQueryString } from "src/CommonFunction/TS_function";
import 'src/Components/EventPhoto/StyleEventPhoto.css';
import { MenuBar } from "../../Components/System/NavBar/MenuBar";


const EventPhoto = () => {

    const history = useNavigate();
    const { BlockUI, UnBlockUI } = FnBlock_UI();
    const [arrCard, setarrCard] = useState([] as any);
    const searchParams = location.search;
    const sID = new URLSearchParams(searchParams).get('sID');
    const [slideIndex, setslideIndex] = useState(1)
    const [PhotoDetail, setPhotoDetail] = useState({} as any)
    const sContentID = GetQueryString('str');

    const [clickedImg, setClickedImg] = useState() as any;
    const [currentIndex, setCurrentIndex] = useState() as any;

    useEffect(() => {
        OnGetData();
    }, []);
    const OnGetData = async () => {
        await Axios(
            `EventphotoPage/GetData_EventDetail`, { sID: sID }, "POST", async (res) => {
                setarrCard(res.lstEventPhoto || []);
                setPhotoDetail(res.lstEventDetail || {});
            },
            null,
            BlockUI,
            UnBlockUI,
            () => { }
        );
    };

    const onBack = () => {
        history(-1);
    }

    const handleClick = (item, index) => {
        setCurrentIndex(index);
        setClickedImg(item.sURLImage);
    };

    const handelRotationRight = () => {
        const totalLength = arrCard.length;
        if (currentIndex + 1 >= totalLength) {
            setCurrentIndex(0);
            const newUrl = arrCard[0].sURLImage;
            setClickedImg(newUrl);
            return;
        }
        const newIndex = currentIndex + 1;
        const newUrl = arrCard.filter((item) => {
            return arrCard.indexOf(item) === newIndex;
        });
        const newItem = newUrl[0].sURLImage;
        setClickedImg(newItem);
        setCurrentIndex(newIndex);
    };

    const handelRotationLeft = () => {
        const totalLength = arrCard.length;
        if (currentIndex === 0) {
            setCurrentIndex(totalLength - 1);
            const newUrl = arrCard[totalLength - 1].sURLImage;
            setClickedImg(newUrl);
            return;
        }
        const newIndex = currentIndex - 1;
        const newUrl = arrCard.filter((item) => {
            return arrCard.indexOf(item) === newIndex;
        });
        const newItem = newUrl[0].sURLImage;
        setClickedImg(newItem);
        setCurrentIndex(newIndex);
    };


    return (
        <>
            <Grid container direction="row" justifyContent="center" alignItems="start">
                <Grid item xs={12}>
                    {/* <BreadcrumbsUI
                        items={[
                            {
                                menuName: "กิจกรรม/Acitivty",
                                path: process.env.REACT_APP_API_URL + "EventPhoto?str=d7mepU474zw8L%2bYTQhfS8A%3d%3d",
                                IsOnClick: true
                            },
                        ]}
                        // items={navbar}
                        isShowHome={false}
                    /> */}
                     <MenuBar sContentID={sContentID} />
                </Grid>
                <Grid item xs={12}>
                    <Grid container spacing={2} direction="row" justifyContent="start" alignItems="center" style={{ padding: '5em' }}>
                        <Grid item xs={12}>
                            <h1
                                className="sTitleNews"
                                style={{
                                    whiteSpace: "normal",
                                    textOverflow: "ellipsis",
                                    wordBreak:"break-word"
                                }}
                            >
                                {PhotoDetail.sAlbumName}
                            </h1>
                        </Grid>
                        <Grid item xs={12}>
                            <Typography color="#708090" fontSize={18}>
                                {PhotoDetail.dDateTH}
                            </Typography>
                        </Grid>

                        <Grid item xs={12} >
                            <Typography fontSize={18} style={{wordBreak:"break-word"}}>
                                {PhotoDetail.sAlbumDesc}
                            </Typography>
                        </Grid>
                        <Grid item xs={12} >
                            <br/>
                            <br/>
                            <div className="wrapperGallery">
                                <Grid container spacing={4} direction="row" justifyContent="start" alignItems="center" >
                                    {arrCard.map((item, idx) => (
                                        <Grid item xs={12} md={4} lg={3}>
                                            <div key={idx} className="wrapper-images">
                                                <img
                                                    alt={"img1" + idx}
                                                    src={item.sURLImage}
                                                    className="fill zoom"
                                                    // className="img-responsive zoom"
                                                    onClick={() => handleClick(item, idx)}
                                                    style={{ width: '100%', height:'300px' , borderRadius:'0.5em',objectFit:'cover' }}
                                                    onError={({ currentTarget }) => {
                                                        currentTarget.onerror = null;
                                                        currentTarget.src = defaultImage;
                                                    }}
                                                />
                                            </div>

                                        </Grid>
                                    ))}

                                </Grid>
                            </div>
                        </Grid>
                    </Grid>
                </Grid>
                {clickedImg && (
                    <ModalGallery
                        clickedImg={clickedImg}
                        handelRotationRight={handelRotationRight}
                        setClickedImg={setClickedImg}
                        handelRotationLeft={handelRotationLeft}
                    />
                )}
            </Grid>

        </ >
    );
}
export default EventPhoto

export const ModalGallery = ({clickedImg, setClickedImg, handelRotationRight, handelRotationLeft}) => {
    const handleClick = (e) => {
        if (e.target.classList.contains("dismiss")) {
            setClickedImg(null);
        }
    };
    return (
        <>
            <div className="overlay dismiss" onClick={handleClick}>
                <img 
                    src={clickedImg} 
                    alt="Image" 
                    onError={({ currentTarget }) => {
                        currentTarget.onerror = null;
                        currentTarget.src = defaultImage;
                    }}
                />
                <span className="dismiss" onClick={handleClick}>
                </span>
                <div onClick={handelRotationLeft} className="overlay-arrows_left">
                    <ArrowBackIcon onClick={handelRotationLeft} />
                </div>
                <div onClick={handelRotationRight} className="overlay-arrows_right">
                    <ArrowForwardIcon onClick={handelRotationLeft} />
                </div>
            </div>
        </>
    );
};


