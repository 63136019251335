import { useCallback, useEffect, useRef, useState } from "react";
import "./Topic.css";
import { i18n } from "src/i18n";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import yupFormSchemas, { MsgValidate } from "src/Components/FormItem/yup/yupFormSchemas";
import { Axios, AxiosGetAsync, AxiosPostFileVideo } from "src/CommonFunction/TS_Axios";
import Grid from "@mui/material/Grid";
import ReactPlayer from "react-player";
import { ParseHtml, lnkToHomePage } from "src/CommonFunction/TS_function";
import { FnBlock_UI } from "src/Components/BlockUI";
import { MenuBar } from "src/Components/System/NavBar/MenuBar";
import Rating from '@mui/material/Rating';
import Stack from '@mui/material/Stack';
import Logo from "src/Layout/img/oba-logo.png";
import {
    Star,
    Person
} from "@mui/icons-material";
import { Box, Card, Divider, Fade, IconButton, Modal, Typography } from "@mui/material";
import StarIcon from '@mui/icons-material/Star';
import { styled } from '@mui/material/styles';
import LinearProgress, { linearProgressClasses } from '@mui/material/LinearProgress';
import { TiPencil } from "react-icons/ti";
import { AlertMsg, AlertTitle, SwAlert } from "src/Components/Alert/Sweetalert";
import { DialogUI } from "src/Components/Dialog";
import { AiOutlineEye } from "react-icons/ai";
import exVideo from 'src/Components/Banner/Video/ExampleVideo.mp4';
import { BtnCustomText, BtnDetail } from "src/Components/Button";
import RadioFormItem from "src/Components/FormItem/RadioFormItem/RadioIFormtem";
import { FormProvider, useForm } from "react-hook-form";
import { InputForm } from "src/Components/FormItem/Input";
import { GetQueryString } from "src/CommonFunction/TS_function";
import { useNavigate } from "react-router";

import LightGallery from 'lightgallery/react';
import lgThumbnail from 'lightgallery/plugins/thumbnail';


const BorderLinearProgress = styled(LinearProgress)(({ theme }) => ({
    height: 11,
    borderRadius: 5,
    [`& .${linearProgressClasses.bar}`]: {
        borderRadius: 5,
    },
}));

const dataEx = [
    {
        "nStar": 5,
        "nVoter": 0,
        "sPercent": "0.00",
        "nVoteCount": 0
    },
    {
        "nStar": 4,
        "nVoter": 0,
        "sPercent": "0.00",
        "nVoteCount": 0
    },
    {
        "nStar": 3,
        "nVoter": 0,
        "sPercent": "0.00",
        "nVoteCount": 0
    },
    {
        "nStar": 2,
        "nVoter": 0,
        "sPercent": "0.00",
        "nVoteCount": 0
    },
    {
        "nStar": 1,
        "nVoter": 0,
        "sPercent": "0.00",
        "nVoteCount": 0
    }
]

export function TopicCouse(props: pTopic) {
    const [lstData, setData] = useState<any>({});
    const [lstRating, setDataRating] = useState<any>({});
    const { BlockUI, UnBlockUI } = FnBlock_UI();
    const [openDialog, setOpenDialog] = useState(false);
    const [value, setValue] = useState<number | null>(0);
    const [hover, setHover] = useState(-1);
    const [isOpenCheckQuestion, setOpenCheckQuestion] = useState(false);
    const [sIdDecrypt, setsIdDecrypt] = useState(0);

    const [ResultExamPreLession, setResultExamPreLession] = useState<any>([]);
    const [ResultExamPostLession, setResultExamPostLession] = useState<any>([]);
    const [ResultExamPre, setResultExamPre] = useState<any>([]);
    const [ResultExamPost, setResultExamPost] = useState<any>([]);
    const [lstFileID, setLstFileID] = useState<any>([]);
    const sContentID = GetQueryString('str');

    const [schemaObjQ, setSchemaObjQ] = useState<any>({} as any);
    const [schemaTempQ, setschemaTempQ] = useState({} as any);
    const schemaQ = yup.object().shape(schemaObjQ);
    const lightGallery = useRef<any>(null);
    const [isNeedTest, setNeedTest] = useState(true);
    const [nUserType, setUserType] = useState(0);
    const navigate = useNavigate();
    const videoRef = useRef<HTMLVideoElement>(null);
    const playerRef = useRef({} as any);
    let timeStarted = -1;
    let timePlayed = 0;
    let duration = 0;

    const formQ = useForm({
        mode: "all",
        resolver: yupResolver(schemaQ),
        shouldUseNativeValidation: false,
    });

    useEffect(() => {
        const objSchemaQ = {
            ...schemaTempQ,
        };
        setSchemaObjQ({ ...objSchemaQ });
    }, [schemaTempQ])

    const setSchemaTmp = (e) => {
        // let emplate = {};
        // (e.lstTopicQuestionnaire || []).forEach((item, i) => {
        //     if (item != null) {
        //         e.lstQuestionnaireChoice.forEach((items, ii) => {
        //             emplate["radio_" + i] = yupFormSchemas.string("คำตอบ", { required: true, });
        //         })
        //     }
        // });
        // setschemaTempQ(emplate)
    }
    const onPageLoad = async () => {
        await Axios("Master/onPageLoad", {}, "GET", async (result) => {
            // if (result.IsExpired) {
            //     SwAlert.Warning(undefined, i18n("common.SessionTimeOut"), lnkToHomePage);
            // }
            // else {
            setUserType(result.nUserType);
            // setOpenDialog(true);
            // }
        })

    }
    const openReating = () => {
        Axios("Master/onPageLoad", {}, "GET", async (result) => {
            if (result.IsExpired) {
                SwAlert.Warning(undefined, i18n("common.SessionTimeOut"), lnkToHomePage);
            }
            else {
                setOpenDialog(true);
            }
        })
    }
    const labels: { [index: string]: string } = {
        0: '0',
        1: '1',
        2: '2',
        3: '3',
        4: '4',
        5: '5',
    };

    function getLabelText(value: number) {
        return `${value} Star${value !== 1 ? 's' : ''}, ${labels[value]}`;
    }

    useEffect(() => {
        onPageLoad();
        getData();

    }, []);

    const getData = async () => {
        let TopicDetail = await GetTopicDetail();
        if (TopicDetail.isSuccess) {
            let sIDDecrypt = await getDecrypt(props.sID);
            let CheckQuestionnaire = await Questionnaire_CheckNeed(sIDDecrypt);
            let TopicRating = await GetTopicRating();
            setLstFileID(TopicDetail.lstFileID);
            setDataRating(TopicRating);
            setData(TopicDetail);
            setsIdDecrypt(sIDDecrypt);
            setSchemaTmp(TopicDetail);
            setNeedTest(TopicDetail.isNeedTest);
            let arrayLesson = [] as any;
            TopicDetail.lstLesson.forEach((e, i) => {
                arrayLesson.push(e.nLessonID);
            })

            let paramPre = {
                "nTopicID": sIDDecrypt,
                "lstLessonID": [0],
                "isPreLearn": true,
            }
            let resResultExamPre = await GetResultExam(paramPre);
            setResultExamPre(resResultExamPre);
            // setResultExamPreLession(resResultExamPre);          
            let paramPost = {
                "nTopicID": sIDDecrypt,
                "lstLessonID": [0],
                "isPreLearn": false,
            }
            let resResultExamPost = await GetResultExam(paramPost);
            setResultExamPost(resResultExamPost);
            // setResultExamPostLession(resResultExamPost);

            let paramPreLession = {
                "nTopicID": sIDDecrypt,
                "lstLessonID": arrayLesson,
                "isPreLearn": true,
            }
            let resResultExamPreLession = await GetResultExam(paramPreLession);
            setResultExamPreLession(resResultExamPreLession);

            let paramPostLession = {
                "nTopicID": sIDDecrypt,
                "lstLessonID": arrayLesson,
                "isPreLearn": false,
            }
            let resResultExamPostLesson = await GetResultExam(paramPostLession);
            setResultExamPostLession(resResultExamPostLesson);

            setOpenCheckQuestion(CheckQuestionnaire);
        } else {
            SwAlert.Warning(AlertTitle.Warning, i18n("common.NoPermission"), lnkToHomePage);


        }
    }

    const onCloseCheckQuestion = () => {
        setOpenCheckQuestion(false);
    }

    const onSubmitQuestionare = async (e) => {
        let dataAns = [] as any;
        let ansValue = [] as any;

        Object.keys(e).forEach(function (key) {
            ansValue.push(Number(e[key]));
        });

        if ((ansValue.length - 1) < lstData.lstTopicQuestionnaire.length) {
            return SwAlert.Warning(AlertTitle.Warning, "กรุณาประเมินให้ครบทุกข้อ")

        }

        if (ansValue.length > 0) {
            lstData.lstTopicQuestionnaire.forEach((item, i) => {
                dataAns.push({
                    "nQuestionID": item.nQuestionID,
                    "nChoiceID": ansValue[i + 1],
                });
            })
            let param = {
                "nTopicID": sIdDecrypt,
                "lstResult": dataAns,
                "sComment": formQ.getValues("sComment") || "",
            }
            let re = await saveQuestionare(param)
            if (re.isSuccess) {
                SwAlert.Success(AlertTitle.Success, AlertMsg.SaveComplete)
            }
            await getData()
            formQ.reset();
        }
        setOpenCheckQuestion(false)
    }

    const saveQuestionare = async (param) => {
        let result = null as any;
        await Axios(
            `Course/Evaluate_SendResult`,
            param,
            "Post",
            async (res) => {
                result = res;
            },
            null,
            BlockUI,
            UnBlockUI,
            null
        );
        return result;
    }

    const Questionnaire_CheckNeed = async (param) => {
        let result = false as any;
        await Axios(
            `Course/Questionnaire_CheckNeed`,
            'nTopicID=' + param,
            "Get",
            async (res) => {
                result = res;
            },
            null,
            BlockUI,
            UnBlockUI,
            () => { }
        );
        return result;
    }

    const GetTopicDetail = async () => {
        BlockUI();
        let result = null as any;
        result = await AxiosGetAsync("Course/GetTopicDetail", { sTopicID: props.sID, sCategory: props.sCategory });
        UnBlockUI();
        return result;
    };

    const GetTopicRating = async () => {
        BlockUI();
        let result = null as any;
        result = await AxiosGetAsync("Course/TopicRating_GetAll", { sTopicID: props.sID });
        if (result.lstPercent.length === 0) {
            result = dataEx;
        }
        UnBlockUI();
        return result;
    };

    const GetResultExam = async (param) => {
        let result = null as any;
        await Axios(
            `Course/ExamScore_Get`,
            param,
            "Post",
            async (res) => {
                result = res;
            },
            null,
            BlockUI,
            UnBlockUI,
            () => { }
        );
        return result;
    }

    const getEncrypt = async (param) => {
        let result = null as any;
        await Axios(
            `Master/Encrypt`,
            "sValue=" + param,
            "Get",
            async (res) => {
                result = res;
            },
            null,
            BlockUI,
            UnBlockUI,
            () => { }
        );
        return result;
    };

    const getDecrypt = async (param) => {
        let result = null as any;
        await Axios(
            `Master/Decrypt`,
            "sValue=" + param,
            "Get",
            async (res) => {
                result = res;
            },
            null,
            BlockUI,
            UnBlockUI,
            () => { }
        );
        return result;
    };

    const TopicRating_Save = async (param) => {
        let result = null as any;
        await Axios(
            `Course/TopicRating_Save`,
            param,
            "Get",
            async (res) => {
                result = res;
                if (res.isSuccess) {
                    SwAlert.Success(AlertTitle.Success, "Thank you Rating us");
                } else {
                    SwAlert.Warning(AlertTitle.Warning, AlertMsg.Error);
                }
            },
            null,
            BlockUI,
            UnBlockUI,
            null
            //SwAlert.Warning(undefined, i18n("common.SessionTimeOut"), () => { window.location.reload() })

        );
        return result;
    };

    const handleClose = () => {
        setOpenDialog(false);
        setValue(0);
        setHover(0);
    }

    const handleClickConfirm = async () => {
        await TopicRating_Save("nTopicID=" + sIdDecrypt + "&nRate=" + value);
        let TopicRating = await GetTopicRating();
        setDataRating(TopicRating);
        setOpenDialog(false);
        setValue(0);
        setHover(0);
    }

    const handleClickCancel = () => {
        setOpenDialog(false);
        setValue(0);
        setHover(0);
    }
    const FileCheckRead = async () => {
        let box = null as any;
        let data = {
            nTopicID: sIdDecrypt,
            lstFileID: lstFileID || [],
        }

        await Axios(
            `Course/File_CheckRead`,
            data || {},
            "Post",
            async (res) => {

                if (res.length > 0) {
                    for (let i = 0; i < res.length; i++) {
                        let nID = +res[i];
                        box = document.getElementById('file_' + nID);
                        if (box != null) {
                            if (nUserType != 0) {
                                box.classList.add('file-readed');
                            }

                        }
                    }

                }

            },
            null,
            BlockUI,
            UnBlockUI,
            null
        );


    }
    const Head = () => {
        return (
            < Grid container sx={{ p: { xs: "1em", md: "2em 5em" }, alignItems: "center" }}>
                < Grid item xs={12} sm={9}>
                    < Grid item xs={12} >
                        <h1
                            className="sTitleNews"
                            style={{
                                whiteSpace: "normal",
                                textOverflow: "ellipsis"
                            }}
                        >
                            {lstData.sTitle}
                        </h1>
                    </Grid>
                    < Grid item xs={12}>
                        <p
                            className="topic-cate"
                        >
                            {lstData.sCategory}
                        </p>
                    </Grid>
                </Grid>
                < Grid item xs={12} sm={3}>
                    < Grid item xs={12} sx={{ display: "flex", alignItems: "center", justifyContent: "flex-end" }} >
                        <Typography sx={{ px: "0.5em" }}> {lstRating.sVoterAll || 0} </Typography>
                        <Rating name="half-rating-read" defaultValue={lstRating.nVoterAll || 0} precision={0.5} readOnly />
                    </Grid>
                    < Grid item xs={12} sx={{ display: "flex", alignItems: "center", justifyContent: "flex-end" }} >
                        <AiOutlineEye size={20} style={{}} /> {lstData.sViewContent}
                    </Grid>
                </Grid>
            </Grid>
        );
    };

    const BoxVideo = (data) => {
        return (
            <Grid
                item
                xs={12}
                md={12}
                lg={12}
                sx={{ margin: { xs: "1em 1em", md: "1em 4em" }, textAlign: "center" }}
            >
                <ReactPlayer
                    url={data.data.sCoverVDOPath}
                    style={{ textAlign: "center" }}
                    width="100%"
                    height="auto"
                    controls={true}
                />
            </Grid>
        );
    };

    const BoxImage = (data) => {
        return (
            <Grid
                item
                xs={12}
                md={12}
                lg={12}
                sx={{ margin: { xs: "1em 1em", md: "1em 4em" }, textAlign: "center" }}
            >
                <div>
                    <img src={data.data.sCoverImagePath} style={{ width: "100%" }} />
                    {/* <LightGallery plugins={[lgZoom, lgVideo]} mode="lg-fade">
                        <a
                            data-lg-size="1406-1390"
                            className="gallery-item"
                            data-src="https://images.unsplash.com/photo-1581894158358-5ecd2c518883?ixid=MXwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHw%3D&ixlib=rb-1.2.1&auto=format&fit=crop&w=1406&q=80"
                            data-sub-html=""
                        >
                            <img
                                className="img-responsive"
                                src="https://images.unsplash.com/photo-1581894158358-5ecd2c518883?ixid=MXwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHw%3D&ixlib=rb-1.2.1&auto=format&fit=crop&w=240&q=80"
                            />
                        </a>
                    </LightGallery> */}
                </div>
            </Grid>
        );
    };

    const BoxDetail = (data) => {
        return (
            <>
                <Grid item sx={{ m: { xs: "1em 1em", md: "1em 4em" } }}>
                    <p
                        className="sContent"
                        style={{
                            whiteSpace: "normal",
                            textOverflow: "ellipsis",
                            margin: "5px 10px",
                        }}
                    >
                        {ParseHtml(data.data.sContent)}
                    </p>
                </Grid>
            </>
        );
    };

    const BoxRating = (dataRating) => {
        const style = {
            position: 'absolute',
            top: '50%',
            left: '50%',
            transform: 'translate(-50%, -50%)',
            bgcolor: 'background.paper',
            boxShadow: 24,
        };
        return (
            <Grid container sx={{ my: "2%" }}>
                <Grid item xs={12} sx={{ mx: { xs: "1em", sm: "2em", md: "4em" }, display: "flex", alignItems: "center", flexWrap: "wrap" }}>
                    <Grid item xs={12} sm={3} lg={7}>
                    </Grid>
                    <Grid item xs={12} sm={4} lg={2} sx={{ display: "flex", flexDirection: "column", alignItems: "center" }}>
                        <Grid item xs={12}>
                            <Typography variant="h5" >{lstRating.sVoterAll || 0}</Typography>
                        </Grid>
                        <Grid item xs={12}>
                            <Rating name="half-rating-read" defaultValue={lstRating.nVoterAll || 0} precision={0.5} readOnly />
                        </Grid>
                        <Grid item xs={12} >
                            {(lstRating.nPerson !== undefined) ? (
                                <Typography sx={{ display: "flex" }}><Person />&nbsp;ผู้ให้คะแนน {lstRating.nPerson} คน</Typography>
                            ) : (
                                <Typography sx={{ display: "flex" }}><Person />&nbsp;ยังไม่มีผู้ให้คะแนน</Typography>
                            )}
                        </Grid>
                        {(localStorage.getItem(process.env.REACT_APP_JWT_KEY as string) != null
                            || localStorage.getItem(process.env.REACT_APP_JWT_KEY as string) != undefined) ? (
                            <Grid item xs={12} sx={{ display: "flex", alignItems: "center" }}>
                                <TiPencil />
                                <Typography
                                    sx={{
                                        cursor: "pointer",
                                        color: "rgb(57, 151, 223)",
                                        ":active": { color: "rgba(57, 151, 223,0.5)" }
                                    }}
                                    onClick={() => {
                                        lstData.isRatingTrigger ? openReating() : <></>;
                                    }}>ให้คะแนน</Typography>

                            </Grid>
                        ) : (
                            <></>
                        )}
                    </Grid>
                    <Grid item xs={12} sm={5} lg={3} sx={{ borderRadius: "16px", border: "1px solid rgba(0,0,0,0.1)" }}>
                        {(lstRating.lstPercent || dataEx).map((m) => {
                            return (
                                <>
                                    <Grid item xs={12} sx={{ display: "flex", justifyContent: "start", m: "0.5em 1.5em" }}>
                                        <Grid item xs={1} sx={{ display: "flex", alignItems: "center" }}>
                                            {m.nStar}<StarIcon sx={{ color: "gold" }} />
                                        </Grid>
                                        <Grid item xs={9} sx={{ display: "flex", flexDirection: "column", justifyContent: "center", pl: "1em" }}>
                                            <BorderLinearProgress variant="determinate" value={m.nVoteCount} sx={{
                                                ".MuiLinearProgress-bar": { bgcolor: "rgba(159,192,90,1) !important" },
                                                bgcolor: "rgba(159,192,90,0.6)"
                                            }} />
                                        </Grid>
                                        <Grid item xs={2} sx={{ display: "flex", alignItems: "center", ml: "0.5em" }}>
                                            <Typography>
                                                {m.sPercent.substring(0, m.sPercent.length - 1)}%
                                            </Typography>
                                        </Grid>

                                    </Grid>
                                </>
                            )
                        })}
                    </Grid>
                </Grid>
            </Grid>
        );
    };

    const BoxCouseContent = (data) => {
        const [isOpenVideo, setOpenVideo] = useState(false);
        const [isOpenPDF, setOpenPDF] = useState(false);
        const [isOpenTest, setOpenTest] = useState(false);
        const [dataURL, setDataURL] = useState("");
        const [IDName, setnIDName] = useState(0);
        const [sType, setsType] = useState("");
        const [lstDataExam, setlstExam] = useState([] as any);
        const [supposedCurrentTime, setSupposedCurrentTime] = useState(0);
        const [IsPreTest, setIsPreTest] = useState(false);

        const [nPlayedTime, setPlayedTime] = useState(0);
        const [playing, setPlaying] = useState(true)
        const [playTimeStart, setPlayTime] = useState(true)

        const [schemaObj, setSchemaObj] = useState<any>({} as any);
        const [schemaTemp, setschemaTemp] = useState({} as any);
        const schema = yup.object().shape(schemaObj);
        const form = useForm({
            mode: "all",
            resolver: yupResolver(schema),
            shouldUseNativeValidation: false,
        });

        useEffect(() => {
            const objSchema = {
                ...schemaTemp,
            };
            setSchemaObj({ ...objSchema });
        }, [schemaTemp])

        const setSchemaTmp = (e) => {
            let emplate = {};
            (e.lstExam || []).forEach((item, i) => {
                if (item != null) {
                    item.lstChoice.forEach((items, ii) => {
                        emplate["radio_" + i] = yupFormSchemas.string("คำตอบ", { required: true, });
                    })
                }
            });
            setschemaTemp(emplate)
        }

        const style = {
            position: 'absolute',
            top: '50%',
            left: '50%',
            transform: 'translate(-50%, -50%)',
            boxShadow: 24,
        };

        const CheckFile = async (nLessonID, nID, sType, sURL, sName) => {
            let sCategoryDecrypt = await getDecrypt(props.sCategory);
            let param = "nTopicID=" + sIdDecrypt + "&nCategoryID=" + (sCategoryDecrypt !== null ? sCategoryDecrypt : 0) +
                "&nLessonID=" + nLessonID + "&nFileID=" + nID;
            let res = await LessonFile_Verify(param);
            if (res !== null) {
                if (res.isSuccess === false) {
                    return SwAlert.Warning(AlertTitle.Warning, res.sMessage)
                }
            }

            setDataURL(sURL);
            setnIDName(nID);
            setsType(sType);
            if (sType === "mp4" || sType === "pdf") {
                view(sURL, nID, sType)
            }
            else if (sType === 'jpg' || sType === 'jpeg' || sType === 'png' || sType === 'gif' || sType === 'bmp') {
                openGallery(nID);
            }
            else {
                loadFile(nID, sURL, sName)
            }
        }

        const loadFile = async (nID, sURL, sName) => {
            setnIDName(nID);
            let sFile = document.createElement('a');
            sFile.download = sName;
            sFile.href = sURL//sURL.substring(44);
            sFile.click();
            getData();
        }

        const view = async (sURL, nID, sType) => {
            setnIDName(nID);
            if (sType === "mp4") {
                setOpenVideo(true);
                timeStarted = new Date().getTime() / 1000;


            } else {
                setOpenPDF(true);
            }
        }

        const Get_Exam = async (param) => {
            let result = null as any;
            await Axios(
                `Course/Exam_Get`,
                param,
                "Post",
                async (res) => {
                    result = res;
                },
                null,
                BlockUI,
                UnBlockUI,
                null
                //SwAlert.Warning(undefined, i18n("common.SessionTimeOut"), () => { window.location.reload() })
            );
            return result;
        }

        const LessonFile_Verify = async (param) => {
            let result = null as any;
            await Axios(
                `Course/LessonFile_Verify`,
                param,
                "Get",
                async (res) => {
                    result = res;
                },
                null,
                BlockUI,
                UnBlockUI,
                () => { }
            );
            return result;
        }

        const saveLogView = async (param) => {
            let result = null as any;
            await Axios(
                `Course/SaveLogView`,
                "nTopicID=" + param,
                "Get",
                async (res) => {
                    result = res;
                },
                null,
                BlockUI,
                UnBlockUI,
                () => { }
            );
            return result;
        }

        const saveExam = async (param) => {
            let result = null as any;
            await Axios(
                `Course/Exam_SendResult`,
                param,
                "Post",
                async (res) => {
                    result = res;
                },
                null,
                BlockUI,
                UnBlockUI,
                null
                //SwAlert.Warning(undefined, i18n("common.SessionTimeOut"), () => { window.location.reload() })
            );

            return result;
        }

        const saveQuestionare = async (param) => {
            let result = null as any;
            await Axios(
                `Course/Evaluate_SendResult`,
                param,
                "Post",
                async (res) => {
                    result = res;
                },
                null,
                BlockUI,
                UnBlockUI,
                null
                //SwAlert.Warning(undefined, i18n("common.SessionTimeOut"), () => { window.location.reload() })
            );
            return result;
        }

        const onCloseVideo = async () => {

            let video = document.getElementById("video_" + IDName) as HTMLVideoElement;
            video.pause();
            videoStoppedPlaying();

            setOpenVideo(false);
            //  videoRef.current.currentTime > videoRef.current.duration / 2
            if (timePlayed > (duration / 2)) {
                await saveLogView(IDName);
                let TopicDetail = await GetTopicDetail();
                setData(TopicDetail);
                needTodo();
                FileCheckRead();

            }

            timeStarted = -1;
            timePlayed = 0;
            duration = 0;
            video.currentTime = 0;

        }

        const onClosePDF = async () => {
            setOpenPDF(false);
            await saveLogView(IDName);
            let TopicDetail = await GetTopicDetail();
            setData(TopicDetail);
            needTodo();
            FileCheckRead();
        }



        const getDuration = () => {
            let video = document.getElementById("video_" + IDName) as HTMLVideoElement;
            duration = video.duration;

            // console.log("duration", Math.round(duration));
        }

        const videoStartedPlaying = () => {

            let video = document.getElementById("video_" + IDName) as HTMLVideoElement;
            if (video != null) {
                if (video.readyState > 0) {
                    getDuration.call(video);
                }
                else {
                    video.addEventListener('loadedmetadata', getDuration);
                }

                timeStarted = new Date().getTime() / 1000;
            }

        }

        const videoStoppedPlaying = () => {
            
            if (timeStarted > 0) {
                let playedFor = new Date().getTime() / 1000 - timeStarted;
                timeStarted = -1;
                timePlayed += playedFor;
            }
            // console.log("timePlayed", Math.round(timePlayed));
        }

        const play = () => {
            videoStartedPlaying()
        }
        const onPlay = () => {
            
            setPlaying(true);
            if (playTimeStart) {
                timeStarted = new Date().getTime() / 1000;
                setPlayTime(false);
            }

        }
        const pause = () => {
            setPlaying(false);
            setPlayTime(true);
            videoStoppedPlaying()
        }

        const seeking = (e) => {
            let getVideo = document.getElementById("video_" + IDName) as HTMLVideoElement;
            let delta = getVideo.currentTime - supposedCurrentTime;
            if (Math.abs(delta) > 0.01) {
                getVideo.currentTime = supposedCurrentTime;
            }
        }

        const timeUpdate = (e) => {
            let getVideo = document.getElementById("video_" + IDName) as HTMLVideoElement;
            if (getVideo !== null) {
                if (!getVideo.seeking) {
                    setSupposedCurrentTime(getVideo.currentTime);
                }
            }
        }

        const endVideo = async () => {
            setOpenVideo(false);
            setPlaying(false);
            await onCloseVideoChecked();

        }

        const onCloseVideoChecked = async () => {
            
            if (playerRef.current) {
                if (timeStarted > 0) {
                    let playedFor = new Date().getTime() / 1000 - timeStarted;
                    timeStarted = -1;
                    timePlayed += playedFor;
                }

                if (timePlayed > (playerRef.current.getDuration() / 2)) {

                    await saveLogView(IDName);
                    let TopicDetail = await GetTopicDetail();
                    setData(TopicDetail);
                    await needTodo();
                    await FileCheckRead();

                }
            }
            timePlayed = 0;
            setPlayedTime(0);

        }

        const onClickPreTest = async (mode, nLessonID) => {
            //mode {1 = PreTestLession , 2 = PreTest}
            let param = {
                "nTopicID": sIdDecrypt,
                "nLessonID": mode === 1 ? null : nLessonID,
                "isPreTest": true
            }
            let lstdata = await Get_Exam(param);
            if (!lstdata.isSuccess) {
                return SwAlert.Warning(AlertTitle.Warning, lstdata.sMessage);

            }

            setlstExam(lstdata)
            setSchemaTmp(lstdata);
            setOpenTest(true);
            setIsPreTest(true);
        }

        const onClickPostTest = async (mode, nLessonID) => {
            //mode {1 = PostTestLession , 2 = PostTest}
            let param = {
                "nTopicID": sIdDecrypt,
                "nLessonID": mode === 1 ? null : nLessonID,
                "isPreTest": false
            }
            let lstdata = await Get_Exam(param);
            if (!lstdata.isSuccess) {
                return SwAlert.Warning(AlertTitle.Warning, lstdata.sMessage);
            }
            setlstExam(lstdata)
            setSchemaTmp(lstdata);
            setOpenTest(true);
            setIsPreTest(false);
        }

        const onCloseTest = () => {
            setOpenTest(false);
        }



        const onSubmitTest = async (e) => {
            let dataAns = [] as any;
            let ansValue = [] as any;

            Object.keys(e).forEach(function (key) {
                ansValue.push(Number(e[key]));
            });

            setOpenTest(false)

            if (ansValue.length > 0) {
                lstDataExam.lstExam.forEach((item, i) => {
                    dataAns.push({
                        "nLessonID": item.nLessonID,
                        "nQuestionID": item.nQuestionID,
                        "nChoiceID": ansValue[i],
                    });
                })
                let param = {
                    "nTopicID": sIdDecrypt,
                    "lstAnswer": dataAns,
                    "isPreTest": IsPreTest
                }
                //  console.log(JSON.stringify(param))
                await saveExam(param)
                await getData()
                form.reset()
                // ansValue.forEach(e => {
                //     form.reset(e)
                // });
            }
        }

        const onSubmitQuestionare = async (e) => {
            let dataAns = [] as any;
            let ansValue = [] as any;

            Object.keys(e).forEach(function (key) {
                ansValue.push(Number(e[key]));
            });


            if (ansValue.length > 0) {
                lstData.lstTopicQuestionnaire.forEach((item, i) => {
                    dataAns.push({
                        "nQuestionID": item.nQuestionID,
                        "nChoiceID": ansValue[i + 1],
                    });
                })
                let param = {
                    "nTopicID": sIdDecrypt,
                    "lstResult": dataAns,
                    "sComment": form.getValues("sComment") || "",
                }
                await saveQuestionare(param)
                await getData()
                form.reset();
                // ansValue.forEach(e => {
                //     form.reset(e)
                // });
            }
            setOpenCheckQuestion(false)

        }

        const needTodo = async () => {
            let sIDDecrypt = await getDecrypt(props.sID);
            let CheckQuestionnaire = await Questionnaire_CheckNeed(sIDDecrypt);
            setOpenCheckQuestion(CheckQuestionnaire);

        }
        //#region Open Image
        const onCloseImage = () => {
            getData();

            //console.log('onCloseImage: ' + IDName);
        }
        const DivImage = (URL) => {

            const [items, setItems] = useState([
                {
                    src: URL.URL,
                },
            ]);

            return (
                <LightGallery
                    onAfterClose={() => onCloseImage()}
                    elementClassNames="custom-classname"
                    dynamic
                    dynamicEl={items}
                    onInit={onInit}
                    plugins={[lgThumbnail]}
                ></LightGallery>
            );
        }
        const openGallery = useCallback((nID) => {

            setnIDName(nID);
            lightGallery.current.openGallery();
        }, []);
        const onInit = useCallback((detail: any) => {
            if (detail) {
                // console.log(detail)
                lightGallery.current = detail.instance;
            }
        }, []);
        //#endregion Open Image

        const GetBlobVideo = async (url) => {
            let o = {
                sPath: url,
            }
            await AxiosPostFileVideo(`Course/GetBlobVideo`, o, (res) => {
                setDataURL(res);
            }, null, BlockUI, UnBlockUI, "TemplateImport_Participant")

        }

        const BodyVideo = useCallback(() => {
            setPlaying(true);

            return (
                <>
                    {dataURL && (
                        <ReactPlayer
                            ref={playerRef}
                            config={{
                                file: {
                                    attributes: { controlsList: "nodownload" },
                                },
                            }}
                            className="react-player"
                            url={dataURL}
                            width="100%"
                            height="100%"
                            // playing={true}
                            controls={true}
                            pip={true}
                            muted
                            stopOnUnmount={true}
                            // onProgress={(playedSeconds) => {
                            //     let nProgress = playedSeconds.playedSeconds || 0;

                            //     if (nProgress > nPlayedTime) {

                            //         if (nProgress - nPlayedTime < 2) {

                            //             setPlayedTime(nProgress);
                            //         }
                            //     } 


                            // }}
                            playing={playing}
                            onPlay={() => onPlay()}
                            onPause={() => pause()}

                        />

                        //    <video
                        //         playsInline
                        //         contextMenu="return false;"
                        //         id={"video_" + IDName}
                        //         src={dataURL}
                        //         key={dataURL}
                        //         controls={true}
                        //         autoPlay={true}
                        //         muted
                        //         width="100%"
                        //         height="auto"
                        //         controlsList="nodownload"
                        //         onEnded={endVideo}
                        //         onPlay={play}
                        //         onPlaying={play}
                        //         onPlayingCapture={play}
                        //         onPause={play}
                        //         ref={videoRef}

                        //     />
                    )}

                </>
            );

        }, [IDName]);

        return (
            <>
                <Grid container sx={{ p: { xs: "1em 1em", md: "2em 4em" }, display: "flex" }}>
                    <Grid item xs={12} md={12} lg={12} >
                        <p className="topic-content-title">เนื้อหาของหลักสูตร/Course Content</p>
                    </Grid>
                    {/* <EmbedVideo /> */}
                    <Grid item xs={12} sx={{
                        display: "flex", alignItems: "center", flexDirection: { xs: "column", sm: "row" },
                        borderBottom: "1px solid rgba(0,0,0,0.1)"
                    }} >
                        <Grid item xs={12} sm={3} lg={6} sx={{
                            display: "flex", justifyContent: "flex-start", my: "1em"
                        }}>
                            <Typography sx={{ mr: "10px", display: "flex", alignItems: "center" }}>
                                <img style={{ width: "32px" }} src={process.env.REACT_APP_KMS + "Images/file-type/mp4.png"} />
                                {lstData.nVideoCount} วิดีโอ
                            </Typography>
                            <Typography sx={{ mr: "10px", display: "flex", alignItems: "center" }}>
                                <img style={{ width: "32px" }} src={process.env.REACT_APP_KMS + "Images/file-type/pdf.png"} />
                                {lstData.nFilesCount} ไฟล์
                            </Typography>
                        </Grid>
                        <Grid item xs={12} sm={9}
                            sx={{
                                display: "flex",
                                justifyContent: "flex-end",
                                alignItems: "center",
                                flexDirection: { xs: "column", sm: "row" }
                            }}>
                            {lstData.isExamPre ?
                                <>
                                    {ResultExamPre.length > 0 ?
                                        <>
                                            {ResultExamPre.map((mEx) => {
                                                return (
                                                    <>
                                                        <Grid item xs={12} sx={{
                                                            display: "flex",
                                                            borderRadius: "16px",
                                                            mr: "1em",
                                                            height: "35px",
                                                            width: { xs: "100%", sm: "auto" },
                                                            mb: { xs: "1em", sm: "0px" },
                                                        }}>
                                                            <Grid item xs={5} sx={{
                                                                borderTopLeftRadius: "16px",
                                                                borderBottomLeftRadius: "16px",
                                                                bgcolor: "rgba(0,0,0,0.2)",
                                                                height: "35px",
                                                                display: "flex",
                                                                alignItems: "center",
                                                                justifyContent: "center",
                                                                border: "1px solid rgba(0,0,0,0.5)",
                                                            }}>
                                                                <Typography sx={{
                                                                    textAlign: "center",
                                                                    fontSize: "0.9em",
                                                                    color: "rgba(0,0,0,0.5) "
                                                                }}> ก่อนเรียน</Typography>
                                                            </Grid>
                                                            <Grid item xs={2} sx={{
                                                                height: "35px",
                                                                display: "flex",
                                                                alignItems: "center",
                                                                justifyContent: "center",
                                                                borderTop: "1px solid rgba(0,0,0,0.5)",
                                                                borderBottom: "1px solid rgba(0,0,0,0.5)",
                                                                borderRight: "1px solid rgba(0,0,0,0.5)",
                                                            }}>
                                                                <Typography sx={{
                                                                    textAlign: "center",
                                                                    fontSize: "0.9em",
                                                                    color: "rgba(0,0,0,0.5)"
                                                                }}> {mEx.nSumScore_Correct}/{mEx.nSumScore_Max}</Typography>
                                                            </Grid>
                                                            <Grid item xs={5} sx={{
                                                                bgcolor: mEx.isPass ? "rgb(159,192,90,0.35)" : "rgba(255,50,50,0.5)",
                                                                borderTopRightRadius: "16px",
                                                                borderBottomRightRadius: "16px",
                                                                height: "35px",
                                                                display: "flex",
                                                                alignItems: "center",
                                                                justifyContent: "center",
                                                                border: mEx.isPass ? " 1px solid rgb(159,192,90,0.35)" : "1px solid rgba(255,50,50,0.5)",
                                                            }}>
                                                                <Typography sx={{
                                                                    textAlign: "center",
                                                                    fontSize: "0.9em",
                                                                    color: mEx.isPass ? "rgb(159,192,90,1)" : "rgba(255,50,50)",
                                                                }}>{mEx.isPass ? "ผ่านเกณฑ์" : "ไม่ผ่านเกณฑ์"}</Typography>
                                                            </Grid>
                                                        </Grid>
                                                    </>
                                                );
                                            })}
                                        </>
                                        :
                                        <>
                                            {isNeedTest ?
                                                <BtnCustomText width={"250px"} backgroundColor="rgba(255,137,0,0.7)" txt="แบบทดสอบก่อนเรียน"
                                                    onClick={() => {
                                                        onClickPreTest(1, null)
                                                    }} />
                                                :
                                                <></>
                                            }
                                        </>
                                    }
                                </>
                                :
                                <></>
                            }
                            {lstData.isExamPost ?
                                <>
                                    {ResultExamPost.length > 0 ?
                                        <>
                                            {ResultExamPost.map((mPo) => {
                                                return (
                                                    <>
                                                        <Grid item xs={15} sx={{
                                                            display: "flex",
                                                            borderRadius: "16px",
                                                            // border: "1px solid rgba(0,0,0,0.2)",
                                                            mr: "1em",
                                                            height: "35px",
                                                            width: { xs: "100%", sm: "auto" },
                                                            mb: { xs: "1em", sm: "0px" },
                                                        }}>
                                                            <Grid item xs={3} sx={{
                                                                borderTopLeftRadius: "16px",
                                                                borderBottomLeftRadius: "16px",
                                                                bgcolor: "rgba(188,232,241,0.5)",
                                                                height: "35px",
                                                                display: "flex",
                                                                alignItems: "center",
                                                                justifyContent: "center",
                                                                border: "1px solid rgba(151,195,204)",
                                                            }}>
                                                                <Typography sx={{ textAlign: "center", fontSize: "0.9em", color: "rgb(151,195,204)" }}>หลังเรียน</Typography>
                                                            </Grid>
                                                            <Grid item xs={2} sx={{
                                                                height: "35px",
                                                                display: "flex",
                                                                alignItems: "center",
                                                                justifyContent: "center",
                                                                color: "rgba(0,0,0,0.5)",
                                                                borderTop: "1px solid rgba(0,0,0,0.5)",
                                                                borderBottom: "1px solid rgba(0,0,0,0.5)",
                                                                borderRight: "1px solid rgba(0,0,0,0.5)",
                                                            }}>
                                                                <Typography sx={{ textAlign: "center", fontSize: "0.9em" }}> {mPo.nSumScore_Correct}/{mPo.nSumScore_Max}</Typography>
                                                            </Grid>
                                                            <Grid item xs={3.5} sx={{
                                                                bgcolor: mPo.isPass ? "rgb(159,192,90,0.35)" : "rgba(255,50,50,0.5)",
                                                                height: "35px",
                                                                display: "flex",
                                                                alignItems: "center",
                                                                justifyContent: "center",
                                                                border: mPo.isPass ? " 1px solid rgb(159,192,90,0.35)" : "1px solid rgba(255,50,50,0.5)",
                                                            }}>
                                                                <Typography sx={{
                                                                    textAlign: "center",
                                                                    fontSize: "0.9em",
                                                                    color: mPo.isPass ? "rgb(159,192,90,1)" : "rgba(255,50,50)",
                                                                }}>{mPo.isPass ? "ผ่านเกณฑ์" : "ไม่ผ่านเกณฑ์"}</Typography>
                                                            </Grid>
                                                            <Grid item xs={3.5}
                                                                onClick={() => { onClickPostTest(2, null) }}
                                                                sx={{
                                                                    height: "35px",
                                                                    display: "flex",
                                                                    alignItems: "center",
                                                                    justifyContent: "center",
                                                                    cursor: "pointer",
                                                                    bgcolor: "rgb(25,118,210,0.5)",
                                                                    borderTopRightRadius: "16px",
                                                                    borderBottomRightRadius: "16px",
                                                                    border: "1px solid rgb(25,118,210,0.5)"
                                                                }}>
                                                                <Typography sx={{
                                                                    textAlign: "center",
                                                                    fontSize: "0.9em",
                                                                    color: "rgb(255,255,255,1)",
                                                                }}>ทดสอบอีกครั้ง</Typography>
                                                            </Grid>
                                                        </Grid>
                                                    </>
                                                );
                                            })}
                                        </>
                                        :
                                        <>
                                            <BtnCustomText width={"250px"} backgroundColor="" txt="แบบทดสอบหลังเรียน"
                                                onClick={() => {
                                                    onClickPostTest(1, null)
                                                }} />
                                        </>
                                    }
                                </>
                                : <></>
                            }
                        </Grid>
                    </Grid>
                    {(lstData.lstLesson || []).map((m) => {
                        return (
                            <>
                                <Grid item xs={12} sx={{ display: "flex", alignItems: "start", flexDirection: { xs: "column", sm: "row", borderBottom: "1px solid rgba(0,0,0,0.1)" } }} >
                                    <Grid item xs={12} sm={6} sx={{ display: "flex", justifyContent: "flex-start", alignItems: "center" }}>
                                        <p style={{ padding: "1em", margin: 0 }} className="topic-content-title">{m.sName}</p>
                                    </Grid>
                                    {(m.needPreTest || m.needPostTest) &&
                                        <Grid item xs={12} sm={6} sx={{ display: "flex", justifyContent: "flex-end", p: "2em 1em" }}>

                                            {m.needPreTest ?
                                                <>
                                                    {ResultExamPreLession.length > 0 ?
                                                        <>
                                                            {ResultExamPreLession.map((mEx) => {
                                                                return (
                                                                    <>
                                                                        {m.nLessonID === mEx.nLessonID &&
                                                                            <Grid item xs={12} sx={{
                                                                                display: "flex",
                                                                                borderRadius: "16px",
                                                                                mr: "1em",
                                                                                height: "35px",
                                                                            }}>
                                                                                <Grid item xs={5} sx={{
                                                                                    borderTopLeftRadius: "16px",
                                                                                    borderBottomLeftRadius: "16px",
                                                                                    bgcolor: "rgba(0,0,0,0.2)",
                                                                                    height: "35px",
                                                                                    display: "flex",
                                                                                    alignItems: "center",
                                                                                    justifyContent: "center",
                                                                                    border: "1px solid rgba(0,0,0,0.5)",
                                                                                }}>
                                                                                    <Typography sx={{
                                                                                        textAlign: "center",
                                                                                        fontSize: "0.9em",
                                                                                        color: "rgba(0,0,0,0.5) "
                                                                                    }}> ก่อนเรียน</Typography>
                                                                                </Grid>
                                                                                <Grid item xs={2} sx={{
                                                                                    height: "35px",
                                                                                    display: "flex",
                                                                                    alignItems: "center",
                                                                                    justifyContent: "center",
                                                                                    borderTop: "1px solid rgba(0,0,0,0.5)",
                                                                                    borderBottom: "1px solid rgba(0,0,0,0.5)",
                                                                                    borderRight: "1px solid rgba(0,0,0,0.5)",
                                                                                }}>
                                                                                    <Typography sx={{
                                                                                        textAlign: "center",
                                                                                        fontSize: "0.9em",
                                                                                        color: "rgba(0,0,0,0.5)"
                                                                                    }}> {mEx.nSumScore_Correct}/{mEx.nSumScore_Max}</Typography>
                                                                                </Grid>
                                                                                <Grid item xs={5} sx={{
                                                                                    bgcolor: mEx.isPass ? "rgb(159,192,90,0.35)" : "rgba(255,50,50,0.5)",
                                                                                    borderTopRightRadius: "16px",
                                                                                    borderBottomRightRadius: "16px",
                                                                                    height: "35px",
                                                                                    display: "flex",
                                                                                    alignItems: "center",
                                                                                    justifyContent: "center",
                                                                                    border: mEx.isPass ? " 1px solid rgb(159,192,90,0.35)" : "1px solid rgba(255,50,50,0.5)",
                                                                                }}>
                                                                                    <Typography sx={{
                                                                                        textAlign: "center",
                                                                                        fontSize: "0.9em",
                                                                                        color: mEx.isPass ? "rgb(159,192,90,1)" : "rgba(255,50,50)",
                                                                                    }}>{mEx.isPass ? "ผ่านเกณฑ์" : "ไม่ผ่านเกณฑ์"}</Typography>
                                                                                </Grid>
                                                                            </Grid>
                                                                        }
                                                                    </>
                                                                );
                                                            })}
                                                        </>
                                                        :
                                                        <>
                                                            {isNeedTest ?
                                                                <BtnCustomText width={"250px"} backgroundColor="rgba(255,137,0,0.7)" txt="แบบทดสอบก่อนเรียน"
                                                                    onClick={() => {
                                                                        onClickPreTest(2, m.nLessonID)
                                                                    }} />
                                                                :
                                                                <></>
                                                            }
                                                        </>
                                                    }
                                                </>
                                                : <></>
                                            }
                                            {m.needPostTest ?
                                                <>
                                                    {ResultExamPostLession.length > 0 ?
                                                        <>
                                                            {ResultExamPostLession.map((mPo) => {
                                                                return (
                                                                    <>
                                                                        {m.nLessonID === mPo.nLessonID &&
                                                                            <Grid item xs={15} sx={{
                                                                                display: "flex",
                                                                                borderRadius: "16px",
                                                                                // border: "1px solid rgba(0,0,0,0.2)",
                                                                                mr: "1em",
                                                                                height: "35px"

                                                                            }}>
                                                                                <Grid item xs={3} sx={{
                                                                                    borderTopLeftRadius: "16px",
                                                                                    borderBottomLeftRadius: "16px",
                                                                                    bgcolor: "rgba(188,232,241,0.5)",
                                                                                    height: "35px",
                                                                                    display: "flex",
                                                                                    alignItems: "center",
                                                                                    justifyContent: "center",
                                                                                    border: "1px solid rgba(151,195,204)",
                                                                                }}>
                                                                                    <Typography sx={{ textAlign: "center", fontSize: "0.9em", color: "rgb(151,195,204)" }}>หลังเรียน</Typography>
                                                                                </Grid>
                                                                                <Grid item xs={2} sx={{
                                                                                    height: "35px",
                                                                                    display: "flex",
                                                                                    alignItems: "center",
                                                                                    justifyContent: "center",
                                                                                    color: "rgba(0,0,0,0.5)",
                                                                                    borderTop: "1px solid rgba(0,0,0,0.5)",
                                                                                    borderBottom: "1px solid rgba(0,0,0,0.5)",
                                                                                    borderRight: "1px solid rgba(0,0,0,0.5)",
                                                                                }}>
                                                                                    <Typography sx={{ textAlign: "center", fontSize: "0.9em" }}> {mPo.nSumScore_Correct}/{mPo.nSumScore_Max}</Typography>
                                                                                </Grid>
                                                                                <Grid item xs={3.5} sx={{
                                                                                    bgcolor: mPo.isPass ? "rgb(159,192,90,0.35)" : "rgba(255,50,50,0.5)",
                                                                                    height: "35px",
                                                                                    display: "flex",
                                                                                    alignItems: "center",
                                                                                    justifyContent: "center",
                                                                                    border: mPo.isPass ? " 1px solid rgb(159,192,90,0.35)" : "1px solid rgba(255,50,50,0.5)",
                                                                                }}>
                                                                                    <Typography sx={{
                                                                                        textAlign: "center",
                                                                                        fontSize: "0.9em",
                                                                                        color: mPo.isPass ? "rgb(159,192,90,1)" : "rgba(255,50,50)",
                                                                                    }}>{mPo.isPass ? "ผ่านเกณฑ์" : "ไม่ผ่านเกณฑ์"}</Typography>
                                                                                </Grid>
                                                                                <Grid item xs={3.5}
                                                                                    onClick={() => { onClickPostTest(2, m.nLessonID) }}
                                                                                    sx={{
                                                                                        height: "35px",
                                                                                        display: "flex",
                                                                                        alignItems: "center",
                                                                                        justifyContent: "center",
                                                                                        cursor: "pointer",
                                                                                        bgcolor: "rgb(25,118,210,0.5)",
                                                                                        borderTopRightRadius: "16px",
                                                                                        borderBottomRightRadius: "16px",
                                                                                        border: "1px solid rgb(25,118,210,0.5)"
                                                                                    }}>
                                                                                    <Typography sx={{
                                                                                        textAlign: "center",
                                                                                        fontSize: "0.9em",
                                                                                        color: "rgb(255,255,255,1)",
                                                                                    }}>ทดสอบอีกครั้ง</Typography>
                                                                                </Grid>
                                                                            </Grid>
                                                                        }
                                                                    </>
                                                                );
                                                            })}
                                                        </>
                                                        :
                                                        <>
                                                            {m.needPostTest && <BtnCustomText width={"250px"} backgroundColor="" txt="แบบทดสอบหลังเรียน"
                                                                onClick={() => {
                                                                    onClickPostTest(2, m.nLessonID)
                                                                }} />}
                                                        </>
                                                    }
                                                </>
                                                : <></>
                                            }
                                        </Grid>
                                    }
                                </Grid>
                                <Grid container sx={{ display: "flex", alignItems: "center", borderBottom: "1px solid rgba(0,0,0,0.1)" }} >
                                    {(m.lstFile || []).map((ms) => {
                                        let tFile = "";
                                        let pathFile = process.env.REACT_APP_KMS + "Images/file-type/";
                                        switch (ms.sType) {
                                            case "bmp":
                                                tFile = pathFile + "bmp.png"
                                                break;
                                            case "doc":
                                                tFile = pathFile + "doc.png"
                                                break;
                                            case "gif":
                                                tFile = pathFile + "gif.png"
                                                break;
                                            case "jpg":
                                                tFile = pathFile + "jpg.png"
                                                break;
                                            case "mp3":
                                                tFile = pathFile + "mp3.png"
                                                break;
                                            case "mp4":
                                                tFile = pathFile + "mp4.png"
                                                break;
                                            case "pdf":
                                                tFile = pathFile + "pdf.png"
                                                break;
                                            case "png":
                                                tFile = pathFile + "png.png"
                                                break;
                                            case "ppt":
                                                tFile = pathFile + "ppt.png"
                                                break;
                                            case "rtf":
                                                tFile = pathFile + "rtf.png"
                                                break;
                                            case "xls":
                                                tFile = pathFile + "xls.png"
                                                break;
                                            default:
                                                break;
                                        }
                                        return (
                                            <>

                                                <Grid item xs={6} sm={2.4} sx={{ mt: "1em", mr: { xs: "1em", sm: "0" } }}>
                                                    <>
                                                        <a id={"file_" + ms.nID}
                                                            className={'topic-file media' + ((nUserType != 0 && ms.isStudied) ? ' file-readed' : '')}
                                                            style={{ textDecoration: "none", cursor: "pointer" }}
                                                            onClick={() => {
                                                                lstData.isExamPre || m.needPreTest ?
                                                                    // (m.needPreTest ?
                                                                    (((ResultExamPre.length > 0) || !isNeedTest) ?
                                                                        CheckFile(m.nLessonID, ms.nID, ms.sType, ms.sURL, ms.sName)
                                                                        : SwAlert.Warning("แจ้งเตือน", "กรุณาทำแบบทดสอบก่อนเรียน"))
                                                                    // : CheckFile(m.nLessonID, ms.nID, ms.sType, ms.sURL, ms.sName))
                                                                    : CheckFile(m.nLessonID, ms.nID, ms.sType, ms.sURL, ms.sName)
                                                            }}
                                                        >
                                                            <div className='media-left'>
                                                                <img src={tFile} className='media-object' />
                                                            </div>
                                                            <div className='media-body'>
                                                                <div className='topic-file-name' style={{ wordWrap: "break-word", margin: "10px 10px" }}>{ms.sName}</div>
                                                            </div>
                                                            <div className='topic-file-collect'><AiOutlineEye />{ms.nView}</div>
                                                            {(ms.sType === 'jpg' || ms.sType === 'jpeg' || ms.sType === 'png' || ms.sType === 'gif' || ms.sType === 'bmp') ?
                                                                <DivImage URL={ms.sURL}></DivImage> : <></>}
                                                        </a>
                                                    </>
                                                </Grid>
                                            </>
                                        )
                                    })}
                                </Grid>

                                <DialogUI
                                    open={isOpenTest}
                                    textHead={IsPreTest ? "แบบทดสอบก่อนเรียน" : "แบบทดสอบหลังเรียน"}
                                    testIcon
                                    isShowCancle
                                    isShowDialogActions
                                    isSendAns
                                    fullWidth
                                    maxWidth="md"
                                    hBGColor="#0b286c"
                                    hColor="#FFFFFF"
                                    handleClose={onCloseTest}
                                    handleClickCancel={onCloseTest}
                                    handleClickConfirm={form.handleSubmit((e) => onSubmitTest(e))}
                                >
                                    <FormProvider {...form}>
                                        <Grid container>
                                            <Box sx={{ display: "flex", flexDirection: "column" }}>
                                                {(lstDataExam.lstExam || []).map((m, i) => {
                                                    return (
                                                        <>
                                                            <Grid item xs={12}>
                                                                {i + 1}. {m.sTitle}<br />
                                                                {(m.lstImageURL || []).map((p, n) => {
                                                                    return (
                                                                        <LightGallery
                                                                            // onInit={onInit}
                                                                            speed={1}
                                                                            plugins={[lgThumbnail]}
                                                                        >
                                                                            <img src={p}// || "https://shorturl.asia/lZFzf"
                                                                                style={{
                                                                                    width: "200px",
                                                                                    height: "150px",
                                                                                    margin: "8px",
                                                                                    marginLeft: "15px",
                                                                                    borderRadius: "4px",
                                                                                    boxShadow: "0 0 0.5em 0 rgba(0, 0, 0, 0.25)",
                                                                                }}
                                                                            />
                                                                        </LightGallery>
                                                                    )
                                                                }
                                                                )}
                                                            </Grid>
                                                            {(m.lstChoice || []).map((ms, is) => {
                                                                return (
                                                                    <>
                                                                        <Grid key={i + is} item xs={12} sx={{
                                                                            display: "flex",
                                                                            justifyContent: "flex-start",
                                                                            alignItems: "flex-start",
                                                                            flexDirection: "column",
                                                                            ml: "10px"
                                                                        }}>
                                                                            <RadioFormItem
                                                                                key={i + is}
                                                                                name={"radio_" + i}
                                                                                options={[{ "label": ms.sLabel, "value": ms.nChoiceID }]}
                                                                                value={form.getValues("radio_" + i)}
                                                                            />
                                                                            {ms.sImageURL !== null && m.sImageURL !== undefined && m.sImageURL !== "" &&
                                                                                <LightGallery
                                                                                    speed={1}
                                                                                    plugins={[lgThumbnail]}

                                                                                >
                                                                                    <img src={ms.sImageURL}// || "https://shorturl.asia/lZFzf"
                                                                                        style={{
                                                                                            width: "200px",
                                                                                            height: "150px",
                                                                                            margin: "8px",
                                                                                            borderRadius: "4px",
                                                                                            boxShadow: "0 0 0.5em 0 rgba(0, 0, 0, 0.25)",
                                                                                        }}
                                                                                    />
                                                                                </LightGallery>
                                                                            }
                                                                        </Grid>
                                                                    </>
                                                                )
                                                            })}
                                                        </>
                                                    )
                                                })}
                                            </Box>
                                        </Grid>
                                    </FormProvider>
                                </DialogUI>

                                <DialogUI
                                    open={isOpenPDF}
                                    fullScreen
                                    isShowCancle
                                    isShowDialogActions
                                    handleClose={onClosePDF}
                                    handleClickCancel={onClosePDF}
                                    height={"100%"}
                                >
                                    <iframe
                                        src={dataURL + "#toolbar=0&navpanes=0"}// || "https://www.softthai.com/PTTOR_OBA_KMS_2022/UploadFiles/Topics/135/TH/20220815154130409.pdf"
                                        style={{ width: "100%", height: "100%" }}
                                    />
                                </DialogUI>
                                {/* <Modal
                                    open={isOpenVideo}
                                    onClose={onCloseVideo}
                                    aria-labelledby="modal-modal-title"
                                    aria-describedby="modal-modal-description"
                                    onBackdropClick={onCloseVideo}
                                    disableScrollLock={false}
                                    closeAfterTransition
                                > */}

                                <DialogUI
                                    open={isOpenVideo}
                                    fullWidth
                                    // handleClose={onCloseVideo}
                                    // handleClickCancel={onCloseVideo}

                                    handleClose={endVideo}
                                    handleClickCancel={endVideo}

                                    isShowCancle
                                    isShowDialogActions
                                    height={"100%"}
                                    className={"popup-alert-video"}
                                    style={{
                                        position: 'relative',
                                        justifyContent: "center",
                                        verticalAlign: "middle"
                                    }}
                                >
                                    <BodyVideo />
                                </DialogUI>

                                {/* <DialogUI
                                    open={isOpenVideo}
                                    fullWidth
                                    handleClose={onCloseVideo}
                                    handleClickCancel={onCloseVideo}
                                    isShowCancle
                                    isShowDialogActions
                                    height={"100%"}
                                    style={{
                                        position: 'relative',
                                        justifyContent: "center",
                                        verticalAlign: "middle"
                                    }}
                                >
                                  
                                    {dataURL && (
                                        <video
                                            playsInline
                                            contextMenu="return false;"
                                            id={"video_" + IDName}
                                            src={dataURL}// || exVideo
                                            key={dataURL}
                                            controls={true}
                                            autoPlay={true}
                                            muted
                                            width="100%"
                                            height="auto"
                                            controlsList="nodownload"
                                            onEnded={endVideo}
                                            onPlay={() => { play(IDName) }}
                                            onPlaying={() => { play(IDName) }}
                                            onPlayingCapture={() => { play(IDName) }}
                                            onPause={() => { play(IDName) }}
                                            ref={videoRef}
                                        // onSeeking={(e) => seeking(e)}
                                        // onTimeUpdate={(e) => timeUpdate(e)}
                                        // onTimeUpdate={pause}
                                        />

                                    )}

                                  
                                </DialogUI> */}
                                {/* </Modal> */}
                            </>
                        );
                    })}
                </Grid>
            </>
        );
    };

    return (
        <>

            <Grid item xs={12} md={12} lg={12}>
                <MenuBar sContentID={sContentID} />
            </Grid>

            <Grid item xs={12} md={12} lg={12}>
                <DialogUI
                    open={isOpenCheckQuestion}//isOpenCheckQuestion
                    textHead={"แบบประเมิน"}
                    testIcon
                    isSendAns
                    isShowDialogActions
                    fullWidth
                    maxWidth="lg"
                    hBGColor="#0b286c"
                    hColor="#FFFFFF"
                    // handleClose={onCloseCheckQuestion}
                    // handleClickCancel={onCloseCheckQuestion}
                    handleClickConfirm={formQ.handleSubmit((e) => onSubmitQuestionare(e))}
                >
                    <FormProvider {...formQ}>
                        <Grid container sx={{
                            bgcolor: "#007aff",
                            color: "#fff"
                        }}>
                            <Grid item xs={12} sx={{ display: "flex" }}>
                                <Grid item xs={7} sx={{
                                    display: "flex", justifyContent: "center", alignItems: "center",
                                    border: "1px solid rgba(0,0,0,0.3)",
                                }}>
                                    <Typography>หัวข้อประเมิน</Typography>
                                </Grid>
                                <Grid item xs={5} sx={{ display: "flex", flexWrap: "wrap" }}>
                                    <Grid item xs={12} sx={{
                                        textAlign: "center",
                                        borderTop: "1px solid rgba(0,0,0,0.3)",
                                        borderBottom: "1px solid rgba(0,0,0,0.3)",
                                        borderRight: "1px solid rgba(0,0,0,0.3)",
                                    }}>
                                        <Typography>ระดับการประเมิน</Typography>
                                    </Grid>
                                    <Grid item xs={12} sx={{ display: "flex" }}>
                                        {(lstData.lstQuestionnaireChoice || []).map((m, i) => {
                                            return (
                                                <>
                                                    {lstData.lstQuestionnaireChoice.length === 5 ?
                                                        <>
                                                            <Grid item xs={4} sx={{
                                                                textAlign: "center",
                                                                borderBottom: "1px solid rgba(0,0,0,0.3)",
                                                                borderRight: "1px solid rgba(0,0,0,0.3)",
                                                                display: "flex",
                                                                justifyContent: "center",
                                                                alignItems: "center",
                                                            }}>
                                                                <Typography>{m}</Typography>
                                                            </Grid>
                                                        </>
                                                        :
                                                        <>
                                                            {lstData.lstQuestionnaireChoice.length === 4 ?
                                                                <>
                                                                    <Grid item xs={3} sx={{
                                                                        textAlign: "center",
                                                                        borderBottom: "1px solid rgba(0,0,0,0.3)",
                                                                        borderRight: "1px solid rgba(0,0,0,0.3)"
                                                                    }}>
                                                                        <Typography>{m}</Typography>
                                                                    </Grid>
                                                                </> :
                                                                <>
                                                                    <Grid item xs={2.4} sx={{
                                                                        textAlign: "center",
                                                                        borderBottom: "1px solid rgba(0,0,0,0.3)",
                                                                        borderRight: "1px solid rgba(0,0,0,0.3)"
                                                                    }}>
                                                                        <Typography>{m}</Typography>
                                                                    </Grid>
                                                                </>
                                                            }
                                                        </>
                                                    }
                                                </>
                                            )
                                        })}
                                    </Grid>
                                </Grid>
                            </Grid>
                        </Grid>

                        <Grid container>
                            {(lstData.lstTopicQuestionnaire || []).map((m, i) => {
                                return (
                                    <>
                                        <Grid key={i} item xs={7} sx={{
                                            display: "flex",
                                            borderLeft: "1px solid rgba(0,0,0,0.3)",
                                            borderBottom: "1px solid rgba(0,0,0,0.3)",
                                            borderRight: "1px solid rgba(0,0,0,0.3)",
                                        }}>
                                            <Typography sx={{
                                                textOverflow: "ellipsis",
                                                wordBreak: "break-word",
                                                m: "5px",

                                            }}> {i + 1}. {m.sName} </Typography>
                                        </Grid>
                                        <Grid item xs={5} sx={{ display: "flex", flexWrap: "wrap" }}>
                                            {(lstData.lstQuestionnaireChoice || []).map((ms, ii) => {
                                                return (
                                                    <>
                                                        {lstData.lstQuestionnaireChoice.length === 5 ?
                                                            <Grid key={ii} item xs={2.4} sx={{
                                                                display: "flex",
                                                                alignItems: "center",
                                                                justifyContent: "center",
                                                                borderBottom: "1px solid rgba(0,0,0,0.3)",
                                                                borderRight: "1px solid rgba(0,0,0,0.3)",
                                                                flexDirection: "column",
                                                            }}>
                                                                <RadioFormItem
                                                                    key={i + ii}
                                                                    name={"radio_" + i}
                                                                    options={[{ "value": ii + 1 }]}
                                                                    value={formQ.getValues("radio_" + i)}
                                                                    isChangeIcon
                                                                />
                                                            </Grid>
                                                            : <>
                                                                {lstData.lstQuestionnaireChoice.length === 4 ?
                                                                    <Grid key={ii} item xs={3} sx={{
                                                                        display: "flex",
                                                                        alignItems: "center",
                                                                        justifyContent: "center",
                                                                        borderBottom: "1px solid rgba(0,0,0,0.3)",
                                                                        borderRight: "1px solid rgba(0,0,0,0.3)",
                                                                        flexDirection: "column",
                                                                    }}>
                                                                        <RadioFormItem
                                                                            key={i + ii}
                                                                            name={"radio_" + i}
                                                                            options={[{ "value": ii + 1 }]}
                                                                            value={formQ.getValues("radio_" + i)}
                                                                            isChangeIcon
                                                                        />
                                                                    </Grid>
                                                                    : <>
                                                                        <Grid key={ii} item xs={2.4} sx={{
                                                                            display: "flex",
                                                                            alignItems: "center",
                                                                            justifyContent: "center",
                                                                            borderBottom: "1px solid rgba(0,0,0,0.3)",
                                                                            borderRight: "1px solid rgba(0,0,0,0.3)",
                                                                            flexDirection: "column",
                                                                        }}>
                                                                            <RadioFormItem
                                                                                key={i + ii}
                                                                                name={"radio_" + i}
                                                                                options={[{ "value": ii + 1 }]}
                                                                                value={formQ.getValues("radio_" + i)}
                                                                                isChangeIcon
                                                                            />
                                                                        </Grid>
                                                                    </>
                                                                }
                                                            </>
                                                        }
                                                    </>
                                                )
                                            })}
                                        </Grid>
                                    </>
                                )
                            })}
                        </Grid>

                        <Grid container>
                            <Grid item xs={12} sx={{ display: "flex", flexDirection: "column" }}>
                                <label style={{ marginTop: "0.5em" }}>ความคิดเห็นเพิ่มเติม</label>
                                <InputForm
                                    multiline
                                    mx={0}
                                    rows={5}
                                    name={"sComment"}
                                    showClear={false}
                                    maxLength={1000}
                                />
                            </Grid>
                        </Grid>
                    </FormProvider>
                </DialogUI>
                <Head></Head>
            </Grid>

            {(lstData.sCoverVDOPath != null || lstData.sCoverVDOPath != "") ? (
                <Grid item xs={12} md={12} lg={12}>
                    <BoxVideo data={lstData}></BoxVideo>
                </Grid>
            ) : (
                <></>
            )}

            {(lstData.sCoverImagePath != null || lstData.sCoverImagePath != "") ? (
                <Grid item xs={12} md={12} lg={12}>
                    <BoxImage data={lstData}></BoxImage>
                </Grid>

            ) : (
                <></>
            )}

            {(lstData.sContent != null || lstData.sContent != "") ? (
                <Grid item xs={12} md={12} lg={12} >
                    <BoxDetail data={lstData}></BoxDetail>
                </Grid>
            ) : (
                <></>
            )}

            <Grid item xs={12} md={12} lg={12} className="topic-rating">
                <Divider />
                <BoxRating dataRating={lstRating} ></BoxRating>
                <Divider />
                <DialogUI
                    open={openDialog}
                    textHead="ให้คะแนนหลักสูตร"
                    fullWidth
                    isShowCancle
                    questionIcon
                    isShowConfirm
                    isShowDialogActions
                    maxWidth="sm"
                    handleClose={handleClose}
                    handleClickConfirm={handleClickConfirm}
                    handleClickCancel={handleClickCancel}
                >
                    {/* <Box sx={{ display: "flex", flexDirection: "column", alignItems: "center" }}> */}
                    {value !== null ?
                        <Box sx={{ m: "0.5em" }}>{labels[hover !== -1 ? hover : (value ? value : 0)]} คะแนน</Box>
                        :
                        <Box sx={{ m: "0.5em" }}>0 คะแนน</Box>
                    }
                    <Rating
                        name="half-rating-read"
                        defaultValue={0}
                        value={value}
                        precision={1}
                        getLabelText={getLabelText}
                        onChange={(event, newValue) => {
                            setValue(newValue)
                        }}
                        onChangeActive={(event, newHover) => {
                            setHover(newHover);
                        }}
                        emptyIcon={
                            <StarIcon style={{ opacity: 0.55, pointerEvents: 'none' }} fontSize="inherit" />
                        }
                    />
                    {value === null &&
                        <Box sx={{ m: "0.5em", color: "red" }}>โปรดระบุคะแนน</Box>
                    }
                    {/* </Box> */}
                </DialogUI>
            </Grid>

            {(lstData.sLessonContent != null || lstData.sLessonContent != "") ? (
                <Grid item xs={12} md={12} lg={12} >
                    <BoxCouseContent data={lstData}></BoxCouseContent>
                </Grid>
            ) : (
                <></>
            )}
        </>);
}

interface pTopic {
    sID: string;
    sCategory: string;
    sHeadContent: string;
}