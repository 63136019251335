import { useEffect, useState } from "react";
import { Grid } from "@mui/material";
import { Axios, AxiosGetAsync } from "src/CommonFunction/TS_Axios";
import { useLocation, useNavigate } from "react-router";
import { Decrypt, DecryptApi, EncryptApi, GetQueryString, ParseHtml } from "src/CommonFunction/TS_function";
import YouTube, { YouTubeProps } from "react-youtube";
import ReactPlayer from "react-player";
import { FnBlock_UI } from "src/Components/BlockUI"
import { BreadcrumbsUI } from "../BreadcrumbsUI";
import * as yup from "yup";
import yupFormSchemas from "src/Components/FormItem/yup/yupFormSchemas";
import { yupResolver } from "@hookform/resolvers/yup";
import { FormProvider, useForm } from "react-hook-form";
import { MenuBar } from "../System/NavBar/MenuBar";
import { Banner } from "../Banner";
import "./cms-content.css"
var getYouTubeID = require('get-youtube-id');
const CMSContent = () => {
    const { BlockUI, UnBlockUI } = FnBlock_UI();
    const [dataSource, setData] = useState<any[]>([]);
    const [navbar, setNavBar] = useState<any[]>([]);
    const [IDforNavbar, setIDforNavbar] = useState("");
    const [banner, setBanner] = useState([]); //show content
    const [sName, setName] = useState<any[]>([]);//content name
    const location = useLocation();
    const searchParams = location.search;
    const sID = new URLSearchParams(searchParams).get('str') as string;
    const sContentID = GetQueryString('sID');

    const objSchema = {
        // sStart: yupFormSchemas.datetime("sStart", { required: false }),
        // sEnd: yupFormSchemas.datetime("sEnd", { required: false })
    }
    const schema = yup.object().shape(objSchema);
    const form = useForm({
        resolver: yupResolver(schema),
        shouldUnregister: false,
        shouldFocusError: true,
        mode: "all",
    });

    useEffect(() => {
        OnPageLoad();
        window[`scrollTo`]({ top: 250, behavior: `smooth` });
    }, [sID]);

    const OnPageLoad = async () => {
        let resBanner = await loadBanner();
        let NamePage = await loadNamePage();

        setBanner(resBanner);
        setName(NamePage);
        onLoadCMS();
    }

    const loadBanner = async () => {
        let result = {} as any;
        result = await AxiosGetAsync("Master/GetBannerCMS", { sContentID: sID });
        return result;
    };

    const loadNamePage = async () => {
        let result = {} as any;
        result = await AxiosGetAsync("Content/GetData_ContentName", { sContentID: sID });
        return result;
    };

    const onLoadCMS = async () => {
        let objCMS = {
            sContentID: sID
        }

        await Axios("Content/GetData_CMSContentDetail", objCMS, "POST", async (result) => {
            let resBanner = await loadBanner();
            let NamePage = await loadNamePage();

            setData(result);
            setBanner(resBanner);
            setName(NamePage);
        },
            null,
            BlockUI,
            UnBlockUI,
            null
        );
    }

    const opts: YouTubeProps['opts'] = {
        overflow: "hidden",
        paddingBottom: "56.25%",
        position: "relative",
        width: "390",
        heigth:"640",
        playerVars: {
            autoplay: 0
        },
    };

    const onPlayerReady: YouTubeProps["onReady"] = (event) => {
        event.target.pauseVideo();
    };
    return (
        <FormProvider {...form}>
            <Grid container>
                <Grid item xs={12}>
                    {banner.length > 0 ? (
                        <Grid item xs={12}>
                            {(sName.length > 0 ? sName : []).map((m) => (
                                <Banner
                                    dataSource={banner}
                                    height={50}
                                    textTH={(m.sContentNameTH)}
                                    textENG={(m.sContentNameEN)}
                                />
                            ))}
                        </Grid>
                    ) : (
                        <></>
                    )}
                </Grid>
                <Grid item xs={12}>
                    <MenuBar sContentID={sID} />
                </Grid>

                {/* <Grid container sx={{ mx: "3%", marginY: "2rem", pl: 0, textAlign: "center" }}> */}
                {/* <Grid container sx={{ pl: 0 }}> */}
                <Grid item xs={12} sx={{pl:"3%",pr: "3%"}}>
                <Grid container>
                    {(dataSource.length > 0 ? dataSource : []).map(
                        (m) => {
                            if (m.nContentTypeID == 1) {
                                return (
                                    <Grid item xs={12} sx={{ marginBottom: "3%" }}>
                                        <p
                                            className="sContentHTML"
                                            style={{
                                                whiteSpace: "normal",
                                                textOverflow: "ellipsis"
                                            }}
                                        >
                                            {ParseHtml(m.sContentHTML)}
                                        </p>
                                    </Grid>
                                )
                            } else if (m.nContentTypeID == 2) {
                                return (
                                    // <Grid item xs={12} sx={{ my: "3%" }}>
                                    <Grid item xs={12} sx={{ display: "flex", justifyContent: "center", my: "3em" }}>
                                        <img src={(m.sFilePath)} />
                                        <h3
                                            className="sDesc"
                                            style={{
                                                whiteSpace: "normal",
                                                textOverflow: "ellipsis",
                                                color: "#A0A0A0"
                                            }}
                                        >
                                            {(m.sDesc)}
                                        </h3>
                                    </Grid>
                                )
                            } else if (m.nContentTypeID == 3) {
                                return (
                                    <Grid item xs={12} sx={{ display: "flex", justifyContent: "center", mb: "3em" }}>
                                        <ReactPlayer url={(m.sFilePath)} width="inherit" height="inherit" controls={true} />

                                        <p
                                            className="sDesc"
                                            style={{
                                                whiteSpace: "normal",
                                                textOverflow: "ellipsis",
                                                color: "#A0A0A0"
                                            }}
                                        >
                                            {(m.sDesc)}
                                        </p>
                                    </Grid>
                                )
                            } else if (m.nContentTypeID == 4) {
                                var id = getYouTubeID(m.sURL);
                                return (
                                    <>
                                        <Grid item xs={12} sx={{ display: "flex", justifyContent: "center", mb: "3em" }}>
                                            <YouTube videoId={id} opts={opts} onReady={onPlayerReady}/>
                                            <p
                                                className="sDesc"
                                                style={{
                                                    whiteSpace: "normal",
                                                    textOverflow: "ellipsis",
                                                    color: "#A0A0A0"
                                                }}
                                            >
                                                {(m.sDesc)}
                                            </p>
                                        </Grid>
                                    </>
                                )
                            }
                            else if (m.nContentID) {
                                return (
                                    <Grid item xs={12} sx={{ display: "flex", justifyContent: "center", mb: "3em" }}>
                                        <p
                                            className="sUpdated"
                                            style={{
                                                whiteSpace: "normal",
                                                textOverflow: "ellipsis",
                                                color: "#A0A0A0"
                                            }}
                                        >
                                            {(m.sUpdated)}
                                        </p>
                                    </Grid>
                                )
                            }
                        }
                    )}
                </Grid>

                </Grid>
            </Grid>
        </FormProvider>
    );
}
export default CMSContent;