import React from "react";
import Grid from "@mui/material/Grid";
import Button from "@mui/material/Button";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import { useLocation } from "react-router";

function MenuItem(props) {
  const { menuTH, menuENG, menuIDRoute, menuID, path, nType, nLevel,isactive } = props;
  const pathName  =(window.location.pathname +window.location.search).substring(16);
  const handleClick = (e) => {
    if (props.onClose) props.onClose();
  };
  return (
    <>
      <Grid
        id={"G"+menuID}
        item
        xs
        sx={{
          cursor: "pointer",
          // width: nLevel > 1 ? "300px" : "auto",
          bgcolor:
            // window.location.pathname.substring(1) == path
            isactive
              ? "#3997df !important"
              : "rgb(255,255,255)",
          ":hover": {
            bgcolor: "#3997df",
          },
        }}
      >
        <Button
          id={"B"+menuID}
          fullWidth
          sx={{
            // bgcolor:
            //   window.location.pathname.substring(1) == path
            //     ? "#3997df !important"
            //     : "rgb(255,255,255)",
            // width: nLevel > 1 ? "250px" : "auto",
            // height: "5vw",
            borderBottom: nLevel > 1 ? "1px solid rgba(0,0,0,0.2)" : "",
            px: 2,
            fontSize: {xs:"0.8em",xl:"1em"},
            height: nLevel !== 1 ? "80px" : "90px",
            minWidth: nLevel > 1 ? "190px" : "80px",
            maxWidth:nLevel !== 1 ? "400px" : {xs:"140px",xl:"400px"},
            borderRadius: "unset",
            background:
            isactive
                ? "linear-gradient( rgba(255,255,255,1) 0%, rgba(255,255,255,1) 100%)"
                : "linear-gradient( #000000 0%, #dbdbdb 145%)",
            WebkitBackgroundClip: "text",
            WebkitTextFillColor: "transparent",
            justifyContent: nLevel > 1 ? "flex-start" : "center",
            "&.MuiButtonBase-root": {
              textTransform: "none",
              textAlign: nLevel > 1 ? "start" : "center ",
            },
            ":hover": {
              background:
                "linear-gradient( rgba(255,255,255,1) 0%, rgba(255,255,255,1) 100%)",
              WebkitBackgroundClip: "text",
              WebkitTextFillColor: "transparent",
            },
          }}
          onClick={handleClick}
        >
          <p>
            <label
              style={{
                alignItems: "left",
                cursor: "pointer",
                fontWeight: "normal",
              }}
            >
              {menuTH}
            </label>
            <br />
            <label
              style={{
                alignItems: "start",
                cursor: "pointer",
                fontWeight: "normal",
              }}
            >
              {menuENG}
            </label>
            {/* <label>{menuTH} </label>
            <br />
            <label>{menuENG} </label> */}
          </p>

          {/* <p style={{ color: "black",display: "inline-block !important"}}> {menuTH}</p>
        <br />
        <p style={{ color: "red",display: "inline-block !important" }}> {menuENG} </p> */}
        </Button>
      </Grid>
      {/* )} */}
    </>
  );
}

export default MenuItem;
