import { useEffect, useRef, useState } from "react";
import { i18n } from "src/i18n";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import yupFormSchemas from "src/Components/FormItem/yup/yupFormSchemas";
import { Axios, AxiosGetAsync } from "src/CommonFunction/TS_Axios";
import Grid from "@mui/material/Grid";
import ReactPlayer from "react-player";
import { ParseHtml } from "src/CommonFunction/TS_function";
import { FnBlock_UI } from "src/Components/BlockUI";
import { Banner } from "src/Components/Banner";
import { MenuBar } from "src/Components/System/NavBar/MenuBar";
import { TopicCouse } from "src/Components/System/OnlineCourse/topic";
import { useLocation } from "react-router";
import { GetQueryString } from "src/CommonFunction/TS_function";

const OnlineDetail = () => {
    const [lstData, setData] = useState<any>({});
    const { BlockUI, UnBlockUI } = FnBlock_UI();
    const [banner, setBanner] = useState([] || undefined);
    const location = useLocation();
    const searchParams = location.search;
    const sID = GetQueryString('sID');
    const sCategory = GetQueryString('c');
    const sHeadContent = GetQueryString('str');




    return (
        <>
            <Grid
                container
                columnSpacing={0}
                rowSpacing={0}
                sx={{ "&.MuiGrid-root": { marginTop: 0, display: "block" } }}
            >
                <Grid item xs={24} md={12} lg={12}>
                <TopicCouse sID={sID} sCategory={sCategory} sHeadContent={sHeadContent}></TopicCouse>
                </Grid>
            </Grid>
        </>
    );
}
export default OnlineDetail;