import BlockUI1, { FnBlock_UI } from "src/Components/BlockUI";
import { useEffect, useRef, useState } from "react";
import {
  BtnSearch,
  BtnSubmit,
  BtnCancel,
  BtnBack,
  BtnDownload,
  BtnSave,
  BtnPreview,
  BtnAddOnTable,
  BtnPreviewOnTable,
  BtnLinkOnTable,
  BtnViewImageOnTable,
  BtnEditOnTable,
  BtnViewOnTable,
} from "src/Components/Button";
import Box from "@mui/material/Box";
import { Container } from "@mui/system";
import Grid from "@mui/material/Grid";
import { InputForm } from "src/Components/FormItem/Input";
import InputNumber from "src/Components/FormItem/InputNumber";
import { AutoCompleteForm } from "src/Components/FormItem/AutoComplete";
import { CheckBoxUI } from "src/Components/FormItem/CheckBox";
import { RadioUI } from "src/Components/FormItem/Radio";
import { DatePickerUI } from "src/Components/FormItem/DatePicker";
import { FormProvider, useForm } from 'react-hook-form';
import * as yup from "yup";
import { yupResolver } from '@hookform/resolvers/yup';
import yupFormSchemas from 'src/Components/FormItem/yup/yupFormSchemas'
import SelectForm from "src/Components/FormItem/Select";
import { DateRangePickerUI } from "src/Components/FormItem/DateRangePicker";
import { DataGridMui, PaginationInterface, initRows, FilterFieldInterface } from "src/Components/Table/DataGridMui";
import { GridColumns } from "@mui/x-data-grid-pro";
import moment from "moment";


const dataTest = [
  { text: "The Shawshank Redemption", year: 1994 },
  { text: "The Godfather", year: 1972 },
  { text: "The Godfather: Part II", year: 1974 },
  { text: "The Dark Knight", year: 2008 },
  { text: "12 Angry Men", year: 1957 },
  { text: "Schindler's List", year: 1993 }
];

const allComponent = () => {
  const { BlockUI, UnBlockUI } = FnBlock_UI();

  const objSchema = {
    Password: yupFormSchemas.string("Password", { required: true }),
    UserName: yupFormSchemas.string("Username", { required: true }),
    InputNumber: yupFormSchemas.string("Input Number", { required: true }),
    AutoCompletes: yupFormSchemas.object("AutoCompletes", { required: true }),
    AutoCompletesmultiple: yupFormSchemas.array("AutoCompletes multiple", { required: true }),
    SelectForm: yupFormSchemas.string("SelectForm", { required: true }),
  };

  const schema = yup.object().shape(objSchema);

  const form = useForm({
    mode: "all",
    resolver: yupResolver(schema),
    shouldFocusError: true
  });

  const onClick = () => { };
  const onChange = (e) => {
    console.log(e);
  }
  const onSubmit = () => {
    console.log("var", form.getValues());

  };

  const onInSave = (e) => {
    console.log("e", e);

  }


  // table
  const [dataRow, setDataRow] = useState<PaginationInterface>({
    ...initRows,
  });

  const [isLoading, setLoading] = useState(false)
  const dataRowInTable = [{ sID: "1", sName: "Test1", isActive: true, sDes: "text for test.", dDateUpdate: moment("2022-08-01").format("L"), },
  { sID: "2", sName: "Test2", isActive: false, sDes: "text for test.", dDateUpdate: moment("2022-08-05").format("L"), },
  { sID: "3", sName: "Test3", isActive: true, sDes: "text for test.", dDateUpdate: moment("2022-08-02").format("L"), },
  { sID: "4", sName: "Test4", isActive: false, sDes: "text for test.", dDateUpdate: moment("2022-07-01").format("L"), },
  { sID: "5", sName: "Test5", isActive: true, sDes: "text for test.", dDateUpdate: moment("2022-07-04").format("L"), },
  { sID: "6", sName: "Test6", isActive: true, sDes: "text for test.", dDateUpdate: moment("2022-07-05").format("L"), },
  { sID: "7", sName: "Test7", isActive: true, sDes: "text for test.", dDateUpdate: moment("2022-07-02").format("L"), },
  { sID: "8", sName: "Test8", isActive: true, sDes: "text for test.", dDateUpdate: moment("2022-07-01").format("L"), },
  { sID: "9", sName: "Test9", isActive: true, sDes: "text for test.", dDateUpdate: moment().format("L"), },
  { sID: "10", sName: "Test10", isActive: true, sDes: "text for test.", dDateUpdate: moment().format("L"), },]

  const dataColumn: GridColumns = [

    {
      field: "sName",
      headerName: "ชื่อ",
      headerAlign: "center",
      align: "left",
      sortable: true,
      minWidth: 180,
    },
    {
      field: "sDes",
      headerName: "คำอธิบาย",
      headerAlign: "center",
      align: "left",
      sortable: true,
      flex: 1
    },
    {
      field: "dDateUpdate",
      headerName: "วันที่อัพเดท",
      headerAlign: "center",
      align: "center",
      sortable: true,
      minWidth: 180,
    },
    {
      field: "isActive",
      headerName: "สถานะ",
      headerAlign: "center",
      align: "center",
      sortable: true,
      renderCell: (p) => <div className='MuiDataGrid-cellContent'>{p.row.isActive ? "ใช้งาน" : "ไม่ใช้งาน"}</div>,
      minWidth: 140,
    },
    {
      field: "isView",
      headerName: "",
      headerAlign: "center",
      align: "center",
      resizable: true,
      sortable: false,
      minWidth: 60,
      renderCell: (item) => {
        return (
          <BtnEditOnTable
            txt="แก้ไข"
            onClick={() => console.log('edit')}
          />
        )
      },
    },
    {
      field: "isEdit",
      headerName: "",
      headerAlign: "center",
      align: "center",
      resizable: true,
      sortable: false,
      minWidth: 60,
      renderCell: (item) => {
        return (
          <BtnViewOnTable
            txt="ดูรายละเอียด"
            onClick={() => console.log('view')}
          />
        )
      },
    },
  ];

  const filters: FilterFieldInterface[] = [
    { sFieldName: "sName", sTypeFilterMode: "input" },
    {
      sTypeFilterMode: "daterange", sFieldName: "dDateUpdate"
    },
    { sFieldName: "isActive", lstDataSelect: [{ label: "ใช้งาน", value: true, keyId: 1 }, { label: "ไม่ใช้งาน", value: false, keyId: 2 }], sTypeFilterMode: "select" }
  ]

  const onLoadDataTable = (p: PaginationInterface) => {
    setLoading(true)
    console.log(p) // สามารถดูค่าต่างๆที่กลับมาได้ที่ console เพื่อนำไปทำการเขียนหลังบ้าน sort , filter และเปลี่ยนหน้า
    let dataFilter = dataRowInTable
    setTimeout(() => {
      setDataRow({
        ...p,
        arrRows: dataFilter,
        nDataLength: 10,
        nPageIndex: 1,
      });
      setLoading(false)
    }, 3000);
  }

  const onDeleteRow = (p) => {
    console.log("delete", p)
  }

  useEffect(() => {
    onLoadDataTable(initRows);
  }, [])

// table 


  return (
    <>
      {/* Button */}
      <Container maxWidth={false}>
        <Box sx={{ paddingTop: 5, borderRadius: 10 }}>
          <BtnSearch></BtnSearch>
          <BtnSubmit></BtnSubmit>
          <BtnCancel></BtnCancel>
          <BtnBack></BtnBack>
          <BtnDownload onClick={onClick}></BtnDownload>
          <BtnSave></BtnSave>
          <BtnPreview></BtnPreview>
          <BtnAddOnTable></BtnAddOnTable>
          <BtnPreviewOnTable></BtnPreviewOnTable>
          <BtnLinkOnTable></BtnLinkOnTable>
          <BtnViewImageOnTable></BtnViewImageOnTable>
          <BtnEditOnTable></BtnEditOnTable>
          <BtnViewOnTable></BtnViewOnTable>
          {/* <BtnRadius></BtnRadius> */}
        </Box>
        <Box sx={{ paddingTop: 5 }}>
          <FormProvider {...form}>
            <form>
              <Grid container columnSpacing={5} rowSpacing={{ xs: 4 }}>
                <Grid item xs={3}>
                  <InputForm
                    name="Password"
                    label="Password"
                    placeholder="Password"
                    onChange={onChange}
                    fullWidth
                    required
                    maxLength={10}
                    isPassword
                    showCoutTest={false}
                    showClear={false}
                  />
                </Grid>
                <Grid item xs={3}>
                  <InputForm
                    name="UserName"
                    label="UserName"
                    suffix="UserName"
                    placeholder="TextBox1"
                    onChange={onChange}
                    fullWidth
                    required
                    maxLength={10}
                  />
                </Grid>
                <Grid item xs={3}>
                  <InputNumber
                    name="InputNumber"
                    label="InputNumber"
                    suffix="cm."
                    placeholder="InputNumber"
                    type={"number"}
                    size="small"
                    required
                    fullWidth
                    digits={2}
                    integerDigits={3}

                  />
                </Grid>
                <Grid item xs={3}>
                  <AutoCompleteForm
                    name="AutoCompletesmultiple"
                    placeholder="Pls Select"
                    label="AutoComplete Multiple"
                    urlAPI="Example/Get"
                    multiple
                    required={true}
                  />
                </Grid>
                <Grid item xs={3}>
                  <AutoCompleteForm
                    name="AutoCompletes"
                    placeholder="Pls Select"
                    label="AutoComplete"
                    urlAPI="Example/Get"
                    required={true}
                  />
                </Grid>
                <Grid item xs={3}>
                  <SelectForm
                    name="SelectForm"
                    placeholder="Pls Select"
                    label="Select Form"
                    required={true}
                  />
                </Grid>
                <Grid item xs={3}>
                  <DatePickerUI
                    onChange={onChange}
                    fullWidth
                    label="DatePicker"
                    name="DatePicker"
                  />
                </Grid>
                <Grid item xs={3}>
                  <DateRangePickerUI name="dateRange" />
                </Grid>
                <Grid item xs={1}>
                  <CheckBoxUI name="test" type="single" label="Test" />
                </Grid>
                <Grid item xs={2}>
                  <CheckBoxUI
                    name="test"
                    type="group"
                    label="hText"
                    AllowCheckAll
                    options={[
                      { label: "Test 1", value: "t1" },
                      { label: "test 2", value: "t2" },
                    ]}
                    onChange={onChange}
                  />
                </Grid>
                <Grid item xs={2}>
                  <RadioUI
                    name="radio"
                    label="Text"
                    option={[
                      { label: "Test 1", values: "t1" },
                      { label: "test 2", values: "t2" },
                    ]}
                    onChange={onChange}
                  />
                </Grid>
              </Grid>
              <BtnSubmit onClick={form.handleSubmit(onSubmit, onInSave)} />
            </form>
          </FormProvider>
        </Box>
        <Box>
          <DataGridMui
            filterField={filters}
            isShowCheckBox
            isLoading={isLoading}
            Columns={dataColumn}
            onDelete={onDeleteRow}
            OnLoadData={(p: PaginationInterface) => {
              onLoadDataTable(p)
            }}
            Rows={dataRow}
          />
        </Box>
        <Box sx={{ paddingTop: 5, borderRadius: 10 }}></Box>
      </Container>
    </>
  );
};

export default allComponent;
