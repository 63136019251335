import { Button, Grid } from "@mui/material";
import Typography from "@mui/material/Typography";
import { useEffect, useState } from "react";
import { Axios } from "src/CommonFunction/TS_Axios";
import { useNavigate } from "react-router-dom";

const PasswordSuccess = () => {

    const [arrData, setarrData] = useState({} as any);
    const searchParams = location.search;
    const sUsername = new URLSearchParams(searchParams).get('sUsername');
    const navigate = useNavigate();


    const Goto = (sUsername: string) => {
        navigate(
            process.env.REACT_APP_API_URL + `ResetPassWord?sUsername=${encodeURI(sUsername)}`
          );
        };
      
    useEffect(() => {
        OnGetData();
    }, []);

    const OnGetData = async () => {

        await Axios(
            `ResetPassWord/GetData_Email`, { sUsername: sUsername }, "POST", async (res) => {
                setarrData(res.lstUsername || {});
            });
    };

    return (
        <Grid sx={{ borderRadius: 5, border: "groove", width: "30%", marginTop: "2%" }}>
            <Grid item xs={12} sx={{ marginTop: "3%" }}>
                <Typography align="center" variant="h5">Success</Typography>
            </Grid>
            <Grid item xs={6} sx={{ marginTop: "5%" }}>
                <Grid container justifyContent="center">
                    <Grid item xs={11} sx={{ marginTop: "3%" }}>
                        <Typography fontSize={18}>ระบบได้ทำการส่งลิ้งค์สำหรับเปลี่ยนรหัสผ่านไปยัง </Typography>
                    </Grid>
                    <Grid item xs={11} >
                        <Typography fontSize={18}>E-mail : {arrData.sEmail ? arrData.sEmail : ""}</Typography>
                    </Grid>
                    <Grid item xs={11} sx={{ marginTop: "5%" }}>
                        <Typography fontSize={18}>โปรดคลิกลิ้งค์ในอีเมลเพื่อกำหนดรหัสผ่านใหม่</Typography>
                    </Grid>
                    <Grid item xs={12} sx={{ marginTop: "10%" }}></Grid>
                    <Grid item xs={5} sx={{ margin: "1%" }}>
                        <Button fullWidth
                            sx={{
                                backgroundColor: "#196F3D", fontSize: 13, color: "White", ":hover": { bgcolor: "#00BFFF" }
                            }}>ส่งอีกครั้ง/Resend</Button>
                    </Grid>
                    <Grid item xs={5} sx={{ margin: "1%" }} >
                        <Button fullWidth variant="outlined"
                            onClick={() => Goto(arrData.sUsername.toString())}
                            sx={{
                                backgroundColor: "#FFFFFF", fontSize: 13, color: "Black", ":hover": { bgcolor: "#00BFFF" }
                            }}>ตกลง/OK</Button>
                    </Grid>
                    <Grid item xs={12} sx={{ marginTop: "10%" }}></Grid>
                </Grid>
            </Grid >
        </Grid>

    );
}
export default PasswordSuccess


