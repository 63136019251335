import Breadcrumbs from "@mui/material/Breadcrumbs";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import Box from "@mui/system/Box";
import { Link } from "react-router-dom";
import wave from "../../Layout/img/wave-blue-internal-header.png";

export function BreadcrumbsUI(props: BreadcrumbsUI) {
  const { isShowHome = true ,isShowBG=true} = props;
  return (
    <Grid
      container
      sx={{
        // mx: "4rem",
        background: isShowBG ? `url(${wave}) no-repeat` : "",
        backgroundSize: isShowBG ? "cover" : "",
        backgroundPosition: isShowBG ? "right bottom" : "",
        boxShadow: isShowBG
          ? "0 0px 8px 0 rgba(0, 0, 0, 0.2), 0 0px 20px 0 rgba(0, 0, 0, 0.19)"
          : "",
      }}
    >
      <Breadcrumbs
        aria-label="breadcrumb"
        separator={" | "}
        sx={{
          fontSize: "1em", // { xs: "1.2em", lg: "1.4em" },
          my: "20px",
          mx: "4rem",
        }}
      >
        {isShowHome && (
          <Link
            to={process.env.REACT_APP_API_URL + "home"}
            style={{
              textDecoration: "none",
              color: "#3997df",
            }}
          >
            หน้าแรก/Home
          </Link>
        )}

        {props.items &&
          // props.items.map((m) => (
          props.items.map((m) => (
            <Box>
              {m.IsOnClick ? (
                <Link
                  to={process.env.REACT_APP_API_URL + m.path}                  
                  style={{
                    textDecoration: "none",
                    color: "#3997df",
                    display: "flex",
                    alignItems: "center",
                  }}
                >
                  {m.icon}{m.menuName}
                </Link>
              ) : (
                <Typography color="text.primary">{m.menuName}</Typography>
              )}
            </Box>
          ))}
      </Breadcrumbs>
    </Grid>
  );
}

interface BreadcrumbsUI {
  items?: any;
  isShowHome?: boolean;
  isShowBG?: boolean;
}
