import { Grid, IconButton, Tooltip, Typography, useMediaQuery } from "@mui/material";

interface propCard {
    skey?: any;
    sTitle?: string;
    sColor?: string;
    children?: React.ReactNode;
    style?: any;

}

const HaftHeaderCards: React.FC<propCard> = (props) => {

    const { sTitle = props.sTitle || "", sColor = props.sColor } = props

  const matches = useMediaQuery('(min-width:900px)');

    return (
        <div key={props.skey} style={{ width: "100%" }}>
            <div style={{ width: matches ? "40%" : "100%"}}>
                <div key={props.skey + "1"} style={{ backgroundColor: `${sColor}` }}>
                    <div key={props.skey + "2"} style={{ padding: "6px", position: 'relative', minHeight: '40px' }}>
                        <div style={{ position: 'absolute', left: '1%', display: 'flex', alignItems: 'center', justifyContent: 'flex-start' }}>
                            <b>
                                <Typography variant="subtitle1" style={{ marginLeft: "8px",color:'#ffffff' }}>
                                    {sTitle}
                                </Typography>
                            </b>
                        </div>
                    </div>
                </div>
            </div>
            <div key={props.skey + "3"} style={{ border: `1px solid #c0c0c0`, width: "100%", padding: "16px", minHeight: '100px' }}>
                {props.children}
            </div>
        </div>

    )
}

export default HaftHeaderCards