import BlockUI1,{FnBlock_UI} from "src/Components/BlockUI";
import { useEffect, useRef, useState } from "react";
const about = () => {
  const { BlockUI, UnBlockUI } = FnBlock_UI();

  useEffect(()=>{
    // BlockUI();
    // const timer = setTimeout(() => {
    //   UnBlockUI();
    // }, 2000);
    // return () => clearTimeout(timer);
  },[])

    return (
        <>
          <p style={{fontSize:100,textAlign:"center"}}>About</p>
        </>
      );
}

export default about;