import BlockUI1,{FnBlock_UI} from "src/Components/BlockUI";
import { useEffect, useRef, useState } from "react";
import { ProfileUI } from "src/Components/Profile";

const information = () => {
  const { BlockUI, UnBlockUI } = FnBlock_UI();

  useEffect(()=>{
    // BlockUI();
    // const timer = setTimeout(() => {
    //   UnBlockUI();
    // }, 2000);
    // return () => clearTimeout(timer);
  },[])

    return (
        <>
          {/* <p style={{fontSize:100,textAlign:"center"}}>information<br/>information<br/>information<br/>information<br/>information<br/>information<br/>information<br/>information<br/>information<br/>information<br/></p> */}
          {/* <p style={{fontSize:100,textAlign:"center"}}>information</p> */}

          {/* <PropfileUI/> */}
        </>
      );
}

export default information;