import DropdownMenu from "./DropdownMenu";
import MenuItem from "./MenuItem";
import { Link } from "react-router-dom";
import { useState, useEffect } from "react";
export default function MenuDropDown(props): any {
  const [lstMenuAtID, setlstMenuAtID] = useState([0]);

  useEffect(() => {
    let arrMenu: any[] = [];
    props.lstMenu.forEach(f => {
      arrMenu.push({ path: f.path, lstHead: f.lstHead });
      if (f.items.length > 0) {
        arrMenu = setarrMenu(arrMenu, f.items)
      }
    });

    let d = arrMenu.filter(f => (process.env.REACT_APP_API_URL + f.path) == window.location.pathname + window.location.search);
    if (d.length > 0) {
      setlstMenuAtID(d[0].lstHead)
    } else {
      if (props.menuID !== undefined) {
        if (props.menuID.length > 0) {
          setlstMenuAtID(props.menuID)
        } else {
          setlstMenuAtID([])
        }
      } else {
        setlstMenuAtID([])
      }
    }
  }, [window.location.href, props.lstMenu]);

  const setarrMenu = (arrMenu, item) => {
    item.forEach(f => {
      arrMenu.push({ path: f.path, lstHead: f.lstHead });
      if (f.items.length > 0) {
        setarrMenu(arrMenu, f.items)
      }
    });
    return arrMenu;
  }

  return props.lstMenu.map((item, key) => (
    <MenuItems
      key={key}
      item={item}
      menuID={props.menuID}
      hMenuID={props.hMenuID}
      lstMenuAtID={lstMenuAtID}
    />
  ));
}
const hasChildren = (item) => {
  const { items: children } = item;

  if (children === null) {
    return false;
  }

  if (children === undefined) {
    return false;
  }

  if (children.constructor !== Array) {
    return false;
  }

  if (children.length === 0) {
    return false;
  }

  return true;
};

const MenuItems = ({ item, menuID, hMenuID, lstMenuAtID }) => {
  const MenuComponent = hasChildren(item) ? MultiLevel : SingleLevel;
  return <MenuComponent item={item} menuID={menuID} hMenuID={hMenuID} lstMenuAtID={lstMenuAtID} />;
};

const SingleLevel = ({ item, menuID, hMenuID, lstMenuAtID }) => {
  return (
    <Link
      key={item.menuID}
      to={process.env.REACT_APP_API_URL + item.path || ""}
      style={{ textDecoration: "none", color: "black" }}
    >
      <MenuItem
        menuTH={item.menuTH}
        menuENG={item.menuENG}
        menuIDRoute={menuID}
        menuID={item.menuID}
        path={item.path}
        nLevel={item.nLevel}
        nType={item.nType}
        isactive={lstMenuAtID.filter(f => f == item.menuID).length > 0}
      />
    </Link>
  );
};

const MultiLevel = ({ item, menuID, hMenuID, lstMenuAtID }) => {
  const { items: children } = item;
  return (
    <>
      {/* <Link
        key={item.menuID}
        to={process.env.REACT_APP_API_URL + item.path || ""}
        style={{ textDecoration: "none", color: "black" }}
      > */}
      {/* {item.nLevel > 1 ? ( */}
      <DropdownMenu
        menuTH={item.menuTH}
        menuENG={item.menuENG}
        key={item.menuID}
        color="primary"
        nLevel={item.nLevel}
        nType={item.nType}
        hMenuIDRoute={hMenuID}
        menuID={item.menuID}
        isactive={lstMenuAtID.filter(f => f == item.menuID).length > 0}
      >
        {children.map((child) => (
          <MenuItems
            key={child.menuID}
            item={child}
            menuID={menuID}
            hMenuID={hMenuID}
            lstMenuAtID={lstMenuAtID}
          />
        ))}
      </DropdownMenu>
      {/* ) : (
            <DropdownMenu
              menuTH={item.menuTH}
              menuENG={item.menuENG}
              key={item.menuID}
              color="primary"
              nLevel={item.nLevel}
              nType={item.nType}
            >
              {children.map((child) => (
                <MenuItems key={child.menuID} item={child} />
              ))}
            </DropdownMenu>
          )} */}
      {/* </Link> */}
    </>
  );
};
