import { useEffect, useRef, useState } from "react";
import BlockUI1, { FnBlock_UI } from "src/Components/BlockUI";
import { Grid, Box } from "@mui/material";
import Authen from "../../Components/System/authen/authen";
import sCover from "src/Layout/img/vision-cover.jpg";
import { MenuBar } from "src/Components/System/NavBar/MenuBar";
import { Banner } from "src/Components/Banner";
import { Axios, AxiosGetAsync, AxiosPostFilePDF, AxiosPost } from "src/CommonFunction/TS_Axios";
import { ClassroomDetail } from 'src/Components/System/ClassroomModal/ClassroomDetail';
import DialogPreview from "src/Components/FormItem/Dialog/DialogPreview";
import { BsFlagFill } from 'react-icons/bs';
import { MdLocationPin } from 'react-icons/md';
import { BiCalendar } from 'react-icons/bi';
import Button from '@mui/material/Button';

const test = () => {
    const [lstData, setData] = useState({} as any);
    const { BlockUI, UnBlockUI } = FnBlock_UI();

    const GetClassroomDetail = async () => {
        BlockUI();
        let result = {} as any;
        result = await AxiosGetAsync("Classroom/GetClassroomDetail", { nCourseID: 34, nScheduleID: 1 });
        UnBlockUI();
        return result;
    };
    const GetData = async () => {
        let res = await GetClassroomDetail();
        

        setData(res);
        console.log(lstData)


    };

    const Print = async () => {
        let data = {
            sInvoiceID: "123456789"
        }
        await AxiosPost(`Classroom/PrintInvoice`, data, (res) => {
            savePDF(res, "Invoice.pdf");
        }, null, null, null
        );

    };
    const onSave = async () => {
        var data = {
            sInvoiceID: "OBA2000003"
        }
        await AxiosPostFilePDF(`Classroom/PrintInvoice`, data, (res) => {
            //    debugger
            //    savePDF(res, "Invoice.pdf");
        }, null, BlockUI, UnBlockUI)
    };
    const savePDF = (result, fileName) => {
        const url = window.URL.createObjectURL(new Blob([result]));
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', fileName); //or any other extension
        document.body.appendChild(link);
        link.click();
    }
    useEffect(() => {
        GetData();
        //onSave();
    }, []);

    return (
        <>
            <Grid
                container
                columnSpacing={0}
                rowSpacing={0}
                sx={{ "&.MuiGrid-root": { marginTop: 0, display: "block" } }}
            >
            
                <ClassroomDetail 
                nID={33} 
                nScheduleID={1} 
                data={lstData} 
                isExpire={false}
                ></ClassroomDetail>
              



            </Grid>
        </>

    );
};
export default test;
