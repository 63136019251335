import { Box, Grid, Typography } from "@mui/material";
import React from "react";
import noData from "../../Layout/img/no_data_found.svg";

export const NoDataUI = (props: NoDataUI) => {
  const { isShow = false } = props;
  return (
    <>
      {isShow && (
        <Grid container>
          <Grid item xs={12}>
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                flexDirection: "column",
                m:"5em 5em 0em 5em"
              }}
            >
              {/* <img src={noData} style={{ width: "400px", height: "400px" }} /> */}
              <Typography sx={{ fontSize: "24px", color: "#aeb4b7" }}>
                ไม่พบข้อมูล
              </Typography>
              <Typography sx={{ fontSize: "24px", color: "#aeb4b7" }}>
                No Data Found
              </Typography>
            </Box>
          </Grid>
        </Grid>
      )}
    </>
  );
};

interface NoDataUI {
  isShow?: boolean;
}
