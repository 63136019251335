//React
import React, { useEffect, useState } from "react";
import logo from "src/Layout/img/oba-logo.png";
//other - Mui
import { useNavigate } from "react-router";
import { Axios } from "src/CommonFunction/TS_Axios";
import { AlertMsg, AlertTitle, DialogConfirm, DialogConfirmDelete, SwAlert } from "src/Components/Alert/Sweetalert";
import { FnBlock_UI, ResultAPI } from "src/CommonFunction/TS_function";
import { Box, Button, Grid, Typography } from "@mui/material";
import { GridColumns } from '@mui/x-data-grid';

//component Input
import InputNumberForm from "src/Components/FormItem/InputNumber";

//Button

//Yup
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { FormProvider, useForm } from "react-hook-form";
import yupFormSchemas from "src/Components/FormItem/yup/yupFormSchemas";
import { BreadcrumbsUI } from "src/Components/BreadcrumbsUI";
import NameBox from "src/Components/Register/RegisterNameBox";
import StepperCustom from "./StepperCustom";


var Regex13 = /^\d{13}$/;


const RegisterTrackingDealer = () => {

    const { BlockUI, UnBlockUI } = FnBlock_UI();
    const [nStatus, setnStatus] = useState("");
    const [sStatusTH, setsStatusTH] = useState("")
    const lstStepper = [
        {
            id: 0,
            sName: "กรอกข้อมูลคำขอ",
            sDesc: <><p>ระบุข้อมูลของท่านหรือธุรกิจของท่าน</p></>,
            classname: "requestStepper",
        },
        {
            id: 1,
            sName: "ส่งคำขอ",
            sDesc: <><p>ส่งคำขอสมัครเข้าใช้งานเพื่อให้ OR Academy ตรวจสอบข้อมูลและอนุมัติคำขอ</p></>,
            classname: "sendStepper",
        },
        {
            id: 2,
            sName: "รออนุมัติคำขอ",
            sDesc: <><p>เป็นขั้นตอน OR Academy ตรวจสอบข้อมูลและอนุมัติคำขอ</p></>,
            classname: nStatus == "3" ? "PassApproveStepper" : nStatus == "2" ? "RejectStepper" : "approveStepper",
        },
        {
            id: 3,
            sName: "เสร็จสิ้นการสมัคร",
            sDesc: <><p>ท่านจะได้รับอีเมลผลคำขอการสมัครเข้าใช้งานพร้อม Username</p></>,
            classname: nStatus == "3" ? "ActivecompleteStepper" : "completeStepper",
        },
    ];


    const schema = yup.object().shape({
        sNoTaxpayer: yupFormSchemas.string("เลขประจำตัวผู้เสียภาษี /Taxpayer Identification No.", { required: true, matches: Regex13, labelmatches: "เลขประจำตัวผู้เสียภาษี /Taxpayer Identification No. ไม่ถูกต้อง" }),
    });
    const form = useForm({
        resolver: yupResolver(schema),
        mode: "all",
    });

    const onCheckTracking = async () => {
        let Param = {
            sTaxID: form.getValues("sNoTaxpayer") ?? "",
            nUserType: "3",  //ผู้แทนจำหน่าย Dealer
        }
        await Axios(
            `RegisterPage/GetData_trackingAccountSignUp`, Param, "POST", async (res) => {
                ResultAPI(true, res, null, () => {
                    if (res.Status == "Success") {
                        setnStatus(res.sstatusID)
                        setsStatusTH(res.sstatusTH)
                    }
                    else {
                        SwAlert.Warning(AlertTitle.Warning, res.Message, () => { });
                    }
                })
            },
            null,
            BlockUI,
            UnBlockUI
        );
    }

    return (
        <Grid container>
            <Grid item xs={12}>
                <BreadcrumbsUI
                    items={[
                        {
                            Key: "1",
                            menuName: "ตรวจสอบสถานะการสมัครสมาชิก/Track Status",
                            IsOnClick: false,
                        },
                    ]}
                    isShowHome={false}
                />
            </Grid>
            <Grid item xs={12} sx={{ marginTop: "3%" }}>
                <FormProvider {...form}>
                    <Grid container justifyContent={'center'} alignItems={'center'}>
                        <Grid item xs={12} sx={{ marginTop: "15px" }}>
                            <img src={logo} style={{ width: "15%", height: "auto", display: 'block', margin: "auto" }} />
                        </Grid>
                        <Grid item xs={12} >
                            <Typography align="center" style={{ color: '#169bd5', fontSize: '1.7rem', fontWeight: 500 }}>ตรวจสอบสถานะการสมัครสมาชิก/Track Status</Typography>
                        </Grid>
                        <Grid item xs={12} style={{ margin: "15px 0px" }}>
                            <Typography align="center" style={{ fontSize: '1.25rem' }}>กรุณากรอกข้อมูลของท่านให้ถูกต้องครบถ้วน เพื่อใช้ในการตรวจสอบสถานะ</Typography>
                            <Typography align="center" style={{ fontSize: '1.25rem' }}>Please enter the Taxpayer Identification No.</Typography>
                        </Grid>
                        <Grid item xs={12} style={{ margin: "0px 15px" }}>
                            <Grid container justifyContent={'center'} alignItems={'center'}>
                                <Grid item xs={12} md={4}>
                                    <InputNumberForm
                                        name="sNoTaxpayer"
                                        label="เลขประจำตัวผู้เสียภาษี /Taxpayer Identification No."
                                        type={"number"}
                                        required={true}
                                        allowMinus={false}
                                        fullWidth
                                        digits={0}
                                        maxLength={13}
                                        integerDigits={13}
                                        textAlign={"left"}
                                        thousandSeparator={false}
                                        allowLeadingZeros={true}
                                    />
                                </Grid>
                            </Grid>
                        </Grid>
                        {nStatus === "0" &&
                            <Grid item xs={12} style={{ margin: "0px 15px", color: 'red' }}>
                                <Typography align="center" style={{ fontSize: '1rem' }}>ไม่พบข้อมูล กรุณาตรวจสอบ <span style={{ fontWeight: 500 }}>"เลขประจำตัวผู้เสียภาษี"</span></Typography>
                                <Typography align="center" style={{ fontSize: '1rem' }}>Not found. Please check the Taxpayer Identification No.</Typography>
                            </Grid>
                        }
                        {nStatus === "1" &&
                            <Grid item xs={12} style={{ margin: "0px 15px" }}>
                                <Typography align="center" style={{ fontSize: '1rem' }}>ขณะนี้ข้อมูลของท่านอยู่ในขั้นตอน  <span style={{ fontSize: '1rem', color: '#ffa237', fontWeight: 600, textDecoration: 'underline' }}>{sStatusTH}</span> เมื่อทาง OR Academy  ตรวจสอบข้อมูลเรียบร้อยแล้ว ท่านจะได้รับผลคำขอการสมัครสมาชิกทางอีเมล</Typography>
                            </Grid>
                        }
                        {nStatus === "2" &&
                            <Grid item xs={12} style={{ margin: "0px 15px" }}>
                                <Typography align="center" style={{ fontSize: '1rem' }}>ขณะนี้ข้อมูลของท่าน <span style={{ fontSize: '1rem', color: 'red', fontWeight: 600, textDecoration: 'underline' }}>{sStatusTH}</span>  โปรดตรวจสอบอีเมลชื่อเรื่อง "แจ้งผลการสมัครสมาชิก OR Academy " ในกล่องจดหมาย เพื่อตรวจสอบและแก้ไขข้อมูลของท่าน</Typography>
                            </Grid>
                        }
                        {nStatus === "3" &&
                            <Grid item xs={12} style={{ margin: "0px 15px" }}>
                                <Typography align="center" style={{ fontSize: '1rem' }}>ขณะนี้ข้อมูลของท่าน <span style={{ fontSize: '1rem', color: '#39822d', fontWeight: 600, textDecoration: 'underline' }}>{sStatusTH}</span> โปรดตรวจสอบอีเมลชื่อเรื่อง "แจ้งผลการสมัครสมาชิก OR Academy " ในกล่องจดหมาย เพื่อดูรายละเอียดเพิ่มเติม</Typography>
                            </Grid>
                        }



                        <Grid item xs={12} style={{ margin: "15px 0px" }}>
                            <Grid container justifyContent={'center'} alignItems={'center'}>
                                <Grid item xs={8} md={2}>
                                    <Button
                                        onClick={form.handleSubmit(() => { onCheckTracking() })}
                                        sx={{
                                            borderRadius: "16px",
                                            backgroundColor: "#1a90c3", fontSize: 15, color: "White", ":hover": { bgcolor: "#10a9eb" },
                                            width: "100%"
                                        }}
                                    >
                                       ตรวจสอบ/Track
                                    </Button>
                                </Grid>
                            </Grid>
                        </Grid>

                    </Grid>
                </FormProvider >

            </Grid>
            {nStatus != "" && nStatus != "0" &&
                <Grid container xs={12}  direction="row" alignItems="center" sx={{ paddingLeft: "10px" }}>
                    <Grid item xs={12}>
                        <Typography align="center" style={{ fontSize: '1rem', color: '#0183bb', fontWeight: 500, marginBottom: '8px' }}>ขั้นตอนการสมัครเข้าใช้งานระบบ OR Academy</Typography>
                    </Grid>
                    <Grid item container xs={12} justifyContent={"center"} alignItems="start" direction="row"  >
                        {lstStepper.map((item, index) => (
                            <Grid item key={index} >
                                <StepperCustom sNameBox={item} />
                            </Grid>
                        ))}
                    </Grid>
                </Grid>}
        </Grid>
    )
}

export default RegisterTrackingDealer