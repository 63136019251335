import { Box, Card, Grid } from "@mui/material";
import { Axios } from "src/CommonFunction/TS_Axios";
import { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router";
import { DecryptApi, ParseHtml, GetQueryString } from "src/CommonFunction/TS_function";
import { BtnSeeDetail } from "../Button";
import { FnBlock_UI } from '../BlockUI';
import YouTube, { YouTubeProps } from 'react-youtube';
import ReactPlayer from "react-player";
import { BreadcrumbsUI } from "../BreadcrumbsUI";
import defaultImage from "../../Layout/img/topic-3.jpg";
import { Typography } from "@mui/material";
import { Banner } from "../Banner";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { FormProvider, useForm } from "react-hook-form";
import { MenuBar } from "../System/NavBar/MenuBar";
import LightGallery from 'lightgallery/react';
import lgThumbnail from 'lightgallery/plugins/thumbnail';

var getYouTubeID = require('get-youtube-id');
export function NewsDetails(props: NewsDetails) {
    const { dataSource } = props;
    const navigate = useNavigate();
    const { BlockUI, UnBlockUI } = FnBlock_UI();
    const [banner, setBanner] = useState([] || undefined);
    const [navbar, setNavBar] = useState([] || undefined);
    const [MotherData, setMotherData] = useState<any[]>([]);
    const [ChildData, setChildData] = useState<any[]>([]);
    const [NewsUpdate, setNewsUpdate] = useState<any[]>([]);
    const location = useLocation();
    const searchParams = location.search;
    const sID = GetQueryString('sID');
    const sContentID = GetQueryString('str');
    const objSchema = {
        // sStart: yupFormSchemas.datetime("sStart", { required: false }),
        // sEnd: yupFormSchemas.datetime("sEnd", { required: false })
    }
    const [sScreenSize, setScreenSize] = useState('lg');
    const [nWidth, setWidth] = useState(window.innerWidth);
    const [nHeight, setHeight] = useState(window.innerHeight);
    const schema = yup.object().shape(objSchema);
    const form = useForm({
        resolver: yupResolver(schema),
        shouldUnregister: false,
        shouldFocusError: true,
        mode: "all",
    });

    useEffect(() => {
        NewsDetail();
    }, [sID]);

    const NewsDetail = async () => {

        await Axios("News/GetData_NewsDetail", { sID: sID }, "POST", async (result) => {
            // let resBanner = await loadBanner();
            // let resNavBar = await loadNavBar();

            // setBanner(resBanner);
            // setNavBar(resNavBar);

            setMotherData(result.lstNews);
            setChildData(result.lstNewsDetail);
            setNewsUpdate(result.lstNewsUpdate);
            // window[`scrollTo`]({ top: 150, behavior: `smooth` });
        },
            null,
            BlockUI,
            UnBlockUI,
            () => { }
        );
    }

    useEffect(() => {
        window.addEventListener("resize", updateDimensions);
        return () => window.removeEventListener("resize", updateDimensions);
    }, []);

    useEffect(() => {

        if (nWidth >= 1200) {
            setScreenSize('lg')
        } else if (nWidth >= 992) {
            setScreenSize('md')
        } else if (nWidth >= 768) {
            setScreenSize('sm')
        } else {
            setScreenSize('xs')
        }

        console.log(nWidth)
        console.log("sScreenSize: " + sScreenSize)
    }, [nWidth]);

    const updateDimensions = () => {
        setWidth(window.innerWidth);
        setHeight(window.innerHeight);
    }

    // const loadBanner = async () => {
    //     let result = {} as any;
    //     await Axios(
    //         `Master/GetBannerCMS`,
    //         "nContentID=5",
    //         "Get",
    //         async (res) => {
    //             result = res;
    //         },
    //         null,
    //         null,
    //         null,
    //         () => { }
    //     );
    //     return result;
    // };

    // const loadNavBar = async () => {
    //     let apiDecrypt = await DecryptApi(sID);
    //     let result = {} as any;
    //     await Axios(`Master/GetNevBarMenu`, "nMenuID=30&nContentID=" + apiDecrypt, "Get", async (res) => {
    //         result = res;
    //     },
    //         null,
    //         BlockUI,
    //         UnBlockUI,
    //         () => { }
    //     );
    //     return result;
    // };

    const opts: YouTubeProps['opts'] = {
        height: '390',
        width: '640',
        playerVars: {
            autoplay: 0
        },
    };

    // const onPlayerReady: YouTubeProps['onPause'] = (event) => {
    //     event.target.pauseVideo();
    // }
    const onPlayerReady: YouTubeProps["onReady"] = (event) => {
        event.target.pauseVideo();
    };
    const gotoNewsList = (sID: string) => {
        // navigate(process.env.REACT_APP_API_URL + `NewsDetail?sID=${encodeURI(sID)}`);
        navigate(process.env.REACT_APP_API_URL + `NewsDetail?sID=` + sID + "&str=" + sContentID);
        // window[`scrollTo`]({ top: 250, behavior: `smooth` });
    };

    const NewsTopic = (data) => {
        let e = data.data;
        return (
            <>

                <Grid item xs={12} sx={{ m: "0.5em", display: "flex", justifyContent: "center", alignItems: "center", flexDirection: "column" }}>
                    <div className="">
                        <div className="img-hover-zoom-NewsList" id="NewsUpdate" style={{ width: "100%", height: "100%" }}>
                            <LightGallery
                                speed={100}
                                plugins={[lgThumbnail]}
                                elementClassNames={"generalImg"}

                            >
                                <img
                                    src={(e.sFilePath) || defaultImage}
                                    onError={({ currentTarget }) => {
                                        currentTarget.onerror = null;
                                        currentTarget.src = defaultImage;
                                    }}
                                    style={{
                                        aspectRatio: "1/1",
                                        maxWidth: "100%",
                                        width: "100%"
                                    }}
                                />
                            </LightGallery>
                        </div>
                    </div>
                    <Box display="flex" justifyContent="center">
                        <p
                            className="titleNews"
                        >
                            {(e.sTitle)}
                        </p>
                    </Box>
                    <Box display="flex" justifyContent="center" sx={{ marginTop: "8%", marginBottom: "4%" }}>
                        <BtnSeeDetail
                            color="warning"
                            txt="อ่านต่อ/Read more"
                            onClick={() => gotoNewsList(e.sID)}
                        />
                    </Box>
                </Grid>

            </>
        );
    };


    return (
        <FormProvider {...form}>
            <Grid container>
                <Grid item xs={12}>
                    {/* <Banner
                        dataSource={banner || []}
                        height={50}
                        textTH="ข่าวสาร"
                        textENG="News"
                    /> */}
                </Grid>
                <Grid item xs={12}>
                    <MenuBar sContentID={sContentID} />
                    {/* <BreadcrumbsUI
                        items={[
                            {
                                menuName: "ข่าวสาร/News",
                                path: process.env.REACT_APP_API_URL + "NewsList",
                                IsOnClick: true
                            },
                        ]}
                        // items={navbar}
                        isShowHome={false}
                    /> */}
                    {/* <MenuBar sContentID={sID} /> */}
                </Grid>
                <Grid item xs={12}>
                    <Grid container sx={{ px: "1em" }}>
                        {/* <Grid item xs={(sScreenSize == 'lg' || sScreenSize == 'md') ? 10 : 12}> */}
                        <Grid item xs={12} md={(NewsUpdate.length > 0) ? 8 : 12} lg={(NewsUpdate.length > 0) ? 9.5 : 12}>
                            <Grid container sx={{ margin: "auto", padding: "4%" }} >
                                {(MotherData.length > 0 ? MotherData : []).map(
                                    (e) => (
                                        <Grid container>
                                            < Grid item xs={12} sx={{ textAlign: "left", marginRight: "8%", marginLeft: "2%", marginBottom: "3%" }}>
                                                <h1
                                                    className="sTitleNews"
                                                    style={{
                                                        whiteSpace: "normal",
                                                        textOverflow: "ellipsis",
                                                        wordBreak: "break-word",
                                                    }}
                                                >
                                                    {(e.sTitle)}
                                                </h1>
                                            </Grid>
                                        </Grid>
                                    )
                                )}

                                {(ChildData.length > 0 ? ChildData : []).map(
                                    (m) => {
                                        if (m.nContentTypeID == 1) {
                                            return (
                                                <Grid item xs={12} sx={{ marginBottom: "3%" }}>
                                                    <pre
                                                        className="sContentHTML"
                                                        style={{
                                                            whiteSpace: "normal",
                                                            textOverflow: "ellipsis",
                                                            // marginRight: "10%",
                                                            // marginLeft: "7%",
                                                            marginRight: "10%",
                                                            marginLeft: "10%",
                                                            wordBreak: "break-word",
                                                            margin: "auto"
                                                        }}
                                                    >
                                                        {ParseHtml(m.sContentHTML)}
                                                    </pre>
                                                </Grid>
                                            )
                                        } else if (m.nContentTypeID == 2) {
                                            return (
                                                <Grid item xs={12} justifyContent="center" alignItems="center" sx={{ marginBottom: "3%", textAlign: "center" }}>
                                                    <LightGallery
                                                        speed={100}
                                                        plugins={[lgThumbnail]}
                                                        elementClassNames={"generalImg"}

                                                    >
                                                        <img
                                                            src={(m.sFilePath)}
                                                            style={{
                                                                maxWidth: "70%"
                                                            }}
                                                        />
                                                    </LightGallery>
                                                    {/* <Grid justifyContent="center" alignItems="center" >
                                                        <img
                                                            src={(m.sFilePath)}
                                                            style={{
                                                                marginLeft: "50%",
                                                                maxWidth: "70%"
                                                            }}
                                                        />
                                                    </Grid> */}
                                                    <p
                                                        className="sDesc"
                                                        style={{
                                                            whiteSpace: "normal",
                                                            textOverflow: "ellipsis",
                                                            color: "#A0A0A0"
                                                        }}
                                                    >
                                                        {(m.sDesc)}
                                                    </p>
                                                </Grid>
                                            )
                                        } else if (m.nContentTypeID == 3) {
                                            return (
                                                <Grid item xs={12} sx={{ marginBottom: "3%", textAlign: "center" }}>
                                                    <ReactPlayer url={(m.sFilePath)} width="100%" height="auto" controls={true} />
                                                    <p
                                                        className="sDesc"
                                                        style={{
                                                            whiteSpace: "normal",
                                                            textOverflow: "ellipsis",
                                                            color: "#A0A0A0"
                                                        }}
                                                    >
                                                        {(m.sDesc)}
                                                    </p>
                                                </Grid>
                                            )
                                        } else if (m.nContentTypeID == 4) {
                                            var id = getYouTubeID(m.sURL);
                                            return (
                                                <Grid item xs={12} sx={{ marginBottom: "3%", textAlign: "center" }}>
                                                    <YouTube
                                                        videoId={id} opts={opts} onReady={onPlayerReady} iframeClassName="fullWidth" />

                                                    <p
                                                        className="sDesc"
                                                        style={{
                                                            whiteSpace: "normal",
                                                            textOverflow: "ellipsis",
                                                            color: "#A0A0A0"
                                                        }}
                                                    >
                                                        {(m.sDesc)}
                                                    </p>
                                                </Grid>
                                            )
                                        }
                                        else {
                                            return null;
                                        }
                                    }
                                )}

                                {(MotherData.length > 0 ? MotherData : []).map(
                                    (e) => (
                                        <Grid container>
                                            < Grid item xs={12} sx={{ textAlign: "left", marginRight: "8%", marginLeft: "2%", marginBottom: "3%" }}>
                                                <p
                                                    className="sUpdateDate"
                                                    style={{
                                                        whiteSpace: "normal",
                                                        textOverflow: "ellipsis",
                                                        fontStyle: "italic",
                                                        color: "#A0A0A0"
                                                    }}
                                                >
                                                    {(e.sUpdateDate)}
                                                </p>
                                            </Grid>
                                        </Grid>
                                    )
                                )}
                            </Grid >
                        </Grid>

                        {/* <Grid item sx={{ my: "2%", borderRadius: 2, maxHeight: "14%", maxWidth: "16%", backgroundColor: "#e5ebef", backgroundImage: "linear-gradient(to bottom right, #e5ebef, #63b2db)" }}> */}
                        {/* {(sScreenSize == 'lg' || sScreenSize == 'md') && */}
                        <Grid item xs={12} md={4} lg={2.5} sx={{ my: "2%", borderRadius: "0.5em", maxHeight: "14%", maxWidth: "100%" }}> {/*maxWidth: "16%" */}
                            {/* <Grid container sx={{ textAlign: "center", marginBottom: "6%" }}> */}
                            <Typography sx={{ marginTop: "10%", mx: "4%", textAlign: "left", fontSize: 18, fontWeight: "500" }}>ข่าวล่าสุด/Recent News</Typography>
                            <Grid container>


                                {NewsUpdate.map(
                                    (e, i) => {
                                        if (i <= 4) {

                                            return (
                                                <Grid item xs={12} sm={6} md={12} sx={{ p: "2em" }}  >
                                                    <Card sx={{ width: "100%", }}>
                                                        <NewsTopic data={e} />
                                                    </Card>
                                                </Grid>
                                            )

                                            // return (
                                            //     // <Grid style={{ display: "flex", height: "100%", maxHeight: "315px" }}>
                                            //     <Card sx={{ my: "3%", mx: "10%", borderRadius: "0.5em", maxWidth: "78%", backgroundColor: "#FEFEFE" }}>
                                            //         <Grid item
                                            //             sx={{
                                            //                 display: "flex",
                                            //                 flexDirection: "column",
                                            //                 justifyContent: "center",
                                            //                 my: "4%",
                                            //                 mx: "3%"
                                            //             }}
                                            //         >
                                            //             <div className="">
                                            //                 <div className="img-hover-zoom-NewsList" id="NewsUpdate" style={{ width: "100%", height: "100%" }}>
                                            //                     <LightGallery
                                            //                         speed={100}
                                            //                         plugins={[lgThumbnail]}
                                            //                         elementClassNames={"generalImg"}

                                            //                     >
                                            //                         <img
                                            //                             src={(e.sFilePath) || defaultImage}
                                            //                             onError={({ currentTarget }) => {
                                            //                                 currentTarget.onerror = null;
                                            //                                 currentTarget.src = defaultImage;
                                            //                             }}
                                            //                             style={{
                                            //                                 aspectRatio: "1/1",
                                            //                                 maxWidth: "100%",
                                            //                                 width: "100%"
                                            //                             }}
                                            //                         />
                                            //                     </LightGallery>
                                            //                 </div>
                                            //             </div>
                                            //             <p
                                            //                 className="titleNews"
                                            //             >
                                            //                 {(e.sTitle)}
                                            //             </p>
                                            //             <Box display="flex" justifyContent="center" sx={{ marginTop: "8%", marginBottom: "4%" }}>
                                            //                 <BtnSeeDetail
                                            //                     color="warning"
                                            //                     txt="อ่านต่อ/Read more"
                                            //                     onClick={() => gotoNewsList(e.sID)}
                                            //                 />
                                            //             </Box>
                                            //         </Grid>
                                            //     </Card>
                                            //     // </Grid>
                                            // )
                                        }
                                    }
                                )}

                                {/* </Grid> */}
                            </Grid>
                        </Grid>
                        {/* } */}
                    </Grid>
                </Grid>
            </Grid >
        </FormProvider >
    );
}

interface NewsDetails {
    dataSource?: any;
    onClick?: (value: any) => void;
}