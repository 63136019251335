//React
import React, { useEffect, useState } from "react";
//other - Mui
import { FnBlock_UI } from "../../BlockUI";
import { useNavigate } from "react-router";
import { Box, Checkbox, DialogActions, DialogContent, Divider, Fade, FormControlLabel, Grid, Modal, Typography } from "@mui/material";
import { DialogCustomUI } from "src/Components/Dialog/DialogCustomUI";
//component Input
//Button
import { BtnOK } from "../../Button";
//Yup
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { FormProvider, useForm } from "react-hook-form";
import yupFormSchemas from "src/Components/FormItem/yup/yupFormSchemas";

/**
 * 
 * @param props 
 * IsOpen
 * setIsOpen
 * @returns 
 */

const PopupSuccess = (props) => {
    const { IsOpenModal, setIsOpenModal } = props

    const navigate = useNavigate();
    const { BlockUI, UnBlockUI } = FnBlock_UI();
    const [isCenter, setAling] = useState(true);
    const [nWidth, setWidth] = useState(window.innerWidth);
    const schema = yup.object().shape({
        nPersonTrain: yupFormSchemas.string("จำนวนผู้เข้าอบรม", { required: true }),
    });
    const formReserve = useForm({
        resolver: yupResolver(schema),
        mode: 'all',
        shouldFocusError: true,
        criteriaMode: "firstError"
    })
    const GoToList = () => {
        navigate(process.env.REACT_APP_API_URL + "TrainingHistory");

    }
    useEffect(() => {

    }, [IsOpenModal])
    useEffect(() => {

        if (nWidth >= 600) {
            setAling(false);
        } else {
            setAling(true);
        }
    }, [nWidth]);
    useEffect(() => {
        window.addEventListener("resize", updateDimensions);
        return () => window.removeEventListener("resize", updateDimensions);
    }, []);
    const updateDimensions = () => {
        setWidth(window.innerWidth);
    }
    const elementAction = () => {
        return (
            <Grid container spacing={1} direction="row" justifyContent="center" alignItems="center">
                <Grid item><BtnOK onClick={() => { GoToList() }} /></Grid>
            </Grid>
        )
    }

    return (

        <DialogCustomUI
            open={IsOpenModal}
            textHead="บันทึกข้อมูลสำเร็จ/Success"
            fullWidth
            handleClose={() => { setIsOpenModal(false) }}
            isSetHeadColor={true}
            titleBgColor={"#43a7f3"}
            titleColor={"#fff"}
            isShowPopupSuccess={true}
            elementAction={elementAction}
        >
            <DialogContent sx={{
                display: "flex"
                , flexDirection: "column"
                , alignItems: "center", m: "1em", paddingBottom: "0px"
            }}>

                <Grid container spacing={2} direction="row" justifyContent="center" alignItems="center">
                    <Grid item xs={12} md={12} style={{ padding: '0px' }}>
                        <Divider />
                    </Grid>
                    <Grid item xs={12} md={12}>
                        <Typography>
                            กรุณาดำเนินการตามขั้นตอนด้านล่าง เพื่อยืนยันเข้าอบรม
                        </Typography>
                        <Typography>
                            1. พิมพ์ใบสั่งจ่ายและใบหักภาษี ณ ที่จ่าย
                        </Typography>
                        <Typography>
                            2. ชำระค่าอบรมโดยนำใบชำระชำระที่ธนาคาร หรือสแกนบาร์โค้ดจากใบชำระเงินกรณีต้องการชำระด้วย Mobile Banking
                        </Typography>
                    </Grid>
                    <Grid item xs={12} md={12}>
                        <Typography>
                            To complete the registration process, Please follow the steps below
                        </Typography>
                        <Typography>
                            Step 1: Print bill
                        </Typography>
                        <Typography>
                            Step 2: Bring the bill go to bank account or scan Barcode via mobile banking.
                        </Typography>
                    </Grid>
                </Grid>
            </DialogContent>
            <DialogActions sx={{ borderTop: "1px solid rgba(0,0,0,0.3)" }}>
                <Grid container spacing={1} direction="row" justifyContent={(!isCenter) ? "right" : "center"} alignItems={(!isCenter) ? "right" : "center"}>
                    <Grid item><BtnOK txt="ยืนยัน/Ok" onClick={() => { GoToList() }} /></Grid>
                </Grid>
            </DialogActions>

        </DialogCustomUI>



        // <Modal
        //     open={IsOpenModal}
        //     onClose={() => { setIsOpenModal(false) }}
        //     aria-labelledby="modal-modal-title"
        //     aria-describedby="modal-modal-description"
        //     onBackdropClick={() => { setIsOpenModal(false);}}
        // >
        //     <Fade in={IsOpenModal}>
        //         <Box sx={{
        //             position: 'absolute' as 'absolute',
        //             top: '50%',
        //             left: '50%',
        //             minWidth: '50%',
        //             transform: 'translate(-50%, -50%)',
        //             bgcolor: 'background.paper',
        //             boxShadow: 24,
        //             padding: '16px'
        //         }}>
        //             <Grid container spacing={2} direction="row" justifyContent="center" alignItems="center">
        //                 <Grid item xs={12} md={12}>
        //                     {/* <img src="" alt="LOGO" style={{ width: '10px', height: '10px' }} /> */}
        //                     <Typography variant="h6" component="h2">
        //                         บันทึกข้อมูลสำเร็จ/Success
        //                     </Typography>
        //                 </Grid>
        //                 <Grid item xs={12} md={12} style={{padding:'0px'}}>
        //                     <Divider />
        //                 </Grid>
        //                 <Grid item xs={12} md={12}>
        //                     <Typography>
        //                         กรุณาดำเนินการตามขั้นตอนด้านล่าง เพื่อยืนยันเข้าอบรม
        //                     </Typography>
        //                     <Typography>
        //                         1. พิมพ์ใบสั่งจ่ายและใบหักภาษี ณ ที่จ่าย
        //                     </Typography>
        //                     <Typography>
        //                         2. ชำระค่าอบรมโดยนำใบชำระชำระที่ธนาคาร หรือสแกนบาร์โค้ดจากใบชำระเงินกรณีต้องการชำระด้วย Mobile Banking
        //                     </Typography>
        //                 </Grid>
        //                 <Grid item xs={12} md={12}>
        //                     <Typography>
        //                         To complete the registration process, Please follow the steps below
        //                     </Typography>
        //                     <Typography>
        //                         Step 1: Print invoice
        //                     </Typography>
        //                     <Typography>
        //                         Step 2: Pay
        //                     </Typography>
        //                 </Grid>
        //                 <Grid item xs={12} md={12} style={{padding:'0px'}}>
        //                     <Divider />
        //                 </Grid>
        //                 <Grid item xs={12} md={12}>
        //                     <Grid container spacing={1} direction="row" justifyContent="right" alignItems="center">
        //                         <Grid item><BtnOK onClick={()=>{setIsOpenModal(false)}}/></Grid>
        //                         {/* <Grid item><BtnCancelForm /></Grid> */}
        //                     </Grid>
        //                 </Grid>
        //             </Grid>
        //         </Box>
        //     </Fade>
        // </Modal>
    )
}

export default PopupSuccess