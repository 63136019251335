import React, { useCallback, useEffect, useRef, useState } from "react";
import { createStyles, makeStyles } from "@mui/styles";
import { Theme } from "@mui/material/styles";
import CloseIcon from "@mui/icons-material/Close";
import { TransitionProps } from "@mui/material/transitions";
import {
  DialogContent,
  Grid,
  Stack,
  Box,
  Dialog,
  AppBar,
  Toolbar,
  IconButton,
} from "@mui/material";
import { Cropper, ReactCropperElement } from "react-cropper";
import "cropperjs/dist/cropper.css";
import { BtnDownload } from "../../../Button";
import { Extension } from "src/CommonFunction/TS_function";
import ReactPlayer from "react-player";
import Snackbar from "@mui/material/Snackbar";
import MuiAlert from "@mui/material/Alert";
import { Document, Page } from "react-pdf/dist/esm/entry.webpack";
import Pagination from "@mui/material/Pagination";
import { DialogUI } from "src/Components/Dialog";
const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    appBar: {
      position: "relative",
      backgroundImage:
        "linear-gradient(180deg, rgba(0,0,0,1) 0%, rgba(25,29,30,1) 51%)",
    },
    title: {
      marginLeft: theme.spacing(2),
      flex: 1,
    },
    body: {
      backgroundColor: "#191d1e",
      // display:"flex",
      // justifyContent:"center",
      // alignItem:"center",
    },
    cropContainer: {
      position: "relative",
      width: "100%",
    },
    cropButton: {
      flexShrink: 0,
      marginLeft: 16,
    },
    controls: {
      padding: 16,
      display: "flex",
      width: "50%",
      flexDirection: "column",
      alignItems: "stretch",
      [theme.breakpoints.up("sm")]: {
        flexDirection: "row",
        alignItems: "center",
      },
    },
    sliderContainer: {
      display: "flex",
      flex: "1",
      alignItems: "center",
    },
    sliderLabel: {
      color: "white",
      [theme.breakpoints.down("xs")]: {
        minWidth: 65,
      },
    },
    slider: {
      padding: "22px 0px",
      marginLeft: 16,
      [theme.breakpoints.up("sm")]: {
        flexDirection: "row",
        alignItems: "center",
        margin: "0 16px",
      },
    },
    HeadLable: {
      fontSize: 15,
      color: "#868686",
    },
    SubLable: {
      fontSize: 13,
      color: "#E3E3E3",
      whiteSpace: "nowrap",
      overflow: "hidden",
      textOverflow: "ellipsis",
    },
  })
);
const Transition = React.forwardRef(function Transition(
  props: TransitionProps & { children?: React.ReactElement },
  ref: React.Ref<unknown>
) {
  return null;
});
export default function FilePopup(props) {
  const classes = useStyles();
  const [open, setOpen] = React.useState(false);
  const [image, setImage] = React.useState<any>(
    // process.env.REACT_APP_API_URL + props.file.sUrl
    props.file.sFileLink
  );
  const [cropper, setCropper] = useState<Cropper>();
  const [rotation, setRotation] = useState<any>(0);
  const [zoom, setZoom] = useState<any>(0);
  const [autoCrop, setautoCrop] = useState<any>(true);
  const cropperRef = useRef<HTMLImageElement>(null);
  //const [SourceCrop, SetSourceCrop] = useState<any>( || "")
  const [DataImg, SetDataImg] = useState<any>();

  //Video
  const [isReady, setIsReady] = React.useState(false);
  const playerRef = React.useRef({} as any);
  const [nPlayedTime, setPlayedTime] = useState(props.nStartVideoOn || 0);
  const [isShowAlert, setIsShowAlert] = useState(false);

  //PDF
  // const [numPages, setNumPages] = useState(null);
  // const [pageNumber, setPageNumber] = useState(1);

  const [numPages, setNumPages] = useState<number>();
  const [pageNumber, setPageNumber] = useState(1);
  const [renderNavButtons, setRenderNavButtons] = useState<Boolean>(false);
  const onSuccess = (sample: any) => {
    alert("PDF document loaded successfully!");
    setPageNumber(1);
    setRenderNavButtons(true);
  };

  useEffect(() => { }, [props]);

  useEffect(() => {
    setImageDetail();
  }, [cropper]);

  useEffect(() => {
    setOpen(props.open);
  }, [props.file]);

  useEffect(() => {
    if (typeof cropper !== "undefined") {
      cropper.zoomTo(zoom);
    }
  }, [zoom]);

  useEffect(() => {
    if (typeof cropper !== "undefined") {
      cropper.rotateTo(rotation);
    }
  }, [rotation]);

  const setImageDetail = () => {
    if (typeof cropper !== "undefined") {
      cropper.setData(props.SourceCrop || "");
    }
  };
  const onPDFPageChange = (event, page) => {
    setPageNumber(page);
  };

  const DownloadOriginal = () => {
    var a = document.createElement("a"); //Create <a>
    a.href = image; //Image Goes here
    a.download = props.file.sFileName; //File name Here
    a.click(); //Downloaded file
  };

  const onReady = React.useCallback(() => {
    if (!isReady) {
      if (props.nStartVideoOn) {
        const timeToStart = props.nStartVideoOn;
        playerRef.current.seekTo(timeToStart, "seconds");
      }
      setIsReady(true);
    }
  }, [isReady]);

  const onClosePopup = () => {
    props.setStartVideoOn && props.setStartVideoOn(nPlayedTime);
    props.Fn();
  };

  const onCloseAlert = (
    event: React.SyntheticEvent | Event,
    reason?: string
  ) => {
    if (reason === "clickaway") {
      return;
    }
    setIsShowAlert(false);
  };

  const onActionAlert = (
    <React.Fragment>
      <IconButton
        size="small"
        aria-label="close"
        color="inherit"
        onClick={onCloseAlert}
      >
        <CloseIcon fontSize="small" />
      </IconButton>
    </React.Fragment>
  );

  const BoxOpen = () => {
    return (
      <React.Fragment>
        <Dialog
          fullScreen
          open={open}
          className={classes.body}
          onClose={onClosePopup}
        >
          <AppBar className={classes.appBar}>
            <Toolbar>
              <Grid container spacing={2} justifyContent="space-between">
                <Grid
                  container
                  spacing={4}
                  lg={8}
                  md={8}
                  justifyContent="flex-start"
                  style={{ paddingTop: "10px" }}
                >
                  <Snackbar
                    anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
                    open={isShowAlert}
                    autoHideDuration={3500}
                    onClose={onCloseAlert}
                  >
                    <MuiAlert
                      severity="warning"
                      onClose={onCloseAlert}
                      action={onActionAlert}
                    >
                      {"ไม่สามารถกดข้ามวิดีโอไปข้างหน้าได้!"}
                    </MuiAlert>
                  </Snackbar>
                  <Grid item lg={4} className={classes.HeadLable}>
                    Name:{" "}
                    <div className={classes.SubLable}>{props.file.sFileName}</div>
                  </Grid>
                  <Grid item lg={2} className={classes.HeadLable}>
                    Type:{" "}
                    <div className={classes.SubLable}>{props.file.sFileType}</div>
                  </Grid>
                </Grid>
                <Grid container lg={4} md={4} justifyContent="flex-end">
                  <BtnDownload
                    txt="Download"
                    onClick={(c) => {
                      DownloadOriginal();
                    }}
                  />
                  <IconButton
                    edge="end"
                    color="inherit"
                    onClick={onClosePopup}
                    aria-label="close"
                    sx={{ marginTop: "15px" }}
                  >
                    <CloseIcon />
                  </IconButton>
                </Grid>
              </Grid>
            </Toolbar>
          </AppBar>
          {Extension.PDF.indexOf(props.file.sFileType) > -1 ? (
          <iframe
            src={image + "#toolbar=0&navpanes=0&zoom=100"}
            style={{ width: "100%", height: "100%", display: Extension.PDF.indexOf(props.file.sFileType) > -1 ? "flex" : "none", paddingTop:"3em" }}
            allowTransparency={true}

          />
          )
          :
          (
          <DialogContent className={classes.body} style={{ display: Extension.PDF.indexOf(props.file.sFileType) > -1 ? "none" : "flex" }} dividers sx={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            justifyContent: "center",
            marginTop: "64px"
          }}>
            <div>
              <React.Fragment>
                {/* <div className={classes.cropContainer}> */}
                <Grid
                  container
                  spacing={0}
                  style={{ height: "85vh" }}
                >
                  <Grid
                    sx={{
                      display: "flex",
                      flexDirection: "column",
                      alignItems: "center",
                      justifyContent: "center",
                    }}
                  >
                    {/* Image */}
                    {Extension.Image.indexOf(props.file.sFileType) > -1 && (
                      <img src={image} alt="" />
                    )}

                    {/* Video */}
                    {Extension.Video.indexOf(props.file.sFileType) > -1 && (
                      <div
                        style={{
                          textAlign: "center",
                        }}
                      >
                        <div className="player-wrapper">
                          <ReactPlayer
                            ref={playerRef}
                            config={{
                              file: {
                                attributes: { controlsList: "nodownload" },
                              },
                            }}
                            className="react-player"
                            url={image}
                            width="100%"
                            height="100%"
                            playing={true}
                            controls={true}
                            pip={true}
                            stopOnUnmount={true}
                            onProgress={(playedSeconds) => {
                              let nProgress = playedSeconds.playedSeconds || 0;
                              if (props.CannotSkipForward) {
                                if (nProgress > nPlayedTime) {
                                  if (nProgress - nPlayedTime < 2) {
                                    setPlayedTime(nProgress);
                                  }
                                }
                              } else {
                                setPlayedTime(nProgress);
                              }
                            }}
                            onReady={onReady}
                            onSeek={(nSeekTo) => {
                              if (props.CannotSkipForward) {
                                if (nSeekTo > nPlayedTime) {
                                  playerRef.current.seekTo(
                                    nPlayedTime,
                                    "seconds"
                                  );
                                  setIsShowAlert(true);
                                }
                              }
                              props.onVideoEnd && props.onVideoEnd(false);
                            }}
                            onEnded={() => {
                              props.onVideoEnd && props.onVideoEnd(true);
                            }}
                          />
                        </div>
                      </div>
                    )}
                    {/* PDF */}
                    {Extension.PDF.indexOf(props.file.sFileType) > -1 && (
                      <Grid
                        item xs={24} md={12} lg={12}
                        sx={{
                          backgroundColor: "white",
                        }}
                      >
                        {/* <Document
                          file={image}
                          onLoadSuccess={({ numPages }) => {
                            setNumPages(numPages);
                            setRenderNavButtons(true);
                            onSuccess;
                          }}
                        >
                          <Page pageNumber={pageNumber} />
                        </Document>

                        {renderNavButtons && (
                          <Box
                            sx={{
                              display: "flex",
                              flexDirection: "row",
                              justifyContent: "center",
                              m: "10px",
                            }}
                          >
                            <Stack spacing={2}>
                              <Pagination
                                count={numPages}
                                onChange={onPDFPageChange}
                                variant="outlined"
                                color="primary"
                              />
                            </Stack>
                          </Box>
                        )} */}
                      </Grid>
                    )}
                  </Grid>
                </Grid>
              </React.Fragment>
            </div>
          </DialogContent>
          
          )}



        </Dialog>
      </React.Fragment>
    )
  }
  return (
    <div>
      <BoxOpen />

    </div>
  );
}
