import { Box, Grid, Pagination } from "@mui/material";
import React, { useEffect, useState, useRef, useCallback } from "react";
import { AiOutlineEye } from "react-icons/ai";
import { FaRegLightbulb } from "react-icons/fa";
import { Axios } from "src/CommonFunction/TS_Axios";
import { Encrypt, ParseHtml, GetQueryString } from "src/CommonFunction/TS_function";
import { BtnCustomText, BtnSearchWithOutText, BtnSeeDetail } from "../../Components/Button";
// import defaultImage from "../../Layout/img/default-image.png";
import { Banner } from "../../Components/Banner";
import { BreadcrumbsUI } from "../../Components/BreadcrumbsUI";
import { usePagination } from "../../Components/PaginationUI/Pagination";
import { InputForm } from "../../Components/FormItem/Input";
import SelectForm from "../../Components/FormItem/Select";
import * as yup from "yup";
import { FormProvider, useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import imgCover from "src/Layout/img/knowledge-sharing.jpg";
import defaultImage from "src/Layout/img/pic-default/de-knowledge.jpg";
import Typography from "@mui/material/Typography";
import IconButton from "@mui/material/IconButton";
import { NoDataUI } from "../../Components/NoData";
import "./KnowledgeSharing.css";
import { useNavigate, useLocation } from "react-router";
import { FnBlock_UI } from "src/Components/BlockUI";

import LightGallery from 'lightgallery/react';
// import styles
import 'lightgallery/css/lightgallery.css';
import 'lightgallery/css/lg-zoom.css';
import 'lightgallery/css/lg-thumbnail.css';
// import plugins if you need
import lgThumbnail from 'lightgallery/plugins/thumbnail';

import { Button, CardActionArea, CardActions } from "@mui/material";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import CardMedia from "@mui/material/CardMedia";
import { MenuBar } from "../../Components/System/NavBar/MenuBar";
import AutoCompleteSelect from "src/Components/FormItem/AutoCompleteSelect";
const KnowledgeSharingList = () => {
  const { BlockUI, UnBlockUI } = FnBlock_UI();
  const [generalKnowledge, setGeneralKnowledge] = useState([]);
  const [banner, setBanner] = useState([] || undefined);
  const [showNodata, setShowNodata] = useState(false);
  const navigate = useNavigate();
  const sContentID = GetQueryString('str');
  const [sNameTH, setNameTH] = useState("");
  const [sNameENG, setNameENG] = useState("");
  const [page, setPage] = useState(1); //page
  const PER_PAGE = 8;
  const objSchema = {};
  const oFormRef = useRef() as any;

  const schema = yup.object().shape(objSchema);
  const lightGallery = useRef<any>(null);
  const form = useForm({
    mode: "all",
    resolver: yupResolver(schema),
    shouldFocusError: true,
  });

  const Sort = [
    { label: "ใหม่-เก่า", value: 1 },
    { label: "เก่า-ใหม่", value: 2 },
  ]
  useEffect(() => {
    form.setValue("nSort", Sort[0]);
    setData();
  }, []);

  useEffect(() => {
    //handleChange(null, 1);
  }, [generalKnowledge]);

  const setData = async () => {
    let resBanner = await loadBanner();
    let param = {
      sSearch: form.getValues("sSearch") || "",
      nSort: form.getValues("nSort") != undefined ? form.getValues("nSort").value : 1,
    };
    let resGeneralKnowledge = await loadGeneralKnowledge(param);
    setBanner(resBanner || []);
    setGeneralKnowledge(resGeneralKnowledge);
    let resNavBar = await loadNavBar();
    setNameTH(resNavBar.menuNameTH);
    setNameENG(resNavBar.menuNameENG);
  };

  const loadNavBar = async () => {
    let result = {} as any;
    let param = "sContentID=" + sContentID;
    await Axios(`Master/GetNamePage`, param, "Get", async (res) => {
      result = res;
    },
      null,
      null,
      null,
      () => { }
    );
    return result;
  };

  const loadGeneralKnowledge = async (param) => {
    let result = null as any;
    param == {} ? {} : param;
    await Axios(
      `GeneralKnowledgel/GetData_GeneralKnowledgel`,
      param || {},
      "Post",
      async (res) => {
        result = res.lstDataMain;
        if (res.lstDataMain === null || res.lstDataMain.length === 0) {
          setShowNodata(true);
        } else {
          setShowNodata(false);
        }
      },
      setShowNodata(true),
      BlockUI,
      UnBlockUI,
      () => { }
    );
    return result;
  };

  const loadBanner = async () => {
    let result = [] as any;
    result.push({
      sPath: imgCover,
    });
    return result;
  };

  const count = Math.ceil((generalKnowledge || []).length / PER_PAGE);
  const _DATA = usePagination(generalKnowledge || [], PER_PAGE);
  const handleChange = (e, p) => {
    setPage(p);
    _DATA.jump(p);
    if (oFormRef.current != null) {
      window.scrollTo({
        top: oFormRef.current.offsetTop - 100,
        left: 0,
        behavior: "smooth",
      });
    }
  };

  const onClickSearch = async () => {
    let param = {
      sSearch: form.getValues("sSearch") || "",
      nSort: form.getValues("nSort") != undefined ? form.getValues("nSort").value : 1,
    };
    let resKnow = await loadGeneralKnowledge(param);
    setGeneralKnowledge(resKnow);

  };

  const onChangeSelect = async () => {
    let param = {
      nSort: form.getValues("nSort") != undefined ? form.getValues("nSort").value : 1,
    };
    let resKnow = await loadGeneralKnowledge(param);
    setGeneralKnowledge(resKnow);

  };
  const onClickDetail = (nIDC: string) => {
    navigate(process.env.REACT_APP_API_URL + `General?nIDC=${encodeURI(nIDC)}` + "&str=" + sContentID);
  };
  //#region Open Image
  const DivImage = (sURLImage) => {
    return (
      <LightGallery
        elementClassNames="custom-classname"
        dynamic
        dynamicEl={[
          {
            src: sURLImage.sURLImage || defaultImage,
          },
        ]}
        onInit={onInit}
        plugins={[lgThumbnail]}
      >

        <img
          src={(sURLImage.sURLImage) || defaultImage}
          onError={({ currentTarget }) => {
            currentTarget.onerror = null;
            currentTarget.src = defaultImage;
          }}
          style={{
            aspectRatio: "1/1",
            maxWidth: "100%",
            width: "100%",
            borderRadius: "50%",
          }}
        />

      </LightGallery>

    );
  }
  const checkFile = async (sUrl) => {
    setTimeout(() => {
      lightGallery.current.refresh([
        {
          src: sUrl || defaultImage,
        },
      ]);
      openGallery();
    }, 50);


  }

  const openGallery = useCallback(() => {
    lightGallery.current.openGallery();
  }, []);
  const onInit = useCallback((detail: any) => {
    if (detail) {
      lightGallery.current = detail.instance;
    }
  }, []);

  //#endregion Open Image
  return (
    <>
      <Grid container>
        <Grid item xs={12}>
          <Banner
            dataSource={banner || []}
            height={50}
            textTH={sNameTH}
            textENG={sNameENG}
          />
        </Grid>
        <Grid item xs={12}>
          <MenuBar sContentID={sContentID} />
        </Grid>

        <Grid container>
          <FormProvider {...form}>
            <Grid container sx={{ m: "2em" }}>
              <Grid container flexWrap={"wrap"} sx={{ paddingRight: "1em", marginTop: 2 }}>
                <Grid item xs={12} sx={{ mb: "1em", display: "flex", justifyContent: "flex-end", flexDirection: { xs: "column", lg: "row" } }}>
                  <Grid item xs={12} sm={12} md={8.5} lg={8.5} sx={{ px: { lg: "0.5em" }, maxWidth: "100% !important" }}>
                    <InputForm
                      name="sSearch"
                      label="คำค้นหา / Search"
                      fullWidth
                      maxLength={100}
                      onKeyPress={onClickSearch}

                    />
                  </Grid>
                  <Grid item xs={12} sm={12} md={1.5} lg={1.5} sx={{ px: { lg: "0.5em" }, maxWidth: "100% !important" }}>
                    <AutoCompleteSelect
                      name="nSort"
                      label={"เรียงโดย"}
                      width={{ width: "100%" }}
                      options={Sort}
                      IsShowIcon={false}
                      defaultValue={{ label: "ใหม่-เก่า", value: 1 }}
                      onChange={(v) => {
                        form.setValue('nSort', v ? v : null)
                        onChangeSelect();
                      }}
                    />
                  </Grid>


                  <Grid item sx={{ display: "flex", justifyContent: "center", paddingLeft: "0.6em" }}>
                    <BtnCustomText
                      txt="ค้นหา/Search"
                      onClick={onClickSearch}
                      fullWidth

                    />
                  </Grid>
                </Grid>

              </Grid>
            </Grid>


            <Grid container sx={{ px: { xs: "1em", md: "2em", lg: "5em" } }}>
              {/* <div ref={oFormRef}> */}
              {_DATA.currentData().map((m) => (
                <Grid xs={12} sm={6} md={4} lg={3} sx={{ display: "flex", justifyContent: "center" }}>
                  <Card sx={{ maxWidth: 350, mt: 8, px: "1em", width: 350, border: "none", boxShadow: "none", background: "transparent", overflow: "hidden", position: "relative" }}>
                    <CardActionArea>
                      <Grid style={{ display: "flex", height: "100%", maxHeight: "315px" }}>
                        <Grid className="img-hover-zoom-NewsList" style={{ width: "100%", height: "100%" }}>
                          <a onClick={() => checkFile(m.sURLImage)}
                            onError={({ currentTarget }) => {
                              currentTarget.onerror = null;
                              currentTarget.href = defaultImage;
                            }}
                          >
                            <DivImage sURLImage={m.sURLImage}></DivImage>
                          </a>

                          {/* <LightGallery
                            speed={500}
                            plugins={[lgThumbnail]}
                          >
                            <a href={(m.sURLImage) || defaultImage}
                              onError={({ currentTarget }) => {
                                currentTarget.onerror = null;
                                currentTarget.href = defaultImage;
                              }}
                            >
                              <img
                                src={(m.sURLImage) || defaultImage}
                                onError={({ currentTarget }) => {
                                  currentTarget.onerror = null;
                                  currentTarget.src = defaultImage;
                                }}
                                style={{
                                  // minHeight: "240px",
                                  aspectRatio: "1/1",
                                  maxWidth: "100%",
                                  width: "100%",
                                  borderRadius: "50%",
                                }}
                              />
                            </a>
                          </LightGallery> */}
                        </Grid>
                        {/* <CardMedia
                          component="img"
                          height="auto"
                          image={m.sURLImage || defaultImage}
                          alt="green iguana"
                          onError={({ currentTarget }) => {
                            currentTarget.onerror = null;
                            currentTarget.src = defaultImage;
                          }}
                          style={{
                            borderRadius: "50%",

                          }}
                        /> */}
                      </Grid>
                      {m.isPin && (
                        <IconButton
                          color="primary"
                          aria-label="upload picture"
                          component="label"
                          style={{ backgroundColor: "#ff0000" }}
                          sx={{
                            position: "absolute",
                            zIndex: 2,
                            right: "0%",
                            top: "0%",
                            borderRadius: "16px",
                            height: "30px"
                            // transform: "translateY(50%)",
                          }}>
                          <Typography variant="body2" sx={{ color: "#fff" }}>
                            New
                          </Typography>
                        </IconButton>
                      )}
                      <CardContent sx={{ display: "flex", justifyContent: "space-between", borderBottom: "1px solid #f5f5f5" }}>
                        <Typography variant="body2">General Knowledge</Typography>
                        <Typography variant="body2">{m.sUpdateDate}</Typography>
                      </CardContent>
                      <CardContent sx={{ maxHeight: 180, height: 180 }}>
                        <Typography
                          gutterBottom
                          variant="h6"
                          component="div"
                          sx={{
                            display: "-webkit-box",
                            overflow: "hidden",
                            WebkitBoxOrient: "vertical",
                            WebkitLineClamp: 2,
                          }}>
                          {m.sNames}
                        </Typography>
                        <Typography
                          variant="body2"
                          color="text.secondary"
                          sx={{
                            display: "-webkit-box",
                            overflow: "hidden",
                            WebkitBoxOrient: "vertical",
                            WebkitLineClamp: 3,
                          }}>
                          {ParseHtml(m.sDesc)}
                        </Typography>
                      </CardContent>
                    </CardActionArea>
                    <CardActions sx={{ pb: 4 }}>
                      <BtnSeeDetail fullWidth color={"rgb(25, 118, 210)"} hover={"rgb(17, 82, 147)"} txt="อ่านต่อ/Read more" onClick={() => onClickDetail(m.sID)} />
                    </CardActions>
                  </Card>
                </Grid>
              ))}
              {/* </div> */}
            </Grid>
            <NoDataUI isShow={showNodata} />
            <Grid
              item
              xs={12}
              sx={{
                m: { xs: "0em 2em 2em 2em", lg: "5em 2em" },
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              <Pagination
                count={count}
                page={page}
                variant="outlined"
                shape="rounded"
                showFirstButton
                showLastButton
                onChange={handleChange}
              />
            </Grid>
          </FormProvider>
        </Grid>
      </Grid>


      {/* </Grid> */}
    </>
  );
};

export default KnowledgeSharingList;
